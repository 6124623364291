import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';

import audiencePlaylist from './stubs/audience_playlist';

export const audienceService = {
    getStreamsStats,
    getDemographicsStats,
    getTerritoryStats,
    getDemographicsTerritoryStats,
    getSourceStats,
    getPlaylistStats,
    getTopPlaylistStats,
    getRepeatListeners    
};

function getStreamsStats(params, globalFilter){
    return config.live ? getStreamsStatsLive(params, globalFilter) : getStreamsStatsStatic();
}

function getStreamsStatsLive(params, globalFilter) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'audience';
    qs.mode = 'timeseries';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getStreamsStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_timeseries.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getDemographicsStats(params, globalFilter){
    return config.live ? getDemographicsStatsLive(params, globalFilter) : getDemographicsStatsStatic();
}

function getDemographicsStatsLive(params, globalFilter) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'audience_demographics';
    qs.mode = 'timeseries';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getDemographicsStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_demographics.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getTerritoryStats(params, globalFilter){
    return config.live ? getTerritoryStatsLive(params, globalFilter) : getTerritoryStatsStatic();
}

function getTerritoryStatsLive(params, globalFilter) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'audience_territory';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getTerritoryStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_territory.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getDemographicsTerritoryStats(params, globalFilter){
    return config.live ? getDemographicsTerritoryStatsLive(params, globalFilter) : getDemographicsTerritoryStatsStatic();
}

function getDemographicsTerritoryStatsLive(params, globalFilter) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'audience_demographics';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getDemographicsTerritoryStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_demographics_territory.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}


function getDemographicsStats(params, globalFilter){
    return config.live ? getDemographicsStatsLive(params, globalFilter) : getDemographicsStatsStatic();
}

function getDemographicsStatsLive(params, globalFilter) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'audience_demographics';
    qs.mode = 'timeseries';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getTerritoryStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_territory.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getSourceStats(params, globalFilter){
    return config.live ? getSourceStatsLive(params, globalFilter) : getSourceStatsStatic();
}

function getSourceStatsLive(params, globalFilter) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'audience_source';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getSourceStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_source.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getPlaylistStats(params, globalFilter){
    return config.live ? getPlaylistStatsLive(params, globalFilter) : getPlaylistStatsStatic();
}

function getPlaylistStatsLive(params, globalFilter) {
    
    return Promise.resolve(audiencePlaylist);
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'audience_playlist';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getPlaylistStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_playlist.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}


function getRepeatListeners(params, globalFilter){
    return config.live ? getRepeatListenersLive(params, globalFilter) : getRepeatListenersStatic();
}

function getRepeatListenersLive(params, globalFilter) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'audience_repeat_listeners';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getRepeatListenersStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_repeat_listeners.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getTopPlaylistStats(params, globalFilter){
    return config.live ? getTopPlaylistStatsLive(params, globalFilter) : getTopPlaylistStatsStatic();
}

function getTopPlaylistStatsLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'audience_playlist';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getTopPlaylistStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_playlist.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}
