import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { Route, withRouter } from 'react-router';
import { map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../../components/common/Spinner';
import Datepicker from '../../components/common/Datepicker';
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { importsActions } from '../../data/actions/imports';
import { statementPeriodsSettings } from '../../components/pages/imports/statementPeriods/Settings';
import ImportMetadataModal from '../../components/pages/imports/ImportMetadata';


var deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');

class ImportMetadata extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null
        }
        this.closeModal = this.closeModal.bind(this);
    }
    
    componentDidMount(){
        const { match, dispatch } = this.props;
        this.setState({
            id: match.params.id,
            showImportMetadata: false
        });
        dispatch(importsActions.getCatalogImport(match.params.id)).then((importData)=>{
            let interval = null;
            if(importData.import_status == 'running' || importData.import_status == 'failed_lines')
                interval = setInterval(()=>dispatch(importsActions.getCatalogImport(match.params.id)), 1000);
                
            this.setState({showImportMetadata: true, interval});
        });
    }
    
    closeModal(){
        this.setState({showImportMetadata: false})
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.imports.catalogImport) {
            if(nextProps.imports.catalogImport && nextProps.imports.catalogImport.import_status != 'running') {
                console.log('clear');
                clearInterval(this.state.interval);
            }
        }
    }
    
    
    
    render() {
        const { catalogImport, catalogImportLoading } = this.props.imports;
        return <div className="inner-page shared-pages">
            <ImportMetadataModal show={this.state.showImportMetadata} importData={catalogImport} closeModal={this.closeModal} />
        </div>
    }
}

function mapStateToProps(state) {
    return {
        imports: state.imports
    } 
}

export default withRouter(connect(mapStateToProps)(ImportMetadata))