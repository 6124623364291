import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

var homeSvg = require('!svg-inline-loader!../../../../public/img/home-icon.svg');


class ImportMenu extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
        }
    }
    
    
    render() {
        return <div className="import-menu import-menu-main">
            <ul>
                <li><Link to={`/import/statement-periods`}>Statements</Link></li>
                <li><Link to={`/import/list`}>Catalog</Link></li>
                <li><Link to={`/import/exceptions`}>Exceptions</Link></li>
                <li className="home-link admin-link"><span className="small-icon" dangerouslySetInnerHTML={{__html: homeSvg}}></span><Link to={`/main`}>Main Dashboard</Link></li>
            </ul>
        </div>
        
    }
}



export default ImportMenu;