import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Modal from '../../common/Modal';
import SharePageForm from '../../forms/sharePage';
import { sharedPagesActions } from '../../../data/actions/shared_pages';
import SharedPageLink from './SharedPageLink';

const shareSvg = require('!svg-inline-loader!../../../../public/img/icons/share.svg');

const dataMap = {
    top_imprints: {data: 'imprints.top', loading: 'imprints.topLoading'},
    top_artists: {data: 'artists.top', loading: 'artists.topLoading'},
    top_projects: {data: 'projects.top', loading: 'projects.topLoading'},
    top_products: {data: 'products.top', loading: 'products.topLoading'},
    top_tracks: {data: 'tracks.top', loading: 'tracks.topLoading'},
    top_territories: {data: 'stats.topTerritory.table', loading: 'stats.topTerritoryLoading'},
    territories: {data: 'stats.territory.table', loading: 'stats.territoryLoading'},
    top_vendors: {data: 'stats.vendor.table', loading: 'stats.vendorLoading'}
}



class SharePage extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            showShareResultModal: false,
            showNewShareModal: false,
            slug: '',
            loadingInitiated: false,
            loadingDone: false            
        }
        this.share = this.share.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const { loadingInitiated, loadingDone } = this.state;
        
        const { CSVEntities: entities } = this.props;
        let loadingInProgress = false;
        for(let entity of entities) {
            const loading = get(this.props, dataMap[entity]['loading']);
            if(loading)
                loadingInProgress = true;
        }
        if(loadingInProgress) {
            if(!loadingInitiated) {
                console.log('loading initiated');
                this.setState({
                    loadingInitiated: true,
                    loadingDone: false
                })
            }
        }
        else {
            if(loadingInitiated && !loadingDone) {
                console.log('loading done');
                this.setState({
                    loadingInitiated: false,
                    loadingDone: true
                });
            }
        }
    }

    
    share(formData) {
        const { title, expires } = formData,
            { entity, id, module, params, CSVEntities=[] } = this.props;
        
        const expiresAt = moment().add(expires ? 1 : 120, 'month').format('YYYY-MM-DD');
        this.props.dispatch(sharedPagesActions.share(entity, id, module, params, title, expiresAt))
        .then(result => this.setState({
            showNewShareModal: false,
            showShareResultModal: true,
            slug: result.slug
        }));
    }
    
    toggleModal(modal, opened){
        this.setState({[modal]: opened});
    }
    
    render() {
        const { sharedPages:{ page, loading} , exportToCsv, exportToPdf, CSVEntities, entity, id, module } = this.props;
        const CSVPage = module ? module : entity;
        const { loadingDone, showShareResultModal, showNewShareModal } = this.state;
        if(!loadingDone && !showNewShareModal)
            return null;

        return <div>
            <Modal
                show={showShareResultModal}
                title="Share Page"
                handleClose={()=>this.toggleModal('showShareResultModal', false)}
                showActionButtons={false}
            >
                <p>Link to share: <SharedPageLink slug={this.state.slug} /></p>
            </Modal>
            
            <SharePageForm
                show={this.state.showNewShareModal} 
                title="Share Page" 
                onSubmit={this.share} 
                values={{title:'', expires: true}}
                exportToPdf={exportToPdf}
                exportToCsv={exportToCsv}
                CSVEntities={CSVEntities}
                CSVPage={CSVPage}
                CSVId={id}
                onClose={(e)=>this.toggleModal('showNewShareModal', false)}
                dataMap={dataMap}
                loadingDone={loadingDone}
            />
            
            <button type="button" onClick={()=>this.toggleModal('showNewShareModal', true)} className="default-btn default-btn--small button-icon share-page-btn">
                share
                <span className="chart-icon chart-icon--small" dangerouslySetInnerHTML={{__html: shareSvg}}></span>
            </button>
        </div>

    }
}


function mapStateToProps(state) {
    return {
        sharedPages: state.sharedPages,
        imprints: state.imprints,
        artists: state.artists,
        products: state.products,
        projects: state.projects,
        tracks: state.tracks,
        stats: state.stats
    }
}


export default connect(mapStateToProps)(SharePage);