import { map, uniq, capitalize, find , uniqBy} from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand'; 
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { formatChartLabel, formatChartLabelItem, sortDateRange } from '../../helpers/DateFormatter';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import {percentageValue} from '../../helpers/NumberFormatter';
import AddTotal from '../../components/common/Datagrid/AddTotal';
import VendorHelper from '../../components/common/Datagrid/VendorHelper';
import FixNumericFields from '../helpers/FixNumericFields';

export const artistsFormatter = {
    formatForPercentageChart,
    formatForTable,
    formatTop,
    formatDetails,
    formatForTableOld,
    formatAutocomplete,
    formatAutocompleteItem,
    formatCard,
    formatStreamsStats,
    formatProfile,
    formatFollowersForChart,
    formatProducts,
    formatArtistChannels,
    formatMetadata,
    formatTimeseries
};

function formatForPercentageChart(data, column){
    let dataset = [], 
    labels = [];

    for(let entry of data) {
        dataset.push(entry[column]);
        labels.push(entry.name);
    }
    return {labels, datasets:[{data: dataset, label: 'Streams'}]};
}

function formatForTable(data){
    if(!data)
        return [];    
    for(let entry of data.data) {
        entry.id = entry.artist_id;
        entry.name = entry.artist_name;
        entry.logo = ImageURL(entry.image, entry.id, 'artists');
        entry.engaged = Math.round((entry.active / entry.curr_units) * 100);
    };
    data.data = AddTotal(data.data, 'artist');
    return data;
   

}

function formatTop(data, addTotal){
    if(!data)
        return [];    
    data = map(data, (entry)=>{
        entry.id = entry.artist_id;
        return entry;
    });
    if(addTotal)
    	data = AddTotal(data, 'artist');
    return  FixNumericFields(data);
}

function formatDetails(data){
    if(!data)
        return [];

    let labels = [],
        dataset = [];
    
    data = data.sort(sortDateRange);
    
    for(let item of data) {
        labels.push(formatChartLabel(item));
        dataset.push(item.units);
    }
    return {labels, datasets: [{data: Object.values(dataset), label: 'plays'}]};
}

function formatForTableOld(data){
    if(!data)
        return [];
    for(let entry of data.data) {
        entry.name = entry.artist;
        entry.logo = entry.image;
    }
    return data;
}

function _formatAutocompleteItem(hit){
    if(hit.entity)
        return hit;

    const entity = 'artists',
        name = hit.name? hit.name : hit._source.name,
        id = hit.id ? hit.id : hit._id;

    return {
        id,
        entity,
        name: `${name} (artist)`,
        logo: icons.entities[entity]
    };
}

function formatAutocompleteItem(hit){
    const entity = 'artists';
    
    return {
        id: hit.id,
        entity,
        name: `${hit.name} (artist)`,
        name_raw: hit.name,
        logo: icons.entities[entity],
        code: hit.id
    }
}


function formatAutocomplete(data) {
    let {artists}= data;
    return artists.map(formatAutocompleteItem);
}

function formatCard(data) {
    data = data.data;
    const channels = data.tracked_channels || []; 
    return {
        id: data.id,
        name: `${data.name} (artist)`,
        logo: data.image,
        channels
    }
}

function formatStreamsStats(streams, metadata) {
    let labels = [],
        datasets = [];
    if(streams) {
        const {dates, period} = dateRangeFlat(streams);
        datasets = metadata.map((artist, index)=>{
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, artist_id: artist.id});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: artist.id,
                label: artist.name,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };
}

function formatProfile(data) {
    return data.result && data.result[0] || {};
}

function _getFollowersData(data){
    return data.data.filter(item=>item.channel_type != 'youtube-video');
}

function getChannelCaption(channel) {
    return channel
    .toLowerCase()
    .replace('-', ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replace('Youtube', 'YouTube');
}

function formatFollowersForChart({data}){
    //data = _getFollowersData(data);
    
    let labels = [],
        datasets = [];
    
    if(data) {
        for(let channel of data) {
            let timeseries = channel.by_date;
            channel.channel_type = getChannelCaption(channel.channel_type);
            
            channel.label = `${channel.channel_name} (${channel.channel_type})`;
               
            for(let entryID in timeseries) {
                const entry = timeseries[entryID];
                if(entry.extract_date === null)
                    timeseries = timeseries.splice(entryID, 1);
                else   
                    labels.push(entry.extract_date);
            }
            channel.growth = timeseries.length ? (timeseries[timeseries.length-1].metric - timeseries[0].metric) : 0;
        }
        
        labels = uniq(labels).sort();
        
        datasets = data.map((channel, index)=>{
            let timeseries = [],
                absTimeseries = [],
                initialValue;
            for(let label of labels) {
                let value = 0;
                for(let item of channel.by_date) {
                    if(item.extract_date == label) {
                        value = item.metric;
                        break;
                    }    
                }
                if(value) {
                    absTimeseries.push(value);
                    if(initialValue === undefined)
                        initialValue = value;
                    timeseries.push(value - initialValue);
                }
                else {
                    absTimeseries.push(null);
                    timeseries.push(null);
                }
            }   
            return {
                id: channel.channel_id,
                label: channel.label,
                timeseries,
                absTimeseries,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length]                
                
            };
        });    
        
        labels = map(labels, (label)=>moment(label).format('DD MMM'));    
    }
    
    return {chart: { labels, datasets }, data};
    
}


function formatProducts(data) {
    return data.map(item=>({
        internal_id: item.id,
        value: item.barcode,
        label: `${item.title} (${item.barcode})`
    }))
}

function formatArtistChannels(data, channelTypes) {
    return data.length ? data.map(item=>{
        const channel_type = channelTypes[item.channel_type_id],
            channel_caption = getChannelCaption(channel_type)
        return {
            channel_type,
            value: item.id,
            label: `${item.name} (${channel_caption})`
        }}) : null;
}

function formatMetadata(entities, metadata, total) {
    const { total_income } = total;
    for(let entity of entities) {
        const metaEntity = find(metadata, {id: entity.id});
        if(metaEntity) {
            entity.name = metaEntity.name;
            entity.artist_name = metaEntity.name;
            entity.image = ImageURL(metaEntity.image, entity.id, 'artists');
        }
        else if(entity.id === null) {
            entity.name = 'Adjustments';
            entity.artist_name = '';
            entity.image = null; //ImageURL(metaEntity.image, entity.id, 'artists');            
        }
        else if(entity.id === 'Unlisted Artist') {
            entity.name = 'Unlisted Artist';
            entity.artist_name = '';
            entity.image = null; //ImageURL(metaEntity.image, entity.id, 'artists');
            metadata.push({
                id: 'Unlisted Artist',
                name: 'Unlisted Artist'
            });
        }
        entity.share = Math.round(entity.total_income/total_income*10000)/100;
    }
    return { formattedRows: entities, formattedMeta: metadata} ;

}

function formatTimeseries(streams, metadata) {
    let labels = [],
	    datasets = [];
	if(streams) {
	    const {dates, period} = dateRangeFlat(streams);
	    datasets = metadata.map((artist, index)=>{
	        let data = [];
	        for(let date of dates) {
	            let stream = find(streams, {report_date: date, artist_id: artist.id});
	            data.push(stream ? Number(stream.total_income).toFixed(2) : null);
	        }    
	
	        return {
	            id: artist.id,
	            label: artist.name,
	            data,
	            fill: false,
	            borderColor: colors[index%colors.length],
	            backgroundColor: colors[index%colors.length],
	            pointBorderColor: colors[index%colors.length],
	            pointBackgroundColor: colors[index%colors.length],                
	
	        }
	    });    
	    
	    labels =  map(dates, (date)=>date)// formatChartLabelItem(date, 'Month'));
	}
	return { labels, datasets };

}