import React from 'react';
import { map, find } from 'lodash';
import moment from 'moment';
import Box from '../../common/Box';
import { LineChart, StackedBarChart } from '../../widgets/charts/types'
import { formatChartLabelItem, sortDateRange } from '../../../helpers/DateFormatter';
import { splitByThousands } from '../../../helpers/NumberFormatter';
import {dateRange} from '../../../helpers/DateRange';

export default function EntityTimeline(props){
    let labels = [], 
        datasets = [], 
        { results, mode = 'line', getTimeseries, forceLegendPosition } = props,
        legendThreshold = 10,
        widthThreshold = 640,
        legendPosition = 'right'
        
    /*
    if(results && results.length) {

        //const dates = results.length ? map(results[0].stms_by_date, (item)=>item.stream_date) : [];
        const {dates, period} = dateRange(results);

        datasets = results.map(entity=>{
            entity.stms_by_date = entity.stms_by_date.sort(sortDateRange);
            let data = [];
            for(let date of dates) {
                let stms = find(entity.stms_by_date, {stream_date: date});
                data.push(stms ? stms.curr_units : null);
            }    
            return {
                label: entity.name,
                data: data,
                fill: false
            }
        });
        
        labels = map(dates, (date)=>formatChartLabelItem(date, period));
        legendPosition = (window.innerWidth < widthThreshold || datasets.length > legendThreshold ) ? 'bottom' : 'right';
    }
    */
    if(results && results.labels) {
        labels = results.labels;
        datasets = results.datasets;
    }
    else {
        getTimeseries();
    }

    legendPosition = 'bottom';    
    return datasets.length ? (mode == 'line' ? <LineChart data={{labels, datasets}} options={{legend: {display: true, position: legendPosition},     "scales": {
        "yAxes": [{
            "ticks": {
                callback: function formatAxesLabel(value, index, values){
                    return splitByThousands(value);
                }
            }
        }]            
    },
}}/> : 
    		<StackedBarChart data={{labels, datasets}} options={{legend: {display: true, position: legendPosition}}}/> ) : null;
}
