import {
  filterConstants
} from '../constants/filter';

const initialState = {
  tableBuilt: true,
  tableLoading: false,
  expanded: false,
  loading: false,
  data: [],
  maxDate: null
};

export function filter(state = initialState, action) {
  switch (action.type) {
    case filterConstants.SET_ENTITY_FILTER:
      return {
        ...state,
        [action.entity]: action.filter
      };
      
    case filterConstants.BUILD_TABLE_REQUEST:  
      return {
          ...state,
        tableBuilt: !action.refresh,
        tableLoading: true
      };
    case filterConstants.BUILD_TABLE_SUCCESS:  
        return {
            ...state,            
          tableBuilt: true,
          tableLoading: false
        };
      
    case filterConstants.BUILD_TABLE_FAILURE:
        const { error } = action;  
        return {
            ...state,            
          tableBuilt: false,
          tableLoading: false,
          error
        };
      
    
    case filterConstants.TOGGLE_GLOBAL_FILTER:
      return {
        ...state,
        expanded: !state.expanded
      };
    case filterConstants.GET_FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case filterConstants.GET_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      }
    case filterConstants.GET_FILTERS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }
    case filterConstants.CREATE_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case filterConstants.CREATE_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case filterConstants.CREATE_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case filterConstants.REMOVE_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case filterConstants.REMOVE_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        removeFilter: action.payload
      }
    case filterConstants.REMOVE_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        removeFilter: null
      }
    case filterConstants.UPDATE_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case filterConstants.UPDATE_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        updateFilter: action.payload
      }
    case filterConstants.UPDATE_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        updateFilter: null,
        error: action.payload
      }
    case filterConstants.GET_MAX_DATE_REQUEST:
        return {
          ...state,
        }
  case filterConstants.GET_MAX_DATE_SUCCESS:
    return {
      ...state,
      maxDate: action.date
    }
  case filterConstants.GET_MAX_DATE_ERROR:
    return {
      ...state,
      maxDate: null
    }
  
    default:
      return state
  }
}
