import React from "react";
var arrowLeftSvg = require('!svg-inline-loader!../../../public/img/arrow-left.svg');
var arrowRightSvg = require('!svg-inline-loader!../../../public/img/arrow-right.svg');

const buttons = {
  left: "left",
  right: "right",
};

class ScrollButtons extends React.Component {
  constructor(props) {
    super(props);
  }

  scrollButtonClick(e, id) {
    const { el } = this.props;
    const scrollStep = 50;
    const scrollPosition = el.scrollLeft;

    if (id === buttons.left) {
      scrollTo = scrollPosition - scrollStep;
    } else if (id === buttons.right) {
      scrollTo = scrollPosition + scrollStep;
    }

    el.scrollTo(scrollTo, 0);
  }

  render() {
    return (
      <div className="scroll-buttons-holder">
        <button
          type="button"
          id={buttons.left}
          onClick={(e) => this.scrollButtonClick(e, buttons.left)}
          dangerouslySetInnerHTML={{__html: arrowLeftSvg}}
          className="angle-button angle-button--left"
        >
        </button>
        <button
          type="button"
          id={buttons.right}
          onClick={(e) => this.scrollButtonClick(e, buttons.right)}
          dangerouslySetInnerHTML={{__html: arrowRightSvg}}
          className="angle-button angle-button--right"
        >
        </button>
      </div>
    );
  }
}

export default ScrollButtons;
