import { channelsConstants } from '../constants/channels';

export function channels(state = {}, action) {
    switch (action.type) {
        case channelsConstants.GET_CHANNEL_TYPES_REQUEST:
            return {
                ...state,
                listLoading: true
            };
        case channelsConstants.GET_CHANNEL_TYPES_SUCCESS:
            return {
                
                ...state,
                listLoading: false,
                items: action.channels,
            };
        case channelsConstants.GET_CHANNEL_TYPES_FAILURE:
            return {
                ...state,
                listLoading: false,
                error: action.error
            };
        case channelsConstants.ADD_CHANNEL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case channelsConstants.ADD_CHANNEL_SUCCESS:
            return {
                
                ...state,
                loading: false,
                channel: action.channel
            };
        case channelsConstants.ADD_CHANNEL_FAILURE:
            return {
                ...state,
                loading: false,
                channel: null
            };
            
        case channelsConstants.DELETE_CHANNEL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case channelsConstants.DELETE_CHANNEL_SUCCESS:
            return {
                
                ...state,
                loading: false,
            };
        case channelsConstants.DELETE_CHANNEL_FAILURE:
            return {
                ...state,
                loading: false,
            };
          
        default:
            return state
    }
} 