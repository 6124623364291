import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import config from '../../config/config';

export const notificationsService = {
    getNotificationsCount,
    getStorePlaylistNotifications,
    getHeartbeatsNotifications,
    updateReleaseNotifications,
    updateStorePlaylistNotifications,
    updateHeartbeatsNotifications,
    getReleaseNotifications
};

function getNotificationsCount(mode) {
    let baseUrl = `${config.apis.sales}/api/static/notifications.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getStorePlaylistNotifications(date) {
  const requestOptions = {
        method: 'GET',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    let baseUrl = `${config.apis.orthus}/v1/playlists/total_count?period_date=${date}`;
    return fetch(baseUrl, requestOptions)
    .then(response => {
      return response.json()
    })
    .catch( error => {
      return Promise.reject(error);
    });
}

function getHeartbeatsNotifications(date) {
  const options = {
    method: 'GET',
    headers: AuthHeader({'Content-Type': 'application/json'})
  };

  const url = `${config.apis.orthus}/v1/heartbeats/total_count?period_date=${date}`;
  return fetch(url, options)
  .then(response => {
    return response.json()
  })
  .catch( error => {
    return Promise.reject( error );
  })
}

function getReleaseNotifications(date) {
  const requestOptions = {
    method: 'GET',
    headers: AuthHeader({'Content-Type': 'application/json'})
  };

  const url = `${config.apis.orthus}/v1/new_releases/total_count?release_date=${date}`;
  return fetch(url, requestOptions)
  .then( response => {
    return response.json();
  })
  .catch( error => {
    return Promise.reject(error);
  });
}

function updateStorePlaylistNotifications(id, cb) {
  const options = {
    method: 'PUT',
    headers: AuthHeader({'Content-Type': 'application/json'}),
  };

  const url = `${config.apis.orthus}/v1/playlists/${id}`;

  return fetch(url, options)
  .then(response => {
    return response.json()
  })
  .catch( error => {
    return Promise.reject( error );
  })
}

function updateReleaseNotifications(id) {
  const requestOptions = {
    method: 'PUT',
    headers: AuthHeader({'Content-Type': 'application/json'}),
  };
  const url = `${config.apis.orthus}/v1/new_releases/${id}`;
  return fetch(url, requestOptions)
  .then( response => {
    return response.json();
  })
  .catch( error => {
    return Promise.reject( error );
  })
}

function updateHeartbeatsNotifications(id) {
  const requestOptions = {
    method: 'PUT',
    headers: AuthHeader(),
  };
  const url = `${config.apis.orthus}/v1/heartbeats/${id}`;
  return fetch(url, requestOptions)
  .then( response => {
    return response.json();
  })
  .catch( error => {
    return Promise.reject( error );
  })
}
