import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize } from 'lodash';
import CSV from '../../../helpers/CSVExport';
import { splitByThousands } from '../../../helpers/NumberFormatter';
import Box from '../../common/Box';
import LineChart from '../../widgets/charts/types/LineChart';
import VendorLogo from "../../common/VendorLogo";

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { audienceActions } from '../../../data/actions/audience';


class SharedChart extends React.Component {
    
    constructor(props) {
        super(props);
        this.exportToCsv = this.exportToCsv.bind(this);        
    }
    
    exportToCsv(){
        let { data: { data }, metric } = this.props,
            exportField;
        
        switch(metric) {
            case 'streams':
                exportField = 'total_streams';
                break;
            case 'ratio':
                exportField = 'listener_ratio';
                break;
            default:
                exportField = metric;
                
        }
        
        let fields = ["isrc", "artist",  "track_title", "first_released", "weeks_since_release", exportField];
        
        let exportData = data.map(item => {
            let resultItem = {};
            for(let field of fields) {
                resultItem[field] = item[field];
            }
            return resultItem;
        });

        const filename = CSV.CSVHeader(`track-comparison`, metric);
        return CSV.CSVExport(exportData, {filename}, 'track-comparison');
    }

    renderToolbar(){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }
        
    render (){  
        const { metric, data: {labels, datasets}, metadata, chartID = ''} = this.props;
        let chartData = {
            labels,
            datasets: []
        }
        
        for(let isrc of Object.keys(datasets)) {
            const track = find(metadata, (track)=>track.code == isrc),
                label = track ? track.name_compare : isrc;
            chartData.datasets.push({
                label,
                data: datasets[isrc][metric],
                fill: false
            })
        }
        
        const percentageChart = ['active', 'skipped'].includes(metric);
        const formatterCallback = percentageChart ? (value) => value + '%' : splitByThousands;
        const chartOptions = {
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        callback: function(value, index, values) {
                            return formatterCallback(value);
                        }
                    }
                }]
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return formatterCallback(tooltipItem.yLabel);
                    }
                }
            }
        };
        
        let title = '';
        if (metric == 'ratio') {
            title = 'Streams Per Listener'
        } else if (metric == 'active' || metric == 'skipped') {
            title = `${capitalize(metric)} %`
        } else {
            title = `${capitalize(metric)} Per Week`
        };
        // const title = metric == 'ratio' ? 'Streams Per Listener' : `${capitalize(metric)} per Week`;
        return <Box title={`Compare: ${title}`} toolbar={this.renderToolbar()} >
            {chartData.datasets.length > 0 && <div className="streams-audience-chart">
                <div className="chart-block">
                    <LineChart data={chartData} options={chartOptions} id={chartID} />
                </div>
                <p className="stream-audience-chart-text">Weeks Since Release</p>
            </div>}
            {!chartData.datasets.length && <p>No data available</p>}
        </Box>;
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getStreamsStats: (id, entity) => {
            return dispatch(audienceActions.getStreamsStats(id, entity))
        } 
    } 
}

export default SharedChart;