export const audienceConstants = {
    GET_AUDIENCE_STREAMS_STATS_REQUEST: 'GET_AUDIENCE_STREAMS_STATS_REQUEST',
    GET_AUDIENCE_STREAMS_STATS_SUCCESS: 'GET_AUDIENCE_STREAMS_STATS_SUCCESS',
    GET_AUDIENCE_STREAMS_STATS_FAILURE: 'GET_AUDIENCE_STREAMS_STATS_FAILURE',
    GET_AUDIENCE_DEMOGRAPHICS_STATS_REQUEST: 'GET_AUDIENCE_DEMOGRAPHICS_STATS_REQUEST',
    GET_AUDIENCE_DEMOGRAPHICS_STATS_SUCCESS: 'GET_AUDIENCE_DEMOGRAPHICS_STATS_SUCCESS',
    GET_AUDIENCE_DEMOGRAPHICS_STATS_FAILURE: 'GET_AUDIENCE_DEMOGRAPHICS_STATS_FAILURE',
    GET_AUDIENCE_TERRITORY_STATS_REQUEST: 'GET_AUDIENCE_TERRITORY_STATS_REQUEST',
    GET_AUDIENCE_TERRITORY_STATS_SUCCESS: 'GET_AUDIENCE_TERRITORY_STATS_SUCCESS',
    GET_AUDIENCE_TERRITORY_STATS_FAILURE: 'GET_AUDIENCE_TERRITORY_STATS_FAILURE',
    GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_REQUEST: 'GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_REQUEST',
    GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_SUCCESS: 'GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_SUCCESS',
    GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_FAILURE: 'GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_FAILURE',    
    GET_AUDIENCE_SOURCE_STATS_REQUEST: 'GET_AUDIENCE_SOURCE_STATS_REQUEST',
    GET_AUDIENCE_SOURCE_STATS_SUCCESS: 'GET_AUDIENCE_SOURCE_STATS_SUCCESS',
    GET_AUDIENCE_SOURCE_STATS_FAILURE: 'GET_AUDIENCE_SOURCE_STATS_FAILURE',
    GET_AUDIENCE_PLAYLIST_STATS_REQUEST: 'GET_AUDIENCE_PLAYLIST_STATS_REQUEST',
    GET_AUDIENCE_PLAYLIST_STATS_SUCCESS: 'GET_AUDIENCE_PLAYLIST_STATS_SUCCESS',
    GET_AUDIENCE_PLAYLIST_STATS_FAILURE: 'GET_AUDIENCE_PLAYLIST_STATS_FAILURE',
    GET_AUDIENCE_TOP_PLAYLIST_STATS_REQUEST: 'GET_AUDIENCE_TOP_PLAYLIST_STATS_REQUEST',
    GET_AUDIENCE_TOP_PLAYLIST_STATS_SUCCESS: 'GET_AUDIENCE_TOP_PLAYLIST_STATS_SUCCESS',
    GET_AUDIENCE_TOP_PLAYLIST_STATS_FAILURE: 'GET_AUDIENCE_TOP_PLAYLIST_STATS_FAILURE',    
    GET_AUDIENCE_REPEAT_LISTENERS_REQUEST: 'GET_AUDIENCE_REPEAT_LISTENERS_REQUEST',
    GET_AUDIENCE_REPEAT_LISTENERS_SUCCESS: 'GET_AUDIENCE_REPEAT_LISTENERS_SUCCESS',
    GET_AUDIENCE_REPEAT_LISTENERS_FAILURE: 'GET_AUDIENCE_REPEAT_LISTENERS_FAILURE',
        
    
    
}