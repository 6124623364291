import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import config from '../../config/config';
import objectToFormData from 'object-to-formdata';

export const whiteLabelService = {
    loadDomainConfig,
    getWhiteLabelData,
    submitWhiteLabelData
};

function getWhiteLabelData(silo) {
    const requestOptions = {
        method: 'GET',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    let baseUrl = `${config.apis.orthus}/v1/white_label/${silo}`;
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        return res.ok ? res.json() : Promise.reject(res)
    })
    .catch(ex=>Promise.reject(ex))
}

function submitWhiteLabelData(silo, params, requestType = 'POST') {
    const urlPart = requestType === 'POST' ? 'add' : 'modify';
    const baseUrl = `${config.apis.orthus}/v1/white_label/${urlPart}/${silo}`;
    const options = {
      indices: false,
      nullsAsUndefineds: false,
    };
    const existingFormData = new FormData();
    for ( let fileName in params.files ) {
      if(params.files[fileName] instanceof File){
        params.files[fileName].original_filename = fileName;
        existingFormData.append(fileName, params.files[fileName]);
      }
    }
    const propertiesData = {"properties": params };
    const formData = objectToFormData(propertiesData , options, existingFormData);
    const requestOptions = {
      method: requestType,
      body: formData
    };
    return fetch(baseUrl, requestOptions)
    .then(res => res.json())
    .catch( error => error.json())
}

function loadDomainConfig() {
    const requestOptions = {
        method: 'GET',
    };

    let baseUrl = `${config.apis.assets}/custom/config.json`; 
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        return res.ok ? res.json() : Promise.reject(res)
    })
    .catch(ex=>Promise.reject(ex))
}
