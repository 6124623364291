import React, { Component } from 'react';
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {  isEqual, find, debounce } from 'lodash';
import {Helmet} from 'react-helmet';
import { usersSettings } from '../../../components/pages/users/Settings';

import { usersActions } from '../../../data/actions/users';


class UsersList extends Component {
    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
        this.refreshList = debounce(this.refreshList, 30000);
    }
    
    componentDidMount() {
        this.props.dispatch(usersActions.getUsers());
    }
    
    refreshList() {
        this.props.dispatch(usersActions.getUsers());
    }
    
    onDelete(email) {
        this.props.dispatch(usersActions.deleteUser(email));
    }
    
    componentWillReceiveProps(nextProps) {
        const hasACLSaving = find(nextProps.users.items, {acl_saving: true});
        if(hasACLSaving){
            this.refreshList();
        }
    }

    
    
    render() {
        const { items, listLoading } = this.props.users;
        return (
            <div className="wrapper wrapper-content light-blue-bg users-management">
                <Link className="btn default-btn add-user-btn" to="/admin/users/new"><i className="fas fa-plus"></i>Create User</Link>
                <ReactTable  
                    loading={listLoading}
                    className='catalogue-table-holder user-management-table'
                    data={items}          
                    defaultPageSize={10}
                    filterable
                    defaultFilterMethod={(filter, row) => {return String(row[filter.id]).match(new RegExp(filter.value, 'i'))}}
                    sorted={[{id: 'invitation_sent_at', desc: true}]}
                    columns={usersSettings.settingsForTable(this.onDelete)}
                    showPagination={true}
                /> 
                <Helmet>
                    <title>User Management - Admin</title>
                </Helmet>

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        users: state.users
    } 
}

export default connect(mapStateToProps)(UsersList);
