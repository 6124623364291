import React from 'react';
import Modal from './Modal';

class ConfirmClick extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showRevokeModal: false
        }
    }
    
    showModal(modal, show){
        this.setState({
            [modal]: show
        })
    }
    
    render() {
        const {className, cancelClass, confirmClass, cancelLabel=<i className="fas fa-times" />, confirmLabel, confirmAction, confirmPrompt='Are you sure?'} = this.props;
        
        return <p className="confirm-click-holder">
            <Modal show={this.state.showRevokeModal}  
                size=""
                title=""
                submitTitle="OK"
                handleSubmit={(e)=>{confirmAction(e); this.showModal('showRevokeModal', false)}}
                handleClose={()=>this.showModal('showRevokeModal', false)}
                dialogClassName="confirm-modal-holder dialog-modal"
            >
            <span className="popup-confirm-text">{confirmPrompt}</span>
            </Modal>
            <a className={`confirm ${confirmClass}`} onClick={()=>this.showModal('showRevokeModal', true)}>{confirmLabel}</a>
        </p>;
    }
}

export default ConfirmClick