import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import {Helmet} from 'react-helmet';
import { isEqual, sumBy } from 'lodash';
import { playlistsActions } from '../../data/actions/playlists';
import { playlistsFormatter } from '../../data/formatters/playlists'
import { playlistDetailsSettings } from '../../components/pages/playlists/details/Settings'
import EntitiesList from '../../components/pages/home/EntitiesList';
import LineChart from '../../components/widgets/charts/types/LineChart';
import TerritoryStats from '../../components/common/Stats/Territory';
import DemographicsStats from '../../components/common/Stats/Demographics';
import DevicesStats from '../../components/common/Stats/Devices';
import SourcesStats from '../../components/common/Stats/Sources';
import StreamsStatsWidget from '../../components/common/Stats/StreamsWidget';
import TagsList from '../../components/pages/playlists/TagsList';
import VendorLogo from '../../components/common/VendorLogo';
import Benchmarks from '../../components/pages/playlists/Benchmarks';
import PlaylistShare from '../../components/pages/playlists/PlaylistShare';
import PlaylistTracks from '../../components/pages/playlists/PlaylistTracks';

import { convertToThousands } from '../../helpers/NumberFormatter';

class PlaylistDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
        };
    }
    
    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({id: match.params.id});
        dispatch(playlistsActions.getPlaylistStats(match.params.id));
    }

    
    componentWillReceiveProps(nextProps){
        const { dispatch } = this.props;
        if(nextProps.filter){
            if(nextProps.filter.global){
                if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                    dispatch(playlistsActions.getPlaylistStats(this.state.id));
                }                
            }
        }
    }
    
    render() {
        if(!this.state.id)
            return null;

        const playlist = this.props.playlists.stats[this.state.id] || {};
        const {data: playlistTracks} = this.props.tracks.topPlaylist[this.state.id] || [];
        
            let metaTitle = playlist ? `${playlist.short_name} - ` : '';
            metaTitle += 'Playlist Details';
            
            const skippedRatio = playlistTracks ? sumBy(playlistTracks, 'skipped_ratio') /  playlistTracks.length : null;

            return <div className="wrapper wrapper-content light-blue-bg playlist-details">                
                    <div className="row">
                        <div className="col-xs-12 playlist-details-title">
                            {playlist.id && <div className="playlist-holder column ibox-content section-header">
                                <a href={`/playlists/${playlist.id}/overview`}>
                                    <div className="playlist-image" style={{backgroundImage: `url(${playlist.image})`}}></div>
                                </a>
                                <div className="playlist-holder-inner">
                                    
                                    <div className="playlist-info">
                                        <div className="playlist-info-item">
                                            <span className="playlist-info-title">{playlist.short_name}</span>
                                        </div>
                                        <div className="playlist-info-item">
                                            <span className="playlist-info-title">Followers: {convertToThousands(playlist.followers_on_last_check)}</span>
                                        </div>
                                    </div>
                                    <div className="playlist-info">
                                        <div className="playlist-info-item">
                                            <span className="playlist-info-title">Average track streams per day: {playlist.avg_track_streams_per_day}</span>
                                        </div>
                                    </div>
                                    { skippedRatio && <div className="playlist-info">
                                        <div className="playlist-info-item">
                                            <span className="playlist-info-title">Average skip ratio: {skippedRatio}%</span>
                                        </div>
                                    </div> }                         
                                    <div className="playlist-info">
                                        <div className="playlist-info-item playlist-info-item--tags">
                                            {playlist.pl_tags && <span className="playlist-info-title">Tags:</span>}
                                            <TagsList tags={playlist.pl_tags} />
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>  
                    <div className="row">
                        <div className="col-xs-12">
                            {/*
                            <EntitiesList defaultView="table" title="Top Playlist Tracks" entity="tracks_in_playlist" parentEntity="playlists" parentEntityIDs={this.state.id} />
                            */}
                            <PlaylistTracks playlistID={this.state.id} mode="details"/>
                        </div>                        
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-xs-12">

                            <PlaylistShare id={this.state.id} />
                            
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12">                    
                            <DemographicsStats entity="playlists" ids={this.state.id} parentEntityTitle={playlist.playlist_name} />
                        </div>                        
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-xs-12 territory-details">
                            <TerritoryStats entity="playlists" ids={this.state.id} parentEntityTitle={playlist.playlist_name} />
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12">
                            <DevicesStats entity="playlists" ids={this.state.id}  parentEntityTitle={playlist.playlist_name} />
                        </div>                        
                    </div>      
                    <Helmet>
                        <title>{metaTitle}</title>
                    </Helmet>                                    
                    {/*
                        <SourcesStats entity="playlists" ids={playlist.playlist_id} />
                    */}
                
            </div>;
    }
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        playlists: state.playlists,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(PlaylistDetails)