import React from 'react';
import { map } from 'lodash';
import AbsoluteValue from '../../AbsoluteValue';
import CountryFlag from '../../CountryFlag';
import { percentageValue, splitByThousands } from '../../../../helpers/NumberFormatter';
import Tooltip from '../../Tooltip';

export const territorySettings = {
    settingsForTable
};


function settingsForTable(mode){
    
    let columns = [
        {
            id: 'logo',
            className: 'logo-sell',
            headerClassName: 'logo-sell',
            Header: '',
            accessor: 'name',
            width: 40,
            Cell: props => {
                return <CountryFlag className="flag-medium" country={props.original.code} />
         }
      },
      {
          id: 'name',
          className: 'table-text-left', 
          headerClassName: 'table-text-left',
          Header: 'Country',
          accessor: 'name',
          Cell: props => <span>{props.value}</span>
       //    width: 190
      },      
      {
            id: 'physial_income',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            Header: props => <span>Physical Income <br /> Physical Qty</span>,
            accessor: 'physical_income',
            Cell: props => <span>{splitByThousands(props.value, '$')}<br /> {splitByThousands(props.original.physical_quantity)}</span>
        },       
      
      {
          id: 'digital_income',
          className: 'table-text-right', 
          headerClassName: 'table-text-right no-sort',
          Header: props => <span>Digital Income <br /> Digital Qty</span>,
          accessor: 'digital_income',
          Cell: props => <span>{splitByThousands(props.value, '$')}<br /> {splitByThousands(props.original.digital_quantity)}</span>
      },
      {
          id: 'nr_income',
          className: 'table-text-right', 
          headerClassName: 'table-text-right no-sort',
          Header: props => <span>NR Income <br /> NR Qty</span>,
          accessor: 'nr_income',
          Cell: props => ( props.value ? <span>{splitByThousands(props.value, '$')}<br /> {splitByThousands(props.original.nr_quantity)}</span> : <span>-</span> )
      },   
      {
            id: 'total_income',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            Header: props => <span>Total Income <br /> Total Qty</span>,
            accessor: 'total_income',
            Cell: props => <span>{splitByThousands(props.value, '$')}<br /> {splitByThousands(props.original.total_quantity)}</span>
        },
        {
            id: 'share',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            footerClassName: 'table-text-right',
            Header: props => <span>Total Income<br /> Share</span>,
            // Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
            // width: 70,
            accessor: 'share',
            Cell: props => <span>{props.value}%</span>,
        },        
          


    ];
    return columns;
}