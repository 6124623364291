import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import {Helmet} from 'react-helmet';
import { Link as LiveLink } from 'react-router-dom';
import ReadonlyLink from '../../components/pages/home/ReadonlyLink';
import { pdf as PDFRenderer }from '@react-pdf/renderer';

import Box from '../../components/common/Box';
import { tracksActions } from '../../data/actions/tracks';
import TransactionTypes from '../../components/common/Stats/TransactionTypes';
import EntityCard from '../../components/common/EntityCard';
import EntityFilterName from '../../components/common/EntityFilterName';
import EntitiesList from '../../components/pages/home/EntitiesList';
import TerritoryStats from '../../components/common/Stats/Territory';
import DemographicsStats from '../../components/common/Stats/Demographics';
import DevicesStats from '../../components/common/Stats/Devices';
import SourcesStats from '../../components/common/Stats/Sources';
import VendorsWidget from '../../components/pages/home/VendorsWidget';
import SharePage from '../../components/pages/sharedPages/SharePage';
import CSV from '../../helpers/CSVExport';
import { dspLogos } from "../../components/common/Stats/utils";
import PDFDocument from '../../components/pages/tracks/PDF';

const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');

class TrackDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
        };
        this.setParentEntity = this.setParentEntity.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({id: match.params.id}); //, name: match.params.name
    }

    componentWillReceiveProps(nextProps){
        const { match } = nextProps;
        if(match.params.id != this.state.id) {
            this.setState({id: null}, ()=>{
                this.setState({id: match.params.id}); //, name: match.params.name
            });
        }
        
    }

    setParentEntity(entity) {
        this.setState({
            name: entity.name_original,
            isrc: entity.code,
            artist: entity.primary_artist.name,
            artistId: entity.primary_artist.id,            
            product: entity.product,
            barcode: entity.barcode,
            released: entity.release_date
        })
    }

    exportToCsv = () => {
      const multipleMode = this.state.id.includes(',');
      const {
        stats,
      } = this.props;

      const data = [
        { platforms: stats.vendor.table },
        { territories: stats.territory.table },
        { transactions: stats.transactions[this.state.id] }
      ];
      
      
      const filename = CSV.CSVHeader('track_details', this.state.id, this.props.filter.global);

      CSV.CSVBulkExport(data, filename);

    }
    
    getPdfData() {
        const reportTitle = 'Track Details',
            { stats } = this.props,
            { id } = this.state;
            
        const imageIDs = [
              'top_territories_pie_chart',
              'top_vendors_pie_chart',
        ];
        let images = {};
        for (let imageID of imageIDs){
            let image = document.getElementById(imageID);
            if(image)
                images[imageID] = image.toDataURL("image/jpeg,1.0"); 
        }
        
        let vendorTableData = [];

        if (this.props.stats.vendor)
            vendorTableData = this.props.stats.vendor.table
            
        const { name, artist = 'Unlisted Artist', artistId, isrc, product, barcode, released } = this.state;
        
        const overview = [
            {title: "Track", value: name},
            {title: "Artist", value: artist},
            {title: "Product", value: product},
            {title: "ISRC", value: isrc},
            {title: "First Released", value: released}
        ]

        const tables = {
            territories: stats.territory.table,
            transactions: stats.transactions[id],
            vendorsTable: vendorTableData,
            overview
        };
        return { reportTitle, images, tables, overview: stats.top, globalFilter: this.props.filter.global };
    }
    
    exportToPdf() {
        const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
        data.toBlob()
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "track_details.pdf";
            link.click();
        });
    }
    


    render() {
        if(!this.state.id)
            return null;
        
        const multipleMode = this.state.id.includes(',');
        
        const { name, artist = 'Unlisted Artist', artistId, isrc, product, barcode, released } = this.state;
        
        let metaTitle = name ? `${name} - ` : '';
        metaTitle += 'Details';
        
        const { sharedMode } = this.props;
        const Link = !sharedMode ? LiveLink : ReadonlyLink;

        return <div className="track-details artist-profile homepage">
            <div className="container">
                <h2 className="main-title">Track details</h2>
                <div className="row download-page download-page--mt">
	                <span>Export the data</span>
	                {sharedMode &&  <a key="download" title="Download entire page" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />}
                    {!sharedMode && <SharePage entity="tracks" id={this.state.id} exportToCsv={this.exportToCsv} exportToPdf={this.exportToPdf} CSVEntities={["territories", "top_vendors"]}/>}
	            </div>                
                <div className="main-artist-holder">
                    <div className="main-artist-name-holder">
                        <div className="main-artist-image">
                            <EntityCard entity="tracks" id={this.state.id} onResult={this.setParentEntity} showLink={true} />
                        </div>
                        <h3 className="middle-title">{name || `${this.state.id} - Unlisted Catalogue`}</h3>
                    </div>
                    <div className="main-artist-details">
                        <div className="main-artist-details-item">
                            <div className="main-artist-details-title">Artist</div>
                            <div className="main-artist-details-value"><Link to={`/artists/${artistId}`}>{artist}</Link></div>
                        </div>
                        <div className="main-artist-details-item">
                            <div className="main-artist-details-title">Product</div>
                            <div className="main-artist-details-value"><Link to={`/products/${barcode}`}>{product}</Link></div>
                        </div>
                        <div className="main-artist-details-item">
                            <div className="main-artist-details-title">ISRC</div>
                            <div className="main-artist-details-value">{isrc}</div>
                        </div>
                        <div className="main-artist-details-item">
                            <div className="main-artist-details-title">First Released</div>
                            <div className="main-artist-details-value">{released}</div>
                        </div>
                    </div>
                </div>
                   
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <TransactionTypes entity="tracks" id={this.state.id} />
                    </div>
                </div>                        
                
                {/* <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <EntitiesList trends={true} title="Top Artists" entity="artists" parentEntity="tracks" parentEntityIDs={this.state.id} parentEntityTitle={this.state.title} />    
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <EntitiesList trends={true} title="Top Products" entity="products" parentEntity="tracks" parentEntityIDs={this.state.id} parentEntityTitle={this.state.title} />
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <TerritoryStats entity="tracks" ids={this.state.id} parentEntityTitle={this.state.title} chartType={dspLogos.territories.id} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <VendorsWidget entity="tracks" id={this.state.id} parentEntityTitle={this.state.title} />
                    </div>
                </div>
                <Helmet>
                    <title>{metaTitle}</title>
                </Helmet>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        filter: state.filter,
        stats: state.stats,
        products: state.products,
        tracks: state.tracks,
        playlists: state.playlists
    }
}

export default connect(mapStateToProps)(TrackDetails)
