import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';

import { notificationReportsActions } from '../../data/actions/notification_reports';
import { productsActions } from '../../data/actions/products';
import NotificationReport from './NotificationReport';
import Spinner from '../../components/common/Spinner';

const PERMIT_FIELDS = ['id', 'report_id', 'status', 'frequency', 'settings'];
const SEPARATOR = ',';
const KEYS_OF_SETTINGS = ['release_weeks', 'threshold', 'avg_streams'];
const HIDDEN_REPORTS = ["Track Activity"];
export class NotificationSettings extends React.Component {
  state = {
    reports: [],
    submitedData: {release_weeks: {}, threshold: {}, avg_streams: {}},
    productsList: [],
    showDropdown: false,
    inputValue: ''
  }

  componentDidMount(){
    const {
      getNotificationReports,
      getUserNotificationSettings,
      getFrequencies,
    } = this.props;

    getUserNotificationSettings();
    getNotificationReports();
    getFrequencies();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      updateUserNotification,
      getUserNotificationSettings,
      getNotificationReports
    } = this.props;

    Object.keys(this.state.submitedData).forEach( notification => {
      this.state.submitedData[notification].id !== undefined && updateUserNotification(this.state.submitedData[notification], getNotificationReports());
    });
  }

  formatSetting = setting => {
    let formatedSetting = Object.assign({}, setting);
    Object.keys(formatedSetting).forEach( key => {
      if(!PERMIT_FIELDS.includes(key)){
        delete formatedSetting[key];
      }
    })
    return formatedSetting;
  }

  assignNewSettingValue = (setting, entityValue, entityName, settingName=null) => {
    let updatedSetting = Object.assign({}, setting);
    if(settingName){
      updatedSetting[entityName][settingName] = parseInt(entityValue);
    } else {
      updatedSetting[entityName] = parseInt(entityValue);
    }
    return updatedSetting;
  }

  handleChangeSetting = (e, setting, settingName, entityName) => {
    let currentSetting = Object.assign({}, setting);
    currentSetting = this.formatSetting(currentSetting);
    currentSetting = this.assignNewSettingValue(currentSetting, e.target.value, entityName, settingName);
    
    const submitedData = Object.assign(this.state.submitedData, { [e.target.name]: currentSetting });
    this.setState({
      submitedData
    });
  }

  findSetting = i => {
    return this.props.reports.settings.find(setting => {
        let key = Object.keys(setting.settings).reverse()[0];
        if(key=='minimum_streams')
            key = 'threshold';
        
      return  key === KEYS_OF_SETTINGS[i]
    })
  }

  onClickItem = (e, item, entity, setting, reportName) => {
    this.setState({
      showDropdown: false,
      inputValue: ''
    });

    const entityName = `${entity}List`;
    const currentCollection = this.state[entityName].length !== 0 ? this.state[entityName] : [];
    const dublicateItem = currentCollection.some( entity => entity.id === item._source.id);

    if(dublicateItem){
      return
    }

    let currentSetting = Object.assign({}, setting);

    currentSetting = this.formatSetting(currentSetting);
    currentSetting.settings[entity] = currentSetting.settings[entity].split(SEPARATOR);
    currentSetting.settings[entity].push(item._source.barcode);
    currentSetting.settings[entity] = currentSetting.settings[entity].join(SEPARATOR);

    const submitedData = Object.assign(this.state.submitedData, { [`${reportName}`]: currentSetting });

    const { updateUserNotification, getUserNotificationSettings, getProducts } = this.props;

    updateUserNotification(submitedData[reportName], getProducts(currentSetting.settings[entity]));
  }

  removeItem = (e, item, entity, setting, reportName) => {
    this.setState({showDropdown: false});

    let currentSetting = Object.assign({}, setting);
    currentSetting = this.formatSetting(currentSetting);

    let settingEntity = currentSetting.settings[entity];
    settingEntity = settingEntity
    .split(SEPARATOR)
    .filter(barcode => barcode !== item.product.barcode)
    .join(SEPARATOR);

    currentSetting.settings[entity] = settingEntity;

    const submitedData = Object.assign(this.state.submitedData, { [`${reportName}`]: currentSetting });

    const { updateUserNotification, getProducts, getUserNotificationSettings } = this.props;
    updateUserNotification(submitedData[reportName], getProducts(currentSetting.settings[entity]));
  }

  onChangeEntity = value => {
    this.setState( {
      showDropdown: true,
      inputValue: value
    });
  }

  render(){
    const { reports } = this.props;
    const filteredReports = reports.data.filter( report => !HIDDEN_REPORTS.includes(report.title));
    return(
      <form onSubmit={this.handleSubmit}>
        <div className="wrapper wrapper-content">
          <div className="NotificationReportHeader">
            <h3> Set up the emails you will receive for different reports </h3>
            <button type="submit" className="chat-submit"><i className="fas fa-save"></i></button>
          </div>
          <Spinner enabled={reports.loading} />
          {
            filteredReports.map( (report, index) => {
              return <NotificationReport
                        inputValue={this.state.inputValue}
                        onChangeSetting={this.handleChangeSetting}
                        onClickItem={this.onClickItem}
                        onChangeEntity={this.onChangeEntity}
                        onRemoveItem={this.removeItem}
                        key={index}
                        frequencies={reports.frequencies}
                        setting={this.findSetting(index)}
                        title={report.title}
                        reportID={report.id}
                        description={report.description}
                        showDropdown={this.state.showDropdown}
                        products={this.props.products}
                      />
            })
          }
        </div>
        <Helmet>
            <title>Notifications - User Preferences</title>
        </Helmet>
        
      </form>
    )
  }
};

function mapDispatchToProps(dispatch){
  return {
    getNotificationReports: () => dispatch(notificationReportsActions.getNotificationReports()),
    getProducts: barcodes => dispatch(productsActions.getProductsByBarcode(barcodes)),
    getUserNotificationSettings: () => dispatch(notificationReportsActions.getUserNotificationSettings()),
    getFrequencies: () => dispatch(notificationReportsActions.getFrequencies()),
    updateUserNotification: (notification, cb) => dispatch(notificationReportsActions.updateUserNotification(notification, cb))
  };
}

function mapStateToProps( state ) {
  return {
    products: state.products.data,
    reports: state.notification_reports
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
