import { audienceConstants } from '../constants/audience';

export function audience(state = {demographicsTerritory: {}}, action) {
    switch (action.type) {
        case audienceConstants.GET_AUDIENCE_STREAMS_STATS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case audienceConstants.GET_AUDIENCE_STREAMS_STATS_SUCCESS:
            return {
                
                ...state,
                loading: false,
                timeline: action.data,
                streams_listeners: action.audience
            };
        case audienceConstants.GET_AUDIENCE_STREAMS_STATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_REQUEST:
            return {
                ...state,
                demographicsLoading: true
            };
        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_SUCCESS:
            return {
                
                ...state,
                demographicsLoading: false,
                demographics: action.data
            };
        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_FAILURE:
            return {
                ...state,
                demographicsLoading: false,
                error: action.error
            };
        case audienceConstants.GET_AUDIENCE_TERRITORY_STATS_REQUEST:
            return {
                ...state,
                territoryLoading: true
            };
        case audienceConstants.GET_AUDIENCE_TERRITORY_STATS_SUCCESS:
            return {
                
                ...state,
                territoryLoading: false,
                territory: action.data
            };
        case audienceConstants.GET_AUDIENCE_TERRITORY_STATS_FAILURE:
            return {
                ...state,
                territoryLoading: false,
                error: action.error
            };          

        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_REQUEST:
            return {
                ...state,
                demographicsTerritoryLoading: true
            };
        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_SUCCESS:
            const {data, territory} = action;
            return {
                
                ...state,
                demographicsTerritoryLoading: false,
                demographicsTerritory: {
                    ...state.demograhicsTerritory,
                    [territory]: data
                }
            };
        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_FAILURE:
            return {
                ...state,
                demographicsTerritoryLoading: false,
                error: action.error
            };
            
        case audienceConstants.GET_AUDIENCE_SOURCE_STATS_REQUEST:
            return {
                ...state,
                sourceLoading: true
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_STATS_SUCCESS:
            return {
                
                ...state,
                sourceLoading: false,
                source: action.data
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_STATS_FAILURE:
            return {
                ...state,
                sourceLoading: false,
                error: action.error
            };          

        case audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_REQUEST:
        case audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_REQUEST:            
            return {
                ...state,
                playlistLoading: true
            };
        case audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_SUCCESS:
        case audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_SUCCESS:
            return {
                
                ...state,
                playlistLoading: false,
                playlist: action.data
            };
        case audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_FAILURE:
        case audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_FAILURE:
            return {
                ...state,
                playlistLoading: false,
                error: action.error
            };          
            
        case audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_REQUEST:
            return {
                ...state,
                repeatListenersLoading: true
            };
        case audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_SUCCESS:
            return {
                
                ...state,
                repeatListenersLoading: false,
                repeatListeners: action.data
            };
        case audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_FAILURE:
            return {
                ...state,
                repeatListenersLoading: false,
                error: action.error
            };          
            
        default:
            return state
    }
} 