import React from 'react';
import {View, Text, StyleSheet, Svg } from '@react-pdf/renderer';
import { convertToThousands } from '../../../helpers/NumberFormatter';

const styles = StyleSheet.create({
    overviewBox: {
        flexDirection: 'column',
        padding: 10
    },
    img: {
        width: 20,
        height: 20
    },
    title: {
        fontSize: 8,
        marginBottom: 20
    }
});

const OverviewBox = ({item}) => {
    return <View style={styles.overviewBox}>
        {/*<Svg class={styles.img}>{item.icon}</Svg> not working*/ }
        <Text style={styles.title}>{item.title}</Text>
        <Text>{item.value}</Text>
    </View>
};
  
export default OverviewBox