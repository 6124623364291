import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { map, sumBy } from 'lodash';
import { Link } from 'react-router-dom';
import Spinner from '../../components/common/Spinner';
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { importsActions } from '../../data/actions/imports';
import { pendingStatementsSettings } from '../../components/pages/imports/pendingStatements/Settings';
import CSV from '../../helpers/CSVExport';

var deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');

class PendingStatements extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.exportToCsv = this.exportToCsv.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
    }
    
    componentDidMount() {
        this.props.dispatch(importsActions.getPendingStatements());
    }
    
    approve(period){
        this.props.dispatch(importsActions.approveSalesData(period)).then(()=>this.props.history.push('/import/statement-periods'));
    }
    
    reject(period){
        this.props.dispatch(importsActions.rejectSalesData(period)).then(()=>this.props.history.push('/import/statement-periods'));
    }

    
    exportToCsv(period){
        let {statements} = this.props.imports;
        let exportData = statements.filter(row=>row.period == period);
        let totalRow = {
                period,
                label: 'Total',
                digital_income: sumBy(exportData, 'digital_income'),
                physical_income: sumBy(exportData, 'physical_income'),
                total_income: sumBy(exportData, 'total_income')
        };
        exportData.push(totalRow);
        const filename = CSV.CSVHeader('pending_statements', period);
        return CSV.CSVExport(exportData, {filename}, 'pending_statements');
    }
    
    render() {
        const { statementsLoading, statements, approveRejectLoading} = this.props.imports,
            rows = Array.isArray(statements) ? statements.length : null;
        
        return <div className="inner-page shared-pages">
            <div className="container">
                <div className="import-menu import-menu-exceptions">
                    <h5>Statements</h5>
                    <ul>
                        <li><Link to={`/import/statement-periods`}>All Periods</Link></li>
                        <li><Link to={`/import/pending-statements`}>Pending Statements</Link></li>
                    </ul>
                    
                </div>
            
                <h2>Pending Statements</h2>
                <div className="ibox">
                    <ReactTable
                        data={statements}
                        loading={statementsLoading}
                        columns={pendingStatementsSettings.settingsForTable(this.exportToCsv, this.approve, this.reject)}
                        pageSize={rows ? Math.min(rows, 10): 10}
                        showPagination={rows > 10}
                        pivotBy={['period']}
                        className="simple-table share-table"
                    />
                    <Spinner enabled={approveRejectLoading} /> 
                    
                    {/* {selectedRows.length > 0 && <button className="default-btn default-btn--small default-btn--light delete-selected" onClick={this.deleteSelectedPages}>Delete Selected</button>} */}

                </div>
            </div>
            <Helmet>
                <title>Pending Statements</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        imports: state.imports
    } 
}

export default connect(mapStateToProps)(PendingStatements)