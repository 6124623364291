import React from 'react';
import { connect } from 'react-redux';
import { entityFiltersActions } from '../../data/actions/entity_filters';
import { filterActions } from '../../data/actions/filter';
import PageFilter from './Datagrid/PageFilter';

import store from '../../helpers/store';

const getFilterName = (filter) => {
    const { name } = filter;
    return name;
    //return name == 'No filter' ? 'Current Filter' : name; 
}


class EntityFilter extends React.Component {

  constructor(props){
    super(props);

    let defaultState = this._defaultState();

    let currentEntityFilter = store.get(props.entity);
    if(currentEntityFilter === undefined || Object.keys(currentEntityFilter).length === 0){
      currentEntityFilter = defaultState.filter;
    }

    defaultState.filter = currentEntityFilter;

    this.state = defaultState;
  }

  _defaultState = () => {
    return {
      filter: { name: 'No filter', body: { key: 'No filter', value: []} },
      showList: false
    }
  }

  loadUnsavedFilter = () => {
    let currentEntityFilter = store.get(this.props.entity);
    const defaultFilter = this._defaultState().filter;
    if(currentEntityFilter === undefined || currentEntityFilter.name === undefined || currentEntityFilter.name !== defaultFilter.name){
      currentEntityFilter = defaultFilter;
    }
    return currentEntityFilter;
  }

  showEntityFilterList = () => { this.setState({ showList: !this.state.showList }) };

  componentDidMount = () => {
    this.props.onGetEntityFilters(this.props.entity);
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.entityLoaded !== this.props.entityLoaded){
      this.props.setFilter(this.props.entity, this.state.filter.body.value);
    }
  }

  handleFilterClick = filter => {
    this.loadFilter(filter);
    this.props.setFilter(this.props.entity, filter.body.value);
    store.set(this.props.entity, filter);
    this.showEntityFilterList();
  }

  loadFilter = filter => {
    this.setState({ filter });
  }

  toggleIsShared = e => {
    const filter = Object.assign({}, this.state.filter);
    filter.is_shared = e.target.checked;

    this.setState({ filter }, () => {
      this.props.onGetEntityFilters(this.props.entity);
      this.props.onUpdateEntityFilter(this.state.filter, this.props.entity);
      store.set(this.props.entity, this.state.filter);
    });
  }
  
  getFilterLength = exclude => {
      const entity = this.props.entity.replace('exclude_', ''),
        currentFilterKey = exclude ? 'exclude_'+entity : entity,
        currentFilter = store.get(currentFilterKey);

      if(currentFilter && Array.isArray(currentFilter.body.value))
          return currentFilter.body.value.length;
      else
          return 0;      
  }
  

  render(){
    const { loaded } = this.props;
    const defaultFilter = this._defaultState().filter;
    const tempFilter = this.state.filter.name === defaultFilter.name;
    const userFilter = this.state.filter.user_id === this.props.user.id;
    const { exclude, toggleExclude } = this.props;
    const filterKey = this.props.entity,
        entityKey = filterKey.replace('exclude_', ''); 

    return <div className="entityFilters">
        <div className="selected-entities">
          <React.Fragment>
            <div className="entity-filters-action">
              <span className="filter-name">{this.props.label} Filters  </span>
              <div>
                <div className="show-filters-holder">
                  <span className="showEntityFiltersList" onClick={this.showEntityFilterList}>{ getFilterName(this.state.filter) } <i class={`fas fa-caret-${this.state.showList ? 'up' : 'down' }`}></i></span>
                  { this.state.showList && <FiltersList onLoadUnsavedFilter={this.loadUnsavedFilter} entity={filterKey} handleItemClick={this.handleFilterClick} filters={this.props.entityFilters[filterKey]} />}
                </div>
                { (!tempFilter && this.props.user.client_admin && userFilter ) && (
                  <React.Fragment>
                    <div className="checkbox-holder checkbox-holder--block">
                      <input onClick={this.toggleIsShared} type="checkbox" className="input" checked={this.state.filter.is_shared} id={`filter_${this.state.filter.id}`} />
                      <label htmlFor={`filter_${this.state.filter.id}`} className="checkbox-label">Shared </label>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
          <div className={`filters-item-holder ${this.props.label.toLowerCase()}`}>

              <p>
                  <a onClick={()=>toggleExclude(false)} className={`switcher-link ${exclude ? '' : 'active'}`}>Include ({this.getFilterLength(false)})</a>
                  <a onClick={()=>toggleExclude(true)} className={`switcher-link ${!exclude ? '' : 'active'}`}>Exclude ({this.getFilterLength(true)})</a>
              </p>
          
            <PageFilter
              onCreateFilter={this.props.onCreateFilter}
              onUpdateEntityFilter={this.props.onUpdateEntityFilter}
              onRemoveFilter={this.props.onDestroyEntityFilter}
              onLoadUnsavedFilter={this.loadUnsavedFilter}
              rootClassName="page-filter"
              filter={this.state.filter}
              filters={this.props.entityFilters[this.props.entity]}
              entity={entityKey}
              filterKey={filterKey}
              label={this.props.label}
              loadFilter={this.loadFilter}
              tempFilter={tempFilter}
              user={this.props.user}
              />
          </div>
        </div>
      </div>
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateEntityFilter: (filter, entity) => dispatch(entityFiltersActions.updateEntityFilter(filter, entity)),
    onCreateFilter: params => dispatch(entityFiltersActions.createFilter(params)),
    onGetEntityFilters: entity => dispatch(entityFiltersActions.getEntityFilters(entity)),
    setFilter: (entity, filter) => dispatch(filterActions.setEntityFilter(entity, filter)),
    onDestroyEntityFilter: (filter, entity) => dispatch(entityFiltersActions.destroyFilter(filter, entity))
  }
}

const mapStateToProps = state => {
  const { entityFilters } = state.entity_filters;
  const { user } = state.user;
  return {
    entityFilters,
    user,
    filter: state.filter
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityFilter);


export class FiltersList extends React.Component {

  constructor(props){
    super(props);
    

    let currentEntityFilter = this.props.onLoadUnsavedFilter();

    this.state = {
        search: '',
        filters: props.filters.concat([currentEntityFilter])
    }
    
    this.setSearch = this.setSearch.bind(this);
  }
  
  setSearch(e) {
      this.setState({search: e.target.value});
  }

  render(){
  let { filters, search } = this.state;
  if(search)
      filters = filters.filter(filter=>filter.name.match(new RegExp(search, 'i')))
  
      
    return(
      <ul className="filter-list">
        <li key="search">
            <input className="filter-search" type="text" onChange={this.setSearch} placeholder="search filters" />
        </li>
        {filters.map(filter => {
            return <li key={JSON.stringify(filter)} onClick={() => this.props.handleItemClick(filter)} > { getFilterName(filter) }</li>
          })}
        {filters.length===0 && <li key="no-filters">
            No filters found
        </li>}
      </ul>
    )
  }
}
