import { map, find, uniqBy } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand';
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import { formatChartLabel, formatChartLabelItem, sortDateRange } from '../../helpers/DateFormatter';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import {percentageValue} from '../../helpers/NumberFormatter';
import AddTotal from '../../components/common/Datagrid/AddTotal';
import VendorHelper from '../../components/common/Datagrid/VendorHelper';
import FixNumericFields from '../helpers/FixNumericFields';

export const productsFormatter = {
    formatForPercentageChart,
    formatForTable,
    formatTop,
    formatDetails,
    formatForTableOld,
    formatAutocomplete,
    formatAutocompleteItem,
    formatCard,
    formatStreamsStats,
    formatTracks,
    formatMetadata,
    formatTimeseries
};

function formatForPercentageChart(data, column){
    let dataset = [], 
        labels = [];
    
    for(let entry of data) {
        dataset.push(entry[column]);
        labels.push(entry.name);
    }
    return {labels, datasets:[{data: dataset, label: 'Streams'}]};
}

function formatForTable(data){
    if(!data)
        return [];    
    for(let entry of data.data) {
        entry.id = entry.product_id;
        entry.name = entry.product_title;
        entry.logo = ImageURL(entry.product_image, entry.id, 'products');
        entry.engaged = Math.round((entry.curr_active / entry.curr_units) * 100);
    }
    data.data = AddTotal(data.data, 'product');
    return data;

}

function formatTop(data, addTotal){
    if(!data)
        return [];    
    data = map(data, (entry)=>{
        entry.id = entry.upc;
        return entry;
    });
    if(addTotal)
    	data = AddTotal(data, 'product');
    return  FixNumericFields(data);
}

function formatDetails(data){
    if(!data)
        return [];

    let labels = [],
        dataset = [];
    
    data = data.sort(sortDateRange);
    
    for(let item of data) {
        labels.push(formatChartLabel(item));
        dataset.push(item.units);
    }
    return {labels, datasets: [{data: Object.values(dataset), label: 'plays'}]};
}

function formatForTableOld(data){
    if(!data)
        return [];
    for(let entry of data.data) {
        entry.name = entry.title;
        entry.logo = entry.image;
    }
    return data;
}

function _formatAutocompleteItem(hit){
    if(hit.entity)
        return hit;
    
    const entity = 'products',
        name = hit.name? hit.name : hit._source.title,
        artist = hit.artist_name? hit.artist_name : hit._source.artist,
        id = hit.id ? hit.id : hit._id,
        barcode = hit._source.barcode ? `(${hit._source.barcode}) ` : '';

    return {
        id,
        entity,
        name: `${name} ${barcode}(product by ${artist})`,
        logo: icons.entities[entity]
    };
}

function formatAutocompleteItem(hit){
    const entity = 'products';
    return {
        id: hit.id,
        entity,
        name: `${hit.title}${hit.version ? ' '+ hit.version : ''} ${hit.barcode} (${hit.cat_no}) (product${hit.artist_name ? ' by ' + hit.artist_name : ''})`,
        name_raw: hit.title,
        logo: icons.entities[entity],
        code: hit.barcode
    }
}


function formatAutocomplete(data) {
    let {products}= data;
    return products.map(formatAutocompleteItem);
}

function formatCard([card1, card2]) {
    let data = {};
    if(card1.data)
    	data = card1.data;
    else if(card2.data)
    	data = card2.data;
    
    return {
      id: data.id,
      name: `${data.title} (product)`,
      name_original: data.title,
      code: data.barcode,
      logo: data.packshot_file_name,
      primary_artist: data.primary_artist,
      release_date: data.original_release_date,
      cat_no: data.cat_no,
      full_format: `${data.primary_format}${data.secondary_format?' '+data.secondary_format:''}`
    };
}

function formatStreamsStats(streams, metadata) {
    let labels = [],
        datasets = [];
    if(streams) {
        const {dates, period} = dateRangeFlat(streams);
        datasets = metadata.map((productEntry, index)=>{
            let product = productEntry.product;
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, product_id: product.id});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: product.id,
                label: product.title,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };
}

function formatTracks(products) {
    let tracks = [];
    for (let product of products) {
        for (let track of product.tracks) { 
            tracks.push({
                internal_id: track.id,
                value: track.isrc,
                label: `${track.title} (${track.isrc}) (from ${product.title})`
            })
        }
    }
    return tracks;
}

function _formatMetadata(entities, metadata) {
    for(let entity of entities) {
        const metaEntity = find(metadata, meta=>meta.product.barcode==entity.id);
        if(metaEntity) {
            const artist = metaEntity.artists ? metaEntity.artists[0].artist : {};
            
            entity.name = entity.product_title = metaEntity.product.title;
            entity.product_version = metaEntity.product.version;
            entity.product_genre = metaEntity.product.primary_genre;
            entity.original_release_date = metaEntity.product.original_release_date;
            entity.release_date = metaEntity.product.release_date;
            entity.imprint_name = metaEntity.imprint_name;
            entity.cat_no = metaEntity.product.cat_no;
            entity.primary_format = metaEntity.product.primary_format;
            entity.secondary_format = metaEntity.product.secondary_format;
            entity.full_format = `${entity.primary_format}${entity.secondary_format?' '+entity.secondary_format:''}`;           
            entity.image = ImageURL(metaEntity.product.packshot_file_name, entity.id, 'products');
            entity.artist_id = artist.id;
            entity.artist_name = artist.name;
        }
        else {
        	if(!entity.product_title) {
        		entity.name = entity.product_title = entity.id + ' - Unlisted Catalogue';
        	}
        }
    }
    return entities;

}

function formatMetadata(entities, metadata, total) {
    const { total_income } = total;
    for(let entity of entities) {
        const metaEntity = find(metadata, meta=>meta.id==entity.product_id);
        if(metaEntity) {
            const artist = metaEntity.primary_artist ? metaEntity.primary_artist : {};
            
            entity.name = entity.product_title = metaEntity.title;
            entity.product_version = metaEntity.version;
            entity.product_genre = metaEntity.primary_genre;
            entity.original_release_date = metaEntity.original_release_date;
            entity.release_date = metaEntity.release_date;
            //entity.imprint_name = metaEntity.imprint_name;
            entity.cat_no = metaEntity.cat_no;
            entity.primary_format = metaEntity.primary_format;
            entity.secondary_format = metaEntity.secondary_format;
            entity.full_format = `${entity.primary_format}${entity.secondary_format?' '+entity.secondary_format:''}`;           
            entity.image = ImageURL(metaEntity.packshot_file_name, entity.id, 'products');
            entity.artist_id = artist.id;
            entity.artist_name = artist.name;
            entity.imprint_id = metaEntity.imprint_id;
            entity.imprint_name = metaEntity.imprint_name;
        }
        else if(entity.id === null) {
            entity.name = 'Adjustments';
            entity.artist_name = '';
            entity.image = null;        
        }
        else {
        	if(!entity.product_title) {
        		entity.name = entity.product_title = entity.id + ' - Unlisted Catalogue';
        	}
        }
        entity.share = Math.round(entity.total_income/total_income*10000)/100;
    }
    return entities;
}
function formatTimeseries(streams, metadata) {
    let labels = [],
	    datasets = [];
	if(streams) {
	    const {dates, period} = dateRangeFlat(streams);
	    datasets = metadata.map((product, index)=>{
	        let data = [];
	        for(let date of dates) {
	            let stream = find(streams, {report_date: date, upc: product.barcode});
	            data.push(stream ? Number(stream.total_income).toFixed(2) : null);
	        }    
	
	        return {
	            id: product.id,
	            label: product.title,
	            data,
	            fill: false,
	            borderColor: colors[index%colors.length],
	            backgroundColor: colors[index%colors.length],
	            pointBorderColor: colors[index%colors.length],
	            pointBackgroundColor: colors[index%colors.length],                
	
	        }
	    });    
	    
	    labels =  map(dates, (date)=>date) //formatChartLabelItem(date, 'Month'));
	}
	return { labels, datasets };

}