export const statsConstants = {
    GET_TOP_STATS_REQUEST: 'GET_TOP_STATS_REQUEST',
    GET_TOP_STATS_SUCCESS: 'GET_TOP_STATS_SUCCESS',
    GET_TOP_STATS_FAILURE: 'GET_TOP_STATS_FAILURE',
    GET_CATALOG_STATS_REQUEST: 'GET_CATALOG_STATS_REQUEST',
    GET_CATALOG_STATS_SUCCESS: 'GET_CATALOG_STATS_SUCCESS',
    GET_CATALOG_STATS_FAILURE: 'GET_CATALOG_STATS_FAILURE',    
    GET_VENDOR_STATS_REQUEST: 'GET_VENDOR_STATS_REQUEST',
    GET_VENDOR_STATS_SUCCESS: 'GET_VENDOR_STATS_SUCCESS',
    GET_VENDOR_STATS_FAILURE: 'GET_VENDOR_STATS_FAILURE',    
    GET_TERRITORY_STATS_REQUEST: 'GET_TERRITORY_STATS_REQUEST',
    GET_TERRITORY_STATS_SUCCESS: 'GET_TERRITORY_STATS_SUCCESS',
    GET_TERRITORY_STATS_FAILURE: 'GET_TERRITORY_STATS_FAILURE',
    GET_TERRITORY_TIMESERIES_REQUEST: 'GET_TERRITORY_TIMESERIES_REQUEST',
    GET_TERRITORY_TIMESERIES_SUCCESS: 'GET_TERRITORY_TIMESERIES_SUCCESS',
    GET_TERRITORY_TIMESERIES_FAILURE: 'GET_TERRITORY_TIMESERIES_FAILURE',    
    GET_TOP_TERRITORY_STATS_REQUEST: 'GET_TOP_TERRITORY_STATS_REQUEST',
    GET_TOP_TERRITORY_STATS_SUCCESS: 'GET_TOP_TERRITORY_STATS_SUCCESS',
    GET_TOP_TERRITORY_STATS_FAILURE: 'GET_TOP_TERRITORY_STATS_FAILURE',
    GET_TOP_TERRITORY_TIMESERIES_REQUEST: 'GET_TOP_TERRITORY_TIMESERIES_REQUEST',
    GET_TOP_TERRITORY_TIMESERIES_SUCCESS: 'GET_TOP_TERRITORY_TIMESERIES_SUCCESS',
    GET_TOP_TERRITORY_TIMESERIES_FAILURE: 'GET_TOP_TERRITORY_TIMESERIES_FAILURE',    
    GET_DEMOGRAPHICS_STATS_REQUEST: 'GET_DEMOGRAPHICS_STATS_REQUEST',
    GET_DEMOGRAPHICS_STATS_SUCCESS: 'GET_DEMOGRAPHICS_STATS_SUCCESS',
    GET_DEMOGRAPHICS_STATS_FAILURE: 'GET_DEMOGRAPHICS_STATS_FAILURE',
    GET_DEMOGRAPHICS_TIMESERIES_REQUEST: 'GET_DEMOGRAPHICS_TIMESERIES_REQUEST',
    GET_DEMOGRAPHICS_TIMESERIES_SUCCESS: 'GET_DEMOGRAPHICS_TIMESERIES_SUCCESS',
    GET_DEMOGRAPHICS_TIMESERIES_FAILURE: 'GET_DEMOGRAPHICS_TIMESERIES_FAILURE',    
    GET_DEVICES_STATS_REQUEST: 'GET_DEVICES_STATS_REQUEST',
    GET_DEVICES_STATS_SUCCESS: 'GET_DEVICES_STATS_SUCCESS',
    GET_DEVICES_STATS_FAILURE: 'GET_DEVICES_STATS_FAILURE',
    GET_SOURCES_STATS_REQUEST: 'GET_SOURCES_STATS_REQUEST',
    GET_SOURCES_STATS_SUCCESS: 'GET_SOURCES_STATS_SUCCESS',
    GET_SOURCES_STATS_FAILURE: 'GET_SOURCES_STATS_FAILURE',    
    GET_SOURCES_TIMESERIES_REQUEST: 'GET_SOURCES_TIMESERIES_REQUEST',
    GET_SOURCES_TIMESERIES_SUCCESS: 'GET_SOURCES_TIMESERIES_SUCCESS',
    GET_SOURCES_TIMESERIES_FAILURE: 'GET_SOURCES_TIMESERIES_FAILURE',    
    GET_TRANSACTIONS_REQUEST: 'GET_TRANSACTIONS_REQUEST',
    GET_TRANSACTIONS_SUCCESS: 'GET_TRANSACTIONS_SUCCESS',
    GET_TRANSACTIONS_FAILURE: 'GET_TRANSACTIONS_FAILURE',
    GET_TRANSACTIONS_TIMESERIES_REQUEST: 'GET_TRANSACTIONS_TIMESERIES_REQUEST',
    GET_TRANSACTIONS_TIMESERIES_SUCCESS: 'GET_TRANSACTIONS_TIMESERIES_SUCCESS',
    GET_TRANSACTIONS_TIMESERIES_FAILURE: 'GET_TRANSACTIONS_TIMESERIES_FAILURE',        
    GET_STATEMENT_TOTALS_REQUEST: 'GET_STATEMENT_TOTALS_REQUEST',
    GET_STATEMENT_TOTALS_SUCCESS: 'GET_STATEMENT_TOTALS_SUCCESS',
    GET_STATEMENT_TOTALS_FAILURE: 'GET_STATEMENT_TOTALS_FAILURE',
    FORCE_LOAD_ALL_TERRITORIES_DATA: 'FORCE_LOAD_ALL_TERRITORIES_DATA',
    FORCE_LOAD_ALL_TOP_TERRITORIES_DATA: 'FORCE_LOAD_ALL_TOP_TERRITORIES_DATA',
    FORCE_LOAD_ALL_VENDORS_DATA: 'FORCE_LOAD_ALL_VENDORS_DATA',
}