import { releasesService } from '../services/releases';
import { releasesConstants } from '../constants/releases';

export const releasesActions = {
  getNewReleases
}

function getNewReleases(date, pagination){
  return ( dispatch, getState ) => {
    dispatch(request());
    releasesService.getNewReleases(date, pagination)
    .then( response => {
      return dispatch(success(response));
    })
    .catch( error => {
      return dispatch(failure(error));
    });
  };

  function request(){ return { type: releasesConstants.GET_NEW_RELEASES_REQUEST }}
  function success( releases ){ return { type: releasesConstants.GET_NEW_RELEASES_SUCCESS, payload: releases }}
  function failure( error ){ return { type: releasesConstants.GET_NEW_RELEASES_FAILURE, error }}
}
