import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { map } from 'lodash';
import Spinner from '../../components/common/Spinner';
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { sharedPagesActions } from '../../data/actions/shared_pages';
import { sharedPagesSettings } from '../../components/pages/sharedPages/Settings';

var deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');
const SelectTable = selectTableHOC(ReactTable);

class SharedPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 'all',
            selectedRows: []
        }
        this.deleteSharedPage = this.deleteSharedPage.bind(this);
        this.deleteSelectedPages = this.deleteSelectedPages.bind(this);
        this.toggleSelection = this.toggleSelection.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.allSelected = this.allSelected.bind(this);
    }
    
    deleteSharedPage(id) {
        this.props.dispatch(sharedPagesActions.deleteSharedPage(id, true));
    }
    
    deleteSelectedPages() {
        Promise.all(map(this.state.selectedRows, id=>this.props.dispatch(sharedPagesActions.deleteSharedPage(id, false))))
        .then(()=>this.props.dispatch(sharedPagesActions.getSharedPages()));
    }
    
    componentDidMount() {
        this.props.dispatch(sharedPagesActions.getSharedPages());
    }
    
    renderTabs() {
        const tabs = {
            'all': 'All',
            'home': 'Overview',
            'details': 'Entity Details'
        };
        const { currentTab } = this.state;
        return <ul className="tabs-list">
            {Object.keys(tabs).map(tabKey=><li key={tabKey} onClick={()=>this.setState({currentTab: tabKey, selectedRows: []})} className={`tab ${tabKey == currentTab?'active':''}`}>{tabs[tabKey]}</li>)}
        </ul>
    }
    
    toggleSelection(id){
        let selectedRows = Object.assign([], this.state.selectedRows);
        if(selectedRows.includes(id))
            selectedRows = selectedRows.filter(item=>item!=id);
        else
            selectedRows.push(id);
        this.setState({selectedRows});
    }
    
    toggleAll(rows){
        let selectedRows = map(rows, row=>row.id);
        if(this.allSelected(rows))
            selectedRows = [];
        this.setState({selectedRows});
    }
    
    allSelected(rows){
        const currentlySelectedRows = this.state.selectedRows;
        return (currentlySelectedRows.length > 0 && currentlySelectedRows.length == rows.length);
    }
    
    render() {
        const { loading, pages = [] } = this.props.sharedPages,
            { currentTab, selectedRows } = this.state,
            tableData = pages.filter(page=>(currentTab == 'all' || page.module == currentTab)),
            rows = tableData.length;
        

            /*
             *                     <div className="shared-link-holder">
                        <SharedPageLink slug={page.slug} justCopy={true} />
                        <a className="remove-filter-link filter-reset-btn button-no-style" onClick={()=>this.deleteSharedPage(page.id)}>
                        <span className="tiny-icon" dangerouslySetInnerHTML={{__html: deleteSvg}}></span>Delete</a>
                    </div>

             */
        return <div className="inner-page shared-pages">
            <div className="container">
                <h2>Shared Pages</h2>
                <div className="ibox">
                    {this.renderTabs()}
                    {selectedRows.length > 0 && <button className="default-btn default-btn--small default-btn--light delete-selected" onClick={this.deleteSelectedPages}>Delete Selected</button>}
                    <SelectTable
                        data={tableData}
                        loading={loading}
                        columns={sharedPagesSettings.settingsForTable(this.deleteSharedPage)}
                        pageSize={rows ? Math.min(rows, 10): 10}
                        showPagination={rows > 10}       
                        selectType="checkbox"
                        keyField="id"
                        toggleSelection={(key, shift, row)=>{this.toggleSelection(row.id)}}
                        toggleAll={()=>this.toggleAll(tableData)}
                        isSelected={(key)=>{return selectedRows.includes(key)}}
                        className="simple-table share-table"
                        selectAll={this.allSelected(tableData)}
                    />
                    
                    {/* {selectedRows.length > 0 && <button className="default-btn default-btn--small default-btn--light delete-selected" onClick={this.deleteSelectedPages}>Delete Selected</button>} */}

                </div>
            </div>
            <Helmet>
                <title>My Shared Pages</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        sharedPages: state.sharedPages
    } 
}

export default connect(mapStateToProps)(SharedPages)