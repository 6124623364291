import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, map } from 'lodash';
import { jsPDF } from "jspdf";
import {Helmet} from 'react-helmet';

import { tracksActions } from '../../data/actions/tracks';
import Box from '../../components/common/Box';
import Spinner from '../../components/common/Spinner';
import EntityCard from '../../components/common/EntityCard';
import ReactSelect from '../../components/common/ReactSelect';
import Streams from '../../components/pages/audience/Streams';
import Demographics from '../../components/pages/audience/Demographics';
import EntityMilestones from '../../components/pages/audience/EntityMilestones';
import Milestones from '../../components/pages/audience/Milestones';
import Sources from '../../components/pages/audience/Sources';
import Territory from '../../components/pages/audience/Territory';
import TopPlaylists from '../../components/pages/audience/TopPlaylists';
import RepeatListeners from '../../components/pages/audience/RepeatListeners';
import SharedChart from '../../components/pages/trackComparison/SharedChart';
import DemographicsStats from '../../components/pages/trackComparison/Demographics';
import VendorsStats from '../../components/pages/trackComparison/Vendors';
import SourcesStats from '../../components/pages/trackComparison/Sources';
import TerritoriesStats from '../../components/pages/trackComparison/Territories';
import CSV from '../../helpers/CSVExport';
import PDF from '../../helpers/PDFExport';
import VendorLogo from "../../components/common/VendorLogo";
import { dspLogos } from "../../components/common/Stats/utils";
import vendors from "../../components/widgets/filter/enums/vendors";


const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');
const pdfSvg = require('!svg-inline-loader!../../../public/img/pdf.svg');

class TrackComparisonDetails extends Component {
    typeOrder = ['Stream', 'Video', 'Radio', 'Download', 'Audiobook', 'Social Music'];
    
    constructor(props) {
        super(props);
        this.state = {
            ids: null,
            weeks: null,
            metadata: [{}, {}],
            sourceType: null
        };
        this.getTrackMetadata = this.getTrackMetadata.bind(this);
        this.setSourceType = this.setSourceType.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
        this.renderVendorLogos = this.renderVendorLogos.bind(this);
    }
    
    getTrackMetadata() {
        const { match, dispatch } = this.props;
        return dispatch(tracksActions.getTracksReleaseDates(match.params.ids))
        .then(metadata => {
            this.setState({
                metadata
            });
            return metadata;
        });
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({
            ids: match.params.ids,
            weeks: match.params.weeks
        });
        this.getTrackMetadata()
        .then((metadata)=>{
            const ids = map(metadata, 'code').join(',');
            const releaseDates = map(metadata, 'release_date').join(',');
            dispatch(tracksActions.compareTracks(ids, match.params.weeks, releaseDates));
        });
    }
    
    componentWillReceiveProps(props) {
        const { match, dispatch } = props;
        if((this.state.ids && this.state.ids !== match.params.ids) 
            || (this.state.weeks && this.state.weeks !== match.params.weeks)
            || (props.territories !== this.props.territories)
            || (props.vendors !== this.props.vendors)) {
            this.setState({
                ids: match.params.ids,
                weeks: match.params.weeks
            });
            this.getTrackMetadata()
            .then((metadata)=>{
                const ids = map(metadata, 'code').join(',');
                const releaseDates = map(metadata, 'release_date').join(',');
                dispatch(tracksActions.compareTracks(ids, match.params.weeks, releaseDates, props.territories, props.vendors));
            });
        }
    }

    
    setParentEntity(entity) {
        this.setState({
            title: entity.name
        })
    }
    
    /*
   
    componentWillReceiveProps(nextProps){
        
        const { match } = nextProps;
    }
    
    */
    
    exportStreamsListeners({labels, datasets}) {
        let result = [];
        for(let index in labels) {
            result.push({
                date: labels[index],
                total_streams: datasets[0].data[index],
                unique_listeners: datasets[1].data[index]
            })
        }
        return result;
    }
    
    isDataReady() {
        const { tracks, match } = this.props;
        const entity = match.params.entity;
        
        const dataLoadingFlags = [
            tracks.comparisonLoading, 
            tracks.demographicsComparisonLoading, 
            tracks.vendorsComparisonLoading,  
            tracks.territoriesComparisonLoading
        ];
        let ready = true;
        for(let flag of dataLoadingFlags) {
            if(flag) {
                ready = false;
                break;
            }
        }
        return ready;
    }

    exportToCsv() {
        const {
            tracks,
            match
        } = this.props;
        
        const [trackID0, trackID1] = match.params.ids.split(',');

        let data = [
            {[`streams - ${trackID0}`] : tracks.comparison.data.filter(item=>item.isrc == trackID0)},
            {[`streams - ${trackID1}`] : tracks.comparison.data.filter(item=>item.isrc == trackID1)},
            {[`age-gender - ${trackID0}`] : tracks.demographicsComparison[trackID0].data},
            {[`age-gender - ${trackID1}`] : tracks.demographicsComparison[trackID1].data},            
            {[`vendors - ${trackID0}`] : tracks.vendorsComparison[trackID0].data},
            {[`vendors - ${trackID1}`] : tracks.vendorsComparison[trackID1].data},
            {[`territories - ${trackID0}`] : tracks.territoriesComparison[trackID0]},
            {[`territories - ${trackID1}`] : tracks.territoriesComparison[trackID1]},
        ];

        const filename = CSV.CSVHeader('track_comparison', match.params.ids);
        CSV.CSVBulkExport(data, filename);
      }
    
    exportToPdf() {
        let pdf = new jsPDF('p', 'mm', 'a4'),
            toWidth = (pdf.internal.pageSize.getWidth()) - 10,
            pageHeight = (pdf.internal.pageSize.getHeight()),
            streamsChartImage = document.getElementById("track_comparison_streams"),
            listenersChartImage = document.getElementById("track_comparison_listeners"),
            ratioChartImage = document.getElementById("track_comparison_ratio"),
            activeChartImage = document.getElementById("track_comparison_active"),
            skippedChartImage = document.getElementById("track_comparison_skipped"),
            demographicsChartImage0 = document.getElementById("track_comparison_demographics_0"),
            demographicsChartImage1 = document.getElementById("track_comparison_demographics_1"),
            vendorsChartImage0 = document.getElementById("track_comparison_vendors_0"),
            vendorsChartImage1 = document.getElementById("track_comparison_vendors_1"),            
            sourcesChartImage0 = document.getElementById("track_comparison_sources_0"),
            sourcesChartImage1 = document.getElementById("track_comparison_sources_1"),            
            yOffset = 10,
            halfXOffset = toWidth / 2,
            { metadata } = this.state,
            { tracks } = this.props,
            trackID0 = metadata[0].code,
            title0 = metadata[0].name_compare,
            fullTitle0 = metadata[0].name_result,
            trackID1 = metadata[1].code,
            title1 = metadata[1].name_compare,
            fullTitle1 = metadata[1].name_result;
            
        pdf.setFontSize(16);
        pdf.text(`Track Comparison: `, 5, yOffset);
        yOffset+=5;
        pdf.setFontSize(10);
        pdf.text(`${fullTitle0}`, 5, yOffset);
        yOffset+=5;        
        pdf.text(`${fullTitle1}`, 5, yOffset);
        yOffset+=10;
        
        pdf.setFontSize(16);
        pdf.text("Streams Per Week", 5, yOffset);
        const streamsResizedChart = PDF.resizeCanvasDimensions(streamsChartImage, toWidth); 
        pdf.addImage(streamsChartImage.toDataURL("image/jpeg,1.0"), 'JPEG', 5, yOffset, streamsResizedChart.width, streamsResizedChart.height, 'streams');
        yOffset+=streamsResizedChart.height;
        yOffset+=16;

        pdf.text("Listeners Per Week", 5, yOffset);
        pdf.text("Streams Per Listener", halfXOffset + 10, yOffset);
        
        const listenersResizedChart = PDF.resizeCanvasDimensions(listenersChartImage, (toWidth - 10)/ 2); 
        pdf.addImage(listenersChartImage.toDataURL("image/jpeg,1.0"), 'JPEG', 5, yOffset, listenersResizedChart.width, listenersResizedChart.height, 'listeners');        
        const ratioResizedChart = PDF.resizeCanvasDimensions(ratioChartImage, (toWidth - 10) / 2); 
        pdf.addImage(ratioChartImage.toDataURL("image/jpeg,1.0"), 'JPEG', halfXOffset + 10, yOffset, ratioResizedChart.width, ratioResizedChart.height, 'ratio');
        yOffset+=Math.max(listenersResizedChart.height, ratioResizedChart.height);
        yOffset+=16;

        pdf.text("Active Percent", 5, yOffset);
        pdf.text("Skipped Percent", halfXOffset + 10, yOffset);
        
        const activeResizedChart = PDF.resizeCanvasDimensions(activeChartImage, (toWidth - 10)/ 2); 
        pdf.addImage(activeChartImage.toDataURL("image/jpeg,1.0"), 'JPEG', 5, yOffset, activeResizedChart.width, activeResizedChart.height, 'active');
        
        const skippedResizedChart = PDF.resizeCanvasDimensions(skippedChartImage, (toWidth - 10) / 2); 
        pdf.addImage(skippedChartImage.toDataURL("image/jpeg,1.0"), 'JPEG', halfXOffset + 10, yOffset, ratioResizedChart.width, skippedResizedChart.height, 'skipped');

        yOffset+=Math.max(activeResizedChart.height, skippedResizedChart.height);
        yOffset+=16;

        pdf.addPage();
        yOffset = 10;

        pdf.setFontSize(16);
        pdf.text("Age/Gender", 5, yOffset);
        yOffset+=5;
        pdf.setFontSize(10);
        pdf.text(title0, 5, yOffset);
        pdf.text(title1, halfXOffset + 10, yOffset);
        
        const demographicsResizedChart0 = PDF.resizeCanvasDimensions(demographicsChartImage0, (toWidth - 10)/ 2); 
        pdf.addImage(demographicsChartImage0.toDataURL("image/jpeg,1.0"), 'JPEG', 5, yOffset, demographicsResizedChart0.width, demographicsResizedChart0.height, 'demographics0');        
        const demographicsResizedChart1 = PDF.resizeCanvasDimensions(demographicsChartImage1, (toWidth - 10) / 2); 
        pdf.addImage(demographicsChartImage1.toDataURL("image/jpeg,1.0"), 'JPEG', halfXOffset + 10, yOffset, demographicsResizedChart1.width, demographicsResizedChart1.height, 'demographics1');
        yOffset+=Math.max(demographicsResizedChart0.height, demographicsResizedChart1.height);
        yOffset+=16;

        pdf.setFontSize(16);
        pdf.text("Vendors", 5, yOffset);
        yOffset+=5;
        pdf.setFontSize(10);
        pdf.text(title0, 5, yOffset);
        pdf.text(title1, halfXOffset + 10, yOffset);
        
        const vendorsResizedChart0 = PDF.resizeCanvasDimensions(vendorsChartImage0, (toWidth - 10)/ 2); 
        pdf.addImage(vendorsChartImage0.toDataURL("image/jpeg,1.0"), 'JPEG', 5, yOffset, vendorsResizedChart0.width, vendorsResizedChart0.height, 'vendors0');        
        const vendorsResizedChart1 = PDF.resizeCanvasDimensions(vendorsChartImage1, (toWidth - 10) / 2); 
        pdf.addImage(vendorsChartImage1.toDataURL("image/jpeg,1.0"), 'JPEG', halfXOffset + 10, yOffset, vendorsResizedChart1.width, vendorsResizedChart1.height, 'vendors1');
        yOffset+=Math.max(vendorsResizedChart0.height, vendorsResizedChart1.height);
        yOffset+=16;
        
        pdf.setFontSize(16);
        pdf.text("Sources", 5, yOffset);
        yOffset+=5;
        pdf.setFontSize(10);
        pdf.text(title0, 5, yOffset);
        pdf.text(title1, halfXOffset + 10, yOffset);

        
        const sourcesResizedChart0 = PDF.resizeCanvasDimensions(sourcesChartImage0, (toWidth - 10)/ 2); 
        pdf.addImage(sourcesChartImage0.toDataURL("image/jpeg,1.0"), 'JPEG', 5, yOffset, sourcesResizedChart0.width, sourcesResizedChart0.height, 'sources0');        
        const sourcesResizedChart1 = PDF.resizeCanvasDimensions(sourcesChartImage1, (toWidth - 10) / 2); 
        pdf.addImage(sourcesChartImage1.toDataURL("image/jpeg,1.0"), 'JPEG', halfXOffset + 10, yOffset, sourcesResizedChart1.width, sourcesResizedChart1.height, 'sources1');
        yOffset+=Math.max(sourcesResizedChart0.height, sourcesResizedChart1.height);
        yOffset+=16;

        pdf.addPage();
        yOffset = 10;
        
        pdf.setFontSize(16);
        pdf.text("Territories", 5, yOffset);
        yOffset+=5;
        pdf.setFontSize(10);
        pdf.text(title0, 5, yOffset);
        pdf.text(title1, halfXOffset + 10, yOffset);
        yOffset+=5;
        
        let territoriesData0 = tracks.territoriesComparison[trackID0];
        pdf.autoTable({
            ...PDF.formatTable(territoriesData0, 'territories_track'), 
            theme: 'plain', 
            startY: yOffset, 
            margin: { right: halfXOffset - 10, left: 5 }, 
            styles: {fontSize: 8}
        });
        
        let territoriesData1 = tracks.territoriesComparison[trackID1];
        pdf.autoTable({
            ...PDF.formatTable(territoriesData1, 'territories_track'), 
            theme: 'plain', 
            startY: yOffset, 
            margin: { left: halfXOffset + 10 }, 
            styles: {fontSize: 8}
        });        
        //const territoriesTableHeight = pdf.lastAutoTable.finalY; 

        pdf.save("track_comparison.pdf");
        
        
    }
    
    setSourceType({value}) {
        this.setState({
            sourceType: value
        })
    }
    
    renderSourceTypes() {
        let options = [{
            label: 'All',
            value: null
        }];
        for(let sourceType of this.typeOrder) {
            options.push({label: sourceType, value: sourceType})
        }
        const value = find(options, (option)=>option.value == this.state.sourceType);
        return <ReactSelect options={options} value={value} onChange={this.setSourceType} /> 
    }
    
    renderVendorLogos(chartType) {
        let logoData = dspLogos[chartType].data;

        if(this.props.vendors) {
            let vendorIDs = this.props.vendors.split(','),
                vendorNames = [];
            for(let vendorID of vendorIDs) {
                let vendorData = find(vendors, v => v.vend_id == vendorID);
                if(vendorData) {
                    vendorNames.push(vendorData.value)
                }
            };
            if(vendorNames.length) {
                logoData = logoData.filter(logo=>vendorNames.includes(logo));
            }
        }
        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} />
            ))}
        </div>
    }

    
    render() {
        const { ids, weeks } = this.props.match.params;
        const trackIDs = ids.split(',');
        const { comparison, comparisonLoading } = this.props.tracks;
        const { metadata } = this.state;
        const { territories, vendors } = this.props;
        if(comparisonLoading || !comparison)
            return <Spinner enabled={true} />
        
        return <div className="wrapper wrapper-content">
            {this.isDataReady() && <div className="row download-page download-page--mt">
                <span>Export the data</span>
                <a key="download" title="Download entire page" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                <span>PDF</span>
                <a key="pdf" title="Download PDF" onClick={this.exportToPdf} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: pdfSvg}} />
            </div>}
            
            <div className="row">                
                <div className="col-xs-12">
                    <SharedChart data={comparison} metric="streams" metadata={metadata} chartID="track_comparison_streams" /> 
                </div>
            </div>        
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <SharedChart data={comparison} metric="listeners" metadata={metadata} chartID="track_comparison_listeners"  /> 
                </div>
                <div className="col-xs-12 col-sm-6">
                    <SharedChart data={comparison} metric="ratio" metadata={metadata} chartID="track_comparison_ratio" /> 
                </div>
            </div>                
            <div className="row">                
                <div className="col-xs-12 col-sm-6">
                    <SharedChart data={comparison} metric="active" metadata={metadata} chartID="track_comparison_active" /> 
                </div>
                <div className="col-xs-12 col-sm-6">
                    <SharedChart data={comparison} metric="skipped" metadata={metadata} chartID="track_comparison_skipped" /> 
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <h2 className="ibox-title__title">Compare: Age/Gender</h2>
                    {this.renderVendorLogos('age')}
                </div>
                <div className="col-xs-12">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <DemographicsStats id={trackIDs[0]} weeks={weeks} metadata={metadata[0]} territories={territories} vendors={vendors} chartID="track_comparison_demographics_0" /> 
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <DemographicsStats id={trackIDs[1]} weeks={weeks} metadata={metadata[1]} territories={territories} vendors={vendors} chartID="track_comparison_demographics_1" /> 
                        </div>
                    </div>
                </div>
            </div>                

            <div className="row">
                <div className="col-xs-12">
                    <h2 className="ibox-title__title">Compare: Platforms</h2>
                    <div className="track-select-container">
                        {this.renderSourceTypes()}
                    </div>
                </div>
                <div className="col-xs-12">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <VendorsStats id={trackIDs[0]} weeks={weeks} metadata={metadata[0]} source={this.state.sourceType}  territories={territories} vendors={vendors} chartID="track_comparison_vendors_0" /> 
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <VendorsStats id={trackIDs[1]} weeks={weeks} metadata={metadata[1]} source={this.state.sourceType}  territories={territories} vendors={vendors} chartID="track_comparison_vendors_1" /> 
                        </div>
                    </div>
                </div>
            </div>                
            
            <div className="row">
                <div className="col-xs-12">
                    <h2 className="ibox-title__title">Compare: Sources</h2>
                    {this.renderVendorLogos('sources')}
                </div>
                <div className="col-xs-12">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <SourcesStats id={trackIDs[0]} weeks={weeks} metadata={metadata[0]}  territories={territories} vendors={vendors} chartID="track_comparison_sources_0" /> 
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <SourcesStats id={trackIDs[1]} weeks={weeks} metadata={metadata[1]}  territories={territories} vendors={vendors} chartID="track_comparison_sources_1" /> 
                        </div>
                    </div>
                </div>
            </div>    
            
            <div className="row">
                <div className="col-xs-12">
                    <h2 className="ibox-title__title">Compare: Territories</h2>
                    {this.renderVendorLogos('territories')}
                </div>
                <div className="col-xs-12">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <TerritoriesStats id={trackIDs[0]} weeks={weeks} metadata={metadata[0]}  territories={territories} vendors={vendors} /> 
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <TerritoriesStats id={trackIDs[1]} weeks={weeks} metadata={metadata[1]}  territories={territories} vendors={vendors} /> 
                        </div>
                    </div>
                </div>
            </div>                
        
            
            <Helmet>
                <title>Track Comparison</title>
            </Helmet>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter,
        audience: state.audience,
        playlists: state.playlists,
        tracks: state.tracks
    }
}

export default connect(mapStateToProps)(TrackComparisonDetails)