import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import ReactTable from "react-table";
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
import { tracksActions } from '../../../data/actions/tracks';
import { playlistTracksSettings } from './PlaylistTracks/Settings'
const CellHeaderTable = cellHeaderHOC(ReactTable);
import Box from '../../common/Box';
import ReactSelect from '../../common/ReactSelect';
import EntityTimeline from '../../pages/home/EntityTimeline';
import CSV from '../../../helpers/CSVExport';

var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');


class PlaylistTracks extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            view: 'table'
        }
        this.renderExpanded = this.renderExpanded.bind(this);
        this.renderDetails = this.renderDetails.bind(this);
        this.getTimeseries = this.getTimeseries.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }
    
    componentDidMount(){
        const { playlistID, parentEntity, parentEntityID } = this.props;
        this.props.dispatch(tracksActions.getTopTracksInPlaylist(playlistID, parentEntity, parentEntityID, false));
    }
    
    getTimeseries(){
        const { playlistID } = this.props,
            { data = [] } = this.props.tracks.topPlaylist[playlistID] || [];
            
        if(data && data.length) {
            const ids = map(data, 'track_id').filter(id=>id);
            let filtered = [{id:'playlists', value: playlistID}, {id:'ids', value:ids}];
            this.props.dispatch(tracksActions.getTimeseriesTracksInPlaylist({sorted: 'curr_units', filtered}), playlistID);
        }
    }
    
    setChartView(view){
        this.setState({view});
    }
    
    exportToCsv(){
        const { playlistID } = this.props,
        { data = []} = this.props.tracks.topPlaylist[playlistID] || [];
        const filename = CSV.CSVHeader('top_tracks_in_playlist', 'curr_units', this.props.filter.global); 
        return CSV.CSVExport(data, {filename}, 'top_tracks_in_playlist');
    }

    renderToolbar() {
        return [<div key="wrapper"><div className="ibox-icon-holder">
            <div className="ibox-actions">
            <a key='table' title="Table" className={`chart-icon ${this.state.view == 'table' ? 'active' : ''}`}
                onClick={()=>this.setChartView('table')}
                dangerouslySetInnerHTML={{__html: tableSvg}}>
            </a>
            <a key='line' title="Line Chart" className={`chart-icon ${this.state.view == 'line' ? 'active' : ''}`}
                onClick={()=>this.setChartView('line')}
                dangerouslySetInnerHTML={{__html: lineSvg}}>
            </a>
            <a key='bar' title="Bar Chart" className={`chart-icon ${this.state.view == 'bar' ? 'active' : ''}`}
                onClick={()=>this.setChartView('bar')}
                dangerouslySetInnerHTML={{__html: barSvg}}>
            </a>
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            </div></div></div>]
    }
    
    renderExpanded (mode) {
        const { playlistID } = this.props,
        { data = [], loading } = this.props.tracks.topPlaylist[playlistID] || [];
    
        return <CellHeaderTable 
            resizable={false}
            loading={loading}
            pageSize={data.length} 
            showPagination={false} 
            data={data} 
            className={"new-releases-track top-tracks-in-playlist"} 
            columns={playlistTracksSettings.settingsForTable(mode)}
        />
        
    }
    
    renderDetails () {
        const { playlistID } = this.props,
            { data = [], loading } = this.props.tracks.topPlaylist[playlistID] || [],
            { topPlaylistTimeseries: timeseries, topPlaylistTimeseriesLoading} = this.props.tracks
            
        let topChartClass = ''
        if (data) {
            if (data.length > 20 && data.length <= 100) {
                topChartClass = 'top-chart-md'    
            } else if (data.length > 100 ) {
                topChartClass = 'top-chart-lg'    
            }
        }            
        return <Box title="Top Tracks in Playlist" toolbar={this.renderToolbar()} spinnerEnabled={topPlaylistTimeseriesLoading}>
            {this.state.view == 'table' && this.renderExpanded('details')}
            {this.state.view == 'line' && <div className={`timeline-holder ${topChartClass}`}><EntityTimeline mode="line" entity="tracks_in_playlist" results={timeseries} forceLegendPosition="bottom" getTimeseries={this.getTimeseries} /></div>}
            {this.state.view == 'bar' && <div className={`timeline-holder ${topChartClass}`}><EntityTimeline mode="bar"  entity="tracks_in_playlist" results={timeseries} forceLegendPosition="bottom" getTimeseries={this.getTimeseries} /></div>}            
        </Box>
    }
    
    render (){
        const { mode } = this.props;
        return mode == 'expanded' ? this.renderExpanded('expanded') : this.renderDetails();
    }
    
    
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        filter: state.filter,
    }
}


export default connect(mapStateToProps)(PlaylistTracks);