import React from 'react';
import { map } from 'lodash';
import AbsoluteValue from '../../AbsoluteValue';
import { percentageValue } from '../../../../helpers/NumberFormatter';

export const demographicsSettings = {
    settingsForTable,
    settingsForRow
};


function settingsForTable(mode){
    
    
    let columns = [
      {
              id: 'gender',
              headerClassName: 'table-header-item',
              Header: 'Gender',
              // width: 70,
              accessor: 'gender',
          },
                                
       {
           id: 'age',
           className: 'imprint-name',
           headerClassName: 'imprint-name',
           Header: 'Age Group',
           accessor: 'age',
           // width: 190
       },
       {
           id: 'streams',
           headerClassName: 'table-header-item',
           Header: 'Streams',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'curr_units'
       },
       /*
       {
           id: 'curr_listeners',
           headerClassName: 'table-header-item',
           Header: 'Listeners',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 100,
           accessor: 'curr_listeners'
       },
       */              
       {
           id: 'share',
           headerClassName: 'table-header-item no-sort',
           Header: 'Ratio',
           sortable: false,
           accessor: 'share',
           Cell: props => <span><AbsoluteValue value={props.value}></AbsoluteValue>%</span>,
           // width: 110
       }


    ];
    return columns.map(column=>{
        //column.show = modes[mode].includes(column.id);
        return column;
    });
}

function settingsForRow(state, rowInfo, column) {
    if(rowInfo===undefined)
        return {};
        
    return {
        className: `tr-gender tr-gender-${rowInfo.row.gender.toLowerCase()}`
    }
}