import { find } from 'lodash';
import vendors from './VendorSources.json';
import vendorsEnum from '../../widgets/filter/enums/vendors.json';

export default {
    VendorDistribution,
    VendorSources,
    VendorColors
}

function VendorDistribution (source) {
    const sourceID = source.toLowerCase();
    let column = {
        Header: source,
        id: sourceID,
        columns: []
    };
    for(let vendor of vendors[source]){
        const vendorID = `${sourceID}.${vendor.toLowerCase()}`;
        column.columns.push({
            id: vendorID,
            accessor: vendor,
        })
    }
    if(vendors[source].length>1) {
        const totalID = sourceID;
        column.columns.unshift({
            id: totalID,
            accessor: 'total'
        })
    }
    return column;
}

function VendorSources () {
    return Object.keys(vendors)
}

function VendorColors(vendors) {
    if(vendors) {
        for(let vendor of vendors) {
            const vendorData = find(vendorsEnum, {value: vendor.vendor_value});
            if(vendorData) {
                vendor.color = vendorData.hex[Object.keys(vendorData.hex)[0]];
            }
        }    
    }
    return vendors;
}