import _ from 'lodash';
import moment from 'moment';
import Papa from 'papaparse';

export const importsFormatter = {
    formatStatementPeriods,
    formatPendingStatements,
    formatDateRangeByPeriod,
    formatImportStatuses,
    formatCatalogImport,
    formatCatalogImportErrors,
    formatCatalogImports,
    formatUploadedCSV
};

function formatStatementPeriods(data){
    let {result} = data;
    let fullResult = [];
    let { period } = _.minBy(result, 'period');
    const [ year, month ] = period.split('-');
    let periodDate = moment().month(Number(month)-1).year(Number(year)); 
    do {
        period = periodDate.format('YYYY-MM');
        let row = _.find(result, {period});
        if(!row) {
            row = {
                period,
                digital_income: 0, 
                physical_income: 0, 
                total_income: 0
            };
        }
        fullResult.push(row);
        periodDate.add(1, 'months');
    } while (periodDate < moment())   
    return fullResult;
}

function formatPendingStatements(data){
    let result = [];
    for(let statement of data.result) {
        let {period} = statement;
        for(let labelStatement of statement.label_totals) {
            result.push({
                period,
                digital_income: labelStatement.digital_income, 
                label: labelStatement.label,
                physical_income: labelStatement.physical_income, 
                total_income: labelStatement.total_income
            })
        }
    }
    return result;
}

function formatImportStatuses(stats, statuses) {
    for(let status of statuses) {
        let stat = _.find(stats, {vendorID: status.vendor_id, silo: status.silo_code});
        if(stat)
            stat.status = status.status;
    }
    return stats;
}


function formatImports(imports) {
    return imports.data;
}

function formatDateRangeByPeriod(period) {
    const [ year, month ] = period.split('-');
    let date = moment().month(Number(month)-1).year(Number(year));
    return { dateStart: date.startOf('month').format('YYYY-MM-DD'), dateEnd: date.endOf('month').format('YYYY-MM-DD')}; 
}

function formatCatalogImport(data) {
    data.percentDone = Math.round(data.processed_lines / data.total_lines * 100);
    return data;
}

function formatCatalogImportErrors(errors) {
    let result = [];
    for(let error of errors) {
        let { title, upc, product_hash } = error;
        let product = `${title} (${upc})`;
        for(let key of Object.keys(product_hash)) {
            if(typeof product_hash[key] == 'object') {
                const { data, error } = product_hash[key];
                result.push({product, field: key, data, error});
            }
        }
    }
    return result;
}


function formatCatalogImports(data) {
    return data;
}

function formatUploadedCSV(csv) {
    //csv = csv.replaceAll('"7"""', '"7\\""').replace('"12"', '"12\\"');
    //csv = csv.replaceAll(/(?<!^)(?<!,)"(?!,|$)/g, '\\"');
    let { data , errors} = Papa.parse(csv, {delimiter: ',', newline: '\r\n', quoteChar: '"', escapeChar: '"', header: true});
    console.log("CSV parse errors");
    console.log(errors);
    for(let item of data) {
        if(!item.primary_genre)
            item.primary_genre = 'Alternative'
    }
    return Papa.unparse(data);
}