import React from 'react';
import OverviewCard from './OverviewCard';
import VendorLogo from  '../../common/VendorLogo';
import PercentageValue from '../../common/PercentageValue';
import { convertToThousands, round } from '../../../helpers/NumberFormatter';

class TrendsWidget extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            vendorsView: false
        }
        
        this.toggleVendorsView = this.toggleVendorsView.bind(this);
    }
    
    toggleVendorsView(){
        this.setState({
            vendorsView: !this.state.vendorsView
        })
    }
    
    render (){
        const { entity } = this.props,
            vendors = entity.stms_by_vendor; 
        
        return <div onClick={this.toggleVendorsView} className="widget overview-widget white-bg p-lg text-center">
            {this.state.vendorsView == false && <OverviewCard entity={entity} /> } 
            {this.state.vendorsView == true && entity.vendors!==undefined && <div>
                <i className="fa fa-question entity-question-sign entity-question-sign--trend"></i>
                <div className="trend-title">Total {entity.title}</div>
                <i className={`fa fa-${entity.icon} rounded-icon trend-rounded-icon`} />
                {entity.vendors.map(vendor=><p className="row">
                    <span className="col-xs-2"><VendorLogo name={vendor.vendor} /></span>
                    {/*
                    <span className="col-xs-3 passive-count">{convertToThousands(vendor.prev_units)}</span>
                    <span className="col-xs-3 active-count">{convertToThousands(vendor.curr_units)}</span>
                    */}
                    <span className="col-xs-10 engaged-count"><PercentageValue item={vendor} field="units" /></span>
                </p>)}
            </div>}
        </div>
    }
}

export default TrendsWidget;

