import React, { Component } from 'react';
import ReactTable from "react-table";
import {Helmet} from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { pick, isEqual } from 'lodash';
import SectionHeader from '../components/common/Datagrid/SectionHeader';
import FieldSelector from '../components/common/Datagrid/FieldsSelector';
import Box from '../components/common/Box';
import SelectFieldFilter from '../components/common/SelectFieldFilter';
import SelectHeartBeatsDates from '../components/common/SelectHeartBeatsDates';
import PlaylistAnalysisLink from '../components/common/Datagrid/PlaylistAnalysisLink';
import DetailsLink from "../components/common/Datagrid/DetailsLink";
import Tooltip from '../components/common/Tooltip';

import { heartbeatsActions } from '../data/actions/heartbeats';
import { notificationReportsActions } from '../data/actions/notification_reports';
import { heartbeatsSettings } from '../components/pages/heartbeats/Settings'
import { getDatesFromFridayToThursday, previousFriday } from '../data/helpers/heartbeats';
import { heartbeatsConstants } from '../data/constants/heartbeats';
import moment from 'moment';
import CSV from '../helpers/CSVExport';

import cellHeaderHOC from '../components/common/Datagrid/CellHeaderHOC';

const CellHeaderTable = cellHeaderHOC(ReactTable);

const downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');

class Heartbeats extends Component {
  constructor(props) {
    super(props);
    this.tableScrollTop = 0;
  }

  state = {
    newRelease: 0,
    minStreams: 100,
    imprint: '',
    artist: '',
    percentChangeOptions: [10, 20, 40, 60, 80, 100, 200, 300],
    minStreamOptions: [100, 500, 1000, 5000, 10000, 50000, 100000],
    week: getDatesFromFridayToThursday()[0].startDate,
    data: [],
    pageSize: 20,
    filtered: [],
    page: 0,
    sorted: [{id: 'percent_change', desc: true}],
    defaultSorted: [{id: 'percent_change', desc: true }],
  }

  componentDidMount(){
    this.dispatchLoadData();
    this.props.getUserNotificationSettings();
    this.findPercentage();
  }

  componentDidUpdate(prevProps){
    if(this.props.heartbeats.data !== prevProps.heartbeats.data){
      this.filterByNewRelease();
    }
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length > 0) {
      filtered.forEach((filter, i) => {
        if (filter.id === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter.value = value;
          insertNewFilter = 0;
        }
      });
    }
    
    let nextState = Object.assign({}, this.state); 

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    if(accessor === 'new_release'){
      nextState.newRelease = value;
    }
    if(accessor === 'percent_change'){
      nextState.percentage = value;
    }
    if(accessor === 'period_2_units'){
        nextState.minStreams = value;
    }
    if(accessor === 'imprint'){
        nextState.imprint = value;
    }
    if(accessor === 'artist_name'){
        nextState.artist = value;
    }
    nextState.filtered = filtered;
    this.setState(nextState) //, ()=>this.dispatchLoadData());
  };
  
  loadData = (params) => {
      params = pick(params, ['page', 'pageSize', 'filtered', 'sorted']);
      const currentState = pick(this.state, ['page', 'pageSize', 'filtered', 'sorted']);
      if(isEqual(params, currentState))
          return;

      const { page, pageSize, filtered, sorted } = params;
      this.setState({
          page,
          pageSize,
          filtered,
          sorted,
      }, ()=>{
          this.dispatchLoadData()
      });
  }

  dispatchLoadData = () => {
    const { page, pageSize, filtered, sorted } = this.state;
    let params = {};
    params.page = page;
    params.pageSize = pageSize;
    params.filtered = filtered;
    params.sorted = sorted;
    params.period_date = this.state.week;
    this.props.loadData(params);
  }

  getDataByWeek = (e) => {
    this.setState({ data: [], newRelease: 0, week: e.target.value}) //, ()=>this.dispatchLoadData());
    
  }

  toggleNewRelease = event => {
    this.setState({ newRelease: event.target.checked });
    if(event.target.checked){
      this.sortNewRelease();
    } else {
      this.filterByNewRelease();
    }
  }

  getFileName = () => {
    const currentDates = getDatesFromFridayToThursday().filter( date => date.startDate === this.state.week)[0];
    const fromDate = moment(new Date(currentDates.endDate)).format('YYYYMMDD');
    const toDate = moment(new Date(currentDates.startDate)).format('YYYYMMDD');
    return `heartbeats_${fromDate}-${toDate}`;
  }

  exportToCsv = data => {
    const filename = this.getFileName();
    return CSV.CSVExport(data, {filename}, 'heartbeats');
  }

  filterByNewRelease = () => {
      return true;
    const data = this.props.heartbeats.data.filter(currentValue => !currentValue.new_release );
    this.setState({ data });
  }

  sortNewRelease = () => {
    let data = this.props.heartbeats.data.slice();
    data.sort((currentValue, nextValue) => (currentValue.new_release === nextValue.new_release) ? 0 : currentValue.new_release ? -1 : 1);
    this.setState({ data });
  }

  findPercentage = () => {
    if(this.props.settings.length !== 0){
      const percentage = this.props.settings
            .find( setting => setting.settings.threshold !== undefined)
            .settings
            .threshold;
      this.setState({
        percentage
      })
    }
  }

  getUnitsFromAccesor = unitsAccessor => {
    const separator = '|';
    const units = unitsAccessor.split(separator)[1];
    return Number(units);
  }

  render() {
    const dates = getDatesFromFridayToThursday();
    const data = this.state.data.length !== 0 ? this.state.data : this.props.heartbeats.data;
    const pages = this.props.heartbeats.pagination ? this.props.heartbeats.pagination.total_page : -1;
    
    return (
      <div className="wrapper wrapper-content light-blue-bg no-padding wrapper-mt">
        <div className="column ibox-content section-header">
          <h2 className="big-title"> Heartbeats </h2>
          <a key="download" onClick={() => this.exportToCsv(data)} title="Export CSV" className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            <div className="row">
                <div className="col-xs-12 col-sm-4">
                    <div className="inline-filter-title"><i className="fa fa-calendar-week calendar-button"></i>Week</div>
                    <SelectHeartBeatsDates
                      dates={dates}
                      week={this.state.week}
                      getDataByWeek={this.getDataByWeek}
                    />
                </div>
                <div className="col-xs-12 col-sm-2">
                    <div className="inline-filter-title">Week Units</div>
                    <SelectFieldFilter
                        onChange={this.onFilteredChangeCustom}
                        value={this.state.minStreams}
                        options={this.state.minStreamOptions}
                        symbol={heartbeatsConstants.PERCENT_SYMBOL}
                        fieldName="min_streams"
                        accessor="period_2_units" 
                    />
                </div>
                <div className="col-xs-12 col-sm-2">
                    <div className="inline-filter-title">Percent Change</div>
                    <SelectFieldFilter
                      onChange={this.onFilteredChangeCustom}
                      value={this.state.percentage}
                      options={this.state.percentChangeOptions}
                      symbol={heartbeatsConstants.PERCENT_SYMBOL}
                      fieldName="percent_change"
                      accessor="percent_change" 
                    />
                </div>
                <div className="col-xs-12 col-sm-2">
                    <div className="inline-filter-title">New Release</div>
                    <select
                        value={this.state.newRelease}
                        onChange={event => {
                            this.onFilteredChangeCustom(event.target.value, 'new_release');
                        }}
                        >
                        <option value="">All</option>
                        <option value="true">New Release</option>
                        <option value="false">Catalog</option>
                        })}
                    </select>
                </div>
                <div className="col-xs-12 col-sm-2">
                    <button className="btn btn-primary heartbeats-apply-button" onClick={this.dispatchLoadData}>Apply</button>
                </div>
            </div>
        </div>
        <div>
          <CellHeaderTable
            filtered={this.state.filtered}
            resizable={false}
            loading={this.props.heartbeats.loading}
            className="heartbeat-table grid-responsive-table sticky-table"
            columns={[
              {
                  id: 'actions',
                  className: 'imprint-name logo frozen',
                  headerClassName: 'imprint-name logo frozen',
                  // width: 50,
                  Cell: props => {
                    return (
                      <div className="heartbeat-icons-holder">
                        <PlaylistAnalysisLink entity="tracks" id={props.original.track_id} />
                        <DetailsLink
                          title="Track Details"
                          entity="tracks"
                          id={props.original.track_id}
                        ></DetailsLink>
                      </div>
                    );
                  }
              },
              {
                  id: 'artist_name',
                  className: 'imprint-name artist frozen',
                  headerClassName: 'imprint-name artist frozen',
                  Header: 'Artist',
                  accessor: 'artist_name',
                  //filterable: true,
                  // width: 220,
                  Cell: props => {
                    return (
                      <span>
                        {props.value}
                      </span>
                    );
                  },
//                  filterMethod: (filter, row) => {
//                      return String(row['artist_name']).match(new RegExp(filter.value, 'i'))},
//                  Filter: ({ filter, onChange }) => {
//                      return (
//                        <div className="inline-filter-holder">
//                          <input
//                            onChange={(e)=>this.onFilteredChangeCustom(e.target.value, 'artist_name')}
//                            value={this.state.artist}
//                          />
//                        </div>
//                      )
//                    }
                  
              },
              {
                id: 'title_and_version',
                className: 'imprint-name song frozen',
                headerClassName: 'imprint-name song frozen',
                Header: 'Song',
                accessor: 'title_and_version',
                // width: 355,
                Cell: props => {
                  return (
                    <span>
                      {props.value}
                    </span>
                  );
                }
              },
              {
                id: 'product_title',
                className: 'imprint-name',
                headerClassName: 'imprint-name',
                Header: 'Product',
                //accessor: heartbeat => { return JSON.parse(heartbeat.associated_releases)[0].product_title },
                accessor: 'product_title',
                sortable: true,
                // width: 350,
                Cell: props => {
                  return (
                    <span>
                      {props.value}
                    </span>
                  );
                }
              },
              {
                  id: 'imprint',
                  className: 'imprint-name',
                  headerClassName: 'imprint-name',
                  Header: 'Imprint',
                  accessor: 'imprint',
                  //filterable: true,
//                  filterMethod: (filter, row) => {
//                      return String(row['imprint']).match(new RegExp(filter.value, 'i'))},
//                  Filter: ({ filter, onChange }) => {
//                      return (
//                        <div className="inline-filter-holder">
//                          <input
//                            onChange={(e)=>this.onFilteredChangeCustom(e.target.value, 'imprint')}
//                            value={this.state.imprint}
//                          />
//                        </div>
//                      )
//                    }
                  
                  
                  // width: 220,
              },              
              
              {
                id: 'period_1_units',
                className: 'imprint-name',
                headerClassName: 'imprint-name',
                Header: 'Previous Week | Units',
                sortMethod: (a, b) => {
                  const unitsCurrent = this.getUnitsFromAccesor(a);
                  const unitsNext = this.getUnitsFromAccesor(b);
                  return unitsCurrent - unitsNext;
                },
                accessor: 'period_1_units',
                Cell: props => { return `Wk${props.original.period_1_week} | ${props.original.period_1_units}` },
                // width: 160
              },
              {
                id: 'period_2_units',
                className: 'imprint-name',
                Header: 'Current Week | Units',
                headerClassName: 'imprint-name inline-filter',
                sortMethod: (a, b) => {
                  const unitsCurrent = this.getUnitsFromAccesor(a);
                  const unitsNext = this.getUnitsFromAccesor(b);
                  return unitsCurrent - unitsNext;
                },
//                filterable: true,
                accessor: heartbeat => { return `Wk${heartbeat.period_2_week} | ${heartbeat.period_2_units}` },
//                filterMethod: (filter, row) => {
//                    return row._original.period_2_units >= parseInt(filter.value);
//                },
                
                // width: 160,
//                Filter: ({ filter, onChange }) => {
//                    return (
//                      <div className="inline-filter-holder">
//                        <div className="inline-filter-title">Current Week | Units</div>
//                        <SelectFieldFilter
//                          onChange={this.onFilteredChangeCustom}
//                          value={this.state.minStreams}
//                          options={this.state.minStreamOptions}
//                          symbol={heartbeatsConstants.PERCENT_SYMBOL}
//                          fieldName="min_streams"
//                          accessor="previousWeek_units_select" 
//                        />
//                      </div>
//                    )
//                  }
                
              },
              {
                id: 'release-date',
                className: 'imprint-name',
                headerClassName: 'imprint-name',
                Header: 'Release date',
                accessor: heartbeat => {
                  const releaseDate = JSON.parse(heartbeat.associated_releases)[0].release_date;
                  const date = new Date(releaseDate);
                  return date.toLocaleString('default', { day: '2-digit', month: 'short', year: 'numeric' });
                },
                filterable: false,
                sortable: false,
                // width: 120,
              },
              {
                id: 'isrc',
                className: 'imprint-name',
                headerClassName: 'imprint-name',
                Header: 'ISRC',
                accessor: 'isrc',
                // width: 115,
              },
              {
                id: 'new_release',
                className: 'with-tooltip',
                headerClassName: 'inline-filter',
                Header: props => <div className="top-table-header-inner grid-header-cell">
                  New Release 
                  <Tooltip 
                    position="bottom" 
                    message="New Releases include products released in the past 6 weeks"
                    tooltipClass="toolbar-title-tooltip" />
                </div>,
                accessor: 'new_release',
                sortable: false,
                Cell: ({value}) => {
                  return (
                    value ? heartbeatsConstants.CHECK_MARK_SYMBOL : heartbeatsConstants.CROSS_SYMBOL
                  )
                },
//                filterable: true,
//                filterMethod: (filter, row) => {
//                    const value = Number(filter.value);
//                    if(value === 0)
//                        return true;
//                    
//                    return (value === 1 ? true : false ) === row._original.new_release;
//                },                
//                Filter: ({ filter, onChange }) => {                
//                    return <div className="inline-filter-holder filter-select">
//                    <div className="inline-filter-title">New Release</div>
//                        <select
//                        value={this.state.newRelease}
//                        onChange={event => {
//                            this.onFilteredChangeCustom(event.target.value, 'new_release');
//                        }}
//                        >
//                        <option value={0}>All</option>
//                        <option value={1}>New Release</option>
//                        <option value={2}>Catalog</option>
//                        })}
//                        </select>
//                    </div>
//                }
              },
              {
                id: 'percent_change',
                className: 'imprint-name',
                headerClassName: 'filter-cell inline-filter',
                Header: 'Percent change',
                accessor: 'percent_change',
                //filterable: true,
                // width: 125,
                //filterMethod: (filter, row) => {
                  //return row[filter.id] >= parseInt(filter.value);
                //},
                Cell: ({value}) => {
                  return value + heartbeatsConstants.PERCENT_SYMBOL;
                },
//                Filter: ({ filter, onChange }) => {
//                  return (
//                    <div className="inline-filter-holder">
//                      <div className="inline-filter-title">Percent change</div>
//                      <SelectFieldFilter
//                        onChange={this.onFilteredChangeCustom}
//                        value={this.state.percentage}
//                        options={this.state.percentChangeOptions}
//                        symbol={heartbeatsConstants.PERCENT_SYMBOL}
//                        fieldName="percent_change"
//                        accessor="percent_change" 
//                      />
//                    </div>
//                  )
//                }
              }
            ]}
            manual
            pages={pages}
            page={this.state.page}
            data={data}
            defaultPageSize={this.state.pageSize}
            defaultSorted={this.state.defaultSorted}
            onFetchData={this.loadData}
            defaultSortDesc={true}
          />
        </div>
      <Helmet>
          <title>New Releases - Heartbeats</title>
      </Helmet>

      </div>
    );
  }
}

function mapDispatchToProps(dispatch){
  return {
    getUserNotificationSettings: () => dispatch(notificationReportsActions.getUserNotificationSettings()),
    loadData: params => dispatch(heartbeatsActions.getPagedHeartbeats(params))
  };
}

function mapStateToProps(state) {
  return {
    playlists: state.playlists,
    filter: state.filter,
    heartbeats: state.heartbeats,
    settings: state.notification_reports.settings
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Heartbeats))