import React from 'react';
import { find } from 'lodash';
import WorldMap from '../../WorldMap';
import AbsoluteValue from '../../AbsoluteValue';
import PercentageValue from '../../PercentageValue';
import ReactTable from "react-table";
import cellHeaderHOC from '../../Datagrid/CellHeaderHOC';
import { territorySettings } from './Settings';
import {convertToThousands} from '../../../../helpers/NumberFormatter';
const CellHeaderTable = cellHeaderHOC(ReactTable);

class TerritoryStatsMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentCountry: null,

        };
        this.onRegionClick = this.onRegionClick.bind(this);
        this.renderSelectedRow = this.renderSelectedRow.bind(this);         
    };    
    


    onRegionClick(code) {
        this.setState({
            currentCountry: code
        })
    }
    
    highlightRow(data, code) {
        return data.map(item=>{
            item.selected = item.territory == code;
            return item;
        })
    }
    
    renderSelectedRow (data, code) {
        const selectedRow = find(data, {territory: code});
        if(selectedRow) {
            return <div className="territory-details-description">
            <h2 className="content-title lowercase">{selectedRow.share}% of your streams </h2>
            <h4 className="content-subtitle">are from {selectedRow.territory_name}</h4>
            <div className={`description ${selectedRow.units_diff > 0 ? 'up' : 'down'}`}>
                <span className="icon fa"></span>
                <p>You have <strong><AbsoluteValue value={selectedRow.curr_units} /></strong> streams, with a <span className="num lowercase"><PercentageValue field="units" item={selectedRow} onlyPercentage={true} /></span> <span class="growth-text">{selectedRow.units_diff > 0 ? 'growth' : 'shrinking'}</span></p> 
            </div>
            </div>    
        }
    }
    

    render () {
        const { expanded, data } = this.props;
        return <div className="row territory-map">
            {expanded && <div className="col-sm-6 col-xs-12">
                {this.state.currentCountry && this.renderSelectedRow(data.table, this.state.currentCountry)}
                <div className="territory-table custom-scroll">
                    <div className="territory-table-inner">
                        <CellHeaderTable
                            className="playlist-table territory-table-holder"
                            data={this.highlightRow(data.table, this.state.currentCountry)}
                            columns={territorySettings.settingsForTable('map')}
                            defaultPageSize={10}
                            showPagination={false}
                            getTrProps={(state, rowInfo)=>{
                                return {
                                    className: `${rowInfo.row._original.selected ? 'selected' : ''}`,
                              }}}
            
                        />
                    </div>
                </div>
            </div>}
            <div className={expanded?'col-sm-6 col-xs-12 territory-map-holder':'col-xs-12'}>
                <WorldMap data={data.world} label="streams" onRegionClick={this.onRegionClick} /> 
            </div>
        </div>
    }
}
export default TerritoryStatsMap;