import React from 'react';
import { map } from 'lodash';
import AbsoluteValue from '../../AbsoluteValue';
import { percentageValue } from '../../../../helpers/NumberFormatter';

export const streamsSettings = {
    settingsForTable
};


function settingsForTable(){
    let columns = [
       {
           id: 'track_title',
           className: 'imprint-name',
           headerClassName: 'imprint-name',
           Header: 'Track',
           accessor: 'track_title',
           // width: 120
       },
       {
           id: 'curr_skipped',
           headerClassName: 'table-header-item',
           Header: <span><i className="color-red fa fa-step-forward" /></span>,
           Cell: props => <AbsoluteValue className="color-red" value={props.value}></AbsoluteValue>,
           // width: 40,
           accessor: 'curr_skipped'
       },
       {
           id: 'completed',
           headerClassName: 'table-header-item',
           Header: <span><i className="color-light-green fa fa-play" /></span>,
           Cell: props => <AbsoluteValue className="color-light-green" value={props.value}></AbsoluteValue>,
           // width: 40,
           accessor: 'curr_completed'
       },
       {
           id: 'total',
           headerClassName: 'table-header-item',
           Header: 'Total',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 50,
           accessor: 'curr_units'
       },
       {
           id: 'completed_diff',
           headerClassName: 'table-header-item no-sort',
           Header: 'Ratio',
           sortable: false,
           accessor: 'completed_diff',
           Cell: props => <span>{Math.round(props.original['curr_completed']/props.original['curr_units']*100)}%</span>,
           // width: 50
       },
       /*
       {
           id: 'curr_listeners',
           headerClassName: 'table-header-item listeners',
           className: 'listeners',
           Header: 'Listeners',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 50,
           accessor: 'curr_listeners'
       },       
       */

    ];
    return columns;
}