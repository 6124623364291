import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PercentageValue from '../../../common/PercentageValue';
import AbsoluteValue from '../../../common/AbsoluteValue';
import Tooltip from '../../../common/Tooltip';
import EntityCard from '../../../common/SharedEntityCard';
import ConfirmClick from '../../../common/ConfirmClick';
import { splitByThousands, percentageValue } from '../../../../helpers/NumberFormatter';

export const exceptionsSettings = {
    settingsForTable
};

function formatCell(value){return splitByThousands(Number.parseFloat(value).toFixed(2), '$')};

function settingsForTable(loadData){

    let columns = [
            {
                id: 'label',
                Header: 'Label',
                accessor: 'label',
            },
            {
                id: 'entity',
                Header: 'Entity',
                accessor: 'entity',
                width: 50,
            },
            {
                id: 'identifier',
                Header: 'Identifier',
                accessor: 'identifier',
            },
            {
                id: 'totals_rows',
                Header: 'Totals Rows',
                accessor: 'totals_rows',
                width: 50,
            },
            {
                id: 'dates',
                Header: 'Dates',
                accessor: 'dates',
                width: 50,
            },
            {
                id: 'income',
                Header: 'Income',
                accessor: 'income',
                Cell: props => <span>{formatCell(props.value)}</span>,
            },
            {
                id: 'extract_date',
                Header: 'Extract Date',
                accessor: 'extract_date',
                Cell: (props)=><span>{moment(props.value).format('YYYY-MM-DD')}</span>
            },
            /*
            {
                id: 'status',
                Header: 'Status',
                accessor: 'status',
                width: 50
            }
            */
            ];
            
    return columns; 
}
