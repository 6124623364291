import React from 'react';
import { Link } from 'react-router-dom';
import PercentageValue from '../../common/PercentageValue';
import AbsoluteValue from '../../common/AbsoluteValue';
import VendorLogo from '../../common/VendorLogo';
import VendorHelper from '../../common/Datagrid/VendorHelper';
import FieldsSelectorHelper from '../../common/Datagrid/FieldsSelectorHelper';
import VendorValue from '../../common/Datagrid/VendorValue';
// import TrendsBox from '../../common/Datagrid/TrendsBox';
import PlaylistAnalysisLink from '../../common/Datagrid/PlaylistAnalysisLink';
import AudienceAnalysisLink from '../../common/Datagrid/AudienceAnalysisLink';
import EntityStatus from '../../common/Datagrid/EntityStatus';
import DetailsLink from '../../common/Datagrid/DetailsLink';
import AccountSplitBox from '../../common/Datagrid/AccountSplitBox';
import Tooltip from "../../common/Tooltip";

var heartFullSvg = require('!svg-inline-loader!../../../../public/img/heart-full.svg');
var ghostFullSvg = require('!svg-inline-loader!../../../../public/img/ghost-full.svg');

export const imprintsSettings = {
    settingsForPercentageChart,
    settingsForTable
};

function settingsForPercentageChart(){
    return {};
}

function settingsForTable(enabledColumns, onTitleClick, sources, expandSource){
    const mainSourceExpanded = sources ? sources['Main'] : true;
    let columns = [{
        id: 'general',
        Header: '',
        columns: [
          {
              id: 'favhide',
              className: 'logo-sell frozen favhide',
              headerClassName: 'logo-sell frozen favhide',
              HeaderAlt: 'Favorite/Hidden',
              width: 70,
              sortable: false,
              Header: "",
              Cell: props => {
                  return <EntityStatus entity="imprints" id={props.original.id} />
              }
          },
                  
            {
                id: 'logo',
                className: 'logo-sell frozen',
                headerClassName: 'logo-sell frozen',
                Header: 'Logo',
                accessor: 'image',
                width: 60,
                sortable: false,
                Cell: props => {
                    const onClickCall = (e)=>{return onTitleClick(props.original);};
                    return <div className="logo-container" onClick={onClickCall}><div className="logo" style={{backgroundImage: `url(${props.original.image})`}} /></div>;
                }
            },
            {
                id: 'imprint_name',
                className: 'imprint-name frozen',
                headerClassName: 'imprint-name frozen no-sort',
                Header: 'Imprint',
                accessor: 'name',
                sortable: false,
                width: 260,
                Cell: props => {
                    const onClickCall = (e)=>{return onTitleClick(props.original);};
                    return <span>{props.original.id ? <Link to={`/imprints/${props.original.id}`}>{props.value}</Link> : props.value}</span>
                    //return <span>{props.value}</span>;
                }
            },
            {
                id: 'links',
                className: 'lowercase grid-td',
                headerClassName: 'table-playlist-link no-sort',
                Header: 'Actions',
                sortable: false,
                Cell: props => <div className="action-icons">
                    <PlaylistAnalysisLink  title="Imprint Playlist Analysis" entity="imprints" id={props.original.id}></PlaylistAnalysisLink>
                    {/*<DetailsLink title="Imprint Details" entity="imprints" id={props.original.id}></DetailsLink>*/}
                    <AudienceAnalysisLink title="Imprint Audience" entity="imprints" id={props.original.id}></AudienceAnalysisLink>
                </div>
                // width: 105
            },
            
            {
                id: 'curr_units',
                className: 'lowercase grid-td',
                Header: <p><span>Total </span> <a onClick={(e)=>expandSource('Main', e)}><i className={`fa ${mainSourceExpanded ? 'fa-angle-double-left':'fa-angle-double-right'}`} /></a></p>,
                Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
                // width: 105
            },
            {
                id: 'skipped_ratio',
                accessor: 'skipped_ratio',
                className: 'lowercase grid-td',
                headerAlt: 'Skip Rate',
                Header: props => <div className="top-table-header-inner">
                    Skip Rate
                    <Tooltip 
                        position="bottom" 
                        message={`The Skip rate only applies to streams over 30 seconds. It's a metric provided by all DSPs except YouTube and Google.`} 
                        tooltipClass="toolbar-title-tooltip" />
                    </div>,
                Cell: props => <span>{props.value}%</span>,
                expanded: mainSourceExpanded
                // width: 105
            },
            {
                id: 'completed_ratio',
                accessor: 'completed_ratio',
                className: 'lowercase grid-td',
                headerAlt: 'Completion Rate',
                Header: props => <div className="top-table-header-inner">
                    Completion Rate
                    <Tooltip 
                    position="left-bottom" 
                    message={`A track is marked as complete when the stream reached 95% of the track length. It's a metric provided by all DSPs except YouTube and Google.`} 
                    tooltipClass="toolbar-title-tooltip" />
                </div>,
                Cell: props => <span>{props.value}%</span>,
                width: 120,
                expanded: mainSourceExpanded
            },
            /*
            {
                id: 'completed_diff',
                headerClassName: 'table-header-item no-sort',
                Header: props => <div className="top-table-header-inner">
                    Ratio S/C 
                    <Tooltip 
                        position="bottom" 
                        message={`S = Skips / C = Completion\n
                        These metrics only apply to streams over 30 seconds. A track is marked as complete when the stream reaches 95% of the track length.\n
                        They are provided by all DSPs except Google and YouTube`} 
                        tooltipClass="toolbar-title-tooltip" />
                    </div>,
                accessor: 'completed_diff',
                headerAlt: 'Ratio S/C',
                Cell: props => <div>
                <span title="Skipped">{props.original.skipped_ratio}%</span>
                /
                <span title="Completed">{props.original.completed_ratio}%</span>
                </div>,
                 
                sortable: false,
                // width: 130
            },
            */
            {
                id: 'sub30',
                Header: props => <div className="top-table-header-inner">
                    Sub30 
                    <Tooltip 
                        position="bottom" 
                        message={`Sub30 refers to streams that did not hit the 30” mark and therefore are excluded from the total streams and skips count as non-royalty bearing metric. This is currently only available from Spotify.`} 
                        tooltipClass="toolbar-title-tooltip" />
                    </div>,
                accessor: 'sub30',
                headerAlt: 'Sub30',
                Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
                expanded: mainSourceExpanded
                // width: 134
            },
            {
                id: 'saves_ratio',
                Header: 'Saves',
                accessor: 'saves_ratio',
                Cell: props => <span title="Skipped">{props.value}%</span>,
                expanded: mainSourceExpanded
                // width: 134
            },

            
            {
                id: 'accounts',
                className: 'account-td',
                headerClassName: 'no-sort',
                headerAlt: 'Account Type',
                Header: props => <div className="top-table-header-inner">
                    Accounts F/D/P 
                    <Tooltip 
                        position="bottom" 
                        message={`F = Freemium / D = Discounted / P = Premium\n
                        These are provided by all DSPs except YouTube.\n
                        Note that Spotify only provide Freemium or Premium type, so all Discounted accounts as under Premium`} 
                        tooltipClass="toolbar-title-tooltip" />
                    </div>,
                Cell: props => <AccountSplitBox item={props.original} />,
                expanded: mainSourceExpanded,
                width: 110
            },       
            {
                id: 'discovery_ratio',
                Header: props => <div className="top-table-header-inner">
                    Discovery 
                    <Tooltip 
                        position="left-bottom" 
                        message={`Discovery Flag shows you the % of total streams which were a first listen from a user. This is provided by Apple and Spotify only.`} 
                        tooltipClass="toolbar-title-tooltip" />
                    </div>,
                accessor: 'discovery_ratio',
                headerAlt: 'Discovery',
                Cell: props => <span title="Discovery">{props.value}%</span>,
                expanded: mainSourceExpanded
                // width: 134
            },            
            /*
            {
                id: 'curr_listeners',
                className: 'lowercase grid-td listeners',
                Header: 'Listeners',
                Cell: props => <PercentageValue field="listeners" item={props.original}></PercentageValue>,
                // width: 105
            },
            */
            /*            
            {
                id: 'curr_playlists',
                className: 'lowercase grid-td',
                Header: 'Playlists',
                Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
                width: 105
            },
            {
                id: 'curr_products',
                className: 'lowercase grid-td',
                Header: 'Products',
                Cell: props => <PercentageValue field="products" item={props.original}></PercentageValue>,
                width: 105
            },
            {
                id: 'curr_tracks',
                className: 'lowercase grid-td',
                Header: 'Tracks',
                Cell: props => <PercentageValue field="tracks" item={props.original}></PercentageValue>,
                width: 105
            },
            */
            /*{
                id: 'stream',
                className: 'lowercase grid-td total-td',
                headerClassName: 'total-td no-sort',
                Header: 'Stream',
                sortable: false,
                // width: 525,
                Cell: props => <TrendsBox name="Stream" trends={props.original.trends}></TrendsBox>
            },
            {
                id: 'video',
                className: 'lowercase grid-td total-td',
                headerClassName: 'total-td no-sort',
                Header: 'Video',    
                sortable: false,
                // width: 310,            
                Cell: props => <TrendsBox name="Video" trends={props.original.trends}></TrendsBox>
            },                      
            {
                id: 'radio',
                className: 'lowercase grid-td total-td',
                headerClassName: 'total-td no-sort',
                Header: 'Radio',    
                sortable: false,
                // width: 310,            
                Cell: props => <TrendsBox name="Radio" trends={props.original.trends}></TrendsBox>
            },
            {
                id: 'downloads',
                className: 'lowercase grid-td total-td',
                headerClassName: 'total-td no-sort',
                Header: 'Downloads',    
                sortable: false,
                // width: 310,            
                Cell: props => <TrendsBox name="Downloads" trends={props.original.trends}></TrendsBox>
            }
            }*/                        

        ]
    }

    ];

    for(let source of VendorHelper.VendorSources()){
        const sourceExpanded = sources ? sources[source] : true,
            expandClass = `fas ${sourceExpanded ? 'fa-angle-double-left':'fa-angle-double-right'}`;
        
        let column = VendorHelper.VendorDistribution(source);
        column.headerClassName = `top-header-${source.toLowerCase().replace(' ', '-')}`;
        const vendorsQty = column.columns.length;
        
        if(vendorsQty > 1)
            column.Header = <span>{source} <a onClick={()=>expandSource(source)}><i className={expandClass} /></a></span>;
        
        
        for(let vendorID = 0; vendorID < vendorsQty; vendorID++) {
            let vendor = column.columns[vendorID];
            vendor.Header = props => vendor.accessor == 'total'?<span>Total</span>:<VendorLogo name={vendor.accessor} />;
            vendor.headerClassName = `no-sort header-${source.toLowerCase().replace(' ', '-')}${vendorID==0?' first':''}`;
            vendor.sortable = false;
            vendor.Header = props => <VendorLogo name={vendor.accessor} />;
            vendor.Cell = props => <VendorValue source={source} vendor={vendor.accessor} trends={props.original.trends} />
            vendor.className = `vendor-cell vendor-cell-${source.toLowerCase().replace(' ', '-')}${vendorID==0?' first':''}`;
            vendor.expanded = sourceExpanded;
        }
        columns.push(column);
    }
    
    return FieldsSelectorHelper.filterFields(columns, enabledColumns); 
}
