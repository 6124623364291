import React, {Fragment} from 'react';
import {Text, View, StyleSheet, Font } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    cell: {
        textAlign: 'left',
        borderColor: "#E8E8EA",
        borderBottomWidth: 1,
        borderRightWidth: 1,
        display: "block",
        height: 20,
        paddingTop: 5,
        paddingLeft: 5,
        fontFamily: "Roboto",
        fontSize: 5,
        overflow: 'visible',
        textOverflow: 'initial'
    },
  });

Font.register({
    family: "Roboto",
    src:
      "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf"
});

const TableRow = ({items, columns}) => {
    const rows = items.map( item => 
        <View style={styles.row} >
            {columns.map(column=>{
                let cellText ;
                if(item[column.key]) {
                    if(column.formatFn)
                        cellText = column.formatFn(item[column.key]);
                    else
                        cellText = item[column.key];
                }
                else
                    cellText = '-';

                let width = String(column.width);
                if(width.match(/\d$/))
                    width += '%';

                return <Text key={cellText} style={{...styles.cell, width: width?`${width}`:'auto', fontSize: column.fontSize}}>{cellText}</Text>}
            )} 
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default TableRow