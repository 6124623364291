import React from 'react';
import moment from 'moment';
import { concat, uniq } from 'lodash';
import {round, splitByThousands } from '../../../helpers/NumberFormatter';
import Tooltip from "../../common/Tooltip";
import VendorBarChart from "./Vendor/BarChart";

function getName(item) {
    return <span>{item.name} {item.code && <span className="newline">{item.code}</span>} </span>  
}
function getVendors(item) {
    return <VendorBarChart vendors={item.vendors} />
}

export default function EntityVendors(props){
    let headers = [
        {text: 'Name'},  
        {text: 'Streams'}, 
        {text: 'Streams growth'}, 
        {text: 'Vendors'}, 
    ],
    cells = (item)=>[getName(item),
        splitByThousands(item.curr_units),
        round(item.units_diff)+'%',
        getVendors(item)
    ],
    preExtraHeaders = [], 
    preExtraCells = (item)=> [];          

    if (props.entity == 'products' || props.entity == 'tracks') {
        preExtraHeaders = [
            {text: 'Artist'}
        ];
        preExtraCells = (item)=> [
            item.artist_name
        ];
        
    };
    
    
    let allHeaders = concat(preExtraHeaders, headers);
    let allVendorsHash = {},
        allVendors = [];
    for(let item of props.results) {
        if(item.vendors) {
            for (let vendor of item.vendors) {
                if(!allVendorsHash[vendor.vendor_value])
                    allVendorsHash[vendor.vendor_value] = vendor.color; 
            }
        }
    }
    for(let vendor of Object.keys(allVendorsHash))
        allVendors.push(<li key={vendor}><span><i className="fas fa-circle" style={{color: allVendorsHash[vendor]}} /> {vendor}</span></li>)
        
    return <div className="row">
        <div className="col-sm-9">
            <div className="top-table-holder">
                <table className="table table-striped no-margins table-hover ">
                    <thead>
                        <tr>
                            {allHeaders.map(header=><th className="top-table-header">
                                <div className="top-table-header-inner">
                                    {header.text}
                                    {header.tooltipText ? (
                                        <Tooltip position="bottom" message={header.tooltipText} tooltipClass="toolbar-title-tooltip"/>
                                    ) : null}
                                </div>
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {props.results.map((item, index)=>{
                            let allCells = props.entity !== 'playlists' ? concat(preExtraCells(item), cells(item)) : cells(item);
                            return <tr key={item.id} className={index % 2 == 0 ? "gradeX" : "gradeC"}>
                                {allCells.map(tdItem=><td>{tdItem}</td>)}
                        </tr>})}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="col-sm-3">
            <ul>
                {allVendors}
            </ul>
        </div>
    </div>    
}
