import React from 'react';
import Spinner from './Spinner'; 

class Box extends React.Component {
    render() {
        const { className, title, toolbar, spinnerEnabled, children, loadingMessage, hasTooltip, loadingTooltip, showLink, vendorLogoList, description, ...restProps } = this.props;
        const message = (spinnerEnabled && loadingMessage) ? <p className="loading-message">{loadingMessage}</p> : null;
        const tooltip = hasTooltip ? <div className="toolbar-title-tooltip">{loadingTooltip}</div> : null;

        return (
            <div className={`ibox float-e-margins ${className}`}>
                <div className="ibox-title"  {...restProps}>
                    <h2 className="ibox-title__title">
                        {title}
                        {tooltip}
                        {showLink}
                        {vendorLogoList}
                    </h2>
                    <div className="ibox-tools">
                        {toolbar}
                    </div>
                    { description && <div className="title-description">{description}</div> }
                </div>
                <div className="ibox-content"  {...restProps}>
                    {message}
                    {(!message && spinnerEnabled) && <p>Loading...</p>}
                    {children}
                </div>
            </div>            
        )
    }
}

export default Box