import store from '../../helpers/store';
import { orderBy, isEqual } from 'lodash';
import { globalFilterConstants } from '../constants/global_filter';
import { globalFilterService } from '../services/global_filter';
import { globalFilterFormatter } from '../formatters/global_filter';

export const globalFilterActions = {
    addEntity,
    getGlobalFilter,
    deleteEntity
};

function addEntity(type, id, favorite){
    return dispatch => {
        dispatch(request());
    
        return globalFilterService.addEntity(type, id, favorite)
        .then(response => {
            const data = globalFilterFormatter.formatGlobalFilter(response.data);
            store.set('globalFilter', data);
            dispatch(success(data))
        })
        .catch( error => dispatch(failure(error.message)));
    }

    function request(){ return { type: globalFilterConstants.ADD_ENTITY_REQUEST }};
    function success(data){ return { type: globalFilterConstants.ADD_ENTITY_SUCCESS, data }};
    function failure(data){ return { type: globalFilterConstants.ADD_ENTITY_FAILURE, data }};
}

function getGlobalFilter(id='system') {
    return dispatch => {
        dispatch(request());
    
        globalFilterService.getGlobalFilter(id)
        .then(response => {
            const data = globalFilterFormatter.formatGlobalFilter(response.data);
            store.set('globalFilter', data);
            dispatch(success(data));
        })
        .catch( error => dispatch(failure(error.message)));
    }

    function request(){ return { type: globalFilterConstants.GET_GLOBAL_FILTER_REQUEST }};
    function success(data){ return { type: globalFilterConstants.GET_GLOBAL_FILTER_SUCCESS, data }};
    function failure(data){ return { type: globalFilterConstants.GET_GLOBAL_FILTER_FAILURE, data }};
}

function deleteEntity(id){
    return dispatch => {
        dispatch(request());
    
        return globalFilterService.deleteEntity(id)
        .then(response => {
            // todo: global filter
            dispatch(success(id))
        })
        .catch( error => dispatch(failure(error.message)));
    }

    function request(){ return { type: globalFilterConstants.DELETE_ENTITY_REQUEST }};
    function success(id){ return { type: globalFilterConstants.DELETE_ENTITY_SUCCESS, id }};
    function failure(data){ return { type: globalFilterConstants.DELETE_ENTITY_FAILURE, data }};
}
