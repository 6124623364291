import store from '../../helpers/store';
import { userConstants } from '../constants/user';

const currentUser = store.get('user');
const initialState = currentUser ? { loggedIn: true, user: currentUser } : {loggedIn: false}; 

export function user(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                loggedIn: false,
                user: undefined
                
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                
                ...state,
                loading: false,
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                loggedIn: false,
                error: action.error
            };
        /*
        case userConstants.LOGOUT_SUCCESS:
            return {
                loggedIn: false
            };
        */    
        case userConstants.AVATAR_UPLOAD_REQUEST:
            return {
                ...state,
                avatarLoading: true,
                
            };
        case userConstants.AVATAR_UPLOAD_SUCCESS:
            return {
                
                ...state,
                avatarLoading: false,
                user: {
                    ...state.user,
                    avatar_url: action.avatar
                }
            };
        case userConstants.AVATAR_UPLOAD_FAILURE:
            return {
                ...state,
                avatarLoading: false
            };
            
      default:
            return state
    }
} 