import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';
import {searchService} from './search';

export const campaignsService = {
    getCampaigns,
    getCampaignDetails,
    addCampaign,
    editCampaign,
    deleteCampaign,
    addSocialChannel,
    addSalesChannel,
    deleteSalesChannel,
    deleteSocialChannel
};

function getCampaigns(params, globalFilter){
    return config.live ? getCampaignsLive(params, globalFilter) : getCampaignsStatic();
}

function getCampaignsLive(params, globalFilter) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/campaigns`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
    
}

function getCampaignsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/campaigns.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}



function getCampaignDetails(id, globalFilter){
    return config.live ? getCampaignDetailsLive(id, globalFilter) : getCampaignDetailsStatic();
}

function getCampaignDetailsLive(id, globalFilter) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/campaigns/${id}`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
    
}

function getCampaignDetailsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/campaign.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function addCampaign(data) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/campaigns`;
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function editCampaign(data, id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/campaigns/${id}`;
    return fetch(baseUrl, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function deleteCampaign(id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/campaigns/${id}`;
    return fetch(baseUrl, {
        method: 'DELETE',
        headers
    })
    .then(res=>{   
        return res.json()
    })
    
}

function addSocialChannel(data, campaign) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/campaigns/${campaign.id}/add_social_channel`;
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function addSalesChannel(data, campaign) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/campaigns/${campaign.id}/add_sales_channel`;
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function deleteSalesChannel(id, campaignID) {
    let baseUrl = `${config.apis.orthus}/v1/campaigns/${campaignID}/remove_sales_channel/${id}`;
    return fetch(baseUrl, {
        method: 'DELETE',
        headers: AuthHeader()
    })
    .then(res=>{   
        return res.json()
    });
}

function deleteSocialChannel(id, campaignID) {
    let baseUrl = `${config.apis.orthus}/v1/campaigns/${campaignID}/remove_social_channel/${id}`;
    return fetch(baseUrl, {
        method: 'DELETE',
        headers: AuthHeader()
    })
    .then(res=>{   
        return res.json()
    });
}
