import { importsConstants } from '../constants/imports';

export function imports(state = {extract: {physical:{}, digital:{}}, upload: {physical:{}, digital:{}}, import: {physical:{}, digital:{}} }, action) {
    switch (action.type) {
        case importsConstants.GET_STATEMENT_PERIODS_REQUEST:
            return {
                ...state,
                periodsLoading: true,
                periods: undefined
            };
        case importsConstants.GET_STATEMENT_PERIODS_SUCCESS:
            return {

                ...state,
                periodsLoading: false,
                periods: action.imports,
            };
        case importsConstants.GET_STATEMENT_PERIODS_FAILURE:
            return {
                ...state,
                periodsLoading: false,
                periods: undefined,
                error: action.error
            };  
            
        case importsConstants.GET_PENDING_STATEMENTS_REQUEST:
            return {
                ...state,
                statementsLoading: true,
                statements: undefined
            };
        case importsConstants.GET_PENDING_STATEMENTS_SUCCESS:
            return {

                ...state,
                statementsLoading: false,
                statements: action.imports,
            };
        case importsConstants.GET_PENDING_STATEMENTS_FAILURE:
            return {
                ...state,
                statementsLoading: false,
                statements: undefined,
                error: action.error
            };  

        case importsConstants.EXTRACT_SALES_DATA_REQUEST:
            return {
                ...state,
                extract: {
                    ...state.extract,
                    [action.source]: {
                        loading: true,
                        success: false
                    }
                }
            };
        case importsConstants.EXTRACT_SALES_DATA_SUCCESS:
            return {
                ...state,
                extract: {
                    ...state.extract,
                    [action.source]: {
                        loading: false,
                        success: true
                    }
                }
            };
        case importsConstants.EXTRACT_SALES_DATA_FAILURE:
            return {
                ...state,
                extract: {
                    ...state.extract,
                    [action.source]: {
                        loading: false,
                        success: true
                    }
                }
            };  

        case importsConstants.UPLOAD_TO_STORAGE_REQUEST:
            return {
                ...state,
                upload: {
                    ...state.upload,
                    [action.source]: {
                        loading: true,
                        success: false
                    }
                }
            };
        case importsConstants.UPLOAD_TO_STORAGE_SUCCESS:
            return {
                ...state,
                upload: {
                    ...state.upload,
                    [action.source]: {
                        loading: false,
                        success: true
                    }
                }
            };
        case importsConstants.UPLOAD_TO_STORAGE_FAILURE:
            return {
                ...state,
                extract: {
                    ...state.upload,
                    [action.upload]: {
                        loading: false,
                        success: true
                    }
                }
            };            

        case importsConstants.IMPORT_SALES_DATA_REQUEST:
            return {
                ...state,
                import: {
                    ...state.import,
                    [action.source]: {
                        loading: true,
                        success: false
                    }
                }
            };
        case importsConstants.IMPORT_SALES_DATA_SUCCESS:
            return {
                ...state,
                import: {
                    ...state.import,
                    [action.source]: {
                        loading: false,
                        success: true
                    }
                }
            };
        case importsConstants.IMPORT_SALES_DATA_FAILURE:
            return {
                ...state,
                import: {
                    ...state.import,
                    [action.upload]: {
                        loading: false,
                        success: true
                    }
                }
            };
        case importsConstants.APPROVE_SALES_DATA_REQUEST:
        case importsConstants.REJECT_SALES_DATA_REQUEST:
            return {
                ...state,
                approveRejectLoading: true
            };
            
            
        case importsConstants.APPROVE_SALES_DATA_SUCCESS: 
        case importsConstants.APPROVE_SALES_DATA_FAILURE: 
        case importsConstants.REJECT_SALES_DATA_SUCCESS:  
        case importsConstants.REJECT_SALES_DATA_FAILURE: 
            return {
                ...state,
                approveRejectLoading: false
            };

        case importsConstants.CATALOG_IMPORT_REQUEST:
            return {
                ...state,
                catalogImportLoading: true
            };
        case importsConstants.CATALOG_IMPORT_SUCCESS:
            return {

                ...state,
                catalogImportLoading: false,
                catalogImport: action.importData
            };
        case importsConstants.CATALOG_IMPORT_FAILURE:
            return {
                ...state,
                catalogImportLoading: false                
            };
            
                    
        case importsConstants.CATALOG_IMPORT_ERRORS_REQUEST:
            return {
                ...state,
                importErrorsLoading: true
            };
        case importsConstants.CATALOG_IMPORT_ERRORS_SUCCESS:
            return {

                ...state,
                importErrorsLoading: false,
                importErrors: action.errors
            };
        case importsConstants.CATALOG_IMPORT_ERRORS_FAILURE:
            return {
                ...state,
                importErrorsLoading: false       
            };
            
        case importsConstants.CATALOG_EXCEPTIONS_REQUEST:
            return {
                ...state,
                exceptionsLoading: true
            };
        case importsConstants.CATALOG_EXCEPTIONS_SUCCESS:
            let exceptions = action.exceptions === undefined ? state.exceptions : action.exceptions;
            return {

                ...state,
                exceptionsLoading: false,
                exceptions
            };
        case importsConstants.CATALOG_EXCEPTIONS_FAILURE:
            return {
                ...state,
                exceptionsLoading: false       
            };            

        case importsConstants.GET_CATALOG_IMPORTS_REQUEST:
            return {
                ...state,
                catalogImportsLoading: true
            };
        case importsConstants.GET_CATALOG_IMPORTS_SUCCESS:
            return {

                ...state,
                catalogImportsLoading: false,
                catalogImports: action.imports
            };
        case importsConstants.GET_CATALOG_IMPORTS_FAILURE:
            return {
                ...state,
                catalogImportsLoading: false                
            };
            
            
        default:
            return state
    }
}
