import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { merge, map } from 'lodash';
import multiselect from 'bootstrap-multiselect';

class Multiselect extends React.Component {
    defaultOptions = {
        numberDisplayed: 1,
        maxHeight: 310,
        enableFiltering: false,
        enableCaseInsensitiveFiltering: false,
        enableCollapsibleOptGroups: true,
        buttonText: function(options, select) { // fix 0 number displayed
            if (options.length === 0) {
                return select.attr('data-placeholder');
            }
            else {
                if(select.attr('multiple')) {
                    return options.length + ' selected';
                }
                else {
                    return options.eq(0).text();  
                }
            }    
        },
        templates: {            
            filter: '<li class="multiselect-item multiselect-filter"><div class="input-group"><input class="form-control multiselect-search" type="text" placeholder="Search"><span class="input-group-addon"><i class="glyphicon glyphicon-search"></i></span></div></li>',
            filterClearBtn: '',
        }
    };
    
    constructor(props){
        super(props);
        this.state = {
            multiple: Array.isArray(props.selected)
        }

        this.isElementSelected = this.isElementSelected.bind(this);
    }

    componentDidMount() {
        this.select = $(ReactDOM.findDOMNode(this.refs.select));
        this.select.multiselect(merge(this.defaultOptions, this.props.options));
        // collapse groups
        const container = this.select.parent();
        container.find('.caret-container').trigger('click');
        this.select.on('change', (e)=>this.props.handleChange(this.select.val()));
        // equal width
        const button = container.find('button.dropdown-toggle'),
            dropdown = container.find('ul.multiselect-container');
        if(dropdown.outerWidth()>button.outerWidth()){
            button.outerWidth(dropdown.outerWidth());
        }
        else {
            dropdown.outerWidth(button.outerWidth());
        }
    }

    componentWillUnmount(){
        this.select.multiselect('destroy');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selected !== this.props.selected) {
          this.select.multiselect('refresh');
        }
    }    

    isElementSelected(element){
        const { selected } = this.props;
        return this.state.multiple ? selected.includes(element) : selected == element
    }

    renderElements(elements){
        return map(elements, (element)=>{
            if(element instanceof Object) // key-value hash
                return <option key={element.value} value={element.value} >{element.label}</option>
            else // plain array, label equals value
                return <option key={element} value={element} >{element}</option>
        }); 
    }
    
    renderGroups(){
        
        let results = [],
            { elements } = this.props;
        
        // hash of arrays, for nested elements with optgroups
        if(elements instanceof Object && Object.values(elements)[0] instanceof Array) {
            for(let group in elements) {
                let options = this.renderElements(elements[group]);
                results.push(<optgroup key={group} label={group}>{options}</optgroup>)
            }
            
        }
        else
            results = this.renderElements(elements);

        return results; 
    }

    render() {
      return (
        <div className={`form-group chosen-group ${this.props.className}`}>          
            <label className="font-normal">{this.props.label}</label>
            <div className="">
                <select className="form-control" ref="select" multiple={this.state.multiple} value={this.props.selected} data-placeholder={this.props.label} onChange={()=>{}} >
                    {this.renderGroups()}
                </select>
            </div>
        </div>
      );
    }
  }

  export default Multiselect;