export const channelsConstants = {
    GET_CHANNEL_TYPES_REQUEST: 'GET_CHANNEL_TYPES_REQUEST',
    GET_CHANNEL_TYPES_SUCCESS: 'GET_CHANNEL_TYPES_SUCCESS',
    GET_CHANNEL_TYPES_FAILURE: 'GET_CHANNEL_TYPES_FAILURE',
    ADD_CHANNEL_REQUEST: 'ADD_CHANNEL_REQUEST',
    ADD_CHANNEL_SUCCESS: 'ADD_CHANNEL_SUCCESS',
    ADD_CHANNEL_FAILURE: 'ADD_CHANNEL_FAILURE',
    DELETE_CHANNEL_REQUEST: 'DELETE_CHANNEL_REQUEST',
    DELETE_CHANNEL_SUCCESS: 'DELETE_CHANNEL_SUCCESS',
    DELETE_CHANNEL_FAILURE: 'DELETE_CHANNEL_FAILURE',
    
}