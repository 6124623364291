import { vendorsConstants } from '../constants/vendors';

export function vendors(state = {}, action) {
    switch (action.type) {
        case vendorsConstants.GET_VENDOR_STATS_REQUEST:
            return {
                ...state,
                loading: true,
                dates: []
            };
        case vendorsConstants.GET_VENDOR_STATS_SUCCESS:
            return {

                ...state,
                loading: false,
                items: action.vendors,
                dates: action.dates
            };
        case vendorsConstants.GET_VENDOR_STATS_FAILURE:
            return {
                ...state,
                loading: false,
                dates: [],
                error: action.error
            };  
            
        case vendorsConstants.CHANGE_VENDOR_STATUS_REQUEST:
            return {
                ...state,
                statusLoading: true,
            };
        case vendorsConstants.CHANGE_VENDOR_STATUS_SUCCESS:
            return {

                ...state,
                statusLoading: false,
            };
        case vendorsConstants.CHANGE_VENDOR_STATUS_FAILURE:
            return {
                ...state,
                statusLoading: false,
            };            
            
        case vendorsConstants.GET_VENDORS_REQUEST:
            return {
                ...state,
                vendorsLoading: true,
            };
        case vendorsConstants.GET_VENDORS_SUCCESS:
            return {

                ...state,
                vendorsLoading: false,
                vendors: action.vendors,
            };
        case vendorsConstants.GET_VENDORS_FAILURE:
            return {
                ...state,
                vendorsLoading: false,
                error: action.error
            };  
            
        default:
            return state
    }
}
