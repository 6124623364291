import React from 'react';
import { convertToThousands, percentageValue } from '../../helpers/NumberFormatter';

export default function PercentageValue(props) {
    const { field, item, onlyPercentage=false } = props,
        curr = Number(item['curr_'+field]),
        prev = Number(item['prev_'+field]) || 0,
        direction = curr >= prev ? 'up' : 'down';
        
    let percentage = percentageValue(curr, prev);
    if(percentage > 100)
        percentage = "> 100";
    else if(percentage < -100)
        percentage = "> -100";
    
    return <span title={curr} className="vendor-value">
        {!onlyPercentage && convertToThousands(curr) }  
        {curr!=prev ?<small className={`${direction} ${props.className}`}> {percentage}%</small> : null}
    </span>    
}
