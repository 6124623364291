import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import Box from '../Box';
import { statsActions } from '../../../data/actions/stats';
import { streamsSettings } from './Streams/Settings';
import LineChart from '../../widgets/charts/types/LineChart';
import CSV from '../../../helpers/CSVExport';
import cellHeaderHOC from '../Datagrid/CellHeaderHOC';

const CellHeaderTable = cellHeaderHOC(ReactTable);
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');


class StreamsStatsWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'chart'
        }
        
        this.setChartMode = this.setChartMode.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);        
    }

    setChartMode(mode){
        this.setState({mode});
    }
    
    
    exportToCsv(){
        const filename = CSV.CSVHeader('details_streams', 'curr_units', this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(this.props.data.tracks, {filename});
    }

    
    renderToolbar(modes){
        let toolbar = [<div className="ibox-action-holder">
            <div className="ibox-icon-holder">
                <a key='chart' title="Line Chart" className={`chart-icon ${this.state.mode == 'chart' ? 'active' : ''}`} 
                    onClick={()=>this.setChartMode('chart')}
                    dangerouslySetInnerHTML={{__html: lineSvg}}>
                </a>
                <a key='table' title="Table" className={`chart-icon ${this.state.mode == 'table' ? 'active' : ''}`} 
                    onClick={()=>this.setChartMode('table')}
                    dangerouslySetInnerHTML={{__html: tableSvg}}>
                </a>
            </div>
            <div className="ibox-actions">
                <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            </div>
        </div>
        ];

        return toolbar;
    }

    
    render (){
        const { data, loading } = this.props;
        
        return <Box title="Streams" toolbar={this.renderToolbar()} spinnerEnabled={loading} >
        {data && <div className="streams-widget-holder">
            {this.state.mode=='chart' && <div className="chart-block"><LineChart data={data.timeline} /></div>}
            {this.state.mode=='table' && <CellHeaderTable 
                className="playlist-table playlist-table--territory responsive-table"
                data={data.tracks} 
                columns={streamsSettings.settingsForTable()}
                defaultPageSize={10}
            />}
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(StreamsStatsWidget)    