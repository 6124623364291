import config from '../../config/config';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';

export const globalFilterService = {
    addEntity,
    getGlobalFilter,
    deleteEntity
}

function addEntity(type, id, favorite){
    const data = {
        entity_type: type, 
        entity_id: id, 
        fav_hide: favorite            
    };
    const baseUrl = `${config.apis.orthus}/v1/user_entity_filters/create_system_entity`;
    
    return fetch(baseUrl, {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify(data)
    })
    .then(res=>res.json());
}

function getGlobalFilter(id){
    const baseUrl = `${config.apis.orthus}/v1/user_entity_filters/${id}`
    
    return fetch(baseUrl, {
        method: 'GET',
        headers: AuthHeader()
    })
    .then(res=>res.json());
}


function deleteEntity(id){
    const baseUrl = `${config.apis.orthus}/v1/user_entity_filters/${id}/destroy_entity`;
    
    return fetch(baseUrl, {
        method: 'DELETE',
        headers: AuthHeader({'Content-Type': 'application/json'}),
    })
    .then(res=>res.json());
}