import { tracksConstants } from '../constants/tracks';

export function tracks(state = {track: {}, card: {}, topPlaylist: {}, 
        topPlaylistTimeseries: {}, autoCompleteLoading: false, forceLoadAllData: false,
        demographicsComparison: {}, territoriesComparison: {}, vendorsComparison: {}}, 
        action) {
    switch (action.type) {
        case tracksConstants.GET_TRACKS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case tracksConstants.GET_TRACKS_SUCCESS:
            return {

                ...state,
                loading: false,
                items: action.tracks,
                metadata: action.metadata,
                total: action.total
            };
        case tracksConstants.GET_TRACKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case tracksConstants.GET_TOP_TRACKS_REQUEST:
            return {
                ...state,
                topLoading: true,
                top: null,
                topTotal: null,
                metadata: null,
                timeseries: null
            };
        case tracksConstants.GET_TOP_TRACKS_SUCCESS:
            return {

                ...state,
                topLoading: false,
                top: action.tracks,
                topTotal: action.total,
                metadata: action.metadata,
                forceLoadAllData: false
            };
        case tracksConstants.GET_TOP_TRACKS_FAILURE:
            return {
                ...state,
                topLoading: false,
                error: action.error,
                forceLoadAllData: false
            };

        case tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_REQUEST:
            return {
                ...state,
                topPlaylist: {
                    ...state.topPlaylist,
                    [action.playlistID]: {
                        loading: true
                    }
                }
            };
        case tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_SUCCESS:
            return {

                ...state,
                topPlaylist: {
                    ...state.topPlaylist,
                    [action.playlistID]: {
                        loading: false,
                        data: action.tracks,
                        metadata: action.metadata
                    }
                }

            };
        case tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_FAILURE:
            return {

                ...state,
                topPlaylist: {
                    ...state.topPlaylist,
                    [action.playlistID]: {
                        loading: false
                    }
                }

            };
            
        case tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_REQUEST:
            return {
                ...state,
                topPlaylistTimeseriesLoading: true,
                topPlaylistTimeseries: null
            };
        case tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_SUCCESS:
            return {

                ...state,
                topPlaylistTimeseriesLoading: false,
                topPlaylistTimeseries: action.tracks,

            };
        case tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_FAILURE:
            return {

                ...state,
                topPlaylistTimeseriesLoading: false

            };
            
            
        case tracksConstants.GET_TRACKS_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                autoCompleteLoading: true
            };
        case tracksConstants.GET_TRACKS_AUTOCOMPLETE_SUCCESS:
            return {

                ...state,
                autoCompleteLoading: false,
                autocomplete_items: action.tracks
            };
        case tracksConstants.GET_TRACKS_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                autoCompleteLoading: false,
                error: action.error
            };
        case tracksConstants.GET_TRACK_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case tracksConstants.GET_TRACK_DETAILS_SUCCESS:
            let track = state.track;
            track[action.id] = action.track;
            return {
                ...state,
                loading: false,
                track: track
            };
        case tracksConstants.GET_TRACK_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case tracksConstants.GET_TRACKS_CARD_REQUEST:
            return {
                ...state,
                cardLoading: true
            };
        case tracksConstants.GET_TRACKS_CARD_SUCCESS:
            let card = state.card;
            card[action.id] = action.card;
            return {
                ...state,
                cardLoading: false,
                card: card
            };
        case tracksConstants.GET_TRACKS_CARD_FAILURE:
            return {
                ...state,
                cardLoading: false,
                error: action.error
            };

        case tracksConstants.GET_STREAMS_STATS_REQUEST:
            return {
                ...state,
                streamsLoading: true,
                streams: undefined
            };
        case tracksConstants.GET_STREAMS_STATS_SUCCESS:
            return {
                ...state,
                streamsLoading: false,
                streams: action.tracks
            };
        case tracksConstants.GET_STREAMS_STATS_FAILURE:
            return {
                ...state,
                streamsLoading: false,
                error: action.error,
                streams: undefined
                
            };

        case tracksConstants.GET_TIMESERIES_TRACKS_REQUEST:
            return {
                ...state,
                timeseriesLoading: true,
                timeseries: null
            };
        case tracksConstants.GET_TIMESERIES_TRACKS_SUCCESS:
            return {

                ...state,
                timeseriesLoading: false,
                timeseries: action.tracks
            };
        case tracksConstants.GET_TIMESERIES_TRACKS_FAILURE:
            return {
                ...state,
                timeseriesLoading: false,
                error: action.error
            };

        case tracksConstants.COMPARE_TRACKS_REQUEST:
            return {
                ...state,
                comparisonLoading: true
            };
        case tracksConstants.COMPARE_TRACKS_SUCCESS:
            return {

                ...state,
                comparisonLoading: false,
                comparison: action.tracks,
            };
        case tracksConstants.GET_TRACKS_FAILURE:
            return {
                ...state,
                comparisonLoading: false,
                error: action.error
            };

        case tracksConstants.COMPARE_TRACK_DEMOGRAPHICS_REQUEST:
            return {
                ...state,
                demographicsComparisonLoading: true
            };
        case tracksConstants.COMPARE_TRACK_DEMOGRAPHICS_SUCCESS:
            return {

                ...state,
                demographicsComparisonLoading: false,
                demographicsComparison: {
                    ...state.demographicsComparison,
                    [action.id]: action.track
                }
                    
                    
            };
        case tracksConstants.GET_TRACK_DEMOGRAPHICS_FAILURE:
            return {
                ...state,
                demographicsComparisonLoading: false,
                error: action.error
            };

        case tracksConstants.COMPARE_TRACK_TERRITORIES_REQUEST:
            return {
                ...state,
                territoriesComparisonLoading: true
            };
        case tracksConstants.COMPARE_TRACK_TERRITORIES_SUCCESS:
            return {

                ...state,
                territoriesComparisonLoading: false,
                territoriesComparison: {
                    ...state.territoriesComparison,
                    [action.id]: action.track
                }
                    
                    
            };
        case tracksConstants.GET_TRACK_TERRITORIES_FAILURE:
            return {
                ...state,
                territoriesComparisonLoading: false,
                error: action.error
            };
            
        case tracksConstants.COMPARE_TRACK_VENDORS_REQUEST:
            return {
                ...state,
                vendorsComparisonLoading: true
            };
        case tracksConstants.COMPARE_TRACK_VENDORS_SUCCESS:
            return {

                ...state,
                vendorsComparisonLoading: false,
                vendorsComparison: {
                    ...state.vendorsComparison,
                    [action.id]: action.track
                }
                    
                    
            };
        case tracksConstants.GET_TRACK_VENDORS_FAILURE:
            return {
                ...state,
                vendorsComparisonLoading: false,
                error: action.error
            };

        case tracksConstants.FORCE_LOAD_ALL_DATA: {
            return {
                ...state,
                forceLoadAllData: action.force,
                topLoading: true
            }
        }
            
        default:
            return state
    }
}
