const numericFields = ['digital_income', 'digital_quantity', 'nr_income', 'nr_quantity', 'physical_income', 'physical_quantity', 'total_income', 'total_quantity']
export default function FixNumericFields(data) {
    for(let item of data) {
        for(let key of Object.keys(item)) {
            if(numericFields.includes(key)) {
                const floatNumber = key.endsWith('_income');
                item[key] = Number(item[key]).toFixed(floatNumber ? 2 : 0); 
            }
        }
    }
    return data;
}