export {
    convertToThousands,
    splitByThousands,
    round,
    percentageValue
}

function convertToThousands(num, digits=2) {
    var si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "B" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/, i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

function splitByThousands(num, prefix=''){
    return (num && Number(num)) ? prefix + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-'; 
}

function round(num){
    return Math.round(num);
}

function percentageValue(curr, prev) {
    return curr ? Math.round((curr-prev)/prev*100) : 0;    
}