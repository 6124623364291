import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { sum } from 'lodash';
import PercentageValue from '../../../common/PercentageValue';
import AbsoluteValue from '../../../common/AbsoluteValue';
import Tooltip from '../../../common/Tooltip';
import EntityCard from '../../../common/SharedEntityCard';
import ConfirmClick from '../../../common/ConfirmClick';
import { splitByThousands, percentageValue } from '../../../../helpers/NumberFormatter';

export const pendingStatementsSettings = {
    settingsForTable
};

function formatCell(value){return splitByThousands(Number.parseFloat(value).toFixed(2), '$')};

function settingsForTable(exportCall, acceptCall, rejectCall){
    
    let columns = [
            {
                id: 'period',
                Header: 'Period',
                accessor: 'period',
            },                
            {
                id: 'label',
                Header: 'Label',
                accessor: 'label',
                Aggregated: row => {
                    return <span>Total</span>
                }
            },
            
            {
                id: 'digital_income',
                Header: 'Digital Income',
                accessor: 'digital_income',
                Cell: props => <span>{formatCell(props.value)}</span>,
                aggregate: (values, rows) => sum(values),
            },
            
            {
                id: 'physical_income',
                Header: 'Physical Income',
                accessor: 'physical_income',
                Cell: props => <span>{formatCell(props.value)}</span>,
                aggregate: (values, rows) => sum(values),
            },
            {
                id: 'total_income',
                Header: 'Total Income',
                accessor: 'total_income',
                Cell: props => <span>{formatCell(props.value)}</span>,
                aggregate: (values, rows) => sum(values),
            },
            {
                id: 'actions',
                Header: 'Actions',
                Cell: props => { return null },  
                Aggregated: ({row}) => {
                    const { period } = row;
                    return <span>
                        <a className="confirm-click-holder" onClick={()=>exportCall(period)}>Export</a>
                        <ConfirmClick confirmAction={()=>acceptCall(period)} title="Accept" confirmClass="" confirmLabel="Accept" confirmPrompt={`About to Accept Data for ${period}`}></ConfirmClick> 
                        <ConfirmClick confirmAction={()=>rejectCall(period)} title="Reject" confirmClass="" confirmLabel="Reject" confirmPrompt={`About to Reject Data for ${period}`}></ConfirmClick>
                    </span>
                }
            }

            ];
            
    return columns; 
}
