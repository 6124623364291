import React from 'react';
import { map, capitalize } from 'lodash';
import PercentageValue from '../../../common/PercentageValue';
import AbsoluteValue from '../../../common/AbsoluteValue';
import { percentageValue, splitByThousands } from '../../../../helpers/NumberFormatter';

export const vendorSettings = {
    settingsForTable
};


function settingsForTable(totals){
    let columns = [
        {
            id: 'vendor',
            className: 'table-text-left', 
            headerClassName: 'table-text-left',
            Header: 'Vendor',
            accessor: 'customer',
            Cell: props => <span>{props.value}</span>,
            Footer: props => <span>Total</span>,
            // width: 190
        },
       {
           id: 'content_type',
           className: 'table-text-left', 
           headerClassName: 'table-text-left',
           Header: 'Source',
           // width: 70,
           accessor: 'source',
           Cell: props => <span>{(props.value)}</span>,
           Footer: props => <span>Digital<br/>Physical<br/>NR<br/>Total</span>,
       },
                                
       {
           id: 'income',
           className: 'table-text-right', 
           headerClassName: 'table-text-right',
           Header: 'Income',
           // Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'income',
           Cell: props => {
               const val = Number.parseFloat(props.value).toFixed(2);
               return  <span>{splitByThousands(val, '$')}</span>
           },
           Footer: props => <span>{splitByThousands(totals.digital_income, '$')}<br/>{splitByThousands(totals.physical_income, '$')}<br/>{splitByThousands(totals.nr_income, '$')}<br/>{splitByThousands(totals.total_income, '$')}</span>,

       },
      
       {
           id: 'quantity',
           className: 'table-text-right', 
           headerClassName: 'table-text-right',
           Header: 'Quantity',
           // Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
           // width: 70,
           accessor: 'quantity',
           Cell: props => <span>{splitByThousands(props.value)}</span>,
           Footer: props => <span>{splitByThousands(totals.digital_quantity)}<br/>{splitByThousands(totals.physical_quantity)}<br/>{splitByThousands(totals.nr_quantity)}<br/>{splitByThousands(totals.total_quantity)}</span>,
       },
       {
              id: 'share',
              className: 'table-text-right', 
              headerClassName: 'table-text-right no-sort',
              footerClassName: 'table-text-right',
              Header: props => <span>Income<br /> Share</span>,
              // Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
              // width: 70,
              accessor: 'share',
              Cell: props => <span>{props.value}%</span>,
          },        
       
       /*       
       {
           id: 'nr_income',
           className: 'table-text-right', 
           headerClassName: 'table-text-right',
           Header: 'NR Income',
           // Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'nr_income',
           Cell: props => {
               const val = val ? Number.parseFloat(props.value).toFixed(2) : null;
               return  val ? <span>{splitByThousands(val, '$')}</span> : <span>-</span>
           }

       },

       {
           id: 'nr_quantity',
           className: 'table-text-right', 
           headerClassName: 'table-text-right',
           Header: 'NR Quantity',
           // Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
           // width: 70,
           accessor: 'nr_quantity',
           Cell: props => <span>{splitByThousands(props.value)}</span>,
       },
       */
       /*
       {
           id: 'playlists',
           headerClassName: 'table-header-item total',
           className: 'total',
           Header: 'Playlists',
           Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
           // width: 70,
           accessor: 'curr_playlists'
       }
       */

    ];
    return columns;
}