import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { Route, withRouter } from 'react-router';
import SearchWidget from '../../components/pages/home/SearchWidget';
import AudienceDetailsView from './Details';
import Tooltip from '../../components/common/Tooltip';

class AudienceOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.searchResult = this.searchResult.bind(this);
    }
    
    componentDidMount() {
    }
    
    searchResult(item) {
        this.props.history.push(`/audience/${item.entity}/${item.id}`);
    }

    
    render() {
        return <div className="wrapper wrapper-content light-blue-bg inner-page">
            <h2 className="big-title audience">
                <span>Audience Analysis</span>
                <Tooltip position="bottom" message="As per the CCPA regulations, Spotify refreshes User IDs on a monthly basis. It is therefore difficult to accurately represent Unique listeners over longer periods of time, so please bear this in mind when doing your analysis." tooltipClass="toolbar-title-tooltip release"/>
            </h2>
            <div className="ibox-content section-header">
                <div className="title-holder">
                    <SearchWidget searchResult={this.searchResult} placeholder="Search artist, product or track" mode="playlist"  />
                </div>
            </div>
            <Route path="/audience/:entity/:id" component={(AudienceDetailsView)}></Route>
            <Helmet>
                <title>Audience Analysis</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
    } 
}

export default withRouter(connect(mapStateToProps)(AudienceOverview))