import moment from 'moment';

import { imprintsFormatter } from './imprints';
import { artistsFormatter } from './artists';
import { productsFormatter } from './products';
import { tracksFormatter } from './tracks';

export const filterFormatter = {
    formatEntityFilter,
    formatMaxDate
};

function formatEntityFilter(data, entity) {
    return data.map(hit=>{
        switch(entity){
            case 'imprints':
            case 'exclude_imprints':
                return imprintsFormatter.formatAutocompleteItem(hit);
            case 'artists':
            case 'exclude_artists':
                return artistsFormatter.formatAutocompleteItem(hit);
            case 'products':
            case 'exclude_products':
                return productsFormatter.formatAutocompleteItem(hit);
            case 'tracks':
            case 'exclude_tracks':    
                return tracksFormatter.formatAutocompleteItem(hit);
        }
    });
}

function formatMaxDate(result) {
	let date = new Date();
	if(result && result.length && result[0].max_date) {
		date = result[0].max_date;
	}
	date = moment(date).utc();
	return date.format('YYYY-MM-DD');
	
}