import React, { Component } from 'react'
import Select from 'react-select'

class ReactSelect extends Component {
    render() {
        const customStyles = {
            dropdownIndicator: (provided, state) => ({
                ...provided,
                //transition: 'all .2s ease',
                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
            }),
            option: (provided, state) => ({
                ...provided,
                ':hover': { 
                    fontWeight: 'bold',
                    cursor: 'pointer'
                }
            })            
        }
        return <Select styles={customStyles} {...this.props} />
    }
}

export default ReactSelect