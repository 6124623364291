import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import HomeView from '../../views/Home';
import MainView from '../../views/Main';
import CatalogView from '../../views/Catalog';
//import AdminView from '../../views/Admin';
import PlaylistSearchView from '../../views/Playlists/Search';
import PlaylistOverviewView from '../../views/Playlists/Overview';
import PlaylistDetailsView from '../../views/Playlists/Details';
import ImprintDetailsView from '../../views/Imprint/Details';
import ArtistProfileView from '../../views/Artist/Profile';
import ProductDetailsView from '../../views/Product/Details';
import ProjectDetailsView from '../../views/Project/Details';
import TrackDetailsView from '../../views/Track/Details';
import RouteLogger from '../../helpers/RouteLogger';
//import HeartBeatsView from '../../views/Heartbeats';
import HeartBeatsView from '../../views/PagedHeartbeats';
import NewReleasesView from '../../views/NewReleases';
import TerritoryAnalysis from '../../views/TerritoryAnalysis/TerritoryAnalysis';
import { NotificationSettings } from '../../views/NotificationSettings/';
import CampaignOverviewView from '../../views/Campaign/Overview';
import AudienceOverviewView from '../../views/Audience/Overview';
import TrackComparisonSearchView from '../../views/TrackComparison/Search';
import API from '../../views/API';
import UserProfile from '../../views/User/Profile';
import UserFavorites from '../../views/User/Favorites';
import UserSharedPages from '../../views/User/SharedPages';

import StatementPeriods from '../../views/Import/StatementPeriods';
import PendingStatements from '../../views/Import/PendingStatements';
import ImportMetadataErrors from '../../views/Import/ImportMetadataErrors';
import ImportMetadata from '../../views/Import/ImportMetadata';
import CatalogImports from '../../views/Import/Imports';
import ImportExceptions from '../../views/Import/Exceptions';

const main=(props)=>{
    return <RouteLogger>
            <Switch>
                <Route path="/main" component={HomeView}></Route>
                <Route path="/catalog/:entity" component={CatalogView}></Route>
                <Route path="/old" component={MainView}></Route>
                {/*<Route path="/admin" component={(AdminView)}></Route>*/}
                <Route path="/playlists/:id/overview" component={(PlaylistOverviewView)}></Route>
                <Route path="/playlists/:id/details" component={(PlaylistDetailsView)}></Route>
                <Route path="/playlists/:entity_type?/:entity_id?" component={(PlaylistSearchView)}></Route>
                <Route path="/reporting/heartbeats" component={(HeartBeatsView)}></Route>
                <Route path="/reporting/new-releases" component={(NewReleasesView)}></Route>
                <Route path="/user/notifications" component={(NotificationSettings)}></Route>
                <Route path="/imprints/:id/:name?" component={(ImprintDetailsView)}></Route>
                <Route path="/artists/:id/:name?" component={(ArtistProfileView)}></Route>
                <Route path="/products/:id/:name?" component={(ProductDetailsView)}></Route>
                <Route path="/projects/:id/:name?" component={(ProjectDetailsView)}></Route>
                <Route path="/tracks/:id/:name?" component={(TrackDetailsView)}></Route>
                <Route path="/campaigns/:id?" component={(CampaignOverviewView)}></Route>
                <Route path="/audience/:entity?/:id?" component={(AudienceOverviewView)}></Route>
                <Route path="/track-comparison/:ids?/:weeks?" component={(TrackComparisonSearchView)}></Route>
                <Route path="/territory-analysis" component={(TerritoryAnalysis)}></Route>
                <Route path="/user/api" component={(API)}></Route>
                <Route path="/user/profile" component={(UserProfile)}></Route>
                <Route path="/user/favorites" component={(UserFavorites)}></Route>
                <Route path="/user/shared-pages" component={UserSharedPages}></Route>
                <Redirect from="/" to="/main" />
            </Switch>
        </RouteLogger>
    }
export default main;
