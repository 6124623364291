import countriesByAbbreviation from 'country-json/src/country-by-abbreviation.json';
import { cloneDeep, sortBy } from 'lodash';

let countriesByAbbreviationCopy = cloneDeep(countriesByAbbreviation)

countriesByAbbreviationCopy.push({
    "country": "Unknown",
    "abbreviation": "ZZ"     
});

countriesByAbbreviationCopy = countriesByAbbreviationCopy.map(obj => {
    if (obj.abbreviation === 'GB') {
        return {...obj, country: 'United Kingdom'};
    }
    
    if(obj.abbreviation == 'UK')
        return false;

    return obj;
});

countriesByAbbreviationCopy = sortBy(countriesByAbbreviationCopy, 'country');

export default countriesByAbbreviationCopy;