import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { map } from 'lodash';
import Spinner from '../../components/common/Spinner';
import Datepicker from '../../components/common/Datepicker';
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { importsActions } from '../../data/actions/imports';
import { exceptionsSettings } from '../../components/pages/imports/exceptions/Settings';
import ImportData from '../../components/pages/imports/ImportData';
import CSV from '../../helpers/CSVExport';

var deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');

class Exceptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            period: null,
            showImportData: false
        }
        this.exportToCsv = this.exportToCsv.bind(this);
    }
    
    componentDidMount() {
        this.props.dispatch(importsActions.getCatalogExceptions());
    }
    
    exportToCsv(){
        let {exceptions} = this.props.imports;
        const filename = CSV.CSVHeader('catalog_exceptions');
        return CSV.CSVExport(exceptions, {filename}, 'catalog_exeptions');
    }
    
    insertExceptions(){
        this.props.dispatch(importsActions.insertCatalogExceptions());
    }

    updateExceptions(){
        this.props.dispatch(importsActions.updateCatalogExceptions());
    }
    
    refreshExceptions(){
        this.props.dispatch(importsActions.refreshCatalogExceptions());
    }

    
    render() {
        const { exceptionsLoading, exceptions } = this.props.imports;
        
        return <div className="inner-page shared-pages">
            <div className="container">
                <div className="import-menu import-menu-exceptions">
                    <h5>Exceptions</h5>
                    <ul>
                        <li><a onClick={()=>this.exportToCsv()}>Export List</a></li>
                        <li><a onClick={()=>this.insertExceptions()}>Generate New</a></li>
                        <li><a onClick={()=>this.updateExceptions()}>Update Statements</a></li>
                        {/*<li><a onClick={()=>this.refreshExceptions()}>Refresh Exceptions</a></li>*/}
                    </ul>
                    
                </div>
                <div className="ibox">
                    <ReactTable
                        data={exceptions}
                        loading={exceptionsLoading}
                        columns={exceptionsSettings.settingsForTable(this.loadData)}
                        defaultSorted={[{ id: "period", desc: true }]}
                        pageSize={10}
                        showPagination={true}
                        className="simple-table share-table"
                    />
                    
                    {/* {selectedRows.length > 0 && <button className="default-btn default-btn--small default-btn--light delete-selected" onClick={this.deleteSelectedPages}>Delete Selected</button>} */}

                </div>
            </div>
            <Helmet>
                <title>Exceptions</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        imports: state.imports
    } 
}

export default connect(mapStateToProps)(Exceptions)