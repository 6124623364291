import React from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { isEqual } from 'lodash';

import CSV from '../../../helpers/CSVExport';


import Box from '../Box';
import { statsActions } from '../../../data/actions/stats';
import PieChart from '../../widgets/charts/types/PieChart';
import { devicesSettings } from './Devices/Settings';

var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import VendorLogo from "../VendorLogo";
import { dspLogos } from "../../common/Stats/utils";

class DevicesStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          mode: "pie",
          defaultSorted: [{ id: "curr_units", desc: false }],
          logoDataFiltered: [],
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }

    setChartMode(mode){
        this.setState({mode});
    }

    exportToCsv(){
        const filename = CSV.CSVHeader('details_devices', 'curr_units', this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(this.props.stats.devices.table, {filename});
    }
    
    componentDidMount() {
        const { chartType } = this.props;
        this.props.dispatch(statsActions.getDevicesStats(this.props.entity, this.props.ids))

        if (this.props.filter.global) {
          if (this.props.filter.global !== undefined) {
            this.setState({
              logoDataFiltered: chartType
                ? dspLogos[chartType].data.filter((l) =>
                    this.props.filter.global.vendors.includes(l)
                  )
                : [],
            });
          }
        }
    }
    
    
    componentWillReceiveProps(nextProps){
        const globalFilter = nextProps.filter.global;
        const { chartType } = this.props;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.props.dispatch(statsActions.getDevicesStats(this.props.entity, this.props.ids))
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    logoDataFiltered: chartType
                      ? dspLogos[chartType].data.filter((l) =>
                          globalFilter.vendors.includes(l)
                        )
                      : [],
                  };
                });
            }                
        }
    }
    
    renderToolbar(modes){
        let toolbar = [];

        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-icon-holder">
            <a key='pie' title="Pie Chart" className={`chart-icon ${this.state.mode == 'pie' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('pie')}
                dangerouslySetInnerHTML={{__html: pieSvg}}>
            </a>
            <a key='table' title="Table" className={`chart-icon ${this.state.mode == 'table' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('table')}
                dangerouslySetInnerHTML={{__html: tableSvg}}>
            </a>
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            
        </div>
        </div>)
        return toolbar;
    }  
    
    renderVendorLogos() {
        const { chartType } = this.props;
        const logoData = chartType ? this.state.logoDataFiltered : [];

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} />
            ))}
        </div>
    }
    
    
    
    render (){
        const stats = this.props.stats;
        const rows = (stats.devices && stats.devices.table) ? stats.devices.table.length : 0;
        return <Box title="Devices" toolbar={this.renderToolbar()} vendorLogoList={this.renderVendorLogos()} spinnerEnabled={this.props.stats.devicesLoading}>
            {this.props.stats.devices && <div>
                {this.state.mode=='pie' && <div className="chart-block">
                    <PieChart data={this.props.stats.devices.chart} />                          
                </div>}
                {this.state.mode=='table' && <div className="devices-table territory-table--single custom-scroll">
                    <ReactTable
                        className="device-table"
                        data={stats.devices.table}
                        columns={devicesSettings.settingsForTable('details')}
                        defaultPageSize={(stats.devices.table && stats.devices.table.length ) ? Math.min(stats.devices.table.length, 10) : 10}
                        showPagination={rows > 10}
                        defaultSorted={this.state.defaultSorted}
                        getTrProps={devicesSettings.settingsForRow}
                    />
                </div>}                
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(DevicesStats);