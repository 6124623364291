import React from 'react';
import { connect } from 'react-redux';
import { entityFiltersActions } from '../../data/actions/entity_filters';

class EntityFilters extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        showList: false
    }
  }

  componentDidMount = () => {
    this.props.onGetEntityFilters();
  }
  
  showEntityFilterList = () => { this.setState({ showList: !this.state.showList }) };

  handleFilterClick = filter => {
      this.showEntityFilterList();
      return this.props.onSelect(filter);
  }

  render(){
    const filters = this.props.entityFilters.allEntityFilters;
    let currentEntity = null,
      currentHeader = null,
      { currentFilter } = this.props,
      currentFilterName =  currentFilter ? currentFilter.name : 'Select Filter'; 
    
    return(
      <div className="entityFilters">
        <div className="selected-entities">
          <React.Fragment>
            <div className="entity-filters-action">
              <div>
                <div className="show-filters-holder">
                  <span className="showEntityFiltersList" onClick={this.showEntityFilterList}>{currentFilterName} <i class={`fas fa-caret-${this.state.showList ? 'up' : 'down' }`}></i></span>
                  { this.state.showList && 
                      <ul className="filter-list">
                        {filters.map(filter => {
                          if (filter.entity !== currentEntity) {
                            currentHeader = <p className="filter-entity-title">{filter.entity}</p>;
                            currentEntity = filter.entity;
                          } else {
                            currentHeader = null;
                          }
                          return (
                            <li
                              key={JSON.stringify(filter)}
                              onClick={() => this.handleFilterClick(filter)}
                            >
                              {currentHeader}
                              {filter.name}
                            </li>
                          );
                        })}
                    </ul>}
                </div>
                {currentFilter!==null && <a className="clear-filter-link" title="Clear Filter" onClick={this.props.onReset}>Clear Filter</a>}
              </div>
            </div>
          </React.Fragment>
          {currentFilter!==null && <div className="page-filter">
            <div className="tag-list">
              {currentFilter.body.value.map(item => {
                return (<div key={item.id} className="tag">
                    <div className="tag-name-holder">
                        <div className="tag-name"><span>{item.name}</span></div>
                    </div>
                </div>)
              })}
            </div>
          </div>}
          
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetEntityFilters: entity => dispatch(entityFiltersActions.getAllEntityFilters()),
  }
}

const mapStateToProps = state => {
  return {
    entityFilters: state.entity_filters
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityFilters);