import { searchConstants } from '../constants/search';

export function search(state = {}, action) {
    switch (action.type) {
        case searchConstants.GET_SEARCH_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                loading: true,
                exactMatch: false
            };
        case searchConstants.GET_SEARCH_AUTOCOMPLETE_SUCCESS:
            return {
                
                ...state,
                loading: false,
                autocomplete_items: action.search,
                exactMatch: action.exactMatch
            };
        case searchConstants.GET_SEARCH_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
     
        default:
            return state
    }
} 