import React from 'react';
import { connect } from 'react-redux';
import Box from './Box';
import DetailsLink from "./Datagrid/DetailsLink";
import AudienceAnalysisLink from './Datagrid/AudienceAnalysisLink';
import EntityStatus from './Datagrid/EntityStatus';
import Tooltip from './Tooltip';

import { imprintsActions } from '../../data/actions/imprints';
import { artistsActions } from '../../data/actions/artists';
import { projectsActions } from '../../data/actions/projects';
import { productsActions } from '../../data/actions/products';
import { tracksActions } from '../../data/actions/tracks';


class EntityCard extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        const {getCard, onResult, id} = this.props;
        if(id.indexOf(',')===-1) {
            getCard(id).then(entity=>{
                if(onResult!==undefined && onResult instanceof Function) {
                    this.props.onResult(entity);
                }
            })
        };
    }
    
    render() {
        let { entity, id } = this.props;
        
        if(entity == 'track_artists' || entity == 'product_artists')
            entity = 'artists';
        
        if(!this.props[entity].card[id])
            return null;
        const card = this.props[entity].card[id];
        return <div className="entity-card-item">
            {/* {this.props.showFavoriteHidden ? <EntityStatus entity={entity} id={id} /> : null} */}
            <div className="logo" style={{backgroundImage: `url(${card.logo})`}}>
                
            </div>
            {/* <div className="description">
                <p>{card.name}</p>
                <p>{card.code}</p>
                {card.release_date && <p>First Released: {card.release_date}</p>}
            </div>
            {this.props.showLink ? (
                <div className="artist-details-link">
                    {card.primary_artist && <DetailsLink 
                        title="Artist Details"
                        entity="artists"
                        id={card.primary_artist.id}
                    ></DetailsLink>}
                    <AudienceAnalysisLink title="Audience" entity={entity} id={id} />
                    <EntityStatus entity={entity} id={id} />
                </div>
            ) : null }
            {this.props.reset!==undefined && <span onClick={this.props.reset} className="close-link" title="remove">&times;</span>} */}
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        imprints: state.imprints,
        artists: state.artists,
        products: state.products,
        projects: state.projects,
        tracks: state.tracks,
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getCard: (id) => {
            switch(ownProps.entity) {
                case 'exclude_imprints':
                case 'imprints':
                    return dispatch(imprintsActions.getCard(id));
                    break;
                case 'exclude_product_artists':                    
                case 'exclude_track_artists':
                case 'track_artists':
                case 'artists':
                    return dispatch(artistsActions.getCard(id));
                    break;
                case 'exclude_products':                    
                case 'products':
                    return dispatch(productsActions.getCard(id))
                    break;                    
                case 'projects':
                    return dispatch(projectsActions.getCard(id))
                    break;                    
                case 'exclude_tracks':                    
                case 'tracks':
                    return dispatch(tracksActions.getCard(id))
                    break;
                    
            }
        }
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityCard)