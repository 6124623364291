import React from 'react';
import {Helmet} from 'react-helmet';
import Progress from '../common/Progress';
import Navigation from '../common/Navigation';
import Footer from '../common/Footer';
import TopHeader from '../common/TopHeader';
import ErrorBox from '../common/ErrorBox';
import WhiteLabelCss from '../common/WhiteLabelCss';
import Filter from '../widgets/filter/Filter';
import ChatWidget from '../widgets/chat/ChatWidget';
import AdminRoutes from '../../config/routes/admin';
import WithPermission from '../../helpers/WithPermission';
import withSiloCode from '../../helpers/WithSiloCode';
import { withRouter } from 'react-router';

import { correctHeight, detectBody } from './Helpers';

class Admin extends React.Component {

    render() {
        let wrapperClass = "light-blue-bg " + this.props.location.pathname;
        return (
            <div id="wrapper" className={this.props.silo}>
                <Progress />
                <ErrorBox />
                <WhiteLabelCss silo={this.props.silo} />
                <Navigation location={this.props.location}/>
                <Helmet defaultTitle="Enterlytics" titleTemplate="%s - Enterlytics" />
                <div id="page-wrapper" className={`page-wrapper admin-page-wrapper ${wrapperClass}`}>
                    <TopHeader />
                    <Filter />
                    <WithPermission permission="allow_admin">
                        <ChatWidget />
                    </WithPermission>
                    <WithPermission permission={["allow_admin", "can_grant_access"]}>
                        <AdminRoutes />
                    </WithPermission>
                    <Footer />
                </div>

            </div>

        )
    }

    componentDidMount() {

        // Run correctHeight function on load and resize window event
        $(window).bind("load resize", function() {
            correctHeight();
            detectBody();
        });

        // Correct height of wrapper after metisMenu animation.
        $('.metismenu a').click(() => {
            setTimeout(() => {
                correctHeight();
            }, 300)
        });
    }
}

export default withSiloCode(withRouter(Admin))
