import React, { Component } from 'react';
import { find, sumBy, map } from 'lodash';
import { connect } from 'react-redux';
import ReactSelect from '../../common/ReactSelect';

import config from '../../../config/config';
import { LineChart, StackedBarChart } from '../../widgets/charts/types';
import Box from '../../common/Box';
import CSV from '../../../helpers/CSVExport';
import { playlistsActions} from '../../../data/actions/playlists';
import { convertToThousands } from '../../../helpers/NumberFormatter';

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class PlaylistDistribution extends Component {

    nullElement = {value: null, label: 'All'}
    constructor(props) {
        super(props);
        this.state = {
            playlist: this.nullElement
        }
        
        this.setPlaylist = this.setPlaylist.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }
    
    componentDidMount() {
        const { data, dispatch } = this.props;
        if(data && data.length) {
            const ids = map(data, 'playlist_id').filter(id=>id);
            const filtered = [{id:'ids', value:ids}];
            dispatch(playlistsActions.getTimeseriesPlaylists({sorted: 'curr_units', filtered}));
        }

    }
    
    exportToCsv (){
        const filename = CSV.CSVHeader('playlist_distribution', 'curr_units', this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(this.props.data, {filename});        
    }
    
    setPlaylist(playlist) {
        this.setState({playlist});
    }
    
    renderToolbar(data){
        let playlists = [this.nullElement];
        for(let playlist of data)
            playlists.push({
                value: playlist.id,
                label: playlist.label
            });
        //onChange={this.setPlaylist} 
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          {/*<ReactSelect value={this.state.playlist} options={playlists} onChange={this.setPlaylist} className="single-select"/>*/}
          <a key="download" onClick={this.exportToCsv} title="Export CSV" className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)

        return toolbar;
    }
    
    renderTrends(data, single) {
        
        if(!data || !data.length)
            return null;
        
        const trendingPlaylist = data[0],
            total = sumBy(data, 'curr_units'),
            growth = data.units_diff > 0;
        
        return <div>
            <h2 className="content-title lowercase">{convertToThousands(total)} of streams</h2>
                {!single && <div><h4 className="content-subtitle">are from playlists</h4>
                        <div className={`description ${growth ? 'up' : 'down'}`}>
                            <span className="icon fa"></span>
                            <p><strong>{trendingPlaylist.name}</strong> was your top playlist with <span className="num lowercase">{convertToThousands(trendingPlaylist.curr_units)}</span> streams in total. </p>
                        </div>
                </div>}
                {single && <h4 className="content-subtitle"> are from <strong>{trendingPlaylist.name}</strong> playlist
                </h4>}
            </div>;                        
                
    }
    
    render(){
        let { timeseries: data } = this.props.playlists;
        if(!data)
            return null;
        const trends = config.showTrends;
        
        let chartData, singlePlaylist ;
        if(this.state.playlist.value!==null) {
            chartData = [find(data, {id: this.state.playlist.value})];
            singlePlaylist = true;
        }
        else {
            chartData = data;
            singlePlaylist = false;
        }
        return data ? <Box title="Playlist Distribution" toolbar={this.renderToolbar(data.datasets)} >
            <div className="row">
                {trends && <div className="col-sm-4 col-xs-12">
                {this.renderTrends(chartData, singlePlaylist)}                          
                </div>}
                <div className={`col-xs-12 col-sm-${trends?8:12}`}>
                    <StackedBarChart 
                        data={data}
                        labels={{header: 'Playlists', titleLabel: 'streams', valueLabel: 'streams'}}
                    /> 
                </div>
            </div>
        </Box>: null;
    }
                        
}

function mapStateToProps(state) {
    return {
        filter: state.filter,
        playlists: state.playlists
    } 
}

export default connect(mapStateToProps)(PlaylistDistribution);