import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, withRouter } from 'react-router';
import Box from '../components/common/Box';

import ImprintsView from './Imprints';
import ArtistsView from './Artists';
import ProductsView from './Products';
import TracksView from './Tracks';
import WithUser from '../helpers/WithUser';


class Catalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    renderEntity(){
        switch(this.props.match.params.entity) {
            case 'imprints':
                return <ImprintsView />;
            case 'artists':
                return <ArtistsView />;
            case 'products':
                return <ProductsView />;
            case 'tracks':
                return <TracksView />;                
                
        }
    }

    render() {
        return (
            <div className="homepage">
                {this.renderEntity()}
                {/*
                <Route path="/catalog/imprints" component={WithUser(ImprintsView)}></Route>
                <Route path="/catalog/artists" component={WithUser(ArtistsView)}></Route>
                <Route path="/catalog/products" component={WithUser(ProductsView)}></Route>
                */}
            </div>
        )
    }

}

export default withRouter(Catalog)