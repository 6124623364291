import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { LineChart} from '../../../widgets/charts/types';

class MilestoneChart extends React.Component{

    constructor(props) {
        super(props);
    }
    
    
    fixChart(items){
        let yStep = 1,
            yOffset = 1;
        if(items && items.length) {
            for(let y = 0; y < items.length; y++) {
                let data = items[y]['data'];
                data[0]['y'] = yOffset;
                if(data[1])
                     data[1]['y'] = yOffset;
                yOffset += yStep;
            }
        }
        return { items, yOffset };        
    }


    render(){
        
        if(!this.props.items || !this.props.items.length)
            return <p>No milestones for selected date range</p>;
            
        const {items, yOffset} = this.fixChart(this.props.items);
        const {dateStart, dateEnd} = this.props.filter.global,
        dateDiff =  moment(dateEnd).diff(moment(dateStart), 'days'),
        selectedDuration = moment.duration(dateDiff, 'days').asDays(),

        data =  {
            datasets: items
        },
        options = {
           scales: {
               xAxes: [{
                   type: 'linear',
                   position: 'bottom',
                   ticks : {
                       //display: false,
                       beginAtZero :true,
                       stepSize : 1,
                       callback: function(value, index, values) {
                           return moment(dateStart).add(value, 'days').format('DD MMM')
                       },
                       suggestedMax: selectedDuration 
                   }
               }],
               yAxes : [{
                   scaleLabel : {
                       display : false
                   },
                   ticks : {
                       display: false,
                       beginAtZero :true,
                       max : yOffset,
                   },
                   gridLines: {
                       display: false 
                   }
                   
                   
               }]
           }
        };
        return <div className="chart-block" style={{height: `${yOffset*50}px`}}>
            <LineChart data={data} options={options} />                          
        </div>            

    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(MilestoneChart)