import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { withRouter } from 'react-router';
import { pdf as PDFRenderer }from '@react-pdf/renderer';

import Box from '../components/common/Box';
import BulkCSVExport from '../components/common/BulkCSVExport';
import CatalogOverview from '../components/widgets/catalog/CatalogOverview';
import VendorsWidget from '../components/pages/home/VendorsWidget';
import TerritoriesWidget from '../components/pages/home/TerritoriesWidget';
import EntitiesList from '../components/pages/home/EntitiesList';
import SearchWidget from '../components/pages/home/SearchWidget';
import StatementTotals from '../components/pages/home/StatementTotals';
import { imprintsActions } from '../data/actions/imprints';
import { artistsActions } from '../data/actions/artists';
import { productsActions } from '../data/actions/products';
import { projectsActions } from '../data/actions/projects';
import { tracksActions } from '../data/actions/tracks';
import { statsActions } from '../data/actions/stats';
import { filterActions } from '../data/actions/filter';
import CSV from '../helpers/CSVExport';
import SharePage from '../components/pages/sharedPages/SharePage';
import PDFDocument from '../components/pages/home/PDF';

const downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.searchResult = this.searchResult.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
        this.loadAllData = this.loadAllData.bind(this);
    }

    searchResult(item) {
        /*
        if(item.entity == 'imprints') {
            this.props.setFilter(item.entity, [item]);
            this.props.history.push(`/catalog/${item.entity}`);
        }
        else {
        */
            this.props.history.push(`/${item.entity}/${item.id}`);
        //}

    }

    exportToCsv(){
      const {
        imprints,
        artists,
        products,
        projects,
        tracks,
        stats
      } = this.props;

      const data = [
                    { statement_totals: stats.statementTotals},        
        { top_labels: imprints.top},
        { top_artists: artists.top },
        { top_products: products.top },
        { top_projects: projects.top},
        { top_tracks: tracks.top },
        { top_platforms: stats.vendor.table },
        { territories: stats.topTerritoryCache.result }
      ];
      

      const filename = CSV.CSVHeader('overview', null, this.props.filter.global);
      CSV.CSVBulkExport(data, filename);

    }
    
    getPdfData() {
        const reportTitle = 'Overview',
            { imprints, artists, products, tracks, stats } = this.props;
            
        const imageIDs = [
              'top_territories_pie_chart',
              'top_vendors_pie_chart',
        ];
        let images = {};
        for (let imageID of imageIDs){
            let image = document.getElementById(imageID);
            if(image)
                images[imageID] = image.toDataURL("image/jpeg,1.0"); 
        }
        
        let vendorTableData = [];

        if (this.props.stats.vendor)
            vendorTableData = this.props.stats.vendor.table

        const tables = {
            imprints: imprints.top,
            artists: artists.top,
            products: products.top,
            tracks: tracks.top,
            territories: stats.topTerritory.table,
            vendorsTable: vendorTableData,
            statementTotals: stats.statementTotals
        };
        return { reportTitle, images, tables, globalFilter: this.props.filter.global };
    }
    
    exportToPdf() {
        const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
        data.toBlob()
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "overview.pdf";
            link.click();
        });
    }
    loadAllData(){
        const datasets = ['imprints', 'artists', 'projects', 'products', 'tracks', 'top_territories', 'vendors'];
        const dataCalls = datasets.map(dataset=>this.props.loadAllDataCall(dataset));
        Promise.all(dataCalls).then(results=>console.log(results));
    }

    render() {
        const { sharedMode } = this.props;

        return (
            <div className="homepage">
                
                <div className="container">
                
                  <div className="download-page download-page--mt">
                    <span>Export the data</span>
                    {sharedMode && <a key="download" title="Download entire page" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />}
                    {!sharedMode && <SharePage entity="" id="" module="home" exportToCsv={this.exportToCsv} exportToPdf={this.exportToPdf} CSVEntities={["top_imprints", "top_artists", "top_products", "top_projects", "top_tracks", "top_territories", "top_vendors"]} />}
                  </div>
                 
                </div>
                 
                {/*<div className="container container-small transparent">
                    <SearchWidget searchResult={this.searchResult}/>
                </div>*/}
                <div className="container">
	            	<StatementTotals />
	            </div>
                
                <div className="container">
                	<EntitiesList title="Top Labels" entity="imprints" externalLinks={!sharedMode} />
                </div>
                
                    
                <div className="container">
                    <div className="top-tables-container">
                        <EntitiesList title="Top Artists" entity="artists" includeTimeseries={false} externalLinks={!sharedMode} />
                        <EntitiesList title="Top Projects" entity="projects" includeTimeseries={false} externalLinks={!sharedMode} />
                        <EntitiesList title="Top Products" entity="products" includeTimeseries={false} externalLinks={!sharedMode} />
                        <EntitiesList title="Top Tracks" entity="tracks" includeTimeseries={false} externalLinks={!sharedMode} />
                    </div>
                </div>
                <div className="container">
                </div>
                <div className="container">
                </div>
                <div className="container">
                    <TerritoriesWidget />
                </div>

                <div className="container">
                    <VendorsWidget />
                </div>
                <Helmet>
                    <title>Overview</title>
                </Helmet>

            </div>
        )
    }

}


function mapStateToProps(state) {
  const {
    imprints,
    artists,
    products,
    projects,
    tracks,
    stats,
    filter
  } = state;

  return {
    imprints,
    artists,
    products,
    projects,
    tracks,
    stats,
    filter
  }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        setFilter: (entity, filter) => {
            return dispatch(filterActions.setEntityFilter(entity, filter));
        },
        loadAllDataCall: (entity) => {
            switch(entity) {
                case 'imprints':
                    return dispatch(imprintsActions.forceLoadAllData(true));            
                case 'artists':
                    return dispatch(artistsActions.forceLoadAllData(true));
                case 'projects':
                    return dispatch(projectsActions.forceLoadAllData(true));
                case 'products':
                    return dispatch(productsActions.forceLoadAllData(true));
                case 'tracks':
                    return dispatch(tracksActions.forceLoadAllData(true));
                case 'top_territories':
                    return dispatch(statsActions.forceLoadAllTopTerritoriesData(true));
                case 'territories':
                    return dispatch(statsActions.forceLoadAllTerritoriesData(true));                                
                case 'vendors':
                    return dispatch(statsActions.forceLoadAllVendorsData(true));            
                    
            }
            
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
