import React from 'react';
import moment from 'moment';
import store from '../../../helpers/store';
import {
  uniq,
  map,
  merge,
  find,
  flatten,
  findKey
} from 'lodash';
import {
  Dropdown
} from 'react-bootstrap';
import {
  connect
} from 'react-redux';

import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';

import Datepicker from '../../common/Datepicker';
import Multiselect from '../../common/Multiselect/Multiselect';
import MultiselectWithSearch from '../../common/MultiselectWithSearch';
import Accordion from './Accordion';
import ReactSelect from '../../common/ReactSelect';

import SaveLoad from '../../../helpers/SaveLoad';

import durations from './enums/durations.json';
//import vendors from './enums/vendors.json';
//import vendorsFull from './enums/vendors_full.json';
import limits from './enums/limits.json';
import territories from './enums/territories.js';
import sources from './enums/sources.json';
import ranges from './enums/ranges.json';
import releaseDates from './enums/release_dates.json';
import genres from './enums/genres.json';
import sourcesOfRevenue from './enums/sources_of_revenue.json';

import Spinner from '../../common/Spinner';
import EntityFilter from '../entityFilter/EntityFilter';

import {
  filterActions
} from '../../../data/actions/filter';

import {
    vendorsActions
} from '../../../data/actions/vendors';

import {
    globalFilterActions
} from '../../../data/actions/global_filter';

import { imprintsActions } from '../../../data/actions/imprints';

const dateFormat = 'YYYY-MM-DD';
const defaultLimit = 10;
const defaultDuration = 1;
const youtubeVendor = '_YouTube'; //todo: refactor to IDs

class Filter extends React.Component {
    constructor(props) {
      super(props);
      this.saveLoad = SaveLoad('global');
      const currentFilter = store.get('currentFilter');
      const defaultState = this._defaultState();

      if(currentFilter) {
        if(!currentFilter.range) {
            currentFilter.range = this._getRange(currentFilter);
        } 
        
        //currentFilter.vendors = this._fixVendors(currentFilter.vendors);
          
        defaultState.currentFilterName = currentFilter.filter_name;
        defaultState.filter = {...currentFilter, filter_name: currentFilter.filter_name};
      } else {
          const { global } = this.props.filter;
          if(global)
              defaultState.filter = global;
          else
              defaultState.filter = this._defaultState().filter;
      }
      
      defaultState.allGenres = map(uniq(map(genres, genre => genre.primary_genre)), genre=>({label: genre, value: genre}));
      //defaultState.allVendors = map(vendorsFull.data, (vendor)=>({label: vendor.name, value: vendor.name}));
      defaultState.genresMode = (defaultState.filter.genres && defaultState.filter.genres.includes('+')) ? 'and' : 'or'; 

      this.state = defaultState;
      this.dateChange = this.dateChange.bind(this);
      this.prevDateChange = this.prevDateChange.bind(this);
      this.durationChange = this.durationChange.bind(this);
      this.releaseDateChange = this.releaseDateChange.bind(this);
      this.filterApply = this.filterApply.bind(this);
      this.filterClose = this.filterClose.bind(this);
      this.filterToggle = this.filterToggle.bind(this);
      this.genreChange = this.genreChange.bind(this);
      this.imprintChange = this.imprintChange.bind(this);
      this.vendorChange = this.vendorChange.bind(this);
      this.genreModeChange = this.genreModeChange.bind(this);
      this.sourceChange = this.sourceChange.bind(this);
      this.engagementModeChange = this.engagementModeChange.bind(this);
      this.filteredModeChange = this.filteredModeChange.bind(this);
      this._getRange = this._getRange.bind(this);
      this.getAllowedDays = this.getAllowedDays.bind(this);
      this.getMaxDate = this.getMaxDate.bind(this);
    }

    componentWillMount(){
        this.props.getVendors();
        if(!this.props.readonly){
        	this.props.getMaxDate()
        	.then(dateEnd=>{
        		const dateStart = moment(dateEnd).set('date', 1).format(dateFormat);
        		this.setState({
        			...this.state,
        			calendarMaxDate: dateEnd,
        			filter: {
        				...this.state.filter,
        				dateStart,
        				dateEnd
        			}
        		}, ()=>this.filterApply(undefined, this.state.filter));
        		
        	})
        }
      
      //this.props.getImprints();
    }

    _getDateStart(dateEnd, duration) {
      let {
        offset
      } = durations[duration];
      return offset ? moment(dateEnd).subtract(offset) : null;
    }
    
    _getSelectedDuration(dateStart, dateEnd) {
      const dateDiff =  moment(dateEnd).diff(moment(dateStart), 'days');
      return moment.duration(dateDiff, 'days').asDays();
    }

    _getDuration() {
      const {dateStart, dateEnd} = this.state.filter;
      const selectedDuration = this._getSelectedDuration(dateStart, dateEnd);
      for (let duration of durations) {
        if (duration.offset && duration.offset.days == selectedDuration)
          return duration.value;
      }
      return durations[durations.length - 1].value;
    }
    
    _getReleaseDate() {
        const {releaseStart, releaseEnd} = this.state.filter;
        const today = moment();
        const selectedDurationStart = this._getSelectedDuration(releaseStart, today);
        const selectedDurationEnd = this._getSelectedDuration(releaseEnd, today);
        for (let date of releaseDates) {
          if (date.offset) {
              if(((date.offset[1].days == selectedDurationStart) || (! date.offset[1].days && !releaseStart )) 
                  && ((date.offset[0].days == selectedDurationEnd)  || (! date.offset[0].days && !releaseEnd )))
              return date.value;    
          }
        }
        return releaseDates[releaseDates.length - 1].value;
    }


    _getRange({dateStart, dateEnd}) {
        let selectedDuration = this._getSelectedDuration(dateStart, dateEnd),
            selectedRange = ranges[0];

        for(let rangeID = ranges.length-1; rangeID>=0; rangeID--) {
            if(ranges[rangeID].range <= selectedDuration) {
                selectedRange = ranges[rangeID];
                break;
            }
        }

        let newRange = selectedRange.value;
        if(this.state) {
            let currentRange = this.state.filter.range;
            if((newRange == 'clwk' && currentRange == 'chwk') || (newRange == 'chwk' && currentRange == 'clwk'))
                newRange = currentRange;
        }
        return newRange;
    }

    _getPrevDateRange({dateStart, dateEnd}) {
        const duration = this._getSelectedDuration(dateStart, dateEnd),
            prevDateEnd = moment(dateStart).subtract(1, 'days'),
            prevDateStart = prevDateEnd.clone().subtract(duration, 'days');

        return {
            prevDateStart: prevDateStart.format(dateFormat),
            prevDateEnd: prevDateEnd.format(dateFormat)
        }
    }

    _getDefaultEndDate() {
      return moment().subtract(2, 'days');
    }
    
    _getDefaultVendors() {
        return [];
    }
    
    _fixVendors(currentVendors = []) {
        if(currentVendors.length) {
            return currentVendors.filter(vendor=>vendor.value!==youtubeVendor);
        }
        else
            return this._getDefaultVendors();
    }

    _defaultState() {
        let dateEnd = moment().subtract(1, 'month').set('date', moment().subtract(1, 'month').daysInMonth()),  //this._getDefaultEndDate(),
        dateStart = moment().subtract(1, 'month').set('date', 1), //this._getDateStart(dateEnd, defaultDuration),
        releaseEnd = null,
        releaseStart = null;

      return {
        filter: {
          dateStart: dateStart.format(dateFormat),
          dateEnd: dateEnd.format(dateFormat),          
          releaseStart: releaseStart,
          releaseEnd: releaseEnd,
          territories: [],
          imprints: [],
          artists: [],
          products: [],
          projects: [],
          tracks: [],
          genres: '',
          vendors: '',
          source: '',
          limit: defaultLimit,
        },
        currentFilterName: "currentFilter",
        currentFilter: {},
        accordion: {
            dates: false,
          territories: false,
          genres: false,
          vendors: false,
//          limit: false,
          filters: false,
          releaseDates: false,
          sources: false,
          imprints: false
        },
        filterOpened: false,
        isMobile: false,
        width: 0,
        calendarMaxDate: null
      }
    }

    prevDateChange(date, propDate) {
        date = moment(date);
        let filter = {
          ...this.state.filter,
        },
        duration = this._getSelectedDuration(filter.dateStart, filter.dateEnd),
        prevDateStart,
        prevDateEnd;

        if(propDate == 'prevDateStart') {
            prevDateStart = date,
            prevDateEnd = date.clone().add(duration, 'days');
        }
        else {
            prevDateEnd = date,
            prevDateStart = date.clone().subtract(duration, 'days');
        }
        filter.prevDateStart = prevDateStart.format(dateFormat);
        filter.prevDateEnd = prevDateEnd.format(dateFormat);

        this.setState({...this.state, filter});
    }


    dateChange(date, propDate) {
      date = moment(date);
      if(propDate == 'dateEnd'){
          date = date.set('date', date.daysInMonth());
      }
      date = date.format(dateFormat);
      
      const filter = {
        ...this.state.filter,
        [propDate]: date
      },
      {prevDateStart, prevDateEnd} = this._getPrevDateRange(filter);
      filter.range = this._getRange(filter);
      filter.prevDateStart = prevDateStart;
      filter.prevDateEnd = prevDateEnd;

      this.setState({...this.state, filter});
    }

    durationChange(durationIndex) {
      let dateEnd = this._getDefaultEndDate(),
        dateStart = this._getDateStart(dateEnd, durationIndex);

      if (!dateStart)
        dateStart = moment(this.state.filter.dateStart).add(1, 'days');

      const filter = {
        ...this.state.filter,
        dateStart: dateStart.format(dateFormat),
        dateEnd: dateEnd.format(dateFormat)
      },
      {prevDateStart, prevDateEnd} = this._getPrevDateRange(filter);
      filter.range = this._getRange(filter);
      filter.prevDateStart = prevDateStart;
      filter.prevDateEnd = prevDateEnd;

      this.setState({
        ...this.state,
        filter
      });
    }
    
    releaseDateChange(dateIndex) {
        
        const {offset} = releaseDates[dateIndex];
        if(offset) {
            let releaseEnd = offset[0].days !== null ? moment().subtract(offset[0].days, 'days') : null,
                releaseStart = offset[1].days !== null ? moment().subtract(offset[1].days, 'days') : null;
            
                
            const filter = {
                ...this.state.filter,
                releaseStart: releaseStart ? releaseStart.format(dateFormat) : '',
                releaseEnd: releaseEnd? releaseEnd.format(dateFormat) : ''
              };
            this.setState({
                ...this.state,
                filter
              });
            
                
        }
      }


    filterChange = (value, prop) => {
      const filter = {
        ...this.state.filter,
        [prop]: value
      };


      this.setState({
        ...this.state,
        filter
      });
    }
    
    entityFilterChange = (entityFilters) =>{
    	for(let key of Object.keys(entityFilters)) {
    		entityFilters[key] = map(entityFilters[key], (item=>item.id)); 
    	}
	    const filter = {
	        ...this.state.filter,
	        ...entityFilters
	    };
	    
	    console.log(filter);
        


      this.setState({
        ...this.state,
        /*
        accordion: {
        	...this.state.accordion,
        	imprints: false
        },
        */
        filter
      });
    	
    }

    filterReset = () => {
      const defaultState = this._defaultState();
      this.setState({
        ...defaultState,
        isMobile: this.state.isMobile,
        width: this.state.width,
        filterOpened: this.state.filterOpened
      });
    }

    filterSave = (e) => {
      e.preventDefault();

      const filterName = prompt('Please enter a name for your filter.');
      if (filterName !== '' && filterName !== null) {
        this.props.createFilter(this.state.filter, filterName);
      }
    }

    filterLoad = (filter, filter_name) => {
      let duration = this._getSelectedDuration(filter.dateStart, filter.dateEnd),
        dateEnd = this._getDefaultEndDate(),
        dateStart = dateEnd.clone().subtract(duration, 'days');

      let {prevDateStart, prevDateEnd} = this._getPrevDateRange({dateStart, dateEnd});
      filter.prevDateStart = prevDateStart;
      filter.prevDateEnd = prevDateEnd;
      filter.dateStart = dateStart.format(dateFormat);
      filter.dateEnd = dateEnd.format(dateFormat);
      filter.range = this._getRange(filter);
      //filter.vendors = this._fixVendors(filter.vendors);

      this.setState({
        filter,
        currentFilterName: filter_name
      });
    }

    filterApply(event, filter) {
      event && event.preventDefault();
      const filterNames = this.props.savedFilters.map(filter => filter.filter_name );
      const filter_name = filterNames.includes(this.state.currentFilterName) && this.state.currentFilterName;
      this.props.setGlobalFilter({...filter, ...{ filter_name }});
    }

    filterClose() {
      this.props.toggleGlobalFilter();
    }

    filterToggle() {
      this.setState({
        filterOpened: !this.state.filterOpened
      }) 

      console.log("test", this.state.filterOpened)
    }

    filterShare = (id, shared) => {
        // we get filter's initial state, so we don't overwrite it intentionally
        let filter = find(this.props.savedFilters, {id});
        filter.is_shared = shared;
        return this.updateFilter(id, filter);
    }

    toggleAccordion = (selectedKey) => {
      let {
        accordion
      } = this.state;
      for (let key in accordion) {
        accordion[key] = (key == selectedKey) ? !accordion[key] : false;
      }

      this.setState({
        accordion
      })
    }

    closeAccordion = (selectedKey) =>  {
      let { accordion } = this.state;

      for (let key in accordion) {
        if (key == selectedKey) {
          accordion[key] = false;
        }
      }
      this.setState({
        accordion
      })
    }

    handleChange = (id, filter) => {
      this.setState({
        ...this.state,
        filter
      })
    }

    removeFilter = id => {
      this.filterReset();
      this.props.onRemoveFilter(id);
    }

    updateFilter = (id, filter=this.state.filter) => {
      this.props.onUpdateFilter(id, filter);
    }

    updateDimensions = () => {
      this.setState({ width: window.innerWidth });
      console.log("widthstate", window.innerWidth)
    };

    componentDidMount() {
      const { getFilters, getSystemFilter, user } = this.props;

      const silo = user.data_silo_codes;
      getFilters();
      getSystemFilter();

      this.updateDimensions();
      this.match = window.matchMedia(`(max-width: 1279px)`);
      this.checkWidth = (e) => {    
        if (e.matches) {
          this.setState({
            isMobile: true
          })
        }
      };

      this.checkWidth(this.match);
      this.match.addListener(this.checkWidth);

      window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
      this.match.removeListener(this.checkWidth);
      window.removeEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps){
      if (this.props.savedFilters.length !== prevProps.savedFilters.length) {
        const filter = this.props.savedFilters.find( filter => filter.filter_name === this.state.filter.filter_name);
        if (filter)
          this.filterLoad({ ...filter.filter_body, is_shared: filter.is_shared }, filter.filter_name)
      }
    }

    getAllowedDays(component){
        const {range} = this.state.filter;
        let allowedDay = '';
        /*
        switch(range){
            case 'chwk':
                allowedDay = component == 'start' ? '5' : '4';
            break;
            case 'clwk':
                allowedDay = component == 'start' ? '1' : '0';
            break;
        }
        */
        return allowedDay ? '0123456'.replace(allowedDay, '') : '';
    }

    getMaxDate(field) {
        let maxDate,
            {filter} = this.state;
        switch(field) {
            case 'dateStart':
                maxDate = moment(filter.dateEnd).subtract(1, 'days');
                break;

            case 'prevDateEnd':
                maxDate = moment(filter.dateStart).subtract(1, 'days');
                break;

            case 'prevDateStart':
                maxDate = moment(filter.dateStart).subtract(this._getSelectedDuration(filter.dateStart, filter.dateEnd)+1, 'days');
                break;

            case 'releaseStart':
                maxDate = moment(filter.releaseEnd).subtract(1, 'days');
                break;
                
            case 'dateEnd':
            case 'releaseEnd':
                maxDate = moment();
                break;
        }
        return maxDate.format(dateFormat);
    }
    vendorChange(values){
        let vendors = '';
        if(values) {
            vendors = values.reduce((str, vendor)=> {
                str += str ? '|' : '';
                str += vendor.value;
                return str;
            }, '');
        }
        this.filterChange(vendors, 'vendors');

    }

    deselectAllVendorFilter() {
      this.filterChange([], 'vendors');
    }

    selectAllTerritoriesFilter(value) {
      if (Array.isArray(value) && value.length == 0) {
        value = territories;
      }
        
      const flattenArray = _.map(_.flatMapDeep(value), (v) => v.value);
      this.filterChange(flattenArray, "territories");
    }

    deselectAllTerritoriesFilter() {
      this.filterChange([], "territories");
    }
    
    selectAllSourcesFilter() {
        this.filterChange(sources, 'sources');
      }
    

    deselectAllSourcesFilter() {
        this.filterChange([], 'sources');
      }
    
    
    limitChange(value){
        value = limits[value];
        this.filterChange(value, 'limit');
    }
    
    genreChange(values){
        let genres = '';
        if(values) {
            genres = values.reduce((str, genre)=> {
                str += str ? ',' : '';
                str += genre.value;
                return str;
            }, '');
        }
        this.filterChange(genres, 'genres');
    }
    
    imprintChange(values){
    	let imprints = map(values, (value)=>value.value);
//        let imprints = '';
//        if(values) {
//            imrpints = values.reduce((str, imprint)=> {
//                str += str ? ',' : '';
//                str += imprint.value;
//                return str;
//            }, '');
//        }
        this.filterChange(imprints, 'imprints');
    }

    
    genreModeChange(mode) {
        let currentGenres = this.state.filter.genres,
            findStr, replaceStr;
        if(mode == 'and') {
            findStr = ',';
            replaceStr = '+';
        }
        else {
            findStr = '+';
            replaceStr = ',';
        }
        
        const genres = currentGenres.split(findStr).join(replaceStr);
        
        this.setState({
            ...this.state,
            genresMode: mode,
            filter: {
                ...this.state.filter,
                genres
            }
        })
    }

    engagementModeChange(active) {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                active
            }
        })
    }

    sourceChange(source) {
      console.log(source);
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                source
            }
        })
    }

    
    filteredModeChange(filtered) {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filtered
            }
        })
    }
    

    renderDateRange(){
        const { range } =  this.state.filter,
            selectedRange = find(ranges, {value: range}),
            isWeekMode = range == 'chwk' || range == 'clwk';

        return <div className="date-range-additional">
            <label>Date Group: {selectedRange.label}</label>
            {isWeekMode && <div className="date-range-holder">
                <div className="option option-item">
                  <div className="filter-radio-holder">
                    <input type="radio" name="week_mode" id="chart_week" checked={(range=='chwk')} onClick={()=>this.filterChange('chwk', 'range')} />
                    <label htmlFor="chart_week">Chart Week</label>
                  </div>
                </div>
                <div className="option option-item">
                  <div className="filter-radio-holder">
                    <input type="radio" name="week_mode" id="calendar_week" checked={(range=='clwk')} onClick={()=>this.filterChange('clwk', 'range')} />
                    <label htmlFor="calendar_week">Calendar Week</label>
                  </div>
                </div>
            </div>}
        </div>;
    }

    render() {
        const isMobileWidth = this.state.width < 1280 ? true : false;
        const { readonly = false } = this.props; 
        const renderSavedFilters = () => {
          const savedFilters = this.props.savedFilters && this.props.savedFilters.map( filter => {
            return (<li
                      key={filter.filter_name}
                      className={`main-filter-item ${filter.filter_name == this.state.currentFilterName ? 'active' : ''}`}

                    >
                      <a className="filterItem" onClick={(e)=>{this.filterLoad({ ...filter.filter_body, is_shared: filter.is_shared }, filter.filter_name )}}>{filter.filter_name}</a>
                      <div className="filterActions">
                        {
                          this.props.user.id === filter.user_id
                          ?
                          <span>
                            {this.props.user.client_admin && filter.filter_name === this.state.currentFilterName && (
                              <div className="filterShared">
                                <span>Shared: </span>
                                <input type="checkbox" disabled={filter.filter_name !== this.state.currentFilterName} defaultChecked={filter.is_shared} onChange={e => this.filterShare(filter.id, e.target.checked )}/>
                              </div>
                            )}
                            {
                              filter.filter_name === this.state.currentFilterName && (
                                <div>
                                  <div><span onClick={() => this.removeFilter(filter.id)} className="removeAction"> Remove </span></div>
                                  <div><span onClick={() => this.updateFilter(filter.id)} className="removeAction"> Replace with current </span></div>
                                </div>
                              )
                            }
                          </span>
                          :
                          null
                        }
                      </div>
                    </li>);
          })
          return savedFilters;
        }

        const filter = this.state.filter,
            selectedTerritories = filter.territories ? filter.territories.slice(0) : [],
            selectedDuration = this._getDuration(),
            selectedReleaseDate = this._getReleaseDate(),
            selectedSources = filter.sources ? filter.sources.slice(0) : [],
            selectedSource = filter.source,
            selectedRange = this.state.filter.range,
            selectedGenresMode = this.state.genresMode,
            selectedEngagementMode = filter.active,
            selectedFilteredMode = filter.filtered,
            { calendarMaxDate } = this.state;
            

        let selectedReleaseDateLabel = find(releaseDates, (releaseDate)=>releaseDate.value == selectedReleaseDate).label;
        if(selectedReleaseDate == 5) { // custom date range
        	const { releaseStart, releaseEnd } = this.state.filter;
        	selectedReleaseDateLabel  = `${releaseStart} to ${releaseEnd}`;
        }
        
        let selectedGenres = [];    
        if(filter.genres) {
            selectedGenres = map(filter.genres.split(selectedGenresMode == 'and' ? '+' : ',') , genre=>({label: genre, value: genre}));
        }
        
        let selectedVendors = [];    
        if(filter.vendors) {
            selectedVendors = map(filter.vendors.split('|') , vendor=>({label: vendor, value: vendor}));
        }

//        let selectedImprints = [];    
//        if(filter.imprints) {
//            selectedImprints = map(filter.imprints.split(',') , imprint=>({label: imprint, value: imprint}));
//        }
        
        let sliderValue = 0,
            sliderLength = 0;
        for(let valueIndex of Object.keys(limits)) {
            sliderLength++;
            if(limits[valueIndex]==filter.limit)
                sliderValue = valueIndex;
        };
        
        let sourceValues = sources.map(source => ({
            label: source,
            value: source
        }));

        let vendorsCount = 'All';
        if (this.state.filter.vendors && this.state.filter.vendors.length) {
          vendorsCount = this.state.filter.vendors.split('|').length;
        }

        let genresList = '';
        if (this.state.filter.genres && this.state.filter.genres.length)
          genresList = this.state.filter.genres.split(',').join(', ');

        const filterMobileClass = isMobileWidth ? "mobile-filter" : "";
        const filterOpenedClass =  isMobileWidth && !this.state.filterOpened ? "filter-closed" : "";
        const imprintData = this.props.imprints.items ? this.props.imprints.items : [];
        let allImprints = imprintData.map(imprint => ({label: imprint.imprint, value: imprint.imprint_id}));
        let selectedImprints = filter.imprints || []; 
        selectedImprints = selectedImprints.map(imprintId=>{
			const imprint = find(imprintData, (imprint)=>imprint.id==imprintId);
			if(imprint)
				return {label: imprint.imprint, value: imprint.imprint_id};
			else
				return {label: 'n/a', value: 0};
        });
        
        const { vendors = [] } = this.props.vendors;
    	const allVendors = map(vendors, (vendor)=>({label: vendor.name, value: vendor.name}));

        return ( <div className="filter-wrapper">
            {/* <button className="close-btn" onClick={this.filterClose}></button> */}
            <div className="filterSpinner">
              <Spinner enabled={this.props.loading} />
            </div>
            <h2 className="filter-title">Filters</h2>
            {isMobileWidth && <div className="filter-toggle-btn" onClick={this.filterToggle}><span className="fa fa-sliders"></span></div>}
            
            <form role="form" className={`filter-main-form ${filterMobileClass} ${filterOpenedClass}`}>
                <div className="filter-wrapper-inner">
                    <Accordion className="chosen-group" id="dates" label="Date Range" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} dateStart={this.state.filter.dateStart} dateEnd={this.state.filter.dateEnd} closeAccordion={this.closeAccordion} readonly={readonly} >
                        <label>Current Date Range</label>
                        <div className="daterange-container" id="main-date-range-container">
                            <Datepicker readonly={readonly} endDate={calendarMaxDate} date={filter.dateStart} label="From" dateChange={(date)=>this.dateChange(date, 'dateStart')} daysOfWeekDisabled={this.getAllowedDays('start')} container="#main-date-range-container" />
                            <span className='fas fa-long-arrow-alt-right'></span>
                            <Datepicker readonly={readonly} endDate={calendarMaxDate} date={filter.dateEnd} label="To" dateChange={(date)=>this.dateChange(date, 'dateEnd')}  daysOfWeekDisabled={this.getAllowedDays('end')} container="#main-date-range-container" />
                        </div>
                        {/*this.renderDateRange()*/}
                    </Accordion>
                    <Accordion className="chosen-group" id="territories" label="Territories" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} closeAccordion={this.closeAccordion} readonly={readonly}>
                        <Multiselect className="territories-container" elements={territories} selected={selectedTerritories} handleChange={(value)=>this.filterChange(value, 'territories')} options={{enableClickableOptGroups: true}} />
                        <div>
                          <a onClick={(e)=>this.selectAllTerritoriesFilter([])}>Select All</a> 
                          <span> / </span>
                          <a onClick={(e)=>this.deselectAllTerritoriesFilter([])}>Deselect All</a> 
                        </div>
                    </Accordion>
                    <Accordion className="chosen-group" id="vendors" label="Platforms" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} vendorsCount={vendorsCount} closeAccordion={this.closeAccordion} readonly={readonly}>
                        <ReactSelect isMulti options={allVendors} placeholder="Search vendors" onChange={this.vendorChange} defaultValue={selectedVendors} />
                    </Accordion>
                    <Accordion className="chosen-group" id="sources" label="Sources" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} closeAccordion={this.closeAccordion} readonly={readonly} source={selectedSource ? selectedSource: 'All'}>
                        <Multiselect elements={sourcesOfRevenue} selected={selectedSource} handleChange={this.sourceChange} options={{disable_search: true}} />
                    </Accordion>
                    {/*
                    <Accordion className="chosen-group" id="genres" label="Genres" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} genresList={genresList} closeAccordion={this.closeAccordion}>
                        <div className="date-range-holder date-range-holder-genres">
                            <div className="option option-item">
                              <div className="filter-radio-holder">
                                <input type="radio" name="genre_mode" id="and" checked={(selectedGenresMode=='and')} onClick={()=>this.genreModeChange('and')} />
                                <label htmlFor="and">All selected genres</label>
                              </div>
                            </div>
                            <div className="option option-item">
                              <div className="filter-radio-holder">
                              <input type="radio" name="genre_mode" id="or" checked={(selectedGenresMode=='or')} onClick={()=>this.genreModeChange('or')} />
                                <label htmlFor="or">Any selected genre from the list</label>
                              </div>
                            </div>                    
                        </div>
                        <ReactSelect isMulti options={this.state.allGenres} placeholder="Search genres" onChange={this.genreChange} defaultValue={selectedGenres} />
                    </Accordion>
                    */}
                    <Accordion className="chosen-group" id="releaseDates" label="Release Date" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} selectedReleaseDateLabel={selectedReleaseDateLabel} closeAccordion={this.closeAccordion} readonly={readonly}>
                        <Multiselect elements={releaseDates} selected={selectedReleaseDate} handleChange={this.releaseDateChange} options={{disable_search: true}} />
                        <p></p>
                        <label>Custom Date Range</label>
                        <div className="daterange-container" id="release-date-range-container">
                            <Datepicker readonly={readonly} endDate={this.getMaxDate('releaseStart')} date={filter.releaseStart} label="From" dateChange={(date)=>this.dateChange(date, 'releaseStart')} placeholder="Start Date" container="#release-date-range-container" />
                            <span className='fas fa-long-arrow-alt-right'></span>
                            <Datepicker readonly={readonly} endDate={this.getMaxDate('releaseEnd')}  date={filter.releaseEnd} label="To" dateChange={(date)=>this.dateChange(date, 'releaseEnd')} placeholder="Today" container="#release-date-range-container" minDate={filter.releaseStart} />
                        </div>
                    </Accordion>
                    <Accordion className="chosen-group" id="imprints" label="Entity Filter" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} closeAccordion={this.closeAccordion} readonly={readonly}>
	                	<EntityFilter onSubmit={this.entityFilterChange} mainEntityFilter={this.state.filter}/>
		                {/*
		
		
		                    <ReactSelect isMulti options={allImprints} placeholder="Search Labels" onChange={this.imprintChange} defaultValue={selectedImprints} />
		                */}
		                
	                </Accordion>                    
                    
                    {/*
                    <Accordion className="" id="limit" label="Limit" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion}>
                        <Slider marks={limits} value={sliderValue} onChange={(value)=>this.limitChange(value)} step={null} max={sliderLength-1} />
                    </Accordion>
                    */}
                </div>
                
                {!readonly && <div className="filter-links-holder">
                  <div className="form-group reset-link-container">
                      <a onClick={(e)=>this.filterReset(e)}>Reset default filters</a>
                  </div>
                
                  
                  <div className="form-group button-container">
                      <button className="btn btn-primary apply-btn" alt="apply" onClick={(e)=>{this.filterApply(e, this.state.filter); this.filterClose();}}>Apply filter</button>
                  </div>

                </div>}
            </form>
        </div>)
    }
}

function mapDispatchToProps(dispatch){
  return {
    getFilters: () => dispatch(filterActions.getFilters()),
    getSystemFilter: () => dispatch(globalFilterActions.getGlobalFilter()),
    onUpdateFilter: (id, filter) => dispatch(filterActions.updateFilter(id, filter)),
    onRemoveFilter: id => dispatch(filterActions.removeFilter(id)),
    createFilter: (filter, filterName) => dispatch(filterActions.createFilter(filter, filterName)),
    setGlobalFilter: filter => dispatch(filterActions.setGlobalFilter(filter)),
    toggleGlobalFilter: () => dispatch(filterActions.toggleGlobalFilter()),
    getImprints: () => dispatch(imprintsActions.getImprints()),
    getVendors: () => dispatch(vendorsActions.getVendors()),
    getMaxDate: () => dispatch(filterActions.getMaxDate(true)),
  }
}

function mapStateToProps(state) {
  return {
	  imprints: state.imprints,
      filter: state.filter,
      savedFilters: state.filter.data,
      loading: state.filter.loading,
      user: state.user.user,
      vendors: state.vendors
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
