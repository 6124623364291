import React from 'react';
import { convertToThousands, round } from '../../../helpers/NumberFormatter';
import PercentageValue from '../../common/PercentageValue';

export default function OverviewCard(props) {
    const {active, entity} = props;
    return <div className={`overview-card ${active ? 'active' : ''}`}>
        <i className="fa fa-question entity-question-sign"></i>
        <i className={`fa fa-${entity.icon} rounded-icon`} />
        <div>
            <h2 className="title-num">{convertToThousands(entity.absoluteValue)}</h2>
            <h3 className="title-totals">{entity.title}</h3>
            {entity.percentageValue && <PercentageValue onlyPercentage={true} item={entity} field="units" /> }
        </div>
    </div>
}