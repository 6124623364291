import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AbsoluteValue from '../../../common/AbsoluteValue';
import PercentageValue from '../../../common/PercentageValue';
import Tooltip from '../../../common/Tooltip';

function renderName(item) {
    let name;
    if(item.name)
        name = `${item.name} (${item.isrc})`;
    else
        name = item.isrc;
    
    return <Link to={`/tracks/${item.id}`}>{name}</Link>
}


export const playlistTracksSettings = {
    settingsForTable
};

const modes={
    'expanded': ['artist_name', 'track', 'isrc', 'curr_units'],
    'details': ['artist_name', 'track_and_isrc', 'prev_units', 'curr_units', 'growth', 'passive', 'active', 'engaged', 'skipped_ratio', 'completed_ratio', 'days_in_list']
};

function settingsForTable(mode){
    let columns = [
        {
            id: 'artist_name',
            className: 'playlist-subtable-td details-td',
            headerClassName: 'playlist-subtable-td details-td table-header-item',
            Header: 'Artist',
            accessor: 'artist_name',
            width: 170,
        },                                 
        {
           id: 'track',
           className: 'playlist-subtable-td details-td',
           headerClassName: 'playlist-subtable-td details-td table-header-item',
           Header: 'Track',
           //accessor: 'name',
           width: 280,
           Cell: props => <Link to={`/tracks/${props.original.id}`}>{props.original.name}</Link>
       },
       {
           id: 'isrc',
           className: 'playlist-subtable-td',
           headerClassName: 'playlist-subtable-td table-header-item',
           Header: 'ISRC',
           accessor: 'isrc',
           // width: 96,
       },
       {
           id: 'track_and_isrc',
           headerClassName: 'playlist-subtable-td details-td table-header-item',
           className: 'playlist-subtable-td details-td',
           Header: 'Name',
           Cell: props => renderName(props.original),
           width: 296,
       },                          
       {
           id: 'prev_units',
           headerClassName: 'table-header-item',
           Header: 'Prev Streams',
           accessor: 'prev_units',
           Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
           // width: 138
       },
       
       {
           id: 'curr_units',
           className: 'playlist-subtable-td',
           headerClassName: 'table-header-item playlist-subtable-td',
           Header: 'Streams',
           accessor: 'curr_units',
           Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
           // width: 138
       },
       {
           id: 'growth',
           headerClassName: 'table-header-item',
           Header: 'Growth',
           Cell: props => <PercentageValue field="units" item={props.original} onlyPercentage={true}></PercentageValue>,
           // width: 96,
       },
       {
           id: 'passive',
           headerClassName: 'table-header-item',
           Header: props => <div className="top-table-header-inner">
               Passive Streams
               <Tooltip 
                position="right-bottom" 
                message={`Passive Streams: editorial, third party and algorithmic playlists, radio, and other (such as the Spotify autoplay feature). This may also include streams from a playlist with the artist name (This is, Essentials…) where the artist is not the main primary artist on the track, like featuring or remixes for instance.`} 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           accessor: 'passive',
           Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
           // width: 138
       },
       
       {
           id: 'active',
           headerClassName: 'table-header-item',
           Header: props => <div className="top-table-header-inner">
               Active Streams
               <Tooltip 
                position="right-bottom" 
                message={`Active Streams: streams coming from search, artist page, album page, collections, and playlists with the artist name in it (This is, Essentials…)`} 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           accessor: 'active',
           Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
           // width: 138
       },
       {
           id: 'engaged',
           headerClassName: 'table-header-item',
           Header: 'Engaged',
           accessor: 'engaged',
           Cell: props => <span>{props.value}%</span>,
           // width: 138
       },
       
       {
           id: 'skipped_ratio',
           headerClassName: 'table-header-item',
           Header: props => <div className="top-table-header-inner">
               Skipped
               <Tooltip 
                position="right-bottom" 
                message={`The Skip rate only applies to streams over 30 seconds. It's a metric provided by all DSPs except YouTube and Google.`} 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           accessor: 'skipped_ratio',
           Cell: props => <span>{props.value}%</span>,
           // width: 138
       },       
       {
           id: 'completed_ratio',
           headerClassName: 'table-header-item',
           Header: props => <div className="top-table-header-inner">
               Completed
               <Tooltip 
                position="right-bottom" 
                message={`A track is marked as complete when the stream reached 95% of the track length. It's a metric provided by all DSPs except YouTube and Google.`} 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           accessor: 'completed_ratio',
           Cell: props => <span>{props.value}%</span>,
           // width: 138
       },            
       {
           id: 'days_in_list',
           Header: 'Days in list',
           headerClassName: 'table-header-item no-sort',
           accessor: 'days_in_list',
           Cell: props => <span>{props.value}</span>,
           // width: 134
           sortable: false,
       },
       
    ];
    return columns.map(column=>{
        column.show = modes[mode].includes(column.id);
        return column;
    });    
}
