import $ from 'jquery';
import config from '../../config/config';
import AuthHeader from '../../helpers/AuthHeader';

export const heartbeatsService = {
  getHeartbeats,
  getPagedHeartbeats
}

function getHeartbeats(date){
    return config.live ? getHeartbeatsLive(date) : getHeartbeatsStatic();
}

function getHeartbeatsLive(date){
  const requestOptions = {
    method: 'GET',
    headers: AuthHeader()
  };
  const baseUrl = `${config.apis.orthus}/v1/heartbeats?period_date=${date}`;

  return fetch(baseUrl, requestOptions)
  .then( response => {
    return response.json();
  })
  .catch( error => {
    return error;
  })
}

function getHeartbeatsStatic(date){
    let baseUrl = `${config.apis.sales}/api/static/heartbeats.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getPagedHeartbeats(params){
    return config.live ? getPagedHeartbeatsLive(params) : getPagedHeartbeatsStatic(params);
}

function getPagedHeartbeatsLive(params){
    console.log(params);
  const requestOptions = {
    method: 'GET',
    headers: AuthHeader()
  };
  let qs = {};
  qs.period_date = params.period_date;
  qs.page = params.page + 1;
  qs.per_page = params.pageSize;
  
  let {sorted, filtered} = params;
  if(sorted !== undefined){
      qs.order_by = sorted[0].id;
      qs.order_dir = sorted[0].desc ? 'desc':'asc';
  }
  
  if(filtered !== undefined && filtered.length){
      for(let filter of filtered) {
          let value = filter.value;
          if(!value)
              continue;
          if(value instanceof Array) {
              value = value.join(',');
              if(!value)
                  continue;
              qs[filter.id] = value;
          }
          else {
              qs[filter.id] = value;    
          }
      }            
  }    

  
  qs = $.param(qs);
  const baseUrl = `${config.apis.orthus}/v1/heartbeats?${qs}`;
  return fetch(baseUrl, requestOptions)
//return Promise.resolve('[]')
  .then( response => {
    return response.json();
  })
  .catch( error => {
    return error;
  })
}

function getPagedHeartbeatsStatic(params){
    console.log(params);
    let baseUrl = `${config.apis.sales}/api/static/paged_heartbeats.json`;
    return fetch(baseUrl).then(res=>res.json());
}
