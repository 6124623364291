import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Spinner from '../../common/Spinner';
import PercentageValue from '../../common/PercentageValue';
import Channels from './Channels';
import AudienceAnalysisLink from '../../common/Datagrid/AudienceAnalysisLink';
import EntityStatus from '../../common/Datagrid/EntityStatus';
import { convertToThousands } from  '../../../helpers/NumberFormatter';
import Tooltip from '../../common/Tooltip';

class ArtistOverview extends React.Component {
    render (){
        const { artist, loading } = this.props;
        
        let metaTitle = ( artist && artist.name) ? `${artist.name} (artist) - ` : '';
        metaTitle += 'Details';

        return <div className="row catalog-overview home artist-overview">
            {loading !== false ? <Spinner enabled={true} /> : <div className="container container-small">
                <div className="row artist-profile-holder">
                    <div className="col-lg-9 col-md-12 col-xs-12 artist-info-holder">
                        <h2 className="big-title">Artist Profile</h2>
                        <div className="artist-main-info">
                            <div className="logo" style={{backgroundImage: `url(${artist.image})`}} >
                            </div>
                            <div className="title-container">
                                <h1 className="content-title">
                                    <span className="content-title-span">{artist.name}</span>
                                    <div className="artist-details-link">
                                        <AudienceAnalysisLink title="Artist Audience" entity="artists" id={artist.id} />
                                        <EntityStatus entity="artists" id={artist.id} />
                            		</div> 
                                </h1>
                                {/*
                                <p className="genres">
                                    {artist.genres && artist.genres.join(' | ')} 
                                </p>
                                */}
                            </div>
                        </div>
                        {/*<p className="bio">{artist.bio}</p>*/}
                    </div>
                    <div className="col-lg-3 col-md-12 col-xs-12 artist-contacts">
                        {/* <div className="connected-socials">
                            <div className="title-container">
                                <h3 className="additional-info-title">Connected social platforms</h3>
                                <i className="fa fa-question question-sign"></i>
                            </div>
                            <Channels artist={artist} /> 
                        </div>
                        */}
                        {/*
                        <div className="additional-info">
                            <h3 className="additional-info-title">Origin: <span className="plain-text">{artist.origin}</span></h3>
                            <h3 className="additional-info-title">Labels: <span className="plain-text">{artist.labels && artist.labels.join('| ')}</span></h3>
                        </div>
                        */}
                    </div>                    
                </div>
                <div className="row">
                    {artist.totals && artist.totals.map(entity=>{
                        return <div key={entity.title} className={`col-sm-3 col-xs-6 overview-widget-container ${entity.title}`}>
                            <div className="widget overview-widget white-bg p-lg text-center">
                                {/* <i className="fa fa-question entity-question-sign entity-question-sign--trend"></i> */}
                                <i className={`fa fa-${entity.icon} rounded-icon`} />
                                <h2 className="title-num">{convertToThousands(entity.absoluteValue)}</h2>
                                <h3 className="title-totals">{entity.title}</h3>
                                <PercentageValue onlyPercentage={true} item={entity} field={entity.key} />
                                {entity.title === "streams" && 
                                <Tooltip 
                                    position="bottom" 
                                    message={`Total Artist streams on the Overview and Catalogue pages are for products and tracks where the artist is Primary Position One only. On the Artist Details page, Total streams are higher because they also include products and tracks where the artist is not the first Primary artist listed.`} 
                                    tooltipClass="top-sections-tooltip"
                                />}
                            </div>                            
                        </div>
                    })} 
                </div>
                {artist.related_artists && <div className="row">
                    {artist.related_artists.length && <div className="col-xs-12">
                        <h2 className="">Related Artists</h2>
                        <div className="similar-artists">
                            {artist.related_artists.map((artist)=><div key={artist.id} className="logo-container">
                                {/*<Link to={`/artists/${artist.id}`}>*/}
                                    <div title={artist.name} className="logo" style={{backgroundImage: `url(${artist.image})`}} data-toggle="tooltip" data-placement="top" title={artist.name} /> 
                                {/*</Link>*/}
                            </div>)}
                        </div>
                    </div>}
                </div>}
            </div> }
            <Helmet>
                <title>{metaTitle}</title>
            </Helmet>

        </div> 
    }
}


export default ArtistOverview