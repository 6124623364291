import store from '../../helpers/store';
import crypto from '../../helpers/crypto'
import { userConstants } from '../constants/user';
import { errorConstants } from '../constants/error';
import { userFormatter } from '../formatters/user';
import { userService } from '../services/user';
import { history } from '../../helpers/history';
import { filterActions } from './filter'

export const userActions = {
    login,
    sso,
    logout,
    sendTicket,
    track,
    uploadAvatar,
    shareLogin
};

function login(params) {
    return dispatch => {
        dispatch(request());
        //store.set('email', params.email);
        userService.login(params)
            .then(user => {
            		if(!user.show_statements) {
            			return dispatch(failureMessage("Sorry, you do not have statement data access, please speak to your system administrator"));
            		}
                    user = userFormatter.formatUser(user);
                    store.set('user', user);
                    dispatch(success(user));
                }
            )
            .catch(error => {
                dispatch(failure(errorMessage));
                let errorMessage = 'API error';
                switch(error.status){
                    case 400:
                        errorMessage = 'Please provide both email and password';
                        break;
                    case 401:
                        errorMessage = 'Email and password combination is invalid';
                        break;
                }
                dispatch(failureMessage(errorMessage));

            });
    };

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user} }
    function failureMessage(message) { return { type: errorConstants.SET_ERROR_MESSAGE, message, title: 'login error' } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function sso(token) {
    return dispatch => {
        dispatch(request());
        userService.sso(token)
            .then(user => {
                store.set('user', user);
                dispatch(success(user));
            })
            .catch(error => {
                error.json().then(err=>{console.log(err); dispatch(failure(err.err));});
            });
    };

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user} }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function logout() {
    return dispatch => {
        store.clearAll();
        dispatch(success());
        history.push('/login');

    };
    function success() { return { type: userConstants.LOGOUT_SUCCESS } }
}

function sendTicket(params) {
    return dispatch => {
        return userService.sendTicket(params)
        .then(res => {
                dispatch(success('ticket was successfully submitted'));
                return true;
            }
        )
        .catch(error => {
            dispatch(failure('there was an error'));
        });
    };

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(message) { return { type: errorConstants.SET_SUCCESS_MESSAGE, message, title: '' } }
    function failure(message) { return { type: errorConstants.SET_ERROR_MESSAGE, message, title: 'send ticket error' } }
}

function track(path, page_title, params) {
    return dispatch => {
        userService.track({path, page_title, params});
    }
}


function uploadAvatar(params) {
    return dispatch => {
        userService.uploadAvatar(params)
        .then(res => {
                const { avatar_url } = res;
                dispatch(success('Avatar Uploaded'));
                dispatch(uploadSuccess(res.avatar_url));
                //
                let user = store.get('user');
                user.avatar_url = avatar_url;
                store.set('user', user);
            }
        )
        .catch(error => {
            dispatch(failure('Error Uploading Avatar'));
        });
    };

    function request() { return { type: userConstants.AVATAR_UPLOAD_REQUEST } }
    function success(message) { return { type: errorConstants.SET_SUCCESS_MESSAGE, message, title: '' } }
    function uploadSuccess(avatar) { return { type: userConstants.AVATAR_UPLOAD_SUCCESS, avatar } }
    function failure(message) { return { type: errorConstants.SET_ERROR_MESSAGE, message, title: '' } }
}

function shareLogin(slug) {
    return dispatch => {
        dispatch(request());
        return userService.shareLogin(slug)
            .then(shareData => {
                let { user_data: userData, filter_data: filterData } = shareData;
                userData = JSON.parse(crypto.decrypt(userData));
                store.set('user', userData);
                dispatch(filterActions.setGlobalFilter(filterData));
                dispatch(success(userData));
                
                //dispatch filter
                
                return shareData;
            })
            .catch(error => {
                let errorMessage = 'API error';
                if(typeof error == 'string')
                    errorMessage = error;
                else {
                    
                    // todo: move to service
                    switch(error.status){
                        case 404:
                            errorMessage = 'This link is expired';
                            break;
                        default:
                            errorMessage = error.message;
                    }
                }
                dispatch(failure(errorMessage));
                
                throw errorMessage;

            });
    };

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user} }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}