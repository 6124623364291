import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';


export const playlistsService = {
    getPlaylists,
    getTopPlaylists,
    getPlaylistStats,
    getMetadata,
    getTimeseriesPlaylists,
    getPlaylistShare,
    getPlaylistTypesSources
};

function getPlaylists(params, globalFilter, mode){
    return config.live ? getPlaylistsNew(params, globalFilter, mode) : ( mode == 'main' ? getPlaylistsStatic(params) : getPlaylistsTotalStatic(params) );
}

function getPlaylistsNew(params, globalFilter, mode = 'main') {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };

    qs.query_name = 'playlist_library_search';
    qs.mode = mode;
    qs.offset = params.page ? params.page*globalFilter.limit : 0;

    let {sorted, filtered } = params;
    if(sorted !== undefined){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;

            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }
        //
        if(!qs.search_term)
            delete qs.search_by;
        //
    }    
    
    baseUrl += '?' + $.param(qs);
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getPlaylistsStatic(params) {
    console.log(params);
    let baseUrl = `${config.apis.sales}/api/static/playlist_search.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getPlaylistsTotalStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/playlist_totals.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}


function getTopPlaylists(params, globalFilter){
    return config.live ? getTopPlaylistsLive(params, globalFilter) : getTopPlaylistsStatic();
}

function getTopPlaylistsLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
        
    qs.query_name = 'top_playlists';
    // qs.offset = 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    

    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTopPlaylistsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_playlists_simple.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getPlaylistStats(id, globalFilter){
    return config.live ? getPlaylistStatsLive(id, globalFilter) : getPlaylistStatsStatic();
}

function getPlaylistStatsStatic(mode) {
    let baseUrl = `${config.apis.sales}/api/static/playlist_details.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getPlaylistStatsLive(id, globalFilter) {
    let baseUrl = config.apis.playlist,
        requestOptions = {
            headers: AuthHeader()
        };
            
    baseUrl += `/playlists/int/${id}.json`;
    return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function getMetadata(ids){
    return config.live ? getMetadataLive(ids) : getMetadataStatic();
}

function getMetadataLive(ids) {
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.playlist}/playlists/by_id.json`, //
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}),
            body: 'ids='+ids
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataStatic() {
    let baseUrl = `${config.apis.sales}/api/static/playlists_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}


function getTimeseriesPlaylists(params, globalFilter){
    return config.live ? getTimeseriesPlaylistsLive(params, globalFilter) : getTimeseriesPlaylistsStatic();
}

function getTimeseriesPlaylistsLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
        
    qs.query_name = 'top_playlists';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    

    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTimeseriesPlaylistsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_playlists_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getPlaylistShare(id, globalFilter){
    return config.live ? getPlaylistShareLive(id, globalFilter) : getPlaylistShareStatic();
}

function getPlaylistShareStatic(mode) {
    let baseUrl = `${config.apis.sales}/api/static/playlist_share.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getPlaylistShareLive(id, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'playlist_share';
    qs.mode = 'main';
    qs.ids = id;
    
    baseUrl += '?' + $.param(qs);
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}


function getPlaylistTypesSources(entity, ids, globalFilter){
    return config.live ? getPlaylistTypesSourcesLive(entity, ids, globalFilter) : getPlaylistTypesSourcesStatic();
}

function getPlaylistTypesSourcesStatic(mode) {
    let baseUrl = `${config.apis.sales}/api/static/playlists_types_sources.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getPlaylistTypesSourcesLive(entity, ids, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'playlists_types_sources';
    qs.mode = 'main';
    qs[entity] = ids;
    
    baseUrl += '?' + $.param(qs);
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}
