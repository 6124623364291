import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { Select, } from './elements'   
import Tooltip from "../common/Tooltip";
import playlistSearchValues from './playlistSearch.json';
import { map } from 'lodash';

var searchSvg = require('!svg-inline-loader!../../../public/img/search.svg');

class PlaylistTypeDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
    }
    
    toggleExpand = (force) => {
        this.setState({
            expanded: force!==undefined ? force : !this.state.expanded
        })
    }
    
    render() {
        const { children, values, labelRef } = this.props,
            { expanded } = this.state,
            dropdownClass = expanded ? 'fa-caret-up' : 'fa-caret-down',
            label = (Array.isArray(values) && values.length )? values.join(', ') : 'All';            
            
        return <div className="playlist-search-dropdown-holder">
            <span className="playlist-search-dropdown-label" onClick={()=>this.toggleExpand()}>{label} <i className={`fas ${dropdownClass}`} /></span>
            {/* hidden component to collapse dropdown, don't remove */}
            <span ref={labelRef} onClick={()=>this.toggleExpand(false)}></span>
            <div className="playlist-search-dropdown" style={{display: expanded ? 'block' : 'none'}}>
                {...children}
            </div>
        </div>
    }
}

const CheckboxGroup = ({ fields, options }) => {
    const toggle = (event, option) => {
        if (event.target.checked) fields.push(option);
        else fields.remove(option);
      };
      
      return (
        <div>
          {options.map(option => (
            <div key={option} className="playlist-dropdown-item">
              <input
                type="checkbox"
                value={option}
                checked={fields.value && fields.value.includes(option)}              
                onClick={event => toggle(event, option)}
              />
              {option}
            </div>
          ))}
        </div>
      );
};

class PlaylistSearchForm extends Component {
    
    constructor(props) {
        super(props);
        // this.onSubmit = this.onSubmit.bind(this); 
    }
    
    buildOptions(key, includeDefault = true) {
        let values = playlistSearchValues[key],
            result = [];
        
        if(includeDefault) {
            result.push({
                value: false,
                label: 'Any'
            });
        }
        
        for(let value of values){
            result.push({
                value: String(value).replace(' ', '_'),
                label: `${value}${['atspd', 'current_tracks'].includes(key)?'+':''}`
            });
        };
        return result;
    }
    
    onSubmit = (values) => {
        this.playlistTypeDropdown.click();
        return this.props.onSubmit(values);
    }
    
    render() {
        let values = {};
        for(let value of this.props.values) {
                values[value.id] = value.value;
        }
        return (
            <Form onSubmit={this.onSubmit} initialValues={values}
                mutators={{
                    ...arrayMutators
                  }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form className="" onSubmit={handleSubmit}>
                        <div className="search-fields-holder">
                            <div className="search-input">
                                <div className="form-group">
                                    <label className="text-label">Playlist Name</label>
                                    <div className="playlist-search-holder">
                                        <span title="Search" className="search-icon" dangerouslySetInnerHTML={{__html: searchSvg}}></span>
                                        <Field name="playlist_name" id="playlist_name" component="input" type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            {/*
                            <div className="search-input">
                                <div className="form-group form-group-tooltip">
                                    <label className="text-label">
                                        Tags
                                        <Tooltip 
                                            position="bottom" 
                                            message={`To filter the Playlist Library table using multiple tags, enter each tag separating them using a comma to get results on either tag, or with a plus sign to get results on both tags`} 
                                            tooltipClass="toolbar-title-tooltip" />
                                    </label>
                                    <div className="playlist-search-holder">
                                        <span title="Search" className="search-icon" dangerouslySetInnerHTML={{__html: searchSvg}}></span>
                                        <Field name="tags" id="tags" component="input" type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            */}
                            <div className="playlist-select-holder">
                                <div className="form-group form-group-tooltip">
                                    <label className="text-label">
                                        DPB
                                        <Tooltip 
                                            position="bottom" 
                                            message={`Daily Performance benchmark: value calculated across all of Ei’s clients as an average of daily streams generated by all known tracks to have been included in this playlist.`} 
                                            tooltipClass="toolbar-title-tooltip" />
                                    </label>
                                    <Field name="atspd" component={Select} className="custom-select" options={this.buildOptions('atspd')} />
                                </div>
                            </div>
                            <div className="playlist-select-holder">    
                                <div className="form-group">
                                    <label className="text-label">Tracks</label>
                                    <Field name="current_tracks" component={Select} className="custom-select" options={this.buildOptions('current_tracks')} />
                                </div>
                            </div>  
                            <div className="playlist-select-holder">    
                                <div className="form-group">
                                    <label className="text-label">Source</label>
                                    <Field name="playlist_source" component={Select} className="custom-select" options={this.buildOptions('playlist_source')} />
                                </div>
                            </div>
                            <div className="playlist-select-holder playlist-select-holder-type">    
                                <div className="form-group">
                                    <label className="text-label">Playlist Type</label>
                                    {/*
                                    <Field name="playlist_types" component={Select} className="custom-select" options={this.buildOptions('playlist_types', false)} isMulti={true} />
                                    */}
                                    <PlaylistTypeDropdown values={values['playlist_types']} labelRef={el => this.playlistTypeDropdown = el}>   
                                        <FieldArray
                                            name="playlist_types"
                                            component={CheckboxGroup}
                                            options={["Algorithmic", "Editorial", "User Generated"] }
                                         />
                                     </PlaylistTypeDropdown>
                                </div>
                            </div>       
                            {/*
                            <div className="playlist-select-holder">    
                                <div className="form-group">
                                    <label className="text-label">Age</label>
                                    <Field name="genders" component={Select} className="custom-select" options={this.buildOptions('ages')} />
                                </div>
                            </div>
                            <div className="playlist-select-holder">
                                <div className="form-group">
                                    <label className="text-label">Gender</label>
                                    <Field name="ages" component={Select} className="custom-select" options={this.buildOptions('genders')} />
                                </div>
                            </div>
                            */}
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <button type="submit" className="default-btn pull-right">Update</button>
                            </div>
                        </div>
                    </form>
                )}
            />
        );
    }
}

export default PlaylistSearchForm