import React from 'react';
import { find } from 'lodash';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import OverviewPDF from '../../common/PDF/Overview';
import DateRange from '../../common/PDF/DateRange';
import { splitByThousands, addPercent } from '../../../helpers/NumberFormatter';
import territories from '../../widgets/filter/enums/territories';
import countriesByAbbreviation from '../../common/CountriesByAbbreviation';


const renderLimitedString = (items, limit = 3) => {
    const overLimit = items.length - limit;
    let title = items.slice(0, limit).join(', ');
    
    if(overLimit > 0)
        title += ` + ${overLimit} more`;

    return title;
}

const renderExtras = (filter) => {
    if(!filter || !filter.dateStart)
        return null;
    let extras = [];
    
    let totalTerritories = 0;
    for(let continent of Object.keys(territories)) {
        totalTerritories += territories[continent].length;
    }
        
    if(filter.territories && filter.territories.length && filter.territories.length != totalTerritories) {
        let territoriesTitles = [];
        for(let territoryCode of filter.territories) {
            const territory = find(countriesByAbbreviation, country=>country.abbreviation == territoryCode);
            if(territory)
                territoriesTitles.push(territory.country)
        }
        extras.push(`Territories: ${renderLimitedString(territoriesTitles)}`);
    }

    if(filter.vendors && filter.vendors.length)
        extras.push(`Platforms: ${renderLimitedString(filter.vendors.split(','))}`);
    
    
    if(filter.releaseStart || filter.releaseEnd) {
        extras.push(`Release Dates: ${filter.releaseStart || 'All Available'} - ${filter.releaseEnd || 'Today'}`);
    }

    
    return extras;
}


export default ({data}) => {
  const extras = renderExtras(data);
  if(!extras || !extras.length)
      return null;
  
  return <View style={styles.reportTitle}>
      {Object.keys(extras).map(key=><Text key={key} style={styles.dateRangeTitle}>{extras[key]}</Text>)}
  </View>
};