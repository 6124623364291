export const vendorsConstants = {
    GET_VENDOR_STATS_REQUEST: 'GET_VENDOR_STATS_REQUEST',
    GET_VENDOR_STATS_SUCCESS: 'GET_VENDOR_STATS_SUCCESS',
    GET_VENDOR_STATS_FAILURE: 'GET_VENDOR_STATS_FAILURE',
    CHANGE_VENDOR_STATUS_REQUEST: 'CHANGE_VENDOR_STATUS_REQUEST',
    CHANGE_VENDOR_STATUS_SUCCESS: 'CHANGE_VENDOR_STATUS_SUCCESS',
    CHANGE_VENDOR_STATUS_FAILURE: 'CHANGE_VENDOR_STATUS_FAILURE',
    GET_VENDORS_REQUEST: 'GET_VENDORS_REQUEST',
    GET_VENDORS_SUCCESS: 'GET_VENDORS_SUCCESS',
    GET_VENDORS_FAILURE: 'GET_VENDORS_FAILURE'    
}