import React from 'react';
import { map, sum, capitalize } from 'lodash';
import AbsoluteValue from '../../AbsoluteValue';
import { splitByThousands, percentageValue } from '../../../../helpers/NumberFormatter';

export const transactionTypeSettings = {
    settingsForTable
};


function settingsForTable(){
    let columns = [

       {
           id: 'source',
           className: 'table-text-left', 
           headerClassName: 'table-text-left',
           Header: 'Source',
           accessor: 'source',
           aggregate: (values, rows) => values[0],
           Aggregated: row => <span>{capitalize(row.value)}</span>,
           Cell: props => <span>{capitalize(props.value)}</span>,
	       Expander: ({ isExpanded, row, ...rest }) => {
	    	   if(row.source == 'Total')
	    		   return null;
	         return <div className={`rt-expander ${isExpanded ? '-open':''}`} />
	       },

           // width: 120
           
       },
       {
           id: 'classification',
           className: 'table-text-left', 
           headerClassName: 'table-text-left',
           Header: 'Classification',
           accessor: 'classification',
           Aggregated: row => {
               return <span>Total</span>
           }
           // aggregate: (values, rows) => values.length
           // width: 120
       },       
                   
       {
           id: 'transaction_type',
           className: 'table-text-left', 
           headerClassName: 'table-text-left',
           Header: 'Transaction Type',
           accessor: 'transaction_type',
           // width: 120
       },
       {
           id: 'total_income',
           className: 'table-text-right', 
           headerClassName: 'table-text-right',
           Header: 'Income',
           // width: 40,
           accessor: 'total_income',
           Cell: props => <span>{splitByThousands(props.value, '$')}</span>,
           aggregate: (values, rows) => sum(values),
           Aggregated: row => {
        	   const total = row.row.source == 'Total';
               const val = Number.parseFloat(row.value).toFixed(2);
               return <span className={total ? 'total-column' : ''}>{splitByThousands(val, '$')}</span>
           }
       },
       {
           id: 'total_quantity',
           className: 'table-text-right', 
           headerClassName: 'table-text-right',
           Header: 'Quantity',
           // width: 40,
           accessor: 'total_quantity',
           Cell: props => <span>{splitByThousands(props.value)}</span>,
           aggregate: (values, rows) => sum(values),
           Aggregated: row => {
        	   const total = row.row.source == 'Total';
               const val = Number.parseFloat(row.value).toFixed(0);
               return <span className={total ? 'total-column' : ''}>{splitByThousands(row.value)}</span>
           }           
       },
       {
              id: 'share',
              className: 'table-text-right', 
              headerClassName: 'table-text-right',
              Header: 'Share',
              // width: 40,
              accessor: 'share',
              Cell: props => <span>{Number.parseFloat(props.value).toFixed(2)}%</span>,
              aggregate: (values, rows) => sum(values),
              Aggregated: row => {
                  const total = row.row.source == 'Total';
                  return total ? <span className={'total-column'}>100%</span> : <span>{Number.parseFloat(row.value).toFixed(2)}%</span>
              }
          }

    ];
    return columns;
}