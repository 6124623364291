import React from 'react';
import config from '../../config/config';

class WhiteLabelCss extends React.Component {

    injectCSS(silo) {
        if(silo===false)
            return;
        console.log('loading '+silo);
        var link = document.createElement('link');
        link.href = `${config.apis.assets}/css/${silo}_custom.css`;
        link.type = 'text/css';
        link.rel = 'stylesheet';

        document.getElementsByTagName('head')[0].appendChild(link);
    }

    componentDidMount() {
        this.injectCSS(this.props.silo);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.silo !== nextProps.silo) {
            this.injectCSS(nextProps.silo);
        }
    }

    render() {
        return null;
    }
}

export default WhiteLabelCss
