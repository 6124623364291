import React from 'react';
import { connect } from 'react-redux';
import { uniq, map } from 'lodash';
import alpha from 'color-alpha';

import Box from '../../common/Box';
import Spinner from '../../common/Spinner';
import Tooltip from "../../common/Tooltip";
import { colors } from '../../widgets/charts/ChartDefaults';

import { usersActions } from '../../../data/actions/users';

class AlphabeticalList extends React.Component{

    constructor(props) {
        super(props);
        
        const { selected = [] } = props,
            allSelected = (selected.length == 1 && selected[0]=='*');
        
        this.state = {
            currentLetter: null,
            expanded: true
        }
        
        this.setLetter = this.setLetter.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this); 
    }
    
    componentDidMount() {
    }
    
    setLetter(currentLetter) {
        this.setState({currentLetter})
    }
    
    toggleExpand(){
        // TODO: call reset when collapsed, as this assumes all privileges
        this.setState({
            expanded: !this.state.expanded
        })
    }
    
    renderToolbar() {
        const caret = this.state.expanded ? 'fa-caret-down' : 'fa-caret-up';
        return <div className="user-action-icons">
            <div className="ibox-icon-holder">
                <a key="toggle" onClick={this.toggleExpand} className={`fa ${caret}`} />
            </div>
            {this.props.remove !== undefined && <div className="ibox-icon-holder"><a onClick={this.props.remove}><i className="fas fa-trash-alt"></i></a></div>}
        </div>

    }

    render(){
        let { items, title, loading, selected = [], select, remove, colorIndex = 0, deselectAll, silo, clientAdmin = false } =  this.props, 
            { currentLetter, expanded } = this.state,
            letters = [],
            selectedLetters = [],
            itemIDs = [];
            
            
        if(items && items.length) {    
            items = items.filter(item=>{
                if(item.silos)
                    return item.silos.includes(silo);
                else
                    return true;
            });
            itemIDs = items.map(item=>String(item.id));
        }
        
        if(selected && selected.length) {
            selected = selected.filter(selectedItem=>itemIDs.includes(selectedItem));
        }
            
        const totalItems = (items && items.length ) ? items.length : 0,
            allSelected = (clientAdmin) || (selected.length == 1 && selected[0]=='*') || (totalItems > 0 && selected.length == totalItems),
            noneSelected = (!clientAdmin && !selected.length) || (totalItems > 0 && selected.length == totalItems);
        
        let selectedItemsLabel;
        if(allSelected)
            selectedItemsLabel = 'All';
        else if(noneSelected)
            selectedItemsLabel = 'None';
        else
            selectedItemsLabel = selected.length;
            
        if(items && items.length) {
            

            for(let item of items) {
                letters.push(item.letter);
                item.isSelected = selected.includes(String(item.id));
                if(item.isSelected)
                    selectedLetters.push(item.letter);
            }
            if(!currentLetter) 
                currentLetter = letters[0];

            items = items.filter(item=>item.letter == currentLetter);
        }
        else
            items = [];
        
        const showLetters = letters.length > 1;
        
        const deselectAllLink = deselectAll !== undefined ? <a onClick={deselectAll}>(Deselect All)</a> : null;
        
        const selectedItems = items.map(item=>{
            return <li  className={item.isSelected ? 'active':''} onClick={()=>select(item)}>{item.name}</li>
        });
        
        letters = uniq(letters).sort();
        selectedLetters = uniq(selectedLetters); 

        
        const color = alpha(colors[colorIndex % colors.length], 0.1);
        
        /*
         *             <Tooltip
                position="top"
                message={`As we cannot create a user with 0 imprints selected, the Deselect All button will reverse the selection to All Selected.`}
                tooltipClass="toolbar-title-tooltip"
              />

         */
        
        return <Box className="show-user-box" title={title} spinnerEnabled={loading} toolbar={this.renderToolbar()} style={{backgroundColor: color}}>
        
        {expanded && <div className={`alphabetical-list-wrapper ${clientAdmin ? 'disabled' : ''}`}>
            <span className="show-user-title">{totalItems} total / {selectedItemsLabel} assigned {deselectAllLink}</span>
            {showLetters && <ul className="alphabetical-list">
                {letters.map(letter=>{
                    let classNames = [];
                    if(currentLetter == letter)
                        classNames.push('active');
                    if(selectedLetters.includes(letter))
                        classNames.push('selected');
                    return <li key={letter} onClick={()=>this.setLetter(letter)} className={classNames.join(' ')}>{letter}</li>
                })};
            </ul>}
            <ul className="alphabetical-list-items">
                {selectedItems}
            </ul>
            {!items.length && <p>No available imprints for this silo code</p>}
        </div>}
        </Box>            
            
        
    }
}


export default AlphabeticalList