import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';

import FavoriteEntities from '../../components/pages/user/FavoriteEntities';

class UserFavorites extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return <div className="wrapper wrapper-content light-blue-bg inner-page ">
            <FavoriteEntities />
            <Helmet>
                <title>User Favorites</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}

export default connect(mapStateToProps)(UserFavorites)