import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize, uniqBy } from 'lodash';
import ReactTable from 'react-table';
import Box from '../Box';
import ReactSelect from '../../common/ReactSelect';
import CSV from '../../../helpers/CSVExport';
import ConfirmClick from '../ConfirmClick';
import { transactionTypeSettings } from './TransactionTypes/Settings';
import { statsActions } from '../../../data/actions/stats';
import StackedBarChart from '../../widgets/charts/types/StackedBarChart';
import LineChart from '../../widgets/charts/types/LineChart';

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');


class TransactionTypes extends React.Component {
    
    nullElement = {value: null, label: 'All'}
    
    constructor(props) {
        super(props);
        this.state = {
            mode: 'table'
        }
        this.exportToCsv = this.exportToCsv.bind(this);
        this.getTimeseries =  this.getTimeseries.bind(this);
    }
    
    exportToCsv(){
        const { entity, id } = this.props,
            { transactions, transactionsLoading } = this.props.stats,
            transactionData = transactions[id] || [];
            
        const filename = CSV.CSVHeader('transaction_types', '', this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(transactionData, {filename}, 'transaction_types');
    }
    
    getTimeseries() {
        this.props.getTransactionsTimeseries(this.props.entity, this.props.id);
    }

    renderToolbar(data){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <a key='table' title="Table" className={`chart-icon ${this.state.mode == 'table' ? 'active' : ''}`} 
            onClick={()=>this.setChartMode('table')}
            dangerouslySetInnerHTML={{__html: tableSvg}}>
        </a>
        <a key='line' title="Line Chart" className={`chart-icon ${this.state.mode == 'line' ? 'active' : ''}`} 
            onClick={()=>{this.setChartMode('line'); this.getTimeseries();}}
            dangerouslySetInnerHTML={{__html: lineSvg}}>
        </a>
        <a key='bar' title="Bar Chart" className={`chart-icon ${this.state.mode == 'bar' ? 'active' : ''}`} 
            onClick={()=>{this.setChartMode('bar'); this.getTimeseries();}}
            dangerouslySetInnerHTML={{__html: barSvg}}>
        </a>
        <div className="ibox-actions">
            <ConfirmClick confirmAction={this.exportToCsv} title="Export CSV" confirmClass="" confirmLabel={<span key="download" className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />} confirmPrompt="These reports are for analytical purposes only and should not be used to pay artist royalties"></ConfirmClick>
            
        </div>
        </div>)

        return toolbar;
    }
            
    setChartMode(mode){
        this.setState({mode}, ()=>{
        });
    }

    componentDidMount() {
        this.props.getTransactions(this.props.entity, this.props.id);
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getTransactions(this.props.entity, this.props.id);
                if(this.state.mode == 'line' || this.state.mode == 'bar')
                    this.getTimeseries();
            }                
        }
    }
    
    render (){  
        const { entity, id } = this.props,
            { transactions, transactionsLoading, transactionsTimeseriesLoading } = this.props.stats,
            entityTitle = entity == 'imprints' ? 'Labels' : capitalize(entity), 
            widgetTitle = `${entityTitle} Transaction Types`,
            transactionData = transactions[id] || [];
        
        const rowsCount = uniqBy(transactionData, 'source').length;
        
        return <Box title={widgetTitle} toolbar={this.renderToolbar()} spinnerEnabled={(transactionsLoading||transactionsTimeseriesLoading)} className="ibox-container transaction-box">
        {rowsCount > 0 && <React.Fragment>
            {this.state.mode=='table' && <ReactTable 
                className="transaction-table"
                data={transactionData} 
                columns={transactionTypeSettings.settingsForTable()}
                defaultPageSize={rowsCount}
                sorted={[{id: 'total_income', desc: true}]}
                pivotBy={['source', 'classification']}
            	showPagination={false}        	
            />}
            {this.state.mode=='line' && <div className="chart-block">
                <LineChart data={this.props.stats.transactionsTimeseries[id]} />                          
            </div>}
            {this.state.mode=='bar' && <div className="chart-block">
                <StackedBarChart data={this.props.stats.transactionsTimeseries[id]} />                          
            </div>}
        </React.Fragment>}
        </Box>;
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
    } 
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getTransactions: (entity, id) => {
            dispatch(statsActions.getTransactions(entity, id))
        },
        getTransactionsTimeseries: (entity, id) => {
            dispatch(statsActions.getTransactionsTimeseries(entity, id))
        } 
        
    } 
}


export default connect(mapStateToProps, mapDispatchToProps)(TransactionTypes);