import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';
import objectToFormData from 'object-to-formdata';
import {searchService} from './search';

export const milestonesService = {
    getMilestones,
    getEntityMilestones,
    getMilestoneTypes,
    addMilestone,
    editMilestone,
    deleteMilestone,
    linkMilestone,
    unlinkMilestone
};

function getMilestones(campaign){
    return config.live ? getMilestonesLive(campaign) : getMilestonesStatic();
}

function getMilestonesLive(campaign) {
    let headers = AuthHeader(),
        baseUrl = `${config.apis.milestones}/milestones/ei/ei_promotion/${campaign}?include_linked=true`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
}

function getMilestonesStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/milestones.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getEntityMilestones(entity, ids, user){
    return true || config.live ? getEntityMilestonesLive(entity, ids, user) : getEntityMilestonesStatic();
}

function getEntityMilestonesLive(entity, ids, user) {
    
    entity = entity.replace(/.$/, '');
    if(ids instanceof Array) {
        ids = ids.join(',')
    }
    
    let headers = AuthHeader(),
        baseUrl = `${config.apis.milestones}/milestones/milestones_for_entities`,
        qs = {
            entity_ids: ids,
            entity_type: entity,
            user_id: user.id,
            user_silo_codes: user.data_silo_codes
        };
        
    baseUrl += '?' + $.param(qs).replace(/%2C/g,',');
        
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
}

function getEntityMilestonesStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/milestones_for_entities.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getMilestoneTypes() {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.milestones}/milestone_types`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
}

function addMilestone(data) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.milestones}/milestones`;
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function editMilestone(data, id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.milestones}/milestones/${id}`;
    return fetch(baseUrl, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
}

function deleteMilestone(id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.milestones}/milestones/${id}`;
    return fetch(baseUrl, {
        method: 'DELETE',
        headers,
    })
    .then(res=>{   
        return res.json()
    })
}

function linkMilestone(data) {
    let headers = AuthHeader(),
        baseUrl = `${config.apis.milestones}/milestone_entity_link`;
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: objectToFormData(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function unlinkMilestone(id) {
    let headers = AuthHeader(),
        baseUrl = `${config.apis.milestones}/milestone_entity_link/${id}`;
    return fetch(baseUrl, {
        method: 'DELETE',
        headers,
    })
    .then(res=>{   
        return res.json()
    })
    
}