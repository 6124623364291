import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from './Box';


import { imprintsActions } from '../../data/actions/imprints';
import { artistsActions } from '../../data/actions/artists';
import { projectsActions } from '../../data/actions/projects';
import { productsActions } from '../../data/actions/products';
import { tracksActions } from '../../data/actions/tracks';


class SharedEntityCard extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        const {getCard, onResult, id} = this.props;
        if(id.indexOf(',')===-1) {
            getCard(id).then(entity=>{
                if(onResult!==undefined && onResult instanceof Function) {
                    this.props.onResult(entity);
                }
            })
        };
    }
    
    renderDetails(card) {
        return <div className="description">
            <p>{card.name}</p>
            {card.code && <p>{card.code}</p>}
            {card.release_date && <p>First Released: {card.release_date}</p>}
        </div>
        
    }
    
    renderSummary(card) {
        return <span>{card.name} {card.code && <span>{card.code}</span>}</span>;        
    }
        
    render() {
        let { entity, id, flat = false } = this.props;
        
        if(entity == 'track_artists' || entity == 'product_artists')
            entity = 'artists';
        
        if(!this.props[entity].card[id])
            return null;
        const card = this.props[entity].card[id];
        
        return  flat ? this.renderSummary(card) : <div className="entity-card-item">
            <div className="logo" style={{backgroundImage: `url(${card.logo})`}}>
            </div>
            {this.renderSummary(card)}
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        imprints: state.imprints,
        artists: state.artists,
        projects: state.projects,
        products: state.products,
        tracks: state.tracks,
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getCard: (id) => {
            switch(ownProps.entity) {
                case 'imprints':
                    return dispatch(imprintsActions.getCatalogCard(id));
                    break;
                case 'track_artists':
                case 'artists':
                    return dispatch(artistsActions.getCard(id));
                    break;
                case 'projects':
                    return dispatch(projectsActions.getCatalogCard(id))
                    break;                    
                case 'products':
                    return dispatch(productsActions.getCard(id))
                    break;
                case 'tracks':
                    return dispatch(tracksActions.getCard(id))
                    break;
                    
            }
        }
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedEntityCard)