import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import Spinner from '../../components/common/Spinner';
import Datepicker from '../../components/common/Datepicker';
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { importsActions } from '../../data/actions/imports';
import { statementPeriodsSettings } from '../../components/pages/imports/statementPeriods/Settings';
import ImportData from '../../components/pages/imports/ImportData';

var deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');

class StatementPeriods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            period: null,
            showImportData: false
        }
        this.loadData = this.loadData.bind(this);
    }
    
    componentDidMount() {
        this.props.dispatch(importsActions.getStatementPeriods());
    }
    
    loadData(period) {
        const { dispatch } = this.props;
        this.setState({
            period,
            showImportData: true
        }, ()=>{
            return Promise.all([dispatch(importsActions.extractSalesData(period, 'physical')), dispatch(importsActions.extractSalesData(period, 'digital'))])
            .then(()=>Promise.all([dispatch(importsActions.uploadToStorage(period, 'physical')), dispatch(importsActions.uploadToStorage(period, 'digital'))]))
            .then(()=>Promise.all([dispatch(importsActions.importSalesData(period, 'physical')), dispatch(importsActions.importSalesData(period, 'digital'))]));
        })
    }
    
    
    
    render() {
        const { periodsLoading, periods } = this.props.imports,
            rows = Array.isArray(periods) ? periods.length : null;
        
        return <div className="inner-page shared-pages">
            <ImportData show={this.state.showImportData} />
            <div className="container">
                <div className="import-menu import-menu-exceptions">
                    <h5>Statements</h5>
                    <ul>
                        <li><Link to={`/import/statement-periods`}>All Periods</Link></li>
                        <li><Link to={`/import/pending-statements`}>Pending Statements</Link></li>
                    </ul>
                    
                </div>
            
                <h2>Statement Periods</h2>
                <div className="ibox">
                    <ReactTable
                        data={periods}
                        loading={periodsLoading}
                        columns={statementPeriodsSettings.settingsForTable(this.loadData)}
                        defaultSorted={[{ id: "period", desc: true }]}
                        pageSize={rows ? Math.min(rows, 10): 10}
                        showPagination={rows > 10}
                        className="simple-table share-table"
                    />
                    
                    {/* {selectedRows.length > 0 && <button className="default-btn default-btn--small default-btn--light delete-selected" onClick={this.deleteSelectedPages}>Delete Selected</button>} */}

                </div>
            </div>
            <Helmet>
                <title>Statement Periods</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        imports: state.imports
    } 
}

export default connect(mapStateToProps)(StatementPeriods)