import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { Route, withRouter } from 'react-router';
import SearchWidget from '../../components/pages/home/SearchWidget';
import TrackSelector from '../../components/pages/trackComparison/TrackSelector';
import TrackComparisonDetails from './Details';
import Tooltip from '../../components/common/Tooltip';
import Filter from '../../components/pages/trackComparison/Filter';
import ReactSelect from '../../components/common/ReactSelect';
import { tracksActions } from '../../data/actions/tracks';

class TrackComparisonSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tracks: [{}, {}],
            weeks: 1,
            territories: '',
            vendors: ''
        };
        this.compare = this.compare.bind(this);
        this.comparisonReady = this.comparisonReady.bind(this); 
        this.setTrack = this.setTrack.bind(this);
        this.setWeek = this.setWeek.bind(this);
        this.filterChange = this.filterChange.bind(this);
    }
    
    componentDidMount() {
        const { match, dispatch } = this.props;
        if(match.params.ids) {
            dispatch(tracksActions.getTracksReleaseDates(match.params.ids))
            .then(tracks => {
                this.setState({
                    tracks,
                    weeks: match.params.weeks
                });
            });
        }
        
    }
    
    compare() {
        const { tracks, weeks } = this.state;
        if(!this.comparisonReady())
            return;
        const codes = tracks.reduce((ids, track, index, tracks)=>ids += track.code + (index < tracks.length - 1 ? ',' : ''), '') 

        this.props.history.push(`/track-comparison/${codes}/${weeks}`);
    }
    
    filterChange(filter) {
        let { territories, vendors } = filter;
        this.setState({
            territories: territories.join(','),
            vendors: vendors.join(',')
        });
    }
    
    setTrack(index, newTrack) {
        const { user } = this.props;
        
        let tracks = this.state.tracks.map(track => ({...track}));

        if(new Date(newTrack.first_released) < new Date(user.user.first_data_date)) {
            return alert("Unfortunately stream data for this track is not available");
        }
        
        for(let track of tracks) {
            if(track.code == newTrack.code)
                return alert("Please choose a different track for comparison");
        }
        tracks[index] = newTrack;
        this.setState({
            tracks
        })
    }

    setWeek(week) {
        this.setState({
            weeks: week.value
        })
    }
    
    comparisonReady() {
        const { tracks } = this.state;
        return tracks.reduce((value, track)=> (value = track.code !== undefined), true);
    }
    
    render() {
        const { tracks, weeks, territories, vendors} = this.state;
        const weeksOptions = [...Array(7).keys()].map( i => {i=i+1; return {label: i, value: i}}),
            weeksValue = weeksOptions[weeks - 1],
            comparisonReady = this.comparisonReady(), 
            compareButtonClass = comparisonReady ? 'btn-active' : 'disabled'; 
        
        return <div className="wrapper wrapper-content light-blue-bg inner-page track-comparison">
            <h2 className="big-title audience">
                <span>Track Comparison</span>
                <Tooltip position="bottom" message="Todo." tooltipClass="toolbar-title-tooltip release"/>
            </h2>
            <div className="ibox-content section-header track-selector">
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <span>Track 1:</span>
                        <TrackSelector index={0} value={tracks[0]} setResult={(track)=>this.setTrack(0, track)} /> 
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <span>Track 2:</span>
                        <TrackSelector index={1} value={tracks[1]} setResult={(track)=>this.setTrack(1, track)} />
                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-8">
                        <span>Weeks:</span>
                        <ReactSelect options={weeksOptions} onChange={this.setWeek} value={weeksValue} />    
                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-12">
                        <button onClick={this.compare} className={`btn ${compareButtonClass} btn-compare`}>Compare</button>    
                    </div>
                    
                </div>
                <Filter onChange={this.filterChange} />
            </div>
            <Route path="/track-comparison/:ids/:weeks" render={(props)=><TrackComparisonDetails territories={territories} vendors={vendors} {...props} />}></Route>
            <Helmet>
                <title>Track Comparison</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}

export default withRouter(connect(mapStateToProps)(TrackComparisonSearch))