import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router';
import parser from 'url-parse';
import AuthHeader from '../../../helpers/AuthHeader';
import { userActions } from '../../../data/actions/user'
import { userService } from '../../../data/services/user';
import { sharedPagesActions } from '../../../data/actions/shared_pages';
import config from '../../../config/config';
import { capitalize } from 'lodash';

import TicketForm from '../../forms/ticket'

class ChatWidget extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showSuccess: false,
            successMessage: '',
            slug: '',
            file: null,
        }
        
        this.share = this.share.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        //this.share();
    }

    onFileChange(e) {
        const file = e.target.files[0];
        this.setState({ file });
    }

    toggleModal(e) {
        var element = $(e.target);
        var modalBtn = element.parents().find('.chat-widget-btn');
        var modalContainer = modalBtn.parent();
        modalContainer.toggleClass('active');

        this.setState({
            showSuccess: false
        })
    }

    onSubmit(values){     
        const { share: sharePage } = values;   
        this.share(sharePage).then(({slug}) => {
            let formData = new FormData();
            
            for(let key of Object.keys(values)) {
                if (key == 'share' && slug) {
                    let url = `${config.apis.shared}/share/${slug}`;
                    formData.append("page_url", url)
                } else if (key == 'category') {
                    let categoryValue = values.category.split('_').map(capitalize).join(' ');
                    formData.append("category", categoryValue)
                } else {
                    formData.append(key, values[key]);
                }
            }
            
            if (this.state.file) {
                formData.append('file', this.state.file)
            }
            
            this.props.dispatch(userActions.sendTicket(formData)).then(() => {
                let successMessage;
                switch(values.category) {
                    case "platform_improvement":
                        successMessage = "We appreciate your input and value your ideas. While we strive to consider all suggestions in our development process, please understand that we may not be able to implement every suggestion. Your feedback helps us improve and we are grateful for your contribution.";
                        break;
                    case "platform_issue":
                    case "data_discrepancy":
                    case "other":
                    default:
                        successMessage = "Thanks for getting in touch, the team will reach out to you shortly.";
                }
            
                this.setState({
                    showSuccess: true,
                    file: null,
                    successMessage
                })
            });
        })
        

    }

    parseUrl(url) {
        let path = new URL(url).pathname;
        let parts = path.split('/').filter(part => part !== "");
        let entity = "";
        let id = "";
    
        if (parts.length > 0) {
            entity = parts[0];
            if (parts.length > 2) {
                id = parts[1];
            }
        }
    
        return {
            entity: entity,
            id: id
        };
    }

    share(sharePage = true) {
        if(!sharePage)
            return Promise.resolve({slug: null});
            
        const url = window.location.href;
        const result = this.parseUrl(url);
        let id;
        let entity;
        let entityResult = result.entity;
        let moduleText;

        switch (entityResult) {
            case "main": 
                entity = "";
                id = "";
                moduleText = "home";
                break;
            default: 
                entity = result.entity
                id = result.id;
                moduleText = "details";
        }
        
        const expires = moment().add(expires ? 1 : 120, 'month').format('YYYY-MM-DD');

        return this.props.dispatch(sharedPagesActions.share(entity, id, moduleText, null, 'shared page for bug report', expires));
        /*
        .then(result => this.setState({
            slug: result.slug
        }));
        */
    }
    
    render() {
        const onSubmit = this.props.onSubmit;
        const { showSuccess, successMessage } = this.state;
        return (
            <div className="chat-widget-container">
                <div className="chat-widget-btn" onClick={this.toggleModal}>
                    <span className="chat-btn-icon">
                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="open-icon"><path fill="currentColor" d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"></path></svg>
                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="close-icon"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                    </span>
                </div>
                <div className="chat-widget-modal">
                    { showSuccess ? 
                        <div className="chat-widget-success-message">
                            <div className="chat-widget-img">
                                <svg width="512" height="512" x="0" y="0" viewBox="0 0 20 20"><g><path d="M6.4 7 5 8.4l5 5 10-10L18.6 2 10 10.6z" fill="#000000" class=""></path><path d="M9 17c-3.9 0-7-3.1-7-7s3.1-7 7-7c1.2 0 2.4.3 3.5.9l1-1.7C12.1 1.4 10.6 1 9 1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9h-2c0 3.9-3.1 7-7 7z" fill="#000000"></path></g></svg>
                            </div>
                            {successMessage}</div> : 
                        <React.Fragment>
                            <div className="chat-modal-head">
                                <p>Please fill out the form below to create the issue.</p>
                            </div>
                            <TicketForm onSubmit={this.onSubmit} onFileChange={this.onFileChange} />
                        </React.Fragment>
                    }
                    
                </div>
            </div>    
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        sharedPages: state.sharedPages,
    } 
}

export default connect(mapStateToProps)(ChatWidget);