import { map } from 'lodash';

export default {
    pickFields,
    filterFields,
    getInitialFilter
}

const generalFieldID = 'general';

function pickFields(fields) {
    let flatFields = [];
    for(let field of fields) {
        if(field.id==generalFieldID) {
            
            flatFields = flatFields.concat(field.columns);       
        }
        else {
            flatFields.push(field);
        }
    }
    return flatFields
    
}

function filterFields(columns, enabledColumns) {
    return map(columns, (column)=>{
        if(enabledColumns !== undefined) {
            if(column.id==generalFieldID) {
                filterFields(column.columns, enabledColumns);
            }
            else {
                let show = enabledColumns.includes(column.id);
                if(column.columns!==undefined) {
                    column.columns.map(column=>column.show = (show && (column.expanded || column.accessor == 'total')));
                }
                if(column.hasOwnProperty('expanded'))
                    show = show && column.expanded;
                
                column.show = show;
            }
        }
        return column;
    });
}

function getInitialFilter(fields) {
    let filter = []; 
    
    fields.filter(item=>(item.show===undefined || item.show===true));
    for(let field of fields) {
        if(field.id==generalFieldID) {
            filter = filter.concat(field.columns.map(field=>field.id));
        }
        else
            filter.push(field.id)
    }
    return filter;
}