import React from 'react';
import moment from 'moment';
import ConfirmClick from '../../../common/ConfirmClick';

export const milestonesSettings = {
    settingsForTable
};


function settingsForTable(setCurrentMilestone, deleteMilestone){
    
    
    let columns = [
       {
           id: 'milestone_type',
           className: 'milestone-type',
           headerClassName: 'milestone-type',
           Header: 'Type',
           accessor: 'milestone_type',
           // width: 190
       },
       {
           id: 'reference',
           className: 'milestone-reference',
           headerClassName: 'milestone-reference',
           Header: 'Reference',
           accessor: 'reference',
           // width: 220
       },
       
       {
           id: 'notes',
           className: 'milestone-reference',
           headerClassName: 'milestone-reference',
           Header: 'Notes',
           accessor: 'notes',
           // width: 220
       },
       
       {
           id: 'occurs_at',
           className: '',
           headerClassName: '',
           Header: 'Occurs At',
           accessor: 'occurs_at',
           // width: 150,
           Cell: props => {
               return <div>{moment(props.value).format('YYYY-MM-DD')}</div>
           }
           
       },
       
       {
           id: 'ends_at',
           className: '',
           headerClassName: '',
           Header: 'Ends At',
           accessor: 'ends_at',
           // width: 150,
           Cell: props => {
               const date = moment(props.value);
               return <div>{date.isValid() ? date.format('YYYY-MM-DD') : '-'}</div>
           }
           
       },
       {
           id: 'actions',
           className: 'milestone-actions',
           headerClassName: 'no-sort milestone-actions',
           Header: '',
           // width: 150,
           Cell: props => <div className="buttons-holder buttons-holder--milestones">
            <a onClick={()=>setCurrentMilestone(props.original, 'showAddChannelModal')} className="btn add-to-filter"><i class="fas fa-plus"></i></a>
            <a onClick={()=>setCurrentMilestone(props.original, 'showEditMilestoneModal')} className="btn add-to-filter"><i className="far fa-edit"></i></a>
            <ConfirmClick confirmAction={()=>deleteMilestone(props.original.id)} confirmClass="btn add-to-filter" confirmLabel={<i class="fas fa-trash-alt"></i>}></ConfirmClick>
           </div> 
           
       },
       { accessor: 'exception', expander: true }      
       
    ];
    return columns;
}