import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PercentageValue from '../../common/PercentageValue';
import AbsoluteValue from '../../common/AbsoluteValue';
import Tooltip from '../../common/Tooltip';
import EntityCard from '../../common/SharedEntityCard';
import SharedPageLink from './SharedPageLink';
import ConfirmClick from '../../common/ConfirmClick';

export const sharedPagesSettings = {
    settingsForTable
};

function renderCaption(row){
    switch(row.module) {
        case 'home':
            return <span>Overview Page</span>;
        case 'track_comparison':
            const [id0, id1] = row.entity_id.split(',');
            return <span>
                <EntityCard entity={row.entity} id={id0} flat={true} /> <br />
                <EntityCard entity={row.entity} id={id1} flat={true} />
            </span>
        default:
            return <EntityCard entity={row.entity} id={row.entity_id} flat={true} />
    }
} 
function settingsForTable(deleteSharedPage){

    let columns = [
               {
                   id: 'title',
                   Header: 'Title',
                   accessor: 'title',
               },                   
            {
                id: 'entity',
                Header: 'Entity',
                accessor: 'entity_id',
                sortable: false,
                Cell: props => renderCaption(props.original)
            },
            
            {
                id: 'created_at',
                Header: 'Created At',
                accessor: 'created_at',
                Cell: props => moment(props.value).format('YYYY-MM-DD'),
            },
            {
                id: 'summary',
                Header: 'Summary',
                sortable: false,
                Cell: props => {
                    return <div>
                    TBD: 
                    </div>
                }
            },

            {
                id: 'links',
                Header: 'Actions',
                headerClassName: 'no-sort',
                accessor: 'slug',
                sortable: false,
                Cell: props => <div className="action-icons">
                    <SharedPageLink slug={props.value} justCopy={true} />
                    <ConfirmClick confirmAction={()=>deleteSharedPage(props.original.id)} confirmClass="btn add-to-filter" confirmLabel={<i className="fas fa-trash-alt"></i>} confirmPrompt="Delete shared page?"></ConfirmClick>
                </div>
                // width: 105
            }
            ];
            
    return columns; 
}
