import React from 'react';
import {Helmet} from 'react-helmet';
import Progress from '../common/Progress';
import Footer from '../common/Footer';
import TopHeader from '../common/TopHeader';
import ErrorBox from '../common/ErrorBox';
import WhiteLabelCss from '../common/WhiteLabelCss';
import UserTablesLoader from '../widgets/userTables/UserTablesLoader';
import Filter from '../widgets/filter/Filter';
import ChatWidget from '../widgets/chat/ChatWidget';
import ShareRoutes from '../../config/routes/share';
import LoginButton from '../pages/sharedPages/LoginButton';
import { withRouter } from 'react-router';
import { correctHeight, detectBody } from './Helpers';
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper'

import { connect } from 'react-redux';

class Share extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFilterOpened: false,
            filterDataCount: null,
        }
    } 
    
    toggleFilterOpened = () => {
        this.setState({
            isFilterOpened: !this.state.isFilterOpened
        });        
    }

    getFilterCount = (filterCount) => {
        this.setState({
            filterDataCount: filterCount
        })
    }    
    
    render() {
        const { loggedIn } = this.props.user;
        const route = this.props.location.pathname;
        return (
            <div id="wrapper" className={`${this.props.silo} share-preview-page`}>
                <ErrorBox />
                <Helmet defaultTitle="Enterlytics" titleTemplate="%s - Enterlytics" />
                {loggedIn && <TopHeader readonly={true} >
                    <LoginButton />
                </TopHeader>}

                <div id="page-wrapper" className={`page-wrapper`}>
                        <div className="main-content-wrapper">
                            <ShareRoutes />
                        </div>
                </div>

            </div>

        )
    }

    componentDidMount() {

        // Run correctHeight function on load and resize window event
        $(window).bind("load resize", function() {
            correctHeight();
            detectBody();
        });

        // Correct height of wrapper after metisMenu animation.
        $('.metismenu a').click(() => {
            setTimeout(() => {
                correctHeight();
            }, 300)
        });
    }
}

function mapStateToProps(state) {
    return { 
        user: state.user
    };
}


export default withRouter(connect(mapStateToProps)(Share))
