import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import { isEqual, maxBy, minBy } from 'lodash';

import CSV from '../../../helpers/CSVExport';
import { convertToThousands, splitByThousands } from '../../../helpers/NumberFormatter';
import Box from '../../common/Box';
import PercentageValue from '../../common/PercentageValue';

import BarChart from '../../widgets/charts/types/BarChart';

import { audienceActions } from '../../../data/actions/audience';
import { playlistSettings } from './Playlists/Settings';

const downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class RepeatListeners extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            view: 'table',
        }
        this.setChartView = this.setChartView.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }

    componentDidMount(){
        this.props.getRepeatListeners(this.props.id, this.props.entity);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getRepeatListeners(this.props.id, this.props.entity);
            }
        }
    }

    exportToCsv(){
        const filename = CSV.CSVHeader(`audience_repeat_listeners`, 'listeners', this.props.filter.global, this.props.parentEntityTitle);
        return CSV.CSVExport(this.props.audience.repeatListeners.table, {filename}, 'audience_repeat_listeners');
    }

    renderToolbar(modes){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }



    setChartView(view){
        this.setState({view});
    }
    
    render(){
        const { audience } = this.props;
        const options = {
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    barThickness: 20,
                    stacked: true,
                    "ticks": {
                    callback: function formatAxesLabel(value, index, values){
                        return splitByThousands(value);
                    }
                }
                    
                }],
                yAxes: [{
                    barThickness: 20,
                    stacked: true,
                }]
            },            
            legend: {
                display: false,
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        var index = tooltipItem.index;
                        if(!dataset.labels)
                            return null;
                        
                        return (dataset.labels[index] && dataset.data[index]) ? dataset.labels[index] + ': ' + splitByThousands(dataset.data[index]) : null;
                    }
                }
            },
        }
        
        let dataset = [];
        if(audience.repeatListeners && audience.repeatListeners.dataset) {
            dataset = audience.repeatListeners.dataset;
        }
            
        
        return <Box title="Repeat Listeners" className="" spinnerEnabled={audience.repeatListenersLoading} toolbar={this.renderToolbar()} toolbar={this.renderToolbar()} >
                <div className="row">
                    <div className={`col-xs-12`}>
                        {audience.repeatListeners && <div className="repeat-listeners-chart"><BarChart id="audience_repeat_listeners_line_chart" data={{labels: ['1', '2 - 5', '6 - 10', '11+'].reverse(), datasets: dataset}} options={options} horizontal={true} height="100" /></div>}
                    </div>
                </div>
            </Box>
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getRepeatListeners: (id, entity) => {
            return dispatch(audienceActions.getRepeatListeners(id, entity))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RepeatListeners)
