import React, { Component } from 'react';
import ReactTable from "react-table";
import {Helmet} from 'react-helmet';
import store from '../helpers/store';
import cellHeaderHOC from '../components/common/Datagrid/CellHeaderHOC';

import { connect } from 'react-redux';
import { debounce, find, findIndex, isEqual } from 'lodash';
import { withRouter } from 'react-router';

import Box from '../components/common/Box';
import { PieChart } from '../components/widgets/charts/types';
import FieldSelector from '../components/common/Datagrid/FieldsSelector';
import EntityFilter from '../components/common/EntityFilter';
import EntityFilterSwitcher from '../components/common/EntityFilterSwitcher';
import SectionHeader from '../components/common/Datagrid/SectionHeader';
import FieldsSelectorHelper from '../components/common/Datagrid/FieldsSelectorHelper';
import StreamsStats from '../components/common/Stats/Streams';
import TerritoryStats from '../components/common/Stats/Territory';
import CSV from '../helpers/CSVExport';
import LoadingMessage from '../helpers/LoadingMessage';
import CatalogOverview from '../components/widgets/catalog/CatalogOverview';

import { productsActions } from '../data/actions/products';
import { productsFormatter } from '../data/formatters/products'
import { productsSettings } from '../components/pages/products/Settings'
import { filterActions } from '../data/actions/filter';

var manageSvg = require('!svg-inline-loader!../../public/img/manage.svg');
var pieSvg = require('!svg-inline-loader!../../public/img/pie.svg');
var tableSvg = require('!svg-inline-loader!../../public/img/table.svg');
var worldSvg = require('!svg-inline-loader!../../public/img/world.svg');
var lineSvg = require('!svg-inline-loader!../../public/img/line.svg');
var downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');
const CellHeaderTable = cellHeaderHOC(ReactTable);

class Products extends Component {
    defaultPageSize = 10;

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            pages: -1,
            page: 0,
//            pageSize: 10,
            filtered: '',
            filter: { // will be set from FieldsSelector
                columns: this.getInitialFilter(),
                filtered: [
                   {id: 'pd.title', value: ''},
                   {id: 'imprints', value: this.getPageFilterInitial(props, 'imprints') },
                   {id: 'exclude_imprints', value: this.getPageFilterInitial(props, 'exclude_imprints')},                   
                   {id: 'product_artists', value: this.getPageFilterInitial(props, 'artists')},
                   {id: 'exclude_product_artists', value: this.getPageFilterInitial(props, 'exclude_artists')},
                   {id: 'products', value: this.getPageFilterInitial(props, 'products')},
                   {id: 'exclude_products', value: this.getPageFilterInitial(props, 'exclude_products')}
               ]
            },
            defaultSorted: [{id: 'curr_units', desc: true}],
            view: 'table',
            sources: {"Main": false, "Stream": false, "Video": false, "Radio": true, "Download": true, "Social Music": false}
        }
        this.getPageFilter = this.getPageFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.loadData = this.loadData.bind(this);
        this.setFilterSearch = this.setFilterSearch.bind(this);
        this.setFilterSearchDeb = debounce(this.setFilterSearch, 1000);

        this.addToProductsFilter = this.addToProductsFilter.bind(this);
        this.tableScrollTop = 0;
        this.exportToCsv = this.exportToCsv.bind(this);
        this.expandSource = this.expandSource.bind(this);
    }

    componentDidMount() {
        //this.dispatchLoadData(this.state);
    }

    getInitialFilter() {
        return FieldsSelectorHelper.getInitialFilter(productsSettings.settingsForTable());
    }

    getPageSize() {
        return this.props.filter.global ? this.props.filter.global.limit : this.defa
    }

    loadData(state, instance) {
        this.setState({
            sorted: state.sorted
        }, ()=>this.dispatchLoadData(state));
    }

    dispatchLoadData(state){
        const { dispatch } = this.props;
        dispatch(productsActions.getProducts({
            page: state.page,
//            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
        }));
    }

    getPageFilter(props, entity){
        let ids = [];
        if(props.filter[entity])
            ids = props.filter[entity].map(item=>item.id);
        return ids;
    }
    
    getPageFilterInitial(props, entity){
        const filter = store.get(entity);
        
        let ids = [];
        
        if(filter && Array.isArray(filter.body.value))
            ids = filter.body.value.map(item=>item.id);
        return ids;
    }


    checkFilterIDs(nextProps, filterKey, valueKey) {
        if(nextProps.filter[filterKey]) {
            const newFilter = this.getPageFilter(nextProps, filterKey);
            const existingFilter = find(this.state.filter.filtered, {id:valueKey}).value;
            if(!isEqual(newFilter, existingFilter)){
                this.setFilterSearch(newFilter, valueKey);
            }
        }
    }
    
    componentWillReceiveProps(nextProps){
        const filterKeys = [
            {filter: 'imprints', value: 'imprints'},
            {filter: 'artists', value: 'product_artists'},
            {filter: 'products', value: 'products'}
        ];
        
        if(nextProps.products && nextProps.products.items){
            this.setState({
                data: nextProps.products.items,
                pages: Math.ceil(nextProps.products.total / this.getPageSize()),
                loaded: true
            })
        }
        if(nextProps.filter){

            if(nextProps.filter.global){
                if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                    let state = Object.assign({}, this.state);
                    state.filtered = state.filter.filtered;
                    this.dispatchLoadData(state);
                }

                /*
                this.setState({
                    pageSize: nextProps.filter.global.limit
                });
                */
            }
            
            for(let filterKey of filterKeys) {
                this.checkFilterIDs(nextProps, filterKey.filter, filterKey.value);
                this.checkFilterIDs(nextProps, `exclude_${filterKey.filter}`, `exclude_${filterKey.value}`);
            }            
        }

    }

    setFilter(filter){
        this.setState({
            filter
        })
    }

    setFilterSearch(value, id){
        let filtered = this.state.filter.filtered.slice(0),
            valueIndex = findIndex(filtered, {id});

        if(valueIndex!==-1){
            filtered[valueIndex] = {id, value};
        }
        else {
            filtered.push({id, value})
        }

        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filtered
            }
        });
    }


    setSearch(e){
        const value = e.target.value;
        this.setState({
            filtered: value
        })
        this.setFilterSearchDeb(value, 'pd.title')
    }

    renderToolbar(){
        let toolbar = [
            <a key="table" title="Table" className={"chart-icon " + (this.state.view == 'table' ? 'active' : '')} dangerouslySetInnerHTML={{__html: tableSvg}} onClick={()=>this.setView('table')}>
            </a>,
            <a key="chart" title="Pie Chart" className={"chart-icon " + (this.state.view == 'chart' ? 'active' : '')} dangerouslySetInnerHTML={{__html: pieSvg}} onClick={()=>this.setView('chart')}>
            </a>,
            <a key="line" title="Line Chart" className={"chart-icon " + (this.state.view == 'line' ? 'active' : '')} dangerouslySetInnerHTML={{__html: lineSvg}} onClick={()=>this.setView('line')}>
            </a>,
            <a key="map" title="Map" className={"chart-icon " + (this.state.view == 'map' ? 'active' : '')} dangerouslySetInnerHTML={{__html: worldSvg}} onClick={()=>this.setView('map')}>
            </a>,

            <a key="download" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        ];
        return toolbar;
    }

    setView(view){
        this.setState({
            view
        })
    }

    addToProductsFilter(product){
        return;
        // TODO: fix persistence used in PageFilter.addToFilter
        this.props.dispatch(filterActions.setEntityFilter('products', [product]))
        this.props.history.push('/catalog/tracks');
    }

    exportToCsv(){
        const sorted = this.state.sorted[0];
        const filename = CSV.CSVHeader('products', sorted.id, this.props.filter.global);
        const data = CSV.expandTrends(this.state.data);
        return CSV.CSVExport(data, {filename}, 'products');
    }

    expandSource(source, event) {
        if(event) {
            event.preventDefault();
            event.stopPropagation();
        }
        let sources = Object.assign({}, this.state.sources);
        sources[source] = !sources[source];
        this.setState({
            sources
        });
    }

    render() {
        const { loaded } = this.state;
        let tableData = this.state.data,
            ids = [];

        if(tableData.length)
            ids = tableData.filter(item=>item.id).map(item=>item.id);

        return (
            <div>
            <CatalogOverview view="catalog" />
            {!this.props.products.loading && <div className="section-filter">
                <div className="page-filter-holder">
                    <EntityFilterSwitcher loaded={loaded} entity="imprints" label="Imprints" />
                    <EntityFilterSwitcher loaded={loaded} entity="artists" label="Artists" />                    
                    <EntityFilterSwitcher loaded={loaded} entity="products" label="Products" />
                </div>
            </div>}
            <div className="wrapper wrapper-content light-blue-bg">
                <Box title="" toolbar={this.renderToolbar()} className="light-blue-bg catalogue-section-holder">
                    <SectionHeader entity="products" searchEntity="Product" label="Products" data={this.state.data} view={this.state.view} filtered={this.state.filtered} onChange={this.setSearch}>
                        <div className="manage-columns-holder">
                            <a key="settings" className="dropdown-toggle manage-columns-title" data-toggle="dropdown" href="#">
                                Manage Columns
                                <span className="chart-icon" dangerouslySetInnerHTML={{__html: manageSvg}}></span>
                            </a>
                            <div key="settings-box" className="dropdown-menu">
                                <FieldSelector settings={productsSettings.settingsForTable()} current={this.state.filter} onUpdate={this.setFilter} />
                            </div>
                        </div>
                    </SectionHeader>
                    {this.state.view == 'table' && <div>
                        <CellHeaderTable
                            manual
                            className="catalogue-table-holder sticky-table"
                            data={tableData}
                            loading={this.props.products.loading}
                            loadingText={LoadingMessage('catalog')}
                        noDataText='No results found. Please check your filters.'
                            pages={this.state.pages}
                            defaultPageSize={this.getPageSize()}
                            showPageSizeOptions={false}
                            columns={productsSettings.settingsForTable(this.state.filter.columns, this.addToProductsFilter, this.state.sources, this.expandSource)}
                            defaultSorted={this.state.defaultSorted}
                            defaultSortDesc={true}
                            filtered={this.state.filter.filtered}
                            onFetchData={this.loadData}
                        />
                    </div>}
                    {this.state.view == 'chart' && <div class="table-chart"><PieChart
                        data={productsFormatter.formatForPercentageChart(tableData, 'curr_units')}
                        labels={{header: 'Products', titleLabel: 'Product', valueLabel: 'streams'}}
                    /></div>}
                    {this.state.view == 'line' && <div class="table-chart"><StreamsStats
                        entity="products" ids={ids}
                    /></div>}
                    {this.state.view == 'map' && <div class=""><TerritoryStats
                        entity="products" ids={ids} expanded={true}
                    /></div>}

                </Box>
                <div className="row">
                    <div className="col-xs-4">

                    </div>
                </div>
            </div>
            <Helmet>
                <title>Products - Catalogue Overview</title>
            </Helmet>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        products: state.products,
        filter: state.filter
    }
}
export default withRouter(connect(mapStateToProps)(Products))
