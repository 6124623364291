import React, {Component} from 'react';
import { get } from 'lodash';
import {connect} from 'react-redux';
import {commonActions} from '../../data/actions/common';
import { Dropdown } from 'react-bootstrap';
import CSV from '../../helpers/CSVExport';
import { imprintsActions } from '../../data/actions/imprints';
import { artistsActions } from '../../data/actions/artists';
import { productsActions } from '../../data/actions/products';
import { projectsActions } from '../../data/actions/projects';
import { tracksActions } from '../../data/actions/tracks';
import { statsActions } from '../../data/actions/stats';
import Spinner from './Spinner';

class BulkCSVExport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingInitiated: false,
            loadingDone: false
        }
    }
    
    componentDidUpdate(prevProps) {
        if(!prevProps.loadingDone && this.props.loadingDone) {
            this.setState({
                loadingInitiated: false,
                loadingDone: true
            }, ()=>{
                this.exportToCsv();                
            })
        }
        
    }

    
    exportToCsv = () => {
        const { entities, page, id = null, dataMap } = this.props;
        let data = [];
        for(let entity of entities) {
            data.push({[entity]: get(this.props, dataMap[entity]['data'])});
        }
        if(id) {
            data.push({
                'transactions': get(this.props, `stats.transactions.${id}`)
            })
        }
        const filename = CSV.CSVHeader(page, id, this.props.filter.global);
        CSV.CSVBulkExport(data, filename);
    }
    
    loadData = () => {
        const { entities } = this.props;
        const dataCalls = entities.map(entity=>this.props.loadAllDataCall(entity));
        Promise.all(dataCalls).then(results=>{
            console.log('loading initiated');
            this.setState({loadingInitiated: true})
        });
    }
    
    render(){
        const { loadingInitiated, loadingDone } = this.state;
        if(loadingInitiated && !loadingDone)
            return <Spinner enabled={true} />;
        return <a className="default-btn default-btn--small" onClick={(e)=>{this.loadData()}}>Export</a>
    }
}

function mapStateToProps(state) {
  const {
    imprints,
    artists,
    products,
    projects,
    tracks,
    stats,
    filter
  } = state;
  return {
    imprints,
    artists,
    products,
    projects,
    tracks,
    stats,
    filter
  }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
      loadAllDataCall: (entity) => {
          switch(entity) {
          case 'top_imprints':
              return dispatch(imprintsActions.forceLoadAllData(true));            
          case 'top_artists':
              return dispatch(artistsActions.forceLoadAllData(true));
          case 'top_projects':
              return dispatch(projectsActions.forceLoadAllData(true));
          case 'top_products':
              return dispatch(productsActions.forceLoadAllData(true));
          case 'top_tracks':
              return dispatch(tracksActions.forceLoadAllData(true));
          case 'top_territories':
              return dispatch(statsActions.forceLoadAllTopTerritoriesData(true));
          case 'territories':
              return dispatch(statsActions.forceLoadAllTerritoriesData(true));                                
          case 'top_vendors':
                  return dispatch(statsActions.forceLoadAllVendorsData(true));            
              
          }
      }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BulkCSVExport);