import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize } from 'lodash';
import CSV from '../../../helpers/CSVExport';
import Box from '../../common/Box';
import LineChart from '../../widgets/charts/types/LineChart';
import VendorLogo from "../../common/VendorLogo";

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { audienceActions } from '../../../data/actions/audience';


class Streams extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.exportToCsv = this.exportToCsv.bind(this);
    }
        
    componentDidMount() {
        this.props.getStreamsStats(this.props.id, this.props.entity);
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStreamsStats(this.props.id, this.props.entity);
            }                
        }
        if(nextProps.id && !isEqual(nextProps.id, this.props.id)) {
            this.props.getStreamsStats(nextProps.id, nextProps.entity);
        } 
    }
    
    exportToCsv(){
        const filename = CSV.CSVHeader('audience_listeners', 'listeners', this.props.filter.global); 
        return CSV.CSVExport(this.props.audience.streams_listeners, {filename}, 'audience_listeners');
    }

    
    renderToolbar(modes){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }


    renderIcon() {
        return <div className="title-vendors-list title-vendors-list--single">
            <VendorLogo name={'spotify'} />
        </div>
    }
    
    render (){  
        const { entity, id } = this.props,
            {timeline, loading} = this.props.audience,
            widgetTitle = `Streams and Unique Listeners`;
            
        return <Box title={widgetTitle} spinnerEnabled={loading} vendorLogoList={this.renderIcon()} toolbar={this.renderToolbar()} >
            {timeline && 
                <div className="streams-audience-chart">
                    <div className="chart-block">
                        <LineChart data={timeline} id='audience_streams_line_chart' />
                    </div>
                    {...this.props.children}
                </div>
            }
        </Box>;
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getStreamsStats: (id, entity) => {
            return dispatch(audienceActions.getStreamsStats(id, entity))
        } 
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(Streams);