import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PercentageValue from '../../../common/PercentageValue';
import AbsoluteValue from '../../../common/AbsoluteValue';
import Tooltip from '../../../common/Tooltip';
import EntityCard from '../../../common/SharedEntityCard';
import ConfirmClick from '../../../common/ConfirmClick';
import { splitByThousands, percentageValue } from '../../../../helpers/NumberFormatter';

export const importErrorsSettings = {
    settingsForTable
};

function settingsForTable(loadData){

    let columns = [
            {
                id: 'product',
                Header: 'Product (UPC)',
                accessor: 'product',
                className: 'text-align-left',
                headerClassName: 'text-align-left',                
            },
           
            {
                id: 'field',
                Header: 'Field',
                accessor: 'field',
                width: 100,
                Aggregated: ()=>'',
                headerClassName: 'text-align-left',
                className: 'text-align-left',
            },
            
            {
                id: 'data',
                Header: 'Data',
                accessor: 'data',
                width: 150,
                Aggregated: ()=>'',
                headerClassName: 'text-align-left',
                className: 'text-align-left',
            },
            {
                id: 'error',
                Header: 'Error',
                accessor: 'error',
                Aggregated: ()=>'',
                className: 'text-align-left',
                headerClassName: 'text-align-left',
            }
            ];
            
    return columns; 
}
