import React from 'react';
import store from '../../../helpers/store';
import { find } from 'lodash';
import { connect } from 'react-redux';

import EntityStatusWidget from './EntityStatusWidget';
import { globalFilterActions } from '../../../data/actions/global_filter';

class EntityStatus extends React.Component {
    constructor(props) {
        super(props);
        this.getStatus = this.getStatus.bind(this);
        this.setStatus = this.setStatus.bind(this);
    }
    
    componentDidMount() {
    }
    
    setStatus(entity, id, status) {
        const { dispatch, globalFilter: {globalFilter} } = this.props;
        const existingEntity = find(globalFilter, (filterItem)=>(filterItem.entity_type == entity && filterItem.entity_id == String(id)));
        if(existingEntity && existingEntity.fav_hide == status)
            return dispatch(globalFilterActions.deleteEntity(existingEntity.id));    
        return dispatch(globalFilterActions.addEntity(entity, id, status));
    }
    
    getStatus(entity, id) {
        const { globalFilter } = this.props.globalFilter;
        const existingEntity = find(globalFilter, (filterItem)=>(filterItem.entity_type == entity && filterItem.entity_id == String(id)));
        if(existingEntity)
            return existingEntity.fav_hide;
        else
            return undefined;
    }
    
    render(){
        const { entity, id } = this.props,
            status = this.getStatus(entity, id);
        return <EntityStatusWidget entity={entity} id={id} status={status} setStatus={(status)=>this.setStatus(entity, id, status)} /> 
    }
}


function mapStateToProps(state) {
    return {
        globalFilter: state.globalFilter
    }
}


export default connect(mapStateToProps)(EntityStatus)