import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { debounce, pull, isEqual, capitalize, find } from 'lodash';
import { Dropdown } from 'react-bootstrap';
import Spinner from '../../common/Spinner';
import ReactSelect from '../../common/ReactSelect';
import { components } from 'react-select'
import ClickOutsideHandler from '../../common/ClickOutsideHandler';

import { searchActions } from '../../../data/actions/search';


var imprintsSvg = require('!svg-inline-loader!../../../../public/img/labels.svg');
var artistsSvg = require('!svg-inline-loader!../../../../public/img/artists.svg');
var projectsSvg = require('!svg-inline-loader!../../../../public/img/projects.svg');
var productsSvg = require('!svg-inline-loader!../../../../public/img/products.svg');
var tracksSvg = require('!svg-inline-loader!../../../../public/img/tracks.svg');


const { Option } = components;
const CustomSelectOption = (props) => {
  return <Option {...props} className={`${props.isSelected ? 'selected' : ''}`}>
    <div className={`search-entity-logo ${props.data.label.toLowerCase()}`}></div>
    {props.data.label}
  </Option>
};

const CustomSelectValue = (props) => (
  <div className="search-select-option">
    <div className={`search-entity-logo ${props.data.label.toLowerCase()}`}></div>
    {props.data.label}
  </div>
);

class SearchWidget extends React.Component {
    allSearchEntities = ["imprints", "artists", "products", "projects", "tracks"];
    allSearchEntitiesObjects = [
        { type: "imprints", icon: imprintsSvg },
        { type: "products", icon: productsSvg },
        { type: "projects", icon: projectsSvg },
        { type: "artists", icon: artistsSvg },
        { type: "tracks", icon: tracksSvg },
    ];

    resultsLength = 10;
    
    constructor(props){
        super(props);
        this.state = {
            search: '',
            entities: Object.assign([], this.allSearchEntities),
            autocomplete: [],
            showAutocomplete: false,
            expanded: true,
            modalId: 'autocompleteSearch',
            showModal: false,
            selectedOption: { value: null, label: "All" },
            showSearchResult: false,
            resultsExpanded: {imprints: true, artists: true, projects: true, products: true, tracks: true}
        }
        this.addToFilter = this.addToFilter.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setFilterSearch = debounce(this.setFilterSearch.bind(this), 1000);
        this.loadMoreResults = this.loadMoreResults.bind(this);
        this.setSearchEntity = this.setSearchEntity.bind(this);
        this.parentExpand = this.parentExpand.bind(this);
        this.resultExpand = this.resultExpand.bind(this);
    }
    
    parentExpand(modalExpanded) {
        if(this.props.onExpand !== undefined) 
            this.props.onExpand(modalExpanded);
    }

    resultExpand(entity, expanded) {
        this.setState({
            ...this.state,
            resultsExpanded: {
                ...this.state.resultsExpanded,
                [entity]: expanded
            }
        })
    }

    renderFilterIcon(entity) {
        // const { filterKey } = this.props;
        const searchCriteria = (f) =>
          f.type === entity;
        const currentType = this.allSearchEntitiesObjects.find(searchCriteria);
        const icon = currentType ? currentType.icon : "";
        
        return <span className={`page-filter-icon ${currentType.type}`} dangerouslySetInnerHTML={{__html: icon}} />
    }
    
    componentDidMount() {
        const search = this.props.mode !== 'playlist' ? 'search' : 'search_playlist';
        if(search == 'search')
            $(`#${this.state.modalId}${search}`).on('hidden.bs.modal', (e)=>this.parentExpand(false));
    }
    
    componentWillReceiveProps(nextProps){
        const search = this.props.mode !== 'playlist' ? 'search' : 'search_playlist';
        
        if(nextProps[search] && nextProps[search].autocomplete_items && !isEqual(nextProps[search].autocomplete_items, this.props[search].autocomplete_items)){
            this.setState({
                autocomplete: nextProps[search].autocomplete_items
            }, ()=>{
                if (this.state.autocomplete.length) {
                    this.setState({
                      showSearchResult: true,
                    });
                    this.parentExpand(true);
                  } else {
                    this.setState({
                      showSearchResult: Boolean(this.state.search),
                    });
                  }
            })
        }
    }

    setSearch(e){
        const value = e.target.value;
        this.setState({
            autocomplete: [],
            search: value,
        })
        this.setFilterSearch(value);
    }

    setFilterSearch(value){
        return this.props.getAutocomplete(value, this.resultsLength, this.state.entities);
    }
    
    loadMoreResults() {
        const { search, entities } = this.state;
        return this.props.getAutocomplete(search, this.resultsLength, entities, true);
    }
    

    addToFilter(e, item){
        const search = this.props.mode !== 'playlist' ? 'search' : 'search_playlist';
        e.preventDefault();
        this.setState({
            showAutocomplete: false,
            // search: ''
        }, ()=>{
            this.setState({
                showSearchResult: false,
            });
            // $(`#${this.state.modalId}${search}`).modal('hide');
            this.parentExpand(false);
            return this.props.searchResult(item);
        })
    }

    onSubmit(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            return this.props.getAutocomplete(this.state.search, this.resultsLength, this.state.entities);
        }
    }

    onFocus = () =>{
        const { showSearchResult, autocomplete: { total } } = this.state;
        if(total > 0 && !showSearchResult) {
            this.setState({
                showSearchResult: true
            });
        }
    }
    
    setSearchEntity(entity) {
    	let { entities } = this.state;
    	if(entities.includes(entity))
    		entities = entities.filter(currentEntity=>currentEntity!=entity);
    	else
    		entities = [...entities, entity];
    	this.setState({entities});
    }

    entitySelectChange = (selectedOption) => {
        this.setState({ selectedOption: selectedOption });
    
        this.setSearchEntity(selectedOption.value);
      };
    
    renderSearchEntities = () => {
        const { selectedOption } = this.state;
        const { mode = 'main' } = this.props;

//        let searchEntities = [{
//            label: 'All',
//            value: this.allSearchEntities
//        }];
//
//        for(let entity of this.allSearchEntities) {
//            searchEntities.push({
//                label: capitalize(entity),
//                value: [entity]
//            });
//        };

        // for (let { type } of this.allSearchEntitiesObjects) {
        //     searchEntities.push({
        //       label: capitalize(type).replace(/s$/, ''),
        //       value: [type],
        //     });
        //   }

//        const options = searchEntities.map((entity) => ({
//          label: entity.label,
//          value: entity.value,
//        }));
//
//        const selectedOptionObject = find(options, (option)=>option.label == selectedOption.label);
        
        const searchEntities = this.allSearchEntities.map(entity=>({
            label: entity == 'imprints' ? 'Labels' : capitalize(entity),
            value: entity
        }));

         return searchEntities.map(entity=>{
             return <div key={entity.label} className={`${entity.label.toLowerCase()} radio input-holder`}>
                <input onClick={()=>this.setSearchEntity(entity.value)} type="checkbox" name="search-entity" className="visually-hidden input-checkbox" checked={this.state.entities.includes(entity.value)} id={`search-entity-${entity.label}-${mode}`} />
                <label htmlFor={`search-entity-${entity.label}-${mode}`} className="radio-inline checkbox-label">{entity.label}</label>
             </div>
         });

        /*
        return (
          <ReactSelect
            options={options}
            placeholder="Choose Filter"
            onChange={(selectedOption) => this.entitySelectChange(selectedOption)}
            value={selectedOptionObject}
            className="select-wrapper"
    //        defaultValue={defaultValue}
            components={{
              Option: CustomSelectOption,
              SingleValue: CustomSelectValue,
            }}
          />
        );
        */

        
    }

    render() {
        const search = this.props.mode !== 'playlist' ? 'search' : 'search_playlist';
        const { exactMatch } = this.props[search];
        return (
                <div className={`page-filter ${this.props.mode}`} >
                    <div className="search-widget">
                        <form role="form" className="search-widget-form">
                            <div className="form-group search">
                                <input className="form-control" placeholder={this.props.placeholder || "Search by label, artist, product, project or track"} type="text" value={this.state.search} onChange={this.setSearch} onKeyPress={this.onSubmit} onFocus={(this.onFocus)} />
                                <Spinner enabled={this.props[search].loading} />
                            </div>
                            <div className="form-group entity-search-list">
                                <span className="entity-search-list-label">Search by: </span>
                                <div className="entity-search-list-holder">{this.renderSearchEntities()}</div>
                            </div>
                        </form>

                        {this.state.showSearchResult && (
                            <ClickOutsideHandler onClickOutside={() => {this.setState({showSearchResult: false})}}>
                                <div className="search-result-list">
                                {this.state.autocomplete.total === 0 &&
                                !this.props[search].loading ? (
                                    <div className="no-results-holder">
                                    <div className="no-results-title">No results found</div>
                                    <div className="no-results-content">
                                        <span className="no-results-list-title">Suggestions:</span>
                                        <ul className="no-results-list">
                                        <li>Make sure all words are spelled correctly.</li>
                                        <li>Try a different search scope.</li>
                                        <li>Try different search terms.</li>
                                        <li>Try more general search terms.</li>
                                        <li>Try fewer search terms.</li>
                                        </ul>
                                    </div>
                                    </div>
                                ) : (<React.Fragment>{Object.keys(this.state.autocomplete).map(entity=>{
                                    if(!Array.isArray(this.state.autocomplete[entity]) || !this.state.autocomplete[entity].length)
                                        return null;
                                    const expanded = this.state.resultsExpanded[entity];
                                    // console.log("test", this.state.autocomplete[entity].length)
                                    const groupTitle = entity == 'imprints' ? 'Labels' : entity;
                                    return <div className="search-result-item">
                                    <a className="search-entity-group-link" onClick={()=>this.resultExpand(entity, !expanded)}>
                                    {this.renderFilterIcon(entity)}
                                    <span className="search-entity-group-title">{groupTitle} ({this.state.autocomplete[entity].length})</span>
                                    <span className={`search-arrow ${expanded ? 'expanded' : 'collapsed'}`} /></a>
                                    {expanded && <ul className="autocomplete">
                                    {this.state.autocomplete[entity].map((item) => {
                                        return (
                                        <li key={item.id} className="autocomplete-item">
                                            <a
                                            href="#"
                                            onClick={(e) => this.addToFilter(e, item)}
                                            className="logo-container"
                                            >
                                            <span>{item.name}</span>
                                            </a>
                                        </li>
                                        );
                                    })}
                                    </ul>}
                                    
                                    </div>
                                })}
                                {exactMatch && <a className="load-more-results-link" onClick={this.loadMoreResults}>Load more results</a>}
                                </React.Fragment>)
                            }
                            </div>
                        </ClickOutsideHandler>
                        )}
                    </div>
                </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        search: state.search,
        search_playlist: state.search_playlist
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getAutocomplete: (value, limit, entities, force) => {
            return ownProps.mode !== 'playlist' ? dispatch(searchActions.getSearchAutocomplete(value, limit, entities, force)) : dispatch(searchActions.getSearchPlaylistAutocomplete(value, limit, entities, force)) ;
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SearchWidget)
