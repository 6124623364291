import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'

import { composeValidators, required, email } from './validators'

class AddUserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let {onSubmit, values={}} = this.props;
        console.log(values);
        
        return <Form onSubmit={onSubmit} initialValues={values} keepDirtyOnReinitialize={true}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
                return <form className="m-t" onSubmit={handleSubmit}>
                    <Field name="first_name"  
                        validate={composeValidators(required)}
                    >
                        {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return <div className={`form-group${hasError ? ' has-error':''}`}>
                                <label className="font-normal control-label">First Name</label>
                                <input {...input} type="text" className="form-control" />
                                {hasError && <span className="help-block">{meta.error}</span>}
                            </div>            
                        }}
                    </Field>
                    
                    <Field name="last_name"  
                        validate={composeValidators(required)}
                    >
                        {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return <div className={`form-group${hasError ? ' has-error':''}`}>
                                <label className="font-normal control-label">Last Name</label>
                                <input {...input} type="text" className="form-control" />
                                {hasError && <span className="help-block">{meta.error}</span>}
                            </div>            
                        }}
                    </Field>         
                    <Field name="invitee_email"  
                        validate={composeValidators(required, email)}
                    >
                        {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return <div className={`form-group${hasError ? ' has-error':''}`}>
                                <label className="font-normal control-label">Email</label>
                                <input {...input} type="text" className="form-control" />
                                {hasError && <span className="help-block">{meta.error}</span>}
                            </div>            
                        }}
                    </Field>
                    <Field name="allow_api" type="checkbox"
                    >
                        {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return <div className={`checkbox${hasError ? ' has-error':''}`}>
                                <label className="font-normal">Enable API access
                                    <input {...input} type="checkbox" className="checkbox enable-api-checkbox" />
                                </label>
                                {hasError && <span className="help-block">{meta.error}</span>}
                            </div>            
                        }}
                    </Field>
                    
                    <button type="submit" className="default-btn">Save</button>
                </form>
            }}
        />
    }
}
export default AddUserForm