import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { map, sumBy } from 'lodash';
import { Link } from 'react-router-dom';
import Spinner from '../../components/common/Spinner';
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { importsActions } from '../../data/actions/imports';
import { catalogImportsSettings } from '../../components/pages/imports/catalogImports/Settings';
import CSV from '../../helpers/CSVExport';

var deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');

class Imports extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.exportToCsv = this.exportToCsv.bind(this);
        this.undo = this.undo.bind(this);
    }
    
    componentDidMount() {
        this.props.dispatch(importsActions.getCatalogImports());
    }
    
    undo(id){
        this.props.dispatch(importsActions.undoCatalogImport(id)).then(()=>this.props.dispatch(importsActions.getCatalogImports()));
    }
    
    exportToCsv(period){
        let {statements} = this.props.imports;
        let exportData = statements.filter(row=>row.period == period);
        let totalRow = {
                period,
                label: 'Total',
                digital_income: sumBy(exportData, 'digital_income'),
                physical_income: sumBy(exportData, 'physical_income'),
                total_income: sumBy(exportData, 'total_income')
        };
        exportData.push(totalRow);
        const filename = CSV.CSVHeader('pending_statements', period);
        return CSV.CSVExport(exportData, {filename}, 'pending_statements');
    }
    
    render() {
        const { catalogImports = [], catalogImportsLoading} = this.props.imports,
            rows = Array.isArray(catalogImports) ? catalogImports.length : null;
        
        return <div className="inner-page shared-pages">
            <div className="container">
                <div className="import-menu import-menu-exceptions">
                    <h5>Catalog</h5>
                    <ul>
                        <li><Link to={`/import/list`}>History</Link></li>
                        <li><Link to={`/import/metadata`}>Import</Link></li>
                    </ul>
                    
                </div>
            
                <h2>Catalog Imports</h2>
                <div className="ibox">
                    <ReactTable
                        data={catalogImports}
                        loading={catalogImportsLoading}
                        columns={catalogImportsSettings.settingsForTable(this.undo)}
                        pageSize={rows ? Math.min(rows, 10): 10}
                        showPagination={rows > 10}
                        className="simple-table share-table"
                    />
                    <Spinner enabled={false} /> 
                    

                </div>
            </div>
            <Helmet>
                <title>Catalog Imports</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        imports: state.imports
    } 
}

export default connect(mapStateToProps)(Imports)