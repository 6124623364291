import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactSelect from '../../common/ReactSelect';
import Box from '../../common/Box';
import { artistsActions } from '../../../data/actions/artists';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import LineChart from '../../widgets/charts/types/LineChart';
import CSV from '../../../helpers/CSVExport';

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class FollowersStats extends React.Component {

    nullElement = {value: null, label: 'All'};
    
    constructor(props) {
        super(props);
        this.state = {
            channel: this.nullElement
        }
        this.exportToCsv = this.exportToCsv.bind(this);   
        this.setChannel = this.setChannel.bind(this);
    }
    
    
    componentDidMount() {
        const channels = _.map(this.props.channels, 'remote_channel_id');
        this.props.dispatch(artistsActions.getFollowers(channels));
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !_.isEqual(nextProps.filter.global, this.props.filter.global)) {
                const channels = _.map(this.props.channels, 'remote_channel_id');
                this.props.dispatch(artistsActions.getFollowers(channels));

            }                
        }
        
        if(nextProps.channels && !_.isEqual(nextProps.channels, this.props.channels)){
            const channels = _.map(nextProps.channels, 'remote_channel_id');
            this.props.dispatch(artistsActions.getFollowers(channels));
        }

    }

    exportToCsv (){
        const filename = CSV.CSVHeader('followers', 'curr_units', this.props.filter.global); 
        return CSV.CSVExport(this.props.artists.followers.data, {filename}, 'followers');        
    }
    
    setChannel(channel) {
        this.setState({channel});
    }
    
    renderToolbar(data){
        
        let channels = [this.nullElement];
        
        if(data.followers) {
            for(let channel of data.followers.data) {
                channels.push({
                    value: channel.channel_id,
                    label: channel.label
                });
            }
        }
        //onChange={this.setPlaylist} 
        let toolbar = [];
        toolbar.push(<div className="ibox-action-holder">
        <div className="ibox-actions">
          <ReactSelect value={this.state.channel} options={channels} onChange={this.setChannel} className="single-select"/>
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)

        return toolbar;
    }
    
    render (){
        const artists = this.props.artists;
        
        let timelineData;
        if(artists.followers){
            timelineData = Object.assign({}, artists.followers.chart);
            if(this.state.channel.value!==null) {
                timelineData.datasets = [_.find(artists.followers.chart.datasets, {id: this.state.channel.value})];
            }
            
            timelineData.datasets.map(dataset=>{
                dataset.data = this.state.mode == 'line' ? dataset.timeseries : dataset.absTimeseries;  
                return dataset;
            })
        }
        
        const noChannels = (!artists.followersLoading && !artists.followers)

        return <Box title="Follower Growth" spinnerEnabled={artists.followersLoading} toolbar={this.renderToolbar(artists)}>
            {artists.followers && <div className="row">
                <div className="col-xs-12">
                    <div className="chart-block"><LineChart data={timelineData} /></div>                          
                </div>
            </div>}
            {noChannels && <p>No social media channels added</p>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        artists: state.artists,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(FollowersStats);