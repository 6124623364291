import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import BuildFilterQS from '../../helpers/BuildFilterQS';
import config from '../../config/config';
import {searchService} from './search';

export const artistsService = {
    getArtists,
    getTopArtists,
    getArtistDetails,
    getArtistsAutocomplete,
    getCard,
    getStreamsStats,
    getArtistProfile,
    getFollowers,
    getProducts,
    getMetadata,
    getTimeseriesArtists,
};

function getArtists(params, globalFilter){
    return config.live ? getArtistsNew(params, globalFilter) : getArtistsStatic();
}

function getTopArtists(params, globalFilter){
    return config.live ? getTopArtistsLive(params, globalFilter) : getTopArtistsStatic();
}

function getTopArtistsLive(params, globalFilter) {
    let baseUrl = config.apis.secStat+'/top_artists',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.mode = 'main';
    let {sorted, filtered, page } = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    if(page !== undefined){
        qs.offset = Number(page)*qs.limit; 
    }
    
    baseUrl += '?' + $.param(qs);

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTopArtistsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/sec/top_artists.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getArtistDetails(mode) {
    let baseUrl = `${config.apis.sales}/api/static/artist_products.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getArtistsNew(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'cat_nav_artists';
    qs.offset = params.page ? params.page*globalFilter.limit : 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else if(typeof value === 'string') { // TODO: kinda crappy way of determining full-string search 
                qs.search_by = filter.id;
                qs.search_term = filter.value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    qs = BuildFilterQS(qs, 'artists');
    
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getArtistsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/artists.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    });
}

function getArtistsAutocomplete(input) {
    return searchService.autocomplete(input, ['artists']);
}

function getCard(id, socials, recommended, related = false) {
    let fields = `id,name,catalogue_name,image&include_socials=${socials}&recommended_channels=${recommended}&related_artists=${related}`, 
        baseUrl = `${config.apis.miniCat}/v2/artists/${id}?fields=${fields}`,
        requestOptions = {
            headers: AuthHeader()
        };
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getStreamsStats(ids, globalFilter) {
    return config.live ? getStreamsStatsLive(ids, globalFilter) : getStreamsStatsStatic();
}

function getStreamsStatsLive(ids, globalFilter) {

    //
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    //
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'top_artists';
    // qs.offset = params.page ? params.page*globalFilter.limit : 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    baseUrl += '?' + $.param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
} 

function getStreamsStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_artists_streams.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    })    
}

function getArtistProfile(id, globalFilter) {
    return config.live ? getArtistProfileLive(id, globalFilter) : getArtistProfileStatic();
}

function getArtistProfileLive(id, globalFilter) {

    let baseUrl = config.apis.secStat+'/artist_profile_header',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.artists = id;

    baseUrl += '?' + $.param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
} 

function getArtistProfileStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/artist_profile_header.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    })    
}

function getFollowers(channels, globalFilter) {
    return config.live ? getFollowersLive(channels, globalFilter) : getFollowersStatic();
}

function getFollowersLive(channel_ids, globalFilter) {
    let params = {
        channel_ids: channel_ids.join(','),
        start_date: globalFilter.dateStart,
        end_date: globalFilter.dateEnd
    },
    headers = AuthHeader({'Content-Type': 'application/json'}),
    baseUrl = `${config.apis.socialCollector}/data/time_series_bq`,
    requestOptions = {
        method: 'GET',
        headers,
    };
    
    baseUrl += '?' + $.param(params).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
    .then(res=>res.json());    
} 

function getFollowersStatic() {
    let baseUrl = `${config.apis.sales}/api/static/social_metric_new.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    })    
}

function getProducts(id) {
    let baseUrl = `${config.apis.miniCat}/v1/artists/${id}/products`,
        requestOptions = {
            headers: AuthHeader()
        };
        
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
    
}

function getMetadata(ids){
    return config.live ? getMetadataLive(ids) : getMetadataStatic();
}

function getMetadataLive(ids) {
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.miniCat}/v2/artists/by_id`,
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}),
            body: 'artist_ids='+ids
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/top_artists_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}


function getTimeseriesArtists(params, globalFilter){
    return true || config.live ? getTimeseriesArtistsLive(params, globalFilter) : getTimeseriesArtistsStatic();
}

function getTimeseriesArtistsLive(params, globalFilter) {
    
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */

	let baseUrl = config.apis.secStat+'/top_artists',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.mode = 'timeseries';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            const key = filter.id == 'ids' ? 'artists' : filter.id;
            
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[key] = value;
            }
            else {
                qs[key] = value;    
            }
        }            
    }    

    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTimeseriesArtistsStatic() { 
	let baseUrl = `${config.apis.sales}/api/static/sec/top_artists_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}
