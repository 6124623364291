import React from 'react';
import {View, Text, StyleSheet, Svg } from '@react-pdf/renderer';
import { splitByThousands } from '../../../helpers/NumberFormatter';

const styles = StyleSheet.create({
    overviewBox: {
        flexDirection: 'column',
        padding: 10
    },
    img: {
        width: 20,
        height: 20
    },
    title: {
        fontSize: 8,
        marginBottom: 20
    }
});

const StatementTotalsBox = ({item: month}) => {
    return <View style={styles.overviewBox}>
        {/*<Svg class={styles.img}>{item.icon}</Svg> not working*/ }
        <Text style={styles.title}>{month.month}</Text>
        <Text>Physical Income: {splitByThousands(month.physical_income, '$')}</Text>
        <Text>Digital Income: {splitByThousands(month.digital_income, '$')}</Text>
        <Text>NR Income: {splitByThousands(month.nr_income, '$')}</Text>
        <Text>Total Income: {splitByThousands(month.total_income, '$')}</Text>
    </View>
};
  
export default StatementTotalsBox
