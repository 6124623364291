export const tracksConstants = {
    GET_TRACKS_REQUEST: 'GET_TRACKS_REQUEST',
    GET_TRACKS_SUCCESS: 'GET_TRACKS_SUCCESS',
    GET_TRACKS_FAILURE: 'GET_TRACKS_FAILURE',
    GET_TRACKS_AUTOCOMPLETE_REQUEST: 'GET_TRACKS_AUTOCOMPLETE_REQUEST',
    GET_TRACKS_AUTOCOMPLETE_SUCCESS: 'GET_TRACKS_AUTOCOMPLETE_SUCCESS',
    GET_TRACKS_AUTOCOMPLETE_FAILURE: 'GET_TRACKS_AUTOCOMPLETE_FAILURE',
    GET_TOP_TRACKS_REQUEST: 'GET_TOP_TRACKS_REQUEST',
    GET_TOP_TRACKS_SUCCESS: 'GET_TOP_TRACKS_SUCCESS',
    GET_TOP_TRACKS_FAILURE: 'GET_TOP_TRACKS_FAILURE',
    GET_TRACK_DETAILS_REQUEST: 'GET_TRACK_DETAILS_REQUEST',
    GET_TRACK_DETAILS_SUCCESS: 'GET_TRACK_DETAILS_SUCCESS',
    GET_TRACK_DETAILS_FAILURE: 'GET_TRACK_DETAILS_FAILURE',
    GET_TRACKS_CARD_REQUEST: 'GET_TRACKS_CARD_REQUEST',
    GET_TRACKS_CARD_SUCCESS: 'GET_TRACKS_CARD_SUCCESS',
    GET_TRACKS_CARD_FAILURE: 'GET_TRACKS_CARD_FAILURE',
    GET_STREAMS_STATS_REQUEST: 'GET_TRACKS_STREAMS_STATS_REQUEST',
    GET_STREAMS_STATS_SUCCESS: 'GET_TRACKS_STREAMS_STATS_SUCCESS',
    GET_STREAMS_STATS_FAILURE: 'GET_TRACKS_STREAMS_STATS_FAILURE',
    GET_TIMESERIES_TRACKS_REQUEST: 'GET_TIMESERIES_TRACKS_REQUEST',
    GET_TIMESERIES_TRACKS_SUCCESS: 'GET_TIMESERIES_TRACKS_SUCCESS',
    GET_TIMESERIES_TRACKS_FAILURE: 'GET_TIMESERIES_TRACKS_FAILURE',
    GET_TOP_TRACKS_IN_PLAYLIST_REQUEST: 'GET_TOP_TRACKS_IN_PLAYLIST_REQUEST',
    GET_TOP_TRACKS_IN_PLAYLIST_SUCCESS: 'GET_TOP_TRACKS_IN_PLAYLIST_SUCCESS',
    GET_TOP_TRACKS_IN_PLAYLIST_FAILURE: 'GET_TOP_TRACKS_IN_PLAYLIST_FAILURE',
    GET_TIMESERIES_TRACKS_IN_PLAYLIST_REQUEST: 'GET_TIMESERIES_TRACKS_IN_PLAYLIST_REQUEST',
    GET_TIMESERIES_TRACKS_IN_PLAYLIST_SUCCESS: 'GET_TIMESERIES_TRACKS_IN_PLAYLIST_SUCCESS',
    GET_TIMESERIES_TRACKS_IN_PLAYLIST_FAILURE: 'GET_TIMESERIES_TRACKS_IN_PLAYLIST_FAILURE',
    COMPARE_TRACKS_REQUEST: 'COMPARE_TRACKS_REQUEST',
    COMPARE_TRACKS_SUCCESS: 'COMPARE_TRACKS_SUCCESS',
    COMPARE_TRACKS_FAILURE: 'COMPARE_TRACKS_FAILURE',
    COMPARE_TRACK_DEMOGRAPHICS_REQUEST: 'COMPARE_TRACK_DEMOGRAPHICS_REQUEST',
    COMPARE_TRACK_DEMOGRAPHICS_SUCCESS: 'COMPARE_TRACK_DEMOGRAPHICS_SUCCESS',
    COMPARE_TRACK_DEMOGRAPHICS_FAILURE: 'COMPARE_TRACK_DEMOGRAPHICS_FAILURE',
    COMPARE_TRACK_TERRITORIES_REQUEST: 'COMPARE_TRACK_TERRITORIES_REQUEST',
    COMPARE_TRACK_TERRITORIES_SUCCESS: 'COMPARE_TRACK_TERRITORIES_SUCCESS',
    COMPARE_TRACK_TERRITORIES_FAILURE: 'COMPARE_TRACK_TERRITORIES_FAILURE',
    COMPARE_TRACK_VENDORS_REQUEST: 'COMPARE_TRACK_VENDORS_REQUEST',
    COMPARE_TRACK_VENDORS_SUCCESS: 'COMPARE_TRACK_VENDORS_SUCCESS',
    COMPARE_TRACK_VENDORS_FAILURE: 'COMPARE_TRACK_VENDORS_FAILURE',    
    GET_TRACKS_RELEASE_DATES_REQUEST: 'GET_TRACKS_RELEASE_DATES_REQUEST',
    GET_TRACKS_RELEASE_DATES_SUCCESS: 'GET_TRACKS_RELEASE_DATES_SUCCESS',
    GET_TRACKS_RELEASE_DATES_FAILURE: 'GET_TRACKS_RELEASE_DATES_FAILURE',
    FORCE_LOAD_ALL_DATA: 'FORCE_LOAD_ALL_DATA_TRACKS',       
}


