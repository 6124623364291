import React from 'react';
import Table from '../../../common/PDF/Table';
import { splitByThousands, addPercent, round } from '../../../../helpers/NumberFormatter';

export default ({data})=>{
    const columns=[
       { key: 'name', title: 'Country', width: 100, fontSize: 5 },
       { key: 'physical_income', title: 'Physical Income', width: 35, fontSize: 5, formatFn: splitByThousands },
       { key: 'physical_quantity', title: 'Physical Qty', width: 35, fontSize: 5, formatFn: splitByThousands } ,      
       { key: 'digital_income', title: 'Digital Income', width: 35, fontSize: 5, formatFn: splitByThousands },
       { key: 'digital_quantity', title: 'Digital Qty', width: 35, fontSize: 5, formatFn: splitByThousands },
       { key: 'nr_income', title: 'NR Income', width: 35, fontSize: 5, formatFn: splitByThousands },
       { key: 'nr_quantity', title: 'NR Qty', width: 35, fontSize: 5, formatFn: splitByThousands },
       { key: 'total_income', title: 'Total Income', width: 35, fontSize: 5, formatFn: splitByThousands },
       { key: 'total_quantity', title: 'Total Qty', width: 35, fontSize: 5, formatFn: splitByThousands },

       ];
       
    return <Table
        columns={columns}
        items={data}
    />
}