import React, { Component } from 'react';
import { map } from 'lodash';
import PercentageValue from '../../common/PercentageValue';

export const heartbeatsSettings = {
  settingsForTable
};

function settingsForTable(state, props, handleChange){
};
