import React, { Component } from 'react';

export default class SelectHeartBeatsDates extends React.Component {
  render(){
    const { week, getDataByWeek, dates } = this.props;
    return (
      <select value={week} onChange={getDataByWeek} >
        {dates.map(value => {
          return (
            <option key={value.startDate} value={value.startDate} >
              {"Wk#" + value.weekNumber + ' ' + value.startDate + ' to ' + value.endDate }
            </option>
          );
        })}
      </select>
    )
  }
}
