import React from 'react';
import { Chart, Bar, HorizontalBar } from 'react-chartjs-2';
import { map, merge } from 'lodash';
import { colors, bar } from '../ChartDefaults';
import RectangularDraw from './RectangularDraw';
import moment from 'moment';

class BarChart extends React.Component {

    constructor(props){
        const options = merge({}, bar, props.options);
        super(props);
        this.state = {
            //data: this.buildChartData(props.data),
            options
        };
        this.getData = this.getData.bind(this);
    }
    
    componentWillMount() {
        //Chart.elements.Rectangle.prototype.draw = RectangularDraw;     
    }

    componentWillReceiveProps(props){
        /*
        this.setState({
            data: this.buildChartData(props.data)
        })
        */
    }
    
    getData(canvas) {
        return this.props.data;
        let {labels, dataset} = this.props.data;
        if(!dataset)
            return false;
        const ctx = canvas.getContext("2d");
        
        const gradientColors = colors.map(color=>{
            let gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
            gradient.addColorStop(0, color);
            gradient.addColorStop(1, '#FFFFFF');
            return gradient;
        });
        
        dataset = {
            label: '',
            backgroundColor: gradientColors,
            data: Object.values(dataset)
        };
        
        labels = labels.map(label=>moment(label).format('DD MMM'));
        return {
            labels,
            datasets: [dataset]
        }
        
    }
    
    render() {  
        return (this.props.data ? 
                (this.props.horizontal ? <HorizontalBar data={this.getData} options={this.state.options}  {...this.props}></HorizontalBar> : <Bar data={this.getData} options={this.state.options} {...this.props}></Bar>) 
                : null);
    }
}

export default BarChart