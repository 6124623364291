import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual } from 'lodash';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import CSV from '../../../helpers/CSVExport';
import { splitByThousands } from '../../../helpers/NumberFormatter';

import config from '../../../config/config';
import Box from '../Box';
import TrendDirection from '../../common/TrendDirection';
import { statsActions } from '../../../data/actions/stats';
import { BarChart, LineChart} from '../../widgets/charts/types';
import { demographicsSettings } from './Demographics/Settings';
import VendorLogo from "../VendorLogo";
import { dspLogos } from "../../common/Stats/utils";

var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class DemographicsStats extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
          mode: "bar",
          logoDataFiltered: [],
          defaultSorted: [{ id: "curr_units", desc: false }],
          //            sort: this.options[0]
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }

    setChartMode(mode){
        this.setState({mode});
    }
    
    componentDidMount() {
        const { chartType } = this.props;
        this.props.dispatch(statsActions.getDemographicsStats(this.props.entity, this.props.ids))
        
        if (this.props.filter.global) {
          if (this.props.filter.global !== undefined) {
            this.setState({
              logoDataFiltered: chartType
                ? dspLogos[chartType].data.filter((l) =>
                    this.props.filter.global.vendors.includes(l)
                  )
                : [],
            });
          }
        }
    }
    
    componentWillReceiveProps(nextProps) {
        const globalFilter = nextProps.filter.global;
        const { chartType } = this.props;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.props.dispatch(statsActions.getDemographicsStats(this.props.entity, this.props.ids))
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    logoDataFiltered: chartType
                      ? dspLogos[chartType].data.filter((l) =>
                          globalFilter.vendors.includes(l)
                        )
                      : [],
                  };
                });
            }                
        }
    }

    getTimeseries() {
        this.props.dispatch(statsActions.getDemographicsTimeseries(this.props.entity, this.props.ids))
    }
    
    exportToCsv(){
        const filename = CSV.CSVHeader('details_demographics', 'curr_units', this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(this.props.stats.demographics.table, {filename});
    }
    
    renderToolbar(modes){
        let toolbar = [];

        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-icon-holder">
            <a key='bar' title="Bar Chart" className={`chart-icon ${this.state.mode == 'bar' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('bar')}
                dangerouslySetInnerHTML={{__html: barSvg}}>
            </a>
            <a key='table' title="Table" className={`chart-icon ${this.state.mode == 'table' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('table')}
                dangerouslySetInnerHTML={{__html: tableSvg}}>
            </a>
            <a key='line' title="Line Chart" className={`chart-icon ${this.state.mode == 'line' ? 'active' : ''}`} 
                onClick={()=>{this.setChartMode('line'); this.getTimeseries();}}
                dangerouslySetInnerHTML={{__html: lineSvg}}>
            </a>            
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            
        </div>
        </div>)
        return toolbar;
    }    
    
    getTrendForGender(data, gender) {
        return maxBy(data.filter(entry=>entry.gender==gender), 'curr_units');
    }
    
    renderTrends(data, single) {
        if(!data || !data.length)
            return null;
        
        const trends = [this.getTrendForGender(data, 'Male'), this.getTrendForGender(data, 'Female')],
            topTrend = maxBy(trends, 'share'); 
        return (topTrend && <div>
            <h2 className="content-title capitalize">{topTrend.gender}s, {topTrend.age}</h2>
            <h4 className="content-subtitle"> is your best performing demographic.</h4>
            {trends.map(trend=>{trend && <TrendDirection key={trend.gender} direction="up">
                <p className={trend.gender}>The most popular age demographic for <span className="gender">{trend.gender}s</span> is <span className="num">{trend.age} years</span>.</p>
            </TrendDirection>})}  

            
        </div>);                        
    }

    renderVendorLogos() {
        const { userData, chartType } = this.props;
        const logoData = chartType ? this.state.logoDataFiltered : [];
        const getIsHidden = (el) => chartType === dspLogos.age.id && el === 'Apple Music' && userData.user.data_silo_codes === 'con';

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                !getIsHidden(l) && <VendorLogo key={l} name={l} />
            ))}
        </div>
    }
    
    
    formatGradient(data) {
        const items = data.datasets;
        if(items){
            for(let item of items) {
                console.log(item);
            }
        }
        return data;
    }
    
    render (){
        const stats = this.props.stats,
            trends = config.showTrends;
        
        return <Box title="Age/Gender" toolbar={this.renderToolbar()} vendorLogoList={this.renderVendorLogos()} spinnerEnabled={this.props.stats.demographicsLoading}>
            {this.props.stats.demographics && <div className="row">
                {trends && <div className="col-sm-5 col-xs-12">
                    {this.renderTrends(stats.demographics.table)}
                </div>}
                <div className={`col-xs-12 col-sm-${trends?7:12}`}>
                    {this.state.mode=='bar' && <div className="chart-block">
                        <BarChart data={this.formatGradient(this.props.stats.demographics.chart)} options={{
                            legend: {display: true, labels: {usePointStyle: true}}, 
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [{
                                    stacked: true
                                }],
                                yAxes: [{
                                    stacked: true,
                                    "ticks": {
                                        callback: function formatAxesLabel(value, index, values){
                                            return splitByThousands(value);
                                        }
                                    }

                                }]
                            },  
                            /*
                            tooltips: {
                                mode: 'index'
                            }
                            */
                            tooltips: {
                                displayColors: false,
                                callbacks: {
                                    title: function(tooltipItem, data) {
                                        const { index, datasetIndex } = tooltipItem[0];
                                        return `${data.datasets[datasetIndex].stack} ${data.labels[index]}`;
                                    },
                                    label: function(tooltipItem, data) {
                                        const { index, datasetIndex } = tooltipItem;
                                        const active = data.datasets[datasetIndex].active[index];
                                        const passive = data.datasets[datasetIndex].passive[index];
                                        const total = active + passive;
                                        return [Math.round(active / total * 100) + '% Streams from active listeners',  
                                            Math.round(passive / total * 100) + '% Streams from passive listeners']; 
                                    }
                                }
                            }
                        }} />                          
                    </div>}
                    {this.state.mode=='line' && <div className="chart-block">
                        <LineChart data={this.props.stats.demographicsTimeseries} />                          
                    </div>}                    
                    {this.state.mode=='table' && <div className="demographics-table territory-table--single custom-scroll">
                        <ReactTable
                            className="playlist-table"
                            data={this.props.stats.demographics.table}
                            columns={demographicsSettings.settingsForTable('details')}
                            defaultPageSize={10}
                            showPagination={true}
                            defaultSorted={this.state.defaultSorted}
                            getTrProps={demographicsSettings.settingsForRow}
                    /></div>}
                </div>    
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter,
        userData: state.user
    } 
}

export default connect(mapStateToProps)(DemographicsStats);