import { projectsConstants } from '../constants/projects';
import { projectsService } from '../services/projects';
import { projectsFormatter } from '../formatters/projects';
import { find } from 'lodash';

export const projectsActions = {
    getProjects,
    getTopProjects,
    getProjectsAutocomplete,
    getProductDetails,
    getCard,
    getCatalogCard,
    getStreamsStats,
    getProjectsByBarcode,
    getTracks,
    getMetadata,
    getTimeseriesProjects,
    forceLoadAllData
};

function getProjects(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        projectsService.getProjects(params, globalFilter)
            .then(
                projects => {
                    const data = projectsFormatter.formatForTable(projects);
                    //dispatch(success(data, projects.pages));
                    return dispatch(getMetadata(data));                    
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PROJECTS_REQUEST } }
    function success(projects, pages) { return { type: projectsConstants.GET_PROJECTS_SUCCESS, projects, pages } }
    function failure(error) { return { type: projectsConstants.GET_PROJECTS_FAILURE, error } }
}

function getProjectsByBarcode(barcodes){
  return dispatch => {
    dispatch(request());

    projectsService.getProjectsByBarcode(barcodes)
    .then(response => {
      const dataIsEmpty = response.data && response.data.length === 0;
      let result = dataIsEmpty ? [] : response.projects;
        dispatch(success(result));
    })
    .catch( error => {
      dispatch(failure(error.message));
    })
  }

  function request() { return { type: projectsConstants.GET_PROJECTS_BY_BARCODES_REQUEST } }
  function success(projects) { return { type: projectsConstants.GET_PROJECTS_BY_BARCODES_SUCCESS, projects } }
  function failure(error) { return { type: projectsConstants.GET_PROJECTS_BY_BARCODES_FAILURE, error } }
}


function getProjectsAutocomplete(search, limit=10) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        projectsService.getProjectsAutocomplete(search)
            .then(
                projects => {
                    const data = projectsFormatter.formatAutocomplete(projects.results, ['projects']);
                    dispatch(success(data.slice(0, limit)));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PROJECTS_AUTOCOMPLETE_REQUEST } }
    function success(projects) { return { type: projectsConstants.GET_PROJECTS_AUTOCOMPLETE_SUCCESS, projects} }
    function failure(error) { return { type: projectsConstants.GET_PROJECTS_AUTOCOMPLETE_FAILURE, error } }
}

function getTopProjects(params, useCache, addTotal = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return projectsService.getTopProjects(params, globalFilter)
            .then(
                projects => {
                    const data = projectsFormatter.formatTop(projects.result);
                    //dispatch(success(data));
                    return dispatch(getTopMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_TOP_PROJECTS_REQUEST } }
    function success(projects) { return { type: projectsConstants.GET_TOP_PROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: projectsConstants.GET_TOP_PROJECTS_FAILURE, error } }
}


function getProductDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const projects = getState().projects.top,
            product = find(projects, {product_id: id});
        return Promise.resolve(product)
            .then(
                product => {
                    const data = projectsFormatter.formatDetails(product.stms_by_date);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PRODUCT_DETAILS_REQUEST } }
    function success(id, product) { return { type: projectsConstants.GET_PRODUCT_DETAILS_SUCCESS, id, product } }
    function failure(error) { return { type: projectsConstants.GET_ARTIST_PRODUCT_FAILURE, error } }
}

function getCard(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return projectsService.getCard(id)
            .then(
                card => {
                    card= projectsFormatter.formatCard(card);
                    dispatch(success(id, card));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PROJECTS_CARD_REQUEST } }
    function success(id, card) { return { type: projectsConstants.GET_PROJECTS_CARD_SUCCESS, id, card } }
    function failure(error) { return { type: projectsConstants.GET_PROJECTS_CARD_FAILURE, error } }
}

function getCatalogCard(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return projectsService.getCatalogCard(id)
            .then(
                card => {
                    card= projectsFormatter.formatCatalogCard(card);
                    dispatch(success(id, card));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PROJECTS_CARD_REQUEST } }
    function success(id, card) { return { type: projectsConstants.GET_PROJECTS_CARD_SUCCESS, id, card } }
    function failure(error) { return { type: projectsConstants.GET_PROJECTS_CARD_FAILURE, error } }
}


function getStreamsStats(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        projectsService.getStreamsStats(ids, globalFilter)
            .then(
                projects => {
                    const metadata = getState().projects.metadata;
                    projects = projectsFormatter.formatStreamsStats(projects, metadata);
                    dispatch(success(projects));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_STREAMS_STATS_REQUEST } }
    function success(projects) { return { type: projectsConstants.GET_STREAMS_STATS_SUCCESS, projects } }
    function failure(error) { return { type: projectsConstants.GET_STREAMS_STATS_FAILURE, error } }
}

function getTracks(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        ids = ids.map(id=>Number(id));
        projectsService.getTracks(ids)
            .then(
                tracks => {
                    const data = projectsFormatter.formatTracks(tracks.data);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_TRACKS_REQUEST } }
    function success(tracks) { return { type: projectsConstants.GET_TRACKS_SUCCESS, tracks} }
    function failure(error) { return { type: projectsConstants.GET_TRACKS_FAILURE, error } }
}

function getTopMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.map(entity=>entity.project);
        return projectsService.getMetadata(ids)
            .then(
                metadata => {
                    let total = entities.length > 0 ? find(entities, entity=>entity.project == '') : {},
                            rows = entities.filter(entity=>entity.project != '');

                    const { formattedRows, formattedMeta }= projectsFormatter.formatMetadata(rows, metadata.data, total);
                    dispatch(success(formattedRows, total, formattedMeta));
                    return formattedRows;

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_TOP_PROJECTS_REQUEST } }
    function success(projects, total, metadata) { return { type: projectsConstants.GET_TOP_PROJECTS_SUCCESS, projects, total, metadata } }
    function failure(error) { return { type: projectsConstants.GET_TOP_PROJECTS_FAILURE, error } }

}

function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.project);
        projectsService.getMetadata(ids)
            .then(
                metadata => {
                    const data = projectsFormatter.formatMetadata(entities.data, metadata.projects);
                    dispatch(success(data, entities.total, metadata.projects));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PROJECTS_REQUEST } }
    function success(projects, total, metadata) { return { type: projectsConstants.GET_PROJECTS_SUCCESS, projects, total, metadata } }
    function failure(error) { return { type: projectsConstants.GET_PROJECTS_FAILURE, error } }

}


function getTimeseriesProjects(params, useCache) {
    return ( dispatch, getState ) => {
        if(getState().projects.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;
        return projectsService.getTimeseriesProjects(params, globalFilter)
            .then(
                projects => {
                    const metadata = getState().projects.metadata;
                    const data = projectsFormatter.formatTimeseries(projects.result, metadata);
                    dispatch(success(data));
                    return projects;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_TIMESERIES_PROJECTS_REQUEST } }
    function success(projects) { return { type: projectsConstants.GET_TIMESERIES_PROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: projectsConstants.GET_TIMESERIES_PROJECTS_FAILURE, error } }
}

function forceLoadAllData(force) {
    return dispatch => {
        dispatch(set(force));
    };

    function set(force) { return { type: projectsConstants.FORCE_LOAD_ALL_DATA, force} }
}
