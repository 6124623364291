import React from 'react';
import Table from '../../../common/PDF/Table';
import { splitByThousands, addPercent, round } from '../../../../helpers/NumberFormatter';

export default ({data})=>{
    const columns=[
       { key: 'source', title: 'Source', width: 15, fontSize: 5 },
       { key: 'classification', title: 'Classification', width: 15, fontSize: 5 },
       { key: 'transaction_type', title: 'Transaction Type', width: 20, fontSize: 5 },
       { key: 'physical_income', title: 'Physical Income',  width: 10, fontSize: 5, formatFn: splitByThousands },
       { key: 'physical_quantity', title: 'Physical Qty',  width: 8, fontSize: 5, formatFn: splitByThousands },
       
       { key: 'digital_income', title: 'Digital Income',  width: 10, fontSize: 5, formatFn: splitByThousands },
       { key: 'digital_quantity', title: 'Digital Qty',  width: 8, fontSize: 5, formatFn: splitByThousands },
       { key: 'nr_income', title: 'NR Income',  width: 10, fontSize: 5, formatFn: splitByThousands },
       { key: 'nr_quantity', title: 'NR Qty',  width: 8, fontSize: 5, formatFn: splitByThousands },
       { key: 'total_income', title: 'Total Income',  width: 10, fontSize: 5, formatFn: splitByThousands },
       { key: 'total_quantity', title: 'Total Qty',  width: 8, fontSize: 5, formatFn: splitByThousands },

       ];
       
    return <Table
        columns={columns}
        items={data}
    />
}