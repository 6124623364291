import { map, find, uniqBy } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand';
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import { formatChartLabel, formatChartLabelItem, sortDateRange } from '../../helpers/DateFormatter';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import {percentageValue} from '../../helpers/NumberFormatter';
import AddTotal from '../../components/common/Datagrid/AddTotal';
import VendorHelper from '../../components/common/Datagrid/VendorHelper';
import FixNumericFields from '../helpers/FixNumericFields';

export const projectsFormatter = {
    formatForPercentageChart,
    formatForTable,
    formatTop,
    formatDetails,
    formatForTableOld,
    formatAutocomplete,
    formatAutocompleteItem,
    formatCard,
    formatCatalogCard,
    formatStreamsStats,
    formatTracks,
    formatMetadata,
    formatTimeseries
};

function formatForPercentageChart(data, column){
    let dataset = [], 
        labels = [];
    
    for(let entry of data) {
        dataset.push(entry[column]);
        labels.push(entry.name);
    }
    return {labels, datasets:[{data: dataset, label: 'Streams'}]};
}

function formatForTable(data){
    if(!data)
        return [];    
    for(let entry of data.data) {
        entry.id = entry.product_id;
        entry.name = entry.product_title;
        entry.logo = ImageURL(entry.product_image, entry.id, 'projects');
        entry.engaged = Math.round((entry.curr_active / entry.curr_units) * 100);
    }
    data.data = AddTotal(data.data, 'product');
    return data;

}

function formatTop(data, addTotal){
    if(!data)
        return [];    
    data = map(data, (entry)=>{
        entry.id = entry.project;
//        entry.name = entry.title;
//        entry.artist_name = entry.artist;
        return entry;
    });
    if(addTotal)
    	data = AddTotal(data, 'project');
    return  FixNumericFields(data);
}

function formatDetails(data){
    if(!data)
        return [];

    let labels = [],
        dataset = [];
    
    data = data.sort(sortDateRange);
    
    for(let item of data) {
        labels.push(formatChartLabel(item));
        dataset.push(item.units);
    }
    return {labels, datasets: [{data: Object.values(dataset), label: 'plays'}]};
}

function formatForTableOld(data){
    if(!data)
        return [];
    for(let entry of data.data) {
        entry.name = entry.title;
        entry.logo = entry.image;
    }
    return data;
}

function _formatAutocompleteItem(hit){
    if(hit.entity)
        return hit;
    
    const entity = 'projects',
        name = hit.name? hit.name : hit._source.title,
        artist = hit.artist_name? hit.artist_name : hit._source.artist,
        id = hit.id ? hit.id : hit._id,
        barcode = hit._source.barcode ? `(${hit._source.barcode}) ` : '';

    return {
        id,
        entity,
        name: `${name} ${barcode}(product by ${artist})`,
        logo: icons.entities[entity]
    };
}

function formatAutocompleteItem(hit){
    const entity = 'projects';
    return {
        id: hit.identifier,
        entity,
        name: `${hit.title} ${hit.identifier} (project${hit.artist_name ? ' by ' + hit.artist_name : ''})`,
        name_raw: hit.title,
        logo: icons.entities[entity],
        code: hit.identifier
    }
}

function formatAutocomplete(data) {
    let {projects}= data;
    return projects.map(formatAutocompleteItem);
}

function formatCard(data) {
	return data.result ? data.result[0] : {};
}

function formatStreamsStats(streams, metadata) {
    let labels = [],
        datasets = [];
    if(streams) {
        const {dates, period} = dateRangeFlat(streams);
        datasets = metadata.map((productEntry, index)=>{
            let product = productEntry.product;
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, product_id: product.id});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: product.id,
                label: product.title,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };
}

function formatTracks(projects) {
    let tracks = [];
    for (let product of projects) {
        for (let track of product.tracks) { 
            tracks.push({
                internal_id: track.id,
                value: track.isrc,
                label: `${track.title} (${track.isrc}) (from ${product.title})`
            })
        }
    }
    return tracks;
}

function formatMetadata(entities, metadata, total) {
    const { total_income } = total;
    for(let entity of entities) {
        const metaEntity = find(metadata, meta=>meta.project==entity.project);
        if(metaEntity) {
            entity.name = entity.product_title = metaEntity.title;
            entity.image = ImageURL(metaEntity.image, entity.id, 'products');
            entity.artist_id = metaEntity.artist_id;
            entity.artist_name = metaEntity.artist;
        }
        else if(!entity.id) {
            entity.name = 'Adjustments';
            entity.artist_name = '';
            entity.image = null;        
        }        
        else if(entity.id === 'Unlisted Project') {
            entity.name = 'Unlisted Project';
            entity.artist_name = '';
            entity.image = null; //ImageURL(metaEntity.image, entity.id, 'artists');
            metadata.push({
                id: 'Unlisted Project',
                name: 'Unlisted Project',
                project: 'Unlisted Project',
            });
        }
        else {
        	if(!entity.project_title) {
        		entity.name = entity.project + ' - Unlisted';
        	}
        }
        entity.share = Math.round(entity.total_income/total_income*10000)/100;
    }
    return { formattedRows: entities, formattedMeta: metadata} ;

}

function formatTimeseries(streams, metadata) {
    let labels = [],
	    datasets = [];
    
	if(streams) {
	    const {dates, period} = dateRangeFlat(streams);
	    datasets = metadata.map((project, index)=>{
	    	
	        let data = [];
	        for(let date of dates) {
	            let stream = find(streams, {report_date: date, project: project.project});
	            data.push(stream ? Number(stream.total_income).toFixed(2) : null);
	        }    
	
	        return {
	            id: project.id,
	            label: project.title,
	            data,
	            fill: false,
	            borderColor: colors[index%colors.length],
	            backgroundColor: colors[index%colors.length],
	            pointBorderColor: colors[index%colors.length],
	            pointBackgroundColor: colors[index%colors.length],                
	
	        }
	    });    
	    
	    labels =  map(dates, (date)=>date) //formatChartLabelItem(date, 'Month'));
	}
	return { labels, datasets };

}

function formatCatalogCard(data) {
    data = data.data;
    return {
      id: data.id,
      name: `${data.title} (project)`,
      name_raw: `${data.title}`,
      code: data.cat_no,
      logo: data.packshot_file_name,
      primary_artist: data.primary_artist,
      release_date: data.release_date
    };
}