import moment from 'moment';

export {
    formatChartLabel,
    formatChartLabelItem,
    sortDateRange
}

function formatChartLabelItem(label, period) {
    const dateLabel = moment(label);
    switch(period) {
        case 'Chart Week':
        case 'Calendar Week':
            const [year, week] = label.split('-'),
                prefix = `${period == 'Chart Week' ? 'Ch.' : 'Cal.'} Wk.`; 
            
            return `${prefix} #${week}, ${year}`;
        case 'Month':
            return dateLabel.format('MMM YYYY');
        default:
            return dateLabel.format('DD MMM');
            
    }

}

function formatChartLabel(item, key='stream_date', periodKey='period') {
    const period = item[periodKey],
        label = item[key];
    return formatChartLabelItem(label, period);
}

function compareValues(a, b) {
    return (a > b) 
        ? 1 
        : (a < b ? -1 : 0);
};

function sortDateRange(a, b) {
    const [yearA, weekA] = a.stream_date.split('-'),
        [yearB, weekB] = b.stream_date.split('-'),
        year = compareValues(Number(yearA), Number(yearB));

    return year === 0 ? compareValues(Number(weekA), Number(weekB)) : year;    
}