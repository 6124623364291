import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import datepicker from 'bootstrap-datepicker';

class Datepicker extends React.Component {
    
    
    componentDidMount() {
        this.dateElement = $(ReactDOM.findDOMNode(this.refs.date));
        console.log(this.props.readonly);
        this.dateElement.datepicker({
            format: "yyyy-mm-dd",
            viewMode: "months", 
            minViewMode: "months",
            todayBtn: "linked",
            keyboardNavigation: false,
            forceParse: false,
            calendarWeeks: true,
            autoclose: true,
            daysOfWeekDisabled: this.props.daysOfWeekDisabled,
            endDate: this.props.endDate,
            container: this.props.container || 'body',
            minDate: this.props.minDate
        });
        this.dateElement.on('changeDate', (e)=>{
            return this.props.dateChange(e.date);
        });
    }

    componentWillUnmount(){
        //const dateElement = $(ReactDOM.findDOMNode(this.refs.date));
        this.dateElement.datepicker('destroy');
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            this.dateElement.datepicker('update')
        }
        
        if(prevProps.daysOfWeekDisabled !== this.props.daysOfWeekDisabled) {
            this.dateElement.datepicker('setDaysOfWeekDisabled', this.props.daysOfWeekDisabled);
        }
        
        if(prevProps.endDate !== this.props.endDate) {
            this.dateElement.datepicker('setEndDate', this.props.endDate);
        }

    }

    render() {
        let {date, placeholder = '', readonly = false} = this.props;
        return (
            <div className="form-group datepicker-group">
                <input ref="date" type="text" placeholder={placeholder} className="form-control" value={date} disabled={readonly} onChange={()=>{}}/>
            </div>
        );
    }
  }
  export default Datepicker;
