import store from './store';

export default function AuthHeader(extraHeaders = {}) {
    let headers = new Headers(),
        user = store.get('user'),
        authHeaders = ['email', 'token', /*'id', 'data_silo_codes', */ 'client_admin'];

    if(user) {
        for(let header of authHeaders)
            headers.append(header, user[header]);
        headers.append('user_id', user.id);
        headers.append('silo_code', user.data_silo_codes);
    
        for(let header in extraHeaders)
            headers.append(header, extraHeaders[header]);
    }
    return headers;
}
