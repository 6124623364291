import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {Helmet} from 'react-helmet';
import parser from 'url-parse';

import LoginForm from '../components/forms/login'
import { userActions } from '../data/actions/user'
import Spinner from '../components/common/Spinner'
import Tooltip from '../components/common/Tooltip';

var secSvg = require('!svg-inline-loader!../../public/img/rev-logo-big.svg');
class Login extends Component {
    constructor(props) {
        super(props);
        const { query : {back = '/'} } = parser(this.props.location.search, '', true);
        this.state = {
            redirect: back
        };
        this.onSubmit = this.onSubmit.bind(this);
    }
    
    onSubmit(values){
        this.props.dispatch(userActions.login(values));
    }
    
    componentWillReceiveProps(props) {
        if(props.user.loggedIn && this.state.redirect)
            this.props.history.push(this.state.redirect);
    }

    render() {
        return (
            <div className="login-holder">
                <div className="login-form-holder">
                    <div className="login-form-container">
                        <h1 className="login-main-title">
                            <span className="login-title-svg" dangerouslySetInnerHTML={{__html: secSvg}}></span>
                            {/* <span className="login-title">SECRETLY DISTRIBUTION</span> */}
                        </h1>
                        <h3 className="login-form-title">Log in</h3>
                        <div className="login-form">
                            <LoginForm onSubmit={this.onSubmit} />
                            
                            <Spinner enabled={this.props.user.loading} />
                            <div className="link-container">
                                <a href="https://orthus.entertainment-intelligence.com/users/password/new" className="forgot-link" target="_blank">Forgot password?</a>
                                <Tooltip 
                                    position="right-center" 
                                    message={`Please be aware, this is the same login for the Entertainment Intelligence dashboard, so changing password will affect both systems.`} 
                                    tooltipClass="toolbar-title-tooltip login-tooltip" 
                                />
                            </div>
                        </div>
                        <Helmet>
                            <title>Login</title>
                        </Helmet>
                    </div>
                </div>
                <div className="login-img-holder"></div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}


export default connect(mapStateToProps)(withRouter(Login));