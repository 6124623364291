import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import DeduplicateQS from '../../helpers/DeduplicateQS';
import config from '../../config/config';

export const statsService = {
    getTopStats,
    getCatalogStats,
    getVendorStats,
    getVendorTimeseries,
    getTopTerritoryStats,
    getTopTerritoryTimeseries,
    getTerritoryStats,
    getTerritoryTimeseries,
    getTerritoryInPlaylistStats,
    getTerritoryInPlaylistTimeseries,    
    getDemographicsStats,
    getDemographicsTimeseries,
    getDevicesStats,
    getSourcesStats,
    getSourcesTimeseries,
    getTransactions,
    getTransactionsTimeseries,
    getStatementTotals
};

function getTopStats(globalFilter){
    return config.live ? getTopStatsLive(globalFilter) : getTopStatsStatic();
}

function getTopStatsLive(globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };        
    qs.query_name = 'catalogue_overview';
    // // qs.offset = 0;
       
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTopStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/trends.json`;    
    return fetch(baseUrl).then(res=>res.json());
}

function getVendorStats(params, globalFilter){
    return config.live ? getVendorStatsLive(params, globalFilter) : getVendorStatsStatic();
}

function getVendorTimeseries(params, globalFilter){
    return config.live ? getVendorTimeseriesLive(params, globalFilter) : getVendorTimeseriesStatic();
}

function getTopTerritoryStats(params, globalFilter){
    return config.live ? getTopTerritoryStatsLive(params, globalFilter) : getTopTerritoryStatsStatic();
}

function getTopTerritoryTimeseries(params, limit, globalFilter){
    return true || config.live ? getTopTerritoryTimeseriesLive(params, limit, globalFilter) : getTopTerritoryTimeseriesStatic();
}


function getTerritoryStats(params, globalFilter){
    return config.live ? getTerritoryStatsLive(params, globalFilter) : getTerritoryStatsStatic();
}

function getTerritoryTimeseries(params, globalFilter){
    return true || config.live ? getTerritoryTimeseriesLive(params, globalFilter) : getTerritoryTimeseriesStatic();
}

function getTerritoryInPlaylistStats(params, globalFilter){
    return config.live ? getTerritoryInPlaylistStatsLive(params, globalFilter) : getTerritoryInPlaylistStatsStatic();
}

function getTerritoryInPlaylistTimeseries(params, globalFilter){
    return config.live ? getTerritoryInPlaylistTimeseriesLive(params, globalFilter) : getTerritoryInPlaylistTimeseriesStatic();
}


function getDemographicsStats(params, globalFilter){
    return config.live ? getDemographicsStatsLive(params, globalFilter) : getDemographicsStatsStatic();
}

function getDemographicsTimeseries(params, globalFilter){
    return config.live ? getDemographicsTimeseriesLive(params, globalFilter) : getDemographicsTimeseriesStatic();
}


function getDevicesStats(params, globalFilter){
    return config.live ? getDevicesStatsLive(params, globalFilter) : getDevicesStatsStatic();
}

function getSourcesStats(params, globalFilter){
    return config.live ? getSourcesStatsLive(params, globalFilter) : getSourcesStatsStatic();
}

function getSourcesTimeseries(params, globalFilter){
    return config.live ? getSourcesTimeseriesLive(params, globalFilter) : getSourcesTimeseriesStatic();
}

function getVendorStatsLive(params, globalFilter) {
    let baseUrl = config.apis.secStat+'/top_vendors',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    qs.limit = 10;
    // qs.query_name = 'top_vendors';
    // qs.offset = 0;
    // qs.mode = 'main';

    let {sorted, filtered, page} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }
    
    if(page !== undefined) {
        qs.offset = qs.limit * Number(page);
    }
    
    qs = DeduplicateQS(qs);

    baseUrl += '?' + $.param(qs); 
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getVendorTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'top_vendors';
    // qs.offset = 0;
    qs.mode = 'timeseries';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }
    
    qs.row_limit = 50;
    
    qs = DeduplicateQS(qs);

    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTopTerritoryStatsLive(params, globalFilter) {
    let baseUrl = config.apis.secStat+'/top_territories',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        }        
    // qs.query_name = 'top_territories';
    // qs.offset = 0;
    
    let {sorted, limit, page} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    if(limit !== undefined){
        qs.limit = limit;
    }
    
    if(page !== undefined) {
        qs.offset = Number(qs.limit)*Number(page)
    }
    
    qs = DeduplicateQS(qs);

    baseUrl += '?' + $.param(qs);

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTopTerritoryTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.secStat+'/top_territories',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        }        
    // qs.query_name = 'top_territories';
    // qs.offset = 0;
    
    qs.mode = 'timeseries';
    let {sorted, limit = 10, page = 0, ids} = params;

    if(limit !== undefined){
        ids = ids.slice(0, limit);
    }
    qs.country_codes = ids.join(',');
    
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(page !== undefined) {
        qs.offset = Number(limit)*Number(page)
    }
    
    qs = DeduplicateQS(qs);
    
    baseUrl += '?' + $.param(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    
}


function getTerritoryStatsLive(params, globalFilter) {
    let baseUrl = config.apis.secStat+'/top_territories',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    // qs.query_name = 'top_territories';
    // qs.offset = 0;

    let {sorted, filtered, limit, page} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(limit !== undefined){
        qs.limit = limit;
    }
    
    if(page !== undefined){
        qs.offset = page * qs.limit;
    }
    
    for(let param of Object.keys(params.filtered)) {
        let value = params.filtered[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    qs = DeduplicateQS(qs);
    baseUrl += '?' + $.param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}

function getTerritoryTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.secStat+'/top_territories',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;
    qs.mode = 'timeseries';

    // qs.query_name = 'top_territories';
    // qs.offset = 0;

    let {sorted, filtered, limit = 10, page = 0, ids} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
        
    if(page !== undefined){
        qs.offset = Number(page) * Number(limit);
    }
    
    qs.country_codes = ids.slice(0, limit).join(',');
    for(let param of Object.keys(params.filtered)) {
        let value = params.filtered[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    qs = DeduplicateQS(qs);
    baseUrl += '?' + $.param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());


}

function getTerritoryInPlaylistStatsLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'top_territories_in_playlist';
    // qs.offset = 0;

    let {sorted, filtered, limit} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(limit !== undefined){
        qs.row_limit = limit;
    }
    
    for(let param of Object.keys(params.filtered)) {
        let value = params.filtered[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    
    baseUrl += '?' + $.param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}

function getTerritoryInPlaylistTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'top_territories_in_playlist';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered, limit} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(limit !== undefined){
        qs.limit = limit;
    }
    
    for(let param of Object.keys(params.filtered)) {
        let value = params.filtered[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    
    baseUrl += '?' + $.param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}



function getDemographicsStatsLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        } ;
    
    qs.query_name = 'detail_demographics';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.row_limit = 50;
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            

    baseUrl += '?' + $.param(qs);

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getDemographicsTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        } ;
    
    qs.query_name = 'detail_demographics';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.mode = 'timeseries';
    qs.row_limit = 50;
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            

    baseUrl += '?' + $.param(qs);

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getDevicesStatsLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'detail_devices';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.row_limit = 50;
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    
    baseUrl += '?' + $.param(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}

function getSourcesStatsLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'detail_sources';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.row_limit = 50;
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    
    baseUrl += '?' + $.param(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}

function getSourcesTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'detail_sources';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.mode = 'timeseries';
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    
    baseUrl += '?' + $.param(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}


function getCatalogStats(globalFilter){
    return config.live ? getCatalogStatsLive(globalFilter) : getCatalogStatsStatic();
}

function getCatalogStatsLive(globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };    
    qs.query_name = 'cat_nav_header';
       
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getCatalogStatsStatic(mode) {
    let baseUrl = `${config.apis.sales}/api/static/catalog_stats.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getVendorStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/top_vendors.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getVendorTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_vendors_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTopTerritoryStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/top_territories.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTopTerritoryTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/top_territories_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTerritoryInPlaylistStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_territories_in_playlist.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTerritoryInPlaylistTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_territories_in_playlist_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getTerritoryStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/top_territories.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTerritoryTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/top_territories_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getDemographicsStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/playlist_demographics.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getDemographicsTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/demographics_timline.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getDevicesStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/playlist_devices.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getSourcesStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/playlist_sources.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getSourcesTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sources_timeline.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTransactions(entity, id, globalFilter){
    return config.live ? getTransactionsLive(entity, id, globalFilter) : getTransactionsStatic();
}

function getTransactionsLive(entity, id, globalFilter) {
    let baseUrl = config.apis.secStat+'/transaction_types',
		qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs[entity] = id;
    qs.limit = config.fullCSVThreshold;
    qs.order_by = 'total_income';
    qs.order_dir = 'DESC';

    
    // qs.query_name = 'top_vendors';
    // // qs.offset = 0;
    // qs.mode = 'main';

    /*
    let {sorted, filtered} = params;
    if(sorted !== undefined){
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }
    
    qs.row_limit = 50;
    */
    qs = DeduplicateQS(qs);
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTransactionsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/transactions.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTransactionsTimeseries(entity, id, globalFilter){
    return config.live ? getTransactionsTimeseriesLive(entity, id, globalFilter) : getTransactionsTimeseriesStatic();
}

function getTransactionsTimeseriesLive(entity, id, globalFilter) {
    let baseUrl = config.apis.secStat+'/transaction_types',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs[entity] = id;
    qs.limit = config.fullCSVThreshold;
    qs.order_by = 'total_income';
    qs.order_dir = 'DESC';
    qs.mode = 'timeseries';

    
    qs = DeduplicateQS(qs);
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTransactionsTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/transactions_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getStatementTotals(globalFilter){
    return config.live ? getStatementTotalsLive(globalFilter) : getStatementTotalsStatic();
}

function getStatementTotalsLive(globalFilter) {
    let baseUrl = config.apis.secStat+'/statement_totals',
		qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.order_by = 'total_income';
    qs.order_dir = 'DESC';

    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getStatementTotalsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/statement_totals.json`;
    return fetch(baseUrl).then(res=>res.json());
}


