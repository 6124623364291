import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import { isEqual, maxBy, minBy } from 'lodash';

import CSV from '../../../helpers/CSVExport';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import Box from '../../common/Box';
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
import PercentageValue from '../../common/PercentageValue';

import { audienceActions } from '../../../data/actions/audience';
import { playlistSettings } from './Playlists/Settings';

const downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
const CellHeaderTable = cellHeaderHOC(ReactTable);

class TopPlaylists extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            view: 'table',
        }
        this.setChartView = this.setChartView.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }

    componentDidMount(){
        this.props.getPlaylists(this.props.id, this.props.entity);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getPlaylists(this.props.id, this.props.entity);
            }
        }
    }

    exportToCsv(){
        const filename = CSV.CSVHeader(`audience_playlists`, 'listeners', this.props.filter.global, this.props.parentEntityTitle);
        return CSV.CSVExport(this.props.audience.playlist, {filename}, `audience_playlists`);
    }


    setChartView(view){
        this.setState({view});
    }


    renderToolbar(){

        let toolbar = [];
        //for (let view in views) {
          //  toolbar.push(<a key={view}><i className={`fa fa-${views[view]} ${this.state.view == view ? 'active' : ''}`} onClick={()=>this.setChartView(view)}></i></a>);
        //}
        toolbar.push(<div><div className="ibox-icon-holder">
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div></div>);
        return toolbar;
    }

    render(){
        const { audience } = this.props;
        
        return <Box title="Top Playlists" toolbar={this.renderToolbar()} className="home-box home-box-large" spinnerEnabled={audience.playlistLoading}>
                <div className="row">
                    <div className={`col-xs-12`}>
                        {audience.playlist && <div className=""><CellHeaderTable
                            className="playlist-table"
                                data={audience.playlist}
                                columns={playlistSettings.settingsForTable()}
                                defaultPageSize={5}
                                showPagination={audience.playlist.length > 5}
                        /></div>}
                    </div>
                </div>
            </Box>
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getPlaylists: (id, entity) => {
            return dispatch(audienceActions.getTopPlaylistStats(id, entity))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TopPlaylists)
