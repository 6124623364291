import store from '../../helpers/store';
import { orderBy, isEqual } from 'lodash';
import { filterConstants } from '../constants/filter';
import { filterService } from '../services/filter';
import { filterFormatter } from '../formatters/filter';

export const filterActions = {
    setGlobalFilter,
    setEntityFilter,
    toggleGlobalFilter,
    getFilters,
    createFilter,
    removeFilter,
    updateFilter,
    getMaxDate
};

function updateFilter(id, filter){
  return dispatch => {
    dispatch(request());

    filterService.updateFilter(id, filter)
    .then(response => {
      dispatch(getFilters());
      dispatch(success(response.status))
    })
    .catch( error => dispatch(failure(error.message)));
  }

  function request(){ return { type: filterConstants.UPDATE_FILTER_REQUEST }};
  function success(payload){ return { type: filterConstants.UPDATE_FILTER_SUCCESS, payload }};
  function failure(payload){ return { type: filterConstants.UPDATE_FILTER_ERROR, payload }};
}

function getFilters(){
  return dispatch => {
    dispatch(request());

    filterService.getFilters()
    .then( response => {
      const data = orderBy(response.data, ['filter_name', 'asc']);
      dispatch(success(data));
    })
    .catch( error => {
      dispatch(failure(error.message));
    });
  }
  function request(){ return { type: filterConstants.GET_FILTERS_REQUEST }};
  function success(payload){ return { type: filterConstants.GET_FILTERS_SUCCESS, payload }};
  function failure(payload){ return { type: filterConstants.GET_FILTERS_FAILURE, payload }};
}

function createFilter(filter, filterName){
  return dispatch => {
    dispatch(request());

    filterService.createFilter(filter, filterName)
    .then( response => {
      dispatch(setGlobalFilter(filter));
      dispatch(getFilters());
      dispatch(success(response.data));
    })
    .catch( error => {
      dispatch(failure(error.message));
    });
  }

  function request(){ return { type: filterConstants.CREATE_FILTER_REQUEST }};
  function success(payload){ return { type: filterConstants.CREATE_FILTER_SUCCESS, payload }};
  function failure(error){ return { type: filterConstants.CREATE_FILTER_FAILURE, error }};
}

function removeFilter(id){
  return dispatch => {
    dispatch(request());

    filterService.removeFilter(id)
    .then( response => {
      dispatch(getFilters());
      dispatch(success(response.status));
    })
    .catch( error => {
      dispatch(failure(error.message));
    });

    function request(){ return { type: filterConstants.REMOVE_FILTER_REQUEST} };
    function success(payload){ return { type: filterConstants.REMOVE_FILTER_SUCCESS, payload } };
    function failure(payload){ return { type: filterConstants.REMOVE_FILTER_ERROR, payload } };
  }
}


function shouldRebuildTable(newFilter, oldFilter){
    return false;
    if(oldFilter==[])
        return true;

    if(isEqual(newFilter, oldFilter))
        return false;

    return (oldFilter.dateStart!=newFilter.dateStart || oldFilter.dateEnd!=newFilter.dateEnd || oldFilter.prevDateStart!=newFilter.prevDateStart || oldFilter.prevDateEnd!=newFilter.prevDateEnd);
}

function setGlobalFilter(filter) {

    return ( dispatch, getState ) => {
        const globalFilter = getState().filter.global;
        dispatch(set(filter));
        store.set('currentFilter', filter);
        
        const refreshTable = shouldRebuildTable(filter, globalFilter),
            filterThenable =  refreshTable ? filterService.buildTable(filter) : Promise.resolve();
        dispatch(request(refreshTable));
        filterThenable.then(
            data => {
                
                dispatch(success(refreshTable));
                
            },
            error => {
                dispatch(failure('error'))
            }
        );
    };

    function request(refresh) { return { type: filterConstants.BUILD_TABLE_REQUEST, refresh } }
    function success(refresh) { return { type: filterConstants.BUILD_TABLE_SUCCESS, refresh } }
    function set(filter) { return { type: filterConstants.SET_ENTITY_FILTER, entity: 'global', filter } }
    function failure(error) { return { type: filterConstants.BUILD_TABLE_FAILURE, error } }

}

function setEntityFilter(entity, filter) {
    return dispatch => {
        filter = filterFormatter.formatEntityFilter(filter, entity);
        dispatch(set(entity, filter));
        return true;
    };

    function set(entity, filter) { return { type: filterConstants.SET_ENTITY_FILTER, entity, filter } }
}

function toggleGlobalFilter() {
    return dispatch => {
        dispatch(set());
    };

    function set() { return { type: filterConstants.TOGGLE_GLOBAL_FILTER} }
}


function getMaxDate(useCache=true) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        const maxDate = useCache ? getState().filter.maxDate : undefined;
        if(maxDate) {
            dispatch(success(maxDate));
            return maxDate;
        }
        return filterService.getMaxDate()
            .then(
                ({ result }) => {
                	const date = filterFormatter.formatMaxDate(result);
                    dispatch(success(date));
                    return date;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: filterConstants.GET_MAX_DATE_REQUEST } }
    function success(date) { return { type: filterConstants.GET_MAX_DATE_SUCCESS, date } }
    function failure(error) { return { type: filterConstants.GET_MAX_DATE_FAILURE, error } }
}
