import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual, map } from 'lodash';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import CSV from '../../../helpers/CSVExport';
import { milestonesActions } from '../../../data/actions/milestones';
import { milestonesSettings } from './Milestones/Settings'
import AddMilestoneForm from '../../forms/addMilestone'
import AddChannelToCampaignForm from '../../forms/addChannelToCampaign'
import MilestoneChart from './Milestones/Chart';
import LinkedEntities from './Milestones/LinkedEntities';

import Box from '../../common/Box';
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class Milestones extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showNewMilestoneModal: false,
            showEditMilestoneModal: false,
            showAddChannelModal: false,
            mode: 'table',
            milestoneType: undefined,
            currentMilestone: undefined
        }
        
        this.showModal = this.showModal.bind(this);
        this.addMilestone = this.addMilestone.bind(this);
        this.editMilestone = this.editMilestone.bind(this);
        this.deleteMilestone = this.deleteMilestone.bind(this);
        this.shareMilestone = this.shareMilestone.bind(this);
        this.shareAllMilestones = this.shareAllMilestones.bind(this);
        this.refresh = this.refresh.bind(this);
        this.setCurrentMilestone = this.setCurrentMilestone.bind(this);
        this.setChartMode = this.setChartMode.bind(this);
        this.setMilestoneType = this.setMilestoneType.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }
        
    showModal(modal, show){
        this.setState({
            [modal]: show
        })
    }
    
    setChartMode(mode){
        this.setState({mode});
    }
    
    setMilestoneType(milestoneType){
        this.setState({milestoneType});
    }

    addMilestone(formData) {
        this.props.dispatch(milestonesActions.addMilestone(null, formData))
        .then(({milestone}) => this.props.dispatch(milestonesActions.linkAudienceMilestone(this.props.entity, this.props.id, milestone.id)))
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id)));
        this.showModal('showNewMilestoneModal', false);
    }
    
    editMilestone(formData) {
        this.props.dispatch(milestonesActions.editMilestone(null, formData, this.state.currentMilestone.id))
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id)));
        this.showModal('showEditMilestoneModal', false);
    }
    
    shareMilestone(shared, id) {
        const formData = {'public': !Boolean(shared)};
        this.props.dispatch(milestonesActions.editMilestone(null, formData, id, false))
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id)));
    }
    
    shareAllMilestones(allShared) {
        const milestones = this.props.milestones.entities[this.props.entity];
        if(!milestones || !milestones.tableItems)
            return;
        //.filter(item => !item.public)
        const ids = map(milestones.tableItems, 'id');
        const sharePromises = map(ids, (id)=>this.props.dispatch(milestonesActions.editMilestone(null, {'public': allShared}, id, false)));
        Promise.all(sharePromises)
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id)));
        this.showModal('showEditMilestoneModal', false);
    }


    deleteMilestone(milestoneID) {
        this.props.dispatch(milestonesActions.deleteMilestone(milestoneID))
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id)));
    }
    
    refresh(){
        this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id))
        this.showModal('showAddChannelModal', false);
    }
    
    exportToCsv(){
        const milestones = this.props.milestones.entities[this.props.entity],
            { tableItems } = milestones,
            typeLabel = this.state.milestoneType ? this.state.milestoneType.label : 'all',
            filename = CSV.CSVHeader('entity milestones', typeLabel, this.props.filter.global, this.props.parentEntityTitle);

        return CSV.CSVExport(this.filterMilestoneTypes(tableItems), {filename}, 'entity_milestones');
    }

    setCurrentMilestone(currentMilestone, showModal) {
        this.setState({currentMilestone}, ()=>this.showModal(showModal, true));
    }
    
    componentDidMount() {
        this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id))
        this.props.dispatch(milestonesActions.getMilestoneTypes())
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id))
            }                
        }
        
        if(nextProps.id && nextProps.id !== this.props.id){
            this.props.dispatch(milestonesActions.getEntityMilestones(nextProps.entity, nextProps.id))
        }
    }
    
    filterMilestoneTypes(items){
        const { milestoneType } = this.state;
        if(milestoneType && milestoneType.value) {
            items = items.filter(item=>item.milestone_type_id == milestoneType.value)
        }
        return items;
    }
    
    renderShareAll() {
        const milestones = this.props.milestones.entities[this.props.entity];
        if(!milestones || !milestones.tableItems) 
            return null;
        
        
        let allShared = true;
        for(let milestone of milestones.tableItems) {
            if(!milestone.public) {
                allShared = false;
                break;
            }        
        }
        
        let buttonLabel,
            buttonClass;
        
        if(allShared) {
            buttonLabel = 'Stop Sharing All Milestones';
            buttonClass = 'fa-check-square';
        }
        else {
            buttonLabel = 'Share All Milestones';
            buttonClass = 'fa-square';
        }
            
        
        return <div className="small-title-holder small-title-holder--right">
            <button title={buttonLabel} onClick={()=>this.shareAllMilestones(!allShared)} className="btn share-all-milestones"><i className={`far ${buttonClass}`}></i></button>
            <h3 className="title">{buttonLabel}</h3>
        </div>
        
    }
    


    renderToolbar(types){
        let toolbar = [];
    
        types = [{
            label: 'All',
            value: null
        }].concat(types);
    
        toolbar.push(<div key="wrapper" className="ibox-action-holder">

            <div className="ibox-actions">
                <div key="sort" className="select-holder"><span className="select-label">Milestone Type </span>
                    <ReactSelect value={this.state.milestoneType || types[0]} options={types} onChange={this.setMilestoneType} className="single-select"/>
                </div>
            </div>
            <div className="ibox-actions">
                <a key="download" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            </div>            
        </div>)
        return toolbar;
    }    

    render (){

        const renderTable = (items, loading) => {
            return <ReactTable
                data={items}
                loading={loading}
                className='milestone-table'
                columns={milestonesSettings.settingsForTable(this.setCurrentMilestone, this.deleteMilestone, this.shareMilestone)}
                showPagination={false}
                pageSize={(items && items.length ) ? items.length : 10}
                SubComponent={row => <LinkedEntities row={row} /> }
            />

        }

        const milestones = this.props.milestones.entities[this.props.entity];
        if(!milestones)
            return null;
        
        const { items, tableItems, loading } = milestones,
            {types} = this.props.milestones; 

        return <Box title="Milestones" toolbar={this.renderToolbar(types)}>
            {types && <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <div className="small-title-holder">
                            <h3 className="title">Add Milestone</h3>
                            <button title="Add Milestone" onClick={()=>this.showModal('showNewMilestoneModal', true)} className="btn add-to-filter"><i className="fas fa-plus"></i></button>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        {this.renderShareAll()}
                    </div>
                </div>
                
                <AddMilestoneForm show={this.state.showNewMilestoneModal} title="Create Milestone" onSubmit={this.addMilestone} onClose={(e)=>this.showModal('showNewMilestoneModal',false)} milestoneTypes={types} />
                <AddMilestoneForm show={this.state.showEditMilestoneModal} title="Edit Milestone" onSubmit={this.editMilestone} onClose={(e)=>this.showModal('showEditMilestoneModal',false)} milestoneTypes={types} values={this.state.currentMilestone} />
            </div>}
            {this.state.mode=='table' && renderTable(this.filterMilestoneTypes(tableItems), loading)}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        milestones: state.milestones,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(Milestones);