import React from 'react';
import { playlistsFormatter } from  '../../../data/formatters/playlists';
import PieChart from '../../widgets/charts/types/PieChart';
import StackedBarChart from '../../widgets/charts/types/StackedBarChart';

var chartArrowUpSvg = require('!svg-inline-loader!../../../../public/img/chart-up-arrow.svg');

class Benchmarks extends React.Component {
    
    render (){
        if(!this.props.data)
            return null;
        
        const data = playlistsFormatter.formatBenchmarks(this.props.data);
        
        return <div className="benchmark-holder">
            <div className="modal fade" id="modal-benchmarks" tabIndex="-1" role="dialog" aria-labelledby="Benchmarks">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            Benchmarks
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-chart-holder">
                                <div className="modal-chart-item">
                                    <PieChart data={data.devices} />
                                </div>
                                <div  className="modal-chart-item">
                                    <PieChart data={data.territories} />
                                </div>        
                                <div  className="modal-chart-item">
                                    <StackedBarChart data={data.demographics} />
                                </div>                        
                            </div>                    
                        </div>                  
                    </div>
                </div>
            </div>
            <button type="button" data-toggle="modal" data-target="#modal-benchmarks" className="default-btn button-icon">
                benchmarks
                <span className="chart-icon chart-icon--small" dangerouslySetInnerHTML={{__html: chartArrowUpSvg}}></span>
            </button>
        </div>;
    }
}

export default Benchmarks;