import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { merge, map } from 'lodash';
import multiselect from 'bootstrap-multiselect';

class Multiselect extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.select = $(ReactDOM.findDOMNode(this.refs.select));
        this.select.multiselect(merge(this.defaultOptions, this.props.options));
    }

    componentWillUnmount(){
        this.select.multiselect('destroy');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selected !== this.props.selected) {
          this.select.multiselect('refresh');
        }
    }    
    
    render() {
        let {input: { name, onChange, value, label, className, ...restInput }, meta, children, ...rest} = this.props;
        return (
            <select className={`form-control ${className}`} name={name} ref="select" value={value} data-placeholder={label} onChange={onChange} {...rest} >
            {children}
            </select>
        );
    }
}

 export default Multiselect;