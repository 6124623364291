import React from 'react';
export default function EntitiesList(props){
    const { entities = [], type, onDelete, pageFilterObjects } = props;
    if(!entities.length)
        return null;
    
    const renderFilterIcon = (type) => {
        const searchCriteria = (f) =>
            f.type === type || `exclude_${f.type}` === type;
        const currentType = pageFilterObjects.find(searchCriteria);
        const icon = currentType ? currentType.icon : "";
        
        return <span className={`page-filter-icon ${currentType.type}`} dangerouslySetInnerHTML={{__html: icon}} />
    }

    return <div key={`${type}`} className={`page-filter ${type}`}>
        <div className="tag-list">
            <div className="page-filter-item">
                {renderFilterIcon(type)}
                <div className="page-filter-list">
                    {entities.map(entity=><div key={entity.entity_id} className="tag">
                        <div className="tag-name-holder">
                            <div className="tag-name">
                                <span>{entity.name}</span>
                            </div>
                            <a
                                className="close-link"
                                onClick={()=>onDelete(entity.id, type)}
                            >
                                &times;
                            </a>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </div>;
}
