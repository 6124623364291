import React from 'react';
import Table from '../../../common/PDF/Table';
import { splitByThousands, addPercent } from '../../../../helpers/NumberFormatter';

export default ({data})=>{
    const columns=[
        {key: 'customer', title: 'Vendor', width: 25, fontSize: 5},                   
        {key: 'source', title: 'source', width: 25, fontSize: 5},
        {key: 'income', title: 'Income', width: 25, fontSize: 5, formatFn: splitByThousands},
        {key: 'quantity', title: 'Qty', width: 25, fontSize: 5, formatFn: splitByThousands},
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}