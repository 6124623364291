import { Chart } from 'chart.js';
import { merge } from 'lodash';
import { defaults } from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes';
import { formatAxesLabel, formatTooltipLabel, formatTooltipLabelPie, formatTooltipLabelDoughnut } from './Formatter';
import datalabelsPlugin from 'chartjs-plugin-datalabels'
import { splitByThousands, convertToThousands} from '../../../helpers/NumberFormatter';

Chart.pluginService.register(datalabelsPlugin);

export const colors = ['#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6', '#DD4477', '#E67300', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11', '#6633CC', '#66AA00', '#8B0707', '#329262', '#5574A6', '#3B3EAC'];

// chart.js globals
// defaults.global.animation = false;
defaults.global.plugins = {
    colorschemes: {
        scheme: colors,
        fillAlpha: 0.8,
        reverse: false,
        override: false         
    },
    datalabels: {
        // display: false
    }
};



// chart type settings

const tooltips = {
    callbacks: {
        label: formatTooltipLabel
    }    
}

function newLegendHandler(e, legendItem) {
    var index = legendItem.datasetIndex;
    var ci = this.chart;
    
    var datasets = ci.data.datasets;
    var visibleCount = ci.getVisibleDatasetCount();

    if (e.shiftKey) {
        var currentHiddenVal = ci.getDatasetMeta(index).hidden;

        datasets.forEach(function(el, i) {
            var meta = ci.getDatasetMeta(i);
            var hidden = null;

            if (visibleCount === 1) {
                if (i === index && currentHiddenVal === true) {
                    hidden = null
                } else if (i !== index && currentHiddenVal === true) {
                    hidden = true;
                } else {
                    hidden = null;
                }
            } else {
                if (i !== index) {
                    hidden = true;
                }
            }

            meta.hidden = hidden;
        });
    } else {
        var meta = ci.getDatasetMeta(index);
        
        meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
    }

    ci.update();
}

export const pie = {
    "maintainAspectRatio": false,
    // "responsive": true,
    "legend": {
        "position": "top",
        "labels": {
            "usePointStyle": true,
        },
    },
    tooltips: {
        callbacks: {
            label: formatTooltipLabelPie
        }    
    },
    plugins: {
        datalabels: {
            display: false
        }
    }
};

export const doughnut = {
    "maintainAspectRatio": false,
    // "responsive": true,
    "legend": {
        "display": false,
        "position": "top",
        "labels": {
            "usePointStyle": true,
        },
    },
    tooltips: {
        callbacks: {
            label: formatTooltipLabelDoughnut
        }    
    },
    plugins: {
      datalabels: {
        display: false,
        align: 'bottom',
        backgroundColor: '#ccc',
        borderRadius: 3,
        font: {
          size: 18,
        }
      },
    }
};

export const bar = {
    // "responsive": true,
    "maintainAspectRatio": false,
    "cornerRadius": 20,
    "legend": {
        //"display": false,
        "position": "top",
        "labels": {
            "usePointStyle": true,
        },
        onClick: newLegendHandler
    },
    tooltips,
    "scales": {
        "xAxes": [{
            "ticks": {
                callback: function formatAxesLabel(value, index, values){
                    return value;
                }
            }
        }],
        "yAxes": [{
            "ticks": {
                callback: formatAxesLabel
            }
        }]            
    },
    // plugins: {
    //     datalabels: {
    //         display: false
    //     }
    // }
};

export const stackedBar = merge({}, bar, {
    "maintainAspectRatio": false,
    "maxBarThickness": 40,
    "layout": {
        padding: {
          left: 10,
          right: 10,
          top: 0,
          bottom: 0
        }
      },
    "legend": {
        "display": true,
        "position": "top",
        "align": "left",
        "labels": {
          "usePointStyle": true,
          "padding": 10
        },
        onClick: newLegendHandler
    },    
    "scales": {
        "xAxes": [{
            "stacked": true
        }],
        "yAxes": [{
            "stacked": true
        }]            
    },
    plugins: {
        datalabels: {
          align: 'top',
					anchor: 'end',
          borderRadius: 3,
          font: {
            size: 12,
						weight: 700
          },
          formatter: (value, context) => {
						const datasetArray = [];
						context.chart.data.datasets.forEach((dataset) => {
							if (dataset.data[context.dataIndex] != undefined) {
								datasetArray.push(parseInt(dataset.data[context.dataIndex]));
							}
						})

						function totalSum(total, datapoint) {
							return total + datapoint;
						}
						
						let sum = datasetArray.reduce(totalSum, 0);
						
						if (context.datasetIndex === datasetArray.length - 1) {
							return convertToThousands(sum)
						} else {
							return ''
						}
          }
        },
      }
});

export const line =  {
    // "responsive": true,
    "maintainAspectRatio": false,
    "layout": {
        padding: {
          left: 10,
          right: 10,
          top: 0,
          bottom: 0
        }
      },
    tooltips,
    "scales": {
        "yAxes": [{
            "ticks": {
                callback: function formatAxesLabel(value, index, values){
                    return splitByThousands(value);
                }
            }
        }]            
    },
    "legend": {
        "position": "top",
        "labels": {
            "usePointStyle": true,
        },
        onClick: newLegendHandler
    },
    plugins: {
        datalabels: {
            display: false
        }
    }
}; 