import React, { Component } from 'react';
import Modal from './Modal';
import Spinner from './Spinner';

var downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');

class ExportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
        this.showModal = this.showModal.bind(this);
    }
    
    showModal(show) {
        this.setState({
            showModal: show !== undefined ? show: !this.state.showModal
        })
    }

    render() {
        const { exportToCsv, loading = false } = this.props;
        return <span>
            <Modal show={this.state.showModal} 
                title="Export Data" 
                showActionButtons={false}
                dialogClassName="confirm-modal-holder dialog-modal"
                handleClose={()=>this.showModal(false)}
            >
                <p className="export-modal">
                    <span className="popup-confirm-text">These reports are for analytical purposes only and should not be used to pay artist royalties</span>
                </p>
                <p className="export-modal">
                    <a className="export-link" onClick={()=>exportToCsv(false)}>Export Current</a>
                    <a className="export-link" onClick={()=>exportToCsv(true)}>Export Full</a>
                    <Spinner enabled={loading} />
                </p>
            </Modal>
            <a key="download" title="Export" onClick={()=>this.showModal(true)} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </span>
    }
}
export default ExportModal;