import $ from 'jquery';
import {merge} from 'lodash';
import AuthHeader from '../../helpers/AuthHeader';
import config from '../../config/config';

export const searchService = {
    autocomplete
};

function _autocomplete(search_input, search_types) {
    if(typeof search_types === 'string')
        search_types = [search_types];
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.v2_sales}/search`,
        requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify({search_input, search_types: search_types.join(',')})
        };

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function autocomplete(search_input, search_types, force) {
    return true || config.live ? autocompleteLive(search_input, search_types, force) : autocompleteStatic();
}

function autocompleteLive(search_input, search_types, force) {
    
//    if(typeof search_types === 'array')
	let entities = Object.assign([], search_types);
	if(entities.includes('projects') && !entities.includes('products'))
		entities.push('products');
	
	entities = entities.join(',');
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.miniCat}/v2/search?search_input=${encodeURIComponent(search_input)}&search_types=${encodeURIComponent(entities)}&source=statements${force?'&force=true':''}`,
        requestOptions = {
            method: 'GET',
            headers,
//            body: JSON.stringify({search_input, search_types: search_types.join(',')})
        };

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function autocompleteStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/search_sec.json`;
    return fetch(baseUrl)
    .then(res=>{   
        return res.json()
    });
}
