import countriesByAbbreviation from '../../../common/CountriesByAbbreviation';
import countriesByContinent from 'country-json/src/country-by-continent.json';
import { groupBy, orderBy, find, filter } from 'lodash';

const groupedCountries = filter(countriesByAbbreviation, (c)=>{return c.abbreviation}).map((country)=>{
    const continent = find(countriesByContinent, {country: country.country});
    if(continent)
      country.continent = continent.continent;
    else
      country.continent = 'Unknown';
    return country;
})

export default groupBy(orderBy(groupedCountries.map((country)=>({value: country.abbreviation, label: country.country, region: country.continent})), ['region', 'name'], ['asc', 'asc']), 'region');
// export default orderBy(countries.map((country)=>({value: country.cca2, label: country.name.common})), 'label', 'asc');