import React from 'react';
import {Helmet} from 'react-helmet';
import Progress from '../common/Progress';
import Navigation from '../common/Navigation';
import Footer from '../common/Footer';
import TopHeader from '../common/TopHeader';
import ErrorBox from '../common/ErrorBox';
import WhiteLabelCss from '../common/WhiteLabelCss';
import Filter from '../widgets/filter/Filter';
import ChatWidget from '../widgets/chat/ChatWidget';
import UserTablesLoader from '../widgets/userTables/UserTablesLoader';
import MainRoutes from '../../config/routes/main';
import WithPermission from '../../helpers/WithPermission';
import withSiloCode from '../../helpers/WithSiloCode';
import SearchWidget from '../pages/home/SearchWidget';
import { withRouter } from 'react-router';

import { correctHeight, detectBody } from './Helpers';

class Main extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            modalExpanded: false
        }
    } 
    

    searchResult = (item) => {
        this.props.history.push(`/${item.entity}/${item.code}/${item.name}`);
    }
    
    onExpand = (modalExpanded) => {
        this.setState({modalExpanded});        
    }

    
    render() {
        const route = this.props.location.pathname,
            wrapperClass = "light-blue-bg " + route + (this.state.modalExpanded ? ' search-modal-expanded' : '');
        
        return (
            <div id="wrapper" className={this.props.silo}>
                <Progress />
                <ErrorBox />
                <WhiteLabelCss silo={this.props.silo} />
                {/*<Navigation location={this.props.location}/>*/}
                <Helmet defaultTitle="Statements" titleTemplate="%s - Statements" /> 
                <div id="page-wrapper" className={`page-wrapper`}>
                    <TopHeader>
                        <div className="main-search-holder">
                            <SearchWidget searchResult={this.searchResult} placeholder="Type to search the dashboard" onExpand={this.onExpand} />
                        </div>
                    </TopHeader>
                    {/* <Filter /> */}
                    <UserTablesLoader route={route} >
                        <MainRoutes />
                        {/*<WithPermission permission="allow_support">*/}
                            <ChatWidget />
                        {/*</WithPermission>*/}
                    </UserTablesLoader>
                    <Footer />
                </div>

            </div>
        )
    }

    componentDidMount() {

        // Run correctHeight function on load and resize window event
        $(window).bind("load resize", function() {
            correctHeight();
            detectBody();
        });

        // Correct height of wrapper after metisMenu animation.
        $('.metismenu a').click(() => {
            setTimeout(() => {
                correctHeight();
            }, 300)
        });
    }
}

export default withSiloCode(withRouter(Main))
