import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { debounce, pull, isEqual, get } from 'lodash';
import { Dropdown } from 'react-bootstrap';
import Box from '../../common/Box';
import FilterSaveLoad from '../../widgets/filter/FilterSaveLoad';

import store from '../../../helpers/store';

import { filterActions } from '../../../data/actions/filter';
import { imprintsActions } from '../../../data/actions/imprints';
import { artistsActions } from '../../../data/actions/artists';
import { productsActions } from '../../../data/actions/products';
import { tracksActions } from '../../../data/actions/tracks';
import icons from '../../../helpers/Icons';
import Spinner from '../../common/Spinner';

import { Modal as RBModal } from 'react-bootstrap';

class TrackSelector extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            search: '',
            autocomplete: [],
            showAutocomplete: false,
            expanded: true,
            modalId: `autocomplete${this.props.entityIndex}`,
            showSeachModal: false
        }

        this.setResult = this.setResult.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.setFilterSearch = debounce(this.setFilterSearch.bind(this), 1000);
        this.collapseAutocomplete = this.collapseAutocomplete.bind(this);
    }

    componentWillReceiveProps(nextProps){
        const entity = 'tracks';
        if(nextProps[entity] && nextProps[entity].autocomplete_items){
            this.setState({
                autocomplete: nextProps[entity].autocomplete_items,
                autocompleteNotFound: (nextProps[entity].loading === false && nextProps[entity].autocomplete_items.length === 0)
            })
        }
    }

    setSearch(e){
        const value = e.target.value;
        this.setState({
            autocomplete: [],
            search: value,
            showAutocomplete: true
        })
        this.setFilterSearch(value)
    }

    setFilterSearch(value){
        this.props.getAutocomplete(value);
    }

    formatFilterItem = (item) => {
      const { logo, ...rest } = item;
      return rest;
    }

    setResult(e, item){
        e.preventDefault();
        this.props.setResult(item);
        this.showModal('showSeachModal', false);
    }
    collapseAutocomplete(){
        this.setState({
            showAutocomplete: false
        })
    }

    toggleExpand() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    showModal = (modal, show) => {
      this.setState({
          [modal]: show
      })
    }

    render() {
        const currentValue = this.props.value,
            valueText = (currentValue && currentValue.name_result) ? currentValue.name_result : 'No Track Selected',
            valueName = valueText.split('released on'),
            weekDayWarning = currentValue ? currentValue.week_day_warning : '';
        
        const autocompleteLoading = this.props.tracks.autoCompleteLoading,
            autocompleteNotFound = !autocompleteLoading && this.state.autocompleteNotFound;
        return (
                <div className={this.props.rootClassName} >
                      <RBModal show={this.state.showSeachModal} style={{opacity:1}} onHide={()=>this.showModal('showSeachModal', false)} className="page-filter-dialog">
                        <RBModal.Body>
                        <form role="form" className="form-inline">
                          <div className="form-group page-filter-input">
                            <input className="form-control" type="text" value={this.state.search} onChange={this.setSearch} placeholder={`Search for track`} />
                            <Spinner className="spinner filter-spinner" enabled={autocompleteLoading} />
                          </div>
                        </form>
                          <ul className="autocomplete">
                              {this.state.autocomplete.map(item => {
                                  return (<li eventKey={item.id} className="autocomplete-item">
                                      <a href="#" onClick={(e)=>this.setResult(e, item)} className="logo-container">
                                          <span className="chart-icon logo" dangerouslySetInnerHTML={{__html: item.logo}}></span>
                                          <span>{item.name}</span>
                                      </a>
                                  </li>)
                              })}
                              {autocompleteNotFound === true &&  <li className="autocomplete-item">
                                  <span>No tracks found</span>
                              </li>}
                          </ul>
                        </RBModal.Body>
                      </RBModal>


                      <div className="tag-list track-tag-list">
                        <p>
                            {valueName[0]}
                            {valueName[1] && <span className="newline">Released on{valueName[1]}</span>} 
                            {weekDayWarning && <p className="week-day-warning"><i className="fa fa-exclamation-triangle" /> {weekDayWarning}</p>}
                            <button type="button" className="btn add-to-filter" onClick={()=>this.showModal('showSeachModal', true)} title={`Add to filter`}><i class="fas fa-search"></i></button>
                        </p>

                          
                          
                      </div>
                </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getAutocomplete: (value) => { dispatch(tracksActions.getTracksAutocomplete(value)) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TrackSelector)
