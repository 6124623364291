export const whiteLabelFormatter = {
    formatForForm
};

function formatForForm(data){
    let files = ['header_logo', 'email_logo', 'home_page_background', 'login_page_background'],
        { properties } = data;

    for(let file of files) {
        properties[`${file}_source`] = properties[file];
        properties[file] = '';
    }

    return data;
}
