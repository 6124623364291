import { commonConstants } from '../constants/common';
import { searchService } from '../services/search';

export const commonActions = {
  getAutocomplete
};

function getAutocomplete(value, entity, limit=10) {
    return dispatch => {
      dispatch(request());
      if(!value)
        return dispatch(success([]));
      return searchService.autocomplete(value, entity)
      .then(response => {
        dispatch(success(response.results.hits));
      })
      .catch(error => {
        dispatch(failure(error.message))
      })
    };

    function request() { return { type: commonConstants.GET_AUTOCOMPLETE_REQUEST}}
    function success( payload ) { return { type: commonConstants.GET_AUTOCOMPLETE_SUCCESS, payload}}
    function failure( payload ) { return { type: commonConstants.GET_AUTOCOMPLETE_FAILURE, error: payload}}
}
