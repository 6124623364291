export const artistsConstants = {
    GET_ARTISTS_REQUEST: 'GET_ARTISTS_REQUEST',
    GET_ARTISTS_SUCCESS: 'GET_ARTISTS_SUCCESS',
    GET_ARTISTS_FAILURE: 'GET_ARTISTS_FAILURE',
    GET_ARTISTS_AUTOCOMPLETE_REQUEST: 'GET_ARTISTS_AUTOCOMPLETE_REQUEST',
    GET_ARTISTS_AUTOCOMPLETE_SUCCESS: 'GET_ARTISTS_AUTOCOMPLETE_SUCCESS',
    GET_ARTISTS_AUTOCOMPLETE_FAILURE: 'GET_ARTISTS_AUTOCOMPLETE_FAILURE',
    GET_TOP_ARTISTS_REQUEST: 'GET_TOP_ARTISTS_REQUEST',
    GET_TOP_ARTISTS_SUCCESS: 'GET_TOP_ARTISTS_SUCCESS',
    GET_TOP_ARTISTS_FAILURE: 'GET_TOP_ARTISTS_FAILURE',
    GET_ARTIST_DETAILS_REQUEST: 'GET_ARTIST_DETAILS_REQUEST',
    GET_ARTIST_DETAILS_SUCCESS: 'GET_ARTIST_DETAILS_SUCCESS',
    GET_ARTIST_DETAILS_FAILURE: 'GET_ARTIST_DETAILS_FAILURE',    
    GET_ARTISTS_CARD_REQUEST: 'GET_ARTISTS_CARD_REQUEST',
    GET_ARTISTS_CARD_SUCCESS: 'GET_ARTISTS_CARD_SUCCESS',
    GET_ARTISTS_CARD_FAILURE: 'GET_ARTISTS_CARD_FAILURE',
    GET_STREAMS_STATS_REQUEST: 'GET_STREAMS_STATS_REQUEST',
    GET_STREAMS_STATS_SUCCESS: 'GET_STREAMS_STATS_SUCCESS',
    GET_STREAMS_STATS_FAILURE: 'GET_STREAMS_STATS_FAILURE',
    GET_ARTIST_PROFILE_REQUEST: 'GET_ARTIST_PROFILE_REQUEST',
    GET_ARTIST_PROFILE_SUCCESS: 'GET_ARTIST_PROFILE_SUCCESS',
    GET_ARTIST_PROFILE_FAILURE: 'GET_ARTIST_PROFILE_FAILURE',    
    GET_ARTIST_FOLLOWERS_REQUEST: 'GET_ARTIST_FOLLOWERS_REQUEST',
    GET_ARTIST_FOLLOWERS_SUCCESS: 'GET_ARTIST_FOLLOWERS_SUCCESS',
    GET_ARTIST_FOLLOWERS_FAILURE: 'GET_ARTIST_FOLLOWERS_FAILURE',    
    GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
    GET_TIMESERIES_ARTISTS_REQUEST: 'GET_TIMESERIES_ARTISTS_REQUEST',
    GET_TIMESERIES_ARTISTS_SUCCESS: 'GET_TIMESERIES_ARTISTS_SUCCESS',
    GET_TIMESERIES_ARTISTS_FAILURE: 'GET_TIMESERIES_ARTISTS_FAILURE',
    FORCE_LOAD_ALL_DATA: 'FORCE_LOAD_ALL_DATA_ARTISTS',

}