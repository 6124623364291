import { playlistsConstants } from '../constants/playlists';
import { playlistsService } from '../services/playlists';
import { tracksService } from '../services/tracks';
import { playlistsFormatter } from '../formatters/playlists';
import { find, map, concat, chunk, flatten } from 'lodash';

export const playlistsActions = {
    getPlaylists,
    getTopPlaylists,
    getPlaylistTimeline,
    getPlaylistDetails,
    getPlaylistStats,
    getMetadata,
    getTimeseriesPlaylists,
    getPlaylistShare,
    getPlaylistTypesSources
};

function getPlaylists(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        Promise.all([
            playlistsService.getPlaylists(params, globalFilter, 'main'),
            // playlistsService.getPlaylists(params, globalFilter, 'total')
        ])
            .then(
                res => {
                    let playlists = res[0];
                        // total = res[1];
                    
                    const data = playlistsFormatter.formatForTable(playlists.data);
                    dispatch(success(data, playlists.total));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLISTS_REQUEST } }
    function success(playlists, total) { return { type: playlistsConstants.GET_PLAYLISTS_SUCCESS, playlists, total } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLISTS_FAILURE, error } }
}


function getTopPlaylists(params, entity=null) {
    return ( dispatch, getState ) => {
        dispatch(request(entity));
        const globalFilter = getState().filter.global;
        playlistsService.getTopPlaylists(params, globalFilter)
            .then(
                playlists => {
                    const data = playlistsFormatter.formatTop(playlists);
                    //dispatch(success(data, entity));
                    return dispatch(getMetadata(data));
                },
                error => {
                    dispatch(failure('error', entity))
                }
            );
    };

    function request(entity) { return { type: playlistsConstants.GET_TOP_PLAYLISTS_REQUEST, entity } }
    function success(playlists, entity) { return { type: playlistsConstants.GET_TOP_PLAYLISTS_SUCCESS, playlists, entity } }
    function failure(error, entity) { return { type: playlistsConstants.GET_TOP_PLAYLISTS_FAILURE, error, entity } }
}


function getPlaylistTimeline(id) {
    return ( dispatch, getState ) => {
        dispatch(request());

        const playlists = getState().playlists.top,
            playlist = find(playlists, {playlist_id: id});
        return Promise.resolve(playlist)

            .then(
                playlist => {
                    const data = playlistsFormatter.formatTimeline(playlist.stms_by_date);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_TIMELINE_REQUEST } }
    function success(id, playlist) { return { type: playlistsConstants.GET_PLAYLIST_TIMELINE_SUCCESS, id, playlist } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_TIMELINE_FAILURE, error } }
}

function getTracks(ids, filter) {
    return tracksService.getTopTracks({filtered:[{
        id: 'isrcs',
        value: ids
    }]}, filter);
}

function getPlaylistDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        playlistsService.getPlaylistStats(id, globalFilter)
            .then(
                playlist => {
                    const chunkSize = 50,
                        ids = chunk(concat(map(playlist.current_tracks, 'isrc'), map(playlist.removed_tracks, 'isrc')), chunkSize),
                        filter = {...globalFilter, limit: playlist.current_tracks.length};
                    
                    Promise.all(ids.map(chunk=>getTracks(chunk, filter)))
                    .then(allTracks => {
                        const { data, benchmarks } = playlistsFormatter.formatDetails(playlist, flatten(allTracks));                        
                        dispatch(success(id, data, benchmarks));    
                    })
                    
                    
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_DETAILS_REQUEST } }
    function success(id, playlist, benchmarks) { return { type: playlistsConstants.GET_PLAYLIST_DETAILS_SUCCESS, id, playlist, benchmarks } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_DETAILS_FAILURE, error } }
}


function getPlaylistStats(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        playlistsService.getPlaylistStats(id, globalFilter)
            .then(
                playlist => {
                    const data = playlistsFormatter.formatStats(playlist);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_STATS_REQUEST } }
    function success(id, playlist) { return { type: playlistsConstants.GET_PLAYLIST_STATS_SUCCESS, id, playlist } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_STATS_FAILURE, error } }
}

function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.map(entity=>entity.id);
        playlistsService.getMetadata(ids)
            .then(
                metadata => {
                    entities = playlistsFormatter.formatMetadata(entities, metadata.playlists);
                    dispatch(success(entities, metadata.playlists));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_TOP_PLAYLISTS_REQUEST } }
    function success(playlists, metadata) { return { type: playlistsConstants.GET_TOP_PLAYLISTS_SUCCESS, playlists, metadata } }
    function failure(error) { return { type: playlistsConstants.GET_TOP_PLAYLISTS_FAILURE, error } }

}

function getTimeseriesPlaylists(params, useCache) {
    console.log(params);
    return ( dispatch, getState ) => {
        if(getState().playlists.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;
        let ids = [];
        for(let param of params.filtered) {
            if(param.id=='ids') {
                ids = param.value;
            }    
        }
        
        return Promise.all([
                playlistsService.getTimeseriesPlaylists(params, globalFilter),
                playlistsService.getMetadata(ids)
            ])
            .then(
                results => {
                    const playlists = results[0]
                    const metadata = results[1].playlists;
                    
                    const data = playlistsFormatter.formatStreamsStats(playlists, metadata);
                    dispatch(success(data));
                    return playlists;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_TIMESERIES_PLAYLISTS_REQUEST } }
    function success(playlists) { return { type: playlistsConstants.GET_TIMESERIES_PLAYLISTS_SUCCESS, playlists } }
    function failure(error) { return { type: playlistsConstants.GET_TIMESERIES_PLAYLISTS_FAILURE, error } }
}

function getPlaylistShare(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        playlistsService.getPlaylistShare(id, globalFilter)
            .then(
                playlist => {
                    const data = playlistsFormatter.formatShare(playlist);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_SHARE_REQUEST } }
    function success(id, playlist) { return { type: playlistsConstants.GET_PLAYLIST_SHARE_SUCCESS, id, playlist } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_SHARE_FAILURE, error } }
}


function getPlaylistTypesSources(entity, ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        playlistsService.getPlaylistTypesSources(entity, ids, globalFilter)
            .then(
                playlist => {
                    const data = playlistsFormatter.formatTypesSources(playlist);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_REQUEST } }
    function success(stats) { return { type: playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_SUCCESS, stats } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_FAILURE, error } }
}
