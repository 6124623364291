import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual, capitalize } from 'lodash';
import CSV from '../../../helpers/CSVExport';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';

import config from '../../../config/config';
import Box from '../../common/Box';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { tracksActions } from '../../../data/actions/tracks';
import { FillLineChart, StackedBarChart } from '../../widgets/charts/types';


class SourcesStats extends React.Component {
    constructor(props) {
        super(props);
        this.exportToCsv = this.exportToCsv.bind(this);        
    }
    
    exportToCsv(){
        let { tracks, id } = this.props,
            fields = ["isrc", "artist",  "track_title", "first_released", "weeks_since_release", "src_album", "perc_album", "src_artist", "perc_artist", "src_collection", "perc_collection", "src_other", "perc_other", "src_playlist", "perc_playlist", "src_radio", "perc_radio", "src_search", "perc_search"];
        
        let data = tracks.comparison.data.filter(item=>item.isrc == id);
        let exportData = data.map(item => {
            let resultItem = {};
            for(let field of fields) {
                resultItem[field] = item[field];
            }
            return resultItem;
        });

        const filename = CSV.CSVHeader(`track-comparison-sources`);
        return CSV.CSVExport(exportData, {filename}, 'track-comparison-sources');
    }

    renderToolbar(){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }
    
    render (){
        const { tracks, id, metadata, chartID = '' }  = this.props,
            data = tracks.comparison;

        let datasets = [];
        if(data.sourceDatasets && data.sourceDatasets[id]) {
            for(let datasetIndex in data.sourceLabels) {
                const source = data.sourceLabels[datasetIndex];
                
                datasets.push ({
                    label: source,
                    data: data.sourceDatasets[id][source]
                })
            }
        }
        let chartData = {
            labels: data.labels,
            datasets
        }
        
        const title = ( metadata && metadata.name_compare ) ? metadata.name_compare : id;
        const chartOptions = {
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        callback: function(value, index, values) {
                            return value + '%';
                        }
                    }
                }]
            },
            tooltips: {
                callbacks: {
                    title: function(tooltipItem, data) {
                        return `Week ${tooltipItem[0].xLabel}`;
                    },
                    label: function(tooltipItem, data) {
                        return `${capitalize(data.datasets[tooltipItem.datasetIndex].label)} ${tooltipItem.yLabel}%`;
                    }
                }
            }
        };

        return <Box title={title} toolbar={this.renderToolbar()}>
        
            {chartData && <div className="row">
                <div className={`col-xs-12`}>
                    {chartData.datasets.length > 0 && <div className="chart-block">
                        <FillLineChart data={chartData} id={chartID} />                          
                    </div>}
                    {!chartData.datasets.length && <p>No data available</p>}
                </div>    
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        filter: state.filter,
        userData: state.user
    } 
}

export default connect(mapStateToProps)(SourcesStats);