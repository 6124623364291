import config from '../../config/config';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';

export const filterService = {
  getFilters,
  createFilter,
  removeFilter,
  updateFilter,
  buildTable,
  getMaxDate
}

function updateFilter(id, filter){
  const url = `${config.apis.orthus}/v1/filters/${id}`;
  const { is_shared, ...rest } = filter;

  const params = {  is_shared, filter_body: { ...rest } };

  return fetch(url, {
    method: 'PUT',
    headers: AuthHeader({'content-type': 'application/json'}),
    body: JSON.stringify(params)
  })
  .then(res => res.json())
  .catch( err => Promise.reject(err));
}

function getFilters(){
  const options = {
    method: 'GET',
    headers: AuthHeader()
  };

  const url = `${config.apis.orthus}/v1/filters`;

  return fetch(url, options)
  .then( response => response.json())
  .catch(error => Promise.reject(error.message));
}

function createFilter(filter, filterName) {
  const body = {
    "filter_name": filterName,
    "filter_body": filter,
  };

  const options = {
    method: 'POST',
    headers: AuthHeader({'Content-Type': 'application/json'}),
    body: JSON.stringify(body)
  };

  const url = `${config.apis.orthus}/v1/filters`;

  return fetch(url, options)
  .then( response => response.json())
  .catch( error => Promise.reject(error));
}

function removeFilter(id){
  const url = `${config.apis.orthus}/v1/filters/${id}`

  const params = {
    method: 'DELETE',
    headers: AuthHeader()
  };

  return fetch(url, params)
  .then( res => res.json())
  .catch(error => Promise.reject(error));
}


function buildTable(globalFilter){
    return config.live ? buildTableLive(globalFilter) : buildTableStatic();
}

function buildTableLive(globalFilter) {

    let baseUrl = config.apis.v3_data,
    qs = BuildQS(globalFilter),
    authHeader = AuthHeader();
    
    const fieldsToRemove = ['territories', 'vendors', 'row_limit', 'client_admin', 'silo_code'];
    qs.silo_codes = qs.silo_code;
    for(let fieldToRemove of fieldsToRemove)
        delete qs[fieldToRemove];

    baseUrl += '/temp_user_table?' + $.param(qs);
    
    return fetch(baseUrl, {headers: authHeader})
    .then(res=>{   
        return res.json()
    });
} 


function buildTableStatic() {
    let baseUrl = `${config.apis.sales}/api/v1/temp_user_table`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    })    
}


function getMaxDate(){
    return config.live ? getMaxDateLive() : getMaxDateStatic();
}


function getMaxDateLive(){
	  const options = {
	    method: 'GET'
	  };
	  const qs = BuildQS({});

	  const url = `${config.apis.secStat}/get_max_date?`+ $.param(qs);

	  return fetch(url, options)
	  .then( response => response.json())
	  .catch(error => Promise.reject(error.message));
}

function getMaxDateStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/get_max_date.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    })    
}
