import { statsConstants } from '../constants/stats';

export function stats(state = {transactions: {}, transactionsTimeseries: {}}, action) {
    switch (action.type) {
        case statsConstants.GET_TOP_STATS_REQUEST:
            return {
                ...state,
                topLoading: true
            };
        case statsConstants.GET_TOP_STATS_SUCCESS:
            return {
                
                ...state,
                topLoading: false,
                top: action.stats
            };
        case statsConstants.GET_TOP_STATS_FAILURE:
            return {
                ...state,
                topLoading: false,
                error: action.error
            };
            
        case statsConstants.GET_CATALOG_STATS_REQUEST:
            return {
                ...state,
                catalogLoading: true
            };
        case statsConstants.GET_CATALOG_STATS_SUCCESS:
            return {
                
                ...state,
                catalogLoading: false,
                catalog: action.stats
            };
        case statsConstants.GET_CATALOG_STATS_FAILURE:
            return {
                ...state,
                catalogLoading: false,
                error: action.error
            };
            
        case statsConstants.GET_VENDOR_STATS_REQUEST:
            return {
                ...state,
                vendorLoading: true,
                vendorTotals: undefined,
                vendor: undefined
            };
        case statsConstants.GET_VENDOR_STATS_SUCCESS:
            return {
                
                ...state,
                vendorLoading: false,
                vendor: action.stats,
                vendorTotals: action.totals,
                vendorCache: action.cache,
                forceLoadAllVendorsData: false
            };
        case statsConstants.GET_VENDOR_STATS_FAILURE:
            return {
                ...state,
                vendorLoading: false,
                error: action.error,
                forceLoadAllVendorsData: false
            };

        case statsConstants.GET_TERRITORY_STATS_REQUEST:
            return {
                ...state,
                territoryLoading: true,
                territory: undefined,
                territoryTotals: undefined,
                territoryTimeseries: undefined
            };
        case statsConstants.GET_TERRITORY_STATS_SUCCESS:
            return {
                
                ...state,
                territoryLoading: false,
                territoryTotals: action.totals,
                territory: action.stats,
                forceLoadAllTerritoriesData: false
            };
        case statsConstants.GET_TERRITORY_STATS_FAILURE:
            return {
                ...state,
                territoryLoading: false,
                error: action.error,
            };
            
        case statsConstants.GET_TERRITORY_TIMESERIES_REQUEST:
            return {
                ...state,
                territoryTimeseriesLoading: true,
                territoryTimeseries: undefined                
            };
        case statsConstants.GET_TERRITORY_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                territoryTimeseriesLoading: false,
                territoryTimeseries: action.stats
            };
        case statsConstants.GET_TERRITORY_TIMESERIES_FAILURE:
            return {
                ...state,
                territoryTimeseriesLoading: false,
                error: action.error
            };
            

        case statsConstants.GET_TOP_TERRITORY_STATS_REQUEST:
            return {
                ...state,
                topTerritoryLoading: true,
                topTerritory: undefined,
                topTerritoryTotals: undefined,
                timeseries: undefined
                
            };
        case statsConstants.GET_TOP_TERRITORY_STATS_SUCCESS:
            return {
                
                ...state,
                topTerritoryLoading: false,
                topTerritory: action.stats,
                topTerritoryTotals: action.totals,
                topTerritoryCache: action.cache,
                forceLoadAllTopTerritoriesData: false
            };
        case statsConstants.GET_TOP_TERRITORY_STATS_FAILURE:
            return {
                ...state,
                topTerritoryLoading: false,
                error: action.error,
                forceLoadAllTopTerritoriesData: false
            };

        case statsConstants.GET_TOP_TERRITORY_TIMESERIES_REQUEST:
            return {
                ...state,
                topTimeseriesLoading: true,
                topTerritoryTimeseries: undefined
                
            };
        case statsConstants.GET_TOP_TERRITORY_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                topTimeseriesLoading: false,
                topTerritoryTimeseries: action.timeseries
            };
        case statsConstants.GET_TOP_TERRITORY_TIMESERIES_FAILURE:
            return {
                ...state,
                topTimeseriesLoading: false,
                error: action.error
            };
            
        case statsConstants.GET_DEMOGRAPHICS_STATS_REQUEST:
            return {
                ...state,
                demographicsLoading: true,
                demographics: undefined,
                demographicsTimeseries: undefined,
            };
        case statsConstants.GET_DEMOGRAPHICS_STATS_SUCCESS:
            return {
                
                ...state,
                demographicsLoading: false,
                demographics: action.stats
            };
        case statsConstants.GET_DEMOGRAPHICS_STATS_FAILURE:
            return {
                ...state,
                demographicsLoading: false,
                error: action.error
            };  
            
        case statsConstants.GET_DEMOGRAPHICS_TIMESERIES_REQUEST:
            return {
                ...state,
                demographicsLoading: true,
                demographicsTimeseries: undefined                
            };
        case statsConstants.GET_DEMOGRAPHICS_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                demographicsLoading: false,
                demographicsTimeseries: action.stats
            };
        case statsConstants.GET_DEMOGRAPHICS_TIMESERIES_FAILURE:
            return {
                ...state,
                demographicsLoading: false,
                error: action.error
            };  

        case statsConstants.GET_DEVICES_STATS_REQUEST:
            return {
                ...state,
                devicesLoading: true,
                devices: undefined                
            };
        case statsConstants.GET_DEVICES_STATS_SUCCESS:
            return {
                
                ...state,
                devicesLoading: false,
                devices: action.stats
            };
        case statsConstants.GET_DEVICES_STATS_FAILURE:
            return {
                ...state,
                devicesLoading: false,
                error: action.error
            };  

        case statsConstants.GET_SOURCES_STATS_REQUEST:
            return {
                ...state,
                sourcesLoading: true,
                sources: undefined,
                sourcesTimeseries: undefined
            };
        case statsConstants.GET_SOURCES_STATS_SUCCESS:
            return {
                
                ...state,
                sourcesLoading: false,
                sources: action.stats
            };
        case statsConstants.GET_SOURCES_STATS_FAILURE:
            return {
                ...state,
                sourcesLoading: false,
                error: action.error
            };  

        case statsConstants.GET_SOURCES_TIMESERIES_REQUEST:
            return {
                ...state,
                sourcesLoading: true,
                sourcesTimeseries: undefined                
            };
        case statsConstants.GET_SOURCES_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                sourcesLoading: false,
                sourcesTimeseries: action.stats
            };
        case statsConstants.GET_SOURCES_TIMESERIES_FAILURE:
            return {
                ...state,
                sourcesLoading: false,
                error: action.error
            };  

        case statsConstants.GET_TRANSACTIONS_REQUEST:
            return {
                ...state,
                transactionsLoading: true,                
                transactions: {
                    ...state.transactions,
                    [action.id]: undefined
                }
            };
        case statsConstants.GET_TRANSACTIONS_SUCCESS:
            let { transactions } = state;
            transactions[action.id] = action.data;
            return {
                ...state,
                transactionsLoading: false,
                transactions
            };
        case statsConstants.GET_TRANSACTIONS_FAILURE:
            return {
                ...state,
                transactionsLoading: false,
                error: action.error
            };
            
        case statsConstants.GET_STATEMENT_TOTALS_REQUEST:
            return {
                ...state,
                statementTotalsLoading: true,
                statementTotals: null
            };
        case statsConstants.GET_STATEMENT_TOTALS_SUCCESS:
            return {
                ...state,
                statementTotalsLoading: false,
                statementTotals: action.data
            };
        case statsConstants.GET_STATEMENT_TOTALS_FAILURE:
            return {
                ...state,
                statementTotalsLoading: false,
                error: action.error
            };  

        case statsConstants.FORCE_LOAD_ALL_TOP_TERRITORIES_DATA: {
            return {
                ...state,
                forceLoadAllTopTerritoriesData: action.force,
                topTerritoryLoading: true,
            }
        }

        case statsConstants.FORCE_LOAD_ALL_TERRITORIES_DATA: {
            return {
                ...state,
                forceLoadAllTerritoriesData: action.force,
                territoryLoading: true
            }
        }
        
        case statsConstants.FORCE_LOAD_ALL_VENDORS_DATA: {
            return {
                ...state,
                forceLoadAllVendorsData: action.force,
                vendorLoading: true
            }
        }
        
        case statsConstants.GET_TRANSACTIONS_TIMESERIES_REQUEST:
            return {
                ...state,
                transactionsTimeseriesLoading: true,                
            };
        case statsConstants.GET_TRANSACTIONS_TIMESERIES_SUCCESS:
            let { transactionsTimeseries } = state;
            transactionsTimeseries[action.id] = action.data;
            return {
                ...state,
                transactionsTimeseriesLoading: false,
                transactionsTimeseries
            };
        case statsConstants.GET_TRANSACTIONS_TIMESERIES_FAILURE:
            return {
                ...state,
                transactionsTimeseriesLoading: false,
                error: action.error
            };
        
        
        default:
            return state
    }
} 