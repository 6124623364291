import React from 'react';
import { map } from 'lodash';
import AbsoluteValue from '../../../common/AbsoluteValue';
import CountryFlag from '../../../common/CountryFlag';
import { percentageValue } from '../../../../helpers/NumberFormatter';
import Tooltip from '../../../common/Tooltip';

export const territorySettings = {
    settingsForTable
};


function settingsForTable(mode, setTerritory){
    const modes={
        'map': ['logo', 'share', 'name', 'listeners'],
        'expanded': ['rank', 'logo', 'name', 'listeners', 'total']
    };
    
    
    let columns = [
       {
           id: 'rank',
           className: 'rank',
           headerClassName: 'table-header-item rank',
           Header: 'Rank',
           width: 60,
           accessor: 'rank'
       },
                   
        {
            id: 'logo',
            className: 'logo-sell',
            headerClassName: 'logo-sell',
            Header: '',
            accessor: 'name',
            width: 40,
            Cell: props => {
                return <CountryFlag className="flag-medium" country={props.original.code} />
         }
      },
      {
          id: 'name',
       //    className: 'imprint-name',
       //    headerClassName: 'imprint-name',
          Header: 'Country',
          accessor: 'name',
       //    width: 190
          Cell: props => {
              return <span className="territory-demographics-link" onClick={()=>{setTerritory(props.original.code)}}>{props.value}</span>
           }

      },
      {
          id: 'listeners',
          headerClassName: 'table-header-item',
          className: 'listeners',
          Header: 'Listeners',
          Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
          // width: 100,
          accessor: 'listeners'
      },      
      {
              id: 'share',
              headerClassName: 'table-header-item',
              Header: 'Share',
              // width: 70,
              accessor: 'share',
              Cell: props => <span>{props.value}%</span>,
          },
       {
           id: 'total',
           headerClassName: 'table-header-item total',
           className: 'total',
           Header: 'Streams',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'total_streams'
       },       


    ];
    return columns.map(column=>{
        column.show = modes[mode].includes(column.id);
        return column;
    });
}