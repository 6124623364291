import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { withRouter } from 'react-router'
import { filterActions } from '../../../data/actions/filter';
import vendors from './enums/vendors';
import territories from './enums/territories';
import sources from './enums/sources';
import releaseDates from './enums/release_dates.json';

class FilterButton extends React.Component {

    _getSelectedDuration(dateStart, dateEnd) {
        const dateDiff =  moment(dateEnd).diff(moment(dateStart), 'days');
        return moment.duration(dateDiff, 'days').asDays();
    }
    
    getReleaseDate(filter) {
        const {releaseStart, releaseEnd} = filter;
        const today = moment();
        const selectedDurationStart = this._getSelectedDuration(releaseStart, today);
        const selectedDurationEnd = this._getSelectedDuration(releaseEnd, today);
        for (let date of releaseDates) {
          if (date.offset) {
              if(((date.offset[1].days == selectedDurationStart) || (! date.offset[1].days && !releaseStart )) 
                  && ((date.offset[0].days == selectedDurationEnd)  || (! date.offset[0].days && !releaseEnd )))
              return date.label;    
          }
        }
        return releaseDates[releaseDates.length - 1].label;
    }

    
    _renderCaption(filter) {
        if(!filter || !filter.dateStart)
            return null;
        const dateStart = moment(filter.dateStart),
            prevDateEnd = moment(filter.prevDateEnd),
            diff = dateStart.diff(prevDateEnd, 'days');
        return <p className="filter-date-range">
            <span className={`prev-range ${diff > 1 ? 'non-neighboring' : ''}`}>({filter.prevDateStart} - {filter.prevDateEnd})</span> <span>{filter.dateStart} - {filter.dateEnd}</span>
        </p>;
    }
    
    renderCaption(filter) {
        if(!filter || !filter.limit)
            return null;
        
        let totalTerritories = 0;
        for(let continent of Object.keys(territories)) {
            totalTerritories += territories[continent].length;
        }
        
        let vendorsCount = 'All';
        if(filter.genres && filter.vendors.length)
            vendorsCount = filter.vendors.split(',').length;
        
        let territoriesCount = 'All';
        if(filter.territories && filter.territories.length)
            territoriesCount = filter.territories.length == totalTerritories ? 'All' : filter.territories.length;
        
        let genresCount = 'All';
        if(filter.genres && filter.genres.length)
            genresCount = filter.genres.split(',').length;
        
        return <div><p className="main-filter-text">
            <span className="main-filter-text-item">Territories: ({territoriesCount})</span>
            <span className="main-filter-text-item">Platforms: ({vendorsCount})</span>
            <span className="main-filter-text-item">Genres: ({genresCount})</span>
            <span className="main-filter-text-item">Release Date: ({this.getReleaseDate(filter)})</span>
        </p></div>

        
    }
    
    render (){
        const filter = this.props.filter.global;
        const { pathname } = this.props.location;
        const pathnameRegExp = /heartbeats|new-releases|track-comparison/;
        const isDisabled = pathnameRegExp.test(pathname);
        
        return <div>
            <button className={`filter-toggle-btn btn ${isDisabled ? 'filter-disabled' : ''}`} onClick={this.props.toggleFilter}>{this.renderCaption(filter)} <span className="fa fa-sliders"></span></button>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps){
    return {
        toggleFilter: ()=>dispatch(filterActions.toggleGlobalFilter()),
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterButton))