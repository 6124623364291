import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'
import Modal from '../common/Modal'
import { composeValidators, required, URL } from './validators'

class AddChannelForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let submit,
        {show, onClose, onSubmit} = this.props;
        
        return <Modal show={show} 
                title="New Channel" 
                submitTitle="Save"
                handleSubmit={e=>submit(e)}
                handleClose={onClose}
            >
                <Form onSubmit={onSubmit} initialValues={{}}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit;
                        return <form className="m-t">
                                <Field name="url"  
                                    validate={composeValidators(required, URL)}
                                >
                                    {({ input, meta }) => {
                                        const hasError = meta.error && meta.touched;
                                        return <div className={`form-group${hasError ? ' has-error':''}`}>
                                            <label className="font-normal control-label">URL</label>
                                            <input {...input} type="text" className="form-control" placeholder="https://" />
                                            {hasError && <span className="help-block">{meta.error}</span>}
                                        </div>            
                                    }}
                                </Field>
                            
                        </form>
                    }}
                />
                
        </Modal>
    }
}
export default AddChannelForm