import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import { sumBy, isEqual, maxBy, minBy, map, uniq, find, cloneDeep } from 'lodash';
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';

import { vendorSettings } from './Vendor/Settings';
import { statsActions } from '../../../data/actions/stats';

import config from '../../../config/config';
import CSV from '../../../helpers/CSVExport';
import StackedBarChart from '../../widgets/charts/types/StackedBarChart';
import PieChart from '../../widgets/charts/types/PieChart';
import DoughnutChart from '../../widgets/charts/types/DoughnutChart';
import LineChart from '../../widgets/charts/types/LineChart';
import Box from '../../common/Box';
import ExportModal from '../../common/ExportModal';
import TrendDirection from '../../common/TrendDirection';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import LoadingMessage from '../../../helpers/LoadingMessage';
import ConfirmClick from '../../common/ConfirmClick';

var chartSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var arrowLeftSvg = require('!svg-inline-loader!../../../../public/img/arrow-left.svg');
var arrowRightSvg = require('!svg-inline-loader!../../../../public/img/arrow-right.svg');
var xlsxSvg = require('!svg-inline-loader!../../../../public/img/xlsx.svg');

const CellHeaderTable = cellHeaderHOC(ReactTable);

class VendorsWidget extends Component {
    options = [
       {label: 'Top Total Income', field: 'total_income', dir: 'DESC'},
       {label: 'Top Total Quantity', field: 'total_quantity', dir: 'DESC'},
       {label: 'Top Physical Income', field: 'physical_income', dir: 'DESC'},
       {label: 'Top Physical Quantity', field: 'physical_quantity', dir: 'DESC'},
       {label: 'Top Digital Income', field: 'digital_income', dir: 'DESC'},
       {label: 'Top Digital Quantity', field: 'digital_quantity', dir: 'DESC'},
       {label: 'Top NR Income', field: 'nr_income', dir: 'DESC'},
       {label: 'Top NR Quantity', field: 'nr_quantity', dir: 'DESC'},       
       {label: 'Bottom Total Income', field: 'total_income', dir: 'ASC'},
       {label: 'Bottom Total Quantity', field: 'total_quantity', dir: 'ASC'},
       {label: 'Bottom Physical Income', field: 'physical_income', dir: 'ASC'},
       {label: 'Bottom Physical Quantity', field: 'physical_quantity', dir: 'ASC'},
       {label: 'Bottom Digital Income', field: 'digital_income', dir: 'ASC'},
       {label: 'Bottom Digital Quantity', field: 'digital_quantity', dir: 'ASC'},
       {label: 'Bottom NR Income', field: 'nr_income', dir: 'ASC'},
       {label: 'Bottom NR Quantity', field: 'nr_quantity', dir: 'ASC'},
    ];	
	limits = [10, 25, 50]; // , 100, 10000];

    constructor(props) {
        super(props);
        this.state = {
            vendor: 'all',
            contentType: null,
            contentTypes: null,
            mode: 'table',
//            sort: {field: 'total_income', dir: 'desc'}
	        sort: this.options[0],
	        limit: this.limits[0],
	        page: 0
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.setContentType = this.setContentType.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToXls = this.exportToXls.bind(this);
        this.setSort = this.setSort.bind(this);
        this.setLimit = this.setLimit.bind(this);
        this.setPage = this.setPage.bind(this);
    }
    
    componentDidMount() {
        this.props.getVendors(this.state, false, this.props.entity, this.props.id);
    }
    
    setPage(page){
        this.setState({page}, ()=>{
            this.props.getVendors(this.state, false, this.props.entity, this.props.id);
        });
    }

    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.setState({
                    vendor: 'all',
                    contentType: null,
                    contentTypes: null
                }, ()=>{
                    this.props.getVendors(this.state, false, this.props.entity, this.props.id);                    
                })
                
            }                
        }
        if(nextProps.stats && nextProps.stats.vendor){
            let contentTypes = map(uniq(map(nextProps.stats.vendor.table, data=>data.content_type)), type=>({
                label: type, value: type
            })),
            contentType = contentTypes[0];
            
            contentTypes.unshift({
                label: 'All',
                value: null
            });
            if(this.state.contentTypes === null || !isEqual(this.state.contentTypes, contentTypes)) {
                this.setState({
                    contentTypes,
                    contentType
                });
            }
                
        }
        
        if(nextProps.stats.forceLoadAllVendorsData && !this.props.stats.forceLoadAllVendorsData) {
            console.log('loading export data for top territories');
            let state = cloneDeep(this.state);
            state.limit = config.fullCSVThreshold;
            state.page = 0;
            this.props.getVendors(state, false, this.props.entity, this.props.id);
        }

    }

    
    setChartMode(mode){
        this.setState(mode, ()=>this.props.getVendors(this.state, true, this.props.entity, this.props.id));
    }

    setContentType(contentType){
        this.setState({contentType, vendor: 'all'}, ()=>this.props.getVendors(this.state, true, this.props.entity, this.props.id));
    }
    
    setSort(sort){
        this.setState({sort}, ()=>{
        	this.props.getVendors(this.state, false, this.props.entity, this.props.id);
        })
    }
    
    setLimit(limit){
        this.setState({limit: limit.value, page: 0}, ()=>{
        	this.props.getVendors(this.state, false, this.props.entity, this.props.id);
            
        })
    }    
    
    
    exportToCsv(full = false){
        let dataCall;
        if(full) { //this.props.entity && this.props.id
            let state = cloneDeep(this.state);
            state.limit = config.fullCSVThreshold;
            dataCall = this.props.getVendors(state, false, this.props.entity, this.props.id); 
        }
        else {
            dataCall = Promise.resolve();
        }
        dataCall.then(()=>{
            const filename = CSV.CSVHeader('top_vendors', this.state.sort.field, this.props.filter.global, this.props.parentEntityTitle); 
            return CSV.CSVExport(this.props.stats.vendor.table, {filename}, 'top_vendors');            
        })    
    }
    
    exportToXls(){
        const filename = CSV.CSVHeader('top_vendors', this.state.sort.field, this.props.filter.global);
        let data = this.props.stats.vendor.table;
        data = CSV.expandTimeline(data, 'stms_by_date');
        return CSV.XLSExport(data, {filename}, 'top_vendors');
    }

    
    renderToolbar(modes){
        let toolbar = [],
            contentTypes = this.state.contentTypes || [];
        for (let mode in modes) {
        //     toolbar.push(<a key={mode}><i className={`fa fa-${modes[mode]} ${this.state.mode == mode ? 'active' : ''}`} onClick={()=>this.setChartMode({mode})}></i></a>);                
        }
        
        const limitOptions = this.limits.map(limit => ({
            label: limit == 10000 ? 'All' : limit,
            value: limit        
        })),
        limitValue = find(limitOptions, {value: this.state.limit});

        const renderLimit = !(this.props.entity && this.props.id);
        const { mode } = this.state;
        
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
        	<React.Fragment>Limit: <ReactSelect value={limitValue} options={limitOptions} onChange={this.setLimit} className="single-select select-limit"/></React.Fragment>
        	<ReactSelect value={this.state.sort} options={this.options} onChange={this.setSort} className="single-select top-single-select"/>
            {/*<ReactSelect value={this.state.contentType} options={contentTypes} onChange={this.setContentType} className="single-select"/>*/}
            {/*
            <ConfirmClick confirmAction={this.exportToCsv} title="Export CSV" confirmClass="" confirmLabel={<span key="download" className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />} confirmPrompt="These reports are for analytical purposes only and should not be used to pay artist royalties"></ConfirmClick>
            */}
            
            {mode == 'table' && <ExportModal exportToCsv={this.exportToCsv} loading={this.props.stats.vendorLoading} />}
            {mode != 'table' && <a key="download" title="Export" onClick={this.exportToXls} className="chart-icon download-link" />}
        </div>
        </div>)
        return toolbar;
    }

    renderVendors(vendorsObj){
        let vendors = {'all': 'All'};
        for(let vendor of vendorsObj.vendors){
            vendors[vendor] = vendor;
        }
        const vendorsArray = Object.keys(vendors),
            currentVendorIndex = vendorsArray.indexOf(this.state.vendor),
            nextVendorIndex = currentVendorIndex+1,
            prevVendorIndex = currentVendorIndex-1,
            prevVendor = vendorsArray[prevVendorIndex < 0 ? vendorsArray.length-1 : prevVendorIndex],
            nextVendor = vendorsArray[nextVendorIndex > vendorsArray.length-1 ? 0 : nextVendorIndex];
        return <div className="chart-all-holder"> 
            <span className="chart-all-text">{vendors[this.state.vendor]}</span>
            <a className="angle-button angle-button--left" onClick={()=>this.setChartMode({vendor:prevVendor})} dangerouslySetInnerHTML={{__html: arrowLeftSvg}}></a>
            <a className="angle-button angle-button--right" onClick={()=>this.setChartMode({vendor:nextVendor})} dangerouslySetInnerHTML={{__html: arrowRightSvg}}></a>
        </div>
    }
    
    renderTrend(value) {
        const growth = value >= 0 ? 'an increase' : 'a decrease';
        return `${growth} of ${convertToThousands(Math.abs(value))}`;
    }

    renderTrends(data){
        if(!data || !data.length || !this.state.contentType)
            return null;
        if(this.state.contentType.value)
            data = data.filter(item => item.content_type == this.state.contentType.value);
        
        const topVendor = maxBy(data, 'growth'),
            lowVendor = minBy(data, 'growth'),
            totalPlays = sumBy(data, 'total'),
            showLowVendor = lowVendor && !isEqual(topVendor, lowVendor);
        return <div>
            <h3 className="content-title lowercase">{convertToThousands(totalPlays)} plays</h3>
            {/*<h4 className="content-subtitle">Total plays</h4>*/}
            
            {topVendor && <TrendDirection direction="up">
                <strong>{topVendor.vendor}</strong> shows strongest growth with {this.renderTrend(topVendor.growth)} plays      
            </TrendDirection>}
            {showLowVendor && <TrendDirection direction="down">
                <strong>{lowVendor.vendor}</strong> was your least growing vendor with {this.renderTrend(lowVendor.growth)} plays
            </TrendDirection>}  
                
        </div>;                                
    }
    
    render() {
        const { limit } = this.state;
        const modes = {
            'table': 'table',
            'pie': 'chart-pie',
//            'timeline': 'chart-line'
        },
        trends = config.showTrends;
      
        let tableData = [],
            totals = {};
        if(this.props.stats.vendor) {
            tableData = this.props.stats.vendor.table,
            totals = this.props.stats.vendorTotals;
            if(this.state.contentType && this.state.contentType.value) {
                tableData = tableData.filter(row => row.content_type == this.state.contentType.value)
            }
            tableData = tableData.slice(0, limit);
                
        }
        const totalRows = totals.total_rows || limit;
        
        let columns = vendorSettings.settingsForTable(totals);
        
        return  <div className="table-with-chart-holder">
            <Box title="Platforms" toolbar={this.renderToolbar(modes)} loadingMessage={LoadingMessage('overview')} spinnerEnabled={false} className="ibox-container box-table">
                <div className="row">
                    <div className={`col-xs-12`}>
                        <div className="vendor-table vendor-table--single custom-scroll">
                            <CellHeaderTable
                                className=""
                                manual
                                page={this.state.page}
                                pages={Math.ceil(totalRows / limit)}
                            defaultPageSize={Math.min(totalRows, limit)}
                            minRows={1}
                                data={tableData}
                            	loading={this.props.stats.vendorLoading}
                                columns={columns}
                                sortable={false}
                                onPageChange={this.setPage}
                                showPagination={(totalRows > limit)}
                                showPageSizeOptions={false}
                            />
                        </div>
                    </div>                   
                </div>
            </Box> 
            <div className="table-chart-holder">
                <div className="table-chart-holder-inner"> 
                    <div className="chart-title">Platforms</div>
                    {this.props.stats.vendor && <DoughnutChart data={this.props.stats.vendor.chart} chartID="top_vendors_pie_chart" />}
                </div>
            </div>
        </div>
    }

}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch){
    return {
        getVendors: (params, cache, entity, id)=>dispatch(statsActions.getVendorStats(params, cache, entity, id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VendorsWidget)