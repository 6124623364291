import config from '../../config/config';
import { errorActions } from './error';
import { importsConstants } from '../constants/imports';
import { importsService } from '../services/imports';
import { importsFormatter } from '../formatters/imports';


export const importsActions = {
    getStatementPeriods,
    getPendingStatements,
    extractSalesData,
    uploadToStorage,
    importSalesData,
    approveSalesData,
    rejectSalesData,
    initiateCatalogImport,
    getCatalogImport,
    getCatalogImportErrors,
    getUploadForm,
    insertCatalogExceptions,
    getCatalogExceptions,
    updateCatalogExceptions,
    refreshCatalogExceptions,
    getCatalogImports,
    undoCatalogImport
};

function getStatementPeriods() {
    return ( dispatch, getState ) => {
        dispatch(request());
        return importsService.getStatementPeriods()
            .then(
                imports => {
                    imports = importsFormatter.formatStatementPeriods(imports);
                    dispatch(success(imports));
                }
            )
            .catch(error => {
                console.log(error);
                dispatch(failure('error'))
            })
    };

    function request() { return { type: importsConstants.GET_STATEMENT_PERIODS_REQUEST } }
    function success(imports) { return { type: importsConstants.GET_STATEMENT_PERIODS_SUCCESS, imports } }
    function failure(error) { return { type: importsConstants.GET_STATEMENT_PERIODS_FAILURE, error } }
}

function getPendingStatements() {
    return ( dispatch, getState ) => {
        dispatch(request());
        return importsService.getPendingStatements()
            .then(
                imports => {
                    imports = importsFormatter.formatPendingStatements(imports);
                    dispatch(success(imports));
                }
            )
            .catch(error => {
                console.log(error);
                dispatch(failure('error'))
            })
    };

    function request() { return { type: importsConstants.GET_PENDING_STATEMENTS_REQUEST } }
    function success(imports) { return { type: importsConstants.GET_PENDING_STATEMENTS_SUCCESS, imports } }
    function failure(error) { return { type: importsConstants.GET_PENDING_STATEMENTS_FAILURE, error } }
}


function extractSalesData(period, source) {
    return ( dispatch, getState ) => {
        dispatch(request(source));
        let { dateStart: date_start, dateEnd: date_end } = importsFormatter.formatDateRangeByPeriod(period);
        return importsService.extractSalesData({source, date_start, date_end})
        .then(
            imports => {
                dispatch(success(source));
                return imports;
            }
        )
    };

    function request(source) { return { type: importsConstants.EXTRACT_SALES_DATA_REQUEST, source } }
    function success(source) { return { type: importsConstants.EXTRACT_SALES_DATA_SUCCESS, source } }
    function failure(error) { return { type: importsConstants.EXTRACT_SALES_DATA_FAILURE, error } }
}

function uploadToStorage(period, source) {
    return ( dispatch, getState ) => {
        dispatch(request(source));
        let { dateStart: date_start, dateEnd: date_end } = importsFormatter.formatDateRangeByPeriod(period);
        return importsService.uploadToStorage({source, date_start, date_end})
        .then(
            imports => {
                dispatch(success(source));
                return imports;
            }
        )
    };

    function request(source) { return { type: importsConstants.UPLOAD_TO_STORAGE_REQUEST, source } }
    function success(source) { return { type: importsConstants.UPLOAD_TO_STORAGE_SUCCESS, source } }
    function failure(error) { return { type: importsConstants.UPLOAD_TO_STORAGE_FAILURE, error } }
}


function importSalesData(period, source) {
    return ( dispatch, getState ) => {
        dispatch(request(source));
        let { dateStart: date_start, dateEnd: date_end } = importsFormatter.formatDateRangeByPeriod(period);
        return importsService.importSalesData({source, date_start, date_end})
        .then(
            imports => {
                dispatch(success(source));
                return imports;
            }
        )
    };

    function request(source) { return { type: importsConstants.IMPORT_SALES_DATA_REQUEST, source } }
    function success(source) { return { type: importsConstants.IMPORT_SALES_DATA_SUCCESS, source } }
    function failure(error) { return { type: importsConstants.IMPORT_SALES_DATA_FAILURE, error } }
}

function approveSalesData(period) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let { dateStart: date_start, dateEnd: date_end } = importsFormatter.formatDateRangeByPeriod(period);
        return importsService.approveSalesData({date_start, date_end})
        .then(
            imports => {
                dispatch(success());
                dispatch(errorActions.setSuccessMessage('Data import scheduled. Please check this page in one hour.'));
                return imports;
            }
        )
    };

    function request() { return { type: importsConstants.APPROVE_SALES_DATA_REQUEST } }
    function success() { return { type: importsConstants.APPROVE_SALES_DATA_SUCCESS } }
    function failure(error) { return { type: importsConstants.APPROVE_SALES_DATA_FAILURE, error } }
}

function rejectSalesData(period) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let { dateStart: date_start, dateEnd: date_end } = importsFormatter.formatDateRangeByPeriod(period);
        return importsService.rejectSalesData({date_start, date_end})
        .then(
            imports => {
                dispatch(success());
                dispatch(errorActions.setSuccessMessage('Data Removed'));
                return imports;
            }
        )
    };

    function request() { return { type: importsConstants.REJECT_SALES_DATA_REQUEST } }
    function success() { return { type: importsConstants.REJECT_SALES_DATA_SUCCESS } }
    function failure(error) { return { type: importsConstants.REJECT_SALES_DATA_FAILURE, error } }
}


function initiateCatalogImport(url) {
    const client = config.miniCatImport.clientId;
    return ( dispatch, getState ) => {
        dispatch(request());
        url = url.split('?')[0];
        return importsService.initiateCatalogImport(client, url)
            .then(
                (importData) => {
                    if(importData.id) {
                        dispatch(success(importData.id));
                        return importData.id;
                    }
                    else {
                        dispatch(failure(importData.error))
                        dispatch(errorActions.setErrorMessage('Upload error, please ensure file is in CSV format'));
                    }
                }
            )
            .catch(error => {
                console.log(error);
                dispatch(failure('error'))
            })
    };

    function request() { return { type: importsConstants.CATALOG_IMPORT_REQUEST } }
    function success(id) { return { type: importsConstants.CATALOG_IMPORT_SUCCESS, id } }
    function failure(error) { return { type: importsConstants.CATALOG_IMPORT_FAILURE, error } }
}

function getCatalogImport(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return importsService.getCatalogImport(id)
            .then(
                importData => {
                    if(importData.error) {
                        dispatch(errorActions.setErrorMessage(`Import ${id} not found.`));
                    }
                    else {
                        importData = importsFormatter.formatCatalogImport(importData);
                        dispatch(success(importData));
                        return importData;
                    }
                }
            )
            .catch(error => {
                console.log(error);
                dispatch(failure('error'))
            })
    };

    function request() { return { type: importsConstants.CATALOG_IMPORT_REQUEST } }
    function success(importData) { return { type: importsConstants.CATALOG_IMPORT_SUCCESS, importData} }
    function failure(error) { return { type: importsConstants.CATALOG_IMPORT_FAILURE, error } }
}


function getCatalogImportErrors(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return importsService.getCatalogImportErrors(id)
        .then(
            ({errors}) => {
                errors = importsFormatter.formatCatalogImportErrors(errors);
                dispatch(success(errors));
                return errors;
            }
        )
    };

    function request() { return { type: importsConstants.CATALOG_IMPORT_ERRORS_REQUEST } }
    function success(errors) { return { type: importsConstants.CATALOG_IMPORT_ERRORS_SUCCESS, errors} }
    function failure(error) { return { type: importsConstants.CATALOG_IMPORT_ERRORS_FAILURE, error } }
}

function insertCatalogExceptions() {
    return ( dispatch, getState ) => {
        dispatch(request());
        return importsService.insertCatalogExceptions()
        .then(
            ({result}) => {
                dispatch(success());
                return result;
            }
        )
    };

    function request() { return { type: importsConstants.CATALOG_EXCEPTIONS_REQUEST } }
    function success() { return { type: importsConstants.CATALOG_EXCEPTIONS_SUCCESS } }
    function failure(error) { return { type: importsConstants.CATALOG_EXCEPTIONS_FAILURE, error } }
}



function getCatalogExceptions(date) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return importsService.getCatalogExceptions(date)
        .then(
            ({result}) => {
                dispatch(success(result));
                return result;
            }
        )
    };

    function request() { return { type: importsConstants.CATALOG_EXCEPTIONS_REQUEST } }
    function success(exceptions) { return { type: importsConstants.CATALOG_EXCEPTIONS_SUCCESS, exceptions} }
    function failure(error) { return { type: importsConstants.CATALOG_EXCEPTIONS_FAILURE, error } }
}

function updateCatalogExceptions() {
    return ( dispatch, getState ) => {
        dispatch(request());
        return importsService.updateCatalogExceptions()
        .then(
            ({result}) => {
                dispatch(success());
                return result;
            }
        )
    };

    function request() { return { type: importsConstants.CATALOG_EXCEPTIONS_REQUEST } }
    function success() { return { type: importsConstants.CATALOG_EXCEPTIONS_SUCCESS } }
    function failure(error) { return { type: importsConstants.CATALOG_EXCEPTIONS_FAILURE, error } }
}

function refreshCatalogExceptions() {
    return ( dispatch, getState ) => {
        dispatch(request());
        return importsService.refreshCatalogExceptions()
        .then(
            ({result}) => {
                dispatch(success(result));
                return result;
            }
        )
    };

    function request() { return { type: importsConstants.CATALOG_EXCEPTIONS_REQUEST } }
    function success() { return { type: importsConstants.CATALOG_EXCEPTIONS_SUCCESS } }
    function failure(error) { return { type: importsConstants.CATALOG_EXCEPTIONS_FAILURE, error } }
}

function getUploadForm(fileName, body) {
    const bucket = config.miniCatImport.bucket;
    return ( dispatch, getState ) => {
        dispatch(request());
        return importsService.getUploadForm(bucket, fileName)
        .then(({form}) => {
            const { fields, url } = form;
            fields.body = importsFormatter.formatUploadedCSV(body);
            return importsService.uploadFile(url, fields, fileName) 
        })
        .then(headers=>{
            return importsService.getUrl(bucket, fileName);
        })
        .then(({url})=>{
            return url;
        })
    };

    function request(source) { return { type: importsConstants.EXTRACT_SALES_DATA_REQUEST, source } }
    function success(source) { return { type: importsConstants.EXTRACT_SALES_DATA_SUCCESS, source } }
    function failure(error) { return { type: importsConstants.EXTRACT_SALES_DATA_FAILURE, error } }
}

function getCatalogImports() {
    return ( dispatch, getState ) => {
        dispatch(request());
        const client = config.miniCatImport.clientId;
        return importsService.getCatalogImports(client)
            .then(
                imports => {
                    imports = importsFormatter.formatCatalogImports(imports);
                    dispatch(success(imports));
                }
            )
            .catch(error => {
                console.log(error);
                dispatch(failure('error'))
            })
    };

    function request() { return { type: importsConstants.GET_CATALOG_IMPORTS_REQUEST } }
    function success(imports) { return { type: importsConstants.GET_CATALOG_IMPORTS_SUCCESS, imports } }
    function failure(error) { return { type: importsConstants.GET_CATALOG_IMPORTS_FAILURE, error } }
}

function undoCatalogImport(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return importsService.undoCatalogImport(id)
            .then(
                importData => {
                    if(importData.error) {
                        dispatch(errorActions.setErrorMessage(`Import ${id} not found.`));
                        dispatch(success(imports));
                        return false;
                    }
                    else {
                        dispatch(errorActions.setErrorMessage(`Import ${id} is being reversed.`));
                        dispatch(success(imports));
                        return true;
                    }
                }
            )
            .catch(error => {
                console.log(error);
                dispatch(failure('error'))
            })
    };

    function request() { return { type: importsConstants.UNDO_CATALOG_IMPORT_REQUEST } }
    function success() { return { type: importsConstants.UNDO_CATALOG_IMPORT_SUCCESS} }
    function failure(error) { return { type: importsConstants.UNDO_CATALOG_IMPORT_FAILURE, error } }
}
