import { errorActions } from './error';
import { vendorsConstants } from '../constants/vendors';
import { vendorsService } from '../services/vendors';
import { vendorsFormatter } from '../formatters/vendors';

export const vendorsActions = {
    getVendorStats,
    getVendors,
    changeVendorStatus
};

function getVendorStats() {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        vendorsService.getVendorStats(globalFilter)
            .then(
                vendors => {
                    return vendorsFormatter.formatVendorStats(vendors);
                    //
                }
            )
            .then(stats=>{
                let {result, dates} = stats;
                vendorsService.getVendorStatuses(globalFilter)
                .then(statuses=>{
                    result = vendorsFormatter.formatVendorStatuses(result, statuses.data);
                    return dispatch(success(result, dates));
                })
            })
            .catch(error => {
                dispatch(failure('error'))
            })
    };

    function request() { return { type: vendorsConstants.GET_VENDOR_STATS_REQUEST } }
    function success(vendors, dates) { return { type: vendorsConstants.GET_VENDOR_STATS_SUCCESS, vendors, dates } }
    function failure(error) { return { type: vendorsConstants.GET_VENDOR_STATS_FAILURE, error } }
}

function changeVendorStatus(vendor, silo, status, vendorName) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const params = {vendor_id: vendor, silo_code: silo, status, vendor_name: vendorName};
        
        vendorsService.changeVendorStatus(params)
            .then(
                res => {
                    const successMessage = (status == 'approved') ? 'Thank you for approving the data security policy' : 'You have successfully revoked your credentials'; 
                    
                    dispatch(success());
                    dispatch(errorActions.setSuccessMessage(successMessage));
                    dispatch(getVendorStats());
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving status'))
                }
            );
 
    };
    function request() { return { type: vendorsConstants.CHANGE_VENDOR_STATUS_REQUEST } }
    function success() { return { type: vendorsConstants.CHANGE_VENDOR_STATUS_SUCCESS } }
    function failure() { return { type: vendorsConstants.CHANGE_VENDOR_STATUS_FAILURE } }

}


function getVendors() {
    return ( dispatch, getState ) => {
        dispatch(request());
        vendorsService.getVendors()
        .then(
            vendors => {
                vendors = vendorsFormatter.formatVendors(vendors);
                return dispatch(success(vendors));
                
            }
        )
    };

    function request() { return { type: vendorsConstants.GET_VENDORS_REQUEST } }
    function success(vendors) { return { type: vendorsConstants.GET_VENDORS_SUCCESS, vendors } }
    function failure(error) { return { type: vendorsConstants.GET_VENDORS_FAILURE, error } }
}
