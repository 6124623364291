import config from '../../config/config';
import AuthHeader from '../../helpers/AuthHeader';

export const releasesService = {
  getNewReleases
};

function getNewReleases(date, pagination){
  const { page, per_page } = pagination;
  const baseUrl = `${config.apis.orthus}/v1/new_releases?release_date=${date}&page=${page}&per_page=${per_page}`;
  const options = {
    method: 'GET',
    headers: AuthHeader({'Content-Type': 'application/json'})
  };

  return fetch( baseUrl, options )
  .then( response => {
    return response.json();
  })
  .catch( error => {
    return Promise.reject(ex)
  })
}
