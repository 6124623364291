import React from 'react';
import ReactDOM from 'react-dom';
import Multiselect from './Multiselect';
import { merge } from 'lodash';

const searchOptions = {
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true
};

class MultiselectWithSearch extends React.Component {
    render() {
      return <Multiselect {...this.props} options={merge(searchOptions, this.props.options)} />
    }
}

export default MultiselectWithSearch;