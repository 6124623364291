import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual, cloneDeep } from 'lodash';
import CSV from '../../../helpers/CSVExport';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';

import config from '../../../config/config';
import Box from '../../common/Box';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { tracksActions } from '../../../data/actions/tracks';
import { StackedBarChart } from '../../widgets/charts/types';


class VendorsStats extends React.Component {
    
    constructor(props) {
        super(props);
        this.exportToCsv = this.exportToCsv.bind(this);        
    }
    
    exportToCsv(){
        let { tracks, id }  = this.props,
            exportData = tracks.vendorsComparison[id].data;
        const filename = CSV.CSVHeader(`track-comparison-vendors`, id);
        return CSV.CSVExport(exportData, {filename}, 'track-comparison-vendors');
    }

    renderToolbar(){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }



    componentDidMount() {
        const { id, weeks, metadata, territories, vendors } = this.props,
            releaseDate = metadata.release_date;
        this.props.dispatch(tracksActions.compareTrackVendors(id, weeks, releaseDate, territories, vendors))
        
    }
    
    componentWillReceiveProps(nextProps) {
    }

    
    render (){
        let { tracks, id, metadata, source, chartID = '' }  = this.props,
            chartData = cloneDeep(tracks.vendorsComparison[id]);
        
        
        if(chartData && source) {
            chartData.datasets = chartData.datasets.filter(dataset => dataset.source == source); 
        }
        

        const title = ( metadata && metadata.name_compare ) ? metadata.name_compare : id;
        return <Box title={title} spinnerEnabled={tracks.vendorsComparisonLoading} toolbar={this.renderToolbar()}>
        
            {chartData && <div className="row">
                <div className={`col-xs-12`}>
                    {chartData.datasets.length > 0 && <div className="chart-block">
                        <StackedBarChart data={chartData} id={chartID} />                          
                    </div>}
                    {!chartData.datasets.length && <p>No data available</p>}
                </div>
            </div>}
            
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        filter: state.filter,
        userData: state.user
    } 
}

export default connect(mapStateToProps)(VendorsStats);