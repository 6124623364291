import React from 'react';
import moment from 'moment';
import { concat, find } from 'lodash';
import { Link as LiveLink } from 'react-router-dom';
import ReadonlyLink from './ReadonlyLink';
import ReactTable from "react-table";

import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
const CellHeaderTable = cellHeaderHOC(ReactTable);
import {round, splitByThousands } from '../../../helpers/NumberFormatter';
import Tooltip from "../../common/Tooltip";

function getName(item, entity) {
    if(entity == 'playlists')
        return <Link to={`/${entity}/${item.id}/details`}>{item.name} {item.code && <span className="newline">{item.code}</span>} </Link>;
    else
        return <Link to={`/${entity}/${item.id}/${encodeURIComponent(item.name)}`}>{item.name}</Link>;
}

function dateDiff(item) {
    const addedAt = moment(item.added_at),
        removedAt = moment(item.removed_at || undefined);
    return Math.round(moment.duration(removedAt.diff(addedAt)).as('days'));
}

function summaryFooter(totals, key) {
    return <React.Fragment>{splitByThousands(totals[`${key}_income`], '$')}<br/>{splitByThousands(totals[`${key}_quantity`])}</React.Fragment>;
}

function getNameCell(item, entity, externalLinks) {
    const Link = externalLinks ? LiveLink : ReadonlyLink;
    return <Link to={`/${entity}/${item.id}/${encodeURIComponent(item.name)}`}>
        <div className="logo-container"><div className="logo" style={{backgroundImage: `url(${item.image})`}} /></div>
        <div className="name-info-holder">
            <p className="name">{item.name} {(item.name !='Total' && entity != 'artists' && entity != 'imprints') && <span className="name-by text">{item.artist_name ? `By: ${item.artist_name}` : null}</span>}</p> 
            <p className="text">{item.isrc && <React.Fragment>ISRC: {item.isrc}</React.Fragment>}</p>
            <p className="text">{(item.upc && item.cat_no) && <React.Fragment>ID: {item.cat_no} ({item.full_format})</React.Fragment>}</p>
            <p className="text">{item.project && <React.Fragment>Project: {item.project}</React.Fragment>}</p>
        </div>
    </Link>
}

function showPhysicalDigital(entity) {
	return ['imprints', 'artists', 'projects', 'products'].includes(entity);
}

export default function EntityTable(props){
    const { entity, totals, limit, externalLinks } = props;
    
    let columns = [
        {
            id: 'name',
            className: 'table-text-left name-cell', 
            headerClassName: 'table-text-left no-sort',
            footerClassName: 'table-text-left',
            Header: entity == 'artists'? 'Name' : 'Title',
            accessor: 'name',
            Cell: props => <span className="name-logo-container">{getNameCell(props.original, entity, externalLinks)}</span>,
            width: 200,
            sortable: false
        },
        {
            id: 'physical_income',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            footerClassName: 'table-text-right',
            Header: props => <span>Physical Income <br/> Physical Qty</span>,
            // Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
            // width: 70,
            accessor: 'physical_income',
            Cell: props => <span>{splitByThousands(props.value, '$')}<br/> {splitByThousands(props.original.physical_quantity)}</span>,
            sortable: false,
            show: showPhysicalDigital(entity)
        },
        {
            id: 'digital_income',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            footerClassName: 'table-text-right',
            Header: props => <span>Digital Income <br/> Digital Qty</span>,
            // Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
            // width: 70,
            accessor: 'digital_income',
            Cell: props => <span>{splitByThousands(props.value, '$')}<br/> {splitByThousands(props.original.digital_quantity)}</span>,
            sortable: false,
        },
        {
            id: 'nr_income',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            footerClassName: 'table-text-right',
            Header: props => <span>NR Income <br/> NR Qty</span>,
            // Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
            // width: 70,
            accessor: 'nr_income',
            Cell: props => <span>{splitByThousands(props.value, '$')}<br/> {splitByThousands(props.original.nr_quantity)}</span>,
            sortable: false,
        },        
        
        {
            id: 'total_income',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            footerClassName: 'table-text-right',
            Header: props => <span>Total Income <br /> Total Qty</span>,
            // Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
            // width: 70,
            accessor: 'total_income',
            Cell: props => <span>{splitByThousands(props.value, '$')}<br /> {splitByThousands(props.original.total_quantity)}</span>,
        },
        {
            id: 'share',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            footerClassName: 'table-text-right',
            Header: props => <span>Total Income<br /> Share</span>,
            // Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
            // width: 70,
            accessor: 'share',
            Cell: props => <span>{props.value}%</span>,
        },        
        // {
        //     id: 'physical_quantity',
        //     className: 'table-text-left', 
        //     headerClassName: 'table-text-left',
        //     Header: 'Physical Qty',
        //     // Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
        //     // width: 70,
        //     accessor: 'physical_quantity',
        //     Cell: props => <span>{splitByThousands(props.value)}</span>,
        // },       

        // {
        //     id: 'digital_quantity',
        //     className: 'table-text-left', 
        //     headerClassName: 'table-text-left',
        //     Header: 'Digital Qty',
        //     // Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
        //     // width: 70,
        //     accessor: 'digital_quantity',
        //     Cell: props => <span>{splitByThousands(props.value)}</span>,
        // },       

        {
            id: 'artists',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            footerClassName: 'table-text-right',
            Header: props => <span>Artists</span>,
            // Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
            // width: 70,
            accessor: 'artists',
            Cell: props => <span>{props.value}</span>,
            sortable: false,
            show: entity == 'imprints'
        },  
        {
            id: 'projects',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            footerClassName: 'table-text-right',
            Header: props => <span>Projects</span>,
            // Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
            // width: 70,
            accessor: 'projects',
            Cell: props => <span>{props.value}</span>,
            sortable: false,
            show: entity == 'imprints'
        },        
        {
            id: 'products',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            footerClassName: 'table-text-right',
            Header: props => <span>Products</span>,
            // Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
            // width: 70,
            accessor: 'products',
            Cell: props => <span>{props.value}</span>,
            sortable: false,
            show: ['imprints', 'projects'].includes(entity) 
        },        
        {
            id: 'tracks',
            className: 'table-text-right', 
            headerClassName: 'table-text-right no-sort',
            footerClassName: 'table-text-right',
            Header: props => <span>Tracks</span>,
            // Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
            // width: 70,
            accessor: 'tracks',
            Cell: props => <span>{props.value}</span>,
            sortable: false,
            show: entity == 'imprints'
        },        
        // {
        //     id: 'total_quantity',
        //     className: 'table-text-left', 
        //     headerClassName: 'table-text-left',
        //     Header: 'Total Qty',
        //     // Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
        //     // width: 70,
        //     accessor: 'total_quantity',
        //     Cell: props => <span>{splitByThousands(props.value)}</span>,
        // },
    ];
    const totalResults = totals && totals.hasOwnProperty('total_rows') ? totals.total_rows : limit; ;//(props.results && props.results.length) ? props.results.length : 10; 
    const pages = Math.ceil(totalResults/limit);
    
    for(let column of columns) {
        column.Footer = (props) => {
            const {id} = column;
            const [key, metric] = id.split('_');
            let value = totals[key];
            if(metric == 'income')
                value = summaryFooter(totals, key);
            else if(key == 'name')
                value = 'Total';
            return <div><span>{value}</span></div>
        };
    }
    return <CellHeaderTable
        manual
        data={props.results}
        columns={columns}
        defaultPageSize={limit}
        sortable={false}
        page={props.currentPage}
        pages={pages}
    defaultPageSize={Math.min(totalResults, limit)}    
    minRows={1}
        showPagination={(totalResults > limit)}
        showPageSizeOptions={false}
        loading={props.resultsLoading}
        onPageChange={(page)=>props.changePage(page)}
    />
    
}
