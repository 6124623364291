import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { uniq } from 'lodash';
import alpha from 'color-alpha';

import Spinner from '../../components/common/Spinner';
import AvatarUpload from '../../components/pages/user/AvatarUpload';
import { usersActions } from '../../data/actions/users';
// import { colors } from '../../widgets/charts/ChartDefaults';

class UserProfile extends Component {
    constructor(props) {
        super(props);

        const { selected = [] } = props,
            allSelected = (selected.length == 1 && selected[0]=='*');
        
        this.state = {
            currentLetter: null,
            // expanded: true
        }
        
        this.setLetter = this.setLetter.bind(this);
        // this.toggleExpand = this.toggleExpand.bind(this)
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(usersActions.getImprints());
    }  
    
    setLetter(currentLetter) {
        this.setState({currentLetter})
    }
    
    render() {
        const { user } = this.props.user,
            { imprintsLoading } = this.props.users;
        let { selected = [] } =  this.props, 
            { imprints } = this.props.users,
            { currentLetter } = this.state,
            letters = [],
            selectedLetters = [],
            itemIDs = [];
            
            
        if(imprints && imprints.length) {    
            itemIDs = imprints.map(imprint=>String(imprint.id));
        }
        
        if(selected && selected.length) {
            selected = selected.filter(selectedItem=>itemIDs.includes(selectedItem));
        }
            
        const totalItems = (imprints && imprints.length ) ? imprints.length : 0;
        
        if(imprints && imprints.length) {
            for(let item of imprints) {
                letters.push(item.letter);
                item.isSelected = selected.includes(String(item.id));
                if(item.isSelected)
                    selectedLetters.push(item.letter);
            }
            if(!currentLetter) 
                currentLetter = letters[0];

            imprints = imprints.filter(item=>item.letter == currentLetter);
        }
        else
            imprints = [];
        
        const showLetters = letters.length > 1;
        
        
        const selectedItems = imprints.map(item=>{
            return <li>{item.name}</li>
        });
        
        letters = uniq(letters).sort();
        selectedLetters = uniq(selectedLetters); 
        
        return (
          <div className="wrapper wrapper-content light-blue-bg inner-page users-management ">
            <h1>User Profile</h1>
            <div className="m-t">
              <div>
                <AvatarUpload user={user} />
              </div>
              <div>
                <p>
                  <span className="bold">Can Grant Access:</span>{" "}
                  {user.can_grant_access ? "Yes" : "No"}
                </p>
              </div>
              <div>
                <p>
                  <span className="bold">User Silos:</span>{" "}
                  {user.data_silo_codes}
                </p>
              </div>
              <p className="bold">Available Imprints</p>            
                <div className={`alphabetical-list-wrapper`}>
                  {imprintsLoading && <Spinner enabled={true} />}
                  <span className="show-user-title">{totalItems} total</span>
                  {showLetters && (
                    <ul className="alphabetical-list">
                      {letters.map((letter) => {
                        let classNames = [];
                        if (currentLetter == letter) classNames.push("active");
                        if (selectedLetters.includes(letter))
                          classNames.push("selected");
                        return (
                          <li
                            key={letter}
                            onClick={() => this.setLetter(letter)}
                            className={classNames.join(" ")}
                          >
                            {letter}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <ul className="alphabetical-list-items">{selectedItems}</ul>
                  {!imprints.length && (
                    <p>No available imprints</p>
                  )}
                </div>
            </div>

            <Helmet>
              <title>User Profile</title>
            </Helmet>
          </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        users: state.users,
    } 
}

export default connect(mapStateToProps)(UserProfile)