import { map, find, uniq, uniqBy, fill, sumBy, sortBy, capitalize } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand';
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import vendorsArray from '../../components/widgets/filter/enums/vendors';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import { formatChartLabel, formatChartLabelItem, sortDateRange } from '../../helpers/DateFormatter';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import {percentageValue} from '../../helpers/NumberFormatter';
import AddTotal from '../../components/common/Datagrid/AddTotal';
import VendorHelper from '../../components/common/Datagrid/VendorHelper';
import FixNumericFields from '../helpers/FixNumericFields';

export const tracksFormatter = {
    formatForPercentageChart,
    formatForTable,
    formatTop,
    formatDetails,
    formatTracklist,
    formatAutocomplete,
    formatAutocompleteItem,
    formatStatsTimeline,
    formatCard,
    formatStreamsStats,
    formatMetadata,
    formatTimeseries,
    formatCompareTracks,
    formatCompareTrackDemographics,
    formatCompareTrackTerritories,
    formatCompareTrackVendors,
    formatTracksReleaseDates
};

function formatForPercentageChart(data, column){
    let dataset = [], 
    labels = [];

    for(let entry of data) {
        dataset.push(entry[column]);
        labels.push(entry.name);
    }
    return {labels, datasets:[{data: dataset, label: 'Streams'}]};
}

function formatForTable(data){
    if(!data)
        return [];    
    
    for(let entry of data.data) {
        entry.id = entry.track_id;
        entry.name = entry.track_title;
        entry.logo = ImageURL(entry.track_image, entry.product_id, 'tracks');
        entry.engaged = Math.round((entry.curr_active / entry.curr_units) * 100);
    };
    data.data = AddTotal(data.data, 'track');
    return data;
}

function dateDiff(item) {
    if(!item.added_at)
        return 'N/A';
    const addedAt = moment(item.added_at),
        removedAt = moment(item.removed_at || undefined);

    
    return Math.round(moment.duration(removedAt.diff(addedAt)).as('days'));
}

function formatTop(data, addTotal){
    if(!data)
        return [];    
    data = map(data, (entry)=>{
        entry.id = entry.isrc;
        return entry;
    });
    if(addTotal)
    	data = AddTotal(data, 'track');
    return  FixNumericFields(data);
}

function formatDetails(data){
    if(!data)
        return [];

    let labels = [],
        dataset = [];
    
    data = data.sort(sortDateRange);
    
    for(let item of data) {
        labels.push(formatChartLabel(item));
        dataset.push(item.units);
    }
    return {labels, datasets: [{data: Object.values(dataset), label: 'plays'}]};
}

function formatTracklist(data){
    let active = [], 
        removed = []; 
    data = data.filter(item=>item.track);
    for(let item of data){
        item.id = item.track_id;
        item.logo = ImageURL(item.image, item.product_id, 'products');
        let artists = [];
        try {
            artists = JSON.parse(item.artists);
        }
        catch (e) {
            //todo: try decoding strings like
            //"---↵- :name: Franz Ferdinand↵  :vendor_id: 0XNa1vTidXlvJ2gHSsRi4A↵"
        };
        item.artists = artists;
        
        if(item.removed_at)
            removed.push(item);
        else
            active.push(item);
    };
    return {active, removed};
}

function _formatAutocompleteItem(hit){
    if(hit.entity)
        return hit;

    const entity = 'tracks',
        name = hit.name? hit.name : hit._source.title_and_version,
        artist = hit.artist_name? hit.artist_name : hit._source.artist,
        isrc = hit._source.isrc ? `(${hit._source.isrc}) ` : '',
        id = hit.id ? hit.id : hit._id;
    
    return {
        id: id,
        entity,
        name: `${name} ${isrc}(track by ${artist})`,
        logo: icons.entities[entity]
    }
}

function getWeekDay(date) {
    return moment(date).format('dddd');
}

function getWeekDayWarning(date) {
    console.log(moment(date).format('d'));
    return moment(date).format('dd') == 'Fr' ? '' : 'This Release date is not a Friday so please note that streams for Week 0 will not be representative of a full chart week and will not take into consideration potential DSP features which only refresh on a Friday.';
}

function formatAutocompleteItem(hit){
    const entity = 'tracks';
    const artist = hit.artist_name ? hit.artist_name : '';
    
    return {
        id: hit.id,
        entity,
        code: hit.isrc,
        first_released: hit.first_released,
        name: `${hit.title}${hit.version ? ' '+ hit.version : ''} ${hit.isrc} (track${artist ? ' by ' + artist : ''})`,
        name_compare: `${artist} - ${hit.title}`,
        name_result: `${artist} - ${hit.title} (${hit.isrc}) released on ${hit.first_released} (${getWeekDay(hit.first_released)})`,
        name_raw: hit.title,
        week_day_warning: getWeekDayWarning(hit.first_released),
        logo: icons.entities[entity]
    }
}


function formatAutocomplete(data) {
    let {tracks}= data;
    return tracks.map(formatAutocompleteItem);
}

function formatStatsTimelineSum(tracks) {
    let timeline = {};
    for(let track of tracks) {
        if(!track.stms_by_date)
            continue;
        for(let stream of track.stms_by_date) {
            let { stream_date, units } = stream;
            if(timeline[stream_date] === undefined) {
                timeline[stream_date] = 0;
            }
            timeline[stream_date] += units;
        }
    }
    return {
        labels: Object.keys(timeline), 
        datasets: [{
            label: 'Streams',
            data: Object.values(timeline)
        }]
    };
}

function formatStatsTimeline(data) {
    data = data.slice(0, 10);
    const {dates, period} = dateRange(data),
    datasets = data.map(entry=>{
        entry.stms_by_date = entry.stms_by_date.sort(sortDateRange);
        let data = [];
        for(let date of dates) {
            let stms = find(entry.stms_by_date, {stream_date: date});
            data.push(stms ? stms.units : null);
        }    
            
        return {
            label: entry.track_title,
            data,
            fill: false
        }
    });
    
    const labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    
    return {labels, datasets}

}


function formatCard(data) {
    data = data.data;
    return {
        id: data.id,
        name: `${data.title} (track)`,
        name_original: data.title,
        code: data.isrc,
        product: data.product_title,
        barcode: data.barcode,
        logo: data.product_image,
        primary_artist: data.primary_artist,
        release_date: data.original_release_date
    }
}

function formatStreamsStats(streams, metadata) {
    let labels = [],
        datasets = [];
    if(streams) {
        const {dates, period} = dateRangeFlat(streams);
        datasets = metadata.map((track, index)=>{
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, track_id: track.id});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: track.id,
                label: `${track.title} (${track.isrc})`,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };
}

function formatMetadata(entities, metadata, total) {
    const { total_income } = total;
    for(let entity of entities) {
        const metaEntity = find(metadata, {isrc: entity.id});
        if(metaEntity) {
            const artist = metaEntity.primary_artist;
            entity.name = entity.track_title = metaEntity.title;
            entity.image = ImageURL(metaEntity.product_image, metaEntity.product_id, 'tracks');
            entity.track_version = metaEntity.version;
            entity.track_genre = metaEntity.primary_genre;
            entity.duration = metaEntity.duration;
            
            entity.artist_id = artist.id;
            entity.artist_name = artist.name;

        }
        else if(entity.id === null) {
            entity.name = 'Adjustments';
            entity.artist_name = '';
            entity.image = null;        
        }

        else {
        	if(!entity.track_title) {
        		entity.name =  entity.id + ' - Unlisted Catalogue';
        	}
        }        
        entity.share = Math.round(entity.total_income/total_income*10000)/100;
    }
    return entities;

}

function formatTimeseries(streams, metadata) {
    let labels = [],
	    datasets = [];
	if(streams) {
	    const {dates, period} = dateRangeFlat(streams);
	    datasets = metadata.map((track, index)=>{
	        let data = [];
	        for(let date of dates) {
	            let stream = find(streams, {report_date: date, isrc: track.isrc});
	            data.push(stream ? Number(stream.total_income).toFixed(2) : null);
	        }    
	
	        return {
	            id: track.id,
	            label: track.title,
	            data,
	            fill: false,
	            borderColor: colors[index%colors.length],
	            backgroundColor: colors[index%colors.length],
	            pointBorderColor: colors[index%colors.length],
	            pointBackgroundColor: colors[index%colors.length],                
	
	        }
	    });    
	    
	    labels =  map(dates, (date)=>date)//formatChartLabelItem(date, 'Month'));
	}
	return { labels, datasets };

}

function formatCompareTracks(idsArray, data) {
    const idsArraySorted = idsArray.split(',');
    
    const ids = sortBy(uniq(map(data, 'isrc')), (id)=>idsArraySorted.indexOf(id)),
        weeks = uniq(map(data, 'weeks_since_release')).sort(),
        weeksCount = weeks.length,
        sources = ['album', 'artist', 'collection', 'other', 'playlist', 'radio', 'search'];
    
    let result = {},
        sourceResult = {};
    
    for(let id of ids) {
        result[id] = {
            streams: fill(Array(weeksCount), null),
            listeners: fill(Array(weeksCount), null),
            ratio: fill(Array(weeksCount), null),
            active: fill(Array(weeksCount), null),
            skipped: fill(Array(weeksCount), null)
        }
        let sourceItem = {};
        for(let source of sources) {
            sourceItem[source] = fill(Array(weeksCount), null);
        }
        sourceResult[id] = sourceItem; 
    }
    for(let item of data) {
        const {isrc, weeks_since_release: week, total_streams, listeners, listener_ratio, active, skipped} = item;
        result[isrc].streams[week] = total_streams;
        result[isrc].listeners[week] = listeners;
        result[isrc].ratio[week] = listener_ratio;
        result[isrc].active[week] = Math.round(active / total_streams * 100);
        result[isrc].skipped[week] = Math.round(skipped / total_streams * 100);
        
        let totalSources = 0,
            maxSourceKey = '',
            maxSource = 0;
        
        for(let source of sources) {
            const sourcePercentage = item[`src_${source}`];
            sourceResult[isrc][source][week] = sourcePercentage;
            totalSources += Math.round(sourcePercentage * 100);
            if(maxSource < sourcePercentage) {
                maxSource = sourcePercentage;
                maxSourceKey = source;
            }
        }
        
        /*
        
        if(totalSources != 10000) {
            
            let maxSourceValue = sourceResult[isrc][maxSourceKey][week],
                offset = (totalSources - 10000)  / 100;
            
            console.log(totalSources);
            console.log(maxSourceValue);
            console.log(offset);
            maxSourceValue = Math.round((maxSourceValue - offset) * 100) / 100;
            console.log(maxSourceValue);
            sourceResult[isrc][maxSourceKey][week] = maxSourceValue;
            
            
            let debugSum = 0;
            for(let source of sources) {
                debugSum += sourceResult[isrc][source][week];
            }
            
            console.log(debugSum);
        }
        */
    }
    //const sourceLabels = sources.map(source => capitalize(source));    
    return {datasets: result, labels: weeks, sourceDatasets: sourceResult, sourceLabels: sources, data};
}

function formatCompareTrackDemographics(data) {
    for(let item of data) {
        let [gender, age] = item.demographic.split(' ');
        item.age = age;
        item.gender = gender;
    }
    
    const genderOrder = ['Male', 'Female', 'Unknown'];
    
    let genders = uniq(map(data, 'gender')),
        ages = uniq(map(data, 'age')),
        datasets = {},
        totalStreams = sumBy(data, 'curr_units');
    
    ages.sort();
    genders = sortBy(genders, (gender) => genderOrder.indexOf(gender));

    for(let gender of genders) {
        datasets[gender] = [];
    }
    
    for(let entry of data) {
        datasets[entry.gender][ages.indexOf(entry.age)] = entry.curr_units;
        entry.share = Math.round(entry.curr_units / totalStreams * 100);
    }
    
    datasets = map(datasets, (data, label)=>{
        return {
            data, 
            label, 
        };
    });


    
    return {labels: ages, datasets, data};

}

function formatTracksReleaseDates(tracks, cards, ids) {
    
    let results = [];
    for(let track of tracks.data) {
        let result = {
            id: track.track_id,
            code: track.isrc,
            release_date: track.first_released
        }
        
        
        const card = find(cards, (card => card.data.isrc == track.isrc));
        if(card) {
            result.name_result = `${card.data.primary_artist.name} - ${card.data.title} (${track.isrc}) released on ${track.first_released} (${getWeekDay(track.first_released)})`;
            result.name_compare = `${card.data.title} - ${card.data.primary_artist.name}`;
            result.week_day_warning = getWeekDayWarning(track.first_released);
            result.primary_artist = card.data.primary_artist.name;
        }
        results.push(result);
    }
    const idsArraySorted = ids.split(',');
    results = sortBy(results, (result) => idsArraySorted.indexOf(result.code))
    return results;
}

function formatCompareTrackTerritories(data) {
    for(let itemIndex in data) {
        let item = data[itemIndex];
        item.code = item.territory;
        item.name = item.territory_name;
        item.share = Math.round((item.curr_units / item.total_units) * 100);
        item.rank = ++itemIndex;
    }
    return data;
}

function formatCompareTrackVendors(data) {
    let datasets = [];
    const weeks = uniq(map(data, 'weeks_since_release')).sort(),
        contentTypes = uniq(map(data, 'content_type')).sort(),
        vendors = uniq(map(data, 'vendor')).sort();
    
    for(let vendorID in vendors) {
        let vendor = vendors[vendorID];
        for(let contentType of contentTypes) {
            const vendorMeta = find(vendorsArray, (vendorItem)=>vendorItem.label == vendor);
            
            const streams = data.filter((item)=>(item.vendor == vendor && item.content_type == contentType));
            if(streams.length) {
                let color = colors[vendorID];
                if(vendorMeta && vendorMeta.hex[contentType])
                    color = vendorMeta.hex[contentType];
                    
                let dataset = fill(Array(weeks.length), 0);
                
                for(let stream of streams) {
                    dataset[stream.weeks_since_release] = stream.curr_units;
                }
                datasets.push({
                    label: vendor,
                    data: dataset,
                    source: contentType,
                    borderColor: color,
                    backgroundColor: color,
                    pointBorderColor: color,
                    pointBackgroundColor: color            
                })
                
            }
        }

        
        
        
    }
    return {labels: weeks, datasets, data};
}
