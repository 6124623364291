import { projectsConstants } from '../constants/projects';

export function projects(state = {data: [], product: {}, card: {}, autoCompleteLoading: false, forceLoadAllData: false }, action) {
    switch (action.type) {
        case projectsConstants.GET_PROJECTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case projectsConstants.GET_PROJECTS_SUCCESS:
            return {

                ...state,
                loading: false,
                items: action.projects,
                metadata: action.metadata,
                total: action.total
            };
        case projectsConstants.GET_PROJECTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case projectsConstants.GET_TOP_PROJECTS_REQUEST:
            return {
                ...state,
                topLoading: true,
                top: null,
                topTotal: null,
                timeseries: null
            };
        case projectsConstants.GET_TOP_PROJECTS_SUCCESS:
            return {

                ...state,
                topLoading: false,
                top: action.projects,
                topTotal: action.total,
                metadata: action.metadata,
                forceLoadAllData: false
            };
        case projectsConstants.GET_TOP_PROJECTS_FAILURE:
            return {
                ...state,
                topLoading: false,
                error: action.error,
                forceLoadAllData: false
            };

        case projectsConstants.GET_PROJECTS_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                autoCompleteLoading: true
            };
        case projectsConstants.GET_PROJECTS_AUTOCOMPLETE_SUCCESS:
            return {

                ...state,
                autoCompleteLoading: false,
                autocomplete_items: action.projects
            };
        case projectsConstants.GET_PROJECTS_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                autoCompleteLoading: false,
                error: action.error
            };
        case projectsConstants.GET_PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case projectsConstants.GET_PRODUCT_DETAILS_SUCCESS:
            let product = state.product;
            product[action.id] = action.product;
            return {
                ...state,
                loading: false,
                product: product
            };
        case projectsConstants.GET_PRODUCT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case projectsConstants.GET_PROJECTS_CARD_REQUEST:
            return {
                ...state,
                cardLoading: true
            };
        case projectsConstants.GET_PROJECTS_CARD_SUCCESS:
            let card = state.card;
            card[action.id] = action.card;
            return {
                ...state,
                cardLoading: false,
                card: card
            };
        case projectsConstants.GET_PROJECTS_CARD_FAILURE:
            return {
                ...state,
                cardLoading: false,
                error: action.error
            };

        case projectsConstants.GET_STREAMS_STATS_REQUEST:
            return {
                ...state,
                streamsLoading: true,
                streams: undefined
            };
        case projectsConstants.GET_STREAMS_STATS_SUCCESS:
            return {
                ...state,
                streamsLoading: false,
                streams: action.projects
            };
        case projectsConstants.GET_STREAMS_STATS_FAILURE:
            return {
                ...state,
                streamsLoading: false,
                error: action.error,
                streams: undefined
            };
        case projectsConstants.GET_PROJECTS_BY_BARCODES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case projectsConstants.GET_PROJECTS_BY_BARCODES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.projects
            };
        case projectsConstants.GET_PROJECTS_BY_BARCODES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case projectsConstants.GET_TRACKS_REQUEST:
            return {
                ...state,
                tracksLoading: true,
                tracks: null
            };
        case projectsConstants.GET_TRACKS_SUCCESS:
            return {

                ...state,
                tracksLoading: false,
                tracks: action.tracks,
            };
        case projectsConstants.GET_TRACKS_FAILURE:
            return {
                ...state,
                tracksLoading: false,
                error: action.error
            };


        case projectsConstants.GET_TIMESERIES_PROJECTS_REQUEST:
            return {
                ...state,
                timeseriesLoading: true,
                timeseries: null
            };
        case projectsConstants.GET_TIMESERIES_PROJECTS_SUCCESS:
            return {

                ...state,
                timeseriesLoading: false,
                timeseries: action.projects
            };
        case projectsConstants.GET_TIMESERIES_PROJECTS_FAILURE:
            return {
                ...state,
                timeseriesLoading: false,
                error: action.error
            };
            
        case projectsConstants.FORCE_LOAD_ALL_DATA: {
            return {
                ...state,
                forceLoadAllData: action.force,
                topLoading: true
            }
        }
            
        default:
            return state
    }
}
