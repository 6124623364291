import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { map, merge, sum, cloneDeep } from 'lodash';
import { colors, doughnut } from '../ChartDefaults';

class DoughnutChart extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            options: merge({}, doughnut, props.options)
        };
    }
    render() {
        const { data, options: propsOptions, chartID, ...rest} = this.props;
        let chartData = cloneDeep(data),
            { options } = this.state;
            
            
        if(chartData && chartData.datasets) {
            const dataset = chartData.datasets[0].data;
            const total = sum(dataset);
            for(let index in dataset) {
                const percent = Math.round(dataset[index]/total*100);
                // chartData.labels[index] = `${chartData.labels[index]} (${percent}%)`; 
            }
        }
            
        return (chartData ? <Doughnut data={chartData} options={options} id={chartID} {...rest}></Doughnut> : null);
    }
}

export default DoughnutChart