import { splitByThousands, convertToThousands} from '../../../helpers/NumberFormatter';

export function formatAxesLabel(value, index, values){
    return convertToThousands(value, 1);
}

export function formatTooltipLabel(tooltipItem, data){
    var valueLabel = data.datasets[tooltipItem.datasetIndex].label || '';
    return splitByThousands(tooltipItem.yLabel) + (valueLabel ?  ` ${valueLabel}`: '') 
}

export function formatTooltipLabelWorldMap(label, value){
    return label + (value ? (': ' + splitByThousands(value)) : '');
}

export function formatTooltipLabelPie(tooltipItem, data){
    var dataLabel = data.labels[tooltipItem.index],
        valueLabel = data.datasets[tooltipItem.datasetIndex].label,
        value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
    return `${dataLabel}: ${splitByThousands(value)} ${valueLabel}`;
}

export function formatTooltipLabelDoughnut(tooltipItem, data){
    var dataLabel = data.labels[tooltipItem.index],
        value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
    return `${dataLabel}: $${splitByThousands(value)}`;
}

