import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { pdf as PDFRenderer }from '@react-pdf/renderer';

import Box from '../../components/common/Box';
import { artistsActions } from '../../data/actions/artists';
import ArtistOverview from '../../components/pages/artists/ArtistOverview';
//import EntitiesListSelector from '../../components/pages/artists/EntitiesListSelector';
import EntityCard from '../../components/common/EntityCard';
import EntitiesList from '../../components/pages/home/EntitiesList';
import TerritoryStats from '../../components/common/Stats/Territory';
import DemographicsStats from '../../components/common/Stats/Demographics';
import DevicesStats from '../../components/common/Stats/Devices';
import SourcesStats from '../../components/common/Stats/Sources';
import FollowersStats from '../../components/pages/artists/Followers';
import VendorsWidget from '../../components/pages/home/VendorsWidget';
import TransactionTypes from '../../components/common/Stats/TransactionTypes';
import SharePage from '../../components/pages/sharedPages/SharePage';
import CSV from '../../helpers/CSVExport';
import { convertToThousands } from '../../helpers/NumberFormatter';
import { dspLogos } from "../../components/common/Stats/utils";
import PDFDocument from '../../components/pages/artists/PDF';

const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');

class ArtistProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
        };
        this.exportToPdf = this.exportToPdf.bind(this);
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({id: match.params.id, name: match.params.name });
        dispatch(artistsActions.getArtistProfile(match.params.id));
    }

    componentWillReceiveProps(nextProps){
        const { dispatch } = this.props;
        if(nextProps.filter){
            if(nextProps.filter.global){
                if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                   dispatch(artistsActions.getArtistProfile(this.state.id));
                }
            }
        }
        if(nextProps.match){
            const {match} = nextProps;
            if(match.params.id!==this.state.id)
                this.setState({id: null, name: null}, ()=>{
                    this.setState({id: match.params.id, name: match.params.name}, ()=>{
                       dispatch(artistsActions.getArtistProfile(this.state.id));
                    });
                });
        }
    }

    exportToCsv = () => {
      const {
        products,
        tracks,
        stats
      } = this.props;

      const data = [
        { top_products: products.top },
        { top_tracks: tracks.top },
        { platforms: stats.vendor.table },
        { territories: stats.territory.table },
        { transactions: stats.transactions[this.state.id] }
      ];
      
      const filename = CSV.CSVHeader('artist_profile', this.state.id, this.props.filter.global);

      CSV.CSVBulkExport(data, filename);
    }
    
    getPdfData() {
        const reportTitle = 'Artist Profile',
            { products, tracks, stats } = this.props,
            { id } = this.state;
            
        const imageIDs = [
              'top_territories_pie_chart',
              'top_vendors_pie_chart',
        ];
        let images = {};
        for (let imageID of imageIDs){
            let image = document.getElementById(imageID);
            if(image)
                images[imageID] = image.toDataURL("image/jpeg,1.0"); 
        }
        
        let vendorTableData = [];

        if (this.props.stats.vendor)
            vendorTableData = this.props.stats.vendor.table
            
        const artist = this.props.artists.profile[this.state.id] || {};
        const overview = [
            {title: "Artist", value: artist.artist},
            {title: "Income", value: artist.total_income ? convertToThousands(artist.total_income, '$') : ''},
            {title: "Followers", value: artist.spotify_followers ? convertToThousands(artist.spotify_followers, '$') : ''},
            {title: "Products", value: artist.curr_products },
            {title: "Tracks", value: artist.curr_tracks}
        ]

        const tables = {
            products: products.top,
            tracks: tracks.top,
            territories: stats.territory.table,
            transactions: stats.transactions[id],
            vendorsTable: vendorTableData,
            overview
        };
        return { reportTitle, images, tables, overview: stats.top, globalFilter: this.props.filter.global };
    }

    exportToPdf() {
        const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
        data.toBlob()
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "artist_profile.pdf";
            link.click();
        });
    }

    render() {
        if(!this.state.id)
            return null;

        const artist = this.props.artists.profile[this.state.id] || {};
        const name = this.state.name || `${this.state.id} - Unlisted Catalogue`;
        const productTitle = "Sales on a UPC level start populating from the product release date, apart from Apple where there are Instant grat tracks available for streaming on Apple Music. Use the entity filters to group ISRCs to have the full project visibility. In addition, note that due to the nature of reporting from some DSPs, it is sometimes not possible for us to map an ISRC to a UPC. In these instances, these products will appear in your Admin panel  for tou to review"
        const trackTitle = "Sales on a UPC level start populating from the product release date, apart from Apple where there are Instant grat tracks available for streaming on Apple Music. Use the entity filters to group ISRCs to have the full project visibility. In addition, note that due to the nature of reporting from some DSPs, it is sometimes not possible for us to map an ISRC to a UPC. In these instances, these products will appear in your Admin panel  for tou to review"
        
        const { sharedMode } = this.props;
        
        return <div className="artist-profile homepage">
            <div className="container">               
                {/* <ArtistOverview artist={artist} loading={this.props.artists.profileLoading} /> */}
                <h2 className="main-title">Artist profile</h2>
                <div className="row download-page">
	                <span>Export the data</span>
	                {sharedMode && <a key="download" title="Download entire page" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />}
                    {!sharedMode && <SharePage entity="artists" id={this.state.id} exportToCsv={this.exportToCsv} exportToPdf={this.exportToPdf} CSVEntities={["top_products", "top_projects", "top_tracks", "territories", "top_vendors"]} />}
	            </div>                
                <div className="main-artist-holder">
	                <div className="main-artist-image">
		                <div className="entity-card-item">
		                	<div className="logo" style={{backgroundImage: `url(${artist.image})`}} />
		                </div>
		            </div>
                
                    <div className="main-artist-name-holder">
                        <h3 className="middle-title">{artist.artist}</h3>
                    </div>
                    <div className="main-artist-details">
                        <div className="main-artist-details-item">
                            <div className="main-artist-details-title">Income</div>
                            <div className="main-artist-details-value">{artist.total_income ? convertToThousands(artist.total_income, '$') : ''}</div>
                        </div>
                        <div className="main-artist-details-item">
                            <div className="main-artist-details-title">Followers</div>
                            <div className="main-artist-details-value">{artist.spotify_followers ? convertToThousands(artist.spotify_followers) : ''}</div>
                        </div>
                        <div className="main-artist-details-item">
                            <div className="main-artist-details-title">Products</div>
                            <div className="main-artist-details-value">{artist.curr_products}</div>
                        </div>
                        <div className="main-artist-details-item">
                            <div className="main-artist-details-title">Tracks</div>
                            <div className="main-artist-details-value">{artist.curr_tracks}</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <TransactionTypes entity="artists" id={this.state.id} parentEntityTitle={name} />
                    </div>
                </div>
                <div className="artist-top-tables">
                	<EntitiesList description={productTitle} trends={true} title="Top Projects" entity="projects" parentEntity="artists" parentEntityIDs={this.state.id}  parentEntityTitle={name} addTotal={true} externalLinks={!sharedMode} />
                    <EntitiesList description={productTitle} trends={true} title="Top Products" entity="products" parentEntity="artists" parentEntityIDs={this.state.id} parentEntityTitle={name} addTotal={true} externalLinks={!sharedMode} />
                    <EntitiesList description={trackTitle} trends={true} title="Top Tracks" entity="tracks" parentEntity="artists" parentEntityIDs={this.state.id}  parentEntityTitle={name} addTotal={true} externalLinks={!sharedMode} />
                </div>
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <TerritoryStats entity="artists" ids={this.state.id}  parentEntityTitle={name} chartType={dspLogos.territories.id} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <VendorsWidget entity="artists" id={this.state.id} parentEntityTitle={name} />
                    </div>
                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {

    return {
        artists: state.artists,
        filter: state.filter,
        stats: state.stats,
        products: state.products,
        tracks: state.tracks,
        playlists: state.playlists
    }
}

export default connect(mapStateToProps)(ArtistProfile)
