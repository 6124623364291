import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEqual, sumBy } from 'lodash';
import Spinner from '../../common/Spinner';
import { statsActions } from '../../../data/actions/stats';
import { filterActions } from '../../../data/actions/filter';
import {round, splitByThousands } from '../../../helpers/NumberFormatter';

class StatementTotals extends React.Component {
    componentDidMount() {
        this.props.getStats();
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStats(false);
            }                
        }
    }


    render (){
        return <div>
            <h2 className="main-title main-title--mb">Latest Statements
                <div className="report-warning">These reports are for analytical purposes only and should not be used to pay artist royalties
                </div>
            </h2>
            <div className="catalog-overview-inner">
                <div className="statement-totals main-artist-details">
                	{this.props.stats.statementTotalsLoading && <Spinner enabled={true} />}
                    {/*this.props.stats.statementTotals && Object.keys(this.props.stats.statementTotals).map(month=>{
                        let statements = this.props.stats.statementTotals[month];
                        return <div key={month} className="main-artist-details-item">
                        <table>
                            <th><td colspan="3">{month}</td></th>
                            <tr><td>Gross</td><td>Charges</td><td>Net</td></tr>
                            {statements.map(statement=>{
                                return <tr><td>{splitByThousands(statement.gross_income, '$')}</td><td>{splitByThousands(statement.charges, '$')}</td><td>{splitByThousands(statement.net_income, '$')}</td></tr>
                            })}
                        </table>
                        </div>
                    })*/}
                    {this.props.stats.statementTotals &&  <table className="statements-totals-table">
                        {this.props.stats.statementTotals.map(row=><tr>
                            {row.map(col=><td>{col}</td>)}
                        </tr>)}
                    </table>}
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps){
    return {
        getStats: (cache=true)=> {
    		return dispatch(statsActions.getStatementTotals(cache))
        },
        toggleFilter: ()=>dispatch(filterActions.toggleGlobalFilter()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StatementTotals))