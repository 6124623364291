import React, {Component} from 'react';
import {connect} from 'react-redux';
import {commonActions} from '../../data/actions/common';
import { Dropdown } from 'react-bootstrap';

export class EntityAutocomplete extends React.Component {

  handleChange = (value, entity) => {
    const { getAutocomplete, onChange } = this.props;

    onChange(value);
    getAutocomplete(value, entity);
  }

  render() {
    const existData = Object.keys(this.props.results).length !== 0;
    const { getAutocomplete, results, entities, entity, onClickItem, show, currentSetting, reportName, entityName, value } = this.props;
    const placeholder = `Enter a ${entityName} name to add it to the notifications list`;
    const listOfUserEntities = entities.map(entity => entity.product.title);
    const searchWithoutSelectedData = existData && results.hits.filter(hit => !listOfUserEntities.includes(hit._source.title) );

    return (
      <div>
        <input value={value} placeholder={placeholder} onChange={e => this.handleChange(e.target.value, entity)} name={entity} type="text" className="form-control"/>
        {existData && <Dropdown show={show}>
          <Dropdown.Menu style={{overflowY: 'scroll', minWidth: 500, maxHeight: 200 }} as="ul">
            {
              searchWithoutSelectedData.map( item => {
                return (<Dropdown.Item as="li" key={item._source.id} eventKey={item._source.id}>
                  <a onClick={e => onClickItem(e, item, entity, currentSetting, reportName)} className="logo-container">
                    <span> { entity === 'products' ? item._source.title : item._source.name } </span>
                  </a>
                </Dropdown.Item>)
              })
            }
        </Dropdown.Menu>

      </Dropdown>
      }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { results: state.common.data }
}

function mapDispatchToProps(dispatch) {
  return {
    getAutocomplete: (value, entity) => {
      dispatch(commonActions.getAutocomplete(value, entity))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityAutocomplete);
