import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';

export const importsService = {
    getStatementPeriods,
    getPendingStatements,
    extractSalesData,
    uploadToStorage,
    importSalesData,
    approveSalesData,
    rejectSalesData,
    getUploadForm,
    uploadFile,
    getUrl,
    initiateCatalogImport,
    getCatalogImport,
    getCatalogImportErrors,
    insertCatalogExceptions,
    getCatalogExceptions,
    updateCatalogExceptions,
    refreshCatalogExceptions,
    getCatalogImports,
    undoCatalogImport    
};

function getStatementPeriods(){
    return config.live ? getStatementPeriodsLive() : getStatementPeriodsStatic();
}

function getStatementPeriodsLive() {
    
    let baseUrl = config.apis.secStat,
        requestOptions = {
            headers: AuthHeader()
        };
        
    baseUrl += '/list_statement_periods';
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getStatementPeriodsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/statement_periods.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getPendingStatements(){
    return config.live ? getPendingStatementsLive() : getPendingStatementsStatic();
}

function getPendingStatementsLive() {
    
    let baseUrl = config.apis.secStat,
        requestOptions = {
            headers: AuthHeader()
        };
        
    baseUrl += '/list_pending_statement_data';
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getPendingStatementsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/pending_statements.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function extractSalesData(qs) {
    let baseUrl = config.apis.secStat+'/extract_sales_data',
        requestOptions = {
            headers: AuthHeader()
        };
        
    baseUrl += '?' + $.param(qs);
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}


function uploadToStorage(qs) {
    let baseUrl = config.apis.secStat+'/upload_to_storage',
        requestOptions = {
            headers: AuthHeader()
        };
        
    baseUrl += '?' + $.param(qs);
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}


function importSalesData(qs) {
    let baseUrl = config.apis.secStat+'/import_sales_data',
        requestOptions = {
            headers: AuthHeader()
        };
        
    baseUrl += '?' + $.param(qs);
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function approveSalesData(qs) {
    let baseUrl = config.apis.secStat+'/approve_sales_data',
        requestOptions = {
            headers: AuthHeader()
        };
        
    baseUrl += '?' + $.param(qs);
    // don't wait for the response here
    fetch(baseUrl, requestOptions);
    return Promise.resolve({result: 'OK'});
}


function rejectSalesData(qs) {
    let baseUrl = config.apis.secStat+'/reject_sales_data',
        requestOptions = {
            headers: AuthHeader()
        };
        
    baseUrl += '?' + $.param(qs);
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getUploadForm(bucket, file) {
    let baseUrl = `${config.apis.secStat}/get_presigned_form/${encodeURIComponent(bucket)}/${encodeURIComponent(file)}`,
        requestOptions = {
            headers: AuthHeader()
        };    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function uploadFile(url, params, fileName) {
    let formData = new FormData();
    for(let key of Object.keys(params)) {
        if(key == 'body')
            formData.append('file', new File([new Blob([params['body']])], fileName))
        else
            formData.append(key, params[key]);
    }
    
    return fetch(url, {
        method: 'POST',
        body: formData
    })
    .then(res=>{   
        return res.headers;
    })
        

}

function getUrl(bucket, file) {
    let baseUrl = `${config.apis.secStat}/get_url/${encodeURIComponent(bucket)}/${encodeURIComponent(file)}`,
        requestOptions = {
            headers: AuthHeader()
        };    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function initiateCatalogImport(client_id, file_url) {
    let baseUrl = `${config.apis.miniCat}/v2/imports`,
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'}),
            body: JSON.stringify({client_id, file_url})
        };    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
    
}

function initiateCatalogImport(client_id, file_url) {
    let baseUrl = `${config.apis.miniCat}/v2/imports`,
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'}),
            body: JSON.stringify({client_id, file_url})
        };    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
    
}

function getCatalogImport(id) {
    let baseUrl = `${config.apis.miniCat}/v2/imports/${id}`,
        requestOptions = {
            headers: AuthHeader()
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getCatalogImportErrors(id) {
    let baseUrl = `${config.apis.miniCat}/v2/imports/${id}/errors`,
        requestOptions = {
            headers: AuthHeader()
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function insertCatalogExceptions() {
    let baseUrl = `${config.apis.secStat}/insert_exceptions`,
        requestOptions = {
            headers: AuthHeader()
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getCatalogExceptions(date){
    return config.live ? getCatalogExceptionsLive() : getCatalogExceptionsStatic();
}

function getCatalogExceptionsLive(date) {
    let baseUrl = `${config.apis.secStat}/get_exceptions`,
        requestOptions = {
            headers: AuthHeader()
        };
    if(date)
        baseUrl += `?date={date}`;
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getCatalogExceptionsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/exceptions.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function updateCatalogExceptions() {
    let baseUrl = `${config.apis.secStat}/update_exceptions`,
        requestOptions = {
            headers: AuthHeader()
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function refreshCatalogExceptions() {
    let baseUrl = `${config.apis.secStat}/refresh_exceptions`,
        requestOptions = {
            headers: AuthHeader()
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getCatalogImports(clientId) {
    let baseUrl = `${config.apis.miniCat}/v2/imports?client_id=${clientId}`,
        requestOptions = {
            headers: AuthHeader()
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}


function undoCatalogImport(id) {
    let baseUrl = `${config.apis.miniCat}/v2/imports/${id}/undo`,
        requestOptions = {
            headers: AuthHeader()
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}
