import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import store from '../../../helpers/store';

import ReactSelect from '../../common/ReactSelect';
import { isEqual, map, find } from 'lodash';
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
import Tooltip from '../../common/Tooltip';
import config from '../../../config/config';

import { territorySettings } from './Territory/Settings';
import { statsActions } from '../../../data/actions/stats';
import { convertToThousands, splitByThousands } from '../../../helpers/NumberFormatter';

import CSV from '../../../helpers/CSVExport';
import WorldMap from '../../common/WorldMap';
import DoughnutChart from '../../widgets/charts/types/DoughnutChart';
import LineChart from '../../widgets/charts/types/LineChart';
import Box from '../../common/Box';
import ExportModal from '../../common/ExportModal';
import LoadingMessage from '../../../helpers/LoadingMessage';
import ConfirmClick from '../../common/ConfirmClick';

var worldSvg = require('!svg-inline-loader!../../../../public/img/world.svg');
var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var xlsxSvg = require('!svg-inline-loader!../../../../public/img/xlsx.svg');

const CellHeaderTable = cellHeaderHOC(ReactTable);

class TerritoriesWidget extends Component {
    topQuantity = 10;
    options = [
       {label: 'Top Total Income', field: 'total_income', dir: 'DESC'},
       {label: 'Top Total Quantity', field: 'total_quantity', dir: 'DESC'},
       {label: 'Top Physical Income', field: 'physical_income', dir: 'DESC'},
       {label: 'Top Physical Quantity', field: 'physical_quantity', dir: 'DESC'},
       {label: 'Top Digital Income', field: 'digital_income', dir: 'DESC'},
       {label: 'Top Digital Quantity', field: 'digital_quantity', dir: 'DESC'},
       {label: 'Top NR Income', field: 'nr_income', dir: 'DESC'},
       {label: 'Top NR Quantity', field: 'nr_quantity', dir: 'DESC'},       
       {label: 'Bottom Total Income', field: 'total_income', dir: 'ASC'},
       {label: 'Bottom Total Quantity', field: 'total_quantity', dir: 'ASC'},
       {label: 'Bottom Physical Income', field: 'physical_income', dir: 'ASC'},
       {label: 'Bottom Physical Quantity', field: 'physical_quantity', dir: 'ASC'},
       {label: 'Bottom Digital Income', field: 'digital_income', dir: 'ASC'},
       {label: 'Bottom Digital Quantity', field: 'digital_quantity', dir: 'ASC'},
       {label: 'Bottom NR Income', field: 'nr_income', dir: 'ASC'},
       {label: 'Bottom NR Quantity', field: 'nr_quantity', dir: 'ASC'},               
    ];
    
    limits = [10, 25, 50, 500];
    
    constructor(props) {
        super(props);
        
        const territoryLimit = store.get('territoryLimit') || this.limits[0];
        
        this.state = {
            mode: 'table',
            page: 0,
            sort: this.options[0],
            limit: territoryLimit
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setSort = this.setSort.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToXls = this.exportToXls.bind(this);
        this.setPage = this.setPage.bind(this);
        this.setLimit = this.setLimit.bind(this);
        this.getTimeseries = this.getTimeseries.bind(this);
    }
    
    setChartMode(mode){
        this.setState({mode}, ()=>{
            if(mode == 'line')
                this.getTimeseries();
            else
                this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit, page: this.state.page});
        });
    }
    
    setPage(page){
        this.setState({page}, ()=>{
            const { territoryLimit } = this.state;
            if(territoryLimit !== 500)
                this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit, page: this.state.page}, false);
        });
    }
    

    handleChange(event) {
        this.setState({value: event.target.value});
    }
    
    setSort(sort){
        this.setState({sort}, ()=>{
            this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit, page: this.state.page}, false);
        })
    }
    
    setLimit(limit){
        const { mode } = this.state;
        this.setState({limit: limit.value, page: 0}, ()=>{
            store.set('territoryLimit', limit.value);
            this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit, page: this.state.page}, false)
            .then(()=>{
                if(mode == 'line')
                    this.getTimeseries();
            })
            
        })
    }
    
    exportToCsv(full = false){
        const getData = full ? 
        this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: config.fullCSVThreshold, page: this.state.page}, false) : Promise.resolve(this.props.stats.topTerritory);
        getData.then(()=>{

            const filename = CSV.CSVHeader('top_territories', this.state.sort, this.props.filter.global); 
            return CSV.CSVExport(this.props.stats.topTerritory.table, {filename}, 'territories');
        });
    }
    
    exportToCsvTimeseries = () => {
        const data = this.props.stats.topTerritoryTimeseries;
        let table=[];
        for(let row of data.datasets) {
            let tableRow = {code: row.id, name: row.label};
            for(let labelIndex in data.labels) {
                tableRow[data.labels[labelIndex]] = row.data[labelIndex];
            }
            table.push(tableRow);
        }
        const filename = CSV.CSVHeader('top_territories_timeseries', this.state.sort.field, this.props.filter.global);
        return CSV.CSVExport(table, {filename});
        
    }
    
    exportToXls(){
        this.getTimeseries().then(timeseries => {
            const filename = CSV.CSVHeader('top_territories', this.state.sort.field, this.props.filter.global);
            let data = this.props.stats.topTerritoryCache;
            data = CSV.expandTimelineFlat(data, timeseries, 'territory');
            return CSV.XLSExport(data, {filename}, 'territories');
            
        });
    }

        
    renderToolbar(modes){
        let toolbar = [];
        const limitOptions = this.limits.map(limit => ({
            label: limit == 500 ? 'All' : limit,
            value: limit        
        })),
        limitValue = find(limitOptions, {value: this.state.limit});
        const { mode } = this.state;

        toolbar.push(<div key="wrapper" className="ibox-action-holder">
            <div className="ibox-actions">
            Limit: <ReactSelect value={limitValue} options={limitOptions} onChange={this.setLimit} className="single-select select-limit"/>
            <ReactSelect value={this.state.sort} options={this.options} onChange={this.setSort} className="single-select top-single-select"/>
            {/*
            <ConfirmClick confirmAction={this.exportToCsv} title="Export CSV" confirmClass="" confirmLabel={<span key="download" className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />} confirmPrompt="These reports are for analytical purposes only and should not be used to pay artist royalties"></ConfirmClick>
            */}
            
            {mode == 'table' && <ExportModal exportToCsv={this.exportToCsv} loading={this.props.stats.topTerritoryLoading} />}
            {mode != 'table' && <a key="download" title="Export" onClick={this.exportToCsvTimeseries} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />}

            
            </div>
        </div>)
        
        for (let mode in modes) {
             toolbar.push(<a key={mode} className="ibox-link"><i className={`fa fa-${modes[mode]} ${this.state.mode == mode ? 'active' : ''}`} onClick={()=>this.setChartMode(mode)}></i></a>);                
         }      


        return toolbar;
    }
    
    componentDidMount() {
        this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit, page: this.state.page}, false);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit, page: this.state.page}, false)
                .then(territories => {
                    const { mode } = this.state;
                    if(mode != 'table')
                        this.getTimeseries();
                })
            }                
        }
        
        if(nextProps.stats.forceLoadAllTopTerritoriesData && !this.props.stats.forceLoadAllTopTerritoriesData) {
            console.log('loading export data for top territories');
            this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: config.fullCSVThreshold, page: 0}, false);
        }

    }
    
    getTimeseries(){
        const ids = this.props.stats.topTerritory ? map(this.props.stats.topTerritory.table, 'code') : [];
        return this.props.getTimeseries({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit, page: this.state.page, ids}, false);
    }

    renderCountry(country) {
        if(this.state.sort.field == 'per_cap_units')
            return `${country.name} (${Math.round(country.perCapita*100)/100})`;
        else
            return country.name;
    }

    renderTooltip() {
        let tooltip = [];
        tooltip.push(
            <Tooltip 
                position="bottom" 
                message={"This section is independent from the Main Filter Limit in order to give you an accurate territory market share no matter how many results you’re looking at. By default you can see the top 10 but you can toggle this in the Limit drop down menu on the right. You can still select a specific Vendor in the Main Filter."} 
                tooltipClass="top-sections-tooltip"
            />
        )

        return tooltip;
    }
    
    render() {
        const { limit, mode } = this.state;
        let modes = {
            'table': 'table',
//            'pie': 'chart-pie',
            'line': 'chart-line',
            },
            threshold = 1000000,
            topCount = 0,
            topSum = 0,
            countriesOverThreshold = 0,
            countries;
        
        if(this.props.stats.topTerritory){
            countries = this.props.stats.topTerritory.table;
            for(let country of countries){
                let streams = Number(country.value);
                if(topCount<this.topQuantity) {
                    topCount++;
                    topSum += streams;
                } 
                if(streams<threshold)
                    continue;
                countriesOverThreshold++;
            }
        }
        let totalRows = limit;
        let columns = territorySettings.settingsForTable();
        const pageSize = Math.min(totalRows, 50);
        
        if(this.props.stats.topTerritoryTotals) {
            const totals = this.props.stats.topTerritoryTotals; 
            totalRows = totals.total_rows;
            
            for(let column of columns) {
                column.Footer = (props) => {
                    const {id} = column;
                    const [key, metric] = id.split('_');
                    let value = totals[key];
                    if(metric == 'income')
                        value = <React.Fragment>{splitByThousands(totals[`${key}_income`], '$')}<br/>{splitByThousands(totals[`${key}_quantity`])}</React.Fragment>
                    else if(key == 'name')
                        value = 'Total';
                    return <div><span>{value}</span></div>
                };
            }
                        
        }

        const title = <React.Fragment>Territories <br/><div className="small-description">This section is independent from the Main Filter Limit in order to give you an accurate territory market share no matter how many results you’re looking at. By default you can see the top 10 but you can toggle this in the Limit drop down menu on the right. You can still select a specific Vendor in the Main Filter.</div></React.Fragment>
        return (
          <div className="table-with-chart-holder">
            <Box title={title} toolbar={this.renderToolbar(modes)} loadingMessage={LoadingMessage('overview')} spinnerEnabled={this.props.stats.topTerritoryLoading || this.props.stats.topTimeseriesLoading} className="ibox-container box-table">
                {this.props.stats.topTerritory && <div className="row">

                    <div className={`col-xs-12 col-md-12 `}>
                      {mode == 'table' && <div className="territory-table territory-table--single custom-scroll">
                        <CellHeaderTable
                          className=""
                          data={this.props.stats.topTerritory.table}
                          columns={columns}
                          defaultPageSize={pageSize}
                        minRows={1}
                        page={this.state.page}
                        pages={Math.ceil(totalRows/pageSize)}
                        sortable={false}
                        onPageChange={this.setPage}
                        showPagination={totalRows > pageSize}
                        showPageSizeOptions={false}
                        />
                      </div>}
                      {(mode == 'line' && !this.props.stats.topTimeseriesLoading) && <div className="chart-block chart-block--full custom-scroll">
                          <LineChart data={this.props.stats.topTerritoryTimeseries} />
                      </div> }
                      {/* <div className="chart-block chart-block--full custom-scroll">
                        {this.state.mode == 'map' && <WorldMap data={this.props.stats.topTerritory.world} label="plays" /> }
                        {this.state.mode == 'pie' && <PieChart data={this.props.stats.topTerritory.chart} /> }
                        
                      
                      </div> */}
                    </div>
                </div> }
            </Box>
            {mode == 'table' && <div className="table-chart-holder">
              <div className="table-chart-holder-inner"> 
                <div className="chart-title">Territories</div>
                {this.props.stats.topTerritory && <DoughnutChart data={this.props.stats.topTerritory.chart} chartID="top_territories_pie_chart" />}
              </div>
            </div>}
          </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch){
    return {
        getTerritories: (params, cache=true)=>dispatch(statsActions.getTopTerritoryStats(params, cache)),
        getTimeseries: (params, cache=true)=>dispatch(statsActions.getTopTerritoryTimeseries(params, cache)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TerritoriesWidget)