import { encrypt } from '../../helpers/crypto';
import config from '../../config/config';

export const sharedPagesFormatter = {
    formatSharedPages,
    formatSharedUser
};

function formatSharedPages(data, user) {
    for(let item of data) {
        item.title = item.title || '-- share title TBD --';
        item.module = item.module || 'catalog';
    }
    return data.filter(item=> item.user_id == user.id);
}

function formatSharedUser(user){
    user.sharedToken = true;
    // token is encrypted for share domain
    return encrypt(JSON.stringify(user), config.apis.shared);
}