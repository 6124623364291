import React from 'react';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import TransactionTypesPDF from '../../common/Stats/TransactionTypes/PDF';
import OverviewPDF from '../../common/PDF/Overview';
import DateRange from '../../common/PDF/DateRange';
import MainFilterSummary from '../../common/PDF/MainFilterSummary';
import EntityTablePDF from '../home/EntityTable/PDF';
import TerritoryTablePDF from '../home/Territory/PDF';
import VendorTablePDF from '../home/Vendor/PDF';

export default (props) => {
    const { data: { images, tables, reportTitle, overview, grid, globalFilter } } = props;
    const allViews = {
            "transactions": <View style={styles.columnSection}>
                <View style={styles.column}>
                  <Text style={styles.smallHeader}>Transaction Types</Text>
                  <TransactionTypesPDF data={tables.transactions} />
                </View>
            </View>,
            "top_artists": <View style={styles.columnSection}>
                <View style={styles.column}>
                  <Text style={styles.smallHeader}>Top Artists</Text>
                  <EntityTablePDF entity="artists" data={tables.artists} />
                </View>
              </View>,
              "top_projects": <View style={styles.columnSection}>
                    <View style={styles.column}>
                        <Text style={styles.smallHeader}>Top Projects</Text>
                        <EntityTablePDF entity="projects" data={tables.projects} />
                    </View>
              </View>,
            
            "top_products": <View style={styles.columnSection}>
                <View style={styles.column}>
                  <Text style={styles.smallHeader}>Top Products</Text>
                  <EntityTablePDF entity="products" data={tables.products} />
                </View>
          </View>,
          "top_tracks": <View style={styles.columnSection}>
                <View style={styles.column}>
                    <Text style={styles.smallHeader}>Top Tracks</Text>
                    <EntityTablePDF entity="tracks" data={tables.tracks} />
                </View>
          </View>,
          "top_territories": <View style={styles.columnSection}>
          <Text style={styles.smallHeader}>Territories</Text>
          <View style={styles.rowSection}>
            <View style={styles.columnSection}>
                <TerritoryTablePDF data={tables.territories} />
            </View>
            <View style={styles.imageColumn}>
                <Image style={styles.imageBlock} source={images.top_territories_pie_chart} />
            </View>
          </View>
      </View>,
      "top_vendors": <View style={styles.columnSection}>      
        <Text style={styles.smallHeader}>Platforms</Text>
          <View style={styles.rowSection}>
            <View style={styles.columnSection}>
                <VendorTablePDF data={tables.vendorsTable} />
            </View>
            <View style={styles.imageColumn}>
                <Image style={styles.imageBlock} source={images.top_vendors_pie_chart} />
            </View>
          </View>
      </View>

    };
  return <Document>
    <Page size="A4" style={styles.page}>
        <View style={styles.reportTitle}>
            <Text style={styles.roayltyWarning}>These reports are for analytical purposes only and should not be used to pay artist royalties</Text>        
            <Text style={styles.header}>{reportTitle}</Text>
        </View>
        <DateRange filter={globalFilter} />
        <MainFilterSummary data={globalFilter} />
        <OverviewPDF mode="overview" data={tables.overview} />
        <View style={styles.section}>
            
        </View>    
        {Object.values(allViews)}
    </Page>
  </Document>
};