import React from 'react';
import copyToClipboard from '../../helpers/copyToClipboard';

var copySvg = require('!svg-inline-loader!../../../public/img/copy-icon.svg');


class CopyToClipboard extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            justCopied: false
        }
        
        this.setCopied = this.setCopied.bind(this);
    }

    setCopied(justCopied) {
        this.setState({justCopied});
        if(justCopied)
            setTimeout(()=>this.setCopied(false), 3000);
    }
    
    render() {
        return <div>
            {!this.state.justCopied && <div className="copy-holder" dangerouslySetInnerHTML={{__html: copySvg}} onClick={()=>{copyToClipboard(this.props.value); this.setCopied(true); }} />}
            {this.state.justCopied && <span className="copy-holder">Copied</span>}
        </div>
    }
}

export default CopyToClipboard;