export default {
    entities: {
        imprints: require('!svg-inline-loader!../../public/img/stacked-vinyl-records.svg'),
        artists: require('!svg-inline-loader!../../public/img/user.svg'),
		projects: require('!svg-inline-loader!../../public/img/stacked-vinyl-records.svg'),
        products: require('!svg-inline-loader!../../public/img/vinyl.svg'),
        tracks: require('!svg-inline-loader!../../public/img/microphone.svg'),
        playlists: require('!svg-inline-loader!../../public/img/playlist.svg'),
    }
}
