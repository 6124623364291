import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PercentageValue from '../../../common/PercentageValue';
import AbsoluteValue from '../../../common/AbsoluteValue';
import Tooltip from '../../../common/Tooltip';
import EntityCard from '../../../common/SharedEntityCard';
import ConfirmClick from '../../../common/ConfirmClick';
import { splitByThousands, percentageValue } from '../../../../helpers/NumberFormatter';

export const statementPeriodsSettings = {
    settingsForTable
};

function formatCell(value){return splitByThousands(Number.parseFloat(value).toFixed(2), '$')};

function settingsForTable(loadData){

    let columns = [
            {
                id: 'period',
                Header: 'Period',
                accessor: 'period',
            },
           
            {
                id: 'digital_income',
                Header: 'Digital Income',
                accessor: 'digital_income',
                Cell: props => <span>{formatCell(props.value)}</span>,
            },
            
            {
                id: 'physical_income',
                Header: 'Physical Income',
                accessor: 'physical_income',
                Cell: props => <span>{formatCell(props.value)}</span>,
            },
            {
                id: 'total_income',
                Header: 'Total Income',
                accessor: 'total_income',
                Cell: props => <span>{formatCell(props.value)}</span>,
            },
            {
                id: 'actions',
                Header: 'Actions',
                accessor: 'period',
                Cell: props => props.original.total_income ? null : 
                    <ConfirmClick confirmAction={()=>loadData(props.value)} title="LoadData" confirmClass="" confirmLabel="Load Data" confirmPrompt={`About to Load Data for ${props.value}`}></ConfirmClick>
            }
            ];
            
    return columns; 
}
