import React from 'react';
import ClickOutsideHandler from '../../common/ClickOutsideHandler';

var imprintsSvg = require('!svg-inline-loader!../../../../public/img/labels.svg');
var artistsSvg = require('!svg-inline-loader!../../../../public/img/artists.svg');
var productsSvg = require('!svg-inline-loader!../../../../public/img/products.svg');
var projectsSvg = require('!svg-inline-loader!../../../../public/img/projects.svg');
var tracksSvg = require('!svg-inline-loader!../../../../public/img/tracks.svg');

export default function Accordion(props) {
    const opened = props.accordion[props.id],
        { readonly = false } = props, 
        filter = props.filter[props.id];
    let itemsCount = null;
    if(filter && Array.isArray(filter) && props.id != 'imprints')
        itemsCount = filter.length || 'All'; 
    
    return <div className={`form-group ${props.className} ${opened ? 'opened' : ''}`}>  
        <ClickOutsideHandler preventInner={true} onClickOutside={(e) => { if(!readonly) {props.closeAccordion(props.id)} }}>
            <label className="font-normal filter-label" onClick={(e)=>{ if(!readonly) {props.toggleAccordion(props.id)} }}>
                {props.label}{(itemsCount ? ` (${itemsCount})` : '')} {props.vendorsCount && `(${props.vendorsCount})`}
                {!readonly && <span className={`caret ${opened && 'opened'}`}></span>}
                
                <div className="filter-date-range-values">
                    { props.dateStart && props.dateEnd && (`${props.dateStart} to ${props.dateEnd}`) }
                    { props.genresList && props.genresList }
                    { props.source && props.source }
                    { props.selectedReleaseDateLabel && props.selectedReleaseDateLabel }
                    { props.id == 'imprints' && <ul className="accordeon-filter-search-results">
                        <li className="accordeon-filter-search-item"><span className="accordeon-filter-search-icon" dangerouslySetInnerHTML={{__html: imprintsSvg}} /> {props.filter.imprints? props.filter.imprints.length : 0}</li>
                        <li className="accordeon-filter-search-item"><span className="accordeon-filter-search-icon" dangerouslySetInnerHTML={{__html: artistsSvg}} /> {props.filter.artists ? props.filter.artists.length : 0}</li>
                        <li className="accordeon-filter-search-item"><span className="accordeon-filter-search-icon" dangerouslySetInnerHTML={{__html: projectsSvg}} /> {props.filter.projects ? props.filter.projects.length : 0}</li>
                        <li className="accordeon-filter-search-item"><span className="accordeon-filter-search-icon" dangerouslySetInnerHTML={{__html: productsSvg}} /> {props.filter.products ? props.filter.products.length : 0}</li>
                        <li className="accordeon-filter-search-item"><span className="accordeon-filter-search-icon" dangerouslySetInnerHTML={{__html: tracksSvg}} /> {props.filter.tracks ? props.filter.tracks.length : 0}</li>
                    </ul>}
                </div>
            </label>

            {opened && 
                <div className={`form-group-inner ${props.id}`}>
                    {props.children}
                </div>
            }
        </ClickOutsideHandler>
    </div>
    
}
