import React from 'react';
import { connect } from 'react-redux';

import Spinner from '../../common/Spinner';

import { filterActions } from '../../../data/actions/filter';
import LoadingMessage from '../../../helpers/LoadingMessage';

class UserTablesLoader extends React.Component {
    render (){
        const {filter: {maxDate, global}, children, route} = this.props;
        const ready = ( maxDate && global && global.dateStart);
        if(ready) {
            return children;
        }   
        else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps){
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserTablesLoader)