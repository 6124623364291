import React from 'react';
import { filter, isEqual, map, sumBy } from 'lodash';

import BarChart from '../../../widgets/charts/types/BarChart';
import PieChart from '../../../widgets/charts/types/PieChart';
import Box from '../../../common/Box';
import countriesByAbbreviation from '../../../common/CountriesByAbbreviation';
import ReactSelect from '../../../common/ReactSelect';
import { audienceActions } from '../../../../data/actions/audience'; 


class VendorBarChart extends React.Component {
    
    
    renderStackedChart(data) {
        const options = {
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    barThickness: 20,
                    stacked: true,
                    display: false
                }],
                yAxes: [{
                    barThickness: 20,
                    stacked: true,
                    display: false
                    
                }]
            },            
            legend: {
                display: false,
            },
            tooltips: {
                mode: 'nearest',
                yAlign: 'center',
                callbacks: {
                    label: function(tooltipItem, data) {
                        const vendor = data.datasets[tooltipItem.datasetIndex].label;
                        return vendor + ': ' + tooltipItem.xLabel + '%';
                    }
                }

            }
        };
        return <BarChart data={data} options={options} horizontal={true} />
    }
    
    render (){
        const { vendors } = this.props;
        const total = sumBy(vendors, 'curr_units');
        
        let barDataset = [],
            maxPercent = 0,
            maxIndex = 0,
            totalPercent = 0;
    
        for(let vendorIndex in vendors) {
            let vendor = vendors[vendorIndex]
            const percent = Math.round(vendor.curr_units / total * 100);
            totalPercent += percent;
            
            if(percent > maxPercent) {
                maxPercent = percent;
                maxIndex = vendorIndex;
            }
    
            barDataset[vendorIndex] = {
                label: vendor.vendor_value,
                data: [percent],
                backgroundColor: vendor.color
            };              
        }
        if(barDataset[maxIndex])
            barDataset[maxIndex].data[0] = barDataset[maxIndex].data[0] - totalPercent + 100;
        const chart = {labels: ['Streams by Vendor'], datasets: barDataset};
        return <div className="chart-block top-vendor-chart-block">{this.renderStackedChart(chart)}</div>
    }
}


export default VendorBarChart;