import { map, uniq, capitalize, sumBy, find, orderBy, sum } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand'; 
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { formatChartLabelItem } from '../../helpers/DateFormatter';
import {dateRangeFlat} from '../../helpers/DateRange';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import countriesByAbbreviation from '../../components/common/CountriesByAbbreviation';

export const audienceFormatter = {
    formatStreamsStats,
    formatDemographicsStats,
    formatTerritoryStats,
    formatDemographicsTerritoryStats,
    formatSourceStats,
    formatPlaylistStats,
    formatRepeatListeners,
    formatTopPlaylistStats,
    formatTopPlaylistStatsMeta
};

function formatStreamsStats(data) {
    let labels = [],
    datasets = [{
        id: 0,
        label: 'Total Streams',
        total: 0,
        data: [],
        fill: false,
        borderColor: colors[1],
        backgroundColor: colors[1],
        pointBorderColor: colors[1],
        pointBackgroundColor: colors[1],                        
    }, {
        id: 1,
        label: 'Unique Listeners',
        total: 0,
        data: [],
        fill: false,
        borderColor: colors[0],
        backgroundColor: colors[0],
        pointBorderColor: colors[0],
        pointBackgroundColor: colors[0],                        
    }
    ];
    if(data) {
        
        
        for(let item of data) {
            item.stream_date = item.period_date;
            datasets[0].data.push(item.total_streams);
            datasets[1].data.push(item.listeners);
            datasets[0].total += item.total_streams;
            datasets[1].total += item.listeners;
        }
        
        const {dates, period} = dateRangeFlat(data);
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };

}

function formatDemographicsStats(data) {
    const genderOrder = ['Male', 'Female', 'Unknown'];
    
    for(let item of data) {
        item.stream_date = item.period_date;
        item.demographic = item.age + ' ' + item.gender;
    }
    
    let demos = uniq(map(data, 'demographic')),
        datasets = {},
        timelineLabels = [],
        timelineDatasets = [],        
        grandTotalListeners = sumBy(data, 'listeners');
    
    demos.sort();

    for(let demo of demos) {
        datasets[demo] = [];
    }
        
    const {dates, period} = dateRangeFlat(data);
    datasets = demos.map((demo, index)=>{
        
        let days = [],
            totalListeners = 0,
            totalStreams = 0,
            gender = '',
            age = '';
        
        for(let date of dates) {
            let stream = find(data, {period_date: date, demographic: demo});
            let listeners = stream ? stream.listeners : null;
            let streams = stream ? stream.total_streams : null;  
            if(!gender && stream)
                gender = stream.gender;
            if(!age && stream)
                age = stream.age;
            
            
            days.push(listeners);
            totalListeners += Number(listeners);
            totalStreams += Number(streams);
        }    
            
        return {
            label: demo,
            data: days,
            fill: false,
            gender,
            age,
            listeners: totalListeners,
            streams: totalStreams,
            share: Math.round(totalListeners / grandTotalListeners * 10000) / 100
        }
    });
    
    datasets = orderBy(datasets, ['totals'], ['desc']);
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));
    
    return {labels: timelineLabels, datasets: datasets};
}

function formatTerritoryStats(data, limit = 10) {
    if(!data)
        return {};
        
    let world = {},
        table;
    
    const total = sumBy(data, 'listeners');
    
    table = map(data, (territory=>{        
        const code = territory.territory,
            country = find(countriesByAbbreviation, {abbreviation: code});
        
        if(territory.territory == 'ZZ')
            territory.territory_name = 'Unknown';
        
        territory.name = country ? country.country : '-';
        territory.code = code;
        territory.value = territory.listeners;
        territory.share = Math.round(territory.listeners/total*100);
        return territory;
    }));
    table = orderBy(table, ['value'], ['desc']);
    
    for (let rank = 0; rank < table.length; rank++) {
        let item = table[rank];
        item.rank = rank + 1; 
    }
    
    for(let territory of table.slice(0, limit)){
        world[territory.code] = territory.value;
    }                
    
    return {world, table};
}

function formatDemographicsTerritoryStats(data) {
    let genderOrder = ['Male', 'Female', 'Unknown'];
    
    let genders = uniq(map(data, item=>item.gender));
    let ages = uniq(map(data, item=>item.age)).sort();
    genders = genderOrder.filter(gender => genders.includes(gender));
    let colorIndex = 0;
    let gendersMap = {};
    let agesMap = {};
    
    for(let gender of genders) {
        gendersMap[gender] = colors[colorIndex];
        colorIndex++;
    }
    
    for(let age of ages) {
        agesMap[age] = colors[colorIndex];
        colorIndex++;
    }
    
    return {genders: gendersMap, ages: agesMap, data};
}

function formatSourceStats(data) {
    let labels = [],
    dataset = [],
    total = 0;

    for(let entry of data) {
        labels.push(entry.source);
        dataset.push(entry.listeners);
        total += entry.listeners;
    }
    
    
    data = data.map(entry=>{
        entry.share = Math.round((entry.listeners/total)*100);
        return entry;
    });
    
    return {chart: {labels, datasets: [{ data:dataset, label: "Listeners"}]}, table: data};
}

function formatPlaylistStats(data) {
    for(let index in data) {
        let item = data[index];
        item.vendor = 'Spotify';
        item.rank = Number(index) + 1;
        item.ratio = Number(item.total_streams / item.listeners).toFixed(1);
    }
    return data;
}


function formatRepeatListeners(listens) {
    let thresholds = {11: [1001, 501, 101, 11], 6: [9, 8, 7, 6], 2: [5, 4, 3, 2], 1: [0, 0, 0, 1]},
        labels = {11: ['1001+', '501 - 1000', '101 - 500', '11 - 100'], 6: ['9-10', '8', '7', '6'], 2: ['5', '4', '3', '2'], 1: [0, 0, 0, '1']},
        dataset = {11: [0, 0, 0, 0], 6: [0, 0, 0, 0], 2:[0, 0, 0, 0] , 1:[0, 0, 0, 0]},
        totals = {11: 0, 6: 0, 2: 0, 1: 0};

    for(let listen of listens) {
        for (let threshold of Object.keys(thresholds).reverse()) {
            if(listen.listens >= threshold) {
                totals[threshold] += listen.listeners;
                for(let innerKey in thresholds[threshold]) {
                    let inner = thresholds[threshold][innerKey];
                    if(inner && listen.listens >= inner) {
                        listen.group = labels[threshold][innerKey];
                        dataset[threshold][innerKey] += listen.listeners;
                        break;
                    }
                }
                break;
            }
        }
    }
    
    
    let result = [],
        datasetArray = Object.values(dataset),
        thresholdArray = Object.values(labels);
    
    for(let resultIndex = 0; resultIndex < 4; resultIndex++) {
        let data = [],
            labels = [];
        for(let itemIndex in Object.keys(dataset)) {
            let item = datasetArray[itemIndex];
            data.push(item[resultIndex]);
            let labelItem = thresholdArray[itemIndex];
            labels.push(labelItem[resultIndex]);
            
        }
        result.push({
            data: data.reverse(),
            label: String(resultIndex),
            labels: labels.reverse()
        });
    }
    
    const grandTotal = sum(Object.values(totals));

    totals = Object.values(totals).map(total => ( grandTotal ? Math.round(total / grandTotal * 100) : 0 ) );
    
    result.reverse();
    
    totals.reverse();
    
    result.push({
        label: 'Total',
        data: [0, 0, 0, 0], 
        datalabels: {
            align: 'end',
            anchor: 'end',
            display: true,
            offset: 7,
            formatter: (value, context) => {return `${totals[context.dataIndex] } %`;}
        }

    })
        
    return {dataset: result, table: listens};
}


function formatTopPlaylistStats(data) {
    for(let index in data) {
        let item = data[index];
        item.vendor = 'Spotify';
        item.rank = Number(index) + 1;
        item.ratio = Number(item.total_streams / item.listeners).toFixed(1);
    }
    return data;
}

function formatTopPlaylistStatsMeta(data, metadata) {
    for(let index in data) {
        let item = data[index];
        const metaItem = find(metadata.playlists, (meta)=>meta.id == item.playlist_id);
        item.rank = Number(index) + 1;
        item.ratio = Number(item.total_streams / item.listeners).toFixed(1);
        if(metaItem) {
            item.playlist_name = metaItem.short_name;
            item.vendor = metaItem.vendor;    
        }
        else {
            item.playlist_name = 'tbd';
            item.vendor = 'Spotify';    
        }
        
    }
    return data;
}
