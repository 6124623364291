import React, { Component } from 'react';
import { map, concat, find, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {Helmet} from 'react-helmet';

import { usersActions } from '../../../data/actions/users';

import ReactSelect from '../../../components/common/ReactSelect';
import AddUserForm from '../../../components/forms/addUser';
import Spinner from '../../../components/common/Spinner';
import Tooltip from "../../../components/common/Tooltip";
import AlphabeticalList from '../../../components/pages/users/AlphabeticalList';

class UsersDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'new',
            userID: null,
            user: this.getDefaultUser(),
            expandedACL: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleEntity = this.toggleEntity.bind(this);
        this.cleanUp = this.cleanUp.bind(this);
        this.expandACL = this.expandACL.bind(this);
        this.getSelectedImprintIDs = this.getSelectedImprintIDs.bind(this);
        this.clonePermissions = this.clonePermissions.bind(this);
        this.deselectAll = this.deselectAll.bind(this);
        this.renderSilos = this.renderSilos.bind(this);
        this.renderArtistsButton = this.renderArtistsButton.bind(this);
        this.toggleUserSiloFlag = this.toggleUserSiloFlag.bind(this);
    }
    
    componentDidMount() {
        const { match, dispatch } = this.props,
            userID = match.params.id;
        
        if(userID!=='new'){
            this.setState({
                mode: 'edit',
                userID,
                user: undefined
            }, ()=>dispatch(usersActions.getUser(userID)));
                 
        }
        
        dispatch(usersActions.getImprints());
        dispatch(usersActions.getCampaigns());
        dispatch(usersActions.getUsers(true));
    }
    
    getDefaultUser() {
        // clone permissions from current user
        const { user } = this.props.user; 
        const userPermissions = Object.keys(user).filter(key=>key.match(/^allow_/));
        let defaultUser = {
            acl: {
                imprint_ids: [],
                artist_ids: [],
                product_ids: [],
                campaign_ids: []
            }
        };
        for(let permission of userPermissions) {
            defaultUser[permission] = user[permission];  
        }
        
        defaultUser.users_silos = [];
        for(let silo of user.users_silos) {
            if(silo.can_grant_access) {
                defaultUser.users_silos.push({
                    silo: silo.silo,
                    can_grant_access: false,
                    client_admin: false
                })
            }
        }
                
        return defaultUser;
    }
    
    getSelectedImprintIDs() {
        const { users } = this.props;
        if(!users.imprints)
            return [];
        return map(users.imprints, u=>u.id);
    }
    
    getSelectedIDs(key, filterByImprints = true)  {
        let ids = [];
        const imprintIDs = this.getSelectedImprintIDs();
        const { user } = this.state;
        if(user) {
            for(let parent of user.acl[key]) {
                for (let parentID of Object.keys(parent)) {
                    for(let entityID of parent[parentID]) {
                        if(entityID!=='*') {
                            if(!filterByImprints || (filterByImprints && imprintIDs.includes(Number(entityID)))) {
                                ids.push(entityID);
                            }
                            
                        }
                    }
                }
            }
        }
        return ids;
    }
    
    getSelectedIDsWithParent(key, filterByImprints=true)  {
        let ids = {};
        const imprintIDs = this.getSelectedImprintIDs();
        const { user } = this.state;
        if(user) {
            for(let parent of user.acl[key]) {
                for (let parentID of Object.keys(parent)) {
                    if(!filterByImprints || imprintIDs.includes(Number(parentID))) {
                        ids[parentID] = parent[parentID]    
                    }                    
                }
            }
        }
        return ids;
    }
    
    deleteByParentID(acl, id) {
        for(let index in acl) {
            for (let entityID of Object.keys(acl[index])) {
                if(entityID == id) {
                    acl.splice(index, 1);
                }
            }
        }
        
        return acl;

    }

    
    toggleEntity(id, parentID, key, cb) {
        let  entityIDs  = this.state.user.acl[key],
            foundParentIndex = -1,
            selectedEntities = [];
            
        for(let parentIndex in entityIDs) {
            const parent = entityIDs[parentIndex];
            
            for (let parentKey of Object.keys(parent)) {
                if(String(parentKey) == parentID) {
                    foundParentIndex = parentIndex;
                    selectedEntities = parent[parentKey];
                    break;
                }        
            }
        }   
        
        const idString = String(id),
            foundEntityIndex = selectedEntities.indexOf(idString),
            foundWildcardIndex = selectedEntities.indexOf('*');
        
        if(foundWildcardIndex!==-1)
            selectedEntities.splice(foundWildcardIndex, 1);
        
        if(foundEntityIndex!==-1)
            selectedEntities.splice(foundEntityIndex, 1);
        else
            selectedEntities.push(idString);
        
        selectedEntities = {[parentID]:selectedEntities};
        
        if(foundParentIndex!==-1) {
            if(selectedEntities.length)
                entityIDs[foundLabelIndex] = selectedEntities;
//            else
//                delete entityIDs[foundLabelIndex];
        }
        else
            entityIDs.push(selectedEntities);
        
        
        
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                acl: {
                    ...this.state.user.acl,
                    [key]: entityIDs
                }
            }
        }, ()=>this.cleanUp(cb));
    }
    
    cleanUp(cb) {
        let {artist_ids, product_ids}  = this.state.user.acl,
            currentImprints = this.getSelectedIDs('imprint_ids'),
            currentArtists = this.getSelectedIDsWithParent('artist_ids', false),
            orphanedImprints = [],
            orphanedArtists = [];
        
        for(let parentID in artist_ids) {
            const parent = artist_ids[parentID];
            for (let imprintID of Object.keys(parent)) {
                if(!currentImprints.includes(String(imprintID))) {
                    orphanedImprints.push(imprintID);
                    let isWildcard = false;
                    if(currentArtists[imprintID]){
                        for(let artistID of currentArtists[imprintID]) {
                            if(artistID == '*') {
                                isWildcard = true;
                                break;
                            }
                            else {
                                orphanedArtists.push(artistID);
                            }
                        }
                        
                    }
                    // else?
                    artist_ids = this.deleteByParentID(artist_ids, imprintID);
                    if(isWildcard)
                        product_ids = this.deleteByParentID(product_ids, imprintID);
                    
                }
            }
        }
        
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                acl: {
                    ...this.state.user.acl,
                    artist_ids,
                    product_ids
                }
            }
        }, ()=>cb());
    }
    
    toggleImprint(id, parentID) {
        this.toggleEntity(id, parentID, 'imprint_ids', ()=>{
            
            let selectedImprints = this.getSelectedIDs('imprint_ids');
            //if(!selectedImprints.length)
                //selectedImprints = this.getSelectedImprintIDs();

            if(this.state.expandedACL) {
                this.props.dispatch(usersActions.getArtists(selectedImprints));
                this.props.dispatch(usersActions.getProducts(this.getSelectedIDsWithParent('artist_ids')));
            }
        })
    } 
    
    toggleArtist(id, parentID) {
        this.toggleEntity(id, parentID, 'artist_ids', ()=>{
            this.props.dispatch(usersActions.getProducts(this.getSelectedIDsWithParent('artist_ids')));
        })
    }
    
    toggleProduct(id, parentID) {
        this.toggleEntity(id, parentID, 'product_ids', ()=>{})
    }
    
    toggleCampaign(id) {
        this.toggleEntity(id, 0, 'campaign_ids', ()=>{})
    } 

    toggleUserSiloFlag(siloCode, flag) {
        let silos = cloneDeep(this.state.user.users_silos), 
            silo = find(silos, {silo: siloCode});

        silo[flag] = !silo[flag];
        
        if(flag == 'client_admin' && silo[flag] === true) {
            this.deselectAll(siloCode);
        }

        
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                users_silos: silos
            }
        });
        
    }
    
    removeImprint(id) {
        const imprint = find(this.props.users.imprints, {id});
        if(imprint)
            this.toggleImprint(id, imprint['label_id']) ;
    }
    
    removeArtist(id) {
        let imprintID = null;
        for(let imprint of this.props.users.artists) {
            for (let artist of imprint.artists) {
                if(artist.id == id) {
                    imprintID = imprint.imprint_id;
                    break;
                }
            }
        }
        
        if(imprintID)
            this.toggleArtist(id, imprintID) ;
    }

    clonePermissions({value}) {
        this.props.dispatch(usersActions.getUser(value.invitee_id)).then(user=>{

            const userPermissions = Object.keys(user).filter(key=>key.match(/^allow_/));
            let clonedUser = cloneDeep(this.state.user);
            for(let permission of userPermissions) {
                clonedUser[permission] = user[permission];  
            }
            clonedUser.acl = cloneDeep(user.acl);
            
            this.setState({
                ...this.state,
                user: clonedUser
            })
        })
    }
    
    expandACL(){
        const { expandedACL } = this.state;
        this.setState({
            expandedACL: true
        })
        let selectedImprints = this.getSelectedIDs('imprint_ids');
        if(!selectedImprints.length) 
            selectedImprints = this.getSelectedImprintIDs();
        this.props.dispatch(usersActions.getArtists(selectedImprints));
        this.props.dispatch(usersActions.getProducts(this.getSelectedIDsWithParent('artist_ids')));

    }
    
    deselectAll(silo) {
        const { users } = this.props;
        if(!users.imprints)
            return;
        const currentImprints = this.getSelectedIDs('imprint_ids');
        for(let imprint of users.imprints) {
            if(imprint.silos.includes(silo)) {
                if(currentImprints.includes(String(imprint.id)))
                    this.toggleImprint(imprint.id, imprint.label_id);
            }
        }
    }
    
    onSubmit(formData) {
        const userWithForm = {
            ...this.state.user,
            first_name: formData.first_name,
            invitee_email: formData.invitee_email,
            last_name: formData.last_name,
            allow_api: formData.allow_api
        }
        
        this.setState({
            ...this.state,
            user: userWithForm            
        }, ()=>this.props.dispatch(usersActions.addUser(this.state.user, this.props.users.imprints)));
        //}, ()=>console.log(this.state.user));
        
    }
    
    componentWillReceiveProps(props) {
        if(this.props.users.userSaved === false && props.users.userSaved === true)
            this.props.history.push('/admin/users/');
        if(this.state.user === undefined && props.users.user)
            this.setState({
                user: props.users.user
            });
    }
    
    renderSilos(silos) {
        const { imprints, artists, products, imprintsLoading, artistsLoading, productsLoading,  campaigns, campaignsLoading, items = [], listLoading, loading } = this.props.users;
        const selectedArtists = this.getSelectedIDsWithParent('artist_ids');
        const selectedProducts = this.getSelectedIDsWithParent('product_ids');
        const { user: {users_silos} } = this.props.user;
                
        return users_silos.map(user_silo=>{
            let siloCode = user_silo.silo,
                silo = find(silos, {silo: siloCode}),
                colorIndex = 1; 
                
            if(!silo) {
                silo = {client_admin: false, can_grant_access: false}
            }
            
            return <div key={siloCode}>
                <h2>{siloCode}</h2>
                
                {user_silo.client_admin && <div className="checkbox-holder">
                    <input onClick={()=>this.toggleUserSiloFlag(siloCode, 'client_admin')} type="checkbox" className="input" checked={silo.client_admin} id={`client_admin_${siloCode}`} />
                    <label htmlFor={`client_admin_${siloCode}`} className="checkbox-label">Grant All Access</label>
                  </div>}
                <div className="checkbox-holder">
                  <input onClick={()=>this.toggleUserSiloFlag(siloCode, 'can_grant_access')} type="checkbox" className="input" checked={silo.can_grant_access} id={`can_grant_access_${siloCode}`} />
                  <label htmlFor={`can_grant_access_${siloCode}`} className="checkbox-label">Can Grant Access To Others</label>
                </div>
                <div>
                    <AlphabeticalList title="Imprints" boxClass="imprint" silo={siloCode} items={imprints} loading={imprintsLoading} selected={this.getSelectedIDs('imprint_ids')} select={(val)=>this.toggleImprint(val.id, val.label_id)} colorIndex={1} deselectAll={()=>this.deselectAll(siloCode)} clientAdmin={silo.client_admin} />
                    {this.state.expandedACL && <div>
                        {artistsLoading && <p>Loading Artists</p>}
                        {artists && artists.map(imprint=>{colorIndex++; return <AlphabeticalList title={`${imprint.name} (artists)`} silo={siloCode} items={imprint.artists} selected={selectedArtists[imprint.imprint_id]} select={(val)=>this.toggleArtist(val.id, imprint.imprint_id)} remove={()=>this.removeImprint(imprint.imprint_id)} colorIndex={colorIndex} clientAdmin={silo.client_admin} /> })}
                        {productsLoading && <p>Loading Products</p>}
                        {products && products.map(artist=>{colorIndex++; return <AlphabeticalList title={`${artist.name} (products)`} silo={siloCode} items={artist.products} selected={selectedProducts[artist.imprintID]} select={(val)=>this.toggleProduct(val.id, val.imprint_id)} remove={()=>this.removeArtist(artist.artist_id)} colorIndex={colorIndex} clientAdmin={silo.client_admin} />})}
                    </div>}
                </div>
            </div>
        });

    }    
                        
    renderArtistsButton() {
        const {user, expandedACL} = this.state;
        let showArtistsButton = !expandedACL;
        if(showArtistsButton) {
            showArtistsButton = false;
            for(let silo of user.users_silos) {
                if(silo.client_admin == false) {
                    showArtistsButton = true;
                }
            }
        } 
        
        return showArtistsButton ? <button onClick={this.expandACL} className="default-btn">Show Artists and Products</button> : null;

    }                        
    
    render() {
        const {mode, userID, user: currentUser} = this.state,
            { imprints, artists, products, imprintsLoading, artistsLoading, productsLoading,  campaigns, campaignsLoading, items = [], listLoading, loading } = this.props.users;
        if(!currentUser)
            return <Spinner enabled={loading} />;
        
        let showArtistsButton = !this.state.expandedACL;
        if(showArtistsButton) {
            let selectedImprints = this.getSelectedIDs('imprint_ids');
            showArtistsButton = Boolean(selectedImprints.length);
        } 
        
        const usersOptions = items.map(user=>({
            value: user,
            label: `${user.first_name} ${user.last_name}`
        }));
        
        return <div className="wrapper wrapper-content light-blue-bg users-management">
            <h1>{`${mode=='new' ? 'Create' : 'Update'} User`}</h1>
            <div className="create-user-form">
                <AddUserForm onSubmit={this.onSubmit} values={currentUser} />
        		<Spinner enabled={loading} />
            </div>
            <div className="copy-select-holder">
                <p>Copy permissions from: 
                    <Tooltip
                        position="top"
                        message={`You can type the name of the user you want to copy permissions from or search for it in the drop down menu below.`}
                        tooltipClass="toolbar-title-tooltip inline"
                    />
                </p>
                <ReactSelect 
                    options={usersOptions}
                    onChange={this.clonePermissions}
                />
                    
                <Spinner enabled={this.props.users.itemsLoading} />
            </div>
            {/*<AlphabeticalList title="Campaigns" items={campaigns} loading={campaignsLoading} selected={this.getSelectedIDs('campaign_ids', false)} select={(val)=>this.toggleCampaign(val.id)} colorIndex={0}  />*/}
            {this.renderSilos(currentUser.users_silos)}
            {this.renderArtistsButton()}
            <Spinner enabled={artistsLoading || productsLoading} />
            <Helmet>
                <title>User Management - Admin</title>
            </Helmet>
        </div>
    }
}


function mapStateToProps(state) {
    return {
        users: state.users,
        user: state.user
    } 
}

export default connect(mapStateToProps)(withRouter(UsersDetails));
