import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';

export const channelsService = {
    getChannelTypes,
    addChannel,
    deleteChannel
};

function getChannelTypes(){
    return config.live ? getChannelTypesLive() : getChannelTypesStatic();
}

function getChannelTypesStatic(mode) {
    let baseUrl = `${config.apis.sales}/api/static/channel_types.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getChannelTypesLive() {
    let baseUrl = `${config.apis.socialCollector}/channel_types/main`;
    
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    });
}

function addChannel(artist, url) {
    let baseUrl = `${config.apis.miniCat}/v2/artists/${artist}/channel?channel_url=${url}`;
    return fetch(baseUrl, {
        method: 'POST',
        headers: AuthHeader()
    })
    .then(res=>{   
        return res.json()
    });
}


function deleteChannel(artist, channel) {
    let { channel_id, channel_url } = channel,
        baseUrl = `${config.apis.miniCat}/v2/artists/${artist}/channel?channel_url=${channel_url}&remote_channel_id=${channel_id}`;
    return fetch(baseUrl, {
        method: 'DELETE',
        headers: AuthHeader()
    })
    .then(res=>{   
        return res.json()
    });
}

