import React from 'react';
import { Pie } from 'react-chartjs-2';
import { map, merge, sum, cloneDeep } from 'lodash';
import { colors, pie } from '../ChartDefaults';

class PieChart extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            options: merge({}, pie, props.options)
        };
    }
    render() {
        let chartData = cloneDeep(this.props.data),
            { options } = this.state;
            
            
        if(chartData && chartData.datasets) {
            const dataset = chartData.datasets[0].data;
            const total = sum(dataset);
            for(let index in dataset) {
                const percent = Math.round(dataset[index]/total*100);
                chartData.labels[index] = `${chartData.labels[index]} (${percent}%)`; 
            }
        }
            
        return (chartData ? <Pie {...this.props} data={chartData} options={options} ></Pie> : null);
    }
}

export default PieChart