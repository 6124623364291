import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize, cloneDeep } from 'lodash';
import ReactSelect from '../../common/ReactSelect';
import Box from '../../common/Box';
import CSV from '../../../helpers/CSVExport';
import FillLineChart from '../../widgets/charts/types/FillLineChart';
import LineChart from '../../widgets/charts/types/LineChart';

import { audienceActions } from '../../../data/actions/audience';

var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var fillLineSvg = require('!svg-inline-loader!../../../../public/img/chart-area.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class Demographics extends React.Component {
    genders = [{
       label: 'All',
       value: null
    }, {
        label: 'Male',
        value: 'Male'
    }, {
        label: 'Female',
        value: 'Female'        
    }, {
        label: 'Unknown',
        value: 'Unknown'
    }];
    
    constructor(props) {
        super(props);
        this.state = {
            mode: 'fill',
            gender: null
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.setGender = this.setGender.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }
        
    componentDidMount() {
        this.props.getStreamsStats(this.props.id, this.props.entity);
    }
    
    setChartMode(mode){
        this.setState({mode});
    }
    
    setGender(gender){
        this.setState({gender : gender.value});
    }
    
    exportToCsv(){
        const filename = CSV.CSVHeader('audience_demographics', 'listeners', this.props.filter.global);
        
        let chartData = cloneDeep(this.props.audience.demographics.datasets);

        if(this.state.gender) {
            chartData = chartData.filter(dataset => dataset.gender == this.state.gender );
        }
        
        return CSV.CSVExport(chartData, {filename}, 'audience_demographics');
    }

    
    
    renderToolbar(modes){
        let toolbar = [];
        
        let selectedGender = find(this.genders, gender => gender.value == this.state.gender);
        

        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-icon-holder">
            <a key='line' title="Line Chart" className={`chart-icon ${this.state.mode == 'line' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('line')}
                dangerouslySetInnerHTML={{__html: lineSvg}}>
            </a>
            <a key='fill' title="Fill Line Chart" className={`chart-icon ${this.state.mode == 'fill' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('fill')}
                dangerouslySetInnerHTML={{__html: fillLineSvg}}>
            </a>            
        </div>
        <div className="ibox-actions">
            <ReactSelect value={selectedGender} options={this.genders} onChange={this.setGender} className="single-select"/>
        </div>
        <div className="ibox-actions">
        
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStreamsStats(this.props.id, this.props.entity);
            }                
        }
        if(nextProps.id && !isEqual(nextProps.id, this.props.id)) {
            this.props.getStreamsStats(nextProps.id, nextProps.entity);
        } 
    }
    
    render (){  
        const { entity, id } = this.props,
            { demographics, demographicsLoading } = this.props.audience,
            { mode, gender } = this.state,
            widgetTitle = `Age/Gender`;
            
        let chartData = cloneDeep(demographics);

        if(gender) {
            chartData.datasets = chartData.datasets.filter(dataset => dataset.gender == gender );
        }
            
        return <Box title={widgetTitle} toolbar={this.renderToolbar()} spinnerEnabled={demographicsLoading} >
            {chartData && 
                <div className="chart-block">
                    {mode=='line' && <LineChart data={chartData} id='audience_demographics_line_chart' />}
                    {mode=='fill' && <FillLineChart data={chartData}  id='audience_demographics_line_chart' />}
                </div>
            }
        </Box>;
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getStreamsStats: (id, entity) => {
            return dispatch(audienceActions.getDemographicsStats(id, entity))
        } 
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(Demographics);