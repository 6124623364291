import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {withRouter} from 'react-router';
import {releasesActions} from '../data/actions/releases';
import ReactTable from "react-table";
import { debounce, find, findIndex, isEqual, flatten } from 'lodash';
import Datepicker from '../components/common/Datepicker';
import { previousFriday } from '../data/helpers/heartbeats';
import SectionHeader from '../components/common/Datagrid/SectionHeader';
import PageFilter from '../components/common/Datagrid/PageFilter';
import Tooltip from '../components/common/Tooltip';
import DetailsLink from "../components/common/Datagrid/DetailsLink";
import moment from 'moment';
const downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');
import CSV from '../helpers/CSVExport';

import cellHeaderHOC from '../components/common/Datagrid/CellHeaderHOC';

const CellHeaderTable = cellHeaderHOC(ReactTable);

const dateFormat = 'YYYY-MM-DD';
const disabledDaysOfWeek = [0, 1, 2, 3, 4, 6];

export class NewReleases extends React.Component {
  constructor(props){
    super(props);
    this.setFilterSearchDeb = debounce(this.setFilterSearch, 1000);
    this.dateChange = this.dateChange.bind(this);
    this.bulkExportToCsv = this.bulkExportToCsv.bind(this);
    this.state = {
      expanded: {},
      filtered: '',
      pageSize: 10,
      filteredProductsData: [],
      filter: {
        filtered: [{id: 'track_title', value: ''}]
      },
      pageNumber: 0,
      filterDate: moment(previousFriday()).format(dateFormat),
      defaultSorted: [{id: 'product_title', desc: false}],
    }
  }

  setFilterSearch = (value, id) => {
    let filtered = this.state.filter.filtered.slice(0),
      valueIndex = findIndex(filtered, {id});
    const filteredProductsData = this.props.releases.new.filter( release => {
      return release.tracks.some( track => track.track_title.indexOf(value) > -1)
    });

    if (valueIndex !== -1) {
      filtered[valueIndex] = {
        id,
        value
      };
    } else {
      filtered.push({id, value})
    }

    this.setState({
      ...this.state,
      filteredProductsData,
      pageSize: filteredProductsData.length,
      filter: {
        ...this.state.filter,
        filtered
      }
    });
  }

  dateChange(date, propDate){
    date = moment(date).format(dateFormat);
    this.setState({
      filterDate: date,
      expanded: {},
      filtered: '',
      pageSize: 10,
      filteredProductsData: [],
      filter: {
        filtered: [{id: 'track_title', value: ''}]
      },
    });

    this.dispatchLoadData({page: this.state.pageNumber}, date);
  }

  dispatchLoadData = (state, instance={}, date = this.state.filterDate) => {
    const { dispatch } = this.props;
    const pagination = { page: state.page + 1, per_page: this.state.pageSize};
    dispatch(releasesActions.getNewReleases(date, pagination));
  }

  handleToggleSubComponent = (event, props) => {
    const rowNumber = props.viewIndex;
    const expanded = {};
    const value = this.state.expanded[props.viewIndex]
      ? false
      : true;
    expanded[rowNumber] = value;
    this.setState({expanded})
  }

  setSearch = (e) => {
    const value = e.target.value;
    this.setState({filtered: value});
    this.setFilterSearchDeb(value, 'track_title');
  }

  getFileName = (record, upc) => {
    const todayDate = moment(new Date()).format('YYYYMMDD');
    const fromDay = moment(new Date(this.state.filterDate)).format('YYYYMMDD');
    if(upc){
      return `new_releases_${upc}_${fromDay}-${todayDate}`;
    } else {
      return `new_releases_${fromDay}-${todayDate}`;
    }
  }

  exportToCsv = (record, upc) => {
    const filename = this.getFileName(record, upc);
    return CSV.CSVExport(record, {filename}, 'tracks');
  }

  prepareReleasesToTracks = releases => {
    let result;
    if(releases){
      const tracks = releases.map( release => {
        return this.prepareReleaseToTracks(release);
      });
      result = flatten(tracks);
    } else {
      result = []
    }
    return result;
  }

  handlePercentage = value => {
    return Math.floor(value) + '%';
  }

  buildCarcassTrackObject = release => {
    return {
      track_title: '',
      upc: `'${release.upc}`,
      isrc: '',
      units: release.units,
      playlists: release.playlists,
      playlist_units: release.playlist_units,
      playlist_percentage: this.handlePercentage(release.playlist_percentage)
    }
  }

  prepareReleaseToTracks = release => {
    const releaseData = {'product_title': release.product_title, 'upc': '' };
    let carcassTrackObject;
    const tracks = release.tracks.map( track => {
      const trackData = {...track};
      carcassTrackObject = {...track};
      trackData.playlist_percentage = this.handlePercentage(trackData.playlist_percentage);
      return {...trackData, ...releaseData };
    })

    tracks.unshift({ ...releaseData, ...this.buildCarcassTrackObject(release) });
    return tracks;
  }

  bulkExportToCsv(){
    const {
      releases,
    } = this.props;
    
    let newReleases = [];
    
    for(let product of releases.new) {
        for(let track of product.tracks) {
            newReleases.push({
                ...product,
                ...track,
                'playlist_ratio': this.handlePercentage(track.playlist_percentage)
            });
        }
    }
    
    const data = [
      { new_releases: newReleases}
    ];

    const filename = CSV.CSVHeader('new_releases', null, this.props.filter.global);
    CSV.CSVBulkExport(data, filename);

  }

  render() {
    const data = this.state.filteredProductsData.length !== 0 ? this.state.filteredProductsData : this.props.releases.new;

    const pagination = this.props.releases.pagination ? this.props.releases.pagination : {};
    const preparedReleasesToTracks = data !== undefined ? this.prepareReleasesToTracks(data) : [];


    const subComponent = (row) => {
      const filteredTracks = row.original.tracks.filter( track => track.track_title ? track.track_title.indexOf(this.state.filtered) > -1 : false);
      return (<CellHeaderTable 
        resizable={false} 
        pageSize={filteredTracks.length} 
        showPagination={false} 
        data={filteredTracks} 
        className={"new-releases-track grid-responsive-table"} 
        columns={[
          {
            id: 'track_title',
            className: 'imprint-name',
            headerClassName: 'imprint-name',
            Header: 'Track',
            accessor: 'track_title',
            Cell: ({ value, ...props }) => {
              return (
                <div className="product-item-title">
                  <span>{value}</span>
                  <DetailsLink
                    title="Track Details"
                    entity="tracks"
                    id={props.original.track_id}
                  ></DetailsLink>
                </div>
              );
            }
          }, {
            id: 'isrc',
            className: 'imprint-name',
            headerClassName: 'imprint-name',
            Header: 'International Standard Recording Code',
            accessor: 'isrc',
          }, {
            id: 'units',
            className: 'imprint-name',
            headerClassName: 'imprint-name',
            Header: 'Streams',
            accessor: 'units',
          }, {
            id: 'playlists',
            className: 'imprint-name',
            headerClassName: 'imprint-name',
            Header: 'Playlists',
            accessor: 'playlists',
          }, {
            id: 'pl-streams',
            className: 'imprint-name',
            Header: 'PL Streams',
            headerClassName: 'imprint-name',
            accessor: 'playlist_units',
          }, {
            id: 'pl-ratio',
            className: 'imprint-name',
            Header: 'PL Ratio',
            headerClassName: 'imprint-name',
            accessor: 'playlist_percentage',
            Cell: ({value}) => {
              return this.handlePercentage(value);
            },
          }
        ]}/>)
    }

    return (<div className="wrapper-mt">
      <SectionHeader label="New Releases" searchEntity="Track" data={data === undefined ? [] : data.tracks} filtered={this.state.filtered} onChange={this.setSearch} view="table" >
        <div className="title-action-buttons">
          <Tooltip position="bottom" message="New Releases include products released in the past 6 weeks" tooltipClass="toolbar-title-tooltip release"/>
          <a key="download" title="Export CSV" onClick={() => this.exportToCsv(preparedReleasesToTracks)} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
      </SectionHeader>
      <div className="row" id="new-releases-header">
        <div className="col-xs-12 col-sm-6">
          <div className='calendar-filter'>
              <span>From: </span>
              <i className="fa fa-calendar-week calendar-button"></i>
              <Datepicker date={this.state.filterDate} dateChange={(date)=>this.dateChange(date, 'dateEnd')} daysOfWeekDisabled={disabledDaysOfWeek} endDate={previousFriday()} container="#new-releases-header" />
            </div>
          </div>
        <div className="col-xs-12 col-sm-6">  
          <div className="download-page">
            <span>Export the data</span>
            <a key="download" title="Download entire page" onClick={this.bulkExportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
          </div>
        </div>
    </div>
      <CellHeaderTable
        defaultSorted={this.state.defaultSorted}
        resizable={false}
        loading={this.props.releases.releasesInProgress}
        className="-striped -highlight new-releases-table grid-responsive-table sticky-table"
        defaultPageSize={this.state.pageSize}
        expanded={this.state.expanded}
        columns={[
          {
            id: 'arrow',
            className: 'imprint-name arrow frozen',
            headerClassName: 'imprint-name arrow frozen',
            Cell: props => {
              return (<button className="plus-button" onClick={(e) => this.handleToggleSubComponent(e, props)}>
                {
                  this.state.expanded[props.viewIndex] ? '-' : '+'
                }
              </button>)
            }
          }, {
            id: 'artist_image',
            className: 'imprint-name release-logo frozen',
            headerClassName: 'imprint-name release-logo frozen',
            accessor: 'artist_image',
            Cell: ({value}) => {
              return (<div className="image-cell">
                <img src={value}/>
              </div>);
            },
          }, {
            id: 'product_title',
            className: 'imprint-name release-product frozen',
            headerClassName: 'imprint-name release-product frozen',
            Header: 'Product',
            accessor: 'product_title',
            Cell: ( { value, ...props } ) => {
              return (
                <div className="product-item-title">
                  <span>{value}</span>
                  <DetailsLink
                    title="Product Details"
                    entity="products"
                    id={props.original.product_id}
                  ></DetailsLink>
                </div>
              );
            }
          }, {
            id: 'artist_name',
            className: 'imprint-name release-artist frozen',
            headerClassName: 'imprint-name release-artist frozen',
            Header: 'Artist',
            accessor: 'artist_name',
          }, {
            id: 'actions',
            className: 'imprint-name',
            Cell: props => {
              return <a key="download" onClick={() => this.exportToCsv(this.prepareReleaseToTracks(props.original), props.original.upc)} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            },
          }, {
            id: 'imprint_name',
            className: 'imprint-name',
            Header: 'Imprint',
            headerClassName: 'imprint-name',
            accessor: 'imprint_name',
          }, {
            id: 'upc',
            className: 'imprint-name',
            Header: 'UPC',
            headerClassName: 'imprint-name',
            accessor: 'upc',
          }, {
            id: 'release_date',
            className: 'imprint-name',
            Header: 'Release date',
            headerClassName: 'imprint-name',
            accessor: release => {
              const date = new Date(release.release_date);
              return date.toLocaleString('default', {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
              });
            },
            sortable: false,
          }, {
            id: 'units',
            className: 'imprint-name',
            Header: 'Streams',
            headerClassName: 'imprint-name',
            accessor: 'units',
          }, {
            id: 'playlists',
            className: 'imprint-name',
            Header: 'Playlists',
            headerClassName: 'imprint-name',
            accessor: 'playlists',
          }, {
            id: 'pl-streams',
            className: 'imprint-name',
            Header: 'PL Streams',
            headerClassName: 'imprint-name',
            accessor: 'playlist_units',
          }, {
            id: 'pl-ratio',
            className: 'imprint-name',
            Header: 'PL Ratio',
            headerClassName: 'imprint-name',
            accessor: 'playlist_percentage',
            Cell: ({value}) => {
              return this.handlePercentage(value);
            },
          }
        ]} showPagination={true} showPaginationBottom={true} onFetchData={this.dispatchLoadData} manual pages={pagination.total_page} SubComponent={subComponent} data={data}/>
      <Helmet>
          <title>New Releases - Reports</title>
      </Helmet>

      </div>);
  }
}

function mapStateToProps(state) {
  return { releases: state.releases, filter: state.filter }
}
export default withRouter(connect(mapStateToProps)(NewReleases));
