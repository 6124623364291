import { heartbeatsService } from '../services/heartbeats';
import { heartbeatsConstants } from '../constants/heartbeats';

export const heartbeatsActions = {
  getHeartbeats,
  getPagedHeartbeats,
};

function getHeartbeats(date){
  return ( dispatch, getState ) => {
    dispatch(request());
    heartbeatsService.getHeartbeats(date)
    .then( response => {
      dispatch(success(response));
    })
    .catch( error => {
      dispatch(failure(error));
    })
  };
  function request(){ return { type: heartbeatsConstants.GET_HEARTBEATS_REQUEST } };
  function success(payload){ return { type: heartbeatsConstants.GET_HEARTBEATS_SUCCESS, payload: payload } };
  function failure(error){ return { type: heartbeatsConstants.GET_HEARTBEATS_FAILURE, payload: error } };
}

function getPagedHeartbeats(params){
    return ( dispatch, getState ) => {
      dispatch(request());
      heartbeatsService.getPagedHeartbeats(params)
      .then( response => {
        dispatch(success(response));
      })
      .catch( error => {
        dispatch(failure(error));
      })
    };
    function request(){ return { type: heartbeatsConstants.GET_PAGED_HEARTBEATS_REQUEST } };
    function success(payload){ return { type: heartbeatsConstants.GET_PAGED_HEARTBEATS_SUCCESS, payload: payload } };
    function failure(error){ return { type: heartbeatsConstants.GET_PAGED_HEARTBEATS_FAILURE, payload: error } };
  }
