import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import store from '../../../helpers/store'
import Modal from '../../common/Modal';
import ReactSelect from '../../common/ReactSelect';
import SearchWidget from '../../pages/home/SearchWidget';
import EntitiesList from './EntitiesList';

var imprintsSvg = require('!svg-inline-loader!../../../../public/img/labels.svg');
var artistsSvg = require('!svg-inline-loader!../../../../public/img/artists.svg');
var productsSvg = require('!svg-inline-loader!../../../../public/img/products.svg');
var projectsSvg = require('!svg-inline-loader!../../../../public/img/projects.svg');
var tracksSvg = require('!svg-inline-loader!../../../../public/img/tracks.svg');

class EntityFilter extends React.Component {
    pageFilterObjects = [
        { type: "imprints", icon: imprintsSvg },
        { type: "projects", icon: productsSvg },
        { type: "products", icon: projectsSvg },
        { type: "artists", icon: artistsSvg },
        { type: "tracks", icon: tracksSvg },
    ];
    constructor(props){
        super(props);
        let currentFilter = store.get('userEntityFilter') || this.getDefaultFilter();
        let { mainEntityFilter = {} } = props;
        
        for(let key of Object.keys(currentFilter)) {
        	currentFilter[key] = currentFilter[key].filter(item=>(mainEntityFilter[key] && mainEntityFilter[key].includes(item.id)));
        }
        
        this.state = {
            currentFilter,
            open: false
        }
        this.setFilterState = this.setFilterState.bind(this);
        this.addToFilter = this.addToFilter.bind(this);
        this.removeFromFilter = this.removeFromFilter.bind(this);
        this.showModal = this.showModal.bind(this);
    }
    
    getDefaultFilter(){
    	return {imprints: [], artists: [], projects: [], products: [], tracks: []};
    }
    
    componentDidMount(){
    }
    
    showModal(show){
        this.setState({show});
    }

    
    setFilterState(currentFilter, callback) {
        store.set('userEntityFilter', currentFilter);
        this.setState({
            ...this.state,
            currentFilter
        }, callback);
    }
    
    addToFilter(item) {
    	const { onSubmit } = this.props;
    	const { entity: type, id, name_raw: name } = item;
        let entities = this.state.currentFilter[type];
        entities = entities.filter(entity=>entity.id!=id);
        entities.push({id, name});
        
        this.setFilterState({
    		...this.state.currentFilter, 
    		[type]: entities
        }, ()=>onSubmit({...this.state.currentFilter}));
    }
    
    removeFromFilter(id, type) {
    	const { onSubmit } = this.props;
        let entities = this.state.currentFilter[type];
        entities = entities.filter(entity=>entity.id!=id);
        this.setFilterState({
    		...this.state.currentFilter, 
    		[type]: entities
        }, ()=>onSubmit({...this.state.currentFilter}));        
    }

    renderFilterEntities(entities) {
        return <div className="filter-search-list">
            {Object.keys(entities).map(type=><EntitiesList pageFilterObjects={this.pageFilterObjects} entities={entities[type]} type={type} onDelete={this.removeFromFilter} />)}
        </div>
    }

    
    render() {
        const filter = this.state.currentFilter;
        const { onSubmit } = this.props;
        return <div>
        		
	        	{/*<Modal show={this.state.show}  
	                title="Entity Filter"
	                submitTitle="Apply"
	                handleSubmit={()=>{onSubmit(filter); this.showModal(false);}} 
	                handleClose={()=>this.showModal(false)}
	            >*/}
		            <div className="user-entity-filter"> 
	                    <div>
	                        <SearchWidget searchResult={this.addToFilter} mode="playlist" />
	                    </div>
	                    <div>
	                        {this.renderFilterEntities(filter)}
	                    </div>
		            </div>
	            {/*</Modal>
	            <a onClick={()=>this.showModal(true)}>Filter</a>
	            */}
	            {/*
	            <a onClick={()=>onSubmit(filter)} className="btn btn-primary">Set</a>
	            */}
            </div>;
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter
    } 
}
export default connect(mapStateToProps)(EntityFilter);