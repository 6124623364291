import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router';
import { find } from 'lodash';
import Spinner from '../../components/common/Spinner';
import ConfirmClick from '../../components/common/ConfirmClick';
import ReactSelect from '../../components/common/ReactSelect';
import AddCampaignForm from '../../components/forms/addCampaign';

import { campaignsActions } from '../../data/actions/campaigns';
import CampaignDetailsView from './Details';

class CampaignOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewCampaignModal: false,
            showEditCampaignModal: false,
            currentCampaign: null
        };
        this.goToCampaign = this.goToCampaign.bind(this);
        this.showModal = this.showModal.bind(this);
        this.addNewCampaign = this.addNewCampaign.bind(this);
        this.editCampaign = this.editCampaign.bind(this);
        this.deleteCampaign = this.deleteCampaign.bind(this);
    }
    
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(campaignsActions.getCampaigns()).then(campaigns=>{
            if(!this.props.match.params.id)
                this.goToCampaign(campaigns[0]);
            else {
                this.setState({
                    currentCampaign: this.props.match.params.id 
                });

            }
        });
    }
    
    goToCampaign({id}) {
        this.setState({
            currentCampaign: id
        }, ()=>this.props.history.push(`/campaigns/${id}`));
    }

    showModal(modal, show){
        this.setState({
            [modal]: show
        })
    }
    
    addNewCampaign(formData) {
        this.props.dispatch(campaignsActions.addCampaign(formData));
        this.showModal('showNewCampaignModal', false);
    }
    
    editCampaign(formData) {
        this.props.dispatch(campaignsActions.editCampaign(formData, this.state.currentCampaign));
        this.showModal('showEditCampaignModal', false);
    }
    
    deleteCampaign() {
        this.props.dispatch(campaignsActions.deleteCampaign(this.state.currentCampaign)).then(campaigns=>this.goToCampaign(campaigns[0]));
    }
    
    render() {
        const campaigns = this.props.campaigns.items, 
            selectedCampaign = find(campaigns, {id: Number(this.props.match.params.id)}),
            { currentCampaign } = this.state; 
        return <div className="wrapper wrapper-content light-blue-bg inner-page campaign-page">
            <h2 className="big-title">Campaigns</h2>
            <div className="ibox-content section-header campaign-section-header">
                <div className="title-holder">
                    <div className="select-container">
                        <h3>Choose Campaign</h3>
                        <ReactSelect 
                            options={campaigns}
                            onChange={this.goToCampaign}
                            value={selectedCampaign}
                        />
                    </div>
                    <div className="campaign-actions">
                        <h3>Campaign Actions</h3>
                        <div className="buttons-holder">
                            <button onClick={()=>this.showModal('showNewCampaignModal', true)} className="btn add-to-filter" title="Add new campaign"><i className="fas fa-plus"></i></button>
                            {currentCampaign && <button onClick={()=>this.showModal('showEditCampaignModal', true)} className="btn add-to-filter" title="Edit campaign"><i className="far fa-edit"></i></button>}
                            <AddCampaignForm show={this.state.showNewCampaignModal} title="Create Campaign" onSubmit={this.addNewCampaign} onClose={(e)=>this.showModal('showNewCampaignModal', false)} />
                            <AddCampaignForm show={this.state.showEditCampaignModal} title="Edit Campaign" onSubmit={this.editCampaign} values={selectedCampaign} onClose={(e)=>this.showModal('showEditCampaignModal', false)} />
                        </div>
                        {currentCampaign && <ConfirmClick confirmAction={this.deleteCampaign} confirmClass="btn add-to-filter" title="Delete campaign" confirmLabel={<i className="fas fa-trash-alt"></i>}></ConfirmClick>}    
                    </div>
                </div>
            </div>
            <Spinner enabled={this.props.campaigns.loading} /> 
            <Route path="/campaigns/:id" component={(CampaignDetailsView)}></Route>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        campaigns: state.campaigns,
        filter: state.filter
    } 
}

export default withRouter(connect(mapStateToProps)(CampaignOverview))