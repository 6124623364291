import { map, uniq, capitalize, find, concat } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand'; 
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { colors } from '../../components/widgets/charts/ChartDefaults';

export const milestonesFormatter = {
    formatMilestones,
    formatMilestoneTypes,
    linkEntitiesToMilestones,
    formatNewMilestone,
    formatLinkMilestone,
    formatLinkAudienceMilestone
};

function extractMilestones(data, linked, types) {
    let milestones = [];
    
    if(linked) {
        for(let item of data.milestones) {
            const milestoneType = find(types, type=>item.milestone_type_id == type.id);
            if(milestoneType)
                item.milestone_type = milestoneType.name; 
            milestones.push(item);
        }        
    }
    else {
        for(let category of data.milestones) {
            for(let item of category.data) {
                item.milestone_type = category.name;
                milestones.push(item);
            }
        }
    }
    
    return milestones;
}

function formatMilestones(data, globalFilter, linked, types){
    let dateStartM = moment(globalFilter.dateStart),
        dateStart = dateStartM.format('x'),
        dateEndM = moment(globalFilter.dateEnd),
        dateEnd = dateEndM.format('x'),
        table = [],
        dates = [],
        datasets = [];
    
     for(let item of extractMilestones(data, linked, types)) {   
        // preserve timestaps for comparison
        const { occurs_at, ends_at } = item;
        
        item.occurs_at = moment(item.occurs_at).format('YYYY-MM-DD'),
        item.ends_at = item.ends_at ? moment(item.ends_at).format('YYYY-MM-DD') : '',
        table.push(item);
        
        if(ends_at) { // range 
            if(ends_at < dateStart || occurs_at > dateEnd)
                continue;
            dates.push({range:[Math.max(occurs_at, dateStart), Math.min(ends_at, dateEnd)], item})
        }
        else { // single event
            if(occurs_at > dateEnd || occurs_at < dateStart)
                continue;
            dates.push({range:[occurs_at, occurs_at], item});
            
        }
    }
    
    for(let itemIndex in dates) {
        let {item, range} = dates[itemIndex];
        let milestone = {
            milestone_id: item.id,
            milestone_type_id: item.milestone_type_id,
            backgroundColor: colors[item.milestone_type_id],
            borderColor: colors[item.milestone_type_id],
            label: `${item.reference}`,
            pointBorderColor: colors[item.milestone_type_id],
            pointBackgroundColor: colors[item.milestone_type_id],
            pointHoverRadius: 0,
            data: [{
                x: Math.floor(moment.duration(moment(range[0]).diff(dateStartM)).as('days')), //moment().format('DD MMM'),
                y: itemIndex,
                
            },{
                x: Math.floor(moment.duration(moment(range[1]).diff(dateStartM)).as('days')), //moment(item.range[1]).format('DD MMM'),
                y: itemIndex
            }]
        };
        
        if(item.ends_at && item.occurs_at != item.ends_at) {
            milestone.borderWidth = 0;
            milestone.pointRadius = 4;
            // milestone.pointBorderWidth = 0;
            milestone.pointHoverRadius = 4;
            milestone.fill = false;
        }
        else {
            milestone.borderWidth = 0;
            milestone.pointRadius = 5;
            milestone.fill = true;
        }
        
        datasets.push(milestone);
    }
    
    const { entities } = data;
    for(let row of table) {
        const linked = row.milestone_entity_links[0];
        row.public = linked.public !== undefined ? linked.public : false;
        
        const {entity, entity_type} = linked;
        switch(entity_type) {
            case 'track':
                row.logo = entity.product_image;
                row.linked = `${entity.title} (${entity.isrc}) by ${entity.primary_artist.name}`;
                row.artist = entity.primary_artist ? entity.primary_artist.name : 'N/A';
            break;
            case 'product':
                row.logo = entity.packshot_file_name;
                row.linked = `${entity.title} (${entity.barcode}) by ${entity.primary_artist.name}`;
                row.artist = entity.primary_artist ? entity.primary_artist.name : 'N/A';
            break;
            case 'artist':
                row.logo = entity.image;
                row.linked = entity.name;
            break;
            case 'imprint':
                row.logo = entity.logo_file_name;
                row.linked = entity.name;
            break;
            
        }
    }
    
    return {table, datasets};
}

function linkEntitiesToMilestones({table, datasets}, entity, ids) {
    for(let milestone of table) {
        for(let link of milestone.milestone_entity_links) {
            if(ids.includes(link.entity_id)) {
                let chartItem = find(datasets, {milestone_id: milestone.id});
//                if(chartItem)
//                    chartItem.label += ` +(${link.entity_details.label})`
            }
        }
    }
    return {table, datasets};
}


function formatMilestoneTypes(data){
    return data.map(item=>({
        label: item.name,
        value: item.id
    }))
}

function formatNewMilestone(campaign, params) {
    params.object_type = 'ei_promotion';
    params.source = 'ei';
    
    if(campaign!==null)
        params.object_id = campaign;
    
    if(!params.ends_at)
        delete params.ends_at;

    if(!params.notes)
        params.notes = '';
    
    return {milestone: params};
}

function formatLinkMilestone(params, milestone, user, mode) {
    let entity_type,
        entity_source;
    if(mode == 'sales') {
        entity_type = params.channel_type;
        entity_source = '';
    }
    else {
        entity_type = 'social';
        entity_source = params.channel_type;
    }
        
    return {milestone_link: 
        {
            milestone_id: milestone.id,
            entity_id: params.value,
            entity_type,
            entity_source,
            entity_details: JSON.stringify({label: params.label}), 
            data_silo_codes: user.data_silo_codes,
            user_id: user.id,
            public: false
        }
    }
}

function formatLinkAudienceMilestone(entity, entityID, milestoneID, user) {
    return {
        milestone_link: {
            milestone_id: milestoneID,
            entity_id: entityID,
            entity_type: entity.replace(/.$/, ''),
            entity_source: null,
            entity_details: {}, 
            data_silo_codes: user.data_silo_codes.split(','),
            user_id: user.id,
            public: false
        }
    }
}