import React from 'react';
import { isEqual, sumBy, merge } from 'lodash';
import { Line } from 'react-chartjs-2';
import { colors, line } from '../ChartDefaults';

class FillLineChart extends React.Component {
    convertDatasets(datasets) {
        if(datasets.length && datasets[0].data.length) {
            const days = datasets[0].data.length;
            for(let day = 0; day < days; day++) {
                let largestDatasetIndex = 0,
                    largestShare = 0;
                
                const total = sumBy(datasets, dataset=>dataset.data[day]);
                
                for(let datasetIndex in datasets) {
                    const dataset = datasets[datasetIndex],
                        share = dataset.data[day] ? Math.round((dataset.data[day] / total) * 10000) : 0;
                    if(share > largestShare) {
                        largestShare = share;
                        largestDatasetIndex = datasetIndex;
                    }
                        
                    dataset.data[day] =  share;  
                }   
                const fixOffset = sumBy(datasets, dataset=>dataset.data[day])-10000;
                datasets[largestDatasetIndex].data[day]-=fixOffset;
            }
            for(let dataset in datasets) {
                datasets[dataset].fill = true;
            }   
        }
        return datasets;
    }
    
    constructor(props){
        super(props);
        if(props.data) {
            this.state = {
                labels: props.data.labels,
                datasets: this.convertDatasets(props.data.datasets)
            }
        }
        else {
            this.state = {}
        }
    }
    
    componentWillReceiveProps({data}){
        if(data && data.datasets) {
            if(!isEqual(this.props.datasets, data.datasets)) {
                this.setState({
                    labels: data.labels,
                    datasets: this.convertDatasets(data.datasets)
                })
            }
        }
    }    
    
    render() {
        const stackedOptions = merge({}, line, {
            scales: {
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        callback: function(label, index, labels) {
                            return label / 100 + '%';
                        }
                    },                
                }]
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItems, data) {
                        return tooltipItems.yLabel / 100 + '% ' + data.datasets[tooltipItems.datasetIndex].label;
                    }
                }
            },            
        });
        return (this.state.datasets) ? <Line data={this.state} options={stackedOptions} id={this.props.chartID}  {...this.props}/ > :  null;
    }
}

export default FillLineChart