import { playlistsService } from '../services/playlists';
import { errorActions } from './error';
import { audienceConstants } from '../constants/audience';
import { audienceService } from '../services/audience';
import { audienceFormatter } from '../formatters/audience';
import { find, map } from 'lodash';

export const audienceActions = {
    getStreamsStats,
    getDemographicsStats,
    getTerritoryStats,
    getDemographicsTerritoryStats,
    getSourceStats,
    getPlaylistStats,
    getTopPlaylistStats,
    getRepeatListeners,
};

function getStreamsStats(id, entity) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return audienceService.getStreamsStats({filtered: [{
            id: entity,
            value: id
        }]}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatStreamsStats(audience);
                    dispatch(success(data, audience));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_STREAMS_STATS_REQUEST } }
    function success(data, audience) { return { type: audienceConstants.GET_AUDIENCE_STREAMS_STATS_SUCCESS, data, audience } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_STREAMS_STATS_FAILURE, error } }
}

function getDemographicsStats(id, entity) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        
        let filtered = [{
            id: entity,
            value: id
        }];
        
        return audienceService.getDemographicsStats({filtered}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatDemographicsStats(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_SUCCESS, data} }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_FAILURE, error } }
}

function getTerritoryStats(id, entity) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return audienceService.getTerritoryStats({filtered: [{
            id: entity,
            value: id
        }]}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatTerritoryStats(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_TERRITORY_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_TERRITORY_STATS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_TERRITORY_STATS_FAILURE, error } }
}

function getDemographicsTerritoryStats(id, entity, territory) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        
        let filtered = [{
            id: entity,
            value: id
        },{
            id: 'territories',
            value: territory
        }];
        
        return audienceService.getDemographicsTerritoryStats({filtered}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatDemographicsTerritoryStats(audience);
                    dispatch(success(data, territory));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_REQUEST } }
    function success(data, territory) { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_SUCCESS, data, territory } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_FAILURE, error } }
}


function getSourceStats(id, entity) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return audienceService.getSourceStats({filtered: [{
            id: entity,
            value: id
        }]}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatSourceStats(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_SOURCE_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_STATS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_STATS_FAILURE, error } }
}

function getPlaylistStats(id, entity) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return audienceService.getPlaylistStats({filtered: [{
            id: entity,
            value: id
        }]}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatPlaylistStats(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_FAILURE, error } }
}

function getTopPlaylistStats(id, entity) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        const playlistsFilter = {filtered: [{id: entity, value: id}]};
        
        // ONLY QUERY SPOTIFY TOP PLAYLISTS!!
        let globalFilter = Object.assign({}, getState().filter.global);
            globalFilter.vendors = ['Spotify'];
            
        return playlistsService.getTopPlaylists(playlistsFilter, globalFilter)
            .then(playlists => {
                if(!playlists.length)
                    return dispatch(success([]));
                
                const playlistIDs = map(playlists, (playlist)=>playlist.playlist_id);
                
                return Promise.all([audienceService.getTopPlaylistStats({filtered: [{
                    id: 'playlists',
                    value: playlistIDs.join(',')
                }]}, globalFilter),
                playlistsService.getMetadata(playlistIDs)]);
            })
            .then(data => {
                const audience = data[0],
                    metadata = data[1];
                
                data = audienceFormatter.formatTopPlaylistStatsMeta(audience, metadata);
                dispatch(success(data));
                return data;
            })
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_FAILURE, error } }
}

function getRepeatListeners(id, entity) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return audienceService.getRepeatListeners({filtered: [{
            id: entity,
            value: id
        }]}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatRepeatListeners(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_FAILURE, error } }
}