import React from 'react';
import { Bar } from 'react-chartjs-2';
import { map, merge } from 'lodash';
import alpha from 'color-alpha';
import { colors, stackedBar } from '../ChartDefaults';

class StackedBarChart extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          //  data: this.buildChartData(props.data),
            options: merge({}, stackedBar, props.options)
        };
        
        this.getData = this.getData.bind(this);
    }

    componentWillReceiveProps(props){
        /*
        this.setState({
            data: this.buildChartData(props.data)
        })
        */
    }
    
    getData() {
        const { data } = this.props;
        
        if(data.datasets && Array.isArray(data.datasets)) { 
            data.datasets = data.datasets.map(dataset=>{dataset.maxBarThickness=40; return dataset;});
        }
        return data;
    }
    
    _getData(canvas) {
        return this.props.data;
        const ctx = canvas.getContext("2d"),
            data = this.props.data;
        
        
        if(!data)
            return [];
        
        data.dataset = data.dataset.map((entry, index)=>{
//            let gradient = ctx.createLinearGradient(0, 0, 0, canvas.height),
//                color = colors[index%colors.length],
//                alphaColor = alpha(color, 0,9);
            let color = colors[index%colors.length];
            //gradient.addColorStop(0, color);
            //gradient.addColorStop(1, alphaColor);            
            entry.backgroundColor = color;
            //entry.hoverBackgroundColor = color;
            entry.fill = true;
            return entry;
        });
        
        
        
        return data;

    }
    
    render() {
        let topChartClass = '';

        if (this.props.data && this.props.data.datasets) {
            const { datasets } = this.props.data;

            if (datasets.length > 20 && datasets.length <= 100) {
                topChartClass = 'top-chart-md'    
            } else if (datasets.length > 100 ) {
                topChartClass = 'top-chart-lg'    
            }
        }
        
        return (
            (this.props.data ? <div className={`chart-block ${topChartClass}`}><div className="chart-block-inner"><Bar data={this.getData} options={this.state.options} id={this.props.id}></Bar></div></div> : null )
        )
    }
}

export default StackedBarChart