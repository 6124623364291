import React from 'react';
import EntityFilter from './EntityFilter';

class EntityFilterSwitcher extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        exclude: false
    };
  }

  toggleExclude = (exclude) => {
      exclude === undefined ? !this.state.exclude : exclude;
      this.setState({ exclude }) 
  };

  render(){
        let {entity, label, loaded} = this.props,
            {exclude} = this.state; 
            // this is required to re-render the widget completely
        return <div className="entity-filter-switcher">
            {exclude && <EntityFilter entityLoaded={loaded} entity={`exclude_${entity}`} label={label} exclude={this.state.exclude} toggleExclude={this.toggleExclude} />}
            {!exclude && <EntityFilter entityLoaded={loaded} entity={entity} label={label} exclude={this.state.exclude} toggleExclude={this.toggleExclude} />}
        </div>
  }
}


export default EntityFilterSwitcher;