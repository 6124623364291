import { map, uniq, capitalize } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand'; 
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import {dateRange, formattedDateRange} from '../../helpers/DateRange';

export const channelsFormatter = {
    formatChannelTypes,
};

function formatChannelTypes(channels){
    let channelsHash = {};
    for(let channel of channels) {
        channelsHash[channel.id] = channel.name;
    }
    return channelsHash;
}
