import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, map } from 'lodash';
import store from '../../../helpers/store';

import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import CSV from '../../../helpers/CSVExport';
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';

import Box from '../../common/Box';

import TerritoryStatsMap from './Territory/Map';
import TerritoryDemographics from './Territory/Demographics';
import { audienceActions } from '../../../data/actions/audience';
import { territorySettings } from './Territory/Settings'; 

var worldSvg = require('!svg-inline-loader!../../../../public/img/world.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import VendorLogo from "../../common/VendorLogo";
import { dspLogos } from "../../common/Stats/utils";
const CellHeaderTable = cellHeaderHOC(ReactTable);


class TerritoryStats extends React.Component {
    
    constructor(props) {
        super(props);
        
        const territoryLimit =  10;
        
        this.state = {
          mode: 'map',
          territory: '',
          limit: territoryLimit,
          logoDataFiltered: [],
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.setTerritory = this.setTerritory.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    };    
    
    setChartMode(mode){
        this.setState({mode});
    }
    
    setTerritory(territory){
        this.setState({
            territory,
            mode: 'demographics'
        });
    }

    renderToolbar(modes){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-icon-holder">
            <a key='map' title="Map" className={`chart-icon ${this.state.mode == 'map' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('map')}
                dangerouslySetInnerHTML={{__html: worldSvg}}>
            </a>
            <a key='table' title="Table" className={`chart-icon ${this.state.mode == 'table' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('table')}
                dangerouslySetInnerHTML={{__html: tableSvg}}>
            </a>
            
        </div>
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }
    
    exportToCsv(){
        const filename = CSV.CSVHeader('details_territories', 'listeners', this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(this.props.audience.territory.table, {filename}, 'audience_territories');
    }

    componentDidMount() {
        const { chartType } = this.props;
        this.props.dispatch(audienceActions.getTerritoryStats(this.props.id, this.props.entity))
    }
    
    componentWillReceiveProps(nextProps){
        const globalFilter = nextProps.filter.global;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.props.dispatch(audienceActions.getTerritoryStats(this.props.id, this.props.entity))
            }                
        }
    }

    renderVendorLogos() {
        const { chartType } = this.props;
        const logoData = chartType ? this.state.logoDataFiltered : [];

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} />
            ))}
        </div>
    }

    render (){
        const { audience, expanded = false } = this.props;
        
        return <div className="default"><Box title="Territories" toolbar={this.renderToolbar()} spinnerEnabled={audience.territoryLoading}>
            {audience.territory && <div>
                {this.state.mode=='map' && <TerritoryStatsMap id="audience_territories_widget" data={audience.territory} territoryClick={(territory)=>this.setTerritory(territory)} />}
                {this.state.mode=='table' && <div  id="audience_territories_widget" className="territory-table territory-table--single custom-scroll"><CellHeaderTable
                    className="territory-table-holder playlist-table"
                        data={audience.territory.table}
                        columns={territorySettings.settingsForTable(expanded?'expanded':'details', this.setTerritory)}
                        defaultPageSize={10}
                        showPagination={audience.territory.table.length > 10}
                /></div>}
                {this.state.mode=='demographics' && <TerritoryDemographics entity={this.props.entity} id={this.props.id} territory={this.state.territory} setTerritory={this.setTerritory} />}
            </div>}
        </Box></div>
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(TerritoryStats);