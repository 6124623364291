import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { colors, line } from '../../../widgets/charts/ChartDefaults';
import { merge, uniqBy } from 'lodash';


class MilestoneChart extends React.Component{

    constructor(props) {
        super(props);
    }
    
    
    fixChart(items){
        let yStep = 2,
            yOffset = 1;
        if(items && items.length) {
            for(let y = 0; y < items.length; y++) {
                let data = items[y]['data'];
                data[0]['y'] = yOffset;
                if(data[1])
                     data[1]['y'] = yOffset;
                yOffset += yStep;
            }
        }
        return { items, yOffset };        
    }

    filterMilestoneTypes(chartItems) {
        const { types } = this.props.milestones;
        
        if (types) {
          chartItems = uniqBy(chartItems, "milestone_type_id");
          const filteredChartItems = chartItems.map(
            ({ milestone_type_id, backgroundColor }) => {
              const currentType = types.find(
                (t) => t.value === milestone_type_id
              );

              return {
                label: currentType ? currentType.label : undefined,
                backgroundColor,
              };
            }
          );

          return filteredChartItems;
        }

        return chartItems;
    }


    render(){
        
        if(!this.props.items || !this.props.items.length)
            return <p>No milestones for selected date range</p>;
        
        const {items, yOffset} = this.fixChart(this.props.items);
        const filteredChartItems = this.filterMilestoneTypes(items); 
        
        const {dateStart, dateEnd} = this.props.filter.global,
        dateDiff =  moment(dateEnd).diff(moment(dateStart), 'days'),
        selectedDuration = moment.duration(dateDiff, 'days').asDays(),

        data =  {
            datasets: items
        },
        options = {
            scales: {
                xAxes: [{
                   type: 'linear',
                   position: 'bottom',
                   ticks : {
                       //display: false,
                       beginAtZero :true,
                       stepSize : 1,
                       callback: function(value, index, values) {
                           return moment(dateStart).add(value, 'days').format('DD MMM')
                       },
                       suggestedMax: selectedDuration 
                   }
                }],
                yAxes : [{
                   scaleLabel : {
                       display : false
                   },
                   ticks : {
                       display: false,
                       beginAtZero :true,
                       max : yOffset,
                   },
                   gridLines: {
                       display: false 
                   }
                   
                   
               }]
            },
            legend : {
                display: true, 
                position: 'bottom',
                onHover: function() {},
                onClick: function() {},

                labels: {
                    padding: 15,
                    generateLabels: function(chart) {
                        var data = chart.data;
                        if (data.datasets.length) {
                            return filteredChartItems.map(function(filteredChartItem, i) {
                                return {
                                    text: filteredChartItem.label,
                                    fillStyle: filteredChartItem.backgroundColor,
                                };
                            });
                        } else {
                            return [];
                        }
                    }
                },
            },
            layout: {
                padding: {
                    left: 35,
                    right: 10,
                    top: 0,
                    bottom: 0
                }
            },
            tooltips: {
                enabled: true,
                intersect: true,
                displayColors: false,
                callbacks: {
                    title: function() {},
                    label: function(tooltipItem, data) {
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        return dataset.label ? dataset.label : null;
                    }
                }
            },
        };
        
        const chartOptions = merge({}, line, options);
        
        return <div className="chart-block" style={{height: `${yOffset*18}px`, minHeight: "120px"}}>
            <Line data={data} id="audience_milestones_line_chart" options={chartOptions} />                          
        </div>            

    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter,
        milestones: state.milestones
    } 
}

export default connect(mapStateToProps)(MilestoneChart)