import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf"
});

export default StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: 20,
    fontFamily: "Roboto",
  },
  section: {
    marginBottom: 20,
    flexDirection: 'row'
  },
  
  reportTitle: {
      marginBottom: 5,
      flexDirection: 'row'
  },
  
  columnSection: {
    marginBottom: 20,
    width: '100%',
  },
  columnReportTitle: {
    marginBottom: 5,
  },      
  
  column: {
    flexDirection: 'column',
    width: '100%'
  }, 
  rowSection: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    verticalAlign: 'top',
  }, 
  columnWithMargin: {
    flexDirection: 'column',
    width: '100%',
    marginBottom: "10px"
  },
  half: {
    padding: 5,
    width: '49%',
  },
  threeQuartersColumn: {
      flexDirection: 'column',
      width: '65%',
  },
  quarterColumn: {
      flexDirection: 'column',
      width: '30%',
      display: 'inline-flex',
      alignSelf: 'flex-start'
  },
  header: {
    fontSize: 16,
    marginBottom: 10,
    fontFamily: "Roboto",
  },
  smallHeader: {
    fontSize: 12,
    marginBottom: 12,
    paddingBottom: 6,
    borderBottom: '1px dashed #D2D1D4',
    fontFamily: "Roboto",
  },
  smallHeaderNotUnderline: {
    fontSize: 12,
    marginBottom: 12,
    paddingBottom: 6,
    fontFamily: "Roboto",
  },
  imageColumn: {
    height: '200px',
    width: '200px',
    marginLeft: '10px'
  },
  imageBlock: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover'
  },
  image: {
    marginBottom: "10px"
  },
  complexTitle: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: 'row',
    alignItems: "flex-start",
    paddingTop: "20px",
  },
  reportTitle: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "flex-start",
  },  
  dateRangeTitle: {
    fontSize: 10,
    marginBottom: 10,
  },
  dateMissingText: {
    fontSize: 10,
    marginBottom: "10px"
  },
  roayltyWarning: {
      fontSize: 10,
      fontWeight: "bold",
      marginBottom: "10px"
  }
});
