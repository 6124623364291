import { tracksConstants } from '../constants/tracks';
import { tracksService } from '../services/tracks';
import { playlistsService } from '../services/playlists';
import { tracksFormatter } from '../formatters/tracks';
import { find, map, chunk, flatten } from 'lodash';

export const tracksActions = {
    getTracks,
    getTopTracks,
    getTopTracksInPlaylist,
    getTracksAutocomplete,
    getTrackDetails,
    getCard,
    getStreamsStats,
    getMetadata,
    getTimeseriesTracks,
    getTimeseriesTracksInPlaylist,
    compareTracks,
    compareTrackDemographics,
    compareTrackTerritories,
    compareTrackVendors,
    getTracksReleaseDates,
    forceLoadAllData
};

function getTracks(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        tracksService.getTracks(params, globalFilter)
            .then(
                tracks => {
                    const data = tracksFormatter.formatForTable(tracks);
                    //dispatch(success(data, tracks.pages));
                    return dispatch(getMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TRACKS_REQUEST } }
    function success(tracks, pages) { return { type: tracksConstants.GET_TRACKS_SUCCESS, tracks, pages } }
    function failure(error) { return { type: tracksConstants.GET_TRACKS_FAILURE, error } }
}


function getTracksAutocomplete(search, limit=10) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        tracksService.getTracksAutocomplete(search)
            .then(
                tracks => {
                    const data = tracksFormatter.formatAutocomplete(tracks.results, ['tracks']);
                    dispatch(success(data.slice(0, limit)));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TRACKS_AUTOCOMPLETE_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TRACKS_AUTOCOMPLETE_SUCCESS, tracks} }
    function failure(error) { return { type: tracksConstants.GET_TRACKS_AUTOCOMPLETE_FAILURE, error } }
}

function getTopTracks(params, useCache, addTotal = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return tracksService.getTopTracks(params, globalFilter)
            .then(
                tracks => {
                    const data = tracksFormatter.formatTop(tracks.result);
                    //dispatch(success(data));
                    return dispatch(getTopMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TOP_TRACKS_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TOP_TRACKS_SUCCESS, tracks } }
    function failure(error) { return { type: tracksConstants.GET_TOP_TRACKS_FAILURE, error } }
}

function getTopTracksInPlaylist(playlistID, parentEntity, parentEntityID, useCache) {
    let params = {
        sorted: {field: 'curr_units', dir: 'DESC'}, 
        filtered: [{id: 'playlists', value: playlistID}]
    };
    
    if(parentEntity && parentEntityID) {
        params.filtered.push({id: parentEntity, value: parentEntityID});
    }

    return ( dispatch, getState ) => {
        dispatch(request(playlistID));
        const globalFilter = getState().filter.global;
        tracksService.getTopTracksInPlaylist(params, globalFilter)
            .then(
                tracks => {
                    const data = tracksFormatter.formatTop(tracks);
                    //dispatch(success(data));
                    return dispatch(getTopTracksInPlaylistMetadata(data, playlistID));
                },
                error => {
                    dispatch(failure(playlistID, 'error'))
                }
            );
    };

    function request(playlistID) { return { type: tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_REQUEST, playlistID } }
    function success(tracks) { return { type: tracksConstants.GET_TOP_TRACKS_SUCCESS, tracks} }
    function failure(playlistID, error) { return { type: tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_FAILURE, playlistID, error } }
}


function getTrackDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const tracks = getState().tracks.top,
            track = find(tracks, {track_id: id});
        return Promise.resolve(track)
            .then(
                artist => {
                    const data = tracksFormatter.formatDetails(track.stms_by_date);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TRACK_DETAILS_REQUEST } }
    function success(id, track) { return { type: tracksConstants.GET_TRACK_DETAILS_SUCCESS, id, track } }
    function failure(error) { return { type: tracksConstants.GET_TRACK_DETAILS_FAILURE, error } }
}

function getCard(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return tracksService.getCard(id)
            .then(
                card => {
                    card= tracksFormatter.formatCard(card);
                    dispatch(success(id, card));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TRACKS_CARD_REQUEST } }
    function success(id, card) { return { type: tracksConstants.GET_TRACKS_CARD_SUCCESS, id, card } }
    function failure(error) { return { type: tracksConstants.GET_TRACKS_CARD_FAILURE, error } }
}

function getStreamsStats(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        tracksService.getStreamsStats(ids, globalFilter)
            .then(
                tracks => {
                    const metadata = getState().tracks.metadata;
                    tracks = tracksFormatter.formatStreamsStats(tracks, metadata);
                    dispatch(success(tracks));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_STREAMS_STATS_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_STREAMS_STATS_SUCCESS, tracks } }
    function failure(error) { return { type: tracksConstants.GET_STREAMS_STATS_FAILURE, error } }
}

function getTopMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const chunkSize = 50,
        	ids = chunk(entities.map(entity=>entity.isrc), chunkSize);
        return Promise.all(ids.map(chunk => tracksService.getMetadata(chunk)))
            .then(
                metadata => {
                	const allMetadata = flatten(map(metadata, meta=>meta.data));
                    let total = entities.length > 0 ? find(entities, entity=>entity.track_id == 0) : {},
                            rows = entities.filter(entity=>entity.track_id != 0);
                    
                    rows = tracksFormatter.formatMetadata(rows, allMetadata, total);
                    dispatch(success(rows, total, allMetadata));
                    return rows;

                },
                error => {
                	console.log(error);
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TOP_TRACKS_REQUEST } }
    function success(tracks, total, metadata) { return { type: tracksConstants.GET_TOP_TRACKS_SUCCESS, tracks, total, metadata } }
    function failure(error) { return { type: tracksConstants.GET_TOP_TRACKS_FAILURE, error } }

}

function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.id);
        tracksService.getMetadata(ids)
            .then(
                metadata => {
                    const data = tracksFormatter.formatMetadata(entities.data, metadata.data);
                    dispatch(success(data, entities.total, metadata.data));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TRACKS_REQUEST } }
    function success(tracks, total, metadata) { return { type: tracksConstants.GET_TRACKS_SUCCESS, tracks, total, metadata } }
    function failure(error) { return { type: tracksConstants.GET_TRACKS_FAILURE, error } }

}

function getTopTracksInPlaylistMetadata(entities, playlistID) {
    return ( dispatch, getState ) => {
        dispatch(request(playlistID));
        const ids = entities.map(entity=>entity.id);
        tracksService.getMetadata(ids)
            .then(
                metadata => {
                    const data = tracksFormatter.formatMetadata(entities, metadata.data);
                    dispatch(success(data, metadata.data, playlistID));

                },
                error => {
                    dispatch(playlistID, failure('error'))
                }
            );
    };

    function request(playlistID) { return { type: tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_REQUEST, playlistID } }
    function success(tracks, metadata, playlistID) { return { type: tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_SUCCESS, tracks, metadata, playlistID } }
    function failure(playlistID, error) { return { type: tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_FAILURE, playlistID, error } }

}


function getTimeseriesTracks(params, useCache) {
    return ( dispatch, getState ) => {
        if(getState().tracks.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;
        return tracksService.getTimeseriesTracks(params, globalFilter)
            .then(
                tracks => {
                    const metadata = getState().tracks.metadata;
                    const data = tracksFormatter.formatTimeseries(tracks.result, metadata);
                    dispatch(success(data));
                    return tracks;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TIMESERIES_TRACKS_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TIMESERIES_TRACKS_SUCCESS, tracks } }
    function failure(error) { return { type: tracksConstants.GET_TIMESERIES_TRACKS_FAILURE, error } }
}

function getTimeseriesTracksInPlaylist(params) {
    return ( dispatch, getState ) => {
        const timeseries = getState().tracks;
        if(timeseries && timeseries.topPlaylistTimeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;
        const ids = params.filtered[1].value;
        return Promise.all([tracksService.getTimeseriesTracksInPlaylist(params, globalFilter), 
            tracksService.getMetadata(ids)])
            .then(
                results => {
                    const tracks = results[0];
                    const metadata = results[1].data;
                    const data = tracksFormatter.formatStreamsStats(tracks, metadata);
                    dispatch(success(data));
                    return tracks;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_REQUEST} }
    function success(tracks) { return { type: tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_SUCCESS, tracks} }
    function failure(error) { return { type: tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_FAILURE, error } }
}

function compareTracks(ids, weeks, releaseDates, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        tracksService.compareTracks(ids, weeks, releaseDates, territories, vendors, currentUser)
            .then(
                tracks => {
                    const data = tracksFormatter.formatCompareTracks(ids, tracks);
                    return dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.COMPARE_TRACKS_REQUEST } }
    function success(tracks) { return { type: tracksConstants.COMPARE_TRACKS_SUCCESS, tracks} }
    function failure(error) { return { type: tracksConstants.COMPARE_TRACKS_FAILURE, error } }
}

function compareTrackDemographics(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        tracksService.compareTrackDemographics(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                track => {
                    const data = tracksFormatter.formatCompareTrackDemographics(track);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.COMPARE_TRACK_DEMOGRAPHICS_REQUEST } }
    function success(id, track) { return { type: tracksConstants.COMPARE_TRACK_DEMOGRAPHICS_SUCCESS, id, track} }
    function failure(error) { return { type: tracksConstants.COMPARE_TRACK_DEMOGRAPHICS_FAILURE, error } }
}

function compareTrackTerritories(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        tracksService.compareTrackTerritories(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                track => {
                    const data = tracksFormatter.formatCompareTrackTerritories(track);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.COMPARE_TRACK_TERRITORIES_REQUEST } }
    function success(id, track) { return { type: tracksConstants.COMPARE_TRACK_TERRITORIES_SUCCESS, id, track} }
    function failure(error) { return { type: tracksConstants.COMPARE_TRACK_TERRITORIES_FAILURE, error } }
}

function compareTrackVendors(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        tracksService.compareTrackVendors(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                track => {
                    const data = tracksFormatter.formatCompareTrackVendors(track);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.COMPARE_TRACK_VENDORS_REQUEST } }
    function success(id, track) { return { type: tracksConstants.COMPARE_TRACK_VENDORS_SUCCESS, id, track} }
    function failure(error) { return { type: tracksConstants.COMPARE_TRACK_VENDORS_FAILURE, error } }
}

function getTracksReleaseDates(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return tracksService.getTracksReleaseDates(ids)
            .then(
                tracks => {
                    const trackIDs = map(tracks.data, 'track_id');
                    return Promise.all(map(trackIDs, (id)=>tracksService.getCard(id)))
                    .then(cards => {
                        const data = tracksFormatter.formatTracksReleaseDates(tracks, cards, ids);
                        dispatch(success(data));
                        return data;
                    })
                },
                error => {
                    dispatch(failure('error'))
                }
            )
    };

    function request() { return { type: tracksConstants.GET_TRACKS_RELEASE_DATES_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TRACKS_RELEASE_DATES_SUCCESS, tracks} }
    function failure(error) { return { type: tracksConstants.GET_TRACKS_RELEASE_DATES_FAILURE, error } }
}


function forceLoadAllData(force) {
    return dispatch => {
        dispatch(set(force));
    };

    function set(force) { return { type: tracksConstants.FORCE_LOAD_ALL_DATA, force} }
}
