import React from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { find, maxBy, isEqual } from 'lodash';

import config from '../../../config/config';
import CSV from '../../../helpers/CSVExport';
import Box from '../Box';
import TrendDirection from '../../common/TrendDirection';
import { playlistsActions } from '../../../data/actions/playlists';
import { statsActions } from '../../../data/actions/stats';
import PieChart from '../../widgets/charts/types/PieChart';
import StackedBarChart from '../../widgets/charts/types/StackedBarChart';
import FillLineChart from '../../widgets/charts/types/FillLineChart';
import { sourcesSettings } from './Sources/Settings';
import VendorLogo from '../VendorLogo';
import Spinner from "../../common/Spinner";
import { dspLogos } from "../../common/Stats/utils";
import Tooltip from '../../common/Tooltip';

var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var playlistSvg = require('!svg-inline-loader!../../../../public/img/playlist.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');

class SourcesStats extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
          mode: "pie",
          logoDataFiltered: [],
          defaultSorted: [{ id: "curr_units", desc: false }],
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }

    setChartMode(mode){
        this.setState({mode});
    }
    
    componentDidMount() {
        const { chartType } = this.props;
        this.props.dispatch(statsActions.getSourcesStats(this.props.entity, this.props.ids))
        
        if(this.props.filter.global){
            if(this.props.filter.global!==undefined) {
                this.setState({
                    logoDataFiltered: chartType
                        ? dspLogos[chartType].data.filter((l) =>
                            this.props.filter.global.vendors.includes(l)
                        )
                        : [],
                });
            }                
        }
    }
    
    exportToCsv(){
        const filename = CSV.CSVHeader('details_sources', 'curr_units', this.props.filter.global, this.props.parentEntityTitle);
        return CSV.CSVExport(this.props.stats.sources.table, {filename});
    }
    
    componentWillReceiveProps(nextProps) {
        const globalFilter = nextProps.filter.global;
        const { chartType } = this.props;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.props.dispatch(statsActions.getSourcesStats(this.props.entity, this.props.ids));
                this.setState(prevState => {
                    return {
                    ...prevState,
                    mode: 'pie',
                    logoDataFiltered: chartType
                        ? dspLogos[chartType].data.filter((l) =>
                            globalFilter.vendors.includes(l)
                        )
                        : [],
                    };
                });
            }                
        }
    }
    
    getTimeseries() {
        this.props.dispatch(statsActions.getSourcesTimeseries(this.props.entity, this.props.ids, true));
    }
    
    getPlaylistStats() {
        this.props.dispatch(playlistsActions.getPlaylistTypesSources(this.props.entity, this.props.ids));
    }
    
    
    renderToolbar(modes){
        let toolbar = [];

        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-icon-holder">
            <a key='pie' title="Pie Chart" className={`chart-icon ${this.state.mode == 'pie' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('pie')}
                dangerouslySetInnerHTML={{__html: pieSvg}}>
            </a>
            <a key='bar' title="Playlist and Radio chart" className={`chart-icon ${this.state.mode == 'playlist' ? 'active' : ''}`} 
                onClick={()=>{this.setChartMode('playlist'); this.getPlaylistStats();}}
                dangerouslySetInnerHTML={{__html: playlistSvg}}>
            </a>
            <a key='table' title="Table" className={`chart-icon ${this.state.mode == 'table' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('table')}
                dangerouslySetInnerHTML={{__html: tableSvg}}>
            </a>
            <a key='line' title="Line Chart" className={`chart-icon ${this.state.mode == 'line' ? 'active' : ''}`} 
                onClick={()=>{this.setChartMode('line'); this.getTimeseries();}}
                dangerouslySetInnerHTML={{__html: lineSvg}}>
            </a>
            <a key='bar' title="Bar Chart" className={`chart-icon ${this.state.mode == 'bar' ? 'active' : ''}`} 
                onClick={()=>{this.setChartMode('bar'); this.getTimeseries();}}
                dangerouslySetInnerHTML={{__html: barSvg}}>
            </a>
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            
        </div>
        </div>)
        return toolbar;
    }    
    
    renderTrends(data, single) {
        if(!data || !data.length)
            return null;
        
        const top = maxBy(data, 'share'),
            trending = maxBy(data, 'growth');
        
        return <div>
            <h2 className="content-title capitalize">{top.source}</h2>
            <h4 className="content-subtitle"> has been your top source for plays.</h4>
            <TrendDirection direction="up">
                Plays through <strong>{trending.source}</strong> has seen the most growth with <span className="num">{trending.growth}%</span>
            </TrendDirection>
        </div>;                        
    }

    renderVendorLogos() {
        const { chartType } = this.props;
        const logoData = chartType ? this.state.logoDataFiltered : [];

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} />
            ))}
        </div>
    }
    
    renderLegend(dataset) {
        let items = [];
        for(let index in dataset.labels) {
            const color = dataset.datasets[0].backgroundColor[index],
                label = dataset.labels[index];
            items.push(<li className="sources-legend-item" key={label}><i className="fas fa-circle sources-color" style={{color}} />{label}</li>);
        }
        return items;
    }
    
    renderPlaylistStats() {
        const { playlists } = this.props;
        if(playlists.typesSourcesLoading)
            return <Spinner enabled={true} />

        const chartOptions = {
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return data.labels[tooltipItem.index];
                    }
                }
            }           
            
        }
        return  <div className="row">
            <div className="col-xs-12 col-sm-6">
                <h4 className="sources-item-title">Playlist</h4>
                <div className="chart-block">
                    <PieChart data={playlists.typesSources.playlist} options={chartOptions} />
                </div>
                <ul className="sources-legend">
                    {this.renderLegend(playlists.typesSources.playlist)}
                </ul>
            </div>
            <div className="col-xs-12 col-sm-6">
                <h4 className="sources-item-title">Radio</h4>
                <div className="chart-block">
                    <PieChart data={playlists.typesSources.radio} options={chartOptions} />
                </div>
                <ul className="sources-legend">
                {this.renderLegend(playlists.typesSources.radio)}
                </ul>                    
            </div>                
        </div>;
    }
    
    render (){
        const { stats, playlists } = this.props,
            trends = config.showTrends;

        
        
        const rows = (stats.sources && stats.sources.table) ? stats.sources.table.length : 0;
        return <Box title="Sources" toolbar={this.renderToolbar()} vendorLogoList={this.renderVendorLogos()} spinnerEnabled={this.props.stats.sourcesLoading} className="sources-charts">
            {this.props.stats.sources && <div className="row">
                {trends && <div className="col-sm-5 col-xs-12">
                    {this.renderTrends(stats.sources.table)}
                </div>}
                <div className={`col-xs-12 col-sm-${trends?7:12}`}>
                    {this.state.mode=='pie' && <div className="chart-block">
                        <PieChart data={this.props.stats.sources.chart} />                          
                    </div>}
                    {this.state.mode=='table' && <div className="sources-table territory-table--single custom-scroll">
                        <ReactTable
                            className="source-table"
                            data={stats.sources.table}
                            columns={sourcesSettings.settingsForTable('details')}
                            defaultPageSize={(stats.sources.table && stats.sources.table.length ) ? Math.min(stats.sources.table.length, 10) : 10}
                            showPagination={rows > 10}
                            defaultSorted={this.state.defaultSorted}
                            getTrProps={sourcesSettings.settingsForRow}
                    /></div>}
                    {this.state.mode=='line' && <div className="chart-block">
                        <FillLineChart data={this.props.stats.sourcesTimeseries} />                          
                    </div>}
                    {this.state.mode=='bar' && <div className="chart-block">
                        <StackedBarChart data={this.props.stats.sourcesTimeseries} />                          
                    </div>}
                    {this.state.mode=='playlist' && <div>
                        <Tooltip 
                            position="bottom" 
                            message={`The granularity on Playlist and Radio sources is shown only when possible, provided the streaming platform gave us enough depth of data.`} 
                            tooltipClass="toolbar-title-tooltip sources-big-tooltip" 
                        />
                        {this.renderPlaylistStats()}
                    </div>}                    
                </div>    
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        playlists: state.playlists,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(SourcesStats);