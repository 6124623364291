import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash';

import OverviewWidget from './OverviewWidget';
import TrendsWidget from './TrendsWidget';
import { statsActions } from '../../../data/actions/stats';
import { filterActions } from '../../../data/actions/filter';

class CatalogOverview extends React.Component {
    componentDidMount() {
        this.props.getStats();
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStats(false);
            }                
        }
    }


    render (){
        const statsKey = this.props.view == 'home' ? 'top' : 'catalog';
        return <div className={`row catalog-overview ${this.props.view == 'home' ? 'home' : ''}`}>
            <div className="container container-small">
                <div className="catalog-overview-inner">
                    <div className="title-container">
                        <h2 className="big-title">{this.props.view == 'home' ? 'Totals by Trend Type' : 'Catalogue Overview'}</h2>
                    </div>
                    <div className="row">
                        {this.props.stats[statsKey] && this.props.stats[statsKey].map(entity=>{
                            return <div key={entity.entity} className="col-sm-3 col-xs-6 overview-widget-container">
                                { this.props.view == 'home' ? <TrendsWidget entity={entity} /> : <OverviewWidget entity={entity} /> }
                            </div>
                        })} 
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps){
    return {
        getStats: (cache=true)=> {
            switch(ownProps.view) {
                case 'catalog':
                    dispatch(statsActions.getCatalogStats(cache))
                    break;
                case 'home':
                    dispatch(statsActions.getTopStats(cache))
                    break;                    
            }
        },
        toggleFilter: ()=>dispatch(filterActions.toggleGlobalFilter()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CatalogOverview))