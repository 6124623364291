import React from 'react';
import { map } from 'lodash';
import AbsoluteValue from '../../../common/AbsoluteValue';
import CountryFlag from '../../../common/CountryFlag';
import { percentageValue } from '../../../../helpers/NumberFormatter';
import Tooltip from '../../../common/Tooltip';

export const territorySettings = {
    settingsForTable
};


function settingsForTable(mode){

    
    let columns = [
       {
           id: 'rank',
           className: 'rank',
           headerClassName: 'table-header-item rank',
           Header: 'Rank',
           width: 60,
           accessor: 'rank'
       },
                   
        {
            id: 'logo',
            className: 'logo-sell',
            headerClassName: 'logo-sell',
            Header: '',
            accessor: 'name',
            width: 40,
            Cell: props => {
                return <CountryFlag className="flag-medium" country={props.original.code} />
         }
      },
      {
          id: 'name',
       //    className: 'imprint-name',
       //    headerClassName: 'imprint-name',
          Header: 'Country',
          accessor: 'name',
       //    width: 190
      },      
      {
              id: 'share',
              headerClassName: 'table-header-item',
              Header: 'Share',
              // width: 70,
              accessor: 'share',
              Cell: props => <span>{props.value}%</span>,
          },
       {
           id: 'total',
           headerClassName: 'table-header-item total',
           className: 'total',
           Header: 'Total',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'curr_units'
       },       
       {
           id: 'skipped_ratio',
           headerClassName: 'table-header-item',
           Header: props => <div className="top-table-header-inner">
               Skip Rate
               <Tooltip 
                position="right-bottom" 
                message={`The Skip rate only applies to streams over 30 seconds. It's a metric provided by all DSPs except YouTube and Google.`} 
                tooltipClass="toolbar-title-tooltip" />
            </div>,

           Cell: props => <span>{props.value}%</span>,
           // width: 110,
           accessor: 'skipped_ratio'
       },       
       {
           id: 'completed_ratio',
           headerClassName: 'table-header-item',
           Header: props => <div className="top-table-header-inner">
               Completion Rate
               <Tooltip 
                position="left-bottom" 
                message={`A track is marked as complete when the stream reached 95% of the track length. It's a metric provided by all DSPs except YouTube and Google.`} 
                tooltipClass="toolbar-title-tooltip" />
            </div>,

           Cell: props => <span>{props.value}%</span>,
           // width: 110,
           accessor: 'completed_ratio'
       },
       /*
       {
           id: 'curr_listeners',
           headerClassName: 'table-header-item',
           className: 'listeners',
           Header: 'Listeners',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 100,
           accessor: 'curr_listeners'
       },
       */       

    ];
    return columns;
}