import React from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import PercentageValue from '../../common/PercentageValue';
import AbsoluteValue from '../../common/AbsoluteValue';
import TrendsBox from '../../common/Datagrid/TrendsBox';
import GenderSplitBox from '../../common/Datagrid/GenderSplitBox';
import TagsList from './TagsList';
import VendorLogo from '../../common/VendorLogo';
import Tooltip from "../../common/Tooltip";
import { convertToThousands, percentageValue } from '../../../helpers/NumberFormatter';

var openPlaylistSvg = require('!svg-inline-loader!../../../../public/img/open_playlist.svg');
var overviewSvg = require('!svg-inline-loader!../../../../public/img/overview.svg');
var detailsSvg = require('!svg-inline-loader!../../../../public/img/details.svg');

export const playlistsSettings = {
    settingsForPercentageChart,
    settingsForTable
};

function settingsForPercentageChart(){
    return {};
}

function settingsForTable(enabledColumns, onTitleClick){
    let columns = [
        { 
            className: 'arrow',
            headerClassName: 'arrow',
            accessor: 'tracks', 
            expander: true, 
            Header: '',
            width: 57,
        },                   
        {
            id: 'logo',
            className: 'logo-td',
            headerClassName: 'logo-td table-header-item',
            Header: 'Logo',
            accessor: 'logo',
            width: 60,
            Cell: props => {
                return <Link to={`/playlists/${props.original.id}/details`}><div className="logo-container" ><div className="logo" style={{backgroundImage: `url(${props.original.logo})`}} /></div></Link>;
            }
        },
       {
           id: 'name',
           className: 'playlist-name-td',
           headerClassName: 'playlist-name-td table-header-item',
           headerAlt: 'Playlist',
           Header: props => <div className="top-table-header-inner">
               Playlist 
               <Tooltip 
                position="bottom"
                message="Following the industry wide change in their reporting, Apple is no longer providing Playlist IDs under a certain number of conditions. Where possible we will display the corresponding playlist, but otherwise streams will be aggregated under an 'Editorial playlist' entry." 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           accessor: 'name',
           width: 280,
           Cell: props => {
               //const onClickCall = (e)=>{return onTitleClick(props.original);};
               return <div className="logo-container">
                 
                  <div className="playlist-description">
                		<p className="playlist-name"><VendorLogo name={props.original.vendor} /><span className={`activity activity-${props.original.pl_active?'true':'false'}`}/><Link to={`/playlists/${props.original.id}/overview`}>{props.value}</Link></p>
                		{/*<div className="tags-holder"><TagsList tags={props.original.pl_tags} /></div>*/}
                		<div className="playlist-owner">{props.original.owner}</div>
                  </div>
                		
            		</div>;
           }
       },
       {
           id: 'avg_track_streams_per_day',
           className: 'small-td',
           headerClassName: 'table-header-item small-td',
           headerAlt: 'DPB',
           Header: props => <div className="top-table-header-inner">
               DPB 
               <Tooltip 
                position="bottom"
                message="Daily Performance benchmark: value calculated across all of Ei’s clients as an average of daily streams generated by all known tracks to have been included in this playlist." 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           accessor: 'avg_track_streams_per_day',
           // width: 130,
           Cell: props => <span>{ props.value ? convertToThousands(props.value) : 'N/A' }</span>
       },
       {
           id: 'source',
           className: 'small-td',
           headerClassName: 'table-header-item small-td',
           headerAlt: 'Source',
           Header: props => <div className="top-table-header-inner">
               Source 
               <Tooltip 
                position="bottom"
                message="Playlist / Radio. To filter the table please select your option in the drop down menu above." 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           accessor: 'source',
           // width: 130,
           Cell: props => <span>{ props.value ? props.value.substr(0, 1) : 'P' }</span>
       },
       {
           id: 'playlist_type',
           className: 'small-td',
           headerClassName: 'table-header-item small-td',
           Header: 'Playlist Type',
           accessor: 'playlist_type',
           // width: 130,
           // Cell: props => <span>{ props.value ? props.value.substr(0, 1) : 'P' }</span>
           Cell: props => <span>{props.value}</span>,
       },       
       {
           id: 'curr_tracks',
           headerClassName: 'table-header-item',
           Header: 'Tracks',
           accessor: 'curr_tracks',
           Cell: props => <span>{props.value}</span>,
           // width: 130,
           show: true
       },
       {
           id: 'playlist_share',
           headerClassName: 'table-header-item',
           Header: 'Playlist Share',
           sortable: false,
           accessor: 'playlist_share',
           Cell: props => <span>{props.value}</span>,
           // width: 130,
           show: true
       },       
       {
           id: 'followers',
           headerClassName: 'table-header-item',
           Header: 'Followers',
           accessor: 'followers',
           // width: 130,
           Cell: props => <span>{props.value? convertToThousands(props.value) : 'N/A'}</span>
       },
       /*
       {
           id: 'curr_listeners',
           headerClassName: 'table-header-item',
           Header: 'Listeners',
           accessor: 'curr_listeners',
           // width: 130,
           Cell: props => <span>{convertToThousands(props.value)}</span>
       },
       */       
    //    {
    //        id: 'added_on',
    //        headerClassName: 'table-header-item',
    //        Header: 'Added On',
    //        Cell: props => '-tbd-',
    //        // width: 130,
    //        show: false
    //    },
    //    {
    //        id: 'last_active',
    //        headerClassName: 'table-header-item',
    //        Header: 'Last Active',
    //        Cell: props => '-tbd-',
    //        // width: 130,
    //        show: false
    //    },
       {
           id: 'total',
           className: 'small-td',
           headerClassName: 'table-header-item small-td',
           Header: 'Total',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 130,
           accessor: 'curr_units'
       },       
       {
           id: 'curr_skipped',
           className: 'small-td',
           headerClassName: 'table-header-item small-td',
           Header: <span>Skips <i className="color-red fa fa-step-forward" /></span>,
           Cell: props => <AbsoluteValue className="color-red" value={props.value}></AbsoluteValue>,
           // width: 130,
           accessor: 'curr_skipped'
       },
       {
           id: 'curr_completed',
           headerClassName: 'table-header-item',
           Header: <span>Completed <i className="color-light-green fa fa-play" /></span>,
           Cell: props => <AbsoluteValue className="color-light-green" value={props.value}></AbsoluteValue>,
           // width: 130,
           accessor: 'curr_completed'
       },
       /*
       {
           id: 'completed_diff',
           headerClassName: 'table-header-item no-sort',
           Header: props => <div className="top-table-header-inner">
               Ratio S/C 
               <Tooltip 
                position="bottom" 
                message={`S = Skips / C = Completion\n
                These metrics only apply to streams over 30 seconds. A track is marked as complete when the stream reaches 95% of the track length.\n
                They are provided by all DSPs except Google and YouTube`} 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           accessor: 'completed_diff',
           headerAlt: 'Ratio S/C',
           Cell: props => <div>
           <span title="Skipped">{props.original.skipped_ratio}%</span>
            /
            <span title="Completed">{props.original.completed_ratio}%</span>
           </div>,
            
           sortable: false,
           // width: 130
       },
       */
       {
           id: 'skipped_ratio',
           accessor: 'skipped_ratio',
           className: 'lowercase grid-td',
           headerAlt: 'Skip Rate',
           headerClassName: 'table-header-item small-td',
           Header: props => <div className="top-table-header-inner">
               Skip Rate
               <Tooltip 
                position="bottom" 
                message={`The Skip rate only applies to streams over 30 seconds. It's a metric provided by all DSPs except YouTube and Google.`} 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           Cell: props => <span>{props.value}%</span>,
           // width: 105
       },
       {
           id: 'completed_ratio',
           accessor: 'completed_ratio',
           className: 'lowercase grid-td',
           headerAlt: 'Completion Rate',
           headerClassName: 'table-header-item small-td',
           Header: props => <div className="top-table-header-inner">
               Completion Rate
               <Tooltip 
                position="bottom" 
                message={`A track is marked as complete when the stream reached 95% of the track length. It's a metric provided by all DSPs except YouTube and Google.`} 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           Cell: props => <span>{props.value}%</span>,
           width: 120,
       },
       
       /*
       {
           id: 'completed_diff',
           headerClassName: 'table-header-item no-sort',
           Header: 'Completion Ratio',
           accessor: 'completed_diff',
           Cell: props => <span>{Math.round(props.original['curr_completed']/props.original['curr_units']*100)}%</span>,
           sortable: false,
           // width: 130
       },
       */

       {
           id: 'gender',
           className: 'gender-td',
           headerClassName: 'table-header-item',
           Header: 'Gender Ratio M/F/U',
           Cell: props => <GenderSplitBox item={props.original} />,
           // width: 130
       },

       {
          id: 'overlay',
          className: 'overlay-td',
          headerAlt: 'Overlay',
          headerClassName: 'overlay-header',
          Cell: props => {
            return <div className="overlay-links-holder">
              <a href={props.original.external_url} target="_blank">
                Open Playlist
                <span className="chart-icon" dangerouslySetInnerHTML={{__html: openPlaylistSvg}}></span></a>
              <Link to={`/playlists/${props.original.id}/overview`}>
                Overview
                <span className="chart-icon" dangerouslySetInnerHTML={{__html: overviewSvg}}></span></Link>
              <Link to={`/playlists/${props.original.id}/details`}>
                Details
                <span className="chart-icon" dangerouslySetInnerHTML={{__html: detailsSvg}}></span></Link>
              </div>
          }
       }
       
    ];
    return map(columns, (column)=>{
        if(enabledColumns !== undefined)
            column.show = enabledColumns.includes(column.id);
        return column;
    });
}