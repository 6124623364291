import moment from 'moment';
import store from './store';
import { find } from 'lodash';
import vendors from '../components/widgets/filter/enums/vendors';


export default function BuildQS(params){

    if(params.dateStart && params.dateEnd) {
        let dateStart = moment(params.dateStart),
            dateEnd = moment(params.dateEnd),
            currentDuration = dateEnd.diff(moment(dateStart), 'days'),
            duration = moment.duration(currentDuration+1, 'days').asDays();
        
        params.prevDateStart = params.prevDateStart || dateStart.subtract(duration, 'days').format('YYYY-MM-DD');
        params.prevDateEnd = params.prevDateEnd || dateEnd.subtract(duration, 'days').format('YYYY-MM-DD');
    }
    
    if(params.imprints) {
        params.imprint_ids = params.imprints;
        delete params.imprints;
    }

    const keyMap = {
        'dateStart': 'date_start',
        'dateEnd': 'date_end',
        'releaseStart': 'first_released', 
        'releaseEnd': 'first_release_end',     
        'territories': 'country_codes',
        'vendors': 'customers',
        'genres': 'genres',
        'imprints': 'imprint_ids',
        'artists': 'artists',
        'products': 'product_ids',
        'projects': 'projects',
        'tracks': 'track_ids',
        'imprint_ids': 'imprint_ids',
        'source': 'source'
    },
    user = store.get('user'),
    userKeyMap = {
        'id': 'user_id',
//        'data_silo_codes': 'silo_code',
//        'client_admin': 'client_admin'
    };
    
    let result = {};
    for(let key of Object.keys(params)){
        
        let value = params[key];
        
        if(key=='vendors') {
            value = value.replace(/\,/g, '%2C').replace(/\|/g, ',');
        }
        
        
        if(value instanceof Array){
            value = value.join(',');
        }
        if(value)
            result[keyMap[key]] = value;
    }
    if(user) {
        for(let key in userKeyMap) {
            result[userKeyMap[key]] = user[key]
        }
    }
    /*
    if(params.filtered) {
        const globalFilter = store.get('globalFilter');
        const filteredEntities = globalFilter.filter(entity => entity.fav_hide == params.filtered);
        const paramsPrefix = params.filtered == 'hidden' ? 'exclude_' : '';
        let newParams = {imprints: '', artists: '', products: '', tracks: ''};
        for (let entity of filteredEntities) {
            if(newParams[entity.entity_type])
                newParams[entity.entity_type] += ',';
            newParams[entity.entity_type] += entity.entity_id;
        }
        
        for(let newParamKey of Object.keys(newParams)) {
            if(!newParams[newParamKey])
                continue;
            
            const paramKey = paramsPrefix + newParamKey;
            if(result[paramKey])
                continue; // don't overwrite explicit ID filters
            else
                result[paramKey] = newParams[newParamKey];
        }
    }
    */

    cleanResult(result);
    return result;
}

function cleanResult(result){
  return Object.keys(result).forEach(key => key === 'undefined' && delete result[key])
}
