import React from 'react';
import {Helmet} from 'react-helmet';
import { connect } from 'react-redux';
import Progress from '../common/Progress';
import ErrorBox from '../common/ErrorBox';
import WhiteLabelCss from '../common/WhiteLabelCss';
import UnauthorizedRoutes from '../../config/routes/unauthorized';
import {whiteLabelActions} from '../../data/actions/white_label';
import { withRouter } from 'react-router';

class Blank extends React.Component {

    render() {
        if(!this.props.whiteLabel.config)
            return null;
            
        let {pathname, hostname} = this.props.location,
            silo = this.props.whiteLabel.config[window.location.hostname] || false,
            wrapperClass = `unauthorized-page ${pathname} ${silo}`;
            
            
        return (
            <div id="wrapper" className={wrapperClass}>
                <Progress />
                <ErrorBox />
                <WhiteLabelCss silo={silo} />
                <UnauthorizedRoutes />
                <Helmet defaultTitle="Statements" titleTemplate="%s - Statements" />
            </div>
        )
    }

    componentDidMount(){
//        $('body').addClass('gray-bg');
        this.props.dispatch(whiteLabelActions.loadDomainConfig())
    }

    componentWillUnmount(){
//        $('body').removeClass('gray-bg');
    }
}

function mapStateToProps(state) {
    return {
        whiteLabel: state.whiteLabel
    } 
}

export default connect(mapStateToProps)(withRouter(Blank));