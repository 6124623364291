export const heartbeatsConstants = {
  GET_HEARTBEATS_REQUEST: 'GET_HEARTBEATS_REQUEST',
  GET_HEARTBEATS_SUCCESS: 'GET_HEARTBEATS_SUCCESS',
  GET_HEARTBEATS_FAILURE: 'GET_HEARTBEATS_FAILURE',
  GET_PAGED_HEARTBEATS_REQUEST: 'GET_PAGED_HEARTBEATS_REQUEST',
  GET_PAGED_HEARTBEATS_SUCCESS: 'GET_PAGED_HEARTBEATS_SUCCESS',
  GET_PAGED_HEARTBEATS_FAILURE: 'GET_PAGED_HEARTBEATS_FAILURE',  
  PERCENT_SYMBOL: '%',
  CHECK_MARK_SYMBOL: '✓',
  CROSS_SYMBOL: '✗',
  START_DATE: "2017-12-29"
};
