import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../../components/common/Spinner';
import Datepicker from '../../components/common/Datepicker';
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { importsActions } from '../../data/actions/imports';
import { importErrorsSettings } from '../../components/pages/imports/metadataErrors/Settings';
import ImportMetadataModal from '../../components/pages/imports/ImportMetadata';


var deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');

class ImportMetadataErrors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null
        }
    }
    
    componentDidMount(){
        const { match, dispatch } = this.props;
        this.setState({
            id: match.params.id,
            showImportMetadata: false
        });
        dispatch(importsActions.getCatalogImportErrors(match.params.id)).then((importData)=>{
        });
    }
    
    
    
    render() {
        const { importErrors, importErrorsLoading } = this.props.imports,
            rows = Array.isArray(importErrors) ? importErrors.length : null;
    
        return <div className="inner-page shared-pages">
            <div className="container">
                <h2>Import Errors</h2>
                <div className="ibox">
                    <ReactTable
                        data={importErrors}
                        loading={importErrorsLoading}
                        columns={importErrorsSettings.settingsForTable()}
                        pageSize={rows ? Math.min(rows, 10): 10}
                        showPagination={rows > 10}
                        pivotBy={['product']}
                        className="simple-table share-table"
                    />
                    <Spinner enabled={importErrorsLoading} /> 
                    
                    {/* {selectedRows.length > 0 && <button className="default-btn default-btn--small default-btn--light delete-selected" onClick={this.deleteSelectedPages}>Delete Selected</button>} */}
    
                </div>
            </div>
            <Helmet>
                <title>Import Errors</title>
            </Helmet>
    
        </div>
        
    }
}

function mapStateToProps(state) {
    return {
        imports: state.imports
    } 
}

export default connect(mapStateToProps)(ImportMetadataErrors)