import React from 'react';
import { Line } from 'react-chartjs-2';
import { map, concat, merge } from 'lodash';
import { colors, line } from '../ChartDefaults';
import moment from 'moment';

class LineChart extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            //data: this.buildChartData(props.data),
            options: merge({}, line, props.options)
        };
        this.getData = this.getData.bind(this);
    }
    
    componentWillMount() {
        //Chart.elements.Rectangle.prototype.draw = RectangularDraw;     
    }

    componentWillReceiveProps(props){
        /*
        this.setState({
            data: this.buildChartData(props.data)
        })
        */
    }
    
    getData(canvas) {
        // return this.props.data;
        let {labels, datasets} = this.props.data;
        if(!datasets)
            return false;
            
        let isSingleMonth = false;
        for(let row of datasets) {
            if(Array.isArray(row.data) && row.data.length == 1) {
                isSingleMonth = true;
                row.data.push(row.data[0]);
            }
        }
        return {
            labels,
            datasets
        }
        
    }
    
    render() {
        const { data, options, ...rest } = this.props;
        return (this.props.data ? <Line data={this.getData} options={this.state.options} {...rest}></Line> : null);
    }
}

export default LineChart