import { find } from 'lodash';

const totalRatioFields = ['skipped_ratio', 'completed_ratio', 'saves_ratio', 'discovery_ratio', 'free_ratio', 'discounted_ratio', 'premium_ratio'];
const blacklistTotalFields = ['id'];

export default function AddTotal(rows, entity) {
    if(!rows || !rows.length)
        return rows;
    
    const entitySuffix = ['imprint', 'artist'].includes(entity) ? 'name' : 'title';
    let total = {[`${entity}_${entitySuffix}`]: 'Total', name: 'Total', trends: []};
    
    for(let item of rows) {
        for(let key of Object.keys(item)) {
            if(blacklistTotalFields.includes(key))
                continue;

            const value = item[key];
            if(typeof value == 'number') {
                if(total[key])
                    total[key] += value;
                else
                    total[key] = value;
            }
        }
        
        if(item.trends) {
            for(let itemTrend of item.trends) {
                let trend = find (total.trends, {content_type: itemTrend.content_type, vendor: itemTrend.vendor});
                if(trend) {
                    trend.prev_units += itemTrend.prev_units;
                    trend.curr_units += itemTrend.curr_units;
                }
                else {
                    trend = Object.assign({}, itemTrend);
                    total.trends.push(trend);
                }
                
            }
        }
    }
    
    for(let key of Object.keys(total)) {
        if(totalRatioFields.includes(key)) {
            total[key] = Math.round(total[key] / rows.length)
        }
    }
    
    rows.unshift(total);
    return rows;
} 