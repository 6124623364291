import { map, concat, orderBy, uniq, find } from 'lodash'
import { imprintsFormatter } from './imprints';
import { artistsFormatter } from './artists';
import { projectsFormatter } from './projects';
import { productsFormatter } from './products';
import { tracksFormatter } from './tracks';

export const searchFormatter = {
    formatAutocomplete
};

function _formatAutocomplete(data) {
    let {hits, total}= data.hits;
    return hits.map(hit=>{
        switch(hit._type) {
        case 'imprint':
            return imprintsFormatter.formatAutocompleteItem(hit);
        case 'artist':
            return artistsFormatter.formatAutocompleteItem(hit);
        case 'product':
            return productsFormatter.formatAutocompleteItem(hit);
        case 'track':
            return tracksFormatter.formatAutocompleteItem(hit);
        }
    });
}

function formatAutocomplete(data, entities) {
    let results = {imprints: [], artists: [], projects: [], products: [], tracks: [], total: 0};
    for(let entity of entities) {
        if(data[entity]) {
            for(let entry of data[entity]) {
                const origEntry = Object.assign({}, entry);
                let weight = 1;
                switch(entity) {
                    case 'imprints':
                        entry = imprintsFormatter.formatAutocompleteItem(entry);
                        break;
                    case 'artists':
                        entry = artistsFormatter.formatAutocompleteItem(entry);
                        weight = 1.2;
                        break;
					case 'projects':
						entry = projectsFormatter.formatAutocompleteItem(entry);
						weight = 1.1;
						break;						
                    case 'products':
                        entry = productsFormatter.formatAutocompleteItem(entry);
                        weight = 1.1;
                        break;
                    case 'tracks':
                        entry = tracksFormatter.formatAutocompleteItem(entry);
                        break;
                }
                entry.rank = weight*origEntry.pg_search_rank;
                results[entity].push(entry);
                results.total ++;
            }
        }
    }
    for(let entity of Object.keys(results)) {
        if(Array.isArray(results[entity]))
            results[entity] = orderBy(results[entity], ['rank'], ['desc']);
    }
	/*
    if(entities.includes('projects')) {
    	const products = data.products;
    	const projectKeys = uniq(map(products, 'project'));
    	results.projects = [];
    	for(let projectKey of projectKeys) {
    		const productsInProject = products.filter(product=>product.project == projectKey);
    		let project = productsInProject.reduce((prev, curr)=>{
    			if(!prev.hasOwnProperty('cat_no'))
    				return curr;
    			else 
    				return curr.cat_no.length < prev.cat_no.length ? curr : prev;
    		}, {});
    		const origProject = Object.assign({}, project);
    		project = projectsFormatter.formatAutocompleteItem(project);
    		project.rank = origProject.pg_search_rank;
    		results.projects.push(project);
    		results.total ++;
    	}
    	
    }
	*/
    return results;
}
