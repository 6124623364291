import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'
import Modal from '../common/Modal'
import BulkCSVExport from '../common/BulkCSVExport'
import { composeValidators, required } from './validators'

class SharePageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let submit,
            {show, onClose, title, values={}, exportToCsv, exportToPdf, CSVEntities, CSVPage, CSVId, dataMap, loadingDone} = this.props;
        
        return <Modal show={show} 
                title={title} 
                submitTitle="Share"
                handleSubmit={e=>submit(e)}
                handleClose={onClose}
                showActionButtons={false}
            >
                <p className="roaylty-warning">These reports are for analytical purposes only and should not be used to pay artist royalties</p>
                <Form onSubmit={this.props.onSubmit} initialValues={values}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit;
                        return <form className="m-t">
                            <Field name="title"  
                                validate={composeValidators(required)}
                            >
                                {({ input, meta }) => {
                                    const hasError = meta.error && meta.touched;
                                    return <div className={`form-group${hasError ? ' has-error':''}`}>
                                        <label className="font-normal control-label">Page Title</label>
                                        <input {...input} type="text" className="form-control" />
                                        {hasError && <span className="help-block">{meta.error}</span>}
                                    </div>            
                                }}
                            </Field>
                            
                            <div className="share-form-action">
                                <Field name="expires" type="checkbox"
                                >
                                    {({ input, meta }) => {
                                        const hasError = meta.error && meta.touched;
                                        return <div className={`checkbox${hasError ? ' has-error':''}`}>
                                            <input {...input} type="checkbox" className="checkbox share-expires-checkbox" />
                                            <label className="font-normal">Link Expires in 30 days</label>
                                            {hasError && <span className="help-block">{meta.error}</span>}
                                        </div>            
                                    }}
                                </Field>
                                <a className="default-btn default-btn--small" onClick={handleSubmit}>Share</a>
                            </div>
                            {exportToPdf && <div className="share-form-item">
                                <p className="export-title">Export to PDF <a className="default-btn default-btn--small" onClick={exportToPdf}>Export</a></p>
                            </div>}                                                        
                            {exportToCsv && <div className="share-form-item">
                            {/*
                                <p className="export-title">Export to xlsx <a className="default-btn default-btn--small" onClick={exportToCsv}>Export</a></p>
                            */}
                                <p className="export-title">Export to xlsx <BulkCSVExport page={CSVPage} id={CSVId} entities={CSVEntities} dataMap={dataMap} loadingDone={loadingDone} /></p>
                            </div>}
                        </form>
                    }}
                />
        </Modal>
    }
}
export default SharePageForm