import React from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import AbsoluteValue from '../../../common/AbsoluteValue';
import TrendsBox from '../../../common/Datagrid/TrendsBox';
import TrackPosition from '../TrackPosition';
import GenderSplitBox from '../../../common/Datagrid/GenderSplitBox';
import ArtistsList from '../ArtistsList';
import Tooltip from '../../../common/Tooltip';

export const playlistDetailsSettings = {
    settingsForRow,
    settingsForTable
};

function dateDiff(item) {
    const addedAt = moment(item.added_at),
        removedAt = moment(item.removed_at || undefined);
    return Math.round(moment.duration(removedAt.diff(addedAt)).as('days'));
}

function settingsForTable(enabledColumns){
    let columns = [
        {
            id: 'logo',
            className: 'logo-td',
            headerClassName: 'logo-td table-header-item',
            Header: '',
            accessor: 'image',
            width: 60,
            Cell: props => {
                return <div className="logo" style={{backgroundImage: `url(${props.original.image})`}} />;
            }
        },
        {
           id: 'track',
           className: 'playlist-name-td',
           headerClassName: 'playlist-name-td table-header-item',
           Header: 'Track',
           accessor: 'track_title',
           width: 280,
           Cell: props => {
               const onClickCall = (e)=>{return onTitleClick(props.original);};
               return <div className="playlist-description">
                	  <div><a onClick={onClickCall}>{props.value}</a>
                      <div className="playlist-details-artist"><ArtistsList artists={props.original.artists} /></div>
                    </div>
            	</div>;
           }
       },
       {
           id: 'position',
           headerClassName: 'table-header-item',
           Header: 'Pos',
           accessor: 'position',
           // width: 96,
           Cell: props => <TrackPosition track={props.original} />
       },                          
       {
           id: 'added_at',
           Header: 'Added At',
           accessor: 'added_at',
           // width: 138
       },
       {
           id: 'removed_at',
           className: 'removed-td',
           Header: 'Removed At',
           accessor: 'removed_at',
           Cell: props => moment(props.value).format('YYYY-MM-DD'),
           // width: 134,
           show: false
       },       
       {
           id: 'days_in_list',
           Header: 'Days in list',
           headerClassName: 'no-sort',
           Cell: props => <span>{dateDiff(props.original)}</span>,
           // width: 134
           sortable: false,
       },
       {
           id: 'total',
           Header: 'Total',
           accessor: 'total',
           Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
           // width: 138
       },              
       {
           id: 'skipped',
           Header: 'Skips',
           accessor: 'skipped',
           Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
           // width: 134
       },

       {
           id: 'completed',
           Header: 'Completed',
           accessor: 'completed',
           Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
           // width: 134
       },
       {
           id: 'completed_diff',
           headerClassName: 'table-header-item no-sort',
           Header: props => <div className="top-table-header-inner">
               Ratio S/C 
               <Tooltip 
                position="right-bottom" 
                message={`S = Skips / C = Completion\n
                These metrics only apply to streams over 30 seconds. A track is marked as complete when the stream reaches 95% of the track length.\n
                They are provided by all DSPs except Google and YouTube`} 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           accessor: 'completed_diff',
           headerAlt: 'Ratio S/C',
           Cell: props => {
            const {skipped_ratio, completed_ratio} = props.original,
                showRatio = skipped_ratio && completed_ratio;
            
            return showRatio ? <div>
           <span title="Skipped">{skipped_ratio}%</span>
            /
            <span title="Completed">{completed_ratio}%</span>
           </div> : <div>-</div>},
            
           sortable: false,
           // width: 130
       },
       {
           id: 'gender',
           className: 'gender-td',
           Header: 'Gender M/F/U',
           Cell: props => <GenderSplitBox item={props.original} />,
           // width: 138
       }
    ];
    return map(columns, (column)=>{
        if(enabledColumns !== undefined)
            column.show = enabledColumns.includes(column.id);
        return column;
    });
}

function settingsForRow(state, rowInfo, column) {
    if(rowInfo===undefined)
        return {};
    return {
        className: rowInfo.original.completed === null ? 'disabled' : ''
    }
}