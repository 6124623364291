import React, { Component } from 'react';

export default class ClickOutsideHandler extends Component {
    constructor(props) {
      super(props);
  
      this.ref = React.createRef();
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }
  
    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside, true);
    }
  
    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside, true);
    }

    handleClickOutside(event) {
      if (this.ref && !this.ref.current.contains(event.target)) {
        this.props.onClickOutside && this.props.onClickOutside(event);
      }
    }
  
    render() {
      return <div ref={this.ref}>{this.props.children}</div>;
    }
  }