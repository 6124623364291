import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PercentageValue from '../../../common/PercentageValue';
import AbsoluteValue from '../../../common/AbsoluteValue';
import Tooltip from '../../../common/Tooltip';
import EntityCard from '../../../common/SharedEntityCard';
import ConfirmClick from '../../../common/ConfirmClick';
import { splitByThousands, percentageValue } from '../../../../helpers/NumberFormatter';

export const catalogImportsSettings = {
    settingsForTable
};

function settingsForTable(undo){

    let columns = [
            {
                id: 'id',
                Header: 'ID',
                accessor: 'id',
            },
           
            {
                id: 'created_at',
                Header: 'Created At',
                accessor: 'created_at',
                Cell: props => <span>{moment(props.value).format("YYYY-MM-DD")}</span>,
            },
            
            {
                id: 'name',
                Header: 'Name',
                accessor: 'name',
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: 'import_status',
            },
            {
                id: 'source',
                Header: 'Source File',
                accessor: 'upload_file_name',
                Cell: props => ( props.value ? <a href={props.value} target="_blank">Download</a> : 'N/A' ),
            },            
            {
                id: 'actions',
                Header: 'Actions',
                accessor: 'id',
                Cell: props => {
                    const { import_status } = props.original;
                    return <span>
                        <Link className="confirm-click-holder" to={`/import/metadata/${props.value}/details`}>Details</Link>
                        {import_status == 'failed_lines' && <Link className="confirm-click-holder" to={`/import/${props.value}/errors`}>Errors</Link>}
                        {['complete', 'checked'].includes(import_status) && <ConfirmClick confirmAction={()=>undo(props.value)} title=" Undo" confirmClass="" confirmLabel="Undo" confirmPrompt={`About to Undo Import`}></ConfirmClick>}
                    </span>
                }
            }
            ];
            
    return columns; 
}
