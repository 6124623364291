export const milestonesConstants = {
    GET_MILESTONES_REQUEST: 'GET_MILESTONES_REQUEST',
    GET_MILESTONES_SUCCESS: 'GET_MILESTONES_SUCCESS',
    GET_MILESTONES_FAILURE: 'GET_MILESTONES_FAILURE',
    GET_ENTITY_MILESTONES_REQUEST: 'GET_ENTITY_MILESTONES_REQUEST',
    GET_ENTITY_MILESTONES_SUCCESS: 'GET_ENTITY_MILESTONES_SUCCESS',
    GET_ENTITY_MILESTONES_FAILURE: 'GET_ENTITY_MILESTONES_FAILURE',
    GET_MILESTONE_TYPES_REQUEST: 'GET_MILESTONE_TYPES_REQUEST',
    GET_MILESTONE_TYPES_SUCCESS: 'GET_MILESTONE_TYPES_SUCCESS',
    GET_MILESTONE_TYPES_FAILURE: 'GET_MILESTONE_TYPES_FAILURE',       
    ADD_MILESTONE_REQUEST: 'ADD_MILESTONE_REQUEST',
    ADD_MILESTONE_SUCCESS: 'ADD_MILESTONE_SUCCESS',
    ADD_MILESTONE_FAILURE: 'ADD_MILESTONE_FAILURE',
    LINK_MILESTONE_REQUEST: 'LINK_MILESTONE_REQUEST',
    LINK_MILESTONE_SUCCESS: 'LINK_MILESTONE_SUCCESS',
    LINK_MILESTONE_FAILURE: 'LINK_MILESTONE_FAILURE',
    UNLINK_MILESTONE_REQUEST: 'UNLINK_MILESTONE_REQUEST',
    UNLINK_MILESTONE_SUCCESS: 'UNLINK_MILESTONE_SUCCESS',
    UNLINK_MILESTONE_FAILURE: 'UNLINK_MILESTONE_FAILURE',
 
}