import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { Select, ColorPicker } from './elements';

class WhiteLabelForm extends Component {
    render() {
        const { whiteLabel, whiteLabel: { items } } = this.props
        const fontOptions = [{
            value: 'roboto',
            label: 'Roboto'
        },
        {
            value: 'openSans',
            label: 'Open Sans'
        }];
        let initialValues = {}

        if(!whiteLabel || !items)
            return null;

        initialValues = items.properties || items
        return (
          <FinalForm
            {...this.props}
            initialValues={initialValues}
            render={fieldRenderProps => {
              const {
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
                files,
                onAddFiles,
                existFiles,
                headerLogoPath,
                homepageBackgroundPath,
                loginPageBackgroundPath,
                invitationEmailLogoPath
              } = fieldRenderProps;
              const disabled = existFiles ? !existFiles : (submitting || pristine);
              return (
                <form className="m-t white-label-form"
                  onSubmit={e => {
                    this.submittedValues = values;
                    handleSubmit(e);
                  }}
                >
                  <div className="form-group">
                      <label className="text-label">Header logo</label>
                      <input onChange={(e) => onAddFiles(e, "header_logo")} name="header_logo" id="header_logo" component="input" type="file" className="input-file" />
                      <label htmlFor="header_logo" className="btn btn-w-m btn-default upload-label">Upload</label>
                      <div className="header_logo_preview">
                        {headerLogoPath ?
                          <img src={headerLogoPath} />
                          :
                          <div className="main-logo"></div>
                        }
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Homepage background</label>
                      <input onChange={(e) => onAddFiles(e, "homepage_background")} name="home_page_background" id="home_page_background" component="input" type="file" className="input-file" />
                      <label htmlFor="home_page_background" className="btn btn-w-m btn-default upload-label">Upload</label>
                      <div className="home_page_preview">
                        {homepageBackgroundPath ?
                          <img src={homepageBackgroundPath} />
                          :
                          <div className="catalog-overview home"></div>
                        }
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Login page background</label>
                      <input onChange={(e) => onAddFiles(e, "login_page_background")} name="login_page_background" id="login_page_background" component="input" type="file" className="input-file" />
                      <label htmlFor="login_page_background" className="btn btn-w-m btn-default upload-label">Upload</label>
                      <div className="login_page_preview">
                        {loginPageBackgroundPath ?
                          <img src={loginPageBackgroundPath} />
                          :
                          <div className="catalog-overview home"></div>
                        }
                      </div>
                  </div>                  
                  <div className="form-group">
                      <label className="text-label">Overall font</label>
                      <Field name="site_font" component={Select} className="custom-select" options={fontOptions}> </Field>
                  </div>
                  <h4 className="input-holder-title">Color Settings</h4>
                  <div className="form-group">
                      <label className="text-label">Main color</label>
                      <div className="flex-block">
                          <span>#</span>
                          <Field name="font_colour_default" component={ColorPicker} className="form-control" />
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Main active color</label>
                      <div className="flex-block">
                          <span>#</span>
                          <Field name="font_colour_selected" component={ColorPicker} className="form-control" />
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Headers font color</label>
                      <div className="flex-block">
                          <span>#</span>
                          <Field name="headers_font_colour" component={ColorPicker} className="form-control" />
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Entities font color</label>
                      <div className="flex-block">
                          <span>#</span>
                          <Field name="font_colour_entity" component={ColorPicker} className="form-control" />
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Action icons color</label>
                      <div className="flex-block">
                          <span>#</span>
                          <Field name="action_icon_colour_default" component={ColorPicker} className="form-control" />
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Active action icons color</label>
                      <div className="flex-block">
                          <span>#</span>
                          <Field name="action_icon_colour_selected" component={ColorPicker} className="form-control" />
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Button color</label>
                      <div className="flex-block">
                          <span>#</span>
                          <Field name="button_colour_default" component={ColorPicker} className="form-control" />
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Button active color</label>
                      <div className="flex-block">
                          <span>#</span>
                          <Field name="button_colour_selected" component={ColorPicker} className="form-control" />
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Button text color</label>
                      <div className="flex-block">
                          <span>#</span>
                          <Field name="button_text_colour_default" component={ColorPicker} className="form-control" />
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Button active text color</label>
                      <div className="flex-block">
                          <span>#</span>
                          <Field name="button_text_colour_selected" component={ColorPicker} className="form-control" />
                      </div>
                  </div>
                  <h4 className="input-holder-title">Email Settings</h4>
                  <div className="form-group">
                      <label className="text-label">Invitation Email logo</label>
                      <input onChange={(e) => onAddFiles(e, "email_logo")} name="email_logo" id="email_logo" component="input" type="file" className="input-file" />
                      <label htmlFor="email_logo" className="btn btn-w-m btn-default upload-label">Upload</label>
                      <div className="email_logo_preview">
                        {invitationEmailLogoPath ?
                          <img src={invitationEmailLogoPath} />
                          :
                          <div className="email-logo"></div>
                        }
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="text-label">Invitation Email text</label>
                      <Field name="email_invite_note" component="textarea" className="form-control form-control-textarea" placeholder="" />
                  </div>

                  <button type="submit" disabled={disabled} className="chat-submit">Update settings</button>
                </form>
              );
            }}
          />
        );
    }
}

function mapStateToProps(state) {
    return {
        whiteLabel: state.whiteLabel
    }
}

export default connect(mapStateToProps)(WhiteLabelForm)
