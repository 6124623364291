import { map, uniq, find, fill, capitalize } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand';
import ImageURL from '../../helpers/ImageURL';
import { tracksFormatter } from './tracks';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import { formatChartLabel, formatChartLabelItem, sortDateRange } from '../../helpers/DateFormatter';
import {dateRange, dateRangeFlat, formattedDateRange} from '../../helpers/DateRange';
import {percentageValue} from '../../helpers/NumberFormatter';

export const playlistsFormatter = {
    formatForTable,
    formatTotal,
    formatTop,
    formatTimeline,
    formatDetails,
    formatStats,
    formatBenchmarks,
    formatForTimeline,
    formatMetadata,
    formatTimeseries,
    formatStreamsStats,
    formatShare,
    formatTypesSources,
};

function getOwner(item) {
    if(item.owner){
        return item.owner;
    }
    else {
        switch(item.vendor) {
            case 'applemusic':
                return 'Apple Music';
            case 'amazon':
                return 'Amazon Music';
            default:
                return capitalize(item.vendor);
        }
    }
}

function formatForTable(data){
    if(!data)
        return [];    
    return map(data, (entry, index)=>{
        entry.id = entry.playlist_id;
        entry.name = entry.playlist_name;
        entry.logo = ImageURL(entry.image, entry.id, 'playlists');
        //entry.engaged = Math.round((entry.active / entry.curr_units) * 100);
        entry.colorIndex = index;
        entry.playlist_share = entry.total_tracks ? (Math.round(entry.curr_tracks / entry.total_tracks * 100) + '%') : 'N/A';
        entry.owner = getOwner(entry);
        return entry;
    });

}

function formatTotal(data){
    if(!data)
        return [];    
    return data[0].total;
}


function formatTop(data){
    if(!data)
        return [];
    
    return map(data, (entry)=>{
        entry.id = entry.playlist_id;
        entry.absoluteValue = entry.curr_units;
        entry.percentageValue = entry.units_diff = percentageValue(entry.curr_units, entry.prev_units);
        entry.engaged = Math.round((entry.active / entry.curr_units) * 100);
        entry.skipped_ratio = Math.round(entry.curr_skipped/entry.curr_units*100);
        entry.completed_ratio = Math.round(entry.curr_completed/entry.curr_units*100);
        entry.playlist_share = entry.total_tracks ? (Math.round(entry.curr_tracks / entry.total_tracks * 100) + '%') : 'N/A';
        entry.code = null;
        return entry;
    });

}

function formatTimeline(data){
    if(!data)
        return [];

    let labels = [],
        dataset = [];
    
    data = data.sort(sortDateRange);
    
    for(let item of data) {
        labels.push(formatChartLabel(item));
        dataset.push(item.units);
    }
    return {labels, datasets: [{data: Object.values(dataset), label: 'plays'}]};
}

function formatDetails(data, tracks){
    data.tracks = {
        active: data.current_tracks,
        removed: data.removed_tracks
    };
    
    let totalSkipped = 0, totalTracksCount = 0;
    
    for(let mode of ['active', 'removed']) {
        for(let track of data.tracks[mode]) {
            const streams = find(tracks, {isrc: track.isrc});
            if(streams) {
                track.completed = streams.curr_completed;
                track.skipped = streams.curr_skipped;
                track.total = streams.curr_units;
                track.skipped_ratio = Math.round(streams.curr_skipped/streams.curr_units*100);
                track.completed_ratio = Math.round(streams.curr_completed/streams.curr_units*100);
                track.curr_male = streams.curr_male;
                track.curr_female = streams.curr_female;
                track.curr_unknown = streams.curr_unknown;
                
                totalSkipped += track.skipped_ratio;
                totalTracksCount++;
            }
            
            track.added_at = track.added_at.dates.pop(); 
        }
    }
    
    const benchmarks = {
        skipped_ratio: totalTracksCount ? Math.round(totalSkipped / totalTracksCount) : 0
    }
    return { data, benchmarks };
}

function formatStats(data){
//    data = data[0];
//    data.timeline = tracksFormatter.formatStatsTimeline(data.tracks);
    return data;
}

function formatBenchmarks(data) {
    const genders = ['male', 'female', 'unknown'];
    
    data = JSON.parse(data);
    
    let devices = {labels: [], data: []}, 
        territories = {labels: [], data: []}, 
        ages = [], 
        genderDataset = {};
    
    
    for(let key in data) {
        if(key.startsWith('device_')) {
            let device = key.substring(key.indexOf('_')+1);
            devices.labels.push(device);
            devices.data.push(data[key]);
        }
        else {
            for(let gender of genders) {
                if(key.startsWith(gender)){
                    ages.push(key.substring(key.indexOf('_')+1))
                }
            }
        }
    }
    ages = uniq(ages);
    
    for(let gender of genders) {
        genderDataset[gender]={
            label: gender,
            data: []
        };
        for(let age of ages) {
            genderDataset[gender].data.push(data[`${gender}_${age}`] || 0);
        }
    }
    
    for(let territory of data.territories){
        territories.labels.push(territory.key);
        territories.data.push(territory.value);
    }
    devices.datasets = [{data: devices.data, label: 'Streams'}];
    territories.datasets = [{data: territories.data, label: 'Streams'}];
    
    return {devices, territories, demographics: {
        labels: ages,
        datasets: Object.values(genderDataset)
    }};
}

function formatForTimeline(data) {
    let labels = [],
        datasets = [];
    
    
    if(data && data.length) {
        const {dates, period} = dateRange(data);
        datasets = data.map((playlist, index)=>{
            playlist.stms_by_date = playlist.stms_by_date.sort(sortDateRange);
            let data = [];
            for(let date of dates) {
                let stms = find(playlist.stms_by_date, {stream_date: date});
                data.push(stms ? stms.units : null);
            }    

            return {
                id: playlist.playlist_id,
                label: playlist.playlist_name,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length]            
            }
        });    
        
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));

    }
    return { labels, datasets };
}

function formatMetadata(entities, metadata) {
    for(let entity of entities) {
        const metaEntity = find(metadata, {id: entity.id});
        if(metaEntity) {
            entity.name = metaEntity.short_name;
            entity.image = ImageURL(metaEntity.image, entity.id, 'playlists');
            entity.vendor = metaEntity.vendor;
        }
    }
    return entities;

}

function formatTimeseries(playlists, metadata) {
    for(let playlist of playlists) {
        const metaEntity = find(metadata, {id: playlist.playlist_id});
        if(metaEntity) {
            playlist.name = metaEntity.short_name;
        }

        
        playlist.stms_by_date = playlist.totals;
    }
        
    return playlists;
}

function formatStreamsStats(streams, metadata) {
    let labels = [],
        datasets = [];
    if(streams) {
        const {dates, period} = dateRangeFlat(streams);
        datasets = metadata.map((playlist, index)=>{
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, playlist_id: playlist.id});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: playlist.id,
                label: playlist.short_name,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };
}


function formatShare(data){
    let labels = [],
        dataset = [],
        total = 0,
        count = 0;
    
    for(let item of data) {
        const share = Math.round(item.cl_tracks / item.pl_tracks * 1000) / 10;
        item.playlist_share = share + '%';
        labels.push(item.day);
        dataset.push(share);
        total += share;
        count++;
    };
    
    const average = Math.round(total / count * 10) / 10;
    const averageDataset = fill(Array(count), average);
    const datasets = [
        {label: 'Daily track share', data: dataset, fill: false},
        {label: 'Average track share', data: averageDataset, fill: false}
    ];
    
    return {labels, datasets, data};
    
    
    
}

function formatTypesSources(data) {
    let playlistData = [],
        radioData = [],
        playlistLabels = [],
        radioLabels = [],
        playlistColors = [],
        radioColors = [];
    
    for(let itemIndex in data) {
        const item = data[itemIndex];
        if(item.source == 'Playlist') {
            playlistData.push(item.streams);
            playlistLabels.push(item.playlist_type);
            playlistColors.push(colors[itemIndex]);
        }
        else if(item.source == 'Radio') {
            radioData.push(item.streams);
            radioLabels.push(item.playlist_type);
            radioColors.push(colors[itemIndex]);
        }
    }
    
    return {
        playlist: {
            labels: playlistLabels,
            datasets: [{
                label: 'Streams',
                data: playlistData,
                backgroundColor: playlistColors
            }]
        },
        radio: {
            labels: radioLabels,
            datasets: [{
                label: 'Streams',
                data: radioData,
                backgroundColor: radioColors
            }]
        }
    }
}