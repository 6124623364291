import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { merge, map } from 'lodash';
import datepicker from 'bootstrap-datepicker';
import moment from 'moment';

class DatePicker extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.dateElement = $(ReactDOM.findDOMNode(this.refs.date));
        this.dateElement.datepicker({
            format: 'yyyy-mm-dd',
            todayBtn: "linked",
            keyboardNavigation: false,
            forceParse: false,
            calendarWeeks: true,
            autoclose: true,
        });
        this.dateElement.on('changeDate', (e)=>{
            const date = moment(e.date).format('YYYY-MM-DD');
            return this.props.input.onChange(date);
        });
    }

    componentWillUnmount(){
        this.dateElement.datepicker('destroy');
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            this.dateElement.datepicker('update')
        }
    }

    render() {
        let {input: { name, onChange, value, label, ...restInput }, meta, children, className, ...rest} = this.props;
        return (
            <input ref="date" name={name} type="text" className={className} value={value} onChange={onChange}/>
        );
    }
}

 export default DatePicker;