import { channelsActions }  from './channels';
import { artistsConstants } from '../constants/artists';
import { artistsService } from '../services/artists';
import { artistsFormatter } from '../formatters/artists';
import { find } from 'lodash';

export const artistsActions = {
    getArtists,
    getTopArtists,
    getArtistsAutocomplete,
    getArtistDetails,
    getCard,
    getStreamsStats,
    getArtistProfile,
    getFollowers,
    getProducts,
    getMetadata,
    getTimeseriesArtists,
    forceLoadAllData
};

function getArtists(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        artistsService.getArtists(params, globalFilter)
            .then(
                artists => {
                    const data = artistsFormatter.formatForTable(artists);
                    //dispatch(success(data, artists.pages));
                    return dispatch(getMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_ARTISTS_REQUEST } }
    function success(artists, pages) { return { type: artistsConstants.GET_ARTISTS_SUCCESS, artists, pages } }
    function failure(error) { return { type: artistsConstants.GET_ARTISTS_FAILURE, error } }
}


function getArtistsAutocomplete(search, limit=10) {
    return dispatch => {
        dispatch(request());
        if(!search) {
            dispatch(success([]));
            return Promise.resolve([]);
        }
        return artistsService.getArtistsAutocomplete(search)
            .then(
                artists => {
                    const data = artistsFormatter.formatAutocomplete(artists.results, ['artists']);
                    dispatch(success(data.slice(0, limit)));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_ARTISTS_AUTOCOMPLETE_REQUEST } }
    function success(artists) { return { type: artistsConstants.GET_ARTISTS_AUTOCOMPLETE_SUCCESS, artists} }
    function failure(error) { return { type: artistsConstants.GET_ARTISTS_AUTOCOMPLETE_FAILURE, error } }
}

function getTopArtists(params, useCache, addTotal = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;

        return artistsService.getTopArtists(params, globalFilter)
            .then(
                artists => {
                    const data = artistsFormatter.formatTop(artists.result);
                    //dispatch(success(data));
                    return dispatch(getTopMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_TOP_ARTISTS_REQUEST } }
    function success(artists) { return { type: artistsConstants.GET_TOP_ARTISTS_SUCCESS, artists } }
    function failure(error) { return { type: artistsConstants.GET_TOP_ARTISTS_FAILURE, error } }
}


function getArtistDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const artists = getState().artists.top,
            artist = find(artists, {artist_id: id});
        return Promise.resolve(artist)
            .then(
                artist => {
                    const data = artistsFormatter.formatDetails(artist.stms_by_date);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_ARTIST_DETAILS_REQUEST } }
    function success(id, artist) { return { type: artistsConstants.GET_ARTIST_DETAILS_SUCCESS, id, artist } }
    function failure(error) { return { type: artistsConstants.GET_ARTIST_DETAILS_FAILURE, error } }
}

function getCard(id, socials = false, recommended = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        return dispatch(channelsActions.getChannelTypes())
        .then(channelTypes=> {
            return artistsService.getCard(id, socials, recommended)
            .then(
                card => {
                    card = artistsFormatter.formatCard(card);
                    let channels = [];
                    if(card.channels && card.channels.length)
                        channels = artistsFormatter.formatArtistChannels(card.channels, channelTypes);
                    dispatch(success(id, card, channels));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
        })
    };
    

    function request() { return { type: artistsConstants.GET_ARTISTS_CARD_REQUEST } }
    function success(id, card, channels) { return { type: artistsConstants.GET_ARTISTS_CARD_SUCCESS, id, card, channels} }
    function failure(error) { return { type: artistsConstants.GET_ARTISTS_CARD_FAILURE, error } }
}

function getStreamsStats(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        artistsService.getStreamsStats(ids, globalFilter)
            .then(
                artists => {
                    const metadata = getState().artists.metadata;
                    artists = artistsFormatter.formatStreamsStats(artists, metadata);
                    dispatch(success(artists));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_STREAMS_STATS_REQUEST } }
    function success(artists) { return { type: artistsConstants.GET_STREAMS_STATS_SUCCESS, artists } }
    function failure(error) { return { type: artistsConstants.GET_STREAMS_STATS_FAILURE, error } }
}

function getArtistProfile(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return artistsService.getArtistProfile(id, globalFilter)        
            .then(
                artist => {
                    artist = artistsFormatter.formatProfile(artist);
                    dispatch(success(id, artist));
                },
                error => {
                    dispatch(failure('error'))
                }
            );

    };

    function request() { return { type: artistsConstants.GET_ARTIST_PROFILE_REQUEST } }
    function success(id, artist) { return { type: artistsConstants.GET_ARTIST_PROFILE_SUCCESS, id, artist } }
    function failure(error) { return { type: artistsConstants.GET_ARTIST_PROFILE_FAILURE, error } }
}


function getFollowers(channels) {
    return ( dispatch, getState ) => {
        dispatch(request());
        if(!channels || !channels.length){
            return dispatch(success(null));
        }
        const globalFilter = getState().filter.global;
        
        if(!channels.length)
            return dispatch(success(null));
        
        artistsService.getFollowers(channels, globalFilter)
            .then(
                followers => {
                    followers = artistsFormatter.formatFollowersForChart(followers);
                    dispatch(success(followers));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_ARTIST_FOLLOWERS_REQUEST } }
    function success(followers) { return { type: artistsConstants.GET_ARTIST_FOLLOWERS_SUCCESS, followers } }
    function failure(error) { return { type: artistsConstants.GET_ARTIST_FOLLOWERS_FAILURE, error } }
}

function getProducts(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return artistsService.getProducts(id)
            .then(
                products => {
                    const data = artistsFormatter.formatProducts(products.data);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_PRODUCTS_REQUEST } }
    function success(products) { return { type: artistsConstants.GET_PRODUCTS_SUCCESS, products} }
    function failure(error) { return { type: artistsConstants.GET_PRODUCTS_FAILURE, error } }
}

function getTopMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.map(entity=>entity.id);
        return artistsService.getMetadata(ids)
            .then(
                metadata => {
                    
                    let total = entities.length > 0 ? find(entities, entity=>entity.artist_id == '') : {},
                            rows = entities.filter(entity=>entity.artist_id != '');

                    const { formattedRows, formattedMeta }= artistsFormatter.formatMetadata(rows, metadata.data, total);
                    dispatch(success(formattedRows, total, formattedMeta));
                    return formattedRows;

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_TOP_ARTISTS_REQUEST } }
    function success(artists, total, metadata) { return { type: artistsConstants.GET_TOP_ARTISTS_SUCCESS, artists, total, metadata } }
    function failure(error) { return { type: artistsConstants.GET_TOP_ARTISTS_FAILURE, error } }

}

function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.id);
        artistsService.getMetadata(ids)
            .then(
                metadata => {
                    const data = artistsFormatter.formatMetadata(entities.data, metadata.data);
                    dispatch(success(data, entities.total, metadata.data));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_ARTISTS_REQUEST } }
    function success(artists, total, metadata) { return { type: artistsConstants.GET_ARTISTS_SUCCESS, artists, total, metadata } }
    function failure(error) { return { type: artistsConstants.GET_ARTISTS_FAILURE, error } }

}


function getTimeseriesArtists(params, useCache) {
    return ( dispatch, getState ) => {
        if(getState().artists.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;
        return artistsService.getTimeseriesArtists(params, globalFilter)
            .then(
                artists => {
                    const metadata = getState().artists.metadata;
                    const data = artistsFormatter.formatTimeseries(artists.result, metadata);
                    dispatch(success(data));
                    return artists;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_TIMESERIES_ARTISTS_REQUEST } }
    function success(artists) { return { type: artistsConstants.GET_TIMESERIES_ARTISTS_SUCCESS, artists } }
    function failure(error) { return { type: artistsConstants.GET_TIMESERIES_ARTISTS_FAILURE, error } }
}

function forceLoadAllData(force) {
    return dispatch => {
        dispatch(set(force));
    };

    function set(force) { return { type: artistsConstants.FORCE_LOAD_ALL_DATA, force} }
}
