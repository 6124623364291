import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'
import Modal from '../common/Modal'
import Select from './elements/Select'
import DatePicker from './elements/DatePicker'

import { composeValidators, required, date, length } from './validators'

class AddMilestoneForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notesMaxLength: 255
        };
    }

    getCharactersLeft(maxLength, prop) {
        const charactersDiff = maxLength - (prop ? prop.length : 0);
        const charactersLeft = charactersDiff > 0 ? charactersDiff : 0;

        return `Characters left: ${charactersLeft}`;
    }

    render() {
        let submit,
            {show, onSubmit, onClose, milestoneTypes, title, values={}} = this.props;
        
        return <Modal show={show} 
                title={title} 
                submitTitle="Save"
                handleSubmit={e=>submit(e)}
                handleClose={onClose}
            >
                <Form onSubmit={onSubmit} initialValues={values}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit;
                        return <form className="m-t">
                                <Field name="milestone_type_id"  
                                    validate={composeValidators(required)}
                                >
                                    {({ input, meta }) => {
                                        const hasError = meta.error && meta.touched;
                                        return <div className={`form-group${hasError ? ' has-error':''}`}>
                                            <label className="font-normal control-label">Title</label>
                                            <Select input={input} options={milestoneTypes} className="custom-select" />
                                            {hasError && <span className="help-block">{meta.error}</span>}
                                        </div>            
                                    }}
                                </Field>
                                <Field name="reference"  
                                    validate={composeValidators(required)}
                                >
                                    {({ input, meta }) => {
                                        const hasError = meta.error && meta.touched;
                                        return <div className={`form-group${hasError ? ' has-error':''}`}>
                                            <label className="font-normal control-label">Reference</label>
                                            <input {...input} type="text" className="form-control" />
                                            {hasError && <span className="help-block">{meta.error}</span>}
                                        </div>            
                                    }}
                                </Field>
                                
                                <div className="notes-holder">
                                    <Field name="notes"
                                        maxLength={this.state.notesMaxLength}
                                        validate={composeValidators(length)}
                                    >
                                        {({ input, meta }) => {
                                            const hasError = meta.error && meta.touched;
                                            return <div className={`form-group${hasError ? ' has-error':''}`}>
                                                <label className="font-normal control-label">Notes</label>
                                                <textarea {...input} type="text" className="form-control" />
                                                {hasError && <span className="help-block">{meta.error}</span>}
                                            </div>            
                                        }}
                                    </Field>
                                </div>
                                <div className="characters-left-holder">
                                    <span>{this.getCharactersLeft(this.state.notesMaxLength, values.notes)}</span>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <Field name="occurs_at"  
                                            validate={composeValidators(required, date)}
                                        >
                                            {({ input, meta }) => {
                                                const hasError = meta.error && meta.touched;
                                                return <div className={`form-group${hasError ? ' has-error':''}`}>
                                                    <label className="font-normal control-label">Target Date</label>
                                                    <DatePicker input={input} className="form-control" />
                                                    {hasError && <span className="help-block">{meta.error}</span>}
                                                </div>            
                                            }}
                                        </Field>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <Field name="ends_at"  
                                        validate={composeValidators(date)}
                                    >
                                        {({ input, meta }) => {
                                            const hasError = meta.error && meta.touched;
                                            return <div className={`form-group${hasError ? ' has-error':''}`}>
                                                <label className="font-normal control-label">End Date</label>
                                                <DatePicker input={input} className="form-control" />
                                                {hasError && <span className="help-block">{meta.error}</span>}
                                            </div>            
                                        }}
                                    </Field>
                                </div>
                            </div>
                        </form>
                    }}
                />
                
        </Modal>
    }
}
export default AddMilestoneForm