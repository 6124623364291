import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import BuildFilterQS from '../../helpers/BuildFilterQS';
import DeduplicateQS from '../../helpers/DeduplicateQS';
import config from '../../config/config';
import {searchService} from './search';


import topProducts from './stubs/top_products';
import productsMetadata from './stubs/products_metadata';
import topProductsTimeline from './stubs/top_products_timeline';


export const productsService = {
    getProducts,
    getTopProducts,
    getProductsOld,
    getProductsAutocomplete,
    getCard,
    getStreamsStats,
    getProductsByBarcode,
    getTracks,
    getMetadata,
    getTimeseriesProducts,
};

function getProducts(params, globalFilter){
    return config.live ? getProductsNew(params, globalFilter) : getProductsStatic();
}

function getProductsByBarcode(barcodes){
  const options = {
    method: 'GET',
    headers: AuthHeader()
  }
  const params = barcodes.endsWith(',') ? barcodes.replace(/\,+$/, '') : barcodes;
  const url = `${config.apis.miniCat}/v2/products/by_barcode/${params}`;
  return fetch(url, options)
  .then( response => response.json())
  .catch( error => Promise.reject(error));
}

function getProductsOld(params) {
    let { sorted, page, pageSize, filtered } = params;
    const requestOptions = {
        method: 'GET',
        headers: AuthHeader()
    };

    // TODO: API dependent, change for prod
    let baseUrl = `${config.apis.sales}/api/v1/Product`,
        qs = {};

    if(sorted !== undefined)
        qs.sort = {[sorted[0].id]: sorted[0].desc?-1:1};
    if(page !== undefined && pageSize !== undefined ) {
        qs.limit = pageSize;
        qs.skip = pageSize*params.page;
    }
    if(filtered !== undefined ) {
        let filters = [];
        for(let filter of filtered){
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array){
                if(value.length)
                    filters.push(`"${filter.id}": {"$in": ["${value.join('","')}"]}`);
            }
            else if(typeof value === 'string')
                filters.push(`"${filter.id}": {"$regex": "${value}", "$options": "i"}`);
            else
                filters.push(`"${filter.id}": ${value}`);

        }
        if(filters.length)
            qs.query = `{${filters.join(',')}}`;
    }
    //
    baseUrl += '?' + $.param(qs);

    let pages;

    return fetch(baseUrl, requestOptions)
    .then(res=>{
        pages = res.headers.get('X-Total-Count');
        return res.json()
    })
    .then(data=>{
        return {data, pages}
    });
}


function getTopProducts(params, globalFilter){
    return config.live ? getTopProductsLive(params, globalFilter) : getTopProductsStatic();
}

function getTopProductsLive(params, globalFilter) {
    let baseUrl = config.apis.secStat+'/top_products',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };

    // qs.query_name = 'top_products';
    // qs.offset = 0;
    qs.mode = 'main';
    let {sorted, filtered, page } = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;
            }
        }
    }
    if(page !== undefined){
        qs.offset = Number(page)*qs.limit; 
    }
    
    qs = DeduplicateQS(qs);

    baseUrl += '?' + $.param(qs);

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}



function getTopProductsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/top_products.json`;
    return fetch(baseUrl).then(res=>res.json());
}
/*
function getTopProductsStatic() {
    return Promise.resolve(topProducts);    
}
*/

function getProductsNew(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };

    qs.query_name = 'cat_nav_products';
    qs.offset = params.page ? params.page*globalFilter.limit : 0;
    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else if(typeof value === 'string') { // TODO: kinda crappy way of determining full-string search
                qs.search_by = filter.id;
                qs.search_term = filter.value;
            }
            else {
                qs[filter.id] = value;
            }
        }
    }
    qs = BuildFilterQS(qs, 'products');
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        return res.json()
    })
}

function getProductsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/products.json`;
    return fetch(baseUrl, {})
    .then(res=>{
        return res.json()
    });
}

function getProductsAutocomplete(input) {
    return searchService.autocomplete(input, ['products']);
}

function getCard(id) {
    let fields = 'id,name,logo_file_name',
        baseUrl = `${config.apis.miniCat}/v2/products/${id}?fields=${fields}`,
        requestOptions = {
            headers: AuthHeader()
        };
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        return res.json()
    });
}

function getStreamsStats(ids, globalFilter) {
    return config.live ? getStreamsStatsLive(ids, globalFilter) : getStreamsStatsStatic();
}

function getStreamsStatsLive(ids, globalFilter) {

    //
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    //

    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'top_products';
    // qs.offset = params.page ? params.page*globalFilter.limit : 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;
            }
        }
    }

    baseUrl += '?' + $.param(qs).replace(/%2C/g,',');

    return fetch(baseUrl, requestOptions)
    .then(res=>{
        return res.json()
    });
}

function getStreamsStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_products_streams.json`;
    return fetch(baseUrl, {})
    .then(res=>{
        return res.json()
    })
}

function getTracks(product_ids) {
    let baseUrl = `${config.apis.miniCat}/v2/products/acl_tracks`,
        requestOptions = {
            headers: AuthHeader({'Content-Type': 'application/json'}),
            method: 'POST',
            body: JSON.stringify({product_ids})
        };
        
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });

}

function getMetadata(ids){
    return config.live ? getMetadataLive(ids) : getMetadataStatic(ids);
}

function _getMetadataLive(ids) {
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.miniCat}/v2/products/by_barcode/?skip_tracks=true`,
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}),
            body: 'barcodes='+ids
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataLive(ids) {
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.miniCat}/v2/products/by_id/${ids}`,
        requestOptions = {
            method: 'GET',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}),
        };
 
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}


function getMetadataStatic(ids) {
	console.log(ids);
    let baseUrl = `${config.apis.sales}/api/static/sec/top_products_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}

/*
function getMetadataStatic() {
    return Promise.resolve(productsMetadata);    
}
*/


function getTimeseriesProducts(params, globalFilter){
    return config.live ? getTimeseriesProductsLive(params, globalFilter) : getTimeseriesProductsStatic();
}

function getTimeseriesProductsLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    
	let baseUrl = config.apis.secStat+'/top_products',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };

    qs.mode = 'timeseries';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            
            const key = filter.id == 'ids' ? 'products' : filter.id;
            
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[key] = value;
            }
            else {
                qs[key] = value;
            }
        }
    }
    qs = DeduplicateQS(qs);
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTimeseriesProductsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/top_products_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}