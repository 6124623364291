import { find } from 'lodash';
import { imprintsConstants } from '../constants/imprints';
import { imprintsService } from '../services/imprints';
import { imprintsFormatter } from '../formatters/imprints';

export const imprintsActions = {
    getImprints,
    getImprintsAutocomplete,
    getTopImprints,
    getImprintDetails,
    getCard,
    getCatalogCard,
    getStreamsStats,
    getMetadata,
    getTimeseriesImprints,
    forceLoadAllData
};

function getImprints(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        imprintsService.getImprints(params, globalFilter)
            .then(
                imprints => {
                    imprints = imprintsFormatter.formatImprints(imprints.result);
                    //dispatch(success(imprints.data, imprints.pages));
                    return dispatch(success(imprints));
                },
                error => {
                    console.log(error);
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_IMPRINTS_REQUEST } }
    function success(imprints) { return { type: imprintsConstants.GET_IMPRINTS_SUCCESS, imprints} }
    function failure(error) { return { type: imprintsConstants.GET_IMPRINTS_FAILURE, error } }
}


function getImprintsAutocomplete(search, limit=10) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        imprintsService.getImprintsAutocomplete(search)
            .then(
                imprints => {
                    imprints = imprintsFormatter.formatAutocomplete(imprints.results, ['imprints']);
                    dispatch(success(imprints.slice(0, limit)));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_IMPRINTS_AUTOCOMPLETE_REQUEST } }
    function success(imprints) { return { type: imprintsConstants.GET_IMPRINTS_AUTOCOMPLETE_SUCCESS, imprints} }
    function failure(error) { return { type: imprintsConstants.GET_IMPRINTS_AUTOCOMPLETE_FAILURE, error } }
}

function getTopImprints(params, useCache, addTotal = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;

        return imprintsService.getTopImprints(params, globalFilter)
            .then(
                imprints => {
                    const data = imprintsFormatter.formatTop(imprints.result);
                    //dispatch(success(data));
                    return dispatch(getTopMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_TOP_IMPRINTS_REQUEST } }
    function success(imprints) { return { type: imprintsConstants.GET_TOP_IMPRINTS_SUCCESS, imprints } }
    function failure(error) { return { type: imprintsConstants.GET_TOP_IMPRINTS_FAILURE, error } }
}

                
function getCard(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return imprintsService.getCard(id, globalFilter)
            .then(
                card => {
                    card= imprintsFormatter.formatCard(card);
                    dispatch(success(id, card));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_IMPRINTS_CARD_REQUEST } }
    function success(id, card) { return { type: imprintsConstants.GET_IMPRINTS_CARD_SUCCESS, id, card } }
    function failure(error) { return { type: imprintsConstants.GET_IMPRINTS_CARD_FAILURE, error } }
}

function getCatalogCard(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return imprintsService.getCatalogCard(id, globalFilter)
            .then(
                card => {
                    card= imprintsFormatter.formatCatalogCard(card);
                    dispatch(success(id, card));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_IMPRINTS_CARD_REQUEST } }
    function success(id, card) { return { type: imprintsConstants.GET_IMPRINTS_CARD_SUCCESS, id, card } }
    function failure(error) { return { type: imprintsConstants.GET_IMPRINTS_CARD_FAILURE, error } }
}

function getImprintDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const imprints = getState().imprints.top,
            imprint = find(imprints, {imprint_id: id});
        return Promise.resolve(imprint)
            .then(
                imprint => {
                    const data = imprintsFormatter.formatDetails(imprint.stms_by_date);
                    dispatch(success(id, data));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_IMPRINT_DETAILS_REQUEST } }
    function success(id, imprint) { return { type: imprintsConstants.GET_IMPRINT_DETAILS_SUCCESS, id, imprint } }
    function failure(error) { return { type: imprintsConstants.GET_IMPRINT_DETAILS_FAILURE, error } }
}                    

function getStreamsStats(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        imprintsService.getStreamsStats(ids, globalFilter)
            .then(
                imprints => {
                    const metadata = getState().imprints.metadata;
                    imprints = imprintsFormatter.formatStreamsStats(imprints, metadata);
                    dispatch(success(imprints));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_STREAMS_STATS_REQUEST } }
    function success(imprints) { return { type: imprintsConstants.GET_STREAMS_STATS_SUCCESS, imprints } }
    function failure(error) { return { type: imprintsConstants.GET_STREAMS_STATS_FAILURE, error } }
}

function getTopMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.map(entity=>entity.id);
        return imprintsService.getMetadata(ids)
            .then(
                metadata => {
                    let total = entities.length > 0 ? find(entities, entity=>entity.imprint_id == 0) : {},
                        rows = entities.filter(entity=>entity.imprint_id != 0);
                    
                    rows = imprintsFormatter.formatMetadata(rows, metadata.data, total);
                    dispatch(success(rows, total, metadata.data));
                    return rows;

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_TOP_IMPRINTS_REQUEST } }
    function success(imprints, total, metadata) { return { type: imprintsConstants.GET_TOP_IMPRINTS_SUCCESS, imprints, total, metadata } }
    function failure(error) { return { type: imprintsConstants.GET_TOP_IMPRINTS_FAILURE, error } }

}


function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.id);
        imprintsService.getMetadata(ids)
            .then(
                metadata => {
                    const data = imprintsFormatter.formatMetadata(entities.data, metadata.data);
                    dispatch(success(data, entities.total, metadata.data));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_IMPRINTS_REQUEST } }
    function success(imprints, total, metadata) { return { type: imprintsConstants.GET_IMPRINTS_SUCCESS, imprints, total, metadata } }
    function failure(error) { return { type: imprintsConstants.GET_IMPRINTS_FAILURE, error } }

}

function getTimeseriesImprints(params, useCache) {
    
    return ( dispatch, getState ) => {
        if(getState().imprints.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;
        return imprintsService.getTimeseriesImprints(params, globalFilter)
            .then(
                imprints => {
                    const metadata = getState().imprints.metadata;
                    const data = imprintsFormatter.formatTimeseries(imprints.result, metadata);
                    dispatch(success(data));
                    return imprints;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_TIMESERIES_IMPRINTS_REQUEST } }
    function success(imprints) { return { type: imprintsConstants.GET_TIMESERIES_IMPRINTS_SUCCESS, imprints } }
    function failure(error) { return { type: imprintsConstants.GET_TIMESERIES_IMPRINTS_FAILURE, error } }
}

function forceLoadAllData(force) {
    return dispatch => {
        dispatch(set(force));
    };

    function set(force) { return { type: imprintsConstants.FORCE_LOAD_ALL_DATA, force} }
}
