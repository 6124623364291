import React, { Component } from 'react';
import { connect } from 'react-redux';

class API extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const {user} = this.props.user;
        return <div className="wrapper wrapper-content light-blue-bg inner-page">
            <h2 className="big-title">API Settings</h2>
            {user.api_token && <div className="ibox-content ">
                Your API Key: 
                <input type="text" className="apikey" readOnly={true} value={user.api_token}></input>
            </div>}
            {!user.api_token && <p>Unfortunately you don't have access to API, please contact your administrator</p>}
            <h2 className="big-title">Documentation</h2>
            <div>
                <pre>
                    curl -H "apikey: your api key" "https://enterlytics-api-dev.entertainment-intelligence.com/search?search=records&entities=imprints" | json_pp
                </pre>
    
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}

export default connect(mapStateToProps)(API)