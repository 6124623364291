export const userFormatter = {
    formatUser
};

function formatUser(data) {
    let canGrantAccess = false;
    for (let silo of data.users_silos) {
        if(silo.can_grant_access) {
            canGrantAccess = true;
        }
    }
    data.can_grant_access = canGrantAccess;
    data.allow_api = Boolean(data.api_token);
    data.first_data_date = data.first_data_date || '2014-01-01'; 
    return data;
}