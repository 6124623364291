import React from 'react';
import { pull } from 'lodash';
import FieldsSelectorHelper from './FieldsSelectorHelper';

class FieldSelector extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            settings: this.props.settings,
            current: this.props.current, 
            initial: Object.assign({}, this.props.current)
        };
        this.changeFields = this.changeFields.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
    }

    changeFields(e) {
        let {name, value} = e.target,
            currentFields = this.state.current.columns.slice(0);

        if(currentFields.includes(value)) 
            pull(currentFields, value);
        else
            currentFields.push(value);
        
        this.setState({
            current: {
                ...this.state.current,
                [name]: currentFields
            }
        });
    }
    
    resetFilter(e){
        this.setState({
            current: Object.assign({}, this.state.initial)
        }, ()=>{
            return this.applyFilter(e);
        })
    }
    
    applyFilter(e){
        const filter = this.state.current;
        if(!filter.columns || !filter.columns.length)
            alert('Please select at least one field');
        else
            this.props.onUpdate(filter);
    }
    
    
    
    

    render() {
        const fields = FieldsSelectorHelper.pickFields(this.state.settings);
        return (
            <div className="fieldSelector">
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>    
                        <tbody>
                            {fields.map((item)=>{
                                if(item.expander == true)
                                    return null;
                                
                                return <tr key={item.id}>
                                    <td className="input-holder"><input type="checkbox" name="columns" value={item.id} onChange={this.changeFields} checked={this.state.current.columns.includes(item.id)} id={item.id} className="input-checkbox" /><label className="default-checkbox" htmlFor={item.id}></label></td>
                                    <td className="manage-field-name">{item.headerAlt||item.Header}</td>
                                </tr>    
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="links-holder">
                    <a className="reset" onClick={this.resetFilter}>Select All</a>
                    <a className="apply" onClick={this.applyFilter}>Apply</a>
                </div>
            </div>            
        )
    }
}

export default FieldSelector