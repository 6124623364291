import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <div>
                    <strong>Copyright</strong> Babel Ops Ltd &copy; 2022
                </div>
            </div>
        )
    }
}

export default Footer