import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Modal from '../../common/Modal';
import { importsActions } from '../../../data/actions/imports';
import { Link } from 'react-router-dom';

class ImportMetadata extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
        }
        this.renderProgress = this.renderProgress.bind(this);
    }
    

    
    renderProgress(importData) {
        return <div>
            <h4>Import Metadata</h4>
            <ul>
                <li>Status: {importData.import_status}</li>
                <li>Total Rows: {importData.total_lines}</li>
                <li>Processed: {importData.processed_lines} ({importData.percentDone}%)</li>
            </ul>
        </div>
    }
    
    renderLink(importData) {
        const {id, import_status} = importData;
        switch(import_status) {
            case 'failed_lines':
                return <Link to={`/import/metadata/${id}/errors`}>View Errors</Link>;
            case 'complete':
                return <Link to={`/import/list/`}>Close</Link>;
            default:
                return null;
        }
    }
    
    render() {
        const { show, importData, closeModal } = this.props;
        if(!importData)
            return null;
        const { import_status } = importData;
        
        
        return <Modal
                show={show}
                title="Metadata Import"
                handleClose={closeModal}
                showActionButtons={false}
                dialogClassName="dialog-modal"
            >
                {this.renderProgress(importData)}
                {this.renderLink(importData)}
            </Modal>
    }
}


export default ImportMetadata;