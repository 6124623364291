import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Route, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Spinner from '../../components/common/Spinner';
import Datepicker from '../../components/common/Datepicker';
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { importsActions } from '../../data/actions/imports';
import { statementPeriodsSettings } from '../../components/pages/imports/statementPeriods/Settings';
import ImportData from '../../components/pages/imports/ImportData';
import ImportMetadataDetails from './ImportMetadataDetails';
import ImportMetadataErrors from './ImportMetadataErrors';

var deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');

class ImportMetadata extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
        }
        this.selectCsv = this.selectCsv.bind(this);
        this.uploadCsv = this.uploadCsv.bind(this);
    }
    
    selectCsv(e){
        const file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsText(file, 'utf-8');
        reader.onload = () => {
            this.setState({file, name: file.name, body: reader.result});
        };

    }
    
    uploadCsv(){
        const { name, body } = this.state;
        const path = `imports/csvs/${uuidv4()}/${name}`;
        this.props.dispatch(importsActions.getUploadForm(path, body))
        .then(url=>{
            return this.props.dispatch(importsActions.initiateCatalogImport(url));
        })
        .then(id=>{
            if(id)
                this.props.history.push(`/import/metadata/${id}/details`);
        })
    }
    
    render() {
        return <div className="inner-page shared-pages">
            <div className="container">
                <div className="import-menu import-menu-exceptions">
                    <h5>Catalog</h5>
                    <ul>
                        <li><Link to={`/import/list`}>History</Link></li>
                        <li><Link to={`/import/metadata`}>Import</Link></li>
                    </ul>
                    
                </div>
            
                <h2>Import Metadata</h2>
                <div className="ibox">
                    <div>
                        <input type="file" name="csv" id="csv_file" className="input-file" onChange={this.selectCsv} />
                        <label htmlFor="csv_file" className="btn btn-w-m btn-default upload-label">Select File</label>
                        {this.state.file && <button className="btn btn-w-m btn-default upload-label" onClick={this.uploadCsv}>Upload</button> }
                    </div>
                    {this.state.name && <p>Selected file: {this.state.name}</p>}
                </div>
            </div>
            <Route path="/import/metadata/:id/details" render={(props)=><ImportMetadataDetails {...props} />}></Route>
            <Helmet>
                <title>Import Metadata</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        imports: state.imports
    } 
}

export default withRouter(connect(mapStateToProps)(ImportMetadata))