import { errorActions } from './error';
import { milestonesConstants } from '../constants/milestones';
import { milestonesService } from '../services/milestones';
import { milestonesFormatter } from '../formatters/milestones';
import { find } from 'lodash';

export const milestonesActions = {
    getMilestones,
    getEntityMilestones,
    getMilestoneTypes,
    addMilestone,
    editMilestone,
    deleteMilestone,
    linkMilestone,
    linkAudienceMilestone,
    unlinkMilestone
};

function getMilestones(campaign) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return milestonesService.getMilestones(campaign)
            .then(
                milestones => {
                    const {table, datasets} = milestonesFormatter.formatMilestones(milestones, globalFilter, false);
                    dispatch(success(table, datasets));
                    return table;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: milestonesConstants.GET_MILESTONES_REQUEST } }
    function success(table, datasets) { return { type: milestonesConstants.GET_MILESTONES_SUCCESS, table, datasets} }
    function failure(error) { return { type: milestonesConstants.GET_MILESTONES_FAILURE, error } }
}

function getEntityMilestones(entity, ids) {
    return ( dispatch, getState ) => {
        dispatch(request(entity));
        const globalFilter = getState().filter.global,
            currentUser = getState().user.user;
        return milestonesService.getMilestoneTypes()
            .then(types => {
                return milestonesService.getEntityMilestones(entity, ids, currentUser)
                .then(
                    milestones => {
                        const {table, datasets} = milestonesFormatter.linkEntitiesToMilestones(milestonesFormatter.formatMilestones(milestones, globalFilter, true, types), entity, ids);
                        dispatch(success(table, datasets, entity));
                        return table;
                    },
                    error => {
                        dispatch(failure('error', entity))
                    }
                );
        });
    };

    function request(entity) { return { type: milestonesConstants.GET_ENTITY_MILESTONES_REQUEST, entity } }
    function success(table, datasets, entity) { return { type: milestonesConstants.GET_ENTITY_MILESTONES_SUCCESS, table, datasets, entity} }
    function failure(error, entity) { return { type: milestonesConstants.GET_ENTITY_MILESTONES_FAILURE, error, entity } }
}


function getMilestoneTypes(useCache=true) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        const types = useCache ? getState().milestones.types : undefined;
        if(types!==undefined) {
            dispatch(success(types));
            return;
        }

        return milestonesService.getMilestoneTypes()
            .then(
                types => {
                    const data = milestonesFormatter.formatMilestoneTypes(types);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: milestonesConstants.GET_MILESTONE_TYPES_REQUEST } }
    function success(types) { return { type: milestonesConstants.GET_MILESTONE_TYPES_SUCCESS, types } }
    function failure(error) { return { type: milestonesConstants.GET_MILESTONE_TYPES_FAILURE, error } }
}

function addMilestone(campaign, params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        params = milestonesFormatter.formatNewMilestone(campaign, params);
        
        return milestonesService.addMilestone(params)
            .then(
                milestone => {
                    dispatch(success());
                    dispatch(errorActions.setSuccessMessage('milestone saved'));
                    //dispatch(getMilestones(campaign));
                    return milestone;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.ADD_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.ADD_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.ADD_MILESTONE_FAILURE } }

}

function editMilestone(campaign, params, milestoneID, showSuccessMessage = true) {
    return ( dispatch, getState ) => {
        dispatch(request());
        params = milestonesFormatter.formatNewMilestone(campaign, params);
        
        return milestonesService.editMilestone(params, milestoneID)
            .then(
                milestone => {
                    dispatch(success());
                    if(showSuccessMessage)
                        dispatch(errorActions.setSuccessMessage('milestone updated'));
                    return true;
                    //dispatch(getMilestones(campaign));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.ADD_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.ADD_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.ADD_MILESTONE_FAILURE } }

}

function deleteMilestone(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        return milestonesService.deleteMilestone(id)
            .then(
                milestone => {
                    dispatch(success());
                    dispatch(errorActions.setSuccessMessage('milestone deleted'));
                    return true;
                    //dispatch(getMilestones(campaign));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.ADD_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.ADD_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.ADD_MILESTONE_FAILURE } }

}

function linkMilestone(params, milestone, mode) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        params = milestonesFormatter.formatLinkMilestone(params, milestone, currentUser, mode);
        
        milestonesService.linkMilestone(params)
            .then(
                milestone => {
                    dispatch(success());
                    dispatch(errorActions.setSuccessMessage('milestone linked to an entity'));
                    //dispatch(getMilestones(campaign));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.LINK_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.LINK_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.LINK_MILESTONE_FAILURE } }

}

function linkAudienceMilestone(entity, entityID, milestoneID) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        const params = milestonesFormatter.formatLinkAudienceMilestone(entity, entityID, milestoneID, currentUser);
        return milestonesService.linkMilestone(params)
            .then(
                milestone => {
                    dispatch(success());
                    return true;
                    //dispatch(errorActions.setSuccessMessage('milestone linked to an entity'));
                    //dispatch(getMilestones(campaign));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.LINK_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.LINK_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.LINK_MILESTONE_FAILURE } }

}


function unlinkMilestone(id, milestoneID) {
    return ( dispatch, getState ) => {
        dispatch(request());

        milestonesService.unlinkMilestone(id)
            .then(
                milestone => {
                    dispatch(success(id, milestoneID));
                    dispatch(errorActions.setSuccessMessage('milestone link deleted'));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.UNLINK_MILESTONE_REQUEST } }
    function success(id, milestoneID) { return { type: milestonesConstants.UNLINK_MILESTONE_SUCCESS, id, milestoneID } }
    function failure() { return { type: milestonesConstants.UNLINK_MILESTONE_FAILURE } }

}