import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import { pdf as PDFRenderer }from '@react-pdf/renderer';

import Box from '../../components/common/Box';
import { imprintsActions } from '../../data/actions/imprints';
import TransactionTypes from '../../components/common/Stats/TransactionTypes';
import EntityCard from '../../components/common/EntityCard';
import EntitiesList from '../../components/pages/home/EntitiesList';
import TerritoryStats from '../../components/common/Stats/Territory';
import DemographicsStats from '../../components/common/Stats/Demographics';
import DevicesStats from '../../components/common/Stats/Devices';
import SourcesStats from '../../components/common/Stats/Sources';
import VendorsWidget from '../../components/pages/home/VendorsWidget';
import SharePage from '../../components/pages/sharedPages/SharePage';
import CSV from '../../helpers/CSVExport';
import {convertToThousands} from '../../helpers/NumberFormatter';
import { dspLogos } from "../../components/common/Stats/utils";
import PDFDocument from '../../components/pages/imprints/PDF';

const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');

class ImprintDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
        };
        this.setParentEntity = this.setParentEntity.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({id: match.params.id, name: match.params.name}); // 
    }

    componentWillReceiveProps(nextProps){
        const { match, filter, dispatch } = nextProps;
        if(match.params.id != this.state.id) {
            this.setState({id: null}, ()=>{
                this.setState({id: match.params.id, name: match.params.name}, ()=>{
                    dispatch(imprintsActions.getCard(this.state.id)).then(card=>this.setParentEntity(card));
                }); // 
            });
        }
        
        if(filter){
            if(filter.global){
                if(this.props.filter.global!==undefined && !isEqual(filter.global, this.props.filter.global)) {
                   dispatch(imprintsActions.getCard(this.state.id)).then(card=>this.setParentEntity(card));
                }
            }
        }
    }

    setParentEntity(entity) {
        this.setState({
        	artists: entity.curr_artists, 
        	products: entity.curr_products, 
            tracks: entity.curr_tracks,  
            name: entity.imprint,  
            totalIncome: entity.total_income, 
            totalQuantity: entity.total_quantity,
        })
    }

    exportToCsv = () => {
      const {
    	  artists,
    	  projects,
    	  tracks,
    	  products,
        stats,
      } = this.props;

      const data = [
                    { top_artists: artists.top },                    
                    { top_products: products.top },                    
                    { top_tracks: tracks.top },
        { platforms: stats.vendor.table },
        { territories: stats.territory.table },
        { transactions: stats.transactions[this.state.id] }
      ];
      
      const filename = CSV.CSVHeader('imprint_details', this.state.id, this.props.filter.global);

      CSV.CSVBulkExport(data, filename);

    }
    
    getPdfData() {
        const reportTitle = 'Label Details',
            { artists, projects, products, tracks, stats } = this.props,
            { id } = this.state;
            
        const imageIDs = [
              'top_territories_pie_chart',
              'top_vendors_pie_chart',
        ];
        let images = {};
        for (let imageID of imageIDs){
            let image = document.getElementById(imageID);
            if(image)
                images[imageID] = image.toDataURL("image/jpeg,1.0"); 
        }
        
        let vendorTableData = [];

        if (this.props.stats.vendor)
            vendorTableData = this.props.stats.vendor.table
            
        const { name, artists: totalArtists, products: totalProducts, tracks: totalTracks, totalIncome, totalQuantity} = this.state;
        const overview = [
            {title: "Label", value: name},
            {title: "Income", value: totalIncome ? convertToThousands(totalIncome, '$') : ''},
            {title: "Qty", value: totalQuantity ? convertToThousands(totalQuantity, '$') : ''},
            {title: "Artists", value: totalArtists },
            {title: "Products", value: totalProducts },
            {title: "Tracks", value: totalTracks}
        ]

        const tables = {
                artists: artists.top,
                projects: projects.top,
            products: products.top,
            tracks: tracks.top,
            territories: stats.territory.table,
            transactions: stats.transactions[id],
            vendorsTable: vendorTableData,
            overview
        };
        return { reportTitle, images, tables, overview: stats.top, globalFilter: this.props.filter.global };
    }

    exportToPdf() {
        const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
        data.toBlob()
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "label_details.pdf";
            link.click();
        });
    }
    

    render() {
        if(!this.state.id)
            return null;
        
        const { name, artists, products, tracks, totalIncome, totalQuantity} = this.state;
        let metaTitle = name ? `${name} - ` : '';
        
        metaTitle += 'Details';
        
        const { sharedMode } = this.props;
        
        return <div className="imprint-details artist-profile homepage">
            <div className="container">
                {/*<h2 className="main-title">Label details - {name}</h2>*/}
                <div className="row download-page download-page--mt">
	                <span>Export the data</span>
	                {sharedMode && <a key="download" title="Download entire page" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} /> }
                    {!sharedMode && <SharePage entity="imprints" id={this.state.id} exportToCsv={this.exportToCsv} exportToPdf={this.exportToPdf} CSVEntities={["top_artists", "top_products", "top_projects", "top_tracks", "territories", "top_vendors"]} /> }
	            </div>
                <div className="main-artist-holder">
                    <div className="main-artist-name-holder">
                        <div className="main-artist-image">
                            <EntityCard entity="imprints" id={this.state.id} onResult={this.setParentEntity} showLink={true} />
                        </div>
                        <h3 className="middle-title">{name || `${this.state.id} - Unlisted Catalogue`}</h3>
                    </div>
                    <div className="main-artist-details">
                        <div className="main-artist-details-item">
                            <div className="main-artist-details-title">Income</div>
                            <div className="main-artist-details-value">{totalIncome ? convertToThousands(totalIncome) : ''}</div>
                        </div>
                        <div className="main-artist-details-item">
                            <div className="main-artist-details-title">Artists</div>
                            <div className="main-artist-details-value">{artists}</div>
                        </div>
                        <div className="main-artist-details-item">
                            <div className="main-artist-details-title">Products</div>
                            <div className="main-artist-details-value">{products}</div>
                        </div>
                        <div className="main-artist-details-item">
	                        <div className="main-artist-details-title">Tracks</div>
	                        <div className="main-artist-details-value">{tracks}</div>
	                    </div>                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <TransactionTypes entity="imprints" id={this.state.id} />
                    </div>
                </div>
            	<div className="row">
	                <div className="col-xs-12 pd-0 default">
	                    <EntitiesList trends={true} title="Top Artists" entity="artists" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} addTotal={true} externalLinks={!sharedMode} />
	                </div>
	            </div>                
            	<div className="row">
	                <div className="col-xs-12 pd-0 default">
	                    <EntitiesList trends={true} title="Top Projects" entity="projects" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} addTotal={true} externalLinks={!sharedMode} />
	                </div>
	            </div>
            	<div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <EntitiesList trends={true} title="Top Products" entity="products" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} addTotal={true} externalLinks={!sharedMode} />
                    </div>
                </div>             
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <EntitiesList trends={true} title="Top Tracks" entity="tracks" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} addTotal={true} externalLinks={!sharedMode} />
                    </div>
                </div> 
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <TerritoryStats entity="imprints" ids={this.state.id}  parentEntityTitle={this.state.title} chartType={dspLogos.territories.id} />
                    </div>
                </div>                    
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <VendorsWidget entity="imprints" id={this.state.id} parentEntityTitle={this.state.title} />
                    </div>
                </div>
                <Helmet>
                    <title>{metaTitle}</title>
                </Helmet>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        imprints: state.imprints,
        filter: state.filter,
        stats: state.stats,
        projects: state.projects,
        products: state.products,
        tracks: state.tracks,
        artists: state.artists
    }
}

export default connect(mapStateToProps)(ImprintDetails)
