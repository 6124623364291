import $ from 'jquery';
import config from '../../config/config';
import AuthHeader from '../../helpers/AuthHeader';

export const userService = {
    login,
    sso,
    sendTicket,
    track, 
    uploadAvatar,
    shareLogin
};

function login(params) {
    const baseUrl = `${config.apis.orthus}/v1/users/sign_in`,
        {email, password} = params,

        requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },            
            body: JSON.stringify({email, password})
        };        
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.ok ? res.json() : Promise.reject(res)
    })
    .catch(ex=>Promise.reject(ex))
}


function sso(payload) {
    const baseUrl = `${config.apis.orthus}/sso/v1/login`,

        requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },            
            body: JSON.stringify({payload})
        };        
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.ok ? res.json() : Promise.reject(res)
    })
    .catch(ex=>Promise.reject(ex))
}


function sendTicket(formData) {
    return fetch(`${config.apis.orthus}/v1/users/help`, {
        method: 'POST',
        headers: AuthHeader(),
        body: formData
    })
    .then(res=>{   
        return res.ok ? res.json() : Promise.reject(res)
    })
    .catch(ex=>Promise.reject(ex))
}
    
function track(params) {
    var formData = new FormData();
    for(let key in params)
        formData.append(key,  params[key]);
    const dfd = config.userActivityLog ? fetch(`${config.apis.orthus}/v1/user_action_logs`, {
        method: 'POST',
        headers: AuthHeader(),
        body: formData
    }) : Promise.resolve();
    return dfd;
}

function uploadAvatar(file) {
    let formData = new FormData();
    formData.append('avatar', file);
    
    return fetch(`${config.apis.orthus}/v1/upload_avatar`, {
        method: 'PUT',
        headers: AuthHeader(),
        body: formData
    })
    .then(res=>{   
        return res.ok ? res.json() : Promise.reject(res)
    })
}

function shareLogin(slug) {
    let baseUrl = `${config.apis.orthus}/v1/shared_pages/${slug}`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.ok ? res.json() : Promise.reject(res)
    });
}