import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

export default function(ComposedComponent) {
    class WithUser extends Component {

        constructor(props){
            super(props);
            this.state={
                loading: false
            };
            this.checkLoggedIn = this.checkLoggedIn.bind(this);
        }

        checkLoggedIn(){
            if(!this.props.user.loggedIn){
                this.props.history.push(`/login?back=${encodeURIComponent(this.props.history.location.pathname)}`);
            }
            /*
            if(!this.props.user.items){
                if(!this.state.loading){
                    const { dispatch } = this.props;
                    this.setState({
                            loading: true
                        }, 
                        ()=>dispatch(userActions.getUser())
                    );
                }
            } 
            */           
        }


        componentWillMount() {
            this.checkLoggedIn();
        }
        
        /*

        shouldComponentUpdate(nextProps) {
            this.checkLoggedIn();
            return (this.props.user === undefined && nextProps.user !== undefined);
        }
        
        */

        render() {
            if(!this.props.user.loggedIn){
                return null;
            }
            else {
                return <ComposedComponent {...this.props} />
            }
        }
    }

    function mapStateToProps(state) {
        return { 
            user: state.user
        };
    }

    return withRouter(connect(mapStateToProps)(WithUser));
}