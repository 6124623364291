import React from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { find, maxBy, isEqual } from 'lodash';

import config from '../../../config/config';
import CSV from '../../../helpers/CSVExport';
import Box from '../../common/Box';
import TrendDirection from '../../common/TrendDirection';
import { audienceActions } from '../../../data/actions/audience';
import PieChart from '../../widgets/charts/types/PieChart';
import { sourcesSettings } from './Sources/Settings';
import VendorLogo from '../../common/VendorLogo';
import { dspLogos } from "../../common/Stats/utils";

var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class SourcesStats extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
          mode: "pie",
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }

    setChartMode(mode){
        this.setState({mode});
    }
    
    componentDidMount() {
        const { chartType } = this.props;
        this.props.dispatch(audienceActions.getSourceStats(this.props.id, this.props.entity))
        
    }
    
    exportToCsv(){
        const filename = CSV.CSVHeader('audience_details_sources', 'listeners', this.props.filter.global, this.props.parentEntityTitle);
        return CSV.CSVExport(this.props.audience.source.table, {filename});
    }
    
    componentWillReceiveProps(nextProps) {
        const globalFilter = nextProps.filter.global;
        const { chartType } = this.props;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.props.dispatch(audienceActions.getSourceStats(this.props.id, this.props.entity));
            }                
        }
    }
    
    
    renderToolbar(modes){
        let toolbar = [];

        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-icon-holder">
            <a key='pie' title="Pie Chart" className={`chart-icon ${this.state.mode == 'pie' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('pie')}
                dangerouslySetInnerHTML={{__html: pieSvg}}>
            </a>
            <a key='table' title="Table" className={`chart-icon ${this.state.mode == 'table' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('table')}
                dangerouslySetInnerHTML={{__html: tableSvg}}>
            </a>
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            
        </div>
        </div>)
        return toolbar;
    }    
    

    renderVendorLogos() {
        const { chartType } = this.props;
        const logoData = ['spotify'];

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} />
            ))}
        </div>
    }
    
    render (){
        const { audience } = this.props;
        
        const rows = (audience.source && audience.source.table) ? audience.source.table.length : 0;
        return <Box title="Sources of Discovery" toolbar={this.renderToolbar()} vendorLogoList={this.renderVendorLogos()} spinnerEnabled={audience.sourceLoading} className="source-discovery-holder">
            {audience.source && <div className="row">
                <div className={`col-xs-12 col-sm-12`}>
                    {this.state.mode=='pie' && <div className="chart-block">
                        <PieChart data={audience.source.chart} id="audience_sources_pie_chart" />                          
                    </div>}
                    {this.state.mode=='table' && <div className="sources-table territory-table--single custom-scroll">
                        <ReactTable
                            className="source-table"
                            data={audience.source.table}
                            columns={sourcesSettings.settingsForTable('details')}
                            defaultPageSize={(audience.source.table && audience.source.table.length ) ? Math.min(audience.source.table.length, 10) : 10}
                            showPagination={rows > 10}
                            getTrProps={sourcesSettings.settingsForRow}
                    /></div>}
                </div>    
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(SourcesStats);