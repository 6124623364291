import React, { Component } from 'react';
import ReactTable from "react-table";
import {Helmet} from 'react-helmet';
import store from '../helpers/store';
import cellHeaderHOC from '../components/common/Datagrid/CellHeaderHOC';

import { connect } from 'react-redux';
import { debounce, find, findIndex, isEqual } from 'lodash';
import { withRouter } from 'react-router';

import Box from '../components/common/Box';
import { PieChart } from '../components/widgets/charts/types';
import FieldSelector from '../components/common/Datagrid/FieldsSelector';
import PageFilter from '../components/common/Datagrid/PageFilter';
import EntityFilter from '../components/common/EntityFilter';
import EntityFilterSwitcher from '../components/common/EntityFilterSwitcher';
import SectionHeader from '../components/common/Datagrid/SectionHeader';
import FieldsSelectorHelper from '../components/common/Datagrid/FieldsSelectorHelper';
import StreamsStats from '../components/common/Stats/Streams';
import TerritoryStats from '../components/common/Stats/Territory';
import CSV from '../helpers/CSVExport';
import CatalogOverview from '../components/widgets/catalog/CatalogOverview';
import LoadingMessage from '../helpers/LoadingMessage';

import { artistsActions } from '../data/actions/artists';
import { artistsFormatter } from '../data/formatters/artists'
import { artistsSettings } from '../components/pages/artists/Settings'
import { filterActions } from '../data/actions/filter';

var manageSvg = require('!svg-inline-loader!../../public/img/manage.svg');
var pieSvg = require('!svg-inline-loader!../../public/img/pie.svg');
var tableSvg = require('!svg-inline-loader!../../public/img/table.svg');
var worldSvg = require('!svg-inline-loader!../../public/img/world.svg');
var lineSvg = require('!svg-inline-loader!../../public/img/line.svg');
var downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');
const CellHeaderTable = cellHeaderHOC(ReactTable);

class Artists extends Component {
  defaultPageSize = 10;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: -1,
      page: 0,
      //            pageSize: 10,
      filtered: "",
      filter: {
        // will be set from FieldsSelector
        columns: this.getInitialFilter(),
        filtered: [
          { id: "artist_name", value: "" },
          { id: "imprints", value: this.getPageFilterInitial(props, "imprints") },
          { id: "exclude_imprints", value: this.getPageFilterInitial(props, "exclude_imprints") },          
          { id: "track_artists", value: this.getPageFilterInitial(props, "artists") },
          { id: "exclude_track_artists", value: this.getPageFilterInitial(props, "exclude_artists") }
        ],
      },
      defaultSorted: [{ id: "curr_units", desc: true }],
      view: "table",
      sources: {"Main": false, "Stream": false, "Video": false, "Radio": true, "Download": true, "Social Music": false}
    };
    this.getPageFilter = this.getPageFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setFilterSearch = this.setFilterSearch.bind(this);
    this.setFilterSearchDeb = debounce(this.setFilterSearch, 1000);
    this.dispatchLoadData = this.dispatchLoadData.bind(this);
    this.addToArtistsFilter = this.addToArtistsFilter.bind(this);
    this.exportToCsv = this.exportToCsv.bind(this);
    this.expandSource = this.expandSource.bind(this);
  }

    componentDidMount() {
        //this.dispatchLoadData(this.state);
    }

    getPageSize() {
        return this.props.filter.global ? this.props.filter.global.limit : this.defa
    }

    loadData(state, instance) {
        this.setState({
            sorted: state.sorted
        }, ()=>this.dispatchLoadData(state));
    }

    getInitialFilter() {
        return FieldsSelectorHelper.getInitialFilter(artistsSettings.settingsForTable());
    }

    dispatchLoadData(state){
        const { dispatch } = this.props;
        dispatch(artistsActions.getArtists({
            page: state.page,
//            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
        }));
    }

    getPageFilter(props, entity){
        let ids = [];
        if(props.filter[entity])
            ids = props.filter[entity].map(item=>item.id);
        return ids;
    }

    getPageFilterInitial(props, entity){
        const filter = store.get(entity);
        
        let ids = [];
        
        if(filter && Array.isArray(filter.body.value))
            ids = filter.body.value.map(item=>item.id);
        return ids;
    }
    
    checkFilterIDs(nextProps, filterKey, valueKey) {
        if(nextProps.filter[filterKey]) {
            const newFilter = this.getPageFilter(nextProps, filterKey);
            const existingFilter = find(this.state.filter.filtered, {id:valueKey}).value;
            if(!isEqual(newFilter, existingFilter)){
                this.setFilterSearch(newFilter, valueKey);
            }
        }
    }

    componentWillReceiveProps(nextProps){
        const filterKeys = [
            {filter: 'imprints', value: 'imprints'},
            {filter: 'artists', value: 'track_artists'}
        ];
        
        if(nextProps.artists && nextProps.artists.items){
            this.setState({
                data: nextProps.artists.items,
                pages: Math.ceil(nextProps.artists.total / this.getPageSize()),
                loaded: true
            })
        }
        if(nextProps.filter){
            if(nextProps.filter.global){
                if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                    let state = Object.assign({}, this.state);
                    state.filtered = state.filter.filtered;
                    this.dispatchLoadData(state);
                }
                /*
                this.setState({
                    pageSize: nextProps.filter.global.limit
                });
                */
            }
            for(let filterKey of filterKeys) {
                this.checkFilterIDs(nextProps, filterKey.filter, filterKey.value);
                this.checkFilterIDs(nextProps, `exclude_${filterKey.filter}`, `exclude_${filterKey.value}`);
            }            
        }
    }

    setFilter(filter){
        this.setState({
            filter,
        });
    }


    setFilterSearch(value, id){
        let filtered = this.state.filter.filtered.slice(0),
            valueIndex = findIndex(filtered, {id});

        if(valueIndex!==-1){
            filtered[valueIndex] = {id, value};
        }
        else {
            filtered.push({id, value})
        }

        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filtered
            }
        });
    }

    setSearch(e){
        const value = e.target.value;
        this.setState({
            filtered: value
        })
        this.setFilterSearchDeb(value, 'artist_name')
    }

    renderToolbar(){
        let toolbar = [
            <a key="table" title="Table" className={"chart-icon " + (this.state.view == 'table' ? 'active' : '')} dangerouslySetInnerHTML={{__html: tableSvg}} onClick={()=>this.setView('table')}>
            </a>,
            <a key="chart" title="Pie Chart" className={"chart-icon " + (this.state.view == 'chart' ? 'active' : '')} dangerouslySetInnerHTML={{__html: pieSvg}} onClick={()=>this.setView('chart')}>
            </a>,
            <a key="line" title="Line Chart" className={"chart-icon " + (this.state.view == 'line' ? 'active' : '')} dangerouslySetInnerHTML={{__html: lineSvg}} onClick={()=>this.setView('line')}>
            </a>,
            <a key="map" title="Map" className={"chart-icon " + (this.state.view == 'map' ? 'active' : '')} dangerouslySetInnerHTML={{__html: worldSvg}} onClick={()=>this.setView('map')}>
            </a>,

            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        ];
        return toolbar;
    }

    setView(view){
        this.setState({
            view
        })
    }

    addToArtistsFilter(artist){
        return;
        // TODO: fix persistence used in PageFilter.addToFilter
        this.props.dispatch(filterActions.setEntityFilter('artists', [artist]))
        this.props.history.push('/catalog/products');
    }

    exportToCsv(){
        const sorted = this.state.sorted[0];
        const filename = CSV.CSVHeader('artists', sorted.id, this.props.filter.global);
        const data = CSV.expandTrends(this.state.data);
        return CSV.CSVExport(data, {filename}, 'artists');
    }

    expandSource(source, event) {
        if(event) {
            event.preventDefault();
            event.stopPropagation();
        }
        let sources = Object.assign({}, this.state.sources);
        sources[source] = !sources[source];
        this.setState({
            sources
        });
    }

    render() {
        const { loaded } = this.state;
        let tableData = this.state.data,
            ids = [];

        if(tableData.length)
            ids = tableData.filter(item=>item.id).map(item=>item.id);

        return (
            <div>
            <CatalogOverview view="catalog" />
            {!this.props.artists.loading && <div className="section-filter">
                <div className="page-filter-holder">
                    <EntityFilterSwitcher loaded={loaded} entity="imprints" label="Imprints" />
                    <EntityFilterSwitcher loaded={loaded} entity="artists" label="Artists" />
                </div>
            </div>}
            <div className="wrapper wrapper-content light-blue-bg">
                <Box title="" toolbar={this.renderToolbar()} className="light-blue-bg catalogue-section-holder">
                    <SectionHeader entity="artists" label="Artists" searchEntity="Artist" data={this.state.data} filtered={this.state.filtered} view={this.state.view} onChange={this.setSearch}>
                        <div className="manage-columns-holder">
                            <a key="settings" className="dropdown-toggle manage-columns-title" data-toggle="dropdown" href="#">
                                Manage Columns
                                <span className="chart-icon" dangerouslySetInnerHTML={{__html: manageSvg}}></span>
                            </a>
                            <div key="settings-box" className="dropdown-menu">
                                <FieldSelector settings={artistsSettings.settingsForTable()} current={this.state.filter} onUpdate={this.setFilter} />
                            </div>
                        </div>
                    </SectionHeader>
                    {this.state.view == 'table' && <div>
                        <CellHeaderTable
                            manual
                            loading={this.props.artists.loading}
                            loadingText={LoadingMessage('catalog')} 
                        noDataText='No results found. Please check your filters.'                        
                            className='catalogue-table-holder catalogue sticky-table'
                            data={tableData}
                            pages={this.state.pages}
                            defaultPageSize={this.getPageSize()}
                            showPageSizeOptions={false}
                            columns={artistsSettings.settingsForTable(
                                this.state.filter.columns,
                                this.addToArtistsFilter,
                                this.state.sources, 
                                this.expandSource
                            )}
                            currentlySelectedSource={null}
                            defaultSorted={this.state.defaultSorted}
                            filtered={this.state.filter.filtered}
                            onFetchData={this.loadData}
                            defaultSortDesc={true}
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                  onMouseEnter: (e, handleOriginal) => {
//                                    console.log('A Td Element was clicked!')
//                                    console.log('it produced this event:', e)
//                                    console.log('It was in this column:', column)
//                                    console.log('It was in this row:', rowInfo)
//                                    console.log('It was in this table instance:', instance)
                                      
                                      console.log(state);
                                      console.log(instance);
                             
                                    // IMPORTANT! React-Table uses onClick internally to trigger
                                    // events like expanding SubComponents and pivots.
                                    // By default a custom 'onClick' handler will override this functionality.
                                    // If you want to fire the original onClick handler, call the
                                    // 'handleOriginal' function.
                                    if (handleOriginal) {
                                      handleOriginal()
                                    }
                                  }
                                }
                              }}                        
                        />
                    </div>}
                    {this.state.view == 'chart' && <div class="table-chart"><PieChart
                        data={artistsFormatter.formatForPercentageChart(tableData, 'curr_units')}
                        labels={{header: 'Artists', titleLabel: 'Artist', valueLabel: 'streams'}}
                    /></div>}
                    {this.state.view == 'line' && <div class="table-chart"><StreamsStats
                        entity="artists" ids={ids}
                    /></div>}
                    {this.state.view == 'map' && <div class=""><TerritoryStats
                        entity="artists" ids={ids} expanded={true}
                    /></div>}

                </Box>
                <div className="row">
                    <div className="col-xs-4">

                    </div>
                </div>
            </div>
            <Helmet>
                <title>Artists - Catalogue Overview</title>
            </Helmet>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        artists: state.artists,
        filter: state.filter
    }
}
export default withRouter(connect(mapStateToProps)(Artists))
