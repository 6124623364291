import React from 'react';
import { connect } from 'react-redux';
import { filter, isEqual, map, sumBy } from 'lodash';
import store from '../../../../helpers/store';

import BarChart from '../../../widgets/charts/types/BarChart';
import PieChart from '../../../widgets/charts/types/PieChart';
import Box from '../../../common/Box';
import countriesByAbbreviation from '../../../common/CountriesByAbbreviation';
import ReactSelect from '../../../common/ReactSelect';
import { audienceActions } from '../../../../data/actions/audience'; 


class TerritoryDemographics extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            age: null,
            gender: null
        }
        
        this.setAge = this.setAge.bind(this);
        this.setGender = this.setGender.bind(this);
        this.reset = this.reset.bind(this);        
    };    
    
    setAge(age) {
        this.setState({
            age,
            gender: null
        })
    }
    
    setGender(gender) {
        this.setState({
            gender,
            age: null
        })
    }
    
    reset() {
        this.setState({
            age: null,
            gender: null
        })
    }

    
    componentDidMount() {
        const { entity, id, territory } = this.props;
        this.props.dispatch(audienceActions.getDemographicsTerritoryStats(id, entity, territory));
    }
    
    componentWillReceiveProps(nextProps){
        const globalFilter = nextProps.filter.global;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.props.dispatch(audienceActions.getDemographicsTerritoryStats(this.props.id, this.props.entity, this.props.territory))
            }                
        };
        
        if(nextProps.territory && !isEqual(nextProps.territory, this.props.territory)){
            this.props.dispatch(audienceActions.getDemographicsTerritoryStats(this.props.id, this.props.entity, nextProps.territory));
        }

    }
    
    renderAgeChart(demographics) {
        let dataset = [],
            colors = [];
        const ages = this.state.age ? [this.state.age] : Object.keys(demographics.ages);
        const gender = this.state.gender;
        let total = 0;
        for(let age of ages) {
            let recordsFilter = {age: age};
            if(gender)
                recordsFilter.gender = gender;
            
            const records = filter(demographics.data, recordsFilter);
            const listeners = sumBy(records, 'listeners');
            dataset.push(listeners);
            total += listeners;
            colors.push(demographics.ages[age]);
        }
        
        let barDataset = [],
            maxPercent = 0,
            maxIndex = 0,
            totalPercent = 0;
       
        for(let itemIndex in dataset) {
            const percent = Math.round(dataset[itemIndex] / total * 100);
            totalPercent += percent;
            
            if(percent > maxPercent) {
                maxPercent = percent;
                maxIndex = itemIndex;
            }
            
            barDataset[itemIndex] = {
                label: ages[itemIndex],
                data: [percent],
                backgroundColor: colors[itemIndex]
            };              
        }
        if(barDataset[maxIndex] && totalPercent !== 100)
            barDataset[maxIndex].data[0] = barDataset[maxIndex].data[0] - totalPercent + 100;
        
        //[{ data:dataset, backgroundColor: colors, label: "Listeners"}]
        const data = {labels: ['Listeners'], datasets: barDataset};
        return this.renderStackedChart(data); 
    }
    
    renderStackedChart(data) {
        const options = {
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    barThickness: 30,
                    stacked: true,
                    display: false
                }],
                yAxes: [{
                    barThickness: 30,
                    stacked: true,
                    display: false
                    
                }]
            },            
            legend: {
                display: false,
            },
            tooltips: {
                mode: 'nearest'
            }
        };
        return <BarChart data={data} options={options} horizontal={true} />
    }
    
    renderGenderChart(demographics) {
        let dataset = [],
            colors = [];
        
        const genders = this.state.gender ? [this.state.gender] : Object.keys(demographics.genders);
        const age = this.state.age;
        let total = 0;
        for(let gender of genders) {
            let recordsFilter = {gender: gender};
            if(age)
                recordsFilter.age = age;
            
            const records = filter(demographics.data, recordsFilter);
            const listeners = sumBy(records, 'listeners');
            dataset.push(listeners);
            total += listeners;
            colors.push(demographics.genders[gender]);
        }
        
        let barDataset = [],
            maxPercent = 0,
            maxIndex = 0,
            totalPercent = 0;

        for(let itemIndex in dataset) {
            const percent = Math.round(dataset[itemIndex] / total * 100);
            totalPercent += percent;
            
            if(percent > maxPercent) {
                maxPercent = percent;
                maxIndex = itemIndex;
            }

            barDataset[itemIndex] = {
                label: genders[itemIndex],
                data: [percent],
                backgroundColor: colors[itemIndex]
            };              
        }
        barDataset[maxIndex].data[0] = barDataset[maxIndex].data[0] - totalPercent + 100;        
        
        const data = {labels: ['Listeners'], datasets: barDataset};
        return this.renderStackedChart(data);
    }
    
    renderLegend(demographics) {
        const genders = map(demographics.genders, (color, gender) => <li key={gender}>
            <span onClick={()=>this.setGender(gender)}><i className="fas fa-circle" style={{color}} /> {gender}</span> 
        </li>)
        const ages = map(demographics.ages, (color, age) => <li key={age}>
            <span onClick={()=>this.setAge(age)}><i className="fas fa-circle" style={{color}} /> {age}</span> 
        </li>)
        
        return <div className="age-gender-legend">
            <h5>Ages</h5>
            <ul>{ages}</ul>
            <h5>Genders</h5>
            <ul>{genders}</ul>
            <ul><li><span onClick={this.reset}> Reset Default</span></li></ul>
        </div>
        
    }
    
    renderTerritories() {
        const { territory, setTerritory } = this.props;
        let selectedTerritory = {},
            territories = [];
        for (let country of countriesByAbbreviation) {
            if(country.abbreviation===null)
                continue;
            
            let item = {
                label: country.country,
                value: country.abbreviation
            };
            
            territories.push(item);
            
            if(country.abbreviation == territory)
                selectedTerritory = item;
        }
        
        return <div className="demography-select"><ReactSelect value={selectedTerritory} options={territories} onChange={(territory)=>setTerritory(territory.value)} className="single-select"/></div>
        
    }

    render (){
        const { audience, territory } = this.props;
        const demographics = audience.demographicsTerritory[territory];
        const noRecords = demographics && demographics.data && demographics.data.length == 0;
        const records = demographics && demographics.data && demographics.data.length > 0;
        return <div className="default"><Box title={''} spinnerEnabled={audience.demographicsTerritoryLoading}>
            {this.renderTerritories()}
            {noRecords && <p>No demographics data available for this country</p>}
            {records && <div className="row">
                <div className="col-xs-12 col-sm-10">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="chart-block chart-block-gender">
                                {this.renderAgeChart(demographics)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="chart-block chart-block-gender">
                                {this.renderGenderChart(demographics)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-2">
                    {this.renderLegend(demographics)}
                </div>
            </div>}
        </Box></div>
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(TerritoryDemographics);