import { concat } from 'lodash';

import { usersService } from '../services/users';
import { usersConstants } from '../constants/users';
import { usersFormatter } from '../formatters/users';
import { errorActions } from './error';
import { campaignsService } from '../services/campaigns';

export const usersActions = {
  getUsers,
  getUser,
  addUser,
  deleteUser,
  getImprints,
  getArtists,
  getProducts,
  getCampaigns
}

function getUsers(onlySaved = false){
  return ( dispatch, getState ) => {
    dispatch(request());
    usersService.getUsers()
    .then( users => {
      users = usersFormatter.formatUsers(users, onlySaved);        
      return dispatch(success(users));
    })
    .catch( error => {
      return dispatch(failure(error));
    });
  };

  function request(){ return { type: usersConstants.GET_USERS_REQUEST }}
  function success( users ){ return { type: usersConstants.GET_USERS_SUCCESS, users }}
  function failure( error ){ return { type: usersConstants.GET_USERS_FAILURE, error }}
}

function getUser(id, overwrite = true){
    return ( dispatch, getState ) => {
      dispatch(request());
      return usersService.getUser(id)
      .then( user => {
        user = usersFormatter.formatUser(user);        
        dispatch(success(user, overwrite));
        return user;
      })
      .catch( error => {
        return dispatch(failure(error));
      });
    };

    function request(){ return { type: usersConstants.GET_USER_REQUEST }}
    function success( user, overwrite ){ return { type: usersConstants.GET_USER_SUCCESS, user, overwrite }}
    function failure( error ){ return { type: usersConstants.GET_USER_FAILURE, error }}
  }


function addUser(params, defaultImprints) {
    return ( dispatch, getState ) => {
        dispatch(request());
        params = usersFormatter.formatNewUser(params, defaultImprints);
//        console.log(params);
//        return;
        usersService.addUser(params)
            .then(
                user => {
                    dispatch(success());
                    //dispatch(usersActions.getUsers())
                    dispatch(errorActions.setSuccessMessage('user saved'))
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving user'))
                }
            );
 
    };
    function request() { return { type: usersConstants.ADD_USER_REQUEST } }
    function success() { return { type: usersConstants.ADD_USER_SUCCESS } }
    function failure() { return { type: usersConstants.ADD_USER_FAILURE } }

}

function deleteUser(email) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const params = usersFormatter.formatDeleteUser(email);
        return usersService.deleteUser(params)
            .then(
                user => {
                    dispatch(success());
                    return dispatch(usersActions.getUsers())
                    dispatch(errorActions.setSuccessMessage('user permissions revoked'))
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving user'))
                }
            );
 
    };
    function request() { return { type: usersConstants.ADD_USER_REQUEST } }
    function success() { return { type: usersConstants.ADD_USER_SUCCESS } }
    function failure() { return { type: usersConstants.ADD_USER_FAILURE } }

}

function getImprints(){
    return ( dispatch, getState ) => {
        const currentUser = getState().user.user;        
      dispatch(request());
      usersService.getImprints()
      .then( imprints => {
        imprints = usersFormatter.formatImprints(imprints, currentUser);        
        return dispatch(success(imprints));
      })
      .catch( error => {
        return dispatch(failure(error));
      });
    };

    function request(){ return { type: usersConstants.GET_IMPRINTS_REQUEST }}
    function success( imprints ){ return { type: usersConstants.GET_IMPRINTS_SUCCESS, imprints }}
    function failure( error ){ return { type: usersConstants.GET_IMPRINTS_FAILURE, error }}
  }

function getArtists(ids){
    return ( dispatch, getState ) => {
      dispatch(request());
      
      if(!ids.length)
          return dispatch(success([]));
      
      usersService.getArtists(ids)
      .then( artists => {
        artists = usersFormatter.formatArtists(artists);        
        return dispatch(success(artists));
      })
      .catch( error => {
        return dispatch(failure(error));
      });
    };

    function request(){ return { type: usersConstants.GET_ARTISTS_REQUEST }}
    function success( artists ){ return { type: usersConstants.GET_ARTISTS_SUCCESS, artists }}
    function failure( error ){ return { type: usersConstants.GET_ARTISTS_FAILURE, error }}
  }

function getProducts(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let productCalls = [];
        for(let imprintID of Object.keys(ids)) {
            if(ids[imprintID].length > 0 && ids[imprintID][0]!=='*') {
                productCalls.push(getProductsByImprint(ids[imprintID], imprintID))
            }
        }
        Promise.all(productCalls).then(products=>{
            let result = [];
            
            for (let product of products) {
                result = concat(result, product);
            }
            
            return dispatch(success(result));        
        });

        
    }
    function request(){ return { type: usersConstants.GET_PRODUCTS_REQUEST }}
    function success( products ){ return { type: usersConstants.GET_PRODUCTS_SUCCESS, products }}
    function failure( error ){ return { type: usersConstants.GET_PRODUCTS_FAILURE, error }}
}

function getProductsByImprint(ids, imprintID){
    return usersService.getProducts(ids, imprintID)
      .then( products => {
        return usersFormatter.formatProducts(products, imprintID);        
      });
  }

function getCampaigns(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return campaignsService.getCampaigns(params, globalFilter)
            .then(
                campaigns => {
                    const data = usersFormatter.formatCampaigns(campaigns);
                    dispatch(success(data, campaigns.pages));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: usersConstants.GET_CAMPAIGNS_REQUEST } }
    function success(campaigns) { return { type: usersConstants.GET_CAMPAIGNS_SUCCESS, campaigns} }
    function failure(error) { return { type: usersConstants.GET_CAMPAIGNS_FAILURE, error } }
}
