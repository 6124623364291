import { productsConstants } from '../constants/products';
import { productsService } from '../services/products';
import { productsFormatter } from '../formatters/products';
import { find, chunk, map, flatten } from 'lodash';

export const productsActions = {
    getProducts,
    getTopProducts,
    getProductsAutocomplete,
    getProductDetails,
    getCard,
    getStreamsStats,
    getProductsByBarcode,
    getTracks,
    getMetadata,
    getTimeseriesProducts,
    forceLoadAllData
};

function getProducts(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        productsService.getProducts(params, globalFilter)
            .then(
                products => {
                    const data = productsFormatter.formatForTable(products);
                    //dispatch(success(data, products.pages));
                    return dispatch(getMetadata(data));                    
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_PRODUCTS_REQUEST } }
    function success(products, pages) { return { type: productsConstants.GET_PRODUCTS_SUCCESS, products, pages } }
    function failure(error) { return { type: productsConstants.GET_PRODUCTS_FAILURE, error } }
}

function getProductsByBarcode(barcodes){
  return dispatch => {
    dispatch(request());

    productsService.getProductsByBarcode(barcodes)
    .then(response => {
      const dataIsEmpty = response.data && response.data.length === 0;
      let result = dataIsEmpty ? [] : response.products;
        dispatch(success(result));
    })
    .catch( error => {
      dispatch(failure(error.message));
    })
  }

  function request() { return { type: productsConstants.GET_PRODUCTS_BY_BARCODES_REQUEST } }
  function success(products) { return { type: productsConstants.GET_PRODUCTS_BY_BARCODES_SUCCESS, products } }
  function failure(error) { return { type: productsConstants.GET_PRODUCTS_BY_BARCODES_FAILURE, error } }
}


function getProductsAutocomplete(search, limit=10) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        productsService.getProductsAutocomplete(search)
            .then(
                products => {
                    const data = productsFormatter.formatAutocomplete(products.results, ['products']);
                    dispatch(success(data.slice(0, limit)));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_PRODUCTS_AUTOCOMPLETE_REQUEST } }
    function success(products) { return { type: productsConstants.GET_PRODUCTS_AUTOCOMPLETE_SUCCESS, products} }
    function failure(error) { return { type: productsConstants.GET_PRODUCTS_AUTOCOMPLETE_FAILURE, error } }
}

function getTopProducts(params, useCache, addTotal = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return productsService.getTopProducts(params, globalFilter)
            .then(
                products => {
                    const data = productsFormatter.formatTop(products.result);
                    //dispatch(success(data));
                    return dispatch(getTopMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_TOP_PRODUCTS_REQUEST } }
    function success(products) { return { type: productsConstants.GET_TOP_PRODUCTS_SUCCESS, products } }
    function failure(error) { return { type: productsConstants.GET_TOP_PRODUCTS_FAILURE, error } }
}


function getProductDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const products = getState().products.top,
            product = find(products, {product_id: id});
        return Promise.resolve(product)
            .then(
                product => {
                    const data = productsFormatter.formatDetails(product.stms_by_date);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_PRODUCT_DETAILS_REQUEST } }
    function success(id, product) { return { type: productsConstants.GET_PRODUCT_DETAILS_SUCCESS, id, product } }
    function failure(error) { return { type: productsConstants.GET_ARTIST_PRODUCT_FAILURE, error } }
}

function getCard(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const altID = id.startsWith('0') ? id.substr(1) : '0' + id;
        //return Promise.all([productsService.getCard(id), productsService.getCard(altID)])
        return productsService.getCard(id)
            .then(
                (cards) => {
                	const card = productsFormatter.formatCard([cards, null]);
                    dispatch(success(id, card));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_PRODUCTS_CARD_REQUEST } }
    function success(id, card) { return { type: productsConstants.GET_PRODUCTS_CARD_SUCCESS, id, card } }
    function failure(error) { return { type: productsConstants.GET_PRODUCTS_CARD_FAILURE, error } }
}

function getStreamsStats(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        productsService.getStreamsStats(ids, globalFilter)
            .then(
                products => {
                    const metadata = getState().products.metadata;
                    products = productsFormatter.formatStreamsStats(products, metadata);
                    dispatch(success(products));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_STREAMS_STATS_REQUEST } }
    function success(products) { return { type: productsConstants.GET_STREAMS_STATS_SUCCESS, products } }
    function failure(error) { return { type: productsConstants.GET_STREAMS_STATS_FAILURE, error } }
}

function getTracks(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        ids = ids.map(id=>Number(id));
        productsService.getTracks(ids)
            .then(
                tracks => {
                    const data = productsFormatter.formatTracks(tracks.data);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_TRACKS_REQUEST } }
    function success(tracks) { return { type: productsConstants.GET_TRACKS_SUCCESS, tracks} }
    function failure(error) { return { type: productsConstants.GET_TRACKS_FAILURE, error } }
}

function getTopMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const chunkSize = 50,
	    	ids = chunk(entities.map(entity=>entity.product_id), chunkSize);
	
	    return Promise.all(ids.map(chunk => productsService.getMetadata(chunk)))
	    //productsService.getMetadata(ids)
	        .then(
	            metadata => {
                    let total = entities.length > 0 ? find(entities, entity=>entity.product_id == 0) : {},
                            rows = entities.filter(entity=>entity.product_id != 0);

	            	const allMetadata = flatten(map(metadata, meta => meta.data));
                    rows = productsFormatter.formatMetadata(rows, allMetadata, total);
                    dispatch(success(rows, total, allMetadata));
                    return rows;

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_TOP_PRODUCTS_REQUEST } }
    function success(products, total, metadata) { return { type: productsConstants.GET_TOP_PRODUCTS_SUCCESS, products, total, metadata } }
    function failure(error) { return { type: productsConstants.GET_TOP_PRODUCTS_FAILURE, error } }

}

function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.product_id);
        productsService.getMetadata(ids)
            .then(
                metadata => {
                    const data = productsFormatter.formatMetadata(entities.data, metadata.data);
                    dispatch(success(data, entities.total, metadata.data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_PRODUCTS_REQUEST } }
    function success(products, total, metadata) { return { type: productsConstants.GET_PRODUCTS_SUCCESS, products, total, metadata } }
    function failure(error) { return { type: productsConstants.GET_PRODUCTS_FAILURE, error } }

}


function getTimeseriesProducts(params, useCache) {
    return ( dispatch, getState ) => {
        if(getState().products.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;
        return productsService.getTimeseriesProducts(params, globalFilter)
            .then(
                products => {
                    const metadata = getState().products.metadata;
                    const data = productsFormatter.formatTimeseries(products.result, metadata);
                    
                    dispatch(success(data));
                    return products;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_TIMESERIES_PRODUCTS_REQUEST } }
    function success(products) { return { type: productsConstants.GET_TIMESERIES_PRODUCTS_SUCCESS, products } }
    function failure(error) { return { type: productsConstants.GET_TIMESERIES_PRODUCTS_FAILURE, error } }
}

function forceLoadAllData(force) {
    return dispatch => {
        dispatch(set(force));
    };

    function set(force) { return { type: productsConstants.FORCE_LOAD_ALL_DATA, force} }
}
