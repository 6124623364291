import { heartbeatsConstants } from '../constants/heartbeats';

const initialState = {
  data: [],
  heartbeatsSuccess: false,
  heartbeatsFailure: false,
  heartbeatsInProgress: false
}

export function heartbeats(state = initialState, action = {}){
  switch (action.type) {
    case heartbeatsConstants.GET_HEARTBEATS_REQUEST:
      return { ...state, heartbeatsInProgress: true };
    case heartbeatsConstants.GET_HEARTBEATS_SUCCESS:
      return { ...state, data: action.payload, heartbeatsSuccess: true, heartbeatsFailure: false, heartbeatsInProgress: false };
    case heartbeatsConstants.GET_HEARTBEATS_ERROR:
      return { ...state, heartbeatsSuccess: false, heartbeatsFailure: true, heartbeatsInProgress: false };
      
    case heartbeatsConstants.GET_PAGED_HEARTBEATS_REQUEST:
        return { ...state, loading: true };
      case heartbeatsConstants.GET_PAGED_HEARTBEATS_SUCCESS:
        const {data, pagination} = action.payload;
        return { ...state, data, pagination, heartbeatsSuccess: true, heartbeatsFailure: false, loading: false };
      case heartbeatsConstants.GET_PAGED_HEARTBEATS_ERROR:
        return { ...state, heartbeatsSuccess: false, heartbeatsFailure: true, loading: false };
      default:
          return state;
  }
}
