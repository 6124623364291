import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { userActions } from '../../data/actions/user';
import { smoothlyMenu } from '../layouts/Helpers';
import FilterButton from '../widgets/filter/FilterButton';
import Filter from '../widgets/filter/Filter';
import profile from '../../../public/img/default-user.png';
import NotificationCount from '../widgets/notifications/NotificationCount'
import NotificationMenu from '../widgets/notifications/NotificationMenu'
import WithPermission from '../../helpers/WithPermission';
import SearchWidget from '../pages/home/SearchWidget'
import { withRouter } from 'react-router';

import moment from 'moment';
import { notificationsActions } from '../../data/actions/notifications';
const DATE_FORMAT = 'YYYY-MM-DD';

var logoutSvg = require('!svg-inline-loader!../../../public/img/logout.svg');
var adminSvg = require('!svg-inline-loader!../../../public/img/manage.svg');

class TopHeader extends React.Component {

    constructor(props){
        super(props);
        this.logout = this.logout.bind(this);
    }

    state = {
        todayDate: moment(new Date()).format(DATE_FORMAT)
    }

    componentDidMount() {
      this.props.getCountOfNotifications(this.state.todayDate);
    }

    componentDidUpdate(prevProps){
      if(this.props.notifications.count !== prevProps.notifications.count){
        this.props.getCountOfNotifications(this.state.todayDate);
      }
    }

    logout(e){
        e.preventDefault();
        return this.props.logout();
    }

    toggleNavigation(e) {
        e.preventDefault();
        $("body").toggleClass("mini-navbar");
        smoothlyMenu();
        if($("body").hasClass('mini-navbar')) {
          setTimeout(function(e) {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
          }, 300);
        }
    }

    onUpdatePlaylistNotification = () => {
      const { getPlaylistNotifications, updatePlaylistNotifications } = this.props;
      const { playlist } = this.props.notifications;

      updatePlaylistNotifications(playlist.id, getPlaylistNotifications, this.state.todayDate);
    }

    onUpdateReleaseNotifications = () => {
      const { updateReleaseNotifications, history } = this.props;
      const { releases } = this.props.notifications;

      updateReleaseNotifications(releases.id, this.state.todayDate);

      history.push('/reporting/new-releases');
    }

    onUpdateHeartbeatsNotifications = () => {
      const { updateHeartbeatsNotifications, history } = this.props;
      const { heartbeats } = this.props.notifications;

      updateHeartbeatsNotifications(heartbeats.id, this.state.todayDate);

      history.push('/reporting/heartbeats');
    }
    
    render() {
        const { user, readonly = false } = this.props,
            userName = user.loggedIn ? `${user.user.first_name} ${user.user.last_name}` : 'Anonymous User',
            userAvatar = user.loggedIn ? (user.user.avatar_url ? user.user.avatar_url : profile) : '';
            
        return (
            <div className="header-container">
                <nav className="header-navbar" role="navigation">
                    {!readonly && 
                      <div className="header-left-holder">
                        <Link to="/"><div className="main-logo"></div></Link>
                      </div>
                    }
                    {this.props.children}
                    {!readonly && <WithPermission permission="allow_admin">
                        <Link className="admin-link" to="/import/statement-periods"> 
                            <span className="small-icon" dangerouslySetInnerHTML={{__html: adminSvg}}></span>
                            Admin
                        </Link>
                    </WithPermission>}
                    {!readonly && <a className="logout-link" onClick={this.logout}> 
                        <span className="small-icon" dangerouslySetInnerHTML={{__html: logoutSvg}}></span>
                        Logout
                    </a>}
                    
                </nav>
                <div className="main-filter-container">
                  <Filter readonly={readonly} />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notifications: state.notifications,
        catalogueExceptions: state.catalogueExceptions,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(userActions.logout()),
        getCountOfNotifications: date => dispatch( notificationsActions.getCountOfNotifications(date)),
        updatePlaylistNotifications: (id, cb, date) => dispatch( notificationsActions.updateStorePlaylistNotifications(id, cb, date)),
        updateReleaseNotifications: (id, date) => dispatch( notificationsActions.updateReleaseNotifications(id, date)),
        updateHeartbeatsNotifications: (id, date) => dispatch( notificationsActions.updateHeartbeatsNotifications(id, date))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopHeader));
