import React from 'react';
import { connect } from 'react-redux';
import { filter, find } from 'lodash';
import store from '../../../helpers/store';
import ReactSelect from '../../common/ReactSelect';
import Box from '../../common/Box';
import EntityCard from '../../common/EntityCard';

class FavoriteEntities extends React.Component {
  types = [
    {
      label: "All",
      value: null,
    },
    {
      label: "Favorite",
      value: "favorite",
    },
    {
      label: "Hidden",
      value: "hidden",
    },
  ];

  entities = [
    {
      label: "All",
      value: null,
    },
    {
      label: "Imprint",
      value: "imprints",
    },
    {
      label: "Artist",
      value: "artists",
    },
    {
      label: "Product",
      value: "products",
    },
    {
        label: "Track",
        value: "tracks",
    },    
  ];

  constructor(props) {
    super(props);
    this.state = {
      type: null,
      entity: null,
    };

    this.setType = this.setType.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
  }

  setType(type) {
    this.setState({ type: type.value });
  }
  
  setEntity(entity) {
    this.setState({ entity: entity.value });
  }

  renderItems(items) {
    let widgets = [];
    let self = this;

    for (let entity of items) {
        const { entity_id, entity_type } = entity; 
        if (!self.state.entity || entity_type === self.state.entity) {
            widgets.push(
                <EntityCard
                key={entity_id}
                id={entity_id}
                entity={entity_type}
                showLink={false}
                showFavoriteHidden={true}
                />
            );
      }
    }
    return widgets;
  }

  renderToolbar(modes) {
    let toolbar = [];

    let selectedType = find(
      this.types,
      (type) => type.value == this.state.type
    );

    toolbar.push(
      <div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <ReactSelect
            value={selectedType}
            options={this.types}
            onChange={this.setType}
            className="single-select"
          />
        </div>
      </div>
    );
    return toolbar;
  }

  render() {
      const { globalFilter = [] } = this.props.globalFilter,
        hidden = globalFilter.filter(item=>item.fav_hide == 'hidden'),
        favorite = globalFilter.filter(item=>item.fav_hide == 'favorite');
      
    const { entity, type } = this.state;

    return (
      <Box title="Favorite/Hidden Items" className="favotite-hidden-box" toolbar={this.renderToolbar()}>
        <div className="favorites-tabs-header">
          {this.entities.map((e) => (
            <span
              onClick={() => this.setEntity(e)}
              class={`favorites-tab-item ${entity === e.value ? "active" : ""}`}
            >
              {e.label}
            </span>
          ))}
        </div>
        <div className="favorite-hidden-list">
          {(!type || type == "favorite") && this.renderItems(favorite)}
        </div>
        <div className="favorite-hidden-list">
          {(!type || type == "hidden") && this.renderItems(hidden)}
        </div>
      </Box>
    );
  }
}

function mapStateToProps(state) {
    return {
        globalFilter: state.globalFilter
    } 
}

export default connect(mapStateToProps)(FavoriteEntities);