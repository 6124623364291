import React from "react";

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = { displayTooltip: false };
  }

  hideTooltip = () => {
    this.setState({ displayTooltip: false });
  };

  showTooltip = () => {
    this.setState({ displayTooltip: true });
  };

  render() {
    const { position, message, tooltipClass, customIcon } = this.props;

    return (
      <div className={tooltipClass}>
        {customIcon ? <div className="custom-icon-tooltip" onMouseLeave={this.hideTooltip} onMouseOver={this.showTooltip}>{...this.props.children}</div> : (
          <span className="notificationReportInfoIcon" onMouseLeave={this.hideTooltip} onMouseOver={this.showTooltip}> 
            <i className="fas fa-question-circle" /> 
          </span>
        )}

        { this.state.displayTooltip &&
          <div className={`tooltip-bubble tooltip-${position}`}>
            <div className='tooltip-message'>
              {message}
            </div>
          </div>
        }
      </div>
    )
  }
}

export default Tooltip;