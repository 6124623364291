export const projectsConstants = {
    GET_PROJECTS_REQUEST: 'GET_PROJECTS_REQUEST',
    GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
    GET_PROJECTS_FAILURE: 'GET_PROJECTS_FAILURE',
    GET_PROJECTS_AUTOCOMPLETE_REQUEST: 'GET_PROJECTS_AUTOCOMPLETE_REQUEST',
    GET_PROJECTS_AUTOCOMPLETE_SUCCESS: 'GET_PROJECTS_AUTOCOMPLETE_SUCCESS',
    GET_PROJECTS_AUTOCOMPLETE_FAILURE: 'GET_PROJECTS_AUTOCOMPLETE_FAILURE',
    GET_TOP_PROJECTS_REQUEST: 'GET_TOP_PROJECTS_REQUEST',
    GET_TOP_PROJECTS_SUCCESS: 'GET_TOP_PROJECTS_SUCCESS',
    GET_TOP_PROJECTS_FAILURE: 'GET_TOP_PROJECTS_FAILURE',
    GET_PRODUCT_DETAILS_REQUEST: 'GET_PRODUCT_DETAILS_REQUEST',
    GET_PRODUCT_DETAILS_SUCCESS: 'GET_PRODUCT_DETAILS_SUCCESS',
    GET_PRODUCT_DETAILS_FAILURE: 'GET_PRODUCT_DETAILS_FAILURE',
    GET_PROJECTS_CARD_REQUEST: 'GET_PROJECTS_CARD_REQUEST',
    GET_PROJECTS_CARD_SUCCESS: 'GET_PROJECTS_CARD_SUCCESS',
    GET_PROJECTS_CARD_FAILURE: 'GET_PROJECTS_CARD_FAILURE',
    GET_STREAMS_STATS_REQUEST: 'GET_PROJECTS_STREAMS_STATS_REQUEST',
    GET_STREAMS_STATS_SUCCESS: 'GET_PROJECTS_STREAMS_STATS_SUCCESS',
    GET_STREAMS_STATS_FAILURE: 'GET_STREAMS_STATS_FAILURE',
    GET_PROJECTS_BY_BARCODES_REQUEST: 'GET_PROJECTS_BY_BARCODES_REQUEST',
    GET_PROJECTS_BY_BARCODES_SUCCESS: 'GET_PROJECTS_BY_BARCODES_SUCCESS',
    GET_PROJECTS_BY_BARCODES_FAILURE: 'GET_PROJECTS_BY_BARCODES_FAILURE',
    GET_TRACKS_REQUEST: 'GET_TRACKS_REQUEST',
    GET_TRACKS_SUCCESS: 'GET_TRACKS_SUCCESS',
    GET_TRACKS_FAILURE: 'GET_TRACKS_FAILURE',
    GET_TIMESERIES_PROJECTS_REQUEST: 'GET_TIMESERIES_PROJECTS_REQUEST',
    GET_TIMESERIES_PROJECTS_SUCCESS: 'GET_TIMESERIES_PROJECTS_SUCCESS',
    GET_TIMESERIES_PROJECTS_FAILURE: 'GET_TIMESERIES_PROJECTS_FAILURE', 
    FORCE_LOAD_ALL_DATA: 'FORCE_LOAD_ALL_DATA_PROJECTS',
}
