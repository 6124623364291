import React from 'react';
import ReactModal from 'react-modal';

const shareSvg = require('!svg-inline-loader!../../../public/img/cross.svg');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
    },
    overlay: {
        backgroundColor: 'rgba(112, 112, 112, 0.75)'
    }
};

class Modal extends React.Component {
    
    constructor(props){
        super(props);
    }

    // componentWillMount() {
    //     Modal.setAppElement('body');
    // }
    
    
    render() {

        const { show, title, submitTitle = 'OK', handleSubmit, handleClose, children, showActionButtons=true, dialogClassName, onRequestClose, size, comment, modalContainer, closeTitle = 'Cancel' } = this.props;

        const rootElement = document.getElementById('root');
        const parentElement = modalContainer ? modalContainer : rootElement;
        return <React.Fragment>
        <div>
            <ReactModal 
                isOpen={show}
                contentLabel={title}
                style={customStyles}
                portalClassName={dialogClassName}
                onRequestClose={onRequestClose}
                appElement={rootElement}
                parentSelector={()=>parentElement}
            >
                <div className="modal-title-container">
                    <h2 className="modal-title">{title}</h2>
                    <span className="close-modal-btn" onClick={handleClose} dangerouslySetInnerHTML={{__html: shareSvg}}></span>
                </div>
                <div className="modal-content">
                    {children}
                </div>
                {showActionButtons && <div className="modal-footer">
                    <button type="button" className="default-btn default-btn--small default-btn--transparent footer-modal-close" onClick={handleClose}>{closeTitle}</button>
                    {comment && <span className="footer-modal-comment">{comment}</span>}
                    <button type="button" className="default-btn default-btn--small footer-modal-submit" onClick={handleSubmit}>{submitTitle}</button>
                </div>}
            </ReactModal>
        </div>
        </React.Fragment>
    }
}

export default Modal