import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { find } from 'lodash';
import WithPermission from '../../helpers/WithPermission';

class Navigation extends Component {
    componentDidMount() {
        const { menu } = this.refs;
        $(menu).metisMenu();
    }

    activeRoute(routeName) {
        return this.props.location.pathname.startsWith(routeName) ? "active" : "";
    }

    secondLevelActive(routeRegex, element) {
        if(typeof routeRegex == 'string')
            routeRegex = new RegExp(routeRegex);
        return this.props.location.pathname.match(routeRegex) ? (element == 'li' ? 'active' : "nav nav-second-level collapse in") : (element == 'li' ? '' : "nav nav-second-level collapse")
    }

    getCounter(entity) {
        let value = null;
        if(this.props.stats.catalog) {
            entity = find(this.props.stats.catalog, {entity});
            if(entity)
                value = entity.absoluteValue;
        }

        return value !== null ? <span className="label label-info float-right"> {value}</span> : null;
    }

    render() {
        return (
            <nav className="navbar-default navbar-static-side" role="navigation">
                    <ul className="nav metismenu" id="side-menu" ref="menu">

                        <li className={this.activeRoute("/main")}>
                            <Link to="/main"><i className="fa fa-home"></i> <span className="nav-label">Overview</span></Link>
                        </li>
                        <li className={this.secondLevelActive("/catalog", 'li')}>
                            <a href=""><i className="fa fa-folder-open"></i> <span className="nav-label">Catalogue</span></a>
                            <ul className={this.secondLevelActive("/catalog", 'ul')}>
                                <li className={this.activeRoute("/catalog/imprints")}>
                                    <Link to="/catalog/imprints"><i className="fa fa-user"></i> <span className="nav-label">Imprints </span>{this.getCounter('imprints')}</Link>
                                </li>
                                <li className={this.activeRoute("/catalog/artists")}>
                                    <Link to="/catalog/artists"><i className="fa fa-users"></i> <span className="nav-label">Artists </span>{this.getCounter('artists')}</Link>
                                </li>
                                <li className={this.activeRoute("/catalog/products")}>
                                    <Link to="/catalog/products"><i className="fa fa-trophy"></i> <span className="nav-label">Products </span>{this.getCounter('products')}</Link>
                                </li>
                                <li className={this.activeRoute("/catalog/tracks")}>
                                    <Link to="/catalog/tracks"><i className="fa fa-music"></i> <span className="nav-label">Tracks </span>{this.getCounter('tracks')}</Link>
                                </li>

                            </ul>
                        </li>
                        <li className={this.secondLevelActive(/playlists|campaigns|audience|territory-analysis/, 'li')}>
                            <a href=""><i className="fa fa-chart-line"></i> <span className="nav-label">Analytics</span></a>
                            <ul className={this.secondLevelActive(/playlists|campaigns|audience|territory-analysis/, 'ul')}>
                                <li className={this.activeRoute("/playlists")}>
                                    <Link to="/playlists"><i className="fa fa-list"></i> <span className="nav-label">Playlist Library</span></Link>
                                </li>
                                {/*
                                <li className={this.activeRoute("/campaigns", "li")}>
                                    <Link to="/campaigns"><i className="fa fa-trophy"></i> <span className="nav-label">Campaigns</span></Link>
                                </li>
                                */}
                                <li className={this.activeRoute("/audience", "li")}>
                                    <Link to="/audience"><i className="fa fa-users"></i> <span className="nav-label">Audience Analysis</span></Link>
                                </li>                                
                                <WithPermission permission="allow_territories">
                                  <li className={this.activeRoute("/territory-analysis", "li")}>
                                      <Link to="/territory-analysis"><i className="fas fa-globe-americas"></i> <span className="nav-label">Territories</span></Link>
                                  </li>
                                </WithPermission>
                            </ul>
                        </li>

                        <WithPermission permission={["allow_heartbeats", "allow_releases"]}>
                            <li className={this.secondLevelActive(/reporting|track-comparison/, 'li')}>
                              <a href=""><i className="fa fa-table"></i> <span className="nav-label">Reporting</span></a>
                              <ul className={this.secondLevelActive(/reporting|track-comparison/, 'ul')}>
                                <WithPermission permission="allow_heartbeats">
                                    <li className={this.activeRoute("/reporting/heartbeats", 'li')}>
                                      <Link to="/reporting/heartbeats"><i className="fas fa-heartbeat"></i> <span className="nav-label">Heartbeats</span></Link>
                                    </li>
                                </WithPermission>
                                <WithPermission permission="allow_releases">
                                    <li className={this.activeRoute("/reporting/new-releases", 'li')}>
                                      <Link to="/reporting/new-releases"><i className="fas fa-id-card"></i> <span className="nav-label">New Releases</span></Link>
                                    </li>
                                </WithPermission>
                                <li className={this.activeRoute("/track-comparison", 'li')}>
                                    <Link to="/track-comparison"><i className="fas fa-microphone"></i> <span className="nav-label">Track Comparison</span></Link>
                                </li>
                                
                                {/*<WithPermission permission="allow_admin">
                                  <li className={this.activeRoute("/admin/reporting/reports", 'li')}>
                                    <Link to="/admin/reporting/reports"><i className="fas fa-window-restore"></i> <span className="nav-label">Reports</span></Link>
                                  </li>
                                </WithPermission> */}
                              </ul>
                            </li>
                        </WithPermission>

                        <WithPermission permission={["allow_preferences", "allow_api"]}>
                            <li className={this.secondLevelActive("/user", 'li')}>
                              <a href=""><i className="fa fa-cog"></i> <span className="nav-label">User Preferences</span></a>
                              <ul className={this.secondLevelActive("/user", 'ul')}>
                                <li className={this.activeRoute("/user/profile", 'li')}>
                                    <Link to="/user/profile"><i className="fa fa-user"></i> <span className="nav-label">Profile</span></Link>
                                </li>
                                <li className={this.activeRoute("/user/favorites", 'li')}>
                                    <Link to="/user/favorites"><i className="fa fa-heart"></i> <span className="nav-label">Favorites</span></Link>
                                </li>
                                <li className={this.activeRoute("/user/notifications", 'li')}>
                                    <Link to="/user/notifications"><i className="fa fa-comments"></i> <span className="nav-label">Notifications</span></Link>
                                  </li>

                                <WithPermission permission="allow_api">
                                    <li className={this.activeRoute("/user/api", 'li')}>
                                        <Link to="/preferences/api"><i className="fa fa-comments"></i> <span className="nav-label">API</span></Link>
                                    </li>
                                </WithPermission>
                              </ul>
                            </li>
                        </WithPermission>
                        <WithPermission permission={["allow_admin", "can_grant_access"]}>
                            <li className={this.secondLevelActive("/admin", 'li')}>
                                <a href=""><i className="fa fa-cog"></i> <span className="nav-label">Admin</span></a>
                                <ul className={this.secondLevelActive("/admin", 'ul')}>
                                    <WithPermission permission="allow_admin">
                                        <li className={this.activeRoute("/admin/statistics")}>
                                            <Link to="/admin/statistics"><i className="fa fa-user"></i> <span className="nav-label">Statistics</span></Link>
                                        </li>
                                    </WithPermission>
                                    <WithPermission permission="allow_admin">                                    
                                        <li className={this.activeRoute("/admin/white-label")}>
                                            <Link to="/admin/white-label"><i className="fa fa-palette"></i> <span className="nav-label">White Label</span></Link>
                                        </li>
                                    </WithPermission>
                                    <WithPermission permission="allow_admin">
                                        <li className={this.activeRoute("/admin/data")}>
                                            <Link to="/admin/data"><i className="fa fa-users"></i> <span className="nav-label">Data Admin</span></Link>
                                        </li>
                                    </WithPermission>
                                    <WithPermission permission="can_grant_access">
                                        <li className={this.activeRoute("/admin/users")}>
                                            <Link to="/admin/users"><i className="fa fa-users"></i> <span className="nav-label">User Management</span></Link>
                                        </li>
                                    </WithPermission>
                                </ul>
                            </li>
                        </WithPermission>
                    </ul>

            </nav>
        )
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats
    }
}


export default connect(mapStateToProps)(Navigation)
