import React from 'react';
import Table from '../../../common/PDF/Table';
import { splitByThousands, addPercent } from '../../../../helpers/NumberFormatter';
import { find, capitalize } from 'lodash';

const commonKeys = ['name', 'physical_income', 'physical_quantity', 'digital_income', 'digital_quantity', 'nr_income', 'nr_quantity', 'total_income', 'total_quantity'];
const keys = {
    'imprints': [...commonKeys, 'artists', 'projects', 'products', 'tracks'],
    'artists': [...commonKeys],
    'projects': [...commonKeys, 'products'],
    'products': [...commonKeys],
    'tracks': [...commonKeys.filter(key=>!key.startsWith('physical'))],
    
    
}

const titles = [
    { key: 'name', title: 'Imprint',  width: 100, fontSize: 5 },
    { key: 'physical_income', title: 'Physical Income',  width: 25, fontSize: 5, formatFn: splitByThousands },
    { key: 'physical_quantity', title: 'Physical Qty',  width: 20, fontSize: 5, formatFn: splitByThousands },

    { key: 'digital_income', title: 'Digital Income',  width: 25, fontSize: 5, formatFn: splitByThousands },
    { key: 'digital_quantity', title: 'Digital Qty',  width: 25, fontSize: 5, formatFn: splitByThousands },
    { key: 'nr_income', title: 'NR Income', width: 25, fontSize: 5, formatFn: splitByThousands },
    { key: 'nr_quantity', title: 'NR Qty',  width: 25, fontSize: 5, formatFn: splitByThousands },
    { key: 'total_income', title: 'Total Income',  width: 25, fontSize: 5, formatFn: splitByThousands },
    { key: 'total_quantity', title: 'Total Qty',  width: 25, fontSize: 5, formatFn: splitByThousands },

    { key: 'artists', title: 'Artists',  width: 20, fontSize: 5 },
    { key: 'projects', title: 'Projects',  width: 20, fontSize: 5 },
    { key: 'products', title: 'Products',  width: 20, fontSize: 5 },
    { key: 'tracks', title: 'Tracks',  width: 20, fontSize: 5 },
]


export default ({data, entity}) => {
    // const columns = titles.filter(row=>keys[entity].includes(row.key));
    const columns = keys[entity].map(key=>find(titles, title=>title.key == key));
    return <Table items={data} columns={columns} /> 
}