import React, { Component } from 'react';
import { connect } from 'react-redux';
import Box from '../../components/common/Box';
import { jsPDF } from "jspdf";
import {Helmet} from 'react-helmet';

import EntityCard from '../../components/common/EntityCard';
import Streams from '../../components/pages/audience/Streams';
import Demographics from '../../components/pages/audience/Demographics';
import EntityMilestones from '../../components/pages/audience/EntityMilestones';
import Milestones from '../../components/pages/audience/Milestones';
import Sources from '../../components/pages/audience/Sources';
import Territory from '../../components/pages/audience/Territory';
import TopPlaylists from '../../components/pages/audience/TopPlaylists';
import RepeatListeners from '../../components/pages/audience/RepeatListeners';
import CSV from '../../helpers/CSVExport';
import PDF from '../../helpers/PDFExport';

const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');
const pdfSvg = require('!svg-inline-loader!../../../public/img/pdf.svg');

class AudienceDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            entity: null,
        };
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
        this.setParentEntity = this.setParentEntity.bind(this);
        this.isDataReady = this.isDataReady.bind(this);
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({
            id: match.params.id,
            entity: match.params.entity
        });
    }
    
    setParentEntity(entity) {
        this.setState({
            title: entity.name
        })
    }
    
    componentWillReceiveProps(nextProps){
        
        const { match } = nextProps;
        if(match.params.id && match.params.id !== this.state.id) {
            this.setState({
                id: null
            }, ()=> {
                this.setState({
                    id: match.params.id,
                    entity: match.params.entity
                });
            })
        } 
    }
    
    exportStreamsListeners({labels, datasets}) {
        let result = [];
        for(let index in labels) {
            result.push({
                date: labels[index],
                total_streams: datasets[0].data[index],
                unique_listeners: datasets[1].data[index]
            })
        }
        return result;
    }
    
    isDataReady() {
        const { audience, milestones, match } = this.props;
        const entity = match.params.entity;
        
        const dataLoadingFlags = [
            audience.loading, 
            audience.demographicsLoading, 
            audience.sourceLoading, 
            audience.repeatListenersLoading, 
            audience.territoryLoading,
            (milestones.entities[entity] && milestones.entities[entity].loading)
        ];
        let ready = true;
        for(let flag of dataLoadingFlags) {
            if(flag) {
                ready = false;
                break;
            }
        }
        return ready;
    }

    exportToCsv() {
        const {
          audience,
          milestones,
          match
        } = this.props;
        
        const entity = match.params.entity;

        let data = [
          { streams_and_listeners: this.exportStreamsListeners(audience.timeline) },
          { audience_demographics: audience.demographics.datasets },
          { audience_repeat_listeners: audience.repeatListeners.table },
          { audience_discovery: audience.source.table },
          { audience_territories: audience.territory.table },
          { audience_playlists: audience.playlist },
          { milestones: milestones.entities[entity].tableItems },
        ];

        const filename = CSV.CSVHeader('audience_analysis', `${this.state.entity}-${this.state.id}`, this.props.filter.global);

        CSV.CSVBulkExport(data, filename);
      }
    
    exportToPdf() {
        let pdf = new jsPDF('p', 'mm', 'a4'),
            toWidth = (pdf.internal.pageSize.getWidth()) - 10,
            pageHeight = (pdf.internal.pageSize.getHeight()),
            streamsChartImage = document.getElementById("audience_streams_line_chart"),
            milestonesChartImage = document.getElementById("audience_milestones_line_chart"),
            demographicsChartImage = document.getElementById("audience_demographics_line_chart"),
            sourcesChartImage = document.getElementById("audience_sources_pie_chart"),
            repeatListenersChartImage = document.getElementById("audience_repeat_listeners_line_chart"),
            yOffset = 10,
            halfXOffset = toWidth / 2;
            
        
        

        pdf.setFontSize(16);
        pdf.text("Streams and Unique Listeners", 5, yOffset);
        yOffset+=5;
        
        const streamsResizedChart = PDF.resizeCanvasDimensions(streamsChartImage, toWidth); 
        pdf.addImage(streamsChartImage.toDataURL("image/jpeg,1.0"), 'JPEG', 5, yOffset, streamsResizedChart.width, streamsResizedChart.height, 'main');
        yOffset+=streamsResizedChart.height;
        
        let milestonesChartAdded = false;
        if(milestonesChartImage) {
            const milestonesResizedChart = PDF.resizeCanvasDimensions(milestonesChartImage, toWidth); 
            pdf.addImage(milestonesChartImage.toDataURL("image/jpeg,1.0"), 'JPEG', 5, yOffset, milestonesResizedChart.width, milestonesResizedChart.height, 'milestones');
            yOffset+=milestonesResizedChart.height;
            milestonesChartAdded = true;
        }
        
        yOffset+=16;
        pdf.text("Age/Gender Listeners", 5, yOffset);
        yOffset+=5;
        
        const demographicsResizedChart = PDF.resizeCanvasDimensions(demographicsChartImage, toWidth); 
        pdf.addImage(demographicsChartImage.toDataURL("image/jpeg,1.0"), 'JPEG', 5, yOffset, demographicsResizedChart.width, demographicsResizedChart.height, 'age');
        yOffset+=demographicsResizedChart.height;

        
        pdf.addPage();
        yOffset = 10;
    

        pdf.text("Territories", 5, yOffset);
        pdf.text("Sources of Discovery", halfXOffset + 5, yOffset);
        yOffset+=5;
        
        let territoriesData = this.props.audience.territory.table.slice(0, 10);
        pdf.autoTable({
            ...PDF.formatTable(territoriesData, 'territories'), 
            theme: 'plain', 
            startY: yOffset, 
            margin: { right: halfXOffset - 5 }, 
            styles: {fontSize: 8},

        });
        
        const territoriesTableHeight = pdf.lastAutoTable.finalY; 

//        const territoriesResizedChart = PDF.resizeCanvasDimensions(territoriesChartImage, (toWidth-10) / 2); 
//        pdf.addImage(territoriesChartImage.toDataURL("image/jpeg,1.0"), 'JPEG', 10, yOffset, territoriesResizedChart.width, territoriesResizedChart.height, 'territories');
//            yOffset+=territoriesResizedChart.height;

        const sourcesResizedChart = PDF.resizeCanvasDimensions(sourcesChartImage, toWidth / 2); 
        pdf.addImage(sourcesChartImage.toDataURL("image/jpeg,1.0"), 'JPEG', halfXOffset + 5, yOffset, sourcesResizedChart.width, sourcesResizedChart.height, 'sources');
        //
        yOffset+=Math.max(sourcesResizedChart.height, territoriesTableHeight);
        
        yOffset+=16;
        pdf.text("Repeat Listeners", 5, yOffset);
        yOffset+=5;
        
        const repeatListenersResizedChart = PDF.resizeCanvasDimensions(repeatListenersChartImage, toWidth); 
        pdf.addImage(repeatListenersChartImage.toDataURL("image/jpeg,1.0"), 'JPEG', 5, yOffset, repeatListenersResizedChart.width, repeatListenersResizedChart.height, 'repeat_listeners');
        yOffset+=demographicsResizedChart.height;
        
        
        const { milestones } = this.props,
            { entity } = this.state; 
        
        
        let milestonesData = milestones.entities[entity].tableItems;        
        if(milestonesData.length) {
            yOffset+=16;
            pdf.text("Milestones", 5, yOffset);
            yOffset+=5;
    
            pdf.autoTable({...PDF.formatTable(milestonesData, 'milestones'), theme: 'plain', startY: yOffset, styles: {fontSize: 8}});
        }

        pdf.save("audience.pdf");
        
        
    }
    
    render() {
        if(!this.state.id)
            return null;
        let metaTitle = this.state.title ? `${this.state.title} - ` : '';
        metaTitle += 'Audience Analysis';
        
        return <div className="wrapper wrapper-content audience-analysis">
            <div className="row">
                <div className="col-xs-12 pd-0 default">
                    <EntityCard entity={this.state.entity} id={this.state.id} onResult={this.setParentEntity}  />
                </div>
            </div>
            {this.isDataReady() && <div className="row download-page">
                <span>Export the data</span>
                <a key="download" title="Download entire page" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                <span>PDF</span>
                <a key="pdf" title="Download PDF" onClick={this.exportToPdf} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: pdfSvg}} />
            </div>}
            <div className="row">
                <div className="col-xs-12 pd-0 default">            
                    <Streams entity={this.state.entity} id={this.state.id}>
                        <EntityMilestones entity={this.state.entity} id={this.state.id} />
                    </Streams>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 pd-0 default">            
                    <Demographics entity={this.state.entity} id={this.state.id} />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 pd-0 default">            
                    <RepeatListeners entity={this.state.entity} id={this.state.id} />
                </div>
            </div>
            
            <div className="row">
                <div className="col-xs-12 pd-0 default">
                    <Territory entity={this.state.entity} id={this.state.id} expanded={true} />
                </div>
            </div>            

            <div className="row">
                <div className="col-xs-12 col-sm-6 pd-0 default">
                    <TopPlaylists entity={this.state.entity} id={this.state.id} />
                </div>
                <div className="col-xs-12 col-sm-6 pd-0 default">    
                    <Sources entity={this.state.entity} id={this.state.id} />
                </div>            
            </div>
                
            <div className="row">
                <div className="col-xs-12 pd-0 default">
                    <Milestones entity={this.state.entity} id={this.state.id} />
                </div>
            </div>            
            <Helmet>
                <title>{metaTitle}</title>
            </Helmet>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter,
        audience: state.audience,
        playlists: state.playlists,
        milestones: state.milestones
    }
}

export default connect(mapStateToProps)(AudienceDetails)