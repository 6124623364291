export const searchConstants = {
    GET_SEARCH_REQUEST: 'GET_SEARCH_REQUEST',
    GET_SEARCH_SUCCESS: 'GET_SEARCH_SUCCESS',
    GET_SEARCH_FAILURE: 'GET_SEARCH_FAILURE',
    GET_SEARCH_AUTOCOMPLETE_REQUEST: 'GET_SEARCH_AUTOCOMPLETE_REQUEST',
    GET_SEARCH_AUTOCOMPLETE_SUCCESS: 'GET_SEARCH_AUTOCOMPLETE_SUCCESS',
    GET_SEARCH_AUTOCOMPLETE_FAILURE: 'GET_SEARCH_AUTOCOMPLETE_FAILURE',
    GET_SEARCH_PLAYLIST_AUTOCOMPLETE_REQUEST: 'GET_SEARCH_PLAYLIST_AUTOCOMPLETE_REQUEST',
    GET_SEARCH_PLAYLIST_AUTOCOMPLETE_SUCCESS: 'GET_SEARCH_PLAYLIST_AUTOCOMPLETE_SUCCESS',
    GET_SEARCH_PLAYLIST_AUTOCOMPLETE_FAILURE: 'GET_SEARCH_PLAYLIST_AUTOCOMPLETE_FAILURE'    
    
}