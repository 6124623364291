import { notificationReportsService } from '../services/notification_reports';
import { productsActions } from '../actions/products';
import { notificationReportsConstants } from '../constants/notification_reports';

export const notificationReportsActions = {
  getNotificationReports,
  getUserNotificationSettings,
  getFrequencies,
  updateUserNotification
};

function getNotificationReports(){
  return dispatch => {
    dispatch(request());

    return notificationReportsService.getNotificationReports()
    .then( response => {
      dispatch(success(response.data));
    })
    .catch( error => {
      dispatch(failure(error.message));
    })
  }

  function request() { return { type: notificationReportsConstants.GET_NOTIFICATION_REPORTS_REQUEST }};
  function success(payload) { return { type: notificationReportsConstants.GET_NOTIFICATION_REPORTS_SUCCESS, payload }};
  function failure(error) { return { type: notificationReportsConstants.GET_NOTIFICATION_REPORTS_FAILURE, payload: error }};
}



function getUserNotificationSettings(){
  return dispatch => {
    dispatch(request());

    return notificationReportsService.getUserNotificationSettings()
    .then( response => {
      const notificationReportsProducts = response.data.find( report => report.settings.products !== undefined);
      const notificationReportsProductIds = notificationReportsProducts.settings.products;
      dispatch(productsActions.getProductsByBarcode(notificationReportsProductIds));
      dispatch(success(response.data));
    })
    .catch( error => {
      dispatch(failure(error.message));
    })
  }

  function request(){ return { type: notificationReportsConstants.GET_NOTIFICATION_SETTINGS_REQUEST }};
  function success(payload){ return { type: notificationReportsConstants.GET_NOTIFICATION_SETTINGS_SUCCESS, payload }};
  function failure(error){ return { type: notificationReportsConstants.GET_NOTIFICATION_SETTINGS_FAILURE, payload: error}};
}

function getFrequencies(){
  return dispatch => {
    dispatch(request());

    return notificationReportsService.getFrequencies()
    .then( response => {
      dispatch(success(response.data));
    })
    .catch( error => {
      dispatch(failure(error.message));
    })
  }

  function request(){ return { type: notificationReportsConstants.GET_FREQUENCIES_REQUEST }};
  function success(payload){ return { type: notificationReportsConstants.GET_FREQUENCIES_SUCCESS, payload }};
  function failure(error){ return { type: notificationReportsConstants.GET_FREQUENCIES_FAILURE, payload: error}};
}

function updateUserNotification(notification, cb) {
  return dispatch => {
    dispatch(request());

    notificationReportsService.updateUserNotification(notification)
    .then( response => {
      dispatch(success(response.data));
      if (cb && typeof cb === 'function') {
        cb();
      }
    })
    .catch( error => {
      dispatch(failure(error.message));
    })
  }

  function request(){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_REQUEST }};
  function success(payload){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_SUCCESS, payload }};
  function failure(error){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_FAILURE, payload: error}};
}
