import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import HomeView from '../../views/Home';
import MainView from '../../views/Main';
import CatalogView from '../../views/Catalog';
//import AdminView from '../../views/Admin';
import PlaylistSearchView from '../../views/Playlists/Search';
import PlaylistOverviewView from '../../views/Playlists/Overview';
import PlaylistDetailsView from '../../views/Playlists/Details';
import ImprintDetailsView from '../../views/Imprint/Details';
import ArtistProfileView from '../../views/Artist/Profile';
import ProductDetailsView from '../../views/Product/Details';
import ProjectDetailsView from '../../views/Project/Details';
import TrackDetailsView from '../../views/Track/Details';
import RouteLogger from '../../helpers/RouteLogger';
//import HeartBeatsView from '../../views/Heartbeats';
import HeartBeatsView from '../../views/PagedHeartbeats';
import NewReleasesView from '../../views/NewReleases';
import TerritoryAnalysis from '../../views/TerritoryAnalysis/TerritoryAnalysis';
import { NotificationSettings } from '../../views/NotificationSettings/';
import CampaignOverviewView from '../../views/Campaign/Overview';
import AudienceOverviewView from '../../views/Audience/Overview';
import TrackComparisonSearchView from '../../views/TrackComparison/Search';
import API from '../../views/API';
import UserProfile from '../../views/User/Profile';
import UserFavorites from '../../views/User/Favorites';
import UserSharedPages from '../../views/User/SharedPages';

import StatementPeriods from '../../views/Import/StatementPeriods';
import PendingStatements from '../../views/Import/PendingStatements';
import ImportMetadataErrors from '../../views/Import/ImportMetadataErrors';
import ImportMetadata from '../../views/Import/ImportMetadata';
import CatalogImports from '../../views/Import/Imports';
import ImportExceptions from '../../views/Import/Exceptions';

const importRoutes=(props)=>{
    return <RouteLogger>
            <Switch>
                <Route path="/import/statement-periods" component={StatementPeriods}></Route>
                <Route path="/import/pending-statements" component={PendingStatements}></Route>
                <Route path="/import/metadata/:id/errors" component={ImportMetadataErrors}></Route>
                <Route path="/import/metadata" component={ImportMetadata}></Route>
                <Route path="/import/list" component={CatalogImports}></Route>
                <Route path="/import/exceptions" component={ImportExceptions}></Route>
                <Redirect from="/import" to="/import/statement-periods" />
            </Switch>
        </RouteLogger>
    }
export default importRoutes;
