import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { merge, map } from 'lodash';
import vectorMap from 'jvectormap';
import vectorMapSource from '../../../public/vendor/jquery-jvectormap-world-mill';
import { formatTooltipLabelWorldMap } from '../widgets/charts/Formatter';

const topCountryThreshold = 1000000,
    averageCountryThreshold = 100000,
    normalizeFunction = (val)=>{
        if(val > topCountryThreshold)
            return 100;
        else if(val>averageCountryThreshold)
            return 10;
        else
            return 0 ;
    };

const defaultOptions = {
    map: 'world_mill',
    backgroundColor: "transparent",
    regionStyle: {
        initial: {
            fill: '#F2F5FA',
            "fill-opacity": 1,
            stroke: 'none',
            "stroke-width": 0,
            "stroke-opacity": 0
        }
    },
    series: {
        regions: [{
//            scale: ["#CCE6FF", "#0083FF"],
//            normalizeFunction
            scale: ['#C8EEFF', '#0071A4'],
            normalizeFunction: 'polynomial'            
        }]
        

    }
};

class WorldMap extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            mapValues: this.props.data,
            valueLabel: this.props.label
        };

        this.onRegionTipShow = this.onRegionTipShow.bind(this);
        this.onRegionClick = this.onRegionClick.bind(this);
    }

    componentDidMount() {
        this.map = $(ReactDOM.findDOMNode(this.refs.map));
        let mapOptions = merge(defaultOptions, this.props.options);
        mapOptions.series.regions[0].values = this.state.mapValues;
        mapOptions.onRegionTipShow = this.onRegionTipShow;
        mapOptions.onRegionClick = this.onRegionClick;

        this.map.vectorMap(mapOptions);
    }

//    componentWillUnmount(){
//        this.map.remove();
//    }

    onRegionTipShow(e, el, code){
        let value = this.state.mapValues[code] ? (`${this.state.mapValues[code]}  ${this.state.valueLabel}`) : '';
        el.html(formatTooltipLabelWorldMap(el.html(),  value));
    }    
    
    onRegionClick(e, el, code){
        const mapObject = this.map.vectorMap('get', 'mapObject');
        mapObject.tip.hide();
        return this.props.onRegionClick !== undefined ? this.props.onRegionClick(el) : false; 
    }

    render() {
      return (
        <div ref="map" className="world-map-container">          
        </div>
      );
    }
  }

  export default WorldMap;