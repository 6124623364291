import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Modal from '../../common/Modal';
import { importsActions } from '../../../data/actions/imports';
import { Link } from 'react-router-dom';




class ImportData extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
        }
        this.renderProgress = this.renderProgress.bind(this);
    }
    

    
    renderProgress(source) {
        const { imports } = this.props;
        return <div>
            <h4>{source}</h4>
            <ul>
                <li>Extract: {imports.extract[source].loading && <span>loading</span>} {imports.extract[source].success && <span>done</span>}</li>
                <li>Upload: {imports.upload[source].loading && <span>loading</span>} {imports.upload[source].success && <span>done</span>}</li>
                <li>Import: {imports.import[source].loading && <span>loading</span>} {imports.import[source].success && <span>done</span>}</li>
            </ul>
        </div>
    }
    
    render() {
        const { show, imports } = this.props;
        const ready = (imports.import['digital'].success && imports.import['physical'].success);
        
        return <Modal
                show={show}
                title="Data Import"
                handleClose={()=>this.toggleModal('showShareResultModal', false)}
                showActionButtons={false}
                dialogClassName="dialog-modal"
            >
                {this.renderProgress('digital')}
                {this.renderProgress('physical')}
                {ready && <Link to="/import/pending-statements">Proceed to results</Link>}
            </Modal>
    }
}


function mapStateToProps(state) {
    return {
        imports: state.imports
    }
}


export default connect(mapStateToProps)(ImportData);