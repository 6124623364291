import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import config from '../../config/config';

export const userLogsService = {
    getUsers,
    getUserLogs
};

function getUsers() {
  const requestOptions = {
      method: 'GET',
      headers: AuthHeader({'Content-Type': 'application/json'})
  };
    
  let baseUrl = `${config.apis.orthus}/v1/users/by_silo`;
  return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function getUserLogs(userId, page = 1) {
  const requestOptions = {
      method: 'GET',
      headers: AuthHeader({'Content-Type': 'application/json'})
  };
    
  let baseUrl = `${config.apis.orthus}/v1/user_action_logs?user_id=${userId}&page=${page}`;
  return fetch(baseUrl, requestOptions).then(res=>res.json());
}