import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual } from 'lodash';
import CSV from '../../../helpers/CSVExport';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';

import config from '../../../config/config';
import Box from '../../common/Box';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { tracksActions } from '../../../data/actions/tracks';
import { territorySettings } from './Territory/Settings';
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
const CellHeaderTable = cellHeaderHOC(ReactTable);

class TerritoriesStats extends React.Component {
    
    constructor(props) {
        super(props);
        this.exportToCsv = this.exportToCsv.bind(this);        
    }
    
    exportToCsv(){
        const { tracks, id }  = this.props,
            tableData = tracks.territoriesComparison[id];
        const filename = CSV.CSVHeader(`track-comparison-territories`, id);
        return CSV.CSVExport(tableData, {filename}, 'track-comparison-territories');
    }

    renderToolbar(){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }



    componentDidMount() {
        const { id, weeks, metadata , territories, vendors} = this.props,
            releaseDate = metadata.release_date;
        this.props.dispatch(tracksActions.compareTrackTerritories(id, weeks, releaseDate, territories, vendors))
        
    }
    
    componentWillReceiveProps(nextProps) {
    }

    
    render (){
        const { tracks, id, metadata }  = this.props,
            tableData = tracks.territoriesComparison[id];
        
        const title = ( metadata && metadata.name_compare ) ? metadata.name_compare : id;
        return <Box title={title} spinnerEnabled={tracks.territoriesComparisonLoading} toolbar={this.renderToolbar()}>
        
            {tableData && <div className="row">
                <div className={`col-xs-12`}>
                    {tableData.length > 0 && <div className="territory-table territory-table--single custom-scroll">
                        <CellHeaderTable
                            className="territory-table-holder playlist-table track-territory-table"
                            data={tableData}
                            columns={territorySettings.settingsForTable()}
                            defaultPageSize={10}
                            showPagination={tableData.length > 10}
                    /></div>}                    
                    {!tableData.length && <p>No data available</p>}
                </div>    
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        filter: state.filter,
        userData: state.user
    } 
}

export default connect(mapStateToProps)(TerritoriesStats);