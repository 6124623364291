import React from 'react';
import { Link } from 'react-router-dom';

var openPlaylistSvg = require('!svg-inline-loader!../../../../public/img/open_playlist.svg');

class PlaylistAnalysisLink extends React.Component {
    render() {
        const entity = this.props.entity == 'artists' ? 'track_artists' : this.props.entity,
            { id } = this.props;
            
        return (id ? <Link to={`/playlists/${entity}/${id}`} {...this.props}><span className="chart-icon" dangerouslySetInnerHTML={{__html: openPlaylistSvg}}></span></Link> : null)
    }
}

export default PlaylistAnalysisLink