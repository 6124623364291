import { searchConstants } from '../constants/search';
import { searchService } from '../services/search';
import { searchFormatter } from '../formatters/search';

export const searchActions = {
    getSearchAutocomplete,
    getSearchPlaylistAutocomplete
};



function _getSearchAutocomplete(search, limit=10, entities = ['imprints', 'artists', 'products', 'tracks']) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));

        return searchService.autocomplete(search, entities)
            .then(
                search => {
                    search = searchFormatter.formatAutocomplete(search.results);
                    dispatch(success(search.slice(0, limit)));
                    return search;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_REQUEST } }
    function success(search) { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_SUCCESS, search} }
    function failure(error) { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_FAILURE, error } }
}

function getSearchAutocomplete(search, limit=10, entities = ['imprints', 'artists', 'projects', 'products', 'tracks'], force = false) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        
        return searchService.autocomplete(search, entities, force)
            .then(
                search => {
                    const { results, exact_match } = search;
                    search = searchFormatter.formatAutocomplete(results, entities);
                    dispatch(success(search, exact_match)) //.slice(0, limit)));
                    return search;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_REQUEST } }
    function success(search, exactMatch) { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_SUCCESS, search, exactMatch} }
    function failure(error) { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_FAILURE, error } }
}


function getSearchPlaylistAutocomplete(search, limit=10, entities = ['imprints', 'artists', 'products', 'tracks'], force = false) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        
        return searchService.autocomplete(search, entities, force)
            .then(
                search => {
                    const { results, exact_match } = search;
                    search = searchFormatter.formatAutocomplete(results, entities);
                    dispatch(success(search, exact_match));
                    return search;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: searchConstants.GET_SEARCH_PLAYLIST_AUTOCOMPLETE_REQUEST } }
    function success(search, exactMatch) { return { type: searchConstants.GET_SEARCH_PLAYLIST_AUTOCOMPLETE_SUCCESS, search, exactMatch} }
    function failure(error) { return { type: searchConstants.GET_SEARCH_PLAYLIST_AUTOCOMPLETE_FAILURE, error } }
}
