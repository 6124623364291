import React from 'react';

class Option extends React.Component {
    constructor(props){
        super(props);
    }
    getCheckedClass(){
        if(this.props.type=='checkbox')
            return this.props.selected ? 'far fas fa-check-square checked' : 'far fa-square';
        else
            return this.props.selected ? 'fas fa-dot-circle checked' : 'far fa-circle';
    }

    
    render (){
        const { label, value, selected } = this.props; 
        return  <div className="option option-item">
            <span className={this.getCheckedClass()} onClick={()=>this.props.handleChange(value)}></span>{label}
        </div>;
    }
}


export default Option;