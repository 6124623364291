export const importsConstants = {
    GET_STATEMENT_PERIODS_REQUEST: 'GET_STATEMENT_PERIODS_REQUEST',
    GET_STATEMENT_PERIODS_SUCCESS: 'GET_STATEMENT_PERIODS_SUCCESS',
    GET_STATEMENT_PERIODS_FAILURE: 'GET_STATEMENT_PERIODS_FAILURE',
    GET_PENDING_STATEMENTS_REQUEST: 'GET_PENDING_STATEMENTS_REQUEST',
    GET_PENDING_STATEMENTS_SUCCESS: 'GET_PENDING_STATEMENTS_SUCCESS',
    GET_PENDING_STATEMENTS_FAILURE: 'GET_PENDING_STATEMENTS_FAILURE',
    EXTRACT_SALES_DATA_REQUEST: "EXTRACT_SALES_DATA_REQUEST",
    EXTRACT_SALES_DATA_SUCCESS: "EXTRACT_SALES_DATA_SUCCESS", 
    EXTRACT_SALES_DATA_FAILURE: "EXTRACT_SALES_DATA_FAILURE",
    UPLOAD_TO_STORAGE_REQUEST: "UPLOAD_TO_STORAGE_REQUEST",
    UPLOAD_TO_STORAGE_SUCCESS: "UPLOAD_TO_STORAGE_SUCCESS", 
    UPLOAD_TO_STORAGE_FAILURE: "UPLOAD_TO_STORAGE_FAILURE",
    IMPORT_SALES_DATA_REQUEST: "IMPORT_SALES_DATA_REQUEST",
    IMPORT_SALES_DATA_SUCCESS: "IMPORT_SALES_DATA_SUCCESS", 
    IMPORT_SALES_DATA_FAILURE: "IMPORT_SALES_DATA_FAILURE",
    APPROVE_SALES_DATA_REQUEST: "APPROVE_SALES_DATA_REQUEST",
    APPROVE_SALES_DATA_SUCCESS: "APPROVE_SALES_DATA_SUCCESS", 
    APPROVE_SALES_DATA_FAILURE: "APPROVE_SALES_DATA_FAILURE",
    REJECT_SALES_DATA_REQUEST: "REJECT_SALES_DATA_REQUEST",
    REJECT_SALES_DATA_SUCCESS: "REJECT_SALES_DATA_SUCCESS", 
    REJECT_SALES_DATA_FAILURE: "REJECT_SALES_DATA_FAILURE",
    CATALOG_IMPORT_REQUEST: "CATALOG_IMPORT_REQUEST",
    CATALOG_IMPORT_SUCCESS: "CATALOG_IMPORT_SUCCESS", 
    CATALOG_IMPORT_FAILURE: "CATALOG_IMPORT_FAILURE",
    CATALOG_IMPORT_ERRORS_REQUEST: "CATALOG_IMPORT_ERRORS_REQUEST",
    CATALOG_IMPORT_ERRORS_SUCCESS: "CATALOG_IMPORT_ERRORS_SUCCESS", 
    CATALOG_IMPORT_ERRORS_FAILURE: "CATALOG_IMPORT_ERRORS_FAILURE",
    CATALOG_EXCEPTIONS_REQUEST: "CATALOG_EXCEPTIONS_REQUEST",
    CATALOG_EXCEPTIONS_SUCCESS: "CATALOG_EXCEPTIONS_SUCCESS",
    CATALOG_EXCEPTIONS_FAILURE: "CATALOG_EXCEPTIONS_FAILURE",
    GET_CATALOG_IMPORTS_REQUEST: "GET_CATALOG_IMPORTS_REQUEST",
    GET_CATALOG_IMPORTS_SUCCESS: "GET_CATALOG_IMPORTS_SUCCESS", 
    GET_CATALOG_IMPORTS_FAILURE: "GET_CATALOG_IMPORTS_FAILURE",
    UNDO_CATALOG_IMPORT_REQUEST: "UNDO_CATALOG_IMPORT_REQUEST",
    UNDO_CATALOG_IMPORT_SUCCESS: "UNDO_CATALOG_IMPORT_SUCCESS", 
    UNDO_CATALOG_IMPORT_FAILURE: "UNDO_CATALOG_IMPORT_FAILURE",
    
}