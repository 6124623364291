import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual } from 'lodash';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import CSV from '../../../helpers/CSVExport';
import { milestonesActions } from '../../../data/actions/milestones';
import { milestonesSettings } from './Milestones/Settings'
import AddMilestoneForm from '../../forms/addMilestone'
import AddChannelToCampaignForm from '../../forms/addChannelToCampaign'
import MilestoneChart from './Milestones/Chart';
import LinkedEntities from './Milestones/LinkedEntities';

import Box from '../../common/Box';
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class Milestones extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showNewMilestoneModal: false,
            showEditMilestoneModal: false,
            showAddChannelModal: false,
            mode: 'table',
            milestoneType: undefined,
            currentMilestone: undefined
        }
        
        this.showModal = this.showModal.bind(this);
        this.addMilestone = this.addMilestone.bind(this);
        this.editMilestone = this.editMilestone.bind(this);
        this.deleteMilestone = this.deleteMilestone.bind(this);
        this.refresh = this.refresh.bind(this);
        this.setCurrentMilestone = this.setCurrentMilestone.bind(this);
        this.setChartMode = this.setChartMode.bind(this);
        this.setMilestoneType = this.setMilestoneType.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }
        
    showModal(modal, show){
        this.setState({
            [modal]: show
        })
    }
    
    setChartMode(mode){
        this.setState({mode});
    }
    
    setMilestoneType(milestoneType){
        this.setState({milestoneType});
    }

    addMilestone(formData) {
        this.props.dispatch(milestonesActions.addMilestone(this.props.id, formData));
        this.showModal('showNewMilestoneModal', false);
    }
    
    editMilestone(formData) {
        this.props.dispatch(milestonesActions.editMilestone(this.props.id, formData, this.state.currentMilestone.id));
        this.showModal('showEditMilestoneModal', false);
    }

    deleteMilestone(milestoneID) {
        this.props.dispatch(milestonesActions.deleteMilestone(this.props.id, milestoneID));
    }
    
    refresh(){
        this.props.dispatch(milestonesActions.getMilestones(this.props.id))
        this.showModal('showAddChannelModal', false);
    }
    
    exportToCsv(){
        const { tableItems } = this.props.milestones,
            typeLabel = this.state.milestoneType ? this.state.milestoneType.label : 'all',
            filename = CSV.CSVHeader('campaign milestones', typeLabel, this.props.filter.global, this.props.parentEntityTitle);
        return CSV.CSVExport(this.filterMilestoneTypes(tableItems), {filename});
    }

    setCurrentMilestone(currentMilestone, showModal) {
        this.setState({currentMilestone}, ()=>this.showModal(showModal, true));
    }
    
    componentDidMount() {
        this.props.dispatch(milestonesActions.getMilestones(this.props.id))
        this.props.dispatch(milestonesActions.getMilestoneTypes())
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.dispatch(milestonesActions.getMilestones(this.props.id))
            }                
        }
        
        if(nextProps.id && nextProps.id !== this.props.id){
            this.props.dispatch(milestonesActions.getMilestones(nextProps.id))
        }
    }
    
    filterMilestoneTypes(items){
        const { milestoneType } = this.state;
        if(milestoneType && milestoneType.value) {
            items = items.filter(item=>item.milestone_type_id == milestoneType.value)
        }
        return items;
    }

    renderToolbar(types){
        let toolbar = [];
    
        types = [{
            label: 'All',
            value: null
        }].concat(types);
    
        toolbar.push(<div className="">
        <div className="ibox-icon-holder">
            <a key='pie' className={`chart-icon ${this.state.mode == 'bar' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('bar')}
                dangerouslySetInnerHTML={{__html: barSvg}}>
            </a>
            <a key='table' className={`chart-icon ${this.state.mode == 'table' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('table')}
                dangerouslySetInnerHTML={{__html: tableSvg}}>
            </a>
            <a key="download" onClick={this.exportToCsv} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            
        </div>
        <div key="sort" className="select-holder"><span className="select-label">Milestone Type </span>
            <ReactSelect value={this.state.milestoneType || types[0]} options={types} onChange={this.setMilestoneType} className="single-select"/>
        </div>
        </div>)
        return toolbar;
    }    

    render (){
        
        const renderChart = (items) => {
            return <MilestoneChart items={items} />
        };

        const renderTable = (items) => {
            return <ReactTable
                data={items}
                className='milestone-table'
                columns={milestonesSettings.settingsForTable(this.setCurrentMilestone, this.deleteMilestone)}
                showPagination={false}
                pageSize={(items && items.length ) ? items.length : 10}
                SubComponent={row => <LinkedEntities row={row} /> }
            />

        }

        
        
        const { items, types, tableItems } = this.props.milestones;

        return <Box title="Milestones" toolbar={this.renderToolbar(types)}>
            {types && <div>
                <div className="small-title-holder">
                    <h3 className="title">Add Milestone</h3>
                    <button title="Add Milestone" onClick={()=>this.showModal('showNewMilestoneModal', true)} className="btn add-to-filter"><i class="fas fa-plus"></i></button>
                </div>
                <AddMilestoneForm show={this.state.showNewMilestoneModal} title="Create Milestone" onSubmit={this.addMilestone} onClose={(e)=>this.showModal('showNewMilestoneModal',false)} milestoneTypes={types} />
                <AddMilestoneForm show={this.state.showEditMilestoneModal} title="Edit Milestone" onSubmit={this.editMilestone} onClose={(e)=>this.showModal('showEditMilestoneModal',false)} milestoneTypes={types} values={this.state.currentMilestone} />
                <AddChannelToCampaignForm show={this.state.showAddChannelModal} title="Link Milestone with Channel" onSubmit={()=>{this.refresh(); this.props.refreshCampaign();}} onClose={(e)=>this.showModal('showAddChannelModal',false)} milestone={this.state.currentMilestone} />
            </div>}
            {this.state.mode=='bar' && renderChart(this.filterMilestoneTypes(items))}
            {this.state.mode=='table' && renderTable(this.filterMilestoneTypes(tableItems))}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        milestones: state.milestones,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(Milestones);