import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import BuildFilterQS from '../../helpers/BuildFilterQS';
import DeduplicateQS from '../../helpers/DeduplicateQS';
import config from '../../config/config';
import {searchService} from './search';

import comparisonMainData from './stubs/track_comparison';
import comparisonDemographicsData from './stubs/track_comparison_demographics';

export const tracksService = {
    getTracks,
    getTopTracks,
    getTopTracksInPlaylist,
    getTrackDetails,
    getTracksAutocomplete,
    getCard,
    getStreamsStats,
    getMetadata,
    getTimeseriesTracks,
    getTimeseriesTracksInPlaylist,
    compareTracks,
    compareTrackDemographics,
    compareTrackTerritories,
    compareTrackVendors,
    getTracksReleaseDates
};

function getTracksOld(params) {
    let { sorted, page, pageSize, filtered } = params;
    const requestOptions = {
        method: 'GET',
    };
    
    // TODO: API dependent, change for prod
    let baseUrl = `${config.apis.sales}/api/v1/Track`, 
        qs = AuthHeader();
    
    if(sorted !== undefined)
        qs.sort = {[sorted[0].id]: sorted[0].desc?-1:1};
    if(page !== undefined && pageSize !== undefined ) {
        qs.limit = pageSize;
        qs.skip = pageSize*params.page;
    }
    if(filtered !== undefined ) {
        let filters = [];
        for(let filter of filtered){
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array){
                if(value.length)
                    filters.push(`"${filter.id}": {"$in": [${value.join(',')}]}`);
            }
            else if(typeof value === 'string')
                filters.push(`"${filter.id}": {"$regex": "${value}"}`);
            else
                filters.push(`"${filter.id}": ${value}`);    
            
        }
        if(filters.length)
            qs.query = `{${filters.join(',')}}`; 
    }
    //
    baseUrl += '?' + $.param(qs);

    let pages;
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        pages = res.headers.get('X-Total-Count');   
        return res.json()
    })
    .then(data=>{
        return {data, pages}
    });
}

function getTracks(params, globalFilter){
    return  config.live ? getTracksLive(params, globalFilter) : getTracksStatic();
}

function getTracksLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };        
    
    qs.query_name = 'cat_nav_tracks';
    qs.offset = params.page ? params.page*globalFilter.limit : 0;
    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else if(typeof value === 'string') { // TODO: kinda crappy way of determining full-string search 
                qs.search_by = filter.id;
                qs.search_term = filter.value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }
    qs = BuildFilterQS(qs, 'tracks');
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getTracksStatic(mode) { 
    let baseUrl = `${config.apis.sales}/api/static/tracks.json`;
    return fetch(baseUrl)
    .then(res=>{   
        return res.json()
    });
}


function getTopTracks(params, globalFilter){
    return config.live ? getTopTracksLive(params, globalFilter) : getTopTracksStatic();
}

function getTopTracksLive(params, globalFilter) {
    let baseUrl = config.apis.secStat+'/top_tracks',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    // qs.query_name = 'top_tracks';
    // qs.offset = 0;
    qs.mode = 'main';
    let {sorted, filtered, page } = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }        
    if(page !== undefined){
        qs.offset = Number(page)*qs.limit; 
    }
    qs = DeduplicateQS(qs);

    baseUrl += '?' + $.param(qs);

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTopTracksStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/sec/top_tracks.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getTopTracksInPlaylist(params, globalFilter){
    return config.live ? getTopTracksInPlaylistLive(params, globalFilter) : getTopTracksInPlaylistStatic();
}

function getTopTracksInPlaylistLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'top_tracks_in_playlist';
    // qs.offset = 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }        

    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTopTracksInPlaylistStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_tracks_in_playlist_simple.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTrackDetails(mode) {
    let baseUrl = `${config.apis.sales}/api/static/track_products.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTracksAutocomplete(input) {
    return searchService.autocomplete(input, ['tracks']);
}

function getCard(id) {
    let fields = 'id,title,packshot_file_name', 
        baseUrl = `${config.apis.miniCat}/v2/tracks/${id}?fields=${fields}`,
        requestOptions = {
            headers: AuthHeader()
        };
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getStreamsStats(ids, globalFilter) {
    return config.live ? getStreamsStatsLive(ids, globalFilter) : getStreamsStatsStatic();
}

function getStreamsStatsLive(ids, globalFilter) {

    //
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    //
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'top_tracks';
    // qs.offset = params.page ? params.page*globalFilter.limit : 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    baseUrl += '?' + $.param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
} 

function getStreamsStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/tracks_streams.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    })    
}

function getMetadata(ids){
    return config.live ? getMetadataLive(ids) : getMetadataStatic();
}

function getMetadataLive(ids) {
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.miniCat}/v2/tracks/by_isrc?track_isrc=${ids}`,
        requestOptions = {
            method: 'GET',
            headers: AuthHeader(),
//            body: 'track_ids='+ids
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.text()
        .then(res=>{
        	if(res) 
        		return JSON.parse(res);
        	else
        		return {data: []};
        });
    });
}

function getMetadataStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sec/top_tracks_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}


function getMetadataID(ids){
    return config.live ? getMetadataIDLive(ids) : getMetadataIDStatic();
}

function getMetadataIDLive(ids) {
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.miniCat}/v2/tracks/by_id/`,
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}),
            body: 'track_ids='+ids
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataIDStatic() {
    let baseUrl = `${config.apis.sales}/api/static/tracks_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}


function getTimeseriesTracks(params, globalFilter){
    return config.live ? getTimeseriesTracksLive(params, globalFilter) : getTimeseriesTracksStatic();
}

function getTimeseriesTracksLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
	let baseUrl = config.apis.secStat+'/top_tracks',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.mode = 'timeseries';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            const key = filter.id == 'ids' ? 'tracks' : filter.id;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;

                qs[key] = value;
            }
            else {
                qs[key] = value;    
            }
        }            
    }        
    qs = DeduplicateQS(qs);
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTimeseriesTracksStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/sec/top_tracks_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}



function getTimeseriesTracksInPlaylist(params, globalFilter){
    return config.live ? getTimeseriesTracksInPlaylistLive(params, globalFilter) : getTimeseriesTracksInPlaylistStatic();
}

function getTimeseriesTracksInPlaylistLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'top_tracks_in_playlist';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }        

    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTimeseriesTracksInPlaylistStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_tracks_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function compareTracks(ids, weeks, releaseDates, territories, vendors, user){
    return config.live ? compareTracksLive(ids, weeks, releaseDates, territories, vendors, user) : compareTracksStatic();
}

function compareTracksLive(ids, weeks, releaseDates, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = Array.isArray(ids) ? ids.join(',') : ids;
    qs.release_dates = Array.isArray(releaseDates) ? releaseDates.join(',') : releaseDates;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.data_silo_codes;
    qs.entity = 'main';

    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareTracksStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/track_comparison.json`;
    return fetch(baseUrl).then(res=>res.json());
}

/*
function compareTracksStatic() {
    return Promise.resolve(comparisonMainData);
}
*/

function compareTrackDemographics(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareTrackDemographicsLive(id, weeks, releaseDate, territories, vendors, user) : compareTrackDemographicsStatic();
}

function compareTrackDemographicsLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = id;
    qs.release_dates = releaseDate;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.data_silo_codes;
    qs.entity = 'demographics';

    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareTrackDemographicsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/track_comparison_demographics.json`;
    return fetch(baseUrl).then(res=>res.json());
}

/*
function compareTrackDemographicsStatic() {
    return Promise.resolve(comparisonDemographicsData);
}
*/


function compareTrackTerritories(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareTrackTerritoriesLive(id, weeks, releaseDate, territories, vendors, user) : compareTrackTerritoriesStatic();
}

function compareTrackTerritoriesLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = id;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.release_dates = releaseDate;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.data_silo_codes;
    qs.entity = 'territories';

    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareTrackTerritoriesStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/track_comparison_territories.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function compareTrackVendors(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareTrackVendorsLive(id, weeks, releaseDate, territories, vendors, user) : compareTrackVendorsStatic();
}

function compareTrackVendorsLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = id;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.release_dates = releaseDate;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.data_silo_codes;
    qs.entity = 'vendors';

    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareTrackVendorsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/track_comparison_vendors.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getTracksReleaseDates(ids) {
    ids = Array.isArray(ids) ? ids.join(',') : ids;
    let baseUrl = `${config.apis.miniCat}/v3/tracks/release_dates?isrcs=${ids}`,
        requestOptions = {
            headers: AuthHeader()
        };
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}
